import {HistoryAndMarker} from "@sense-os/goalie-js";
import {channel, Channel} from "redux-saga";
import {call, cancelled, fork, put, select, take, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {whenLoggedIn} from "../../auth/sagas/helper";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {LoadingState} from "../../ts/constants/redux";
import {ChatAction} from "../redux/ChatAction";
import {ChatRoomAction} from "../redux/ChatRoomAction";
import {ChatRoomState, UserMapChatRoomState} from "../redux/ChatRoomReducer";
import {getChatRoomState, isConnected} from "../redux/ChatSelector";
import {getContactById} from "../../contacts/redux/contactSelectors";
import {Contact} from "../../contacts/contactTypes";

import chatSDK from "../sdk";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {isUnauthorizedError} from "../../errorHandler/errorHandlerUtils";
import {isLoggable} from "../../ts/utils/muteLog/loggable";

const log = createLogger("ChatHistory", SentryTags.Chat);

/**
 * Get all unread messages, messages, read marker, and sent marker.
 * If portal want to load chat without queue, simply dispatch this action.
 * Like how this action is fired in contact action when loading contact for one id.
 * But, instead, if we want to queue loading chat, dispatch queueToLoadChat action instead.
 */
function* loadChat(action: ActionType<typeof ChatAction.loadChat>) {
	const {userId} = action.payload;

	isLoggable.loadChat && log.debug("loadChat", userId);

	try {
		yield put(ChatRoomAction.setChatRoomFetchingState(userId, LoadingState.LOADING));
		const {history, sentMarker, receivedMarker}: HistoryAndMarker = yield apiCallSaga(
			chatSDK.getUnreadHistoryAndMarker,
			userId,
		);

		const partialRoomState: Partial<ChatRoomState> = {};

		if (history.length === 0) {
			// There are no chat history for this user. We don't want to show
			// button to load more chat anymore.
			partialRoomState.isAllHistoryFetched = true;
		}

		partialRoomState.sentReadMarkerTimestampMs = sentMarker.read.markerTimestamp;
		if (receivedMarker.delivered) {
			partialRoomState.deliveredTimestampMs = receivedMarker.delivered.markerTimestamp;
		}

		if (receivedMarker.read) {
			partialRoomState.readTimestampMs = receivedMarker.read.markerTimestamp;
		}

		partialRoomState.fetchingState = LoadingState.LOADED;
		yield put(ChatRoomAction.bulkSetChatRoomState({[userId]: partialRoomState}));
		yield put(ChatRoomAction.addMessages(userId, history));
		isLoggable.loadChat && log.debug("loadChat done!", userId);
	} catch (err) {
		if (isUnauthorizedError(err)) {
			return;
		}

		let contactData: Contact = yield select(getContactById, userId);
		const encodeUserID = contactData ? contactData.hashId : "Unknown";

		log.captureException(err, {message: `Failed to initialise chat for ${encodeUserID}`, hashId: encodeUserID});
		yield put(ChatRoomAction.setChatRoomFetchingState(userId, LoadingState.ERROR));

		// Re-queue this user id so its chat can be loaded.
		yield put(ChatAction.queueToLoadChat([userId]));
	}
}

/**
 * Basically, this function will listen for ChatAction.loadChat that comes from given channel.
 * Note how this function directly do `yield call(loadChat, action);`.
 * This meant, loading chat will be blocking the loop in this function when running.
 *
 * This blocking is how we achieve queueing load chat and support
 * the requirement where portal is limited at 10 as maximum number
 * of concurrent chat history query to ejabberd.
 */
function* subscribeToLoadChatChannel(chan: Channel<ActionType<typeof ChatAction.loadChat>>) {
	while (true) {
		const isChatConnected: boolean = yield select(isConnected);

		// Only trigger load chat when chat is connected.
		// This way, ContactAction free to queue load chat, even when chat isn't connected yet.
		if (isChatConnected) {
			const action: ActionType<typeof ChatAction.loadChat> = yield take(chan);
			yield call(loadChat, action);
		} else {
			// Wait until chat status is changed
			yield take(getType(ChatAction.setConnectionStatus));
		}
	}
}

/**
 * Given a channel, this function will return another function that can respond to
 * ChatAction.queueToLoadChat action by putting ChatAction.loadChat into that action.
 */
const createQueueHandler = (chan: Channel<ActionType<typeof ChatAction.loadChat>>) =>
	function* (action: ActionType<typeof ChatAction.queueToLoadChat>) {
		const {userIds} = action.payload;

		const state: UserMapChatRoomState = yield select(getChatRoomState);
		const partialState = {};

		// Only queue user that isn't currently in the queue
		const userIdsNotCurrentlyFetching = userIds.filter(
			(id) => !state || !state[id] || state[id].fetchingState !== LoadingState.LOADING,
		);

		for (let k = 0; k < userIdsNotCurrentlyFetching.length; k++) {
			partialState[userIdsNotCurrentlyFetching[k]] = {fetchingState: LoadingState.LOADING};
		}
		yield put(ChatRoomAction.bulkSetChatRoomState(partialState));

		// Tested it with NDT account in portal with more than thousand clients,
		// and doesn't hit performance issue there. Most probably,
		// because this doesn't directly affect anything visual.
		for (let k = 0; k < userIdsNotCurrentlyFetching.length; k++) {
			yield put(chan, ChatAction.loadChat(userIdsNotCurrentlyFetching[k]));
		}
	};

function* queueToLoadChat() {
	/**
	 * There's this requirement, where portal is limited at 10 as maximum number
	 * of concurrent chat history query to ejabberd. To facilitate that, we create
	 * a queue via redux-saga channel here.
	 *
	 * Thus, in this function, we fork 10 subscribeToLoadChatChannel as
	 * function that will consume action from created channel.
	 * Also, there'll be while loop in this function, where we populate that channel with action.
	 *
	 * For more details: https://redux-saga.js.org/docs/advanced/Channels.html
	 */
	const chan: Channel<ActionType<typeof ChatAction.loadChat>> = yield call(channel);

	try {
		// Only load chat 10 at a times
		for (let i = 0; i < 10; i++) {
			yield fork(subscribeToLoadChatChannel, chan);
		}

		const queueHandler = createQueueHandler(chan);
		yield takeEvery(getType(ChatAction.queueToLoadChat), queueHandler);
	} finally {
		if (yield cancelled()) {
			chan.close();
		}
	}
}

function* chatHistorySaga() {
	yield takeEvery(getType(ChatAction.loadChat), loadChat);
	yield fork(queueToLoadChat);
}

export default function* () {
	yield fork(whenLoggedIn(chatHistorySaga));
}
