import produce from "immer";
import {GroupTherapyReduxState} from "../groupTherapy";
import {GroupTherapyActions, groupTherapyActions} from "./groupTherapyActions";
import {getType} from "typesafe-actions";
import {arrToMap} from "../../helpers/arrToMap";
import {LoadingState} from "constants/redux";

const initialState: GroupTherapyReduxState = {
	loadingState: LoadingState.EMPTY,
	savingState: LoadingState.EMPTY,
	deletingState: LoadingState.EMPTY,
	registrationFetchState: LoadingState.EMPTY,
	registrationSavingState: LoadingState.EMPTY,
	registrationDeletingState: LoadingState.EMPTY,
	registrationDetailFetchingState: LoadingState.EMPTY,
	groupMap: {},
	registrationDetails: null,
	relatedSensorDataMap: {},
	// group therapy manage trackers
	isManageTrackersDialogOpened: false,
	trackers: [],
	trackersFetchingState: LoadingState.EMPTY,
	trackersSavingState: LoadingState.EMPTY,
};

export const groupTherapyReducer = produce((state: GroupTherapyReduxState, action: GroupTherapyActions) => {
	switch (action.type) {
		case getType(groupTherapyActions.fetchGroupTherapyList.request): {
			state.loadingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.fetchGroupTherapyList.success): {
			state.groupMap = arrToMap(action.payload.groups, (group) => group.id);
			state.loadingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.fetchGroupTherapyList.failure): {
			state.loadingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationList.request): {
			state.registrationFetchState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationList.success): {
			const {id, registrations, totalRegistrations} = action.payload;
			state.groupMap[id].groupRegistrations = registrations;
			state.groupMap[id].totalRegistrations = totalRegistrations;
			state.registrationFetchState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationList.failure): {
			state.registrationFetchState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.addAGroupTherapy.request): {
			state.savingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.addAGroupTherapy.success): {
			const {group} = action.payload;
			state.groupMap[group.id] = group;
			state.savingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.addAGroupTherapy.failure): {
			state.savingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.editAGroupTherapy.request): {
			state.savingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.editAGroupTherapy.success): {
			const {group} = action.payload;
			state.groupMap[group.id] = group;
			state.savingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.editAGroupTherapy.failure): {
			state.savingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.deleteAGroupTherapy.request): {
			state.deletingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.deleteAGroupTherapy.success): {
			const {id} = action.payload;

			if (state.groupMap[id]) {
				delete state.groupMap[id];
				state.deletingState = LoadingState.LOADED;
			}
			return;
		}

		case getType(groupTherapyActions.deleteAGroupTherapy.failure): {
			state.deletingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.saveGroupTherapySessionNote.request):
		case getType(groupTherapyActions.saveGroupTherapySession.request): {
			state.registrationSavingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.saveGroupTherapySessionNote.success):
		case getType(groupTherapyActions.saveGroupTherapySession.success): {
			state.registrationSavingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.saveGroupTherapySessionNote.failure):
		case getType(groupTherapyActions.saveGroupTherapySession.failure): {
			state.registrationSavingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationDetails.request): {
			state.registrationDetailFetchingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationDetails.success): {
			const {registrationDetails, relatedSensorDataMap} = action.payload;
			state.registrationDetailFetchingState = LoadingState.LOADED;
			state.registrationDetails = registrationDetails;
			state.relatedSensorDataMap = relatedSensorDataMap;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationDetails.failure): {
			state.registrationDetailFetchingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.deleteGroupRegistration.request): {
			state.registrationDeletingState = LoadingState.LOADING;
			return;
		}
		case getType(groupTherapyActions.deleteGroupRegistration.success): {
			state.registrationDeletingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.deleteGroupRegistration.failure): {
			state.registrationDeletingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.setEditDialogForGroupTherapy): {
			state.groupForDialog = action.payload.group;
			return;
		}

		case getType(groupTherapyActions.clearFetchedRegistrationDetails): {
			state.registrationDetailFetchingState = LoadingState.EMPTY;
			state.registrationDetails = null;
			state.relatedSensorDataMap = {};
			return;
		}

		case getType(groupTherapyActions.openManageTrackersDialog): {
			const {open} = action.payload;

			state.isManageTrackersDialogOpened = open;
			return;
		}

		case getType(groupTherapyActions.fetchGroupTherapyTrackers.request): {
			state.trackersFetchingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.fetchGroupTherapyTrackers.success): {
			const {trackers} = action.payload;

			state.trackersFetchingState = LoadingState.LOADED;
			state.trackers = trackers;
			return;
		}

		case getType(groupTherapyActions.fetchGroupTherapyTrackers.failure): {
			state.trackersFetchingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.setTrackerStatus): {
			const {isEnabled, trackerId} = action.payload;

			const findTrackerIndex: number = state.trackers.findIndex((tracker) => {
				return tracker.id === trackerId;
			});
			state.trackers[findTrackerIndex].isEnabled = isEnabled;

			return;
		}

		case getType(groupTherapyActions.updateTrackerStatus.request): {
			state.trackersSavingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.updateTrackerStatus.success): {
			state.trackersSavingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.updateTrackerStatus.failure): {
			state.trackersSavingState = LoadingState.ERROR;
			return;
		}
	}
}, initialState);
