import {LanguageTag} from "@sense-os/goalie-js";

/*
 * Valid url based on https://www.ietf.org/rfc/rfc1738.txt is written as follows:
 * <scheme>:<scheme-specific-part>
 * But we limit the scope here to only those that start with http or https
 */
export const isValidUrl = (urlStr: string): boolean => {
	let url;
	try {
		url = new URL(urlStr);
	} catch (_) {
		return false;
	}
	return url.protocol === "http:" || url.protocol === "https:";
};

/*
 * Niceday Blog Url will always start with https://niceday.app/
 * it wil return true if start with it, vice versa
 */
export const isNiceDayBlogUrl = (urlStr: string): boolean => {
	return urlStr.startsWith("https://niceday.app/");
};

/** help-desk url based on the selected language */
export const articleLink = {
	[LanguageTag.EN]: "https://niceday.productfruits.help/en/article/2fa-authentication-",
	[LanguageTag.NL]: "https://niceday.productfruits.help/nl/article/2fa-authenticatie-",
};
