import {UserRole} from "@sense-os/goalie-js";
import {createSelector} from "reselect";
import {getAuthUser} from "../../../auth/redux";
import {OutgoingCallTypes} from "../../../call/callTypes";
import localization from "../../../localization/Localization";
import {getFullName} from "../../../userProfile/helpers/profileHelpers";
import {getCallTypeListByParticipantsRole} from "../../../call/helpers/callHelpers";

import {AppState} from "../AppState";
import strTranslation from "../../../assets/lang/strings";

export const getVideoCallState = (state: AppState) => state.videoCall;
export const getActiveCall = (state: AppState) => state.videoCall.activeCall;
export const getRoomId = createSelector(getActiveCall, (call) => (!call ? undefined : call.roomId));
export const getClientIdOfCall = createSelector(getActiveCall, (call) =>
	!call
		? undefined
		: Object.keys(call.participantMap || {}).find((id) => call.participantMap[id].role === UserRole.PATIENT),
);

/**
 * Combines participants name from ActiveCall data into one string
 */
export const getCallerNamesFromActiveCall = (state: AppState) => {
	const activeCall = getActiveCall(state);
	const authUser = getAuthUser(state);

	if (!activeCall) {
		return localization.formatMessage(strTranslation.COMMON.your_contact);
	}

	// Get names from participant data
	return (
		Object.keys(activeCall.participantMap)
			// Filter out auth user and participants who didn't join the call
			.filter((id) => id !== authUser.id.toString() && !!activeCall.participantMap[id].joinedTime)
			.map((id) => getFullName(activeCall.participantMap[id]))
			.join(", ")
	);
};

export const isCallWindowCrashed = (state: AppState) => state.videoCall.callWindowCrashed;

export const getCallInfo = createSelector(getVideoCallState, (state) => state.callInfo);

export const getCallTypeList = createSelector(getCallInfo, ({senderInfo, recipientInfo}): OutgoingCallTypes[] => {
	return getCallTypeListByParticipantsRole(senderInfo.role, recipientInfo.role);
});
