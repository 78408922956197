import {getType} from "typesafe-actions";
import analyticsService from "../../analytics/AnalyticsService";
import {getAuthUserExternalId} from "../../auth/helpers/authStorage";
import {groupTherapyActions, GroupTherapyActions} from "./groupTherapyActions";

const trackedActions = [
	groupTherapyActions.addAGroupTherapy.request,
	groupTherapyActions.editAGroupTherapy.request,
	groupTherapyActions.deleteAGroupTherapy.request,
	groupTherapyActions.saveGroupTherapySession.request,
	groupTherapyActions.deleteGroupRegistration.request,
	groupTherapyActions.fetchGroupRegistrationDetails.request,
	groupTherapyActions.updateTrackerStatus.request,
].map(getType);

const properties = (action: GroupTherapyActions) => {
	/**
	 * Tracked actions and payload are based on this slab doc:
	 * https://niceday.slab.com/posts/release-planning-group-therapy-v-1-60xb8a7x#hxei7-events
	 */
	switch (action.type) {
		// Group creation
		case getType(groupTherapyActions.addAGroupTherapy.request):
			return {
				createdBy: getAuthUserExternalId(),
				createdDate: new Date(),
				numberOftherapists: action.payload.group.therapists.length || 0,
				numberOfClients: action.payload.group.clients.length || 0,
			};

		// Group edit
		case getType(groupTherapyActions.editAGroupTherapy.request):
			return {
				groupId: action.payload.id,
				updatedBy: getAuthUserExternalId(),
				updatedDate: new Date(),
				updatedNumberOfClients: action.payload.groupUpdate.clients.length || 0,
			};

		// Group delete
		case getType(groupTherapyActions.deleteAGroupTherapy.request):
			return {
				groupId: action.payload.id,
				deletedBy: getAuthUserExternalId(),
				deletedDate: new Date(),
			};

		// Group registration create / edit
		case getType(groupTherapyActions.saveGroupTherapySession.request):
			return {
				groupId: action.payload.group.id,
				isEdit: action.payload.isEdit,
				meetInfo: action.payload.formValues.meetInfo,
				savedBy: getAuthUserExternalId(),
				savedDate: new Date(),
			};

		// Group registration delete
		case getType(groupTherapyActions.deleteGroupRegistration.request):
			return {
				groupId: action.payload.groupId,
				deletedBy: getAuthUserExternalId(),
				deletedDate: new Date(),
			};

		default:
			return null;
	}
};

export const groupTherapyEvents = analyticsService.trackReduxActions(trackedActions, properties);
