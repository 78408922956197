import {put, call, takeEvery, select} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {
	SensorData as BaseSensorData,
	GroupTherapyMember,
	GroupTherapySensor,
	RegistrationType,
	SensorDataResolvedResponse,
} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";

import {SentryTags} from "../../../errorHandler/createSentryReport";
import createLogger from "../../../logger/createLogger";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {toastActions} from "../../../toaster/redux";
import strTranslation from "../../../assets/lang/strings";
import loc from "../../../localization/Localization";

import {
	getSavedSessionToastMessage,
	setOmqSmqToPlannedTherapySession,
	transformSessionFormValuesToPlannedEventSensor,
} from "../../../tracker/therapySession/helpers/therapySessionHelpers";
import {ActivityTypes, PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import {groupTherapyActions} from "../../redux/groupTherapyActions";
import groupTherapySDK from "../../helpers/groupTherapySdk";
import {getTherapySessionData} from "../../../tracker/therapySession/redux/therapySessionSelectors";
import {mergeSensorDataAndClients} from "../../helpers/groupTherapyHelpers";
import moment from "moment";
import {editGroupTherapySessionSaga} from "./editGroupTherapySessionSaga";

const log = createLogger("saveGroupTherapySessionSaga", SentryTags.GroupTherapy);

/**
 * This saga will handle group registration and sensor creation or update
 * Steps:
 * 1. Transform `formValues` into `planned_event_entry`
 * 2. Create or update omq, smq and session sensor based on client members
 * 3. Create group registration with form values and given sensorIds
 */
function* saveGroupTherapySessionSaga(action: ActionType<typeof groupTherapyActions.saveGroupTherapySession.request>) {
	const {group, formValues, isEdit} = action.payload;
	const token: string = yield call(getSessionId);
	const sessionData: SensorDatum<PlannedEventEntry> = yield select(getTherapySessionData);

	const isOmqEnabled: boolean = formValues.omqToogle;
	const isSmqEnabled: boolean = formValues.smqToogle;

	// 1. Transform session form values into planned event entry
	const sessionSensor: BaseSensorData<BasePlannedEventEntry> = yield call(
		transformSessionFormValuesToPlannedEventSensor,
		formValues,
		isEdit ? sessionData.id : undefined,
	);

	if (isEdit) {
		// Edit registration plan if it's an edit action
		yield call(editGroupTherapySessionSaga, group, sessionSensor, isOmqEnabled, isSmqEnabled);
	} else {
		try {
			// 2. Create or update omq, smq, and session
			const saveTherapySessionResponse = yield call(
				saveTherapySession,
				group.clients,
				sessionSensor,
				isOmqEnabled,
				isSmqEnabled,
			);
			const createdSensorIds: string[] = saveTherapySessionResponse.createdSensorIds;
			const plannedTherapySession: BaseSensorData<BasePlannedEventEntry> =
				saveTherapySessionResponse.plannedTherapySession;

			// 3. Create group registration
			yield apiCallSaga(groupTherapySDK.createRegistration, token, group.id, {
				sensorIds: createdSensorIds,
				title: formValues.title,
				dueDate: moment(formValues.startTime).toDate(),
				details: {...plannedTherapySession, startTime: moment(formValues.startTime).toDate()},
				type: RegistrationType.SESSION,
			});

			yield put(groupTherapyActions.saveGroupTherapySession.success());
			yield put(groupTherapyActions.fetchGroupRegistrationList.request({id: group.id}));
			// Clear fetched registration
			yield put(groupTherapyActions.clearFetchedRegistrationDetails());

			// Show success toast message
			const successToastText = yield call(getSavedSessionToastMessage, formValues);
			yield put(toastActions.addToast({message: successToastText, type: "info"}));
		} catch (err) {
			log.captureException(err, {message: "Failed to save group registration plan."});
			yield put(groupTherapyActions.saveGroupTherapySession.failure(err));

			// Show error toast message
			yield put(
				toastActions.addToast({
					message: loc.formatMessage(strTranslation.GRAPHS.toast.save.fail),
					type: "error",
				}),
			);
		}
	}
}

function* saveTherapySession(
	clients: GroupTherapyMember[],
	plannedTherapySession: BaseSensorData<BasePlannedEventEntry>,
	isOmqEnabled: boolean,
	isSmqEnabled: boolean,
) {
	const token: string = yield call(getSessionId);
	let bulkSessionCreationPayload: GroupTherapySensor<BasePlannedEventEntry>[] = yield call(
		mergeSensorDataAndClients,
		plannedTherapySession,
		clients,
	);

	try {
		if (isOmqEnabled) {
			const omqSensor: BaseSensorData<BasePlannedEventEntry> = yield call(
				setOmqSmqToPlannedTherapySession,
				plannedTherapySession,
				ActivityTypes.FILL_OMQ,
			);
			const bulkOmqPayload: GroupTherapySensor<BasePlannedEventEntry>[] = yield call(
				mergeSensorDataAndClients,
				omqSensor,
				clients,
			);
			const plannedOmqs: SensorDataResolvedResponse<BasePlannedEventEntry>[] = yield apiCallSaga(
				groupTherapySDK.createBulkSensors,
				token,
				bulkOmqPayload,
			);

			bulkSessionCreationPayload = bulkSessionCreationPayload.map((sessionPayload, index) => {
				const plannedOmq = plannedOmqs[index];

				if (plannedOmq && plannedOmq.uri) {
					return {
						...sessionPayload,
						value: {
							...sessionPayload.value,
							plannedOmq: {
								"%uri": plannedOmq.uri,
							},
						},
					};
				}

				return sessionPayload;
			});

			/**
			 * assign the omq URI to the group therapy registration
			 * visually it just used for a switch button (read: boolean / on-off)
			 * so the URI id doesn't matter
			 */
			plannedTherapySession = {
				...plannedTherapySession,
				value: {
					...plannedTherapySession.value,
					plannedOmq: {
						"%uri": plannedOmqs?.[0]?.uri,
					},
				},
			};
		}

		if (isSmqEnabled) {
			const smqSensor: BaseSensorData<BasePlannedEventEntry> = yield call(
				setOmqSmqToPlannedTherapySession,
				plannedTherapySession,
				ActivityTypes.FILL_SMQ,
			);
			const bulkSmqPayload: GroupTherapySensor<BasePlannedEventEntry>[] = yield call(
				mergeSensorDataAndClients,
				smqSensor,
				clients,
			);
			const plannedSmqs: SensorDataResolvedResponse<BasePlannedEventEntry>[] = yield apiCallSaga(
				groupTherapySDK.createBulkSensors,
				token,
				bulkSmqPayload,
			);

			bulkSessionCreationPayload = bulkSessionCreationPayload.map((sessionPayload, index) => {
				const plannedSmq = plannedSmqs[index];

				if (plannedSmq && plannedSmq.uri) {
					return {
						...sessionPayload,
						value: {
							...sessionPayload.value,
							plannedSmq: {
								"%uri": plannedSmq.uri,
							},
						},
					};
				}

				return sessionPayload;
			});

			/**
			 * assign the smq URI to the group therapy registration
			 * visually it just used for a switch button (read: boolean / on-off)
			 * so the URI id doesn't matter
			 */
			plannedTherapySession = {
				...plannedTherapySession,
				value: {
					...plannedTherapySession.value,
					plannedSmq: {
						"%uri": plannedSmqs?.[0]?.uri,
					},
				},
			};
		}

		const results: SensorDataResolvedResponse<BasePlannedEventEntry>[] = yield apiCallSaga(
			groupTherapySDK.createBulkSensors,
			token,
			bulkSessionCreationPayload,
		);

		const createdSensorIds: string[] = results.map((result) => {
			return result.id;
		});

		return {
			createdSensorIds,
			plannedTherapySession,
		};
	} catch (err) {
		log.captureException(err, {message: "Failed to create session with new OMQ or SMQ."});
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.saveGroupTherapySession.request, saveGroupTherapySessionSaga);
}
