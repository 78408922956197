import {call, put, select, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {
	authActions,
	getPasswordResetTwoFACodeAttemptCount,
	getPasswordResetTwoFARecoveryCodeAttemptCount,
} from "../redux";
import authSDK from "../helpers/authSDK";
import {history} from "visual/App";
import {toastActions} from "../../toaster/redux/toastAction";
import {passwordResetOTPRequired} from "../helpers";
import * as twoFASDKHelpers from "../../twoFactorAuthentication/helpers/twoFASDKHelpers";
import * as twoFAHelpers from "../../twoFactorAuthentication/helpers/twoFAHelpers";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {TwoFAInvalidOTPError, TwoFASessionExpired, AccountBlockedError} from "@sense-os/goalie-js/dist/twoFA/types";
import {blockLogin} from "../helpers/authStorage";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("passwordResetSaga", SentryTags.Authentication);

function* handlePasswordReset(action: ActionType<typeof authActions.resetPassword.request>) {
	const {email} = action.payload;
	try {
		yield call(authSDK.requestPasswordReset, email);

		yield put(authActions.resetPassword.success({}));
	} catch (error) {
		log.captureException(error);
		yield put(authActions.resetPassword.failure({error}));
	}
}

function* handleChangePassword(action: ActionType<typeof authActions.changePassword.request>) {
	const {uid, token, password} = action.payload;

	try {
		if (passwordResetOTPRequired()) {
			history.push(`/auth/password-reset/complete/${uid}/${token}/otp`, {newPassword: password});
			return;
		}
		yield call(authSDK.resetPasswordConfirm, uid, token, password);

		yield put(authActions.changePassword.success({}));
		yield put(
			toastActions.addToast({
				message: strTranslation.AUTH.password.reset.complete.success.toast,
				type: "success",
			}),
		);
		history.push("/auth/login");
	} catch (error) {
		yield put(authActions.changePassword.failure({error}));
		yield put(
			toastActions.addToast({message: strTranslation.AUTH.password.reset.complete.failure.toast, type: "error"}),
		);
	}
}

const MAX_ATTEMPT_COUNT = 2;
function* handlePasswordResetUsing2FACode(action: ActionType<typeof authActions.resetPassword2FACode.request>) {
	const {uid, token, password, code} = action.payload;

	try {
		yield call(twoFASDKHelpers.resetPasswordOTP, token, uid, password, code, "totp");

		yield put(authActions.resetPassword2FACode.success());
		yield put(
			toastActions.addToast({
				message: strTranslation.AUTH.password.reset.complete.success.toast,
				type: "success",
			}),
		);
		history.push("/auth/login");
	} catch (error) {
		if (error instanceof TwoFAInvalidOTPError) {
			const attemptCount = yield select(getPasswordResetTwoFACodeAttemptCount);
			const attemptCount2 = yield select(getPasswordResetTwoFARecoveryCodeAttemptCount);

			if (attemptCount + attemptCount2 < MAX_ATTEMPT_COUNT) {
				const attemptLeft = MAX_ATTEMPT_COUNT - attemptCount - attemptCount2;
				const message = twoFAHelpers.getInvalidAttemptErrorMessage(attemptLeft);

				yield put(
					authActions.resetPassword2FACode.failure({
						error: new Error(message),
					}),
				);
			} else {
				// Set data in localstorage to make sure portal still blocked upon refreshing
				yield call(blockLogin, "2FA_passwordReset");
				// Show blocked page
				yield put(authActions.blockLogin());
			}
			return;
		}

		if (error instanceof AccountBlockedError) {
			// Set data in localstorage to make sure portal still blocked upon refreshing
			yield call(blockLogin, "2FA_passwordReset");
			// Show blocked page
			yield put(authActions.blockLogin());

			yield put(authActions.resetPassword2FACode.failure({error: null}));
			return;
		}

		if (error instanceof TwoFASessionExpired) {
			// Redirect user to login page
			history.push("/auth/login");

			yield put(authActions.resetPassword2FACode.failure({error: null}));
			return;
		}

		log.captureException(error);
		yield put(authActions.resetPassword2FACode.failure({error}));
		yield put(
			toastActions.addToast({message: strTranslation.AUTH.password.reset.complete.failure.toast, type: "error"}),
		);
	}
}

function* handlePasswordResetUsing2FARecoveryCode(
	action: ActionType<typeof authActions.resetPassword2FARecoveryCode.request>,
) {
	const {uid, token, password, code} = action.payload;

	try {
		yield call(twoFASDKHelpers.resetPasswordOTP, token, uid, password, code, "static");

		yield put(authActions.resetPassword2FARecoveryCode.success());
		yield put(
			toastActions.addToast({
				message: strTranslation.AUTH.password.reset.complete.success.toast,
				type: "success",
			}),
		);
		history.push("/auth/login");
	} catch (error) {
		if (error instanceof TwoFAInvalidOTPError) {
			const attemptCount = yield select(getPasswordResetTwoFACodeAttemptCount);
			const attemptCount2 = yield select(getPasswordResetTwoFARecoveryCodeAttemptCount);

			if (attemptCount + attemptCount2 < MAX_ATTEMPT_COUNT) {
				const attemptLeft = MAX_ATTEMPT_COUNT - attemptCount - attemptCount2;
				const message = twoFAHelpers.getInvalidAttemptErrorMessage(attemptLeft);

				yield put(
					authActions.resetPassword2FARecoveryCode.failure({
						error: new Error(message),
					}),
				);
			} else {
				// Set data in localstorage to make sure portal still blocked upon refreshing
				yield call(blockLogin, "2FA_passwordReset");
				// Show blocked page
				yield put(authActions.blockLogin());
			}
			return;
		}

		if (error instanceof AccountBlockedError) {
			// Set data in localstorage to make sure portal still blocked upon refreshing
			yield call(blockLogin, "2FA_passwordReset");
			// Show blocked page
			yield put(authActions.blockLogin());

			yield put(authActions.resetPassword2FARecoveryCode.failure({error: null}));
			return;
		}

		if (error instanceof TwoFASessionExpired) {
			// Redirect user to login page
			history.push("/auth/login");

			yield put(authActions.resetPassword2FARecoveryCode.failure({error: null}));
			return;
		}

		yield put(authActions.resetPassword2FARecoveryCode.failure({error}));
		yield put(
			toastActions.addToast({message: strTranslation.AUTH.password.reset.complete.failure.toast, type: "error"}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(authActions.resetPassword.request), handlePasswordReset);
	yield takeEvery(getType(authActions.changePassword.request), handleChangePassword);
	yield takeEvery(getType(authActions.resetPassword2FARecoveryCode.request), handlePasswordResetUsing2FARecoveryCode);
	yield takeEvery(getType(authActions.resetPassword2FACode.request), handlePasswordResetUsing2FACode);
}
