import {BrowserEvents} from "constants/BrowserEvents";
import {eventChannel} from "redux-saga";
import {call, select, take, put} from "redux-saga/effects";
import {getActiveCall, getCallerNamesFromActiveCall, isCallWindowCrashed} from "redux/videoCall/VideoCallSelectors";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {resetCallWindow} from "services/chat/video/CallWindow";
import {AuthUser} from "../../../auth/authTypes";
import {getAuthUser} from "../../../auth/redux";
import {isSafariBrowser} from "../../../browserWarning/helpers/browserWarningUtils";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import createLogger from "../../../logger/createLogger";
import {toastActions} from "../../../toaster/redux";
import {shouldSendCancelSignal} from "../../helpers/callHelpers";
import {clearMainWindowOnCloseChannel} from "./mainWindowClosedSaga";
import {restoreChatPresenceToOnline} from "./updateChatPresence";
import {playCallWindowSound} from "./playCallWindowSound";
import {TerminationReason} from "@sense-os/goalie-js";
import {isCallTimedOut} from "../../helpers/callUtils";
import {callActions} from "../../redux/callActions";
import {sidebarNavActions} from "../../../sidebarNav/redux/sidebarNavActions";
import strTranslation from "../../../assets/lang/strings";

const log = createLogger("callWindowSaga", SentryTags.VideoCall);

export function* onCallWindowClosedSaga(callWindow: Window) {
	const callWindowCloseChannel = yield call(createCallWindowOnCloseChannel, callWindow);

	yield take(callWindowCloseChannel);

	yield call(onCallWindowClosedHandler);

	callWindowCloseChannel.close();
}

/**
 * Removes all listeners, chat presence, and send proper signal to the call room
 */
export function* onCallWindowClosedHandler() {
	log.addBreadcrumb({message: "Call window closed"});
	// Restore chat presence from BUSY to ONLINE
	yield call(restoreChatPresenceToOnline);
	// Send cancellation/termination signal
	yield call(hangupCall);
	// Play sound to inform user that the call has ended
	yield call(playCallWindowSound);
	// Reset call window instance
	yield call(resetCallWindow);
	// Reset main window "onclose" event
	yield call(clearMainWindowOnCloseChannel);

	// Bring back the sidebar at the end of a call
	yield put(sidebarNavActions.expandSidebar());
}

function* hangupCall() {
	const authUser: AuthUser = yield select(getAuthUser);
	const activeCall: ActiveCall = yield select(getActiveCall);

	if (!activeCall) {
		return;
	}

	// Check if need to cancel or terminate
	const shouldCancel = yield call(shouldSendCancelSignal, activeCall, authUser.id);

	// Cancel when auth user is initiating a call but not answered
	if (shouldCancel && activeCall.ongoingCounterPartId) {
		// Check if the call window was closed by the user, OR because the call has timed out
		if (!isCallTimedOut(activeCall, activeCall.ongoingCounterPartId)) {
			// If call window was closed by the user, show a toast
			// "You have cancelled the call"
			yield put(toastActions.addToast({type: "info", message: strTranslation.CHAT.video.you_cancelled.toast}));
		}
		// Send cancellation signal
		yield put(callActions.cancelCall(activeCall.ongoingCounterPartId));
	} else {
		// Terminate call when there are no other participant(s) left in the call, OR when auth user leaves
		// an ongoing call with other participant(s)

		// Show generic message: "Call with <names> has ended"
		const callerNames = yield select(getCallerNamesFromActiveCall);
		yield put(
			toastActions.addToast({
				type: "info",
				message: strTranslation.CHAT.video.call_ended.toast,
				localizationPayload: {name: callerNames},
			}),
		);

		// Set termination reason. If the call window crashed, set the termination to AbnormalHangUp
		const callWindowCrashed = yield select(isCallWindowCrashed);
		const terminationReason = callWindowCrashed ? TerminationReason.AbnormalHangUp : TerminationReason.NormalHangUp;

		// Send termination signal
		yield put(callActions.terminateCall(terminationReason));
	}
}

function* createCallWindowOnCloseChannel(callWindow: Window) {
	/**
	 * Somehow on Safari browsers, the `BrowserEvents.BEFORE_UNLOAD` window event will be exectued
	 * after the event is registered. This is a hacky solution for preventing safari to close video call window without any reason.
	 *
	 * TODO: Find another solution beside this hacky solution
	 */
	const isSafari = yield call(isSafariBrowser);
	let canEmitEvent = !isSafari;

	return eventChannel((emit) => {
		callWindow.addEventListener(
			BrowserEvents.BEFORE_UNLOAD,
			(evt) => {
				if (canEmitEvent) {
					log.addBreadcrumb({
						message: "Call window is closed via beforeunload event, emitting event channel",
					});
					evt.stopPropagation();
					emit(evt);
				} else {
					canEmitEvent = true;
				}
			},
			{once: true},
		);

		// Gradually check if call window is still opened or not.
		// This is a hack approach to ensure that we can end an ongoing call in mobile devices.
		// It's because the unreliability of `beforeunload`, `unload`, and `pagehide` window event
		// where it won't always be triggered when window is closed.
		// See https://developers.google.com/web/updates/2018/07/page-lifecycle-api
		// See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
		const intervalId = window.setInterval(() => {
			if (callWindow.closed) {
				log.addBreadcrumb({message: "Call window is closed via interval checks, emitting event channel"});
				emit({});
			}
		}, 1000);

		return () => {
			window.clearInterval(intervalId);
		};
	});
}
