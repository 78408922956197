import {LoadingState} from "constants/redux";
import {createSelector} from "reselect";

import {AppState} from "../../ts/redux/AppState";
import {getCalendarSettings} from "../../userSettings/redux/userSettingsSelectors";

import {
	weeklyShiftDate,
	shiftDate,
	getCalendarNamesFromProfiles,
	getCalendarsFromProfiles,
	getCalendarById as getCalendarByCalendarId,
} from "../helpers/calendarHelpers";
import {DateString, TimeView, DayColumns, Calendar, CalendarEvent} from "../calendarTypes";

export const getCalendarState = (state: AppState) => state.calendar;

/** Currently displayed date (it changes when the user navigates through time) */
export const getSelectedDate = createSelector(getCalendarState, (state) => state.selectedDate);

export const getSelectedTime = createSelector(getCalendarState, (state) => state.selectedTime);

export const getSelectedTimeView = createSelector(getCalendarState, (state) => state.selectedTimeView);

export const isWeekendDaysShown = createSelector(getCalendarState, (state) => state.isWeekendShown);

export const getCalendarAccount = createSelector(getCalendarState, (state) => state.calendarAccount);

export const getCalendarEvents = createSelector(getCalendarState, (state) => state.calendarEvents);

export const isLoadingCalendar = createSelector(
	getCalendarState,
	(state) => state.loadingState === LoadingState.LOADING,
);

export const isCreatingEvent = createSelector(
	getCalendarState,
	(state) => state.createLoadingState === LoadingState.LOADING,
);

export const isUpdatingEvent = createSelector(
	getCalendarState,
	(state) => state.updateLoadingState === LoadingState.LOADING,
);

export const isRemovingEvent = createSelector(
	getCalendarState,
	(state) => state.removeLoadingState === LoadingState.LOADING,
);

/**
 * Get dates to be shown in calendar view
 */
export const getShownDates = createSelector(
	getSelectedDate,
	getSelectedTimeView,
	isWeekendDaysShown,
	(selectedDate, selectedTimeView, isWeekendShown): DateString[] => {
		// Day columns length
		let dayColumns: number = DayColumns.ONE_DAY;
		// Is selectedTimeView a `week` view
		const isWeekView = selectedTimeView === TimeView.WEEK;

		if (isWeekView) {
			if (isWeekendShown) {
				dayColumns = DayColumns.SEVEN_DAYS;
			} else {
				dayColumns = DayColumns.FIVE_DAYS;
			}
		}

		return Array.from(new Array(dayColumns)).map((_, x) =>
			isWeekView ? weeklyShiftDate(selectedDate, isWeekendShown, x) : shiftDate(selectedDate, x),
		);
	},
);

export const getCalendarProfiles = createSelector(getCalendarState, (state) => state.calendarAccount?.calendarProfiles);

export const getConnectedCalendars = createSelector(getCalendarProfiles, (profiles) =>
	getCalendarsFromProfiles(profiles),
);

export const isNoCalendarSynced = createSelector(
	getCalendarAccount,
	getCalendarProfiles,
	(calendarAccount, calendarProfiles): boolean => calendarAccount === null || calendarProfiles.length < 1,
);

export const getCalendarColors = createSelector(getCalendarState, (state) => state.calendarColors);

export const getCalendarsVisibility = createSelector(getCalendarState, (state) => state.calendarsVisibility);

export const getCalendarNames = createSelector(getCalendarProfiles, (calendarProfiles): string[] =>
	getCalendarNamesFromProfiles(calendarProfiles),
);

export const getDefaultCalendar = createSelector(
	getConnectedCalendars,
	getCalendarSettings,
	(connectedCalendars, calendarUserSettings): Calendar => {
		const {defaultCalendarId} = calendarUserSettings;
		const firstConnectedCalendar = connectedCalendars?.length > 0 && connectedCalendars[0];
		return (
			connectedCalendars?.find((calendar) => calendar.calendarId === defaultCalendarId) || firstConnectedCalendar
		);
	},
);

export const getCalendarById = createSelector(
	(_state: AppState, calendarId: string) => calendarId,
	getCalendarProfiles,
	(calendarId, calendarProfiles): Calendar => getCalendarByCalendarId(calendarProfiles, calendarId),
);

export const getCalendarByName = createSelector(
	(_state: AppState, calendarName: string) => calendarName,
	getConnectedCalendars,
	(calendarName, connectedCalendars): Calendar =>
		connectedCalendars.find((calendar) => calendar.calendarName === calendarName),
);

export const getSessionData = createSelector(getCalendarState, (state) => state.sessionData);

export const getCalendarEventById = createSelector(
	(_state: AppState, eventId: string) => eventId,
	getCalendarEvents,
	(eventId, eventsRecord) => {
		const allEvents: CalendarEvent[] = [];
		const allKeys: string[] = Object.keys(eventsRecord).map((key) => key);
		allKeys.forEach((date) => {
			eventsRecord[date].forEach((event) => allEvents.push(event));
		});

		return allEvents.find((event) => event.eventId === eventId);
	},
);

export const isSessionLoading = createSelector(
	getCalendarState,
	(state) => state.sessionLoadingState === LoadingState.LOADING,
);

export const isViewScrollDisabled = createSelector(getCalendarState, (state) => state.isScrollDisabled);
