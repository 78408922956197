/** Author: leo Date: 28/06/2021 */

import {FC} from "react";
import Button from "@material-ui/core/Button";
import {NDColors} from "../../material-ui/NDColors";
import {FormattedMessage} from "react-intl";
import {useDispatch} from "react-redux";
import {versionCheckActions} from "../redux/versionCheckAction";
import strTranslation from "../../assets/lang/strings";

interface Props {
	clickAction: () => void;
}
export const UpdatePortalButton: FC<Props> = () => {
	const dispatch = useDispatch();

	return (
		<>
			<Button
				variant="outlined"
				style={{backgroundColor: NDColors.DEFAULT}}
				onClick={() => {
					dispatch(versionCheckActions.displayUpdateConfirmation());
				}}
			>
				<FormattedMessage id={strTranslation.VERSION.new_version.toast.button} />
			</Button>
		</>
	);
};
