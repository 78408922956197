import {getType} from "typesafe-actions";
import analyticsService from "../../../analytics/AnalyticsService";
import {therapySessionActions, TherapySessionActionType} from "./therapySessionActions";

const trackedActions = [
	therapySessionActions.saveTherapySession.request,
	therapySessionActions.saveTherapySession.success,
	therapySessionActions.saveTherapySession.failure,
].map(getType);

const properties = (action: TherapySessionActionType) => {
	switch (action.type) {
		case getType(therapySessionActions.saveTherapySession.request):
			return {formValues: action.payload.formValues};
		default:
			return null;
	}
};

export const therapySessionEvents = analyticsService.trackReduxActions(trackedActions, properties);
