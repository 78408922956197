import {all, put, takeEvery, call, select} from "@redux-saga/core/effects";
import {LoadingState} from "constants/redux";
import {ActionType, getType} from "typesafe-actions";

import {getSessionId} from "../../auth/helpers/authStorage";
import {contactActions} from "../../contacts/redux/contactAction";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";

import {translateTreatmentComplaints, translateTreatmentTypes} from "../treatmentStatusHelpers";
import {treatmentStatusActions} from "../redux/treatmentStatusActions";
import treatmentStatusSdk from "../treatmentStatusSdk";
import {SdkTreatment, Treatment} from "../treatmentStatusTypes";
import {UserUtils} from "utils/UserUtils";
import {TreatmentPersonnel} from "@sense-os/goalie-js";
import {getAllDetails} from "../redux/treatmentStatusSelectors";
import {getContactByIdFn} from "../../contacts/redux/contactSelectors";
import {Contact} from "../../contacts/contactTypes";

const log = createLogger("TreatmentStatus -- load treatments saga", SentryTags.TreatmentStatus);

/**
 * This will load treatmentTypes and treatmentComplaints of an individual treatment.
 */
function* loadTreatmentDetails(action: ActionType<typeof treatmentStatusActions.loadTreatmentDetails.request>) {
	const token: string = yield call(getSessionId);

	try {
		const [treatmentTypes, treatmentComplaints, treatmentPersonnels] = yield all([
			apiCallSaga(treatmentStatusSdk.getTreatmentTypesOfTreatment, token, action.payload.treatmentId),
			apiCallSaga(treatmentStatusSdk.getTreatmentComplaintsOfTreatment, token, action.payload.treatmentId),
			apiCallSaga(treatmentStatusSdk.getTreatmentPersonnelsOfTreatment, token, action.payload.treatmentId),
		]);

		yield put(
			treatmentStatusActions.loadTreatmentDetails.success({
				...action.payload,
				treatmentComplaints: translateTreatmentComplaints(treatmentComplaints),
				treatmentTypes: translateTreatmentTypes(treatmentTypes),
				treatmentPersonnels,
			}),
		);
	} catch (error) {
		log.captureException(error);
		yield put(treatmentStatusActions.loadTreatmentDetails.failure(action.payload));
		return;
	}
}

/**
 * This function will do the required postprocessing after portal fetch
 * treatments. For example, parsing the patientName and patientId.
 */
export function postProcessFetchedTreatments(treatments: SdkTreatment[]) {
	// There might be multiple treatment for same client in treatments object,
	// but we assume the one with the highest id is the latest one.
	// Thus we sort the treatment in ascending id order, and use reduce function below to accumulate
	// the correct treatment.
	treatments.sort((firstTreatment, secondTreatment) => firstTreatment.id - secondTreatment.id);
	const treatmentMaps = treatments
		// Filter out `null` patient. Possibly caused by deleted patient account: https://github.com/senseobservationsystems/web-getgoalie/issues/4240
		.filter((treatment) => !!treatment.patient)
		.reduce((maps, sdkTreatment) => {
			// The treatment types and client complaints will be updated by loadTreatmentTypesAndComplaints saga
			const patientId = typeof sdkTreatment.patient === "number" ? sdkTreatment.patient : sdkTreatment.patient.id;
			const patientName =
				typeof sdkTreatment.patient === "number"
					? undefined
					: UserUtils.getName(sdkTreatment.patient.firstName, sdkTreatment.patient.lastName, "");

			const treatment: Treatment = {
				...sdkTreatment,
				patient: patientId,
				patientName,
				treatmentTypes: [],
				clientComplaints: [],
				personnels: [],
			};

			maps[patientId] = treatment;
			return maps;
		}, {} as {[id: number]: Treatment});

	return treatmentMaps;
}

/**
 * This is the saga that will be fired when portal first start,
 * to fetch all the ongoing treatments as the necessary step
 * to enable time tracking feature.
 */
function* loadOngoingTreatmentOnStart() {
	const token: string = getSessionId();

	let treatments: SdkTreatment[] = [];
	try {
		yield put(treatmentStatusActions.setFetchingAllTreatmentsState(LoadingState.LOADING));
		treatments = yield apiCallSaga(treatmentStatusSdk.getAllTreatments, token);
	} catch (error) {
		yield put(treatmentStatusActions.setFetchingAllTreatmentsState(LoadingState.ERROR));
		log.captureException(error);
		return;
	}

	const treatmentMaps = postProcessFetchedTreatments(treatments);
	yield put(treatmentStatusActions.updateTreatmentMaps(treatmentMaps));
	yield put(treatmentStatusActions.setFetchingAllTreatmentsState(LoadingState.LOADED));

	// At the end, also load all personnels information
	yield put(treatmentStatusActions.loadAllTreatmentPersonnels.request());
}

/**
 * Saga to load all treatment personnels based on all treatment available in our redux state.
 * This should be temporary and should be gone within the next few weeks! (Probably Jan 2022)
 * GH Issue: https://github.com/senseobservationsystems/web-getgoalie/issues/4258
 */
function* loadAllTreatmentPersonnels(_: ActionType<typeof treatmentStatusActions.loadAllTreatmentPersonnels.request>) {
	const clientTreatmentMap: {[clientId: number]: Treatment} = yield select(getAllDetails);
	try {
		const idAndTreatmentPairs = Object.entries(clientTreatmentMap);
		const idAndPersonnelsPairs: [number, TreatmentPersonnel[]][] = [];

		for (let idx = 0; idx < idAndTreatmentPairs.length; idx++) {
			const [clientIdStr, treatment] = idAndTreatmentPairs[idx];
			const clientId = Number(clientIdStr);

			// No need to load it if it already exists
			if (!!treatment.personnels && treatment.personnels.length > 0) {
				idAndPersonnelsPairs.push([clientId, treatment.personnels]);
			} else {
				const personnels = yield call(loadTreatmentPersonnel, treatment.id);
				idAndPersonnelsPairs.push([clientId, personnels]);
			}
		}

		// Store them in redux
		yield put(treatmentStatusActions.loadAllTreatmentPersonnels.success(Object.fromEntries(idAndPersonnelsPairs)));
	} catch (err) {
		log.captureException(err);
		yield put(treatmentStatusActions.loadAllTreatmentPersonnels.failure(err));
	}
}

/**
 * Fetches treatment personnels by treatment id. The function should only be used to fetch multiple treatment personnels simultaneously.
 * Returns empty array if something wrong happened because we don't want to break other requests.
 *
 * @param treatmentId
 * @returns
 */
export function* loadTreatmentPersonnel(treatmentId: number) {
	const token: string = getSessionId();
	try {
		return yield apiCallSaga(treatmentStatusSdk.getTreatmentPersonnelsOfTreatment, token, Number(treatmentId));
	} catch {
		return [];
	}
}

/**
 * This will fetch all treatments of a client of given hash id.
 */
function* loadTreatmentsOfClient(action: ActionType<typeof treatmentStatusActions.loadTreatmentsOfClient.request>) {
	const {clientId} = action.payload;
	const contact: Contact = yield select(getContactByIdFn(clientId));
	const clientHashId = contact?.hashId;

	const token: string = getSessionId();
	let treatments: SdkTreatment[] = [];
	try {
		treatments = yield apiCallSaga(treatmentStatusSdk.getTreatmentsOfAClient, token, clientHashId);
	} catch (error) {
		yield put(treatmentStatusActions.loadTreatmentsOfClient.failure(action.payload));
		log.captureException(error);
		return;
	}

	const treatmentMaps = postProcessFetchedTreatments(treatments);

	// If client has treatment, load its details.
	if (treatmentMaps[clientId]) {
		// Update client treatment to the latest one
		yield put(treatmentStatusActions.updateTreatmentMaps(treatmentMaps));
		// Load other details: treatment types and treatment complaints
		yield put(
			treatmentStatusActions.loadTreatmentDetails.request({
				clientId: clientId,
				treatmentId: treatmentMaps[clientId].id,
			}),
		);
	} else {
		// The success action is only dispatched here if no treatment exists,
		// as it's purpose currently is to set the "fetch loading state" to "loaded".
		yield put(treatmentStatusActions.loadTreatmentsOfClient.success({clientId}));
	}
}

/**
 * This function will act as respond to whenever a new contact is added to portal.
 */
function* loadTreatmentOfANewContact(action: ActionType<typeof contactActions.addContact>) {
	const {contact} = action.payload;
	yield put(treatmentStatusActions.loadTreatmentsOfClient.request({clientId: contact.id}));
}

/**
 * This saga will load all treatments information on start.
 */
export default function* () {
	yield takeEvery(getType(treatmentStatusActions.loadTreatmentDetails.request), loadTreatmentDetails);
	yield takeEvery(getType(treatmentStatusActions.loadAllOngoingTreatmentsOnStart), loadOngoingTreatmentOnStart);
	yield takeEvery(getType(treatmentStatusActions.loadTreatmentsOfClient.request), loadTreatmentsOfClient);
	yield takeEvery(getType(treatmentStatusActions.loadAllTreatmentPersonnels.request), loadAllTreatmentPersonnels);
	yield takeEvery(getType(contactActions.addContact), loadTreatmentOfANewContact);

	// Load all ongoing treatments when portal starts.
	yield put(treatmentStatusActions.loadAllOngoingTreatmentsOnStart());
}
