import {promisify} from "../promisify";

const queue: {[name: string]: string[]} = {};
const subscriptions: {[id: string]: [() => Promise<any>, (reason?: any) => void]} = {};
const isExecuting: {[name: string]: number} = {};

function recursiveExecute(queueId: string): Promise<void> {
	if (queue[queueId].length === 0) {
		isExecuting[queueId] -= 1;
		return Promise.resolve();
	}

	const id = queue[queueId][0];
	queue[queueId].shift();

	const [fn, reject] = subscriptions[id];
	delete subscriptions[id];

	return fn()
		.catch((e) => {
			reject(e);
		})
		.then(() => {
			return recursiveExecute(queueId);
		});
}

function execute(
	queueName: string,
	maxConcurrent: number,
	fn: () => Promise<any>,
	reject: (reason?: any) => void,
): Promise<void> {
	const id = `${new Date().getTime()}-${Math.random().toString(36).substr(2)}`;
	subscriptions[id] = [fn, reject];
	queue[queueName] = queue[queueName] || [];
	queue[queueName].push(id);

	if (!!isExecuting[queueName] && isExecuting[queueName] >= maxConcurrent) return;

	isExecuting[queueName] = isExecuting[queueName] || 0;
	isExecuting[queueName] += 1;

	return recursiveExecute(queueName);
}

interface Queue {
	push: <T>(fn: () => T | Promise<T>) => Promise<T>;
	wrapFnInQueue: <T, U extends any[]>(fn: (...args: U) => T | Promise<T>) => (...args: U) => Promise<T>;
}

export function makeQueue(queueName: string, maxConcurrent: number): Queue {
	return {
		push<T>(fn: () => T | Promise<T>): Promise<T> {
			const promisedFn = promisify(fn);
			return new Promise<T>((resolve, reject) => {
				execute(queueName, maxConcurrent, () => promisedFn().then(resolve), reject);
			});
		},
		wrapFnInQueue<T, U extends any[]>(fn: (...args: U) => T | Promise<T>): (...args: U) => Promise<T> {
			const promisedFn = promisify(fn);
			return (...args: U) =>
				new Promise<T>((resolve, reject) => {
					execute(queueName, maxConcurrent, () => promisedFn(...args).then(resolve), reject);
				});
		},
	};
}
