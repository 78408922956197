import {ActionType, createAction} from "typesafe-actions";
import {Interval} from "../tracking/TrackingTypes";
import {Target, TargetName} from "./SensorTargetTypes";
import {ThunkAction} from "redux-thunk";
import {DISC} from "IoC/DISC";
import Localization from "../../../localization/Localization";
import {NotFoundError, Target as BaseTarget, TargetResponse} from "@sense-os/goalie-js";
import {AppState} from "../AppState";
import moment from "moment";
import {TIME_UNITS} from "constants/time";
import {getActiveTarget} from "./SensorTargetSelectors";
import {getCurrentInterval} from "../tracking/TrackingSelector";
import {loadAllData} from "../tracking/TrackingAction";
import {UIAction} from "../UI/UIAction";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {toastActions, ToastActionTypes} from "../../../toaster/redux/toastAction";
import {isUnauthorizedError} from "../../../errorHandler/errorHandlerUtils";
import {apiCall} from "../../../helpers/apiCall/apiCall";
import {getSelectedContactId} from "../../../contacts/redux/contactSelectors";
import strTranslation from "../../../assets/lang/strings";

/**
 * The word `target` in the context of this file represents target for a sensor.
 * It is used to to indicates if a sensor has passed a target or not.
 *
 * @see {Sensors}
 * @see {Target}
 */
export namespace SensorTargetActions {
	const c: string = "[SensorTargetActions]";
	const log = createLogger("SensorTargetActions", SentryTags.Tracking);

	const targetVersion = {
		[TargetName.STEP_COUNT]: 1,
	};

	//
	// LOADING ACTIVE TARGET
	//

	/** Portal is fetching target history */
	export const loadingActiveTarget = createAction(
		"SensorTarget/LOADING_ACTIVE_TARGET",
		(userId: number, targetName: TargetName) => ({userId, targetName}),
	)();

	/** Portal done fetching target history */
	export const loadedActiveTarget = createAction(
		"SensorTarget/LOADED_ACTIVE_TARGET",
		(userId: number, targetName: TargetName, activeTarget: Target) => ({userId, targetName, activeTarget}),
	)();

	/** Portal fails to fetch target history */
	export const errorLoadingActiveTarget = createAction(
		"SensorTarget/ERROR_LOADING_ACTIVE_TARGET",
		(userId: number, targetName: TargetName, err: any) => ({userId, targetName, err}),
	)();

	//
	// LOADING TARGET HISTORY
	//

	/** Portal is fetching target history */
	export const loadingTargetHistory = createAction(
		"SensorTarget/LOADING_TARGET_HISTORY",
		(interval: Interval, userId: number, targetName: TargetName) => ({interval, userId, targetName}),
	)();

	/** Portal done fetching target history */
	export const loadedTargetHistory = createAction(
		"SensorTarget/LOADED_TARGET_HISTORY",
		(interval: Interval, userId: number, targetName: TargetName, targetHistories: Target[]) => ({
			interval,
			userId,
			targetName,
			targetHistories,
		}),
	)();

	/** Portal fails to fetch target history */
	export const errorLoadingTargetHistory = createAction(
		"SensorTarget/ERROR_LOADING_TARGET_HISTORY",
		(interval: Interval, userId: number, targetName: TargetName, err: any) => ({interval, userId, targetName, err}),
	)();

	//
	// UPDATE ACTIVE TARGET
	//

	/** Portal is updating active target */
	export const updatingActiveTarget = createAction(
		"SensorTarget/UPDATING_ACTIVE_TARGET",
		(userId: number, targetName: TargetName, target: BaseTarget) => ({userId, targetName, target}),
	)();

	/** Portal done updating active target */
	export const updatedActiveTarget = createAction(
		"SensorTarget/UPDATED_ACTIVE_TARGET",
		(userId: number, targetName: TargetName, target: Target) => ({userId, targetName, target}),
	)();

	/** Portal fails to update active target */
	export const errorUpdatingActiveTarget = createAction(
		"SensorTarget/ERROR_UPDATING_ACTIVE_TARGET",
		(userId: number, targetName: TargetName, target: BaseTarget, err: any) => ({userId, targetName, target, err}),
	)();

	//
	// STOPPING ACTIVE TARGET
	//

	/** Portal is stopping active target */
	export const stoppingActiveTarget = createAction(
		"SensorTarget/STOPPING_ACTIVE_TARGET",
		(userId: number, targetName: TargetName) => ({userId, targetName}),
	)();

	/** Portal done stopping active target */
	export const stoppedActiveTarget = createAction(
		"SensorTarget/STOPPED_ACTIVE_TARGET",
		(userId: number, targetName: TargetName) => ({userId, targetName}),
	)();

	/** Portal fails to stop active target */
	export const errorStoppingActiveTarget = createAction(
		"SensorTarget/ERROR_STOPPING_ACTIVE_TARGET",
		(userId: number, targetName: TargetName, err: any) => ({userId, targetName, err}),
	)();

	//
	//  STEPS CONFIG MODAL
	//

	/** Open config modal */
	export const openConfigModal = createAction("SensorTarget/OPEN_CONFIG_MODAL", (targetName: TargetName) => ({
		targetName,
	}))();

	/** Close config modal */
	export const closeConfigModal = createAction("SensorTarget/CLOSE_CONFIG_MODAL", (targetName: TargetName) => ({
		targetName,
	}))();

	/** combine all typesafe actions above to be union */
	const sensorTargetActions = {
		loadingActiveTarget,
		loadedActiveTarget,
		errorLoadingActiveTarget,

		loadingTargetHistory,
		loadedTargetHistory,
		errorLoadingTargetHistory,

		updatingActiveTarget,
		updatedActiveTarget,
		errorUpdatingActiveTarget,

		stoppingActiveTarget,
		stoppedActiveTarget,
		errorStoppingActiveTarget,

		openConfigModal,
		closeConfigModal,
	};

	/** Union of all action inside `sensorTargetActions` */
	export type SensorTargetAction = ActionType<typeof sensorTargetActions>;
	type SensorTargetThunkAction = ThunkAction<void, AppState, any, SensorTargetAction | ToastActionTypes>;

	//
	// THUNK ACTIONS
	//

	/**
	 * Load active target
	 *
	 * @param {number} userId
	 * @param {TargetName} targetName
	 */
	export const loadActiveTarget = (userId: number, targetName: TargetName): SensorTargetThunkAction => {
		return async (dispatch) => {
			const token: string = getSessionId();

			dispatch(loadingActiveTarget(userId, targetName));
			try {
				const sensorTargetSDK = DISC.getSensorTargetService().sdk;
				const response = await apiCall(
					[sensorTargetSDK, sensorTargetSDK.getActiveTarget],
					token,
					userId,
					targetName,
				);
				log.debug(c, "Loaded active target", response);
				dispatch(loadedActiveTarget(userId, targetName, convertTargetResponse([response])[0]));
			} catch (err) {
				let errorMessage: string = "";
				if (err instanceof NotFoundError) {
					errorMessage = "Active target not found";
					log.debug(c, errorMessage, err);
				} else {
					errorMessage = "Something's wrong!";
					reportErrorToSentry(err, {targetName});

					const failText: string = Localization.formatMessage(
						strTranslation.STEPS_CONFIG.toast.load_active_target.fail,
					);
					dispatch(toastActions.addToast({message: failText, type: "error"}));
				}
				dispatch(errorLoadingActiveTarget(userId, targetName, errorMessage));
			}
		};
	};

	/**
	 * Load target histories
	 *
	 * @param {Interval} interval
	 * @param {number} userId
	 * @param {TargetName} targetName
	 */
	export const loadTargetHistories = (
		interval: Interval,
		userId: number,
		targetName: TargetName,
	): SensorTargetThunkAction => {
		return async (dispatch) => {
			const token: string = getSessionId(),
				timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

			dispatch(loadingTargetHistory(interval, userId, targetName));

			try {
				const sensorTargetSDK = DISC.getSensorTargetService().sdk;
				const response = await apiCall(
					[sensorTargetSDK, sensorTargetSDK.getTargetHistory],
					token,
					userId,
					targetName,
					timezone,
					interval.start.toISOString(),
					interval.end.toISOString(),
				);

				log.debug(c, "Loaded target histories", response);
				dispatch(loadedTargetHistory(interval, userId, targetName, convertTargetResponse(response)));
			} catch (err) {
				reportErrorToSentry(err, {interval, targetName});
				dispatch(errorLoadingTargetHistory(interval, userId, targetName, err));

				const failText: string = Localization.formatMessage(
					strTranslation.STEPS_CONFIG.toast.load_target_history.fail,
				);
				dispatch(toastActions.addToast({message: failText, type: "error"}));
			}
		};
	};

	/**
	 * Convert target response to Target
	 *
	 * @param {TargetResponse[]} targetResponse
	 */
	function convertTargetResponse(targetResponse: TargetResponse[]): Target[] {
		return targetResponse.map((response) => {
			const target: Target = {
				createdBy: response.createdBy,
				endTime: moment(response.endTime || Date.now())
					.endOf(TIME_UNITS.DAY)
					.toDate(),
				startTime: moment(response.startTime).startOf(TIME_UNITS.DAY).toDate(),
				targetName: response.targetName as TargetName,
				notificationEnabled: response.notificationEnabled,
				userId: response.userId,
				value: response.value,
				version: response.version,
			};
			return target;
		});
	}

	/**
	 * Update target
	 *
	 * @param {number} userId
	 * @param {TargetName} targetName
	 * @param {Target} target
	 */
	export const updateTarget = (targetName: TargetName, target: Partial<Target>): SensorTargetThunkAction => {
		return async (dispatch, getState) => {
			const token: string = getSessionId(),
				userId: number = getSelectedContactId(getState()),
				interval: Interval = getCurrentInterval(getState()),
				oldTarget: Target = getActiveTarget(targetName)(getState());

			const newTarget: BaseTarget = {
				targetName,
				notificationEnabled: target.notificationEnabled!,
				userId,
				value: target.value,
				version: targetVersion[targetName],
			};

			dispatch(closeConfigModal(targetName));

			// We don't need to update anything if old target and new target has the same value
			if (
				oldTarget &&
				oldTarget.value.steps === newTarget.value.steps &&
				oldTarget.notificationEnabled === newTarget.notificationEnabled
			) {
				log.debug(c, "No need to update. Old target and new target has the same values");
				return;
			}

			dispatch(updatingActiveTarget(userId, targetName, newTarget));
			try {
				const sensorTargetSDK = DISC.getSensorTargetService().sdk;
				const response = await apiCall([sensorTargetSDK, sensorTargetSDK.postTarget], token, newTarget);
				log.debug(c, "Active target updated", response);
				const targetResult: Target = convertTargetResponse([response])[0];
				dispatch(updatedActiveTarget(userId, targetName, targetResult));
				dispatch(loadAllData(interval, userId));

				const successText: string = Localization.formatMessage(
					strTranslation.STEPS_CONFIG.toast.update_target.success,
				);
				dispatch(toastActions.addToast({message: successText, type: "success"}));
			} catch (err) {
				reportErrorToSentry(err, {interval, targetName, newTarget});
				dispatch(errorUpdatingActiveTarget(userId, targetName, newTarget, err));

				const failText: string = Localization.formatMessage(
					strTranslation.STEPS_CONFIG.toast.update_target.fail,
				);
				dispatch(toastActions.addToast({message: failText, type: "error"}));
			}
		};
	};

	/**
	 * Stop target
	 *
	 * @param {number} userId
	 * @param {TargetName} targetName
	 */
	export const stopActiveTarget = (targetName: TargetName): SensorTargetThunkAction => {
		return async (dispatch, getState) => {
			const token: string = getSessionId(),
				userId: number = getSelectedContactId(getState()),
				interval: Interval = getCurrentInterval(getState());

			dispatch(closeConfigModal(targetName));
			dispatch(stoppingActiveTarget(userId, targetName));
			try {
				const sensorTargetSDK = DISC.getSensorTargetService().sdk;
				await apiCall([sensorTargetSDK, sensorTargetSDK.stopActiveTarget], token, userId, targetName).then(
					() => {
						log.debug(c, "Active target stopped");
						dispatch(stoppedActiveTarget(userId, targetName));
						dispatch(loadAllData(interval, userId));

						const successText: string = Localization.formatMessage(
							strTranslation.STEPS_CONFIG.toast.stop_target.success,
						);
						dispatch(toastActions.addToast({message: successText, type: "success"}));
					},
				);
			} catch (err) {
				let errorMessage: string = "";
				if (err instanceof NotFoundError) {
					errorMessage = "Active target not found. Can't stop target";
					log.debug(c, errorMessage, err);
				} else {
					errorMessage = "Something's wrong!";
					reportErrorToSentry(err, {interval, targetName});
					const failText: string = Localization.formatMessage(
						strTranslation.STEPS_CONFIG.toast.stop_target.fail,
					);

					!isUnauthorizedError(err) && dispatch(toastActions.addToast({message: failText, type: "error"}));
				}
				dispatch(errorStoppingActiveTarget(userId, targetName, errorMessage));
			}
		};
	};

	/**
	 * Opens configuration modal and collapses chatbox
	 *
	 * @param {TargetName} targetName
	 */

	export const openConfigurationModal = (targetName: TargetName): SensorTargetThunkAction => {
		return (dispatch) => {
			dispatch(UIAction.collapseChatBox());
			dispatch(SensorTargetActions.openConfigModal(targetName));
		};
	};

	function reportErrorToSentry(err: any, extras?: Record<string, any>) {
		log.captureException(err, extras);
	}
}
