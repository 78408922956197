import {Utils} from "../ts/utils/Utils";

export enum BrowserName {
	OPERA = "opera",
	CHROME = "chrome",
	SAFARI = "safari",
	FIREFOX = "firefox",
	EDGE = "edge",
}

export interface BrowserEntity {
	name: string;
	link?: string;
	imgUrl?: string;
}

// Supported browser version from early 2017
// see https://en.wikipedia.org/wiki/Timeline_of_web_browsers
export const supportedBrowserVersion = {
	[BrowserName.OPERA]: ">43.0",
	[BrowserName.CHROME]: ">56.0",
	[BrowserName.SAFARI]: ">10.0",
	[BrowserName.FIREFOX]: ">51.0",
	[BrowserName.EDGE]: ">79.0",
};

/**
 * Known browser entities
 *
 * contains all known browser data for each `BrowserName`
 */
export const supportedBrowserEntities: Record<string, BrowserEntity> = {
	[BrowserName.OPERA]: {
		name: Utils.capitalize(BrowserName.OPERA),
		link: "https://www.opera.com/nl",
		imgUrl: "/assets/img/browsers/opera.png",
	},
	[BrowserName.CHROME]: {
		name: Utils.capitalize(BrowserName.CHROME),
		link: "https://www.google.com/intl/nl_ALL/chrome",
		imgUrl: "/assets/img/browsers/chrome.png",
	},
	[BrowserName.SAFARI]: {
		name: Utils.capitalize(BrowserName.SAFARI),
		link: "https://support.apple.com/nl-nl/HT204416",
		imgUrl: "/assets/img/browsers/safari.png",
	},
	[BrowserName.FIREFOX]: {
		name: Utils.capitalize(BrowserName.FIREFOX),
		link: "https://www.mozilla.org/nl/firefox/new",
		imgUrl: "/assets/img/browsers/firefox.png",
	},
	[BrowserName.EDGE]: {
		name: Utils.capitalize(BrowserName.EDGE),
		link: "https://www.microsoft.com/nl-nl/edge",
		imgUrl: "/assets/img/browsers/edge.png",
	},
};
