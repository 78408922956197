import {TimeEntriesOwnerOfTreatment} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";
import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {
	ClientDetailsInTreatment,
	EndTreatmentFormValue,
	Treatment,
	TreatmentComplaint,
	TreatmentFromForm,
	TreatmentPersonnel,
	TreatmentTerminatingReason,
	TreatmentType,
} from "../treatmentStatusTypes";

export const treatmentStatusActions = {
	setTreatmentStatus: createAction(
		"treatmentStatus/setTreatmentStatus",
		(userId: number, treatmentDetails: Treatment) => ({userId, treatmentDetails}),
	)(),
	setTreatmentTypesOfTreatment: createAction(
		"treatmentStatus/setTreatmentTypesOfTreatment",
		(userId: number, treatmentTypes: TreatmentType[]) => ({userId, treatmentTypes}),
	)(),
	setTreatmentComplaintsOfTreatment: createAction(
		"treatmentStatus/setTreatmentComplaintsOfTreatment",
		(userId: number, treatmentComplaints: TreatmentComplaint[]) => ({userId, treatmentComplaints}),
	)(),
	setTreatmentPersonnelsOfTreatment: createAction(
		"treatmentStatus/setTreatmentPersonnelsOfTreatment",
		(userId: number, personnels: TreatmentPersonnel[]) => ({userId, personnels}),
	)(),
	setEditTreatmentStatus: createAction("treatmentStatus/setEditedTreatmentStatus", (treatmentDetails: Treatment) => ({
		treatmentDetails,
	}))(),
	editTreatment: createAction("treatmentStatus/editTreatment", (treatment?: Treatment) => ({treatment}))(),

	openEndTreatmentForm: createAction("treatmentStatus/openEndTreatmentForm", (clientId: number) => ({clientId}))(),
	closeEndTreatmentForm: createAction("treatmentStatus/closeEndTreatmentForm")(),
	openClientDetailsForm: createAction("treatmentStatus/openClientDetailsForm")(),
	closeClientDetailsForm: createAction("treatmentStatus/closeClientDetailsForm")(),
	openEndMultipleTreatmentsForm: createAction("treatmentStatus/openEndMultipleTreatmentsForm")(),
	closeEndMultipleTreatmentsForm: createAction("treatmentStatus/closeEndMultipleTreatmentsForm")(),

	updateTreatmentMaps: createAction(
		"treatmentStatus/updateTreatmentMaps",
		(treatmentMaps: {[id: number]: Treatment}) => ({treatmentMaps}),
	)(),

	// Load all treatments from all connected clients
	loadAllOngoingTreatmentsOnStart: createAction("treatmentStatus/loadAllOngoingTreatmentsOnStart")(),

	// Load treatment of given clientId
	loadTreatmentsOfClient: createAsyncAction(
		"treatmentStatus/loadTreatmentsOfClient",
		"treatmentStatus/loadTreatmentsOfClientSuccess",
		"treatmentStatus/loadTreatmentsOfClientFailed",
	)<{clientId: number}, {clientId: number}, {clientId: number}>(),

	loadTreatmentTypes: createAsyncAction(
		"treatmentStatus/loadTreatmentTypes",
		"treatmentStatus/loadTreatmentTypesSuccess",
		"treatmentStatus/loadTreatmentTypesFailed",
	)<void, {treatmentTypes: TreatmentType[]}, {error: Error}>(),

	loadTreatmentComplaints: createAsyncAction(
		"treatmentStatus/loadTreatmentComplaints",
		"treatmentStatus/loadTreatmentComplaintsSuccess",
		"treatmentStatus/loadTreatmentComplaintsFailed",
	)<void, {treatmentComplaints: TreatmentComplaint[]}, {error: Error}>(),

	loadEndTreatmentReasons: createAsyncAction(
		"treatmentStatus/loadEndTreatmentReasons",
		"treatmentStatus/loadEndTreatmentReasonsSuccess",
		"treatmentStatus/loadEndTreatmentReasonsFailed",
	)<void, {endTreatmentReasons: TreatmentTerminatingReason[]}, {error: Error}>(),

	loadTreatmentDetails: createAsyncAction(
		"treatmentStatus/loadTreatmentDetails",
		"treatmentStatus/loadTreatmentDetailsSuccess",
		"treatmentStatus/loadTreatmentDetailsFailed",
	)<
		{clientId: number; treatmentId: number},
		{
			clientId: number;
			treatmentId: number;
			treatmentTypes: TreatmentType[];
			treatmentComplaints: TreatmentComplaint[];
			treatmentPersonnels: TreatmentPersonnel[];
		},
		{clientId: number; treatmentId: number}
	>(),

	loadAllTreatmentPersonnels: createAsyncAction(
		"treatmentStatus/loadTreatmentPersonnels",
		"treatmentStatus/loadTreatmentPersonnelsSuccess",
		"treatmentStatus/loadTreatmentPersonnelsFailed",
	)<void, {[clientId: number]: TreatmentPersonnel[]}, {error: Error}>(),

	setFetchingAllTreatmentsState: createAction(
		"treatmentStatus/setFetchingAllTreatmentsState",
		(fetchingState: LoadingState) => ({fetchingState}),
	)(),

	createOrUpdateTreatment: createAsyncAction(
		"treatmentStatus/createOrUpdateTreatment",
		"treatmentStatus/createOrUpdateTreatmentSuccess",
		"treatmentStatus/createOrUpdateTreatmentFailed",
	)<{clientId: number; treatment: TreatmentFromForm}, {clientId: number}, {error: Error}>(),

	endTreatment: createAsyncAction(
		"treatmentStatus/endTreatment",
		"treatmentStatus/endTreatmentSuccess",
		"treatmentStatus/endTreatmentFailed",
	)<{clientId: number; endFormValue: EndTreatmentFormValue}, {clientId: number}, {error: Error}>(),

	addTreatmentType: createAction("treatmentStatus/addTreatmentType", (treatmentType: TreatmentType) => ({
		treatmentType,
	}))(),

	addClientComplaint: createAction("treatmentStatus/addClientComplaint", (complaint: TreatmentComplaint) => ({
		complaint,
	}))(),

	updateTreatmentMutationLoadingState: createAction(
		"treatmentStatus/updateTreatmentMutationLoadingState",
		(loadingState: LoadingState) => ({loadingState}),
	)(),

	updateClientNumber: createAsyncAction(
		"treatmentStatus/updateClientNumber",
		"treatmentStatus/updateClientNumberSuccess",
		"treatmentStatus/updateClientNumberFailed",
	)<{clientId: number; clientNumber: string}, {clientId: number}, {error: Error}>(),

	updateClientDetailsInTreatment: createAsyncAction(
		"treatmentStatus/updateClientDetailsInTreatment",
		"treatmentStatus/updateClientDetailsInTreatmentSuccess",
		"treatmentStatus/updateClientDetailsInTreatmentFailed",
	)<
		{
			clientId: number;
			clientDetails: Partial<ClientDetailsInTreatment>;
			orgKeyForm: string;
			hideToast?: boolean;
			clientNumber?: string;
		},
		{clientId: number},
		{error: Error}
	>(),

	// Per https://github.com/senseobservationsystems/web-getgoalie/issues/4130,
	// it's possible that treatment can't be ended if there are
	// unconfirmed entries related to that treatment.
	// This action is about checking the details on the blocker of ending treatment.
	//
	// Additional note: `checkEndTreatmentBlocker.request` isn't called anywhere
	// as we want to rely on openEndTreatmentForm action to trigger the checking saga.
	checkEndTreatmentBlocker: createAsyncAction(
		"treatmentStatus/checkEndTreatmentBlocker",
		"treatmentStatus/checkEndTreatmentBlockerSuccess",
		"treatmentStatus/checkEndTreatmentBlockerFailed",
	)<
		{treatmentId: number},
		{treatmentId: number; owners: TimeEntriesOwnerOfTreatment[]},
		{treatmentId: number; error: Error}
	>(),
};

export type TreatmentStatusAction = ActionType<typeof treatmentStatusActions>;
