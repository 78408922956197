import {put, takeEvery} from "@redux-saga/core/effects";
import {ActionType, getType} from "typesafe-actions";
import {callActions} from "../../redux/callActions";

/**
 * Handle accept signal from counterpart.
 * - Stop call timeout (Only if logged in user is the call initiator)
 * - Update joined time in redux state
 */
function* handleAcceptSignal(action: ActionType<typeof callActions.handleAcceptSignal>) {
	const {signal} = action.payload;
	const {senderId, deviceInformation} = signal;

	const joinedTime = Date.now();

	// Stop call timer
	yield put(callActions.stopTimeout(senderId));
	// Add counterpart `joinedTime` in redux state
	yield put(callActions.userJoinCall(senderId, joinedTime, deviceInformation));
}

export function* handleAcceptSignalSaga() {
	yield takeEvery(getType(callActions.handleAcceptSignal), handleAcceptSignal);
}
