/** TODO @boby Move this data to TrackingTypes(?) */

export interface Emotion {
	emotion: string;
	name: string;
	minimum?: number;
	maximum?: number;
}

export const emotions: Emotion[] = [
	{emotion: "Happy", name: "happy"},
	{emotion: "Confident", name: "confident"},
	{emotion: "Motivated", name: "motivated"},
	{emotion: "Relaxed", name: "relaxed"},
	{emotion: "Energized", name: "energized"},
	{emotion: "Sad", name: "sad"},
	{emotion: "Angry", name: "angry"},
	{emotion: "Fearful", name: "fearful"},
	{emotion: "Tense", name: "tense"},
	{emotion: "Painful", name: "painful"},
];

export const defaultEmotionValues: Object = {
	happy: 0,
	sad: 0,
	angry: 0,
	fearful: 0,
	energized: 0,
	tense: 0,
	painful: 0,
	confident: 0,
	motivated: 0,
	relaxed: 0,
};
