import {fork} from "redux-saga/effects";
import fetchGroupTherapySaga from "./fetchGroupTherapySaga";
import fetchGroupRegistrationListSaga from "./fetchGroupRegistrationListSaga";
import fetchGroupRegistrationDetailsSaga from "./fetchGroupRegistrationDetailsSaga";
import saveGroupTherapySaga from "./saveGroupTherapySaga";
import saveGroupTherapySession from "./therapySession/saveGroupTherapySessionSaga";
import editGroupTherapySaga from "./editGroupTherapySaga";
import deleteGroupTherapySaga from "./deleteGroupTherapySaga";
import deleteGroupRegistrationSaga from "./deleteGroupRegistrationSaga";
import fetchGroupTherapyTrackersSaga from "./fetchGroupTherapyTrackersSaga";
import updateTrackerStatusSaga from "./updateTrackerStatusSaga";
import saveGroupTherapySessionNoteSaga from "./sessionNotes/saveGroupTherapySessionNoteSaga";

export default function* () {
	yield fork(fetchGroupTherapySaga);
	yield fork(fetchGroupRegistrationListSaga);
	yield fork(fetchGroupRegistrationDetailsSaga);
	yield fork(saveGroupTherapySaga);
	yield fork(saveGroupTherapySession);
	yield fork(editGroupTherapySaga);
	yield fork(deleteGroupTherapySaga);
	yield fork(deleteGroupRegistrationSaga);
	yield fork(fetchGroupTherapyTrackersSaga);
	yield fork(updateTrackerStatusSaga);
	yield fork(saveGroupTherapySessionNoteSaga);
}
