import {Authentication} from "@sense-os/goalie-js";

const authSDK = new Authentication();
authSDK.login = authSDK.login.bind(authSDK);
authSDK.loginWithRedirect = authSDK.loginWithRedirect.bind(authSDK);
authSDK.registerTherapist = authSDK.registerTherapist.bind(authSDK);
authSDK.getExternalUserID = authSDK.getExternalUserID.bind(authSDK);
authSDK.requestPasswordReset = authSDK.requestPasswordReset.bind(authSDK);
authSDK.resetPasswordConfirm = authSDK.resetPasswordConfirm.bind(authSDK);
authSDK.logout = authSDK.logout.bind(authSDK);
authSDK.getUserAccount = authSDK.getUserAccount.bind(authSDK);

export default authSDK;
