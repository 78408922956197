import {getType} from "typesafe-actions";
import produce from "immer";

import {InterventionTaskRegistrationState} from "../interventionTaskRegistrationType";
import {
	interventionTaskRegistrationActionType,
	interventionTaskRegistrationActions,
} from "./interventionTaskRegistrationAction";
import {LoadingState} from "constants/redux";

const initialState: InterventionTaskRegistrationState = {
	data: null,
	fetchingState: LoadingState.EMPTY,
	totalData: 0,
};

export const interventionTaskRegistrationReducer = produce(
	(state: InterventionTaskRegistrationState, action: interventionTaskRegistrationActionType) => {
		switch (action.type) {
			case getType(interventionTaskRegistrationActions.fetchTaskRegistration.request): {
				state.fetchingState = LoadingState.LOADING;
				return;
			}
			case getType(interventionTaskRegistrationActions.fetchTaskRegistration.success): {
				const {count, results} = action.payload;
				state.totalData = count;
				state.data = results;
				state.fetchingState = LoadingState.LOADED;
				return;
			}
			case getType(interventionTaskRegistrationActions.fetchTaskRegistration.failure): {
				state.fetchingState = LoadingState.ERROR;

				return;
			}
		}
	},
	initialState,
);
