import {PresenceAvailability} from "@sense-os/goalie-js";
import {eventChannel} from "redux-saga";
import {cancelled, delay, fork, put, race, select, take} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {AppConfig} from "../../ts/app/AppConfig";
import {chatPresenceActions} from "../redux/ChatPresenceAction";
import {KeyboardEvents} from "../../ts/constants/KeyboardEvents";
import {MouseEvents} from "../../ts/constants/MouseEvents";
import * as chatPresenceSelectors from "../redux/ChatPresenceSelector";

import {ChatPresenceContext} from "../types";
import {isMobile, isTablet} from "react-device-detect";
import {TouchEvents} from "constants/TouchEvents";

const INACTIVITY_TIMEOUT = AppConfig.ONLINE_TIMEOUT_SECONDS * 1000;
const mobileEvents = [TouchEvents.TOUCH_MOVE, TouchEvents.TOUCH_END, KeyboardEvents.KEY_UP];
const desktopEvents = [MouseEvents.MOUSE_MOVE, MouseEvents.MOUSE_UP, KeyboardEvents.KEY_UP];
const USER_ACTIVE_EVENTS: string[] = isMobile || isTablet ? mobileEvents : desktopEvents;

function* activityDetection() {
	/**
	 * This activity detection shouldn't run as soon as portal open.
	 * Why? Well, it is not because of a hard technical reasons.
	 *
	 * Part of this activity detection, is broadcasting current presence,
	 * to make all other tabs aware of activity in this tab, right?
	 * Now, running it as soon as portal open, means this tab will inform
	 * other tabs of this tab default presence, instead of giving time
	 * for this tab to query current user presence first.
	 *
	 * Thus, we wait for portal to set its presence first before running this detection.
	 */
	yield take(getType(chatPresenceActions.updateOwnPresence));

	const chan = eventChannel<boolean>((emitter) => {
		/**
		 * This tick here is a helper so we don't listen to the event too much,
		 * by not listening for activity for (INACTIVITY_TIMEOUT * 1/4) after such activity is detected.
		 * But, is it really needed though? Not sure, haven't tested it yet.
		 */
		function tick() {
			emitter(true);
			USER_ACTIVE_EVENTS.forEach((eventName) => {
				window.removeEventListener(eventName, tick);
			});

			setTimeout(() => {
				USER_ACTIVE_EVENTS.forEach((eventName) => {
					window.addEventListener(eventName, tick);
				});
			}, INACTIVITY_TIMEOUT / 4);
		}

		USER_ACTIVE_EVENTS.forEach((eventName) => {
			window.addEventListener(eventName, tick);
		});

		return () => {
			USER_ACTIVE_EVENTS.forEach((eventName) => {
				window.removeEventListener(eventName, tick);
			});
		};
	});

	try {
		while (true) {
			yield take(chan);

			const currentPresence: PresenceAvailability = yield select(chatPresenceSelectors.getCurrentPresence);
			const currentContext: ChatPresenceContext = yield select(chatPresenceSelectors.getCurrentPresenceContext);

			/**
			 * Broadcasting presence as mark of activity, and set it to available if needed.
			 */
			if (currentPresence === PresenceAvailability.Away && currentContext === ChatPresenceContext.SET_BY_PORTAL) {
				yield put(
					chatPresenceActions.updateOwnPresence({
						presence: PresenceAvailability.Available,
						context: ChatPresenceContext.SET_BY_PORTAL,
					}),
				);
			} else {
				yield put(chatPresenceActions.updateOwnPresence({presence: currentPresence, context: currentContext}));
			}
		}
	} finally {
		if (yield cancelled()) {
			chan.close();
		}
	}
}

function* awayPresenceTimer() {
	while (true) {
		const currentPresence: PresenceAvailability = yield select(chatPresenceSelectors.getCurrentPresence);
		if (currentPresence !== PresenceAvailability.Available) {
			/**
			 * If current presence isn't Available, then turn off this away presence timer,
			 * until it become Available again.
			 */
			yield take(getType(chatPresenceActions.updateOwnPresence));
		} else {
			/**
			 * As part of activity detection, tab will regularly broadcast its presence,
			 * so, it need to listen to updateOwnPresence to check activity across multiple tabs.
			 */
			const raceResult = yield race({
				presenceIsUpdated: take(getType(chatPresenceActions.updateOwnPresence)),
				newPresenceIsComing: take(getType(chatPresenceActions.processIncomingOwnPresence)),
				timeout: delay(INACTIVITY_TIMEOUT),
			});

			if (raceResult.timeout) {
				yield put(
					chatPresenceActions.updateOwnPresence({
						presence: PresenceAvailability.Away,
						context: ChatPresenceContext.SET_BY_PORTAL,
					}),
				);
			}
		}
	}
}

export default function* () {
	yield fork(activityDetection);
	yield fork(awayPresenceTimer);
}
