import {Presence, PresenceAvailability} from "@sense-os/goalie-js/dist";
import {ActionType, createAction} from "typesafe-actions";
import {ChatPresenceContext} from "../types";

export const chatPresenceActions = {
	/**
	 * Sets the status selector dialog either open or closed
	 */
	setIsDialogOpen: createAction("chatPresenceActions/setIsDialogOpen", (isOpen: boolean) => ({isOpen}))(),

	/**
	 * This is to trigger logic to send the "first after chat is connected" presence to ejabberd
	 */
	sendInitialPresence: createAction("chatPresenceActions/sendInitialPresence")(),

	/** Set presence of current user */
	updateOwnPresence: createAction(
		"chatPresenceActions/updateOwnPresence",
		(payload: {presence: PresenceAvailability; context: ChatPresenceContext}) => payload,
	)(),

	/** Update presence of other user in redux */
	updateOtherUserPresence: createAction(
		"chatPresenceActions/updateOtherUserPresence",
		(payload: {presence: Presence}) => payload,
	)(),

	/** Query other user presence */
	queryOtherUserPresence: createAction("chatPresenceActions/queryOtherUserPresence", (userId: number) => ({
		userId,
	}))(),

	/** This is to trigger saga to handle incoming presence of current user. Only called from saga. */
	processIncomingOwnPresence: createAction(
		"chatPresenceActions/processIncomingOwnPresence",
		(payload: {presence: PresenceAvailability; context: ChatPresenceContext}) => payload,
	)(),
};

export type ChatPresenceActionType = ActionType<typeof chatPresenceActions>;
