import {ActionType, getType} from "typesafe-actions";
import {fork, takeEvery} from "redux-saga/effects";

import {whenLoggedIn} from "../../auth/sagas/helper";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {ChatRoomAction} from "../redux/ChatRoomAction";

import chatSDK from "../sdk";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";

const log = createLogger("ChatHistorySync", SentryTags.Chat);

function* setLastReadTime(action: ActionType<typeof ChatRoomAction.setLastReadTime>) {
	const {userId, messageId} = action.payload;
	try {
		return yield apiCallSaga(chatSDK.setLastReadTime, userId, messageId);
	} catch (err) {
		log.captureException(err, {message: "Failed to set last read time"});
	}
}

function* setLastSentTime(action: ActionType<typeof ChatRoomAction.setLastSentTime>) {
	const {userId, messageId} = action.payload;

	try {
		return yield apiCallSaga(chatSDK.setLastSentTime, userId, messageId);
	} catch (err) {
		log.captureException(err, {message: "Failed to set last sent time"});
	}
}

function* chatHistorySyncSaga() {
	yield takeEvery(getType(ChatRoomAction.setLastReadTime), setLastReadTime);
	yield takeEvery(getType(ChatRoomAction.setLastSentTime), setLastSentTime);
}

export default function* () {
	yield fork(whenLoggedIn(chatHistorySyncSaga));
}
