import {take, all, select, takeEvery, put, race} from "redux-saga/effects";
import {contactActions} from "../../contacts/redux/contactAction";
import {getType} from "typesafe-actions";
import {ChatAction} from "../../chat/redux/ChatAction";
import {getAllContacts} from "../../contacts/redux/contactSelectors";
import {Contact} from "../../contacts/contactTypes";
import {UserlistUtils} from "utils/UserlistUtils";
import {PureUserUtils} from "utils/PureUserUtils";
import {history} from "visual/App";
import {bootstrapActions} from "../redux";

function* waitForSortedContactsSaga() {
	yield all([
		race({
			success: take(getType(contactActions.loadContacts.success)),
			failure: take(getType(contactActions.loadContacts.failure)),
		}),
		take(getType(ChatAction.lastInteractionTimesLoaded)),
	]);
	const contacts = yield select(getAllContacts);
	if (contacts.length > 0) {
		const sortedContacts: Contact[] = yield select((state) => UserlistUtils.sortByLastInteraction(contacts, state));
		const latestInteractedContact = sortedContacts[0];
		const redirectLink = PureUserUtils.getProfileLink(latestInteractedContact.hashId, latestInteractedContact.role);
		history.push(redirectLink);
	}

	yield put(bootstrapActions.contactsSorted());
}

export default function* () {
	yield takeEvery(getType(bootstrapActions.waitForSortedContacts), waitForSortedContactsSaga);
}
