import {takeEvery, put, call} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {Organization, UserOrganization} from "@sense-os/goalie-js";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";
import {organizationActions} from "../redux/organizationActions";
import {toastActions} from "../../toaster/redux/toastAction";
import organizationSDK from "../helpers/organizationSdk";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {authActions} from "../../auth/redux";

const log = createLogger("organizationSaga", SentryTags.Organization);

function* handleJoinOrganization(action: ActionType<typeof organizationActions.joinOrganization.request>) {
	try {
		const token: string = yield call(getSessionId);
		const toastText: string = loc.formatMessage(strTranslation.USER_PROFILE.org.approve_phase_dialog.toast.success);
		const organization: Organization = action.payload.organization;

		const resp: UserOrganization = yield apiCallSaga(
			[organizationSDK, organizationSDK.joinOrganization],
			organization.id,
			token,
		);
		yield put(organizationActions.joinOrganization.success());
		yield put(
			authActions.updateAuthUser({
				organization: {
					id: resp.organization.id,
					name: resp.organization.name,
					logo: resp.organization.logo,
					isAccepted: false,
					groupId: resp.organization?.group,
					formKey: resp.organization?.formKey,
				},
			}),
		);
		yield put(toastActions.addToast({message: toastText, type: "info"}));
	} catch (err) {
		log.captureException(err);
		const toastText: string = loc.formatMessage(strTranslation.USER_PROFILE.org.approve_phase_dialog.toast.error);
		yield put(organizationActions.joinOrganization.failure(err));
		yield put(toastActions.addToast({message: toastText, type: "error"}));
	}
}

export default function* () {
	yield takeEvery(getType(organizationActions.joinOrganization.request), handleJoinOrganization);
}
