import createLogger from "../../logger/createLogger";
import Localization, {ILocalization} from "../../localization/Localization";
import {IStorage} from "../../ts/services/system/storage/IStorage";
import Storage from "../../ts/services/system/storage/Storage";
import {StorageKeys} from "../../ts/services/system/storage/StorageKeys";
import {StorageBools} from "../../ts/services/system/storage/StorageBools";
import {BrowserEvents} from "../../ts/constants/BrowserEvents";
import {call, fork, put, select, take, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {END, eventChannel} from "redux-saga";
import {push} from "connected-react-router";
import {NotificationAction} from "../redux/NotificationAction";
import {
	askNotificationsPermission,
	getNameByUserIds,
	onWindowInBackground,
	onWindowInForeground,
} from "../notificationHelpers";
import {BackgroundNotificationOptions, NotificationData} from "../types";
import {shouldShowNotification} from "../redux/NotificationSelectors";
import {getActiveCall} from "../../ts/redux/videoCall/VideoCallSelectors";
import {ActiveCall} from "../../ts/services/chat/video/ActiveCall";
import {getNamesByUserIds, getUserProfilesByUserIds} from "../../userProfile/redux/UserProfileSelector";
import {ContactTabPath, Path} from "../../ts/app/Path";
import {Contact} from "../../contacts/contactTypes";
import {getContactById} from "../../contacts/redux/contactSelectors";
import {UserRole} from "@sense-os/goalie-js";
import {UIAction} from "../../ts/redux/UI/UIAction";
import {EMPTY_MESSAGE} from "../../fileSharing/fileSharingTypes";
import {getClientTabMenuRoute, getTherapistTabMenuRoute} from "../../helpers/routeGenerator";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("BackgroundNotifications");
const loc: ILocalization = Localization;
const storage: IStorage = Storage;

/** URL to use as the icon when showing a notification */
const NOTIFICATION_ICON_URL: string = "/assets/img/all-logo/favicon-128x128.png";

/**
 * Releases all the resources used by the chat- and call-related notifications.
 */
export function* reset() {
	storage.remove(StorageKeys.IS_PORTAL_IN_FOCUS);
	window.removeEventListener(BrowserEvents.BLUR, onWindowInBackground);
	window.removeEventListener(BrowserEvents.FOCUS, onWindowInForeground);
}

/**
 * Initialises the chat- and call-related notifications.
 */
export function* init() {
	// Reset yourself to make sure everything is cleaned up before initializing
	yield call(reset);

	log.debug("Initializing");

	try {
		const permission: NotificationPermission = yield call(askNotificationsPermission);
		if (permission === "granted") {
			log.addBreadcrumb({message: "Notification permission granted", data: {permission}});
		} else {
			log.addBreadcrumb({message: "Failed to get permission for notifications", data: {permission}});
		}
	} catch (e) {
		log.addBreadcrumb({message: "Failed to get permission for notifications", data: e});
	}

	// When the portal is initialized we assume that the portal is in foreground
	storage.write(StorageKeys.IS_PORTAL_IN_FOCUS, StorageBools.TRUE);

	window.addEventListener(BrowserEvents.BLUR, onWindowInBackground);
	window.addEventListener(BrowserEvents.FOCUS, onWindowInForeground);
}

/**
 * Browser API to push notification to the client's system.
 * Try/catch added to prevent Unhandled Rejection because Mobile Browser doesn't support Notification API.
 * The mouse click handling is done via a redux-saga event channel.
 * - the TypeError is excluded because it was already expected.
 * @param notificationTitle string of notificaton title.
 * @param options BackgrondNotificationOptions | NotificationOptions
 * @return void
 */
export function* pushNotification(
	notificationTitle: string,
	options: BackgroundNotificationOptions | NotificationOptions,
) {
	try {
		const notification = new Notification(notificationTitle, options);
		const subscription = (emitter) => {
			notification.onclick = (e: MouseEvent) => {
				// console.log(" notification.onclick() called!!! "); // NB: commented and left in on purpose
				emitter(e);
				emitter(END);
			};
			return () => (notification.onclick = null);
		};
		const clickChannel = eventChannel(subscription);

		// Pause till the notification will have been clicked
		yield take(clickChannel);

		window.focus(); // log.debug("Notification data: ", notification.data);

		if (notification.data) {
			const {link} = notification.data as NotificationData;
			link && (yield put(push(link)));

			const shouldOpenChat: boolean =
				link && (link.indexOf(Path.APP_CLIENT) > -1 || link.indexOf(Path.APP_THERAPIST) > -1);
			shouldOpenChat && (yield put(UIAction.openChatBox()));
		}
		notification.close();
	} catch (error) {
		if (!(error instanceof TypeError)) {
			log.warn(`Unable to push notification: ${error.message}`);
		}
	}
}

/**
 * [ Saga generator helper ]
 * Returns a link for opening user profile
 */
function* getUserLink(userIds: number[]) {
	if (!userIds || userIds.length === 0 || userIds.length > 1) {
		// Might be an incoming group video call, thus we just need to redirect the therapist
		// to the dashboard.
		return Path.APP;
	}

	const [userId] = userIds;

	// Try to get contact data to get the user role

	const contactData: Contact = yield select(getContactById, userId);

	// If the contact data is not found, return null
	if (!contactData) {
		return null;
	}

	const destinationTab: string = contactData.role === UserRole.PATIENT ? ContactTabPath.DATA : ContactTabPath.PROFILE;

	if (contactData.role === UserRole.THERAPIST) return getTherapistTabMenuRoute(contactData.hashId, destinationTab);

	return getClientTabMenuRoute(contactData.hashId, destinationTab);
}
/**
 * [ Saga generator helper ]
 * Returns user profile image if `userIds` length is `1`.
 * If not, return `NOTIFICATION_ICON_URL`
 *
 * @param {number[]} userIds
 */
export function* getNotificationIcon(userIds: number[]) {
	if (!userIds || userIds.length > 1) {
		return NOTIFICATION_ICON_URL;
	}
	const [userProfile] = yield select(getUserProfilesByUserIds(userIds));

	if (!userProfile || !userProfile.image) {
		return NOTIFICATION_ICON_URL;
	}
	return userProfile.image;
}
/**
 * [ Saga generator helper ]
 * Returns default notification options.
 *
 * The default `link` value would be the user details page.
 * If the `userIds` array has more than 1 item, the user  will be redirected to `Path.APP` page.
 * The `link` value may also be overwritten by passing the `link` as the `data` parameter.
 *
 * @param {number[]} userIds
 * @param {NotificationData} data
 */
function* getDefaultNotificationOptions(userIds: number[], data?: NotificationData) {
	const notificationData: NotificationData = {
		link: yield call(getUserLink, userIds),
		...data,
	};

	return {
		icon: yield call(getNotificationIcon, userIds),
		data: notificationData,
	};
}

/**
 * [ Saga generator helper ]
 * A helper function that figures out the name for the incoming notifications
 * @param fromIDs
 */
export function* getFromName(fromIDs: number[]) {
	const activeCall: ActiveCall = yield select(getActiveCall);
	// Try to get the name of the user who sent us the message
	let fromName = getNameByUserIds(fromIDs, activeCall);

	if (!fromName) {
		// Get names from user profile state
		fromName = yield select(getNamesByUserIds(fromIDs, loc.formatMessage("CHAT.video.unknown_caller")));
	}
	return fromName;
}

/**
 * Handles an incoming chat message
 * @param action
 */
export function* onReceivedChatMessage(action: ActionType<typeof NotificationAction.onReceivedChatMessage>) {
	const showNotification: boolean = yield select(shouldShowNotification);

	// Check if we should show notification
	if (!showNotification) {
		return;
	}

	const {fromID, message} = action.payload;

	// Try to get the name of the user who sent us the message
	const fromName = yield call(getFromName, [fromID]);

	// Use the name of the user if it exists as the title of the notification or use a generic title
	const notificationTitle =
		fromName || loc.formatMessage(strTranslation.BACKGROUND_NOTIFICATION.chat_msg_without_name.title);

	// There is a case when message contains no value from receiving file sharing with no caption,
	// this condition should be able to handle it.
	const messageText =
		message === EMPTY_MESSAGE
			? loc.formatMessage(strTranslation.BACKGROUND_NOTIFICATION.send_file_without_caption.body, {name: fromName})
			: message;

	const options: NotificationOptions = {
		...(yield getDefaultNotificationOptions([fromID])),
		body: messageText,
	};

	// The name of the sender is the title of the notification
	yield fork(pushNotification, notificationTitle, options);
}

/**
 * Handles a missed call
 */
export function* onMissedCallNotification(action: ActionType<typeof NotificationAction.onMissedCallNotification>) {
	const showNotification: boolean = yield select(shouldShowNotification);

	// Check if we should show notification
	if (!showNotification) {
		return;
	}

	const {fromIDs} = action.payload;
	const options: NotificationOptions = yield getDefaultNotificationOptions(fromIDs);

	const fromName = yield call(getFromName, fromIDs);

	if (fromName) {
		options.body = loc.formatMessage(strTranslation.BACKGROUND_NOTIFICATION.missed_call.body, {
			name: fromName,
		});
	}
	const notificationTitle = loc.formatMessage(strTranslation.BACKGROUND_NOTIFICATION.missed_call.title);
	yield fork(pushNotification, notificationTitle, options);
}

/**
 * Handles an incoming call
 * @param action
 */
export function* onIncomingCallNotification(action: ActionType<typeof NotificationAction.onIncomingCallNotification>) {
	const showNotification: boolean = yield select(shouldShowNotification);

	// Check if we should show notification
	if (!showNotification) {
		return;
	}

	const {fromIDs} = action.payload;
	const options: BackgroundNotificationOptions = yield getDefaultNotificationOptions(fromIDs);

	// Try to get the name of the user who sent us the message
	const fromName = yield call(getFromName, fromIDs);

	if (fromName) {
		options.body = loc.formatMessage(strTranslation.BACKGROUND_NOTIFICATION.incoming_call.body, {
			name: fromName,
		});
	}

	const notificationTitle = loc.formatMessage(strTranslation.BACKGROUND_NOTIFICATION.incoming_call.title);

	yield fork(pushNotification, notificationTitle, options);
}

export function* notificationSaga() {
	yield takeEvery(getType(NotificationAction.init), init);
	yield takeEvery(getType(NotificationAction.reset), reset);
	yield takeEvery(getType(NotificationAction.onReceivedChatMessage), onReceivedChatMessage);
	yield takeEvery(getType(NotificationAction.onIncomingCallNotification), onIncomingCallNotification);
	yield takeEvery(getType(NotificationAction.onMissedCallNotification), onMissedCallNotification);
}
