import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {interventionTreatmentGoalActions} from "../redux/interventionTreatmentGoalActions";
import treatmentStatusSdk from "../../../treatmentStatus/treatmentStatusSdk";
import {Treatment} from "@sense-os/goalie-js";
import {toastActions} from "../../../toaster/redux";
import strTranslation from "../../../assets/lang/strings";
import {automaticTreatmentCreationSaga} from "../../../treatmentStatus/sagas/automaticTreatmentCreationSaga";

type UpdateTreatmentPayload = Omit<Treatment, "terminatingReason"> & {terminatingReason: number};

const log = createLogger("putTreatmentStatus", SentryTags.Intervention);

function* putTreatmentStatus(action: ActionType<typeof interventionTreatmentGoalActions.putTreatmentStatus.request>) {
	const {treatmentGoal} = action.payload;

	try {
		const token: string = yield call(getSessionId);
		const treatment = yield call(automaticTreatmentCreationSaga, treatmentGoal.patient);

		const result: Treatment = yield apiCallSaga(treatmentStatusSdk.updateTreatment, token, {
			...treatmentGoal,
			id: treatment.id,
		} as UpdateTreatmentPayload);

		yield put(interventionTreatmentGoalActions.putTreatmentStatus.success({treatment: result}));

		yield put(interventionTreatmentGoalActions.openModal({isOpen: false}));
		yield put(toastActions.addToast({message: strTranslation.INTERVENTION.treatment.goal.saved, type: "success"}));
	} catch (error) {
		log.error(error);
		yield put(interventionTreatmentGoalActions.putTreatmentStatus.failure({err: error}));
		yield put(
			toastActions.addToast({message: strTranslation.INTERVENTION.treatment.goal.failed_to_save, type: "error"}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(interventionTreatmentGoalActions.putTreatmentStatus.request), putTreatmentStatus);
}
