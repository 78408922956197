import {Contacts} from "@sense-os/goalie-js";

const sdk = new Contacts();

sdk.acceptInvitation = sdk.acceptInvitation.bind(sdk);
sdk.getConnectedContacts = sdk.getConnectedContacts.bind(sdk);
sdk.getInactiveClients = sdk.getInactiveClients.bind(sdk);
sdk.removeContactFromUserContact = sdk.removeContactFromUserContact.bind(sdk);
sdk.rejectInvitation = sdk.rejectInvitation.bind(sdk);
sdk.acceptInvitation = sdk.acceptInvitation.bind(sdk);
sdk.cancelInvitation = sdk.cancelInvitation.bind(sdk);
sdk.getContactById = sdk.getContactById.bind(sdk);
sdk.getInvitedContacts = sdk.getInvitedContacts.bind(sdk);
sdk.getRequestedContacts = sdk.getRequestedContacts.bind(sdk);
sdk.inviteTherapistToPatient = sdk.inviteTherapistToPatient.bind(sdk);

export default sdk;
