import {ActionType, createAction, createAsyncAction} from "typesafe-actions";

import {wrapInDispatch} from "../../helpers/wrapInDispatch";
import {UserManagementState} from "../userManagementTypes";

export const userManagementActions = {
	fetchUserManagementList: createAsyncAction(
		"userManagementActions/fetchUserManagementList",
		"userManagementActions/fetchUserManagementListSucceed",
		"userManagementActions/fetchUserManagementListFailed",
	)<
		void,
		Pick<UserManagementState, "organizationUsers" | "acceptedUsersId" | "deactivatedUsersId" | "pendingUsersId">,
		{error: Error}
	>(),

	acceptPendingUser: createAsyncAction(
		"userManagementActions/acceptUser",
		"userManagementActions/acceptUserSucceed",
		"userManagementActions/acceptUserFailed",
	)<{hashId: string}, {hashId: string}, {hashId: string; error: Error}>(),

	rejectPendingUser: createAsyncAction(
		"userManagementActions/rejectPendingUser",
		"userManagementActions/rejectPendingUserSucceed",
		"userManagementActions/rejectPendingUserFailed",
	)<{hashId: string}, {hashId: string}, {hashId: string; error: Error}>(),

	deactivateAcceptedUser: createAsyncAction(
		"userManagementActions/deactivateAcceptedUser",
		"userManagementActions/deactivateAcceptedUserSucceed",
		"userManagementActions/deactivateAcceptedUserFailed",
	)<{hashId: string}, {hashId: string}, {hashId: string; error: Error}>(),

	reactivateDeactivatedUser: createAsyncAction(
		"userManagementActions/reactivateDeactivatedUser",
		"userManagementActions/reactivateDeactivatedUserSucceed",
		"userManagementActions/reactivateDeactivatedUserFailed",
	)<{hashId: string}, {hashId: string}, {hashId: string; error: Error}>(),

	setDeactivateCandidate: createAction("userManagementActions/setDeactivateCandidate", (hashId: string) => ({
		hashId,
	}))(),
};

export type UserManagementActions = ActionType<typeof userManagementActions>;
export const userManagementActionsWithDispatch = wrapInDispatch(userManagementActions);
