import {createSelector} from "reselect";

import {AppState} from "redux/AppState";
import {FIRST_PAGE_NUMBER, InactiveClient, SortKey} from "../inactiveClientTypes";
import {getFirstItemNumber, getLastItemNumber, sortInactiveClients} from "../inactiveClientHelpers";

/** Extract `inactiveClient` state from AppState */
const getInactiveClientState = (state: AppState) => state.inactiveClient;

/** Get `inactiveClientsMap` object from `inactiveClient` state */
const getInactiveClientsMap = createSelector(getInactiveClientState, (state) => state.inactiveClientsMap);

/**
 * Returns all inactive clients from `inactiveClient` state as array.
 * Sorted ASC by latest registration by default.
 */
const getAllInactiveClients = createSelector(getInactiveClientsMap, (state) => {
	const inactiveClients: InactiveClient[] = Object.values<InactiveClient>(state);
	return sortInactiveClients(inactiveClients, {key: SortKey.LATEST_REGISTRATION, asc: true});
});

/** Get sort by state */
export const getSortByState = createSelector(getInactiveClientState, (state) => state.sortByState);

/** Get `currentPage` state from `inactiveClient` state */
export const getCurrentPage = createSelector(getInactiveClientState, (state) => state.currentPage);

/** Get `maxItemPerPage` state from `inactiveClient` state */
export const getMaxItemPerPage = createSelector(getInactiveClientState, (state) => state.maxItemPerPage);

/** Returns total inactive clients */
export const getTotalInactiveClients = createSelector(getAllInactiveClients, (state) => state.length);

/** Returns all inactive clients from `inactiveClient` state as array, sliced by page settings, and sorted by name. */
export const getInactiveClients = createSelector(
	[getAllInactiveClients, getSortByState, getCurrentPage, getMaxItemPerPage],
	(allInactiveClients, sortByState, currentPage, maxItemPerPage) => {
		const firstItemNumber: number = getFirstItemNumber(currentPage, maxItemPerPage),
			lastItemNumber: number = getLastItemNumber(currentPage, maxItemPerPage);
		// minus `1` because `firstItemNumber` always start with `1`.
		// `slice` starts with `0`
		return sortInactiveClients(allInactiveClients, sortByState).slice(
			firstItemNumber - FIRST_PAGE_NUMBER,
			lastItemNumber,
		);
	},
);

export const getLastRegistrationMap = (state: AppState) => state.inactiveClient.lastRegistrationMap;
