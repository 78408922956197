import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SnackbarKey, useSnackbar} from "notistack";
import {PrimitiveType} from "intl-messageformat";
import Localization, {ILocalization} from "../localization/Localization";
import {StringMap} from "services/utils/Maps";

import {toastActions, getToastList} from "./redux";
import {NotifierDefaultAction} from "./NotifierDefaultAction";

/**
 * List of toast ids that are currently being shown
 */
let displayed: SnackbarKey[] = [];

const storeDisplayed = (id: SnackbarKey) => {
	displayed = [...displayed, id];
};

const removeDisplayed = (id: SnackbarKey) => {
	displayed = [...displayed.filter((key) => id !== key)];
};

const Notifier = () => {
	const dispatch = useDispatch();
	const toasts = useSelector(getToastList);
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	useEffect(() => {
		const loc: ILocalization = Localization;
		const isTextLocalization = (text: string): boolean => {
			return loc.isTranslationIdExist(text);
		};

		const localizeText = (text: string, payloads?: StringMap<PrimitiveType>): string => {
			if (!text) {
				return null;
			}
			return isTextLocalization(text) ? loc.formatMessage(text, payloads) : text;
		};

		toasts.forEach(({key, message, type, localizationPayload, timeout, persist, action}) => {
			// do nothing if snackbar is already displayed
			if (displayed.includes(key)) {
				return;
			}

			// display snackbar using notistack
			enqueueSnackbar(localizeText(message, localizationPayload), {
				key,
				anchorOrigin: {horizontal: "center", vertical: "bottom"},
				autoHideDuration: timeout ? timeout : 3000,
				persist,
				variant: type,
				onExited: (event, myKey) => {
					// remove this snackbar from redux store
					dispatch(toastActions.removeToast(myKey));
					removeDisplayed(myKey);
				},
				action: action ? action : <NotifierDefaultAction snackBarKey={key} />,
			});
			storeDisplayed(key);
		});
	}, [toasts, closeSnackbar, enqueueSnackbar, dispatch]);

	useEffect(() => {
		// Get toasts to be removed
		const removeIds: SnackbarKey[] = displayed.filter(
			(displatedToast) => !toasts.some((toast) => toast.key === displatedToast),
		);
		// Close toasts
		removeIds.forEach((id) => {
			closeSnackbar(id);
			removeDisplayed(id);
		});
	}, [toasts, closeSnackbar]);

	return null;
};

export default Notifier;
