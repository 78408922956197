import {TerminationReason} from "@sense-os/goalie-js";
import {DISC} from "IoC/DISC";
import {put, call, takeEvery, select} from "redux-saga/effects";
import {getActiveCall} from "redux/videoCall/VideoCallSelectors";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {ActionType} from "typesafe-actions";
import chatSDK from "../../../chat/sdk";
import {doAllOtherParticipantsHaveLeft} from "../../helpers/callUtils";
import {callActions} from "../../redux/callActions";
import {playCallWindowSound} from "../callWindow/playCallWindowSound";

/**
 * Handle terminate signal from counterpart.
 */
function* handleTerminateSignal(action: ActionType<typeof callActions.handleTerminateSignal>) {
	const {signal} = action.payload;
	const {senderId, terminationReason} = signal;

	// Busy -> The counterpart is on another call.
	// When this happens, portal doesn't need to do anything and let the call timed out.
	// This is to cover the NDT use case where they can establish a call in multiple instances.
	const isBusySignal: boolean = signal.terminationReason === TerminationReason.Busy;
	if (isBusySignal) {
		return;
	}

	yield put(callActions.processRemoteUserLeavesCall(senderId, terminationReason));
}

/**
 * From portal's technical point of view, there're two ways a user can leave a call.
 * One, via termination signal, and the other, via Twilio's `participantDisconnected` trigger.
 *
 * This saga here handles the common logic for both cases.
 * In both cases:
 * - portal will update active call's in redux,
 * - show termination toast,
 * - sending call summary
 */
function* processRemoteUserLeavesCall(action: ActionType<typeof callActions.processRemoteUserLeavesCall>) {
	const {userId, terminationReason} = action.payload;

	const activeCall = yield select(getActiveCall);
	const userAlreadyLeft =
		!activeCall || !activeCall.participantMap[userId] || !!activeCall.participantMap[userId].leaveTime;

	// If the user already left, portal doesn't have to do anything.
	if (userAlreadyLeft) {
		return;
	}

	// Stop call initiation timer
	yield put(callActions.stopTimeout(userId));

	// Add counterpart `leaveTime` in redux state
	const leaveTime = Date.now();
	yield put(callActions.userLeaveCall(userId, leaveTime));

	// Ideally portal will do another `yield select(getActiveCall)` here
	// to get the updated activeCall after the `callActions.userLeaveCall` above.
	// But, as a hack so this saga can pass the test, we do this technically equivalent logic
	// instead.
	const updatedActiveCallWithUserThatJustLeft: ActiveCall = {
		...activeCall,
		participantMap: {
			...activeCall.participantMap,
			[userId]: {...activeCall.participantMap[userId], leaveTime},
		},
	};

	const allOtherParticipantsHaveLeft = doAllOtherParticipantsHaveLeft(updatedActiveCallWithUserThatJustLeft);

	if (allOtherParticipantsHaveLeft) {
		// We want to make sure that if the local user is the last person in the call to leave,
		// the local user should send termination signal so that the backend can know what
		// the `leaveTime` of the local user is.
		yield call(chatSDK.terminateCall, activeCall.roomId, terminationReason);

		// If all other participants have left, but for one or other reason
		// portal shouldn't end the call, portal still need to play the `call end` token
		// as indicator that the call is, in a way, ends.
		const shouldCallEnd = yield call(DISC.getVideoCallService().shouldEndActiveCall);
		if (!shouldCallEnd) {
			yield call(playCallWindowSound);
		}
	} else {
		// Inform toast that remote user has left the group call
		// No need to show any toast when auth user is the last one on the call. This is because
		// toast message will be shown in another function (When call window closed event)
		yield call(DISC.getVideoCallService().showTerminationToast, userId, terminationReason);
	}

	// Send call summary only to the senderId
	yield call(DISC.getVideoCallService().sendCallSummary, terminationReason, userId);

	// Check if we need to terminate the call (Call mostlikely will end if auth user is the last participant in the call)
	// then send call summary to all participants that still in the call
	yield put(callActions.endCallIfNeeded(terminationReason));
}

export function* handleTerminateSignalSaga() {
	yield takeEvery(callActions.handleTerminateSignal, handleTerminateSignal);
	yield takeEvery(callActions.processRemoteUserLeavesCall, processRemoteUserLeavesCall);
}
