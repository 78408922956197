import {call, fork, takeLatest} from "redux-saga/effects";
import {checkVersionByTimeoutSaga} from "./checkVersionByTimeoutSaga";
import {getType} from "typesafe-actions";
import {versionCheckActions} from "../redux/versionCheckAction";
import {disconnectChat} from "../../chat/sagas/connectionSaga";
function* onPortalOutdated() {
	yield call(disconnectChat);
}

export default function* () {
	yield fork(checkVersionByTimeoutSaga);
	yield takeLatest(getType(versionCheckActions.enableBackdrop), onPortalOutdated);
}
