import {ActionType, getType} from "typesafe-actions";
import {ChatRoomAction} from "../redux/ChatRoomAction";
import {select, put, call, takeEvery} from "redux-saga/effects";
import {chatRoomEarliestMessage} from "../redux/ChatSelector";
import {LoadingState} from "constants/redux";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import chatSDK from "../sdk";
import {Message} from "@sense-os/goalie-js";

const log = createLogger("getMoreHistorySaga", SentryTags.Chat);

// Number of chat messages per request
const historyPageSize = 25;

function* getMoreHistoryHandler(action: ActionType<typeof ChatRoomAction.getMoreChatHistory>) {
	const {userId} = action.payload;

	const earliestMessage = yield select(chatRoomEarliestMessage(userId));
	const earliestId = earliestMessage ? earliestMessage.archiveId : undefined;

	yield put(ChatRoomAction.setChatRoomFetchingState(userId, LoadingState.LOADING));

	try {
		const chatHistory: Message[] = yield call(chatSDK.getChatHistory, userId, historyPageSize, earliestId);
		if (chatHistory.length === 0) {
			yield put(ChatRoomAction.setAllHistoryIsFetched(userId));
		} else {
			yield put(ChatRoomAction.addMessages(userId, chatHistory));
		}
		yield put(ChatRoomAction.setChatRoomFetchingState(userId, LoadingState.LOADED));
	} catch (err) {
		log.captureException(err);
		yield put(ChatRoomAction.setChatRoomFetchingState(userId, LoadingState.ERROR));
	}
}

export default function* getMoreHistorySaga() {
	yield takeEvery(getType(ChatRoomAction.getMoreChatHistory), getMoreHistoryHandler);
}
