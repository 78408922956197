import {useState, useEffect} from "react";
import * as React from "react";
import {useLocation} from "react-router";
import {FormattedMessage} from "react-intl";
import {Dialog, DialogTitle, DialogContent, DialogActions, Grid, Avatar, Typography, Button} from "@material-ui/core";
import loc from "../../localization/Localization";
import {
	getCurrentBrowserName,
	isCurrentBrowserSupported,
	getSupportedBrowserEntities,
} from "../helpers/browserWarningUtils";
import strTranslation from "../../assets/lang/strings";

interface Props {}

const BrowserWarningDialog: React.FC<Props> = () => {
	const moreInfoLink: string = "https://niceday.app/nl/spelregels/";
	const location = useLocation();
	const browserName = getCurrentBrowserName();
	const supportedBrowserEntities = getSupportedBrowserEntities();
	const [isOpen, setDialogOpen] = useState<boolean>(false);

	useEffect(() => {
		if (!isCurrentBrowserSupported()) {
			setDialogOpen(true);
		}
	}, [location]);

	const onContinue = () => {
		setDialogOpen(false);
	};

	return (
		<>
			<Dialog open={isOpen} maxWidth="sm" fullWidth>
				<DialogTitle>
					<FormattedMessage id={strTranslation.BROWSER.warn.header} />
				</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid item>
							{browserName
								? loc.formatMessage(strTranslation.BROWSER.warn.message.general, {browserName})
								: loc.formatMessage(strTranslation.BROWSER.warn.message.unidentified)}
						</Grid>
						<Grid container>
							{supportedBrowserEntities.map((browser) => {
								return (
									<Grid
										key={browser.name}
										item
										sm
										style={{
											marginTop: "30px",
											marginBottom: "25px",
											textAlign: "center",
										}}
									>
										<Avatar
											src={browser.imgUrl}
											style={{
												width: "54px",
												height: "54px",
												margin: "auto",
											}}
										/>
										<Typography variant="body1">{browser.name}</Typography>
										<a href={browser.link} target="_blank" rel="noreferrer">
											<FormattedMessage id={strTranslation.BROWSER.warn.button.download} />
										</a>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						component="a"
						href={moreInfoLink}
						target="_blank"
						rel="noreferrer"
						color="primary"
					>
						<FormattedMessage id={strTranslation.COMMON.button.more_info} />
					</Button>
					<Button id="continue" variant="text" onClick={onContinue} color="primary">
						<FormattedMessage id={strTranslation.COMMON.button.continue} />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default BrowserWarningDialog;
