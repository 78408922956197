import {RegisterResponse} from "@sense-os/goalie-js/dist/authentication/types";
import {ActionType, createAsyncAction, createAction} from "typesafe-actions";
import {AuthUser} from "../authTypes";

export const authActions = {
	login: createAsyncAction("authActions/login", "authActions/loginSucceed", "authActions/loginFailed")<
		{email: string; password: string},
		{user: AuthUser},
		{error: Error}
	>(),
	register: createAsyncAction("authActions/register", "authActions/registerSucceed", "authActions/registerFailed")<
		{email: string; password: string; firstName: string; lastName: string},
		{response: RegisterResponse},
		{error: Error}
	>(),
	resetPassword: createAsyncAction(
		"authActions/resetPassword",
		"authActions/resetPasswordSucceed",
		"authActions/resetPasswordFailed",
	)<{email: string}, {}, {error: Error}>(),
	changePassword: createAsyncAction(
		"authActions/changePassword",
		"authActions/changePasswordSucceed",
		"authActions/changePasswordFailed",
	)<{uid: string; token: string; password: string}, {}, {error: Error}>(),

	resetError: createAction("authActions/resetError")(),
	updateAuthUser: createAction("authActions/updateAuthUser", (user: Partial<AuthUser>) => user)(),

	logout: createAsyncAction("authActions/logout", "authActions/logoutSucceed", "authActions/logoutFailed")<
		{isSessionExpired?: boolean},
		{},
		{}
	>(),
	resetState: createAction("authActions/resetState")(),

	login2FACode: createAsyncAction(
		"authActions/login2FAcode",
		"authActions/login2FAcodeSucceed",
		"authActions/login2FAcodeFailed",
	)<{code: string}, {user: AuthUser}, {error: Error}>(),

	login2FARecoveryCode: createAsyncAction(
		"authActions/login2FARecoveryCode",
		"authActions/login2FARecoveryCodeSucceed",
		"authActions/login2FARecoveryCodeFailed",
	)<{code: string}, {user: AuthUser}, {error: Error}>(),

	resetPassword2FACode: createAsyncAction(
		"authActions/resetPassword2FACode",
		"authActions/resetPassword2FACodeSucceed",
		"authActions/resetPassword2FACodeFailed",
	)<{code: string; uid: string; token: string; password: string}, void, {error: Error}>(),

	resetPassword2FARecoveryCode: createAsyncAction(
		"authActions/resetPassword2FARecoveryCode",
		"authActions/resetPassword2FARecoveryCodeSucceed",
		"authActions/resetPassword2FARecoveryCodeFailed",
	)<{code: string; uid: string; token: string; password: string}, void, {error: Error}>(),

	fetchEmailVerificationStatus: createAsyncAction(
		"authActions/fetchEmailVerificationStatus",
		"authActions/fetchEmailVerificationStatusSucceed",
		"authActions/fetchEmailVerificationStatusFailed",
	)<{}, {isVerified: boolean}, {}>(),
	blockLogin: createAction("authActions/blockLogin")(),
	unblockLogin: createAction("authActions/unblockLogin")(),
	resetLoginLoadingState: createAction("authActions/resetLoginLoadingState")(),
	initLoggedInUser: createAction("authActions/initLoggedInUser", (user: AuthUser) => {
		return {user};
	})(),
};

export type AuthActionType = ActionType<typeof authActions>;
