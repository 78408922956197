import {call, put, takeEvery, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {customTrackerActions} from "../redux/customTrackerActions";
import customTrackerSDK from "../helpers/customTrackerSDK";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {TrackerCategory as SDKTrackerCategory} from "@sense-os/goalie-js";
import {transformSDKTrackerCategory, getCustomTrackerVersion} from "../helpers/customTrackerHelpers";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {getSelectedContactId} from "../../../contacts/redux/contactSelectors";
import trackersSDK from "../helpers/trackersSDK";
import {toastActions} from "../../../toaster/redux";
import strTranslation from "../../../assets/lang/strings";
import localization from "../../../localization/Localization";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";

const log = createLogger("setupTrackerSaga", SentryTags.Tracking);

function* handleLoadTrackerCategories() {
	try {
		const token = yield call(getSessionId);
		const trackerVersion = yield call(getCustomTrackerVersion);
		const response: SDKTrackerCategory[] = yield apiCallSaga(
			customTrackerSDK.getTrackerCategories,
			token,
			trackerVersion,
		);
		const trackerCategories = response.map(transformSDKTrackerCategory);
		yield put(customTrackerActions.loadTrackerCategories.success(trackerCategories));
	} catch (err) {
		log.captureException(err);
		yield put(customTrackerActions.loadTrackerCategories.failure(err));
	}
}

function* handleAddTracker(action: ActionType<typeof customTrackerActions.addTracker.request>) {
	const {tracker} = action.payload;

	try {
		const token: string = yield call(getSessionId);
		const userId: number = yield select(getSelectedContactId);
		yield call(trackersSDK.updateTrackerStatus, token, {
			isEnabled: true,
			tracker: tracker.id,
			patient: userId,
		});
		yield put(customTrackerActions.addTracker.success({userId, tracker}));
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.CUSTOM_TRACKER.tracker_added_dialog.title, {
					trackerName: tracker.trackerName,
				}),
				type: "success",
			}),
		);
		yield put(customTrackerActions.closeSetupTrackerDialog());
		yield put(customTrackerActions.showTrackerAddedDialog(tracker));
	} catch (err) {
		log.captureException(err);
		yield put(customTrackerActions.addTracker.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(customTrackerActions.loadTrackerCategories.request), handleLoadTrackerCategories);
	yield takeEvery(getType(customTrackerActions.addTracker.request), handleAddTracker);
}
