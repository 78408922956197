import {call, cancel, delay, fork, put, select, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {toastActions} from "../../toaster/redux/toastAction";
import {timeTrackingActions} from "../redux/timeTrackingActions";

import {
	getDateBorders,
	getTodaysDate,
	getYesterdaysDate,
	shiftDate,
	processTimeTrackingEntries,
	dateToString,
} from "../timeTrackingHelpers";
import {DateString} from "../timeTrackingTypes";
import {getEntriesCache, getSelectedDate} from "../redux/timeTrackingSelectors";
import {Task} from "redux-saga";
import {TimeTrackingEntry} from "@sense-os/goalie-js";
import Localization, {ILocalization} from "../../localization/Localization";
import timeTrackingSdk from "../timeTrackingSdk";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import moment from "moment";
import strTranslation from "../../assets/lang/strings";

const loc: ILocalization = Localization;

function* handleRequestFetchEntry(action: ActionType<typeof timeTrackingActions.fetchEntries.request>) {
	const {date} = action.payload;

	const token: string = getSessionId();
	const {start, end} = getDateBorders(date);
	const entries: TimeTrackingEntry[] = yield apiCallSaga(timeTrackingSdk.getTimeEntries, token, {
		startTime: start,
		endTime: end,
	});

	yield put(timeTrackingActions.fetchEntries.success({date, entries: processTimeTrackingEntries(entries)}));
}

/**
 * How long to wait before starting to fetch
 * time tracking data while the user clicks through dates.
 */
const NAVIGATION_TIMEOUT_MS = 750;

/**
 * This task is part of a hack to allow portal to cancel
 * a request to `fetchDay` if the user decides to navigate to other day/page quickly.
 */
let fetchDayTask: Task = null;

/**
 * Fetches time tracking entries for a given day and places them into the store
 * @param date
 */
function* fetchDay(date: DateString) {
	// wait to check if the user is clicking through the days faster than `NAVIGATION_TIMEOUT_MS`
	yield delay(NAVIGATION_TIMEOUT_MS);
	fetchDayTask = null; // after this point this fetch task is no longer cancellable!

	yield put(timeTrackingActions.fetchEntries.request({date}));
}

/**
 * 1. Cancels the existing data download task (if any) for the previously selected date.
 * 2. Initiates a new data download task for the currently selected date.
 * @param userSelectedDate
 */
function* scheduleFetching(userSelectedDate: DateString) {
	if (fetchDayTask) {
		yield cancel(fetchDayTask);
		fetchDayTask = null;
	}

	fetchDayTask = yield fork(fetchDay, userSelectedDate);
}

function* handleGoToPrevDay(/*action: ActionType<typeof ... >*/) {
	let userSelectedDate: DateString = yield select(getSelectedDate);
	userSelectedDate = shiftDate(userSelectedDate, "-1");

	yield put(timeTrackingActions.setDate(userSelectedDate));
	yield fork(scheduleFetching, userSelectedDate);
}

function* handleGoToNextDay(/*action: ActionType<typeof ... >*/) {
	const userSelectedDate: DateString = yield select(getSelectedDate);
	const nextDay = shiftDate(userSelectedDate, "+1");
	if (nextDay === userSelectedDate) {
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.TIME_TRACKING.navigation.toast.future_unavailable),
				type: "info",
			}),
		);
		return;
	}

	yield put(timeTrackingActions.setDate(nextDay));
	yield fork(scheduleFetching, nextDay);
}

function* handleGoToToday(/*action: ActionType<typeof ... >*/) {
	yield put(timeTrackingActions.setDate(getTodaysDate()));
	const userSelectedDate: DateString = yield select(getSelectedDate);
	yield fork(scheduleFetching, userSelectedDate);
}

function* handleGoToDate({payload: selectedDate}: ActionType<typeof timeTrackingActions.goToDate>) {
	// Convert to date string
	const formattedDate = dateToString(selectedDate);
	// Set date in redux state
	yield put(timeTrackingActions.setDate(formattedDate));
	// Fetch the data
	yield fork(scheduleFetching, formattedDate);
}

function* fetchEntriesForCalendarIfNeeded(
	action: ActionType<typeof timeTrackingActions.fetchEntriesForCalendarIfNeeded>,
) {
	const {month, year} = action.payload;
	let startMonth = moment().set("year", year).set("month", month).startOf("month");
	const endMonth = moment(startMonth).endOf("month");

	const dateList: string[] = [];
	while (!startMonth.isAfter(endMonth, "date")) {
		dateList.push(moment(startMonth).format("YYYY-MM-DD"));
		startMonth.add(1, "day");
	}

	const cacheMap: Record<DateString, TimeTrackingEntry[]> = yield select(getEntriesCache);
	const needToFetch = dateList.some((date) => cacheMap[date] === undefined);

	if (!needToFetch) return;

	const token = yield call(getSessionId);
	const entries: TimeTrackingEntry[] = yield apiCallSaga(timeTrackingSdk.getTimeEntries, token, {
		startTime: moment().set("year", year).set("month", month).startOf("month").toDate(),
		endTime: endMonth.toDate(),
	});

	const updatedMap: Record<DateString, TimeTrackingEntry[]> = {};
	dateList.forEach((date) => (updatedMap[date] = []));
	entries.forEach((entry) => {
		const date = moment(entry.startTime).format("YYYY-MM-DD");
		updatedMap[date].push(entry);
	});

	for (let idx = 0; idx < dateList.length; idx++) {
		const dateStr = dateList[idx];
		updatedMap[dateStr].sort((a, b) => {
			if (a.startTime < b.startTime) return -1;
			if (a.startTime > b.startTime) return 1;
			return 0;
		});

		yield put(
			timeTrackingActions.fetchEntries.success({
				date: dateStr,
				entries: processTimeTrackingEntries(updatedMap[dateStr]),
			}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(timeTrackingActions.fetchEntries.request), handleRequestFetchEntry);
	yield takeEvery(getType(timeTrackingActions.goToPrevDay), handleGoToPrevDay);
	yield takeEvery(getType(timeTrackingActions.goToNextDay), handleGoToNextDay);
	yield takeEvery(getType(timeTrackingActions.goToDate), handleGoToDate);
	yield takeEvery(getType(timeTrackingActions.goToToday), handleGoToToday);
	yield takeEvery(getType(timeTrackingActions.fetchEntriesForCalendarIfNeeded), fetchEntriesForCalendarIfNeeded);

	// A quick and dirty way to load today's and yesterday's entries
	yield call(scheduleFetching, getTodaysDate());
	yield call(scheduleFetching, getYesterdaysDate());
}
