/**
 * A helper function that can transform an array of T (as colls in the function argumen)
 * into a Record<string, T> based on the given function fn.
 * @param colls - Array to be grouped to.
 * @param fn - Function to get identity of each element in colls.
 * @returns
 */
export function arrToMap<T>(colls: T[], fn: (x: T) => string | number): Record<string | number, T> {
	const map: Record<string | number, T> = {};
	colls.forEach((item) => {
		map[fn(item)] = item;
	});

	return map;
}
