import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {getTherapySessionSensors} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {assessmentActions} from "../redux/assessmentActions";

const logOmq = createLogger("fetchOmq", SentryTags.TherapySession);

export function* fetchOmq(action: ActionType<typeof assessmentActions.fetchOmqSession.request>) {
	const {userId, omqId} = action.payload;
	const token: string = yield call(getSessionId);

	try {
		const results: SensorDatum<PlannedEventEntry>[] = yield apiCallSaga(
			getTherapySessionSensors,
			token,
			userId,
			undefined,
			undefined,
			[omqId],
		);
		yield put(assessmentActions.fetchOmqSession.success({data: results[0]}));
	} catch (err) {
		logOmq.captureException(err);
		yield put(assessmentActions.fetchOmqSession.failure(err));
	}
}

const logSmq = createLogger("fetchSmq", SentryTags.TherapySession);

export function* fetchSmq(action: ActionType<typeof assessmentActions.fetchSmqSession.request>) {
	const {userId, smqId} = action.payload;
	const token: string = yield call(getSessionId);
	try {
		const results: SensorDatum<PlannedEventEntry>[] = yield apiCallSaga(
			getTherapySessionSensors,
			token,
			userId,
			undefined,
			undefined,
			[smqId],
		);
		yield put(assessmentActions.fetchSmqSession.success({data: results[0]}));
	} catch (err) {
		logSmq.captureException(err);
		yield put(assessmentActions.fetchSmqSession.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(assessmentActions.fetchOmqSession.request), fetchOmq);
	yield takeEvery(getType(assessmentActions.fetchSmqSession.request), fetchSmq);
}
