import {LoadingState} from "constants/redux";
import produce from "immer";
import {getType} from "typesafe-actions";

import {UserSettings} from "../userSettingsTypes";
import {userSettingsAction, UserSettingsActions} from "./userSettingsActions";

const defaultState: UserSettings = {
	omqSmq: {
		isOmqEnabled: true,
		isSmqEnabled: true,
	},
	customTracker: {
		isTrackerAddedDialogChecked: false,
	},
	userSettingsLoadingState: LoadingState.EMPTY,
	flagForRecentChangesPopup: {},
	trackerOrderMap: {},
	timeTrackingUserSettings: {},
	isEmdrEnabled: false,
	defaultTimeRangeForActivitiesDashboard: null,
	calendarUserSettings: {
		defaultCalendarId: null,
		calendarColors: [],
		calendarsVisibility: [],
	},
	userSettingsSavingState: LoadingState.EMPTY,
	migrationFlags: {},
};

export const userSettingsReducer = produce((state: UserSettings, action: UserSettingsActions) => {
	switch (action.type) {
		case getType(userSettingsAction.loadSettings.request): {
			return {...state, userSettingsLoadingState: LoadingState.LOADING};
		}
		case getType(userSettingsAction.loadSettings.failure): {
			return {...state, userSettingsLoadingState: LoadingState.ERROR};
		}
		case getType(userSettingsAction.loadSettings.success): {
			// For the new user, they could have an empty settings.
			// However, the SDK tries to access portal settings which lead to the `settings` value to be undefined.
			// Hence, we have to check the action payload. When it's undefined, set the payload with the `defaultState`.
			const payload = action.payload.settings || defaultState;
			return {...state, ...payload, userSettingsLoadingState: LoadingState.LOADED};
		}

		/**
		 * We also add `userSettingsAction.saveSettings.request` here,
		 * so portal can optimistically store the updates and also updates the view.
		 */
		case getType(userSettingsAction.saveSettings.request):
		case getType(userSettingsAction.saveSettings.success): {
			return {...state, ...action.payload.settings};
		}

		case getType(userSettingsAction.saveTrackerOrderSettings.request): {
			return {...state, userSettingsSavingState: LoadingState.LOADING};
		}
		case getType(userSettingsAction.saveTrackerOrderSettings.success): {
			return {...state, userSettingsSavingState: LoadingState.LOADED};
		}
		case getType(userSettingsAction.saveTrackerOrderSettings.failure): {
			return {...state, userSettingsSavingState: LoadingState.ERROR};
		}

		case getType(userSettingsAction.setUserSettingsSavingState): {
			return {...state, userSettingsSavingState: action.payload.savingState};
		}
	}
}, defaultState);
