import {getType} from "typesafe-actions";
import produce from "immer";
import {InterventionTreatmentGoalState} from "../interventionTreatmentGoalType";
import {
	interventionTreatmentGoalActions,
	interventionTreatmentGoalActionType,
} from "./interventionTreatmentGoalActions";
import {LoadingState} from "constants/redux";

const initialState: InterventionTreatmentGoalState = {
	/**
	 * Initial treatment status value
	 */
	treatmentStatus: null,
	/**
	 * Flag to mark fetching status for intervention treatment goal
	 */
	loadingState: LoadingState.EMPTY,
	/**
	 * Flag to mark saving status for intervention treatment goal
	 */
	savingState: LoadingState.EMPTY,
	/**
	 * Flag to mark opened modal
	 */
	isModalOpen: false,
};

export const interventionTreatmentGoalReducer = produce(
	(state: InterventionTreatmentGoalState = initialState, action: interventionTreatmentGoalActionType) => {
		switch (action.type) {
			case getType(interventionTreatmentGoalActions.getDetailTreatment.request): {
				state.loadingState = LoadingState.LOADING;
				state.treatmentStatus = null;
				return;
			}
			case getType(interventionTreatmentGoalActions.getDetailTreatment.success): {
				const {treatment} = action.payload;
				state.loadingState = LoadingState.LOADED;
				state.treatmentStatus = treatment;
				return;
			}
			case getType(interventionTreatmentGoalActions.getDetailTreatment.failure): {
				state.loadingState = LoadingState.ERROR;
				return;
			}
			case getType(interventionTreatmentGoalActions.putTreatmentStatus.request): {
				state.savingState = LoadingState.LOADING;
				return;
			}
			case getType(interventionTreatmentGoalActions.putTreatmentStatus.success): {
				const {treatment} = action.payload;

				state.savingState = LoadingState.LOADED;
				state.treatmentStatus = treatment;
				return;
			}
			case getType(interventionTreatmentGoalActions.putTreatmentStatus.failure): {
				state.savingState = LoadingState.ERROR;
				return;
			}
			case getType(interventionTreatmentGoalActions.openModal): {
				const {isOpen} = action.payload;

				state.isModalOpen = isOpen;
				// to ensure if the modal is opened or not the `savingState` should be start from "LoadingState.EMPTY"
				state.savingState = LoadingState.EMPTY;
				return;
			}
		}
	},
	initialState,
);
