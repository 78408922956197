import {ActionType, getType} from "typesafe-actions";
import {inviteClientsActions} from "../redux/inviteClientsActions";
import {call, put, takeEvery} from "redux-saga/effects";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import inviteClientsSDK from "../helpers/inviteClientsSdk";
import {InviteClientsResponseList} from "@sense-os/goalie-js";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {autoRemoveInviteClients} from "./autoRemoveInviteClients";

const log = createLogger("getInviteClientsListSaga", SentryTags.InviteClients);

export function* getInviteClientsList(action: ActionType<typeof inviteClientsActions.fetchInviteClientsList.request>) {
	const {currentPage, pageSize} = action.payload;

	try {
		const token: string = getSessionId();

		let response: InviteClientsResponseList = yield apiCallSaga(inviteClientsSDK.getInviteClientsList, token, {
			page: currentPage,
			pageSize,
		});

		// TODO: remove this, if the backend has provide this logic
		yield call(autoRemoveInviteClients, response.results);

		response = yield apiCallSaga(inviteClientsSDK.getInviteClientsList, token, {
			page: currentPage,
			pageSize,
		});

		yield put(
			inviteClientsActions.fetchInviteClientsList.success({
				data: response,
			}),
		);
	} catch (error) {
		log.error("Error while fetching invite client list", error);
		yield put(inviteClientsActions.fetchInviteClientsList.failure({error}));
	}
}

export default function* () {
	yield takeEvery(getType(inviteClientsActions.fetchInviteClientsList.request), getInviteClientsList);
}
