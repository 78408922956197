import {getType} from "typesafe-actions";
import {taskRegistrationActionType, taskRegistrationActions} from "./taskRegistrationActions";
import analyticsService from "../../analytics/AnalyticsService";

const trackedActions = [
	taskRegistrationActions.saveTask.request,
	taskRegistrationActions.saveTask.success,
	taskRegistrationActions.saveTask.failure,
].map(getType);

export const taskEvents = analyticsService.trackReduxActions(trackedActions, (action: taskRegistrationActionType) => {
	switch (action.type) {
		case getType(taskRegistrationActions.saveTask.request): {
			return {
				id: action.payload.taskId,
				formValues: action.payload.formValues,
				formAction: action.payload.formAction,
				tracker: action.payload.tracker,
			};
		}
		default: {
			return null;
		}
	}
});
