// Call window instance
let inst: Window = null;

const defaultWindowSize = {
	width: 860,
	height: 600,
};

/**
 * Creates call window instance if there isn't any
 */
export function createCallWindow(size: typeof defaultWindowSize = defaultWindowSize): Window {
	if (inst) {
		return inst;
	}
	inst = window.open("/video.html", "_blank", computeVideoWindowPosition(size.width, size.height));
	return inst;
}

export function getCallWindow(): Window | null {
	return inst;
}

export function resetCallWindow(): void {
	inst = null;
}

function computeVideoWindowPosition(videoWidth: number, videoHeight: number): string {
	let left: number;
	try {
		let screenLeft = window.screenLeft || window.screenX;
		const width = screen.width;
		if (screenLeft < width) {
			screenLeft = 0;
		} else if (screenLeft > width) {
			screenLeft = width;
		}
		left = (width - videoWidth) / 2 + screenLeft;
	} catch (e) {
		left = 0;
	}

	return `width=${videoWidth}, height=${videoHeight}, left=${left}`;
}
