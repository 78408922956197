import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {TrackerItem, TrackerItemsResponse} from "@sense-os/goalie-js";
import {customTrackerActions} from "../redux/customTrackerActions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {
	getCustomTrackerVersion,
	transformSensorDatumsToTrackerDataMap,
	transformTrackerItem,
} from "../helpers/customTrackerHelpers";
import {TrackerDataMap} from "../customTrackerTypes";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {Sensors} from "redux/tracking/TrackingTypes";
import trackersSDK from "../helpers/trackersSDK";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSensorResolved} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {SensorDatum} from "redux/tracking/TrackingTypes";

const log = createLogger("userTrackerSaga", SentryTags.Tracking);

function* handleLoadUserTrackers(action: ActionType<typeof customTrackerActions.loadUserTrackers.request>) {
	const {hashId} = action.payload;

	try {
		const token: string = yield call(getSessionId);
		const trackerVersion: number = yield call(getCustomTrackerVersion);

		const response: TrackerItemsResponse = yield call(trackersSDK.getTrackerListItems, token, {
			patient: hashId,
			version: trackerVersion,
			// to prevent  default page size (10) from API response
			pageSize: 100,
		});
		const {results: trackers} = response;

		// Make it sort by tracker id because it will sort by userTracker id by default
		const transformedTrackers: TrackerItem[] = trackers
			.sort((a, b) => {
				return a.id - b.id;
			})
			.map((tracker) => {
				return transformTrackerItem(tracker);
			});

		// filter duplicate data from backend
		// TODO : remove this if backend has fixed
		// https://github.com/senseobservationsystems/goalie-backend/issues/4101
		const transformedTrackersNonDuplicate = transformedTrackers.filter((tracker, i) => {
			return tracker.id !== transformedTrackers[i - 1]?.id;
		});

		yield put(
			customTrackerActions.loadUserTrackers.success({
				hashId,
				trackers: transformedTrackersNonDuplicate,
			}),
		);
	} catch (err) {
		log.captureException(err, {message: "Unable to load user trackers"});
		yield put(customTrackerActions.loadUserTrackers.failure({error: err, hashId}));
	}
}

function* handleLoadTrackerData(action: ActionType<typeof customTrackerActions.loadTrackerData.request>) {
	const {userId, hashId, interval} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		// Cast to `Sensors[]` because `getSensorResolved` only accepts `Sensors`
		// TODO: Improve the typings
		const sensorNames = action.payload.sensorNames as Sensors[];

		const results: SensorDatum<any>[] = yield apiCallSaga(
			getSensorResolved,
			token,
			userId,
			sensorNames,
			interval.start,
			interval.end,
		);
		const trackerDataMap: TrackerDataMap = transformSensorDatumsToTrackerDataMap(results, sensorNames);

		yield put(
			customTrackerActions.loadTrackerData.success({
				hashId,
				trackerDataMap,
			}),
		);
	} catch (error) {
		yield put(customTrackerActions.loadTrackerData.failure({hashId, error}));
	}
}

export default function* () {
	yield takeEvery(getType(customTrackerActions.loadUserTrackers.request), handleLoadUserTrackers);
	yield takeEvery(getType(customTrackerActions.loadTrackerData.request), handleLoadTrackerData);
}
