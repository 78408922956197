import piexif from "piexifjs";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {
	FileItem,
	FileTypeFilter,
	PersonFilter,
	SelectedFile,
	ValidationType,
	MAX_FILES_AMOUNT,
	MAX_FILE_SIZE,
	ImageSize,
	MAX_IMAGE_SIZE,
} from "../fileSharingTypes";
import {groupBy} from "../../ts/utils/arrayUtils";
import {getAuthUserId} from "../../auth/helpers/authStorage";
import loc from "../../localization/Localization";
import {OptionItem} from "../views/filesTab/FilterDropdown";
import {Contact} from "../../contacts/contactTypes";
import {LoadingState} from "constants/redux";
import {FileChatItem, UploadFilesChatRequestPayload} from "@sense-os/goalie-js/dist";
import {convertB64toBlob, resizeB64Image, resizeFileImage} from "./imageResizer";

const log = createLogger("fileSharingHelpers", SentryTags.FileSharing);

export const urlCreator = window.URL || window.webkitURL;

/**
 * Check is given file url whether image file or not.
 */
export const isImageFileUrl = (file: FileItem | SelectedFile | File): boolean => {
	if (file instanceof File) {
		return file.type.includes("image") && /.(jpg|jpeg|png)$/.test(file?.name?.toLowerCase());
	}
	return /.(jpg|jpeg|png)$/.test(file?.fileName?.toLowerCase());
};

/**
 * Grouped chat files based on shared month
 */
export const getMonthlyGroupedFiles = (files: FileItem[]) => {
	if (files.length === 0) {
		return null;
	}
	// Sort chat files from the latest before grouped it.
	const sortedFiles = files.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf());

	return groupBy(sortedFiles, (file) => file.timestamp.getMonth());
};

/**
 * Get option list of filter by type
 */
export const getFilterByTypeOptions = () => {
	const fileTypeFilterOptions: OptionItem<FileTypeFilter>[] = [];

	Object.keys(FileTypeFilter).forEach((key) => {
		fileTypeFilterOptions.push({
			label: loc.formatMessage(`FILE_SHARING.files_tab.filter.by_type.${FileTypeFilter[key]}`),
			value: FileTypeFilter[key],
		});
	});
	return fileTypeFilterOptions;
};

/**
 * Get option list of person filter
 */
export const getFilterByPersonOptions = (selectedContact: Contact) => {
	if (!selectedContact) {
		return null;
	}

	const personFilterOptions: OptionItem<PersonFilter>[] = [];

	Object.keys(PersonFilter).forEach((key) => {
		personFilterOptions.push({
			label: loc.formatMessage(`FILE_SHARING.files_tab.filter.by_name.${PersonFilter[key]}`, {
				name: selectedContact.fullName,
			}),
			value: PersonFilter[key],
		});
	});
	return personFilterOptions;
};

/**
 * Filter given files based on person filter
 */
export const getFilteredFilesByPerson = (files: FileItem[], filter: string, selectedContactId: number): FileItem[] => {
	if (!files) {
		return null;
	}

	if (filter === PersonFilter.ME) {
		return files.filter((file) => file.authorId === getAuthUserId());
	} else if (filter === PersonFilter.NAME) {
		return files.filter((file) => file.authorId === selectedContactId);
	}
	// TODO: @puguh remove this filter IF the backend returned only `authUser` and `selectedContact` chat files.
	return files.filter((file) => file.authorId === getAuthUserId() || file.authorId === selectedContactId);
};

/**
 * Filter given files based on file type filter
 */
export const getFilteredFilesByFileType = (files: FileItem[], filter: string): FileItem[] => {
	if (!files) {
		return null;
	}

	if (filter === FileTypeFilter.MEDIA) {
		return files.filter(isImageFileUrl);
	} else if (filter === FileTypeFilter.DOCUMENTS) {
		// Currently only support `pdf` file only
		return files.filter(isDocumentFile);
	}

	return files;
};

/**
 * Translate monthly grouped chat files
 */
export const getTranslatedMonthlyGroupedText = (files: FileItem[]): string => {
	if (!files[0]) {
		return null;
	}
	// Month and year text
	const monthText = files[0].timestamp.toLocaleString(loc.getLocale(), {
		month: "long",
		year: "numeric",
	});
	// Total files text
	const totalFiles = `(${files.length.toString()})`;

	return [monthText, totalFiles].join(" ");
};

/**
 * Convert given `FileList` into `SelectedFile`
 */
export const convertFileListIntoSelectedFile = async (files: File[]): Promise<SelectedFile[]> => {
	if (!files) {
		return null;
	}

	let selectedFiles: SelectedFile[] = [];

	for (let i = 0; i < files.length; i++) {
		const currentFile = files[i];
		const base64File = await getBase64StringFromFile(currentFile);
		let thumb_hdpi: Blob = null;
		let thumb_mdpi: Blob = null;
		let thumb_ldpi: Blob = null;
		let imageData: Blob = null;

		if (isImageFileUrl(currentFile)) {
			thumb_hdpi = await resizeB64Image(base64File, ImageSize.HIGH);
			thumb_mdpi = await resizeB64Image(base64File, ImageSize.MEDIUM);
			thumb_ldpi = await resizeB64Image(base64File, ImageSize.LOW);
		}

		if (isNeedToBeResized(currentFile)) {
			try {
				imageData = await resizeFileImage(currentFile);
			} catch (e) {
				return e;
			}
		}
		selectedFiles.push({
			fileData: isNeedToBeResized(currentFile) ? imageData : convertB64toBlob(base64File),
			blobUrl: urlCreator.createObjectURL(files[i]),
			fileName: files[i].name,
			fileType: files[i].type,
			fileSize: files[i].size,
			thumb_hdpi,
			thumb_mdpi,
			thumb_ldpi,
			uploadingState: LoadingState.EMPTY,
		});
	}
	return selectedFiles;
};

/*
 * Check is file needs to be resized
 * Based on this, we need to resize image that more than 1MB
 * https://github.com/senseobservationsystems/web-getgoalie/issues/5318
 */

const MINIMUM_FILE_SIZE_NEEDS_TO_BE_RESIZED = 1000;
const isNeedToBeResized = (file: File): boolean => {
	if (isImageFileUrl(file) && file.size > MINIMUM_FILE_SIZE_NEEDS_TO_BE_RESIZED) {
		return true;
	}
	return false;
};

/**
 * Get base64 string from File
 */
export const getBase64StringFromFile = (file: File): Promise<string> => {
	const reader = new FileReader();

	return new Promise((resolve, reject) => {
		reader.onload = () => {
			let base64 = reader.result;

			// Remove EXIF data if it's a jpeg file
			if (/(jpeg)$/.test(file.type)) {
				try {
					base64 = piexif.remove(reader.result);
				} catch (error) {
					log.captureException(error);
				}
			}

			resolve(base64.toString());
		};

		reader.onerror = (error) => {
			reject(error);
			log.captureException(error);
		};

		reader.readAsDataURL(file);
	});
};

/**
 * Merged given `FileList` and existing `SelectedFile`
 */
export const getMergedSelectedFiles = (newFiles: SelectedFile[], existingFiles: SelectedFile[]): SelectedFile[] => {
	let mergedSelectedFiles: SelectedFile[] = [];

	if (existingFiles) {
		// Only merged when user already have `SelectedFiles` in redux.
		mergedSelectedFiles = existingFiles.concat(newFiles);
	} else {
		mergedSelectedFiles = newFiles;
	}

	return mergedSelectedFiles;
};

/**
 * Check is given `files` include not allowed file type
 */
const isNotAllowedTypeIncluded = (files: SelectedFile[]): boolean => {
	if (!files) {
		return null;
	}

	let isTypeIncluded: boolean = false;

	files.forEach((file) => {
		if (!file.fileType.toLowerCase().match(/(jpg|jpeg|png|pdf)$/i)) {
			isTypeIncluded = true;
		}
	});

	return isTypeIncluded;
};

/**
 * Check is given `files` include not allowed file size
 * we need to check files type !== image
 */
const isNotAllowedFileSizeIncluded = (files: SelectedFile[]): boolean => {
	if (!files) {
		return null;
	}
	return !!files.find((file) => {
		if (file.fileType && !isImageFileUrl(file)) {
			return file.fileSize > MAX_FILE_SIZE;
		}
	});
};

/**
 * Check is given `image` include not allowed file size
 */
const isNotAllowedImageSizeIncluded = (files: SelectedFile[]): boolean => {
	if (!files) {
		return null;
	}
	return !!files.find((file: SelectedFile) => {
		if (file.fileType && isImageFileUrl(file)) {
			return file.fileSize > MAX_IMAGE_SIZE;
		}
	});
};

/**
 * Return `ValidationType` according to given `SelectedFile`
 */
export const getFilesValidation = (files: SelectedFile[]): ValidationType => {
	if (files.length > MAX_FILES_AMOUNT) {
		return ValidationType.FILE_AMOUNT;
	} else if (isNotAllowedTypeIncluded(files)) {
		return ValidationType.FILE_TYPE;
	} else if (isNotAllowedImageSizeIncluded(files)) {
		return ValidationType.IMAGE_SIZE;
	} else if (isNotAllowedFileSizeIncluded(files)) {
		return ValidationType.FILE_SIZE;
	}
};

/**
 * To check if the file is a pdf document based on the extenstion of file.
 */
export const isDocumentFile = (file: FileItem | SelectedFile): boolean => {
	return /.(pdf)$/.test(file?.fileName.toLowerCase());
};

export const fileChatToFileItemParser = (fileChat: FileChatItem): FileItem => {
	const fileItem: FileItem = {
		url: typeof fileChat.file === "string" ? fileChat.file : "",
		blobUrl: null,
		thumbnailUrl: typeof fileChat.thumbHdpi === "string" ? fileChat.thumbHdpi : "",
		blobThumbnailUrl: null,
		id: fileChat.id,
		authorId: fileChat.sender,
		fileName: fileChat.name,
		fileSize: fileChat.size,
		timestamp: fileChat.updatedAt,
		description: fileChat.caption,
	};

	return fileItem;
};

export const fileChatToUploadRequest = (
	file: SelectedFile,
	userId: number,
	caption: string,
): UploadFilesChatRequestPayload => ({
	receiver: userId,
	name: file.fileName,
	file: file.fileData,
	thumb_hdpi: file.thumb_hdpi,
	thumb_ldpi: file.thumb_ldpi,
	thumb_mdpi: file.thumb_mdpi,
	caption: isDocumentFile(file) ? "" : caption,
});

/**
 * Add a collection of FileItem to another collection of FileItem,
 * while guaranteeing uniqueness and sorted ordering.
 */
export function mergeFileItems(currentFileItem: FileItem[], newFileItem: FileItem[]) {
	return newFileItem
		.reduce(
			(accum: FileItem[], file: FileItem) => {
				const index = accum.findIndex((m) => m.id === file.id);
				if (index !== -1) {
					accum[index] = {
						...accum[index],
						blobThumbnailUrl: accum[index].blobThumbnailUrl || file.blobThumbnailUrl,
						blobUrl: accum[index].blobUrl || file.blobUrl,
					};
					return accum;
				}

				accum.push(file);
				return accum;
			},
			[...currentFileItem],
		)
		.sort((firstMsg, secondMsg) => secondMsg.timestamp?.getTime() || 0 - firstMsg.timestamp?.getTime() || 0);
}
