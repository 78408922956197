import {ConnectionStatus} from "@sense-os/goalie-js";
import {Reducer} from "redux";
import {getType} from "typesafe-actions";

import {ChatAction} from "./ChatAction";

export interface ChatState {
	/** Chat connection status */
	connectionStatus: ConnectionStatus;
	/** True if chat has error (On loading chat, sending chat, etc.) */
	hasError: boolean;
}

const chatEmptyState: ChatState = {
	connectionStatus: ConnectionStatus.Disconnected,
	hasError: false,
};

export const ChatReducer: Reducer<ChatState> = (
	state: ChatState = chatEmptyState,
	action: ChatAction.ChatActionType,
): ChatState => {
	switch (action.type) {
		case getType(ChatAction.reportChatError):
			return {...state, hasError: action.payload.isError};

		case getType(ChatAction.setConnectionStatus):
			return {...state, connectionStatus: action.payload.connectionStatus};

		default:
			return state;
	}
};
