import {Message, TerminationReason, ParticipantData} from "@sense-os/goalie-js";
import {TIME} from "constants/time";
import {getFullName} from "../../userProfile/helpers/profileHelpers";

export function isCallSummary(msg: Message): boolean {
	return !!msg.content.GROUPCALL || !!msg.content.CALL;
}

export function isGroupCallSummary(msg: Message): boolean {
	return !!msg.content.GROUPCALL;
}

export function isLegacyCallSummary(msg: Message): boolean {
	return !!msg.content.CALL;
}

/**
 * Per https://github.com/senseobservationsystems/web-getgoalie/issues/3365,
 * portal need to differentiate whether a message is markable or not.
 * At this point, only sent text message can be marked as read or delivered.
 */
export const isMarkable = (message: Message, ownId: number) => {
	return message.from === ownId && !isCallSummary(message);
};

/**
 * Returns call duration between two users.
 */
export function getCallDurationBetweenUserId(msg: Message, userIds: [number, number]) {
	if (!isCallSummary(msg)) {
		return 0;
	}

	if (isLegacyCallSummary(msg)) {
		return msg.content.CALL.duration;
	}

	const {participants} = msg.content.GROUPCALL;
	const [userId1, userId2] = userIds;

	const callData1 = participants[userId1];
	const callData2 = participants[userId2];
	if (!callData1 || !callData2) {
		return 0;
	}

	const leaveTime = getLeaveTimeBetweenParticipants(callData1.leaveTime, callData2.leaveTime) || msg.timestamp; // Use message timestamp as leaveTime replacement
	const joinedTime = Math.max(callData1.joinedTime || 0, callData2.joinedTime || 0);

	if (isEmptyTimestamp(joinedTime)) {
		return 0;
	}

	return (leaveTime - joinedTime) / TIME.MILLISECONDS_IN_SECOND;
}

/**
 * True if timestamp is `0`, null, or undefined
 */
function isEmptyTimestamp(timestamp: number | null | undefined) {
	return timestamp === 0 || timestamp === null || timestamp === undefined;
}

/**
 * Returns `leaveTime` between two participants.
 * If either of one is undefined, returns the one which isn't.
 */
function getLeaveTimeBetweenParticipants(leaveTime1: number | undefined, leaveTime2: number | undefined): number {
	if (isNaN(leaveTime1)) {
		return leaveTime2;
	}
	if (isNaN(leaveTime2)) {
		return leaveTime1;
	}
	return Math.min(leaveTime1, leaveTime2);
}

export function getTerminationReason(msg: Message): TerminationReason {
	if (isLegacyCallSummary(msg)) {
		return msg.content.CALL.reasonOfTermination;
	}

	return msg.content.GROUPCALL.reasonOfTermination;
}

/**
 * Returns true if both participants have talked to each other
 * (There is an intersection between these two persons joinedTime and leaveTime interval)
 * @param participant1
 * @param participant2
 */
export function participantsTalkedToEachOther(msg: Message, participantId1: number, participantId2: number): boolean {
	if (isLegacyCallSummary(msg)) {
		return true;
	}

	const participant1 = getParticipantData(msg, participantId1);
	const participant2 = getParticipantData(msg, participantId2);
	return (
		participant1 &&
		!!participant1.joinedTime &&
		participant2 &&
		!!participant2.joinedTime &&
		(!participant1.leaveTime || participant1.leaveTime >= participant2.joinedTime) &&
		(!participant2.leaveTime || participant2.leaveTime >= participant1.joinedTime)
	);
}

/**
 * Returns true if `userId` have missed call from `counterpartId`.
 *
 * For legacyCall:
 * - True if reasonOfTermination fulfills miscalls criteria
 *
 * For GroupCall:
 * - True if `userId` never join the call
 * - True if `counterpartId` initiates the call and both participants never talk to each other.
 */
export function userHaveMissedCall(msg: Message, userId: number, counterpartId: number): boolean {
	if (isLegacyCallSummary(msg)) {
		const reasonOfTermination = msg.content.CALL.reasonOfTermination;
		const isMissedCall = missedCallsTerminationReason.includes(reasonOfTermination);
		return isMissedCall && isUserInitiatedTheCall(msg, counterpartId);
	}

	const participantsTalkedTogether = participantsTalkedToEachOther(msg, userId, counterpartId);
	const participant1Data = getParticipantData(msg, userId);
	const counterpartInitiatedTheCall = msg.content.GROUPCALL.initiatorUserId === counterpartId;

	return !participant1Data.joinedTime || (!participantsTalkedTogether && counterpartInitiatedTheCall);
}

export function getParticipantData(msg: Message, participantId: number): ParticipantData {
	return msg.content.GROUPCALL.participants[participantId];
}

export function isUserInitiatedTheCall(msg: Message, userId: number): boolean {
	return msg.content.GROUPCALL?.initiatorUserId === userId || msg.content.CALL?.initiatorUserId === userId;
}

export function getCallerNames(msg: Message, userId: number, counterpartId: number): string {
	if (isLegacyCallSummary(msg)) {
		return null;
	}

	const {participants} = msg.content.GROUPCALL;
	// Get participant ids but not include the local user id; perform filtering based on when the remote user joined and left.
	const callerIds = Object.keys(participants)
		.filter((callerId: string) => {
			// skip the local user
			if (callerId === userId.toString()) {
				return false;
			}

			// We need to check if the otherParty was involved with both localUser and the counterpart (selectedClient)
			return (
				participantsTalkedToEachOther(msg, userId, Number(callerId)) &&
				participantsTalkedToEachOther(msg, counterpartId, Number(callerId))
			);
		})
		.map((id) => Number(id));

	if (callerIds.length === 0) {
		return null;
	}
	return callerIds.map((id) => getFullName(participants[id])).join(", ");
}

/**
 * List of termination reasons which categorised as missed calls
 */
export const missedCallsTerminationReason: TerminationReason[] = [
	TerminationReason.Busy,
	TerminationReason.Cancelled,
	TerminationReason.NotAnswered,
	TerminationReason.Rejected,
	TerminationReason.NotReachable,
];

/**
 * List of termination reasons which categorised as missed calls
 */
export const successfulCallsTerminationReason: TerminationReason[] = [
	TerminationReason.NormalHangUp,
	TerminationReason.NormalHangUpByInitiator,
	TerminationReason.NormalHangUpByRecipient,
	TerminationReason.AbnormalHangUp,
];

/**
 * Check whether the given message is an unread message or not.
 */
export const isUnreadMessage = (ownId: number, readTimestamp: number, message: Message) => {
	if (message.timestamp <= readTimestamp) return false;

	if (!!message.content.CALL) {
		if (missedCallsTerminationReason.includes(message.content.CALL.reasonOfTermination)) {
			return true;
		}

		return false;
	}

	if (!!message.content.GROUPCALL) {
		const didAuthUserMissedTheCall = message.content.GROUPCALL.participants[ownId].joinedTime === undefined;

		// Only count as unread if the user missed the call.
		if (
			didAuthUserMissedTheCall &&
			missedCallsTerminationReason.includes(message.content.GROUPCALL.reasonOfTermination)
		) {
			return true;
		}

		return false;
	}

	if (!!message.content.TEXT && message.from !== ownId) {
		return true;
	}
};

/**
 * Format number bytes to string.
 *
 * @param bytes Number in Bytes.
 * @param decimals Total digit of decimals to be shown, default 2.
 * @returns
 */
export function formatBytes(bytes: number, decimals: number = 2): string {
	if (bytes === 0) return "0 Bytes";

	// 1 kilo Bytes
	const oneKiloBytes = 1024;

	// decimal digits shouldn't be less than 0
	const decimalDigits = decimals < 0 ? 0 : decimals;

	// list of sizes for Bytes.
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	// the logarithm of given Bytes with base 1024 Bytes,
	// to get the exponent of the base value,
	// as an index to get the correct size from the sizes array
	// and as the divider.
	// example: 1048576 Bytes has 2 as the exponent, 1024 x 1024 or 1024^2;
	const exponent = Math.floor(Math.log(bytes) / Math.log(oneKiloBytes));

	// calculate the given Bytes with the powered 1KB with the exponent.
	// example: 1048576 / Math.pow(1024, 2) = 1 Mega Bytes
	const result = bytes / Math.pow(oneKiloBytes, exponent);

	// add decimal digits and add suffix with the size of the bytes.
	return parseFloat(result.toFixed(decimalDigits)) + " " + sizes[exponent];
}
