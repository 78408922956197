import {SensorTargetState, Target, TargetName} from "./SensorTargetTypes";
import {SensorTargetActions} from "./SensorTargetActions";
import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";

const initialState: SensorTargetState = {
	activeTarget: {},
	loadingFetchTarget: {},
	loadingFetchActiveTarget: {},
	loadingUpdateTarget: {},
	targets: {},
	isOpenConfigModal: {},
};

enum LoadingKeys {
	LOADING_FETCH_ACTIVE_TARGET = "loadingFetchActiveTarget",
	LOADING_FETCH_TARGET = "loadingFetchTarget",
	LOADING_UPDATE_TARGET = "loadingUpdateTarget",
}

export function SensorTargetReducer(
	state: SensorTargetState = initialState,
	action: SensorTargetActions.SensorTargetAction,
): SensorTargetState {
	switch (action.type) {
		//
		// ACTIVE TARGET
		//
		case getType(SensorTargetActions.loadingActiveTarget):
			// Reset active target
			const newState = updateActiveTarget(state, action.payload.targetName, null);
			return updateLoadingState(
				newState,
				action.payload.targetName,
				LoadingState.LOADING,
				LoadingKeys.LOADING_FETCH_ACTIVE_TARGET,
			);
		case getType(SensorTargetActions.loadedActiveTarget): {
			const {targetName, activeTarget} = action.payload;
			const newState = updateLoadingState(
				state,
				targetName,
				LoadingState.LOADED,
				LoadingKeys.LOADING_FETCH_ACTIVE_TARGET,
			);
			return updateActiveTarget(newState, targetName, activeTarget);
		}
		case getType(SensorTargetActions.errorLoadingActiveTarget):
			return updateLoadingState(
				state,
				action.payload.targetName,
				LoadingState.ERROR,
				LoadingKeys.LOADING_FETCH_ACTIVE_TARGET,
			);

		//
		// TARGET HISTORIES
		//
		case getType(SensorTargetActions.loadingTargetHistory): {
			const {targetName} = action.payload;
			const newState = updateLoadingState(
				state,
				targetName,
				LoadingState.ERROR,
				LoadingKeys.LOADING_FETCH_TARGET,
			);
			// Reset targets with empty array
			return updateTargetHistories(newState, targetName, []);
		}
		case getType(SensorTargetActions.loadedTargetHistory): {
			const {targetName, targetHistories} = action.payload;
			const newState = updateLoadingState(
				state,
				targetName,
				LoadingState.LOADED,
				LoadingKeys.LOADING_FETCH_TARGET,
			);
			return updateTargetHistories(newState, targetName, targetHistories);
		}
		case getType(SensorTargetActions.errorLoadingTargetHistory):
			return updateLoadingState(
				state,
				action.payload.targetName,
				LoadingState.ERROR,
				LoadingKeys.LOADING_FETCH_TARGET,
			);

		//
		// UPDATE TARGET / STOP TARGET
		//
		case getType(SensorTargetActions.stoppingActiveTarget):
		case getType(SensorTargetActions.updatingActiveTarget): {
			// Reset active target
			const newState = updateActiveTarget(state, action.payload.targetName, null);
			return updateLoadingState(
				newState,
				action.payload.targetName,
				LoadingState.LOADING,
				LoadingKeys.LOADING_UPDATE_TARGET,
			);
		}
		case getType(SensorTargetActions.updatedActiveTarget):
		case getType(SensorTargetActions.stoppedActiveTarget):
			return updateLoadingState(
				state,
				action.payload.targetName,
				LoadingState.LOADED,
				LoadingKeys.LOADING_UPDATE_TARGET,
			);
		case getType(SensorTargetActions.errorLoadingActiveTarget):
		case getType(SensorTargetActions.errorStoppingActiveTarget):
			return updateLoadingState(
				state,
				action.payload.targetName,
				LoadingState.ERROR,
				LoadingKeys.LOADING_UPDATE_TARGET,
			);

		//
		// OPEN / CLOSE CONFIG MODAL
		//
		case getType(SensorTargetActions.openConfigModal):
			return updateIsOpenConfigModal(state, action.payload.targetName, true);
		case getType(SensorTargetActions.closeConfigModal):
			return updateIsOpenConfigModal(state, action.payload.targetName, false);

		default:
			return state;
	}
}

/**
 * Update `loadingState` inside SensorTargetReducer
 *
 * The paramter `loadingKey` is to indicate
 * which loadingState property would like to be changed
 *
 * @param {SensorTargetState} state
 * @param {TargetName} targetName
 * @param {LoadingState} loadingState
 * @param {LoadingKeys} loadingKey
 */
function updateLoadingState(
	state: SensorTargetState,
	targetName: TargetName,
	loadingState: LoadingState,
	loadingKey: LoadingKeys,
): SensorTargetState {
	return {
		...state,
		[loadingKey]: {
			[targetName]: loadingState,
		},
	};
}

/**
 * Update active target by target name
 *
 * @param {SensorTargetState} state
 * @param {TargetName} targetName
 * @param {Target} activeTarget
 */
function updateActiveTarget(state: SensorTargetState, targetName: TargetName, activeTarget: Target): SensorTargetState {
	return {
		...state,
		activeTarget: {
			...state.activeTarget,
			[targetName]: activeTarget,
		},
	};
}

/**
 * Update target histories by target name
 *
 * @param {SensorTargetState} state
 * @param {TargetName} targetName
 * @param {Target[]} targetHistories
 */
function updateTargetHistories(
	state: SensorTargetState,
	targetName: TargetName,
	targetHistories: Target[],
): SensorTargetState {
	return {
		...state,
		targets: {
			...state.targets,
			[targetName]: targetHistories,
		},
	};
}

/**
 * Open or close config modal
 *
 * @param {SensorTargetState} state
 * @param {TargetName} targetName
 * @param {boolean} isOpen
 */
function updateIsOpenConfigModal(state: SensorTargetState, targetName: TargetName, isOpen: boolean): SensorTargetState {
	return {
		...state,
		isOpenConfigModal: {
			...state.isOpenConfigModal,
			[targetName]: isOpen,
		},
	};
}
