import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {CustomExerciseItem, CustomExercisePostBodyRequest, TaskPostBodyRequest} from "@sense-os/goalie-js";
import moment from "moment";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {CoreTrackerId} from "../../utils/coreTracker";
import taskSDK from "../../../taskRegistration/helpers/taskSDK";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {customExerciseActions} from "../redux/customExerciseActions";
import customExerciseSDK from "../helper/customExerciseSDK";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getTaskReminderDateTime} from "../../../taskRegistration/helpers/common";
import {toastActions} from "../../../toaster/redux";
import strTranslation from "../../../assets/lang/strings";
import {onAutoEnableTracker} from "../../customTracker/helpers/onAutoEnableTracker";

const log = createLogger("createCustomExerciseSaga", SentryTags.CustomExercise);

/**
 * Handle Create Custom exercise
 * Flow:
 * 1. Create custom exercise, then get custom exercise id
 * 2. Create Task with registrationId = custom exercise id
 */
function* createCustomExercise(action: ActionType<typeof customExerciseActions.createCustomExercise.request>) {
	const {formValues, userId} = action.payload;
	const token = yield call(getSessionId);

	// Set `remindedDateTime` to `null` if `reminderToggle` is not enabled
	const reminderDateTime: Date = yield call(getTaskReminderDateTime, formValues?.reminderSwitchButton && formValues);

	try {
		// 1st Create custom exercise
		const customExerciseBody: CustomExercisePostBodyRequest = {
			content: formValues.content,
			patient: userId,
		};

		const customExerciseResponse: CustomExerciseItem = yield apiCallSaga(
			customExerciseSDK.createCustomExercise,
			token,
			customExerciseBody,
		);

		/** 2nd Create Task */
		const creationTaskBody: TaskPostBodyRequest<unknown> = {
			title: formValues.title,
			patient: userId,
			tracker: CoreTrackerId.CUSTOM_EXERCISE,
			expiresAt: formValues.expiresAtSwitchButton ? moment(formValues.expiresAt).toDate() : null,
			remindAt: reminderDateTime,
			registrationId: customExerciseResponse.id,
			// only send the `recurrence` if the switch button is enabled
			recurrence: formValues.reminderRepeatOptionSwitchButton ? formValues.reminderRepeatOption : undefined,
		};

		/**
		 * Auto enable custom exercise if it's disabled after create a new registration
		 */
		yield call(onAutoEnableTracker, {
			userId,
			trackerId: CoreTrackerId.CUSTOM_EXERCISE,
		});

		yield apiCallSaga(taskSDK.createTask, token, creationTaskBody);

		yield put(customExerciseActions.createCustomExercise.success({userId}));
		yield put(
			toastActions.addToast({
				message: strTranslation.CUSTOM_EXERCISE.create.alert.success,
				type: "info",
			}),
		);
		yield put(customExerciseActions.closeForm());
	} catch (error) {
		log.captureException(error);
		yield put(toastActions.addToast({message: strTranslation.CUSTOM_EXERCISE.create.alert.fail, type: "error"}));
		yield put(customExerciseActions.createCustomExercise.failure(error));
	}
}

export default function* () {
	yield takeEvery(getType(customExerciseActions.createCustomExercise.request), createCustomExercise);
}
