/**
 * Author: leo Date: 16/03/2018
 */

// lib imports
import autobind from "autobind-decorator";
import {CallSignal, TerminationReason} from "@sense-os/goalie-js";
// app imports
import Localization, {ILocalization} from "../../../../localization/Localization";
import Storage from "services/system/storage/Storage";
import {Injectable} from "../../../IoC/Injectable";
import chatSDK from "../../../../chat/sdk";

import {States} from "./States";

import {VideoCallActionCreators} from "../../../redux/videoCall/VideoCallAction";
import {IStorage} from "../../system/storage/IStorage";
import {StorageKeys} from "../../system/storage/StorageKeys";
import featureFlags from "../../../../featureFlags/FeatureFlags";

import {ActiveCall} from "./ActiveCall";
import {Participants} from "@sense-os/goalie-js/dist/chat/call/type";
import {CallSummary} from "@sense-os/goalie-js/dist/chat/Message";
import {getNamesByUserIds} from "../../../../userProfile/redux/UserProfileSelector";
import {getJoinedParticipants, getNumberOfJoinedParticipants, getParticipantsStillInTheCall} from "./VideoCallHelpers";
import {storeDispatch, getStoreState} from "../../../redux/StoreContainer";
import {getAuthUser} from "../../../../auth/redux";
import {AuthUser} from "../../../../auth/authTypes";
import createLogger from "../../../../logger/createLogger";
import {SentryTags} from "../../../../errorHandler/createSentryReport";
import {ChatRoomAction} from "../../../../chat/redux/ChatRoomAction";
import {emdrActions} from "../../../../emdr/emdrActions";
import {toastActions} from "../../../../toaster/redux/toastAction";
import {canProcessSignal, isInitiationSignal} from "../../../../call/helpers/callSignalUtils";
import {callActions} from "../../../../call/redux/callActions";
import {NotificationAction} from "../../../../notifications/redux/NotificationAction";
import {privateNotesActions} from "../../../../privateNotes/redux/privateNotesAction";
import {isEditingInVideoCallWindow} from "../../../../privateNotes/redux/privateNotesSelector";
import {getCallerNamesFromActiveCall} from "redux/videoCall/VideoCallSelectors";
import {timeTrackingActions} from "../../../../timeTracking/redux/timeTrackingActions";
import {getFullName} from "../../../../userProfile/helpers/profileHelpers";
import {OutgoingCallTypes} from "../../../../call/callTypes";
import strTranslation from "../../../../assets/lang/strings";

/**
 *  This class is the backbone of the video/audio calling functionality in the NiceDay portal.
 */
@autobind
export class VideoCallService implements Injectable {
	public readonly c: string = "[VideoCallService]";

	private log = createLogger("VideoCallService", SentryTags.VideoCall);
	private loc: ILocalization = Localization;
	private storage: IStorage = Storage;
	private features = featureFlags;
	/**
	 * Current state of VideoCallService.
	 * The state is stored in local storage of the browser in order to make it available to all the tabs/windows of the browser.
	 * This approach is required to allow only one video call per browsers at a time, even if there are multiple tabs with the portal opened.
	 *
	 * @param {States} val
	 */
	private set state(val: States) {
		this.log.debug(" * * * State:", val);
		this.storage.write(StorageKeys.VIDEO_CALL_STATE, val);
		// this._state = val;
	}

	private get state(): States {
		let state = <States>this.storage.read(StorageKeys.VIDEO_CALL_STATE) || null;

		if (!state) {
			this.log.addBreadcrumb({message: "Invalid state read from the local storage! calling reset()"});
			this.reset();
			state = <States>this.storage.read(StorageKeys.VIDEO_CALL_STATE);
		}

		return state;
	}

	public getCallState(): States {
		return this.state;
	}

	/**
	 * Return activeCall from redux store
	 */
	private get activeCall(): ActiveCall {
		return getStoreState().videoCall.activeCall;
	}

	private get selectedCallType(): OutgoingCallTypes {
		return getStoreState().videoCall.selectedOutgoingCallType;
	}

	private get authUser(): AuthUser {
		return getAuthUser(getStoreState());
	}

	/**
	 * Returns localuser id from `ums`
	 */
	private get localUserId(): number | undefined {
		return this.authUser?.id;
	}

	public async handleTwilioParticipantLeft(publicId: string) {
		let userId: number;
		for (let twilioUserId in this.activeCall.participantMap) {
			if (this.activeCall.participantMap[twilioUserId].publicId === publicId) {
				userId = Number(twilioUserId);
				break;
			}
		}

		// Do nothing if we can't find any user with given Twilio publicId.
		if (!userId) return;

		this.log.addBreadcrumb({message: "User left from twilio", data: {publicId}});
		await chatSDK.reportTerminationInCall(this.activeCall.roomId, userId);

		storeDispatch(callActions.processRemoteUserLeavesCall(Number(userId), TerminationReason.NormalHangUp));
	}

	/**
	 * Stop all timer if exists
	 */
	private stopAllTimer(): void {
		storeDispatch(callActions.stopAllTimeouts());
	}

	/**
	 * Resets the state of the service to as if it has just been initialized.
	 *
	 * This method should be called:
	 *      1. From `this.init()`  -- on start-up
	 *      2. From `this.sendCallSummary()` -- after the call summary has been sent, i.e. the conversation is over.
	 *      3. In case of a serious error to ensure the service remains functional (further incoming calls can be accepted, etc).
	 */
	public reset(): void {
		this.log.addBreadcrumb({message: "Resetting VideoCallService"});

		this.stopAllTimer();
		this.state = States.INITIALIZED;
		this.closeVideoWindow();

		storeDispatch(emdrActions.cleanUpEmdrSaga());
		storeDispatch(privateNotesActions.stopListeningToIwc());

		// create session time entry if needed
		storeDispatch(timeTrackingActions.createTimeEntryForCallSession(this.activeCall, this.selectedCallType));

		// clean up the redux state.
		storeDispatch(callActions.resetActiveCall());
		this.log.debug("reset()");
	}

	/**
	 * Closes the video call window.
	 */
	public closeVideoWindow(): void {
		storeDispatch(callActions.closeCallWindow());
	}

	/**
	 * Show incoming call dialog and push notification
	 *
	 * @param {number[]} initiatorIds
	 */
	private showIncomingCall(initiatorIds: number[]): void {
		storeDispatch(VideoCallActionCreators.incomingCall(initiatorIds));
		this.features.backgroundNotifications &&
			storeDispatch(NotificationAction.onIncomingCallNotification(initiatorIds));
	}

	/**
	 * Initialise the application. This method can be called only once.
	 * @param conf basic app configuration
	 */
	public init(): void {
		// Don't reset VideoCallService if user is in call
		if (!this.activeCall) {
			this.reset();
			// Check if there's initiation signal
			chatSDK.getNotRespondedCallYet().then((signal: CallSignal) => {
				if (signal && isInitiationSignal(signal) && canProcessSignal(signal, this.activeCall)) {
					this.log.debug("callSignal:", signal);
					storeDispatch(callActions.handleInitiationSignal(signal));
				}
			});
		}

		this.log.addBreadcrumb({
			message: "initializing VideoCallService",
		});
		this.log.debug("initializing...");

		this.updateActiveCallParticipants();
	}

	/**
	 * Update activeCall participants. We call this method whenever the chat is reconnected to the backend,
	 * or when there is a group call initiation from other party. This way we can know who is the latest participants
	 * in an active call.
	 *
	 * Since calling `chatSDK.getLatestParticipants` means the local user already connected to a room,
	 * we will receive all signals inside a room until we left the room. Even when we reject a call, we might still
	 * receive signals from the room if there are still participants in the room. But since we ignore them in `onActiveCall`,
	 * we don't need to worry about those signals.
	 */
	public updateActiveCallParticipants(): void {
		const PREF: string = "updateActiveCallParticipants()";

		const currentActiveCall: ActiveCall = this.activeCall;

		if (!currentActiveCall) {
			this.log.debug(PREF, "There's no activecall! Aborting..");
			return;
		}
		this.log.debug(PREF, "Updating activecall participants");

		const localUserId: number = this.localUserId;

		chatSDK.getLatestParticipants(currentActiveCall.roomId).then((latestParticipants: Participants) => {
			this.log.debug(PREF, "Latest participants:", latestParticipants);

			// All participants has left the room
			const everyParticipantsHasLeftTheRoom: boolean =
				!latestParticipants ||
				Object.keys(latestParticipants).length === 0 ||
				Object.keys(latestParticipants)
					.map(Number)
					.filter((id) => !!latestParticipants[id].leaveTime).length ===
					Object.keys(latestParticipants).length;

			// Local user latest participant data
			const luLatestParticipantData = latestParticipants[localUserId];
			// Old local user participant data
			const luOldParticipantData =
				currentActiveCall.participantMap && currentActiveCall.participantMap[localUserId];

			// Local user has left the call, but not leave the current active call yet.
			const localUserHasLeft: boolean =
				luLatestParticipantData &&
				luLatestParticipantData.leaveTime &&
				luOldParticipantData &&
				!luOldParticipantData.leaveTime;

			// Local user has been invited to a call, but not initiated in current active call yet.
			const localUserHasBeenInvited: boolean =
				luLatestParticipantData &&
				!luLatestParticipantData.joinedTime &&
				!luLatestParticipantData.leaveTime &&
				!luOldParticipantData;

			if (localUserHasLeft) {
				this.log.addBreadcrumb({message: PREF + " local user has left the call. Reset call service"});
				this.reset();
			} else if (localUserHasBeenInvited) {
				storeDispatch(
					VideoCallActionCreators.createActiveCall({
						...currentActiveCall,
						participantMap: latestParticipants,
					}),
				);

				const numberOfJoinedParticipants: number = getNumberOfJoinedParticipants(
					this.activeCall,
					this.localUserId,
				);

				if (numberOfJoinedParticipants > 0) {
					this.log.debug(
						PREF,
						"local user has been invited to the call. Showing incoming call. Number of joined participants:",
						numberOfJoinedParticipants,
					);
					const callerIds = this.getParticipantUserIds();
					this.showIncomingCall(callerIds);
				} else {
					this.log.debug(
						PREF,
						"Local user has been invited to the call, but there are no participants in the call. Aborting the call...",
					);
					this.reset();
				}
			} else if (everyParticipantsHasLeftTheRoom) {
				this.log.debug(PREF, "All participants has left the room. Resetting VideoCallService..");
				this.reset();
			} else {
				this.log.debug(PREF, "Updating the participants to the latest one from SDK..", latestParticipants);
				storeDispatch(
					VideoCallActionCreators.createActiveCall({
						...currentActiveCall,
						participantMap: latestParticipants,
					}),
				);
			}
		});
	}

	/**
	 * Show toast for termination signal
	 *
	 * @param {number} initiatorId
	 * @param {TerminationReason} terminationReason
	 */
	public showTerminationToast(initiatorId: number, terminationReason: TerminationReason): void {
		const name: string = this.getCallerNames([initiatorId]);
		let toast: string = "Sorry, something went wrong with the call between you and " + name;

		switch (terminationReason) {
			case TerminationReason.NormalHangUp:
			case TerminationReason.NormalHangUpByRecipient:
			case TerminationReason.NormalHangUpByInitiator:
				storeDispatch(
					toastActions.addToast({
						message: this.loc.formatMessage(strTranslation.CHAT.video.call_ended.toast, {name}),
						type: "info",
					}),
				);
				toast = null;
				break;

			case TerminationReason.NotAnswered:
			case TerminationReason.Cancelled:
				toast = this.loc.formatMessage(strTranslation.CHAT.video.they_cancelled.toast, {name}); // this.getCallerNames() +  " cancelled the call.";

				if (this.features.backgroundNotifications) {
					// Create a notification that the call was missed
					storeDispatch(NotificationAction.onMissedCallNotification([initiatorId]));
				}
				break;

			case TerminationReason.NotReachable:
				toast = this.loc.formatMessage(strTranslation.CHAT.video.client_not_available.toast, {
					name,
				}); // this.getCallerNames() +  " is not available at the moment."

				if (this.features.backgroundNotifications) {
					// Create a notification that the call was missed
					storeDispatch(NotificationAction.onMissedCallNotification([initiatorId]));
				}
				break;

			case TerminationReason.Busy:
				toast = this.loc.formatMessage(strTranslation.CHAT.video.busy.toast, {name}); // this.getCallerNames() +  " is in another call.";
				break;

			case TerminationReason.Rejected:
				toast = this.loc.formatMessage(strTranslation.CHAT.video.rejected.toast, {name}); // this.getCallerNames() +  " rejected your call.";
				break;

			// case TerminationReason.NotAnswered:
			//     toast = this.loc.formatMessage( strTranslation.CHAT.video.no_answer.toast", {name: this.getCallerNames()}); // this.getCallerNames() +  " did not answer"
			//     break;

			case TerminationReason.AbnormalHangUp:
			case TerminationReason.UnexpectedError:
			default:
				storeDispatch(toastActions.addToast({message: toast, type: "error"}));
				toast = null;
				break;
		}

		if (toast) {
			storeDispatch(toastActions.addToast({message: toast, type: "warning"}));
		}
	}

	/**
	 * Check if active call should be ended or not
	 */
	public shouldEndActiveCall(): boolean {
		const activeCall: ActiveCall = this.activeCall;
		const localUserId: number = this.localUserId;

		if (!activeCall || !activeCall.participantMap) {
			return true;
		}

		// If localUser already leave the activeCall, then we should the end active call
		if (activeCall.participantMap[localUserId] && activeCall.participantMap[localUserId].leaveTime) {
			return true;
		}

		// End call if joined participants apart from localuserid is 0
		// and the user isn't currently writing a private note for a client.
		return (
			!isEditingInVideoCallWindow(getStoreState()) && getJoinedParticipants(activeCall, localUserId).length === 0
		);
	}

	/**
	 * Send call summary.
	 *
	 * For group call, will send call summary to ACTIVE PARTICIPANTS WHO STILL STAY IN THE ROOM AND NOT LEFT YET.
	 *
	 * IF userId is provided, the function will send call summary ONLY to the userId.
	 *
	 * @param {TerminationReason} terminationReason
	 * @param {number} userId
	 */
	public sendCallSummary(terminationReason: TerminationReason, userId?: number): void {
		const PREF: string = "sendCallSummary";
		const activeCall: ActiveCall = this.activeCall;
		if (!activeCall) {
			this.log.debug(PREF, "No active call! Aborting!");
			return;
		}

		const localUserId: number = this.localUserId;

		const summary = this.activeCallToCallSummary(activeCall, terminationReason);
		if (!summary) {
			return;
		}

		const sendSummary = (userId: number) => {
			return chatSDK
				.sendCallSummary(
					Number(userId),
					summary.roomId,
					summary.reasonOfTermination,
					summary.initiatorUserId,
					summary.callType,
				)
				.then((msg) => {
					this.log.addBreadcrumb({message: "Summary sent", data: msg});
					storeDispatch(ChatRoomAction.addMessages(userId, [msg]));
					return chatSDK.setLastSentTime(Number(userId), msg.archiveId).then(() => msg);
				});
		};

		if (userId) {
			this.log.debug("SENDING CALL SUMMARY TO", userId);
			sendSummary(userId);
			return;
		}

		// Get only participants that still in the call but not localuser
		const participantsStillInCall: number[] = getParticipantsStillInTheCall(activeCall, localUserId);

		// Send call summary to other participants
		participantsStillInCall.forEach((id) => {
			const participant = activeCall.participantMap[id];

			this.log.debug(PREF, "Sending call summary to", id, ":", getFullName(participant), summary);

			sendSummary(id);
		});
	}

	/**
	 * Converts activeCall to CallSummary object
	 *
	 * @param {ActiveCall} activeCall
	 * @param {TerminationReason} reason
	 */
	private activeCallToCallSummary(activeCall: ActiveCall, reason: TerminationReason): CallSummary {
		return {
			roomId: activeCall.roomId,
			callType: activeCall.type,
			initiatorUserId: activeCall.initiatorUserId,
			participants: activeCall.participantMap,
			reasonOfTermination: reason,
		};
	}

	/**
	 * Return participant userIds but not include localuserid
	 */
	private getParticipantUserIds(): number[] {
		const activeCall = this.activeCall;
		const localUserId = this.localUserId;
		if (!activeCall) {
			return [];
		}
		return Object.keys(activeCall.participantMap)
			.filter((id) => id !== localUserId.toString())
			.map((id) => Number(id));
	}

	/**
	 * Returns caller names based on user Ids provided in the parameter
	 * If no user ID provided, we try to use the `activeCall` participants data.
	 * If no `activeCall` exist, we throw an error and show `contact` instead
	 *
	 * @param {number[]} ids user IDs
	 *
	 * @returns {string} caller names
	 */
	private getCallerNames(userIds?: number[]): string {
		try {
			if (!userIds || userIds.length === 0) {
				return getCallerNamesFromActiveCall(getStoreState());
			}

			// get names from userprofile data
			return getNamesByUserIds(
				userIds,
				this.loc.formatMessage(strTranslation.CHAT.video.unknown_caller),
			)(getStoreState());
		} catch (e) {
			this.log.captureException(e);
			return this.loc.formatMessage(strTranslation.COMMON.your_contact);
		}
	}
}
