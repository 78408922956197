import {ConnectionStatus} from "@sense-os/goalie-js";
import {select} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {DoSomething, doSomethingIfOrWaitUntil, stateMachineHandler} from "../../helpers/sagas/stateMachine";

import {ChatAction} from "../redux/ChatAction";
import * as chatSelectors from "../redux/ChatSelector";

/**
 * Forks the given fn as long as the user is logged in.
 * Accepts an optional second fn that will be called,
 * when the chat is disconnected.
 */
export function whenChatConnect(startEffect?: DoSomething, stopEffect?: DoSomething) {
	return stateMachineHandler(
		select(chatSelectors.isConnected),
		(action: ChatAction.ChatActionType) =>
			action.type === getType(ChatAction.setConnectionStatus) &&
			action.payload.connectionStatus === ConnectionStatus.Connected,
		(action: ChatAction.ChatActionType) =>
			action.type === getType(ChatAction.setConnectionStatus) &&
			action.payload.connectionStatus !== ConnectionStatus.Connected,
		startEffect,
		stopEffect,
	);
}

/**
 * Either execute the fn if chat is connected,
 * or wait until chat is connected and execute the fn
 */
export function doWhenChatConnect(fn: DoSomething) {
	return doSomethingIfOrWaitUntil(
		fn,
		select(chatSelectors.isConnected),
		(action: ChatAction.ChatActionType) =>
			action.type === getType(ChatAction.setConnectionStatus) &&
			action.payload.connectionStatus === ConnectionStatus.Connected,
	);
}
