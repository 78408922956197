import {select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {DoSomething, stateMachineHandler} from "../../helpers/sagas/stateMachine";

import {connectionStatusActions} from "../redux";
import {AppState} from "redux/AppState";

/**
 * Forks the given fn as long as portal think it's online.
 * Accepts an optional second fn that will be called,
 * when the it is offline.
 */
export function whenOnline(startEffect?: DoSomething, stopEffect?: DoSomething) {
	return stateMachineHandler(
		select((state: AppState) => state.connectionStatus.isOnline),
		(action: ActionType<typeof connectionStatusActions.changeOnlineStatus>) =>
			action.type === getType(connectionStatusActions.changeOnlineStatus) && action.payload.status,
		(action: ActionType<typeof connectionStatusActions.changeOnlineStatus>) =>
			action.type === getType(connectionStatusActions.changeOnlineStatus) && !action.payload.status,
		startEffect,
		stopEffect,
	);
}
