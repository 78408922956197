import {DailyPlannerService, SenseTracking} from "@sense-os/goalie-js";
import {getSessionId} from "../../auth/helpers/authStorage";

const tracking = new SenseTracking();
tracking.getThoughtRecords = tracking.getThoughtRecords.bind(tracking);

export function getDailyPlannerSDK() {
	return DailyPlannerService;
}

export function getTrackingSDK() {
	tracking.init(getSessionId());

	return tracking;
}
