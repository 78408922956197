import {DiaryEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const diaryEntryActions = {
	//
	// Fetch diary entry actions
	//
	fetchDiaryEntry: createAsyncAction(
		"diaryEntryActions/fetchDiaryEntry",
		"diaryEntryActions/fetchDiaryEntrySucceed",
		"diaryEntryActions/fetchDiaryEntryFailed",
	)<{userId: number; diaryId: string}, {diaryEntryData: SensorDatum<DiaryEntry>}, Error>(),
};

export type DiaryEntryActionType = ActionType<typeof diaryEntryActions>;
