import {ConnectionStatus, Presence, PresenceAvailability} from "@sense-os/goalie-js";
import {eventChannel} from "redux-saga";
import {call, cancelled, delay, fork, put, race, select, take, takeEvery, takeLatest} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import {whenLoggedIn} from "../../auth/sagas/helper";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {ChatAction} from "../redux/ChatAction";
import {chatPresenceActions} from "../redux/ChatPresenceAction";
import * as chatPresenceSelectors from "../redux/ChatPresenceSelector";

import chatSDK from "../sdk";
import {ChatPresenceContext} from "../types";

import chatPresenceTimerSaga from "./chatPresenceTimerSaga";
import {whenChatConnect} from "./helpers";
import {isLoggable} from "../../ts/utils/muteLog/loggable";
import {onlineUsersActions} from "../../onlineUsers/redux/onlineUsersActions";

const log = createLogger("ChatPresence", SentryTags.ChatPresence);

/**
 * This function will send current presence to ejabberd. This contain information ChatPresenceContext.SENT_WHEN_CHAT_CONNECT,
 * that will help other tabs to ignore this presence when broadcasted to them. Now, there're two possiblities:
 * 1) There are other tab being opened.
 *    In this case, ejabberd will automatically send, in response to this function, the presence of those other tabs.
 *    Which, in return, will be integrated by current tab.
 * 2) There are no other tab being opened.
 *    In this case, nothing will happens. This tab will keep its current internal presence.
 */
function* sendPresenceAfterChatConnect(action: ActionType<typeof ChatAction.setConnectionStatus>) {
	if (action.payload.connectionStatus === ConnectionStatus.Connected) {
		const currentPresence = yield select(chatPresenceSelectors.getCurrentPresence);

		yield put(
			chatPresenceActions.updateOwnPresence({
				presence: currentPresence,
				context: ChatPresenceContext.SENT_WHEN_CHAT_CONNECT,
			}),
		);
	}
}

function* updateOwnPresence(action: ActionType<typeof chatPresenceActions.updateOwnPresence>) {
	const {presence, context} = action.payload;
	log.debug("updateOwnPresence:", presence, context);

	try {
		yield call(chatSDK.sendAvailabilityStatus, presence, JSON.stringify({context}));
	} catch (e) {
		log.debug("Failed to send availability status", action.payload, e);
	}
}

function* updateOtherUserPresence(action: ActionType<typeof chatPresenceActions.updateOtherUserPresence>) {
	const {presence} = action.payload;
	isLoggable.updateOtherUserPresence && log.debug("updateOtherUserPresence:", presence);

	const userId = presence.contactUserId;
	yield put(
		onlineUsersActions.availabilityStatusUpdate({
			userId,
			statusDescriptor: presence.availability,
		}),
	);

	// If a client went offline, check whether they're logged in elsewhere
	// in order to grab the most up-to-date chat presence of the client
	if (presence.availability.availability === PresenceAvailability.Unavailable) {
		yield put(chatPresenceActions.queryOtherUserPresence(userId));
	}
}

function* queryOtherUserPresence(action: ActionType<typeof chatPresenceActions.queryOtherUserPresence>) {
	log.debug("Sending presence probe to ", action.payload.userId);
	try {
		yield call(chatSDK.sendProbeForPresenceStatus, action.payload.userId);
	} catch (e) {
		log.debug("Failed to probe presece", action.payload, e);
	}
}

function* processIncomingOwnPresence(action: ActionType<typeof chatPresenceActions.processIncomingOwnPresence>) {
	/**
	 * For more details, see the issue https://github.com/senseobservationsystems/web-getgoalie/issues/2083
	 * Basically, as part of presence conflict resolution among tabs, we only process the latest incoming own presence.
	 *
	 * Also when this tab somehow set its own presence while waiting this delay,
	 * then we can drop this process.
	 */
	const raceResult = yield race({
		delay: delay(2000),
		updated: take(getType(chatPresenceActions.updateOwnPresence)),
	});

	if (raceResult.updated) {
		return;
	}

	const {presence, context} = action.payload;
	const currentPresence: PresenceAvailability = yield select(chatPresenceSelectors.getCurrentPresence);
	const currentContext: ChatPresenceContext = yield select(chatPresenceSelectors.getCurrentPresenceContext);

	if (presence !== currentPresence || context !== currentContext) {
		yield put(chatPresenceActions.updateOwnPresence(action.payload));
	}
}

/**
 * This is a subscription to listen to presence changes from goalie-js.
 */
function* presenceChangesHandler() {
	const chan = eventChannel<Presence>((emitter) => {
		const subId = chatSDK.subscribeToPresenceChanges(emitter);
		return () => {
			chatSDK.unsubscribeFromPresenceChanges(subId);
		};
	});

	const authUser: AuthUser = yield select(getAuthUser);

	try {
		while (true) {
			const presence: Presence = yield take(chan);
			isLoggable.incomingPresence && log.debug("Received incoming presence", presence);

			/**
			 * Until every portal is updated, there might be no context information.
			 * The safest option for now, is to treat it as if it is being set manually.
			 */
			let presenceContext;
			try {
				presenceContext = JSON.parse(presence.availability.status).context;
			} catch (e) {
				presenceContext = ChatPresenceContext.SET_MANUALLY;
			}

			const isOwnPresence = authUser.id === presence.contactUserId;
			if (!isOwnPresence) {
				yield put(chatPresenceActions.updateOtherUserPresence({presence}));
			} else {
				// Ignore if this signal is about tab being closed, or when it is sent because of chat connect.
				if (
					presence.availability.availability !== PresenceAvailability.Unavailable &&
					presenceContext !== ChatPresenceContext.SENT_WHEN_CHAT_CONNECT
				) {
					yield put(
						chatPresenceActions.processIncomingOwnPresence({
							presence: presence.availability.availability,
							context: presenceContext,
						}),
					);
				}
			}
		}
	} finally {
		if (yield cancelled()) {
			chan.close();
		}
	}
}

function* presenceSagaOnChatConnected() {
	yield takeEvery(getType(chatPresenceActions.updateOwnPresence), updateOwnPresence);
	yield takeEvery(getType(chatPresenceActions.queryOtherUserPresence), queryOtherUserPresence);
}

function* presenceSagaOnLoggedIn() {
	yield takeEvery(getType(ChatAction.setConnectionStatus), sendPresenceAfterChatConnect);
	yield takeEvery(getType(chatPresenceActions.updateOtherUserPresence), updateOtherUserPresence);
	yield takeLatest(getType(chatPresenceActions.processIncomingOwnPresence), processIncomingOwnPresence);

	yield fork(presenceChangesHandler);
	yield fork(chatPresenceTimerSaga);
}

export default function* () {
	yield fork(whenLoggedIn(presenceSagaOnLoggedIn));
	yield fork(whenChatConnect(presenceSagaOnChatConnected));
}
