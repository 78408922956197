/** Author: leo Date: 28/06/2021 */

import {FC} from "react";
import Button from "@material-ui/core/Button";
import {NDColors} from "../../material-ui/NDColors";
import {useDispatch} from "react-redux";
import {timeTrackingActions} from "../redux/timeTrackingActions";
import {FormattedMessage} from "react-intl";
import strTranslation from "../../assets/lang/strings";

interface Props {
	timeTrackingEntryId: number;
}
const DeletionUndoButton: FC<Props> = ({timeTrackingEntryId}) => {
	const dispatch = useDispatch();

	return (
		<>
			<Button
				variant="outlined"
				style={{backgroundColor: NDColors.DEFAULT}}
				onClick={() => {
					dispatch(timeTrackingActions.undoEntryDeletion(timeTrackingEntryId));
				}}
			>
				<FormattedMessage id={strTranslation.TIME_TRACKING.deletion_toast.button.undo} />
			</Button>
		</>
	);
};

/**
 * Returns the "Undo" button component, to be used on the toast for 'undeletion' of a deleted time tracking entry
 * @param entryToDeleteId
 */
export function getDeletionUndoButton(entryToDeleteId: number) {
	return <DeletionUndoButton timeTrackingEntryId={entryToDeleteId} />;
}
