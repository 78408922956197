import {put, select} from "redux-saga/effects";
import {doSomethingIfOrWaitUntil} from "../../helpers/sagas/stateMachine";
import {getPatientsContacts, isContactsLoaded} from "../../contacts/redux/contactSelectors";
import {getType} from "typesafe-actions";
import {contactActions} from "../../contacts/redux/contactAction";
import {Contact} from "../../contacts/contactTypes";
import {TreatmentStatusAction, treatmentStatusActions} from "../redux/treatmentStatusActions";
import {getClientStatus, getIsAllTreatmentsLoaded} from "../redux/treatmentStatusSelectors";
import {LoadingState} from "constants/redux";
import {Treatment} from "../treatmentStatusTypes";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import treatmentStatusSdk from "../treatmentStatusSdk";
import {getSessionId} from "../../auth/helpers/authStorage";
import {postProcessFetchedTreatments} from "./loadTreatmentsSaga";

/**
 * The idea, is that for each of the clients of this therapist,
 * we check (1) whether the client has client number information on their
 * "client-specific-org" data, and (2) whether this data is different with the one
 * in treatment form. If it's so, we move the data from client-org-specific field
 * to the on in treatment.
 */
function* migrateFn() {
	const clients: Contact[] = yield select(getPatientsContacts);

	for (let idx = 0; idx < clients.length; idx++) {
		yield migrateClient(clients[idx]);
	}
}

function* migrateClient(client: Contact) {
	const treatment: Treatment = yield select(getClientStatus(client.id));

	if (!treatment || !treatment.clientData?.value) return;
	const clientData = treatment.clientData?.value;

	// This OR operation should works, as we assume
	// that at most, only one of these value is filled.
	const possibleClientNumber =
		clientData.solClientNumber || clientData.psyqClientNumber || clientData.synaedaClientNumber;

	// Only continue with the migration if the number is different with
	// the one from treatment.
	if (!possibleClientNumber || possibleClientNumber === treatment.orgClientNumber) return;

	const token: string = getSessionId();
	const sdkTreatment = yield apiCallSaga(treatmentStatusSdk.updateTreatment, token, {
		id: treatment.id,
		orgClientNumber: possibleClientNumber,
	} as any); // TODO: @agung Remove this any once goalie-js fix the type of `updateTreatment`.

	const treatmentMaps = postProcessFetchedTreatments([sdkTreatment]);
	yield put(treatmentStatusActions.updateTreatmentMaps(treatmentMaps));
}

/**
 * This migration depends on user settings, and will be part of
 * `userSettingsBasedMigrationSaga`. This saga also depends on two data:
 * contacts list and treatment information.
 * Thus, this saga waits for that two data to be loaded first.
 *
 * For contact, we depends on `contactActions.loadContacts` action,
 * while for treatment, we assume that `loadOngoingTreatmentOnStart`
 * run when portal starts and wait for
 * `treatmentStatusActions.setFetchingAllTreatmentsState(LoadingState.LOADED)`
 * at the end.
 */
export function* migrateClientNumberToTreatmentSaga() {
	yield doSomethingIfOrWaitUntil(
		function* () {
			yield doSomethingIfOrWaitUntil(
				migrateFn,
				select(getIsAllTreatmentsLoaded),
				(action: TreatmentStatusAction) =>
					action.type === getType(treatmentStatusActions.setFetchingAllTreatmentsState) &&
					action.payload.fetchingState === LoadingState.LOADED,
			);
		},
		select(isContactsLoaded),
		getType(contactActions.loadContacts.success),
	);
}
