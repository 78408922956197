import {put, takeEvery, call} from "@redux-saga/core/effects";
import {DISC} from "IoC/DISC";
import {select} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {AuthUser} from "../../../auth/authTypes";
import {getAuthUser} from "../../../auth/redux";
import {callActions} from "../../redux/callActions";

/**
 * Handle accept/reject elsewhere signal.
 */
function* handleAcceptedRejectedElsewhereSignal() {
	const authUser: AuthUser = yield select(getAuthUser);

	// Set leaveTime to auth user
	yield put(callActions.userLeaveCall(authUser.id, Date.now()));
	// No need to send any signal to the room or counterpart
	// Because accepted/rejected elsewhere signal is sent by the auth user from other portal instance
	yield call(DISC.getVideoCallService().reset);
}

export function* handleAcceptRejectElsewhereSignalSaga() {
	yield takeEvery(getType(callActions.handleAcceptRejectElsewhereSignal), handleAcceptedRejectedElsewhereSignal);
}
