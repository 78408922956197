import {fork} from "redux-saga/effects";
import loadBlobSaga from "./loadBlobSaga";
import loadFilesSaga from "./loadFilesSaga";
import deleteFileSaga from "./deleteFileSaga";

export default function* () {
	yield fork(loadFilesSaga);
	yield fork(loadBlobSaga);
	yield fork(deleteFileSaga);
}
