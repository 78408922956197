import {Dispatch} from "redux";
import {call, put, take, takeEvery} from "redux-saga/effects";
import {ActionType as TypeSafeActionType, createAction, getType} from "typesafe-actions";

type ResolveType = (value: unknown) => void;
type RejectType = (reason?: any) => void;
type ActionType<RequestParamType> = {
	request: (arg: RequestParamType) => any;
	success: (...any) => any;
	failure: (...any) => any;
};

type BindActionParam<RequestParamType> = {
	resolve: ResolveType;
	reject: RejectType;
	actionCreator: ActionType<RequestParamType>;
	payload: RequestParamType;
};

const bindActionToPromiseActions = {
	bind: createAction(
		"bindActionToPromiseActions/bind",
		<RequestParamType>(params: BindActionParam<RequestParamType>) => params,
	)(),
};

function* bindActionToPromiseFunc(action: TypeSafeActionType<typeof bindActionToPromiseActions.bind>) {
	const {reject, resolve, actionCreator, payload} = action.payload;

	try {
		yield put(actionCreator.request(payload));
		// wait the request until finish & success
		yield take(getType(actionCreator.success));
		yield call(resolve, null);
	} catch (error) {
		// wait the request until finish & fail
		yield take(getType(actionCreator.failure));
		yield call(reject, error);
	}
}

/**
 * Convert async saga action into promise
 * by this Approach, we can wait the saga action until finish first
 * then next move to the next action.
 *
 * Ex case: close the modal after successfully delete, create, or update
 */
export const bindActionToPromise = <RequestParamType>(
	dispatch: Dispatch<any>,
	action: ActionType<RequestParamType>,
) => {
	return (payload: RequestParamType) => {
		return new Promise((resolve, reject) => {
			return dispatch(
				bindActionToPromiseActions.bind({
					resolve,
					reject,
					actionCreator: action,
					payload,
				}),
			);
		});
	};
};

export function* watchBindActionToPromise() {
	yield takeEvery(getType(bindActionToPromiseActions.bind), bindActionToPromiseFunc);
}
