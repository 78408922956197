import {LoadingState} from "constants/redux";
import strTranslation from "../../assets/lang/strings";
import {MenuButtonItemProps} from "../../components/menuButton/menuButtonType";
import localization from "../../localization/Localization";
import {PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";

export interface InterventionSessionState {
	/** Fetching State of Intervention Session List */
	sessionListFetchingState: LoadingState;
	/** Session List */
	data: SessionDataSource[];
	/** Error object if session failed to be fetched */
	error?: Error;
	/** current page state */
	currentPage: number;
	/** Session expired status */
	selectedSessionExpireStatus: SessionExpireStatus;

	/** Record of sessions from all clients */
	clientSessions: {[key: string]: SessionDataSource[]};
}

/** Planned Event Entry (Session) Sensor Datum */
export type SessionSensorDatum = SensorDatum<PlannedEventEntry>;

/**
 * Define the intervention session table data source
 */
export interface SessionDataSource extends SessionSensorDatum {
	key: string;
}

/** Session Expire Status */
export enum SessionExpireStatus {
	UPCOMING = "upcoming",
	CONCLUDED = "concluded",
}

/** Session Expire Status List */
export const sessionExpireStatusList: MenuButtonItemProps<SessionExpireStatus>[] = [
	{
		value: SessionExpireStatus.UPCOMING,
		label: localization.formatMessage(strTranslation.INTERVENTION.table.menu.upcoming),
		id: "future",
	},
	{
		value: SessionExpireStatus.CONCLUDED,
		label: localization.formatMessage(strTranslation.INTERVENTION.table.menu.past),
		id: "past",
	},
];
