import {GScheme, SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const thoughtRecordActions = {
	//
	// thought record fetch actions
	//
	fetchThoughtRecord: createAsyncAction(
		"thoughtRecordActions/fetchThoughtRecord",
		"thoughtRecordActions/fetchThoughtRecordSuccess",
		"thoughtRecordActions/fetchThoughtRecordFail",
	)<{userId: number; thoughtRecordId: string}, {thoughtRecordData: SensorDatum<GScheme>}, Error>(),
};

export type ThoughtRecordActionType = ActionType<typeof thoughtRecordActions>;
