import {LoadingState} from "../ts/constants/redux";

/**
 *  A string with a date in format `YYYY-MM-DD`.
 *  Examples: `2021-01-01`, `2022-12-25`
 */
export type DateString = string;

export interface TimeTrackingState {
	/** An activity currently being tracked */
	currentAutoTrackedActivity?: AutoTrackedActivity;

	/** Is there a time tracking in progress initiated by the user? */
	isManualTrackingInProgress: boolean;

	/** Currently displayed date */
	selectedDate: DateString;

	/** A map: a date to an array of time tracking entries which correspond to that date */
	trackingEntryCache: Record<DateString, TimeTrackingEntry[]>;

	/** List of unconfirmed entries from the past */
	unconfirmedEntries: TimeTrackingEntry[];

	/** This map allows to check loading state of data for a given date */
	daysLoadingState: Record<DateString, LoadingState>;

	/** This loading state reflects the progress of a confirmation of entries request sent to the BE */
	entryConfirmationLoadingState: LoadingState;

	/**
	 * This is where we store the values to control the input components
	 * that user can use to create a new time tracking entry.
	 */
	activeEntryInput?: Partial<TimeTrackingEntry>;

	/**
	 * A flag to know whether the timer for active entry is running or not.
	 */
	isActiveEntryTimerRunning: boolean;

	/** Flag whether portal is currently creating a time entry */
	isCreatingEntry: LoadingState;

	/** Flag whether portal is currently deleting time entries */
	isDeletingEntryMap: Record<number, LoadingState>;

	/** Flag whether portal is currently updating the time entries */
	isUpdatingEntryMap: Record<number, LoadingState>;

	/** Flag whether user allow automatic tracking or not */
	doesUserAllowAutoTracking?: boolean;

	loadingExportingTimeTracking: LoadingState;

	loadingCheckExportTimeTracking: Record<string, LoadingState>;

	// This map now contains name and link of the exports.
	downloadLinkMapForExport: Record<string, {name: string; link: string}>;

	// To hold the error object when getting export link
	exportDownloadError?: Error;
}

export enum ActivityTypeKey {
	CARE_DIRECT = "CareDirect",
	CARE_INDIRECT = "CareIndirect",
	ADMINISTRATION = "Administration",
	CONTACT_WITH_COLLEAGUES = "ContactWithColleagues",
	OTHER = "Other",
}

/**
 * Note: Per feature https://github.com/senseobservationsystems/web-getgoalie/issues/4435,
 * makes sure that no values contain the symbol "/", as portal will use this symbol
 * to parse the nested activities.
 */
export enum ActivityKey {
	// These are v1 ActivityKey
	ADMIN_TIME = "AdministrativeTime",
	SESSION = "Session",
	CHAT = "Chat",
	FACE_TO_FACE_SESSION = "FaceToFaceSession",
	CALL_WITH_COLLEAGUE = "CallWithColleague",
	CHAT_WITH_COLLEAGUE = "ChatWithColleague",
	INTAKE_DIAGNOSTICS = "IntakeDiagnostics",

	// These are v2 ActivityKey
	DIAGNOSTIC_INTAKE = "diagnostic",
	TREATMENT_CONSULT = "treatment_consult",
	ASYNC_CONTACT = "async_contact",
	MISC = "misc",
	DIRECT_TIME_MISC = "direct_time_misc",
	ONLINE_INTAKE = "online_intake",
	PHONE_CALL_INTAKE = "phone_call_intake",
	FACE_TO_FACE_INTAKE = "face_to_face_intake",
	ONLINE_CONSULT = "online_consult",
	PHONE_CONSULT = "phone_consult",
	FACE_TO_FACE_CONSULT = "face_to_face_consult",
	FACE_TO_FACE_COLLEAGUE_CLIENT = "face_to_face_colleague_client",
	CALL_COLLEAGUE_CLIENT = "call_colleague_client",
	CHAT_COLLEAGUE_CLIENT = "chat_colleague_client",
	DIRECT_TIME_ASYNC = "direct_time_async",
	RTMS = "rtms",
	DIRECT_TIME_MISC_OPT = "direct_time_misc#option",
}

/**
 * Per feature https://github.com/senseobservationsystems/web-getgoalie/issues/4435,
 * now the activity will be grouped up. Here's where we defined each group of activities
 */
export const ActivityGroups: Record<string, ActivityKey[]> = {
	[ActivityKey.DIAGNOSTIC_INTAKE]: [
		ActivityKey.ONLINE_INTAKE,
		ActivityKey.PHONE_CALL_INTAKE,
		ActivityKey.FACE_TO_FACE_INTAKE,
	],
	[ActivityKey.TREATMENT_CONSULT]: [
		ActivityKey.ONLINE_CONSULT,
		ActivityKey.PHONE_CONSULT,
		ActivityKey.FACE_TO_FACE_CONSULT,
		ActivityKey.FACE_TO_FACE_COLLEAGUE_CLIENT,
		ActivityKey.CALL_COLLEAGUE_CLIENT,
		ActivityKey.CHAT_COLLEAGUE_CLIENT,
	],
	[ActivityKey.ASYNC_CONTACT]: [ActivityKey.DIRECT_TIME_ASYNC],
	[ActivityKey.MISC]: [ActivityKey.RTMS],
	[ActivityKey.DIRECT_TIME_MISC]: [ActivityKey.DIRECT_TIME_MISC_OPT],
};

/**
 * Here's the variable that dictates what options are being shown in the activity dropdown.
 */
export const ActivityDropdownKeys = [
	ActivityKey.DIAGNOSTIC_INTAKE,
	ActivityKey.TREATMENT_CONSULT,
	ActivityKey.ASYNC_CONTACT,
	ActivityKey.MISC,
];

/**
 * Here's the variable that dictates what options are being shown in the activity dropdown.
 */
export const ExportEntriesActivityDropdownKeys = [
	ActivityKey.ONLINE_INTAKE,
	ActivityKey.PHONE_CALL_INTAKE,
	ActivityKey.FACE_TO_FACE_INTAKE,
	ActivityKey.ONLINE_CONSULT,
	ActivityKey.PHONE_CONSULT,
	ActivityKey.FACE_TO_FACE_CONSULT,
	ActivityKey.FACE_TO_FACE_COLLEAGUE_CLIENT,
	ActivityKey.CALL_COLLEAGUE_CLIENT,
	ActivityKey.CHAT_COLLEAGUE_CLIENT,
	ActivityKey.DIRECT_TIME_ASYNC,
	ActivityKey.RTMS,
	ActivityKey.DIRECT_TIME_MISC_OPT,
];

/**
 * As we are changing the activity keys per https://github.com/senseobservationsystems/web-getgoalie/issues/4435,
 * here's the "mapping" from previous activity keys to the new keys
 */
export const ActivityKeysMapping = {
	[ActivityKey.ADMIN_TIME]: ActivityKey.DIRECT_TIME_MISC,
	[ActivityKey.SESSION]: ActivityKey.ONLINE_CONSULT,
	[ActivityKey.CHAT]: ActivityKey.DIRECT_TIME_ASYNC,
	[ActivityKey.FACE_TO_FACE_SESSION]: ActivityKey.FACE_TO_FACE_CONSULT,
	[ActivityKey.CALL_WITH_COLLEAGUE]: ActivityKey.CALL_COLLEAGUE_CLIENT,
	[ActivityKey.CHAT_WITH_COLLEAGUE]: ActivityKey.CHAT_COLLEAGUE_CLIENT,
	[ActivityKey.INTAKE_DIAGNOSTICS]: ActivityKey.ONLINE_INTAKE,
};

/**
 * Provides additional information on `Activity`
 */
export interface ActivityType {
	key: ActivityTypeKey;
	name: string;
}

/**
 * `Activity` tells what the therapist spent their time on.
 */
export interface Activity {
	name: string;

	/** Types an activity can have + should the client drop-down be shown per each type (the `boolean` value)  */
	types: Partial<Record<ActivityTypeKey, boolean>>;

	/** Default type to use for an activity */
	defaultType: ActivityTypeKey;
	key: ActivityKey;
}

export type TimeTrackingEntryPatient = {id: number; firstName: string; lastName: string; treatmentId: number};

/**
 * Used for measuring time spent on a particular activity.
 */
export interface TimeTrackingEntry {
	id: number;
	isConfirmed: boolean;

	startTime: Date;

	/** `Optional<>` only to support automatic tracking, we don't know the endTime when we start recording an entry */
	endTime?: Date;
	activityKey: ActivityKey;
	typeKey: ActivityTypeKey;

	/** Id of a therapist (if any) involved into the current time tracking entry */
	therapistId?: number;

	/** Id of the treatment that this time entry is attached to */
	treatmentId?: number;

	/**
	 * The details of the patien that this time entry belongs to.
	 * Note that, there's a possibility that a time entry belongs to a client that is not
	 * connected to the therapist, and this data here helps bridge that "disconnected" state.
	 */
	patient?: TimeTrackingEntryPatient;

	isAutoTracked?: boolean;

	/**
	 * This is the attribute that can have a flexible shape
	 */
	note?: {
		// This is the field that contain information for call session auto time tracking.
		callData?: {
			roomId: string;
			clientId: number;
		};
		chatData?: {
			clientId: number;
		};
	};
}

/**
 * Represents the shape of the html inputs for time tracking entry.
 */
export interface TimeTrackingEntryInput {
	startTime: Date;
	endTime: Date;
	duration: string;
	activityKey: ActivityKey;
	typeKey: ActivityTypeKey;
	treatmentId: string;
}

/**
 * Objects of this type contain time tracking entry information, prepared specifically
 * to be rendered on the day confirmation dialog.
 */
export interface ConfirmationScreenItem {
	key: string;
	clientName: string;
	startTime: string;
	endTime: string;
	duration: string;
	activity: string;
	durationSeconds: number;
}

/**
 * A collection of information to be stored in user settings API
 * for time tracking feature.
 *
 * All information stored in the settings mimic exactly what's the
 * corresponding attribute that is stored in redux.
 */
export interface TimeTrackingSettings {
	activeEntryInput?: TimeTrackingState["activeEntryInput"];
	isActiveEntryTimerRunning?: TimeTrackingState["isActiveEntryTimerRunning"];
	doesUserAllowAutoTracking?: boolean;
}

export enum AutoTrackedActivityType {
	CHAT_WITH_PATIENT = "chat_with_patient",
	CHAT_WITH_THERAPIST = "chat_with_therapist",
	READ_REGISTRATION = "read_registration",
	RESPONSE_REGISTRATION = "response_registration",
}

export interface AutoTrackedActivity {
	type: AutoTrackedActivityType;
	userId: number;
	startTime?: Date;
	endTime?: Date;
}
