import {ComponentsOverrides, Theme} from "@mui/material";

export const muiButtonStyle: ComponentsOverrides<Theme>["MuiButton"] = {
	root: {
		textTransform: "none",
		boxShadow: "none",
		"&:hover": {
			filter: "brightness(95%)",
			boxShadow: "none",
		},
		"&.MuiButton-root": {
			padding: "6px 12px",
			"&.MuiButton-root.MuiButton-sizeSmall": {
				height: 32,
			},
			"&.MuiButton-root.MuiButton-sizeMedium": {
				height: 36,
			},
			"&.MuiButton-root.MuiButton-sizeLarge": {
				height: 40,
				padding: "8.5px 12px",
			},
		},
	},
};
