import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {ClientsFeedLabel} from "../clientsFeedTypes";
import {clientsFeedActions} from "../redux/ClientsFeedActions";

/**
 * Reload feed after change active label and hour range
 */
function* reloadFeeds() {
	yield put(clientsFeedActions.loadFeeds.request());
}

/**
 * Per XAI feature addition, not all `setActiveLabel` should be followed by reloadFeeds
 */
function* setActiveLabel(action: ActionType<typeof clientsFeedActions.setActiveLabel>) {
	if (action.payload === ClientsFeedLabel.PAST_ACTIVITY || action.payload === ClientsFeedLabel.UPCOMING_ACTIVITY) {
		yield call(reloadFeeds);
	}
}

export default function* () {
	yield takeEvery(getType(clientsFeedActions.setActiveLabel), setActiveLabel);
	yield takeEvery(getType(clientsFeedActions.setHourRange), reloadFeeds);
}
