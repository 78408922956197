import {FC} from "react";
import {useSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
	snackBarKey: string | number;
}

export const NotifierDefaultAction: FC<Props> = ({snackBarKey}) => {
	const {closeSnackbar} = useSnackbar();
	return (
		<IconButton
			id="add_alarm"
			color="default"
			aria-label="add an alarm"
			// TODO: add custom IconButton for NiceDay
			style={{color: "#ffffff"}}
			onClick={() => {
				closeSnackbar(snackBarKey);
			}}
		>
			<CloseIcon />
		</IconButton>
	);
};
