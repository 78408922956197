import lodash from "lodash";
import {call, takeEvery, select, put} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {ChatRoomAction} from "../redux/ChatRoomAction";
import chatSDK from "../sdk";
import {chatRoomMessages} from "../redux/ChatSelector";
import {Message} from "@sense-os/goalie-js";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {getAuthUser} from "../../auth/redux";
import {AuthUser} from "../../auth/authTypes";
import {isCallSummary} from "../helpers/messageUtils";

const log = createLogger("MarkChatAsRead", SentryTags.Chat);
function* markChatAsReadHandler(action: ActionType<typeof ChatRoomAction.markChatAsRead>) {
	try {
		const messages: Message[] = yield select(chatRoomMessages(action.payload.userId));

		if (messages.length === 0) {
			log.debug(action.payload.userId, "doesn't have any messages to mark as read");
			return;
		}

		const authUser: AuthUser = yield select(getAuthUser);
		const latestReadableMessage = lodash.findLast(
			messages,
			(message) => (message.from !== authUser.id && !isCallSummary(message)) || isCallSummary(message),
		);

		if (!latestReadableMessage) {
			log.debug(action.payload.userId, "doesn't have any messages to mark as read");
			return;
		}

		yield call(chatSDK.markMessageAsRead, action.payload.userId, latestReadableMessage.id);

		// sent read marker timestamp is a value to indicates when a message was last read by auth user.
		// the timestamp will act as an anchor to calculate unread messages
		yield put(ChatRoomAction.setSentReadMarkerTimestampMs(action.payload.userId, Date.now()));

		// Save last read time to backend
		yield put(ChatRoomAction.setLastReadTime(action.payload.userId, latestReadableMessage.archiveId));

		log.debug(latestReadableMessage, "marked as read");
	} catch (err) {
		log.captureException(err, {message: "Failed to mark message as read"});
	}
}

export default function* () {
	yield takeEvery(getType(ChatRoomAction.markChatAsRead), markChatAsReadHandler);
}
