import produce from "immer";
import {getType} from "typesafe-actions";

import {RateCallState} from "../rateCallTypes";

import {RateCallActionType, rateCallActions} from "./rateCallActions";

const defaultState: RateCallState = {
	showRateCallForm: false,
	showRateCallThanks: false,
	roomIdOfRateCallForm: "",
};

export const rateCallReducer = produce((state: RateCallState, action: RateCallActionType) => {
	switch (action.type) {
		case getType(rateCallActions.showRateCallForm): {
			state.showRateCallForm = true;
			state.showRateCallThanks = false;
			state.roomIdOfRateCallForm = action.payload.roomId;
			return;
		}

		case getType(rateCallActions.showRateCallThanks): {
			state.showRateCallForm = false;
			state.showRateCallThanks = true;
			return;
		}

		case getType(rateCallActions.hideRateCall): {
			state.showRateCallForm = false;
			state.showRateCallThanks = false;
			return;
		}
	}
}, defaultState);
