import {PostItem} from "@sense-os/goalie-js";
import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {SearchItemOption} from "../NDBlogSearchTypes";

export const ndBlogSearchActions = {
	/** Dialog state */
	openDialog: createAction("NDBlogSearchActions/openDialog", (searchOption?: SearchItemOption) => ({searchOption}))(),
	closeDialog: createAction("NDBlogSearchActions/closeDialog")(),

	/** Clear search results */
	clearResult: createAction("NDBlogSearchActions/clearSearchResults")(),

	/** Reset NDBlogSearchState */
	resetState: createAction("NDBlogSearchActions/resetState")(),

	/** Search niceday blog post by keywords */
	searchPostItem: createAsyncAction(
		"NDBlogSearchActions/searchPost",
		"NDBlogSearchActions/searchPostSuccess",
		"NDBlogSearchActions/searchPostFail",
	)<{keywords: string}, {keywords?: string; postItems: PostItem[]}, Error>(),

	/** Search niceday blog post by slug */
	searchPostBySlug: createAsyncAction(
		"NDBlogSearchActions/searchPostBySlug",
		"NDBlogSearchActions/searchPostBySlugSuccess",
		"NDBlogSearchActions/searchPostBySlugFail",
	)<{slugs: string[]}, {results: PostItem[]}, Error>(),

	/** Set paste link of selected search result */
	setPasteLink: createAction("NDBlogSearchActions/setPasteLink", (url: string) => ({url}))(),

	/** Clear paste link */
	clearPasteLink: createAction("NDBlogSearchActions/clearPasteLink")(),

	/** Increment search result page */
	incrementPage: createAction("NDBlogSearchActions/incrementPage")(),

	/** Decrement search result page */
	decrementPage: createAction("NDBlogSearchActions/decrementPage")(),
};

export type NDBlogSearchActionType = ActionType<typeof ndBlogSearchActions>;
