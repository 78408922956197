import {LoadingState} from "constants/redux";
import produce from "immer";
import {getType} from "typesafe-actions";
import {ClientManagementState} from "../clientManagementTypes";
import {ClientManagementAction, clientManagementActions} from "./clientManagementActions";

const initialState: ClientManagementState = {
	isDisconnectDialogOpen: false,
	idsDisconnecting: [],
	isDisconnecting: false,
	doneFetching: true,
	disconnectLoadingMap: {},
};

export const clientManagementReducer = produce((state: ClientManagementState, action: ClientManagementAction) => {
	switch (action.type) {
		case getType(clientManagementActions.askConfirmationOfClientsDisconnection): {
			state.idsDisconnecting = action.payload.clientIds;
			state.isDisconnectDialogOpen = true;
			return;
		}

		case getType(clientManagementActions.closeDisconnectDialog): {
			state.isDisconnectDialogOpen = false;
			state.idsDisconnecting = [];
			return;
		}

		case getType(clientManagementActions.disconnectMultipleClients.request): {
			const {clientIds} = action.payload;
			state.idsDisconnecting = clientIds;
			clientIds.forEach((id) => {
				state.disconnectLoadingMap[id] = LoadingState.LOADING;
			});
			return;
		}

		case getType(clientManagementActions.disconnectMultipleClients.success): {
			const {results} = action.payload;
			state.idsDisconnecting = [];
			results.forEach(({clientId}) => {
				state.disconnectLoadingMap[clientId] = LoadingState.LOADED;
			});
			return;
		}

		case getType(clientManagementActions.disconnectMultipleClients.failure): {
			state.idsDisconnecting = [];
			return;
		}
	}
}, initialState);
