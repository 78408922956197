import {select} from "redux-saga/effects";
import createAudioPlayer from "../../../audioPlayer/createAudioPlayer";
import {getActiveCall} from "../../../ts/redux/videoCall/VideoCallSelectors";
import {ActiveCall} from "../../../ts/services/chat/video/ActiveCall";

const noAnswerRingtone = createAudioPlayer("/assets/sounds/no_answer_call.mp3", {
	loop: false,
	autoplay: false,
	volume: 0.8,
});

const leaveCallRingtone = createAudioPlayer("/assets/sounds/leave_call.mp3", {
	loop: false,
	autoplay: false,
	volume: 0.8,
});

export function* playCallWindowSound() {
	const activeCall: ActiveCall = yield select(getActiveCall);
	// Call was established (More than one participant joined the call)
	const isCallEstablished: boolean =
		activeCall && Object.values(activeCall.participantMap).filter((p) => !!p.joinedTime).length > 1;
	if (isCallEstablished) {
		leaveCallRingtone.play();
	} else {
		noAnswerRingtone.play();
	}
}
