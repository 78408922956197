import {ndBlogSearchActions, NDBlogSearchActionType} from "./NDBlogSearchActions";
import {getType} from "typesafe-actions";
import analyticsService from "../../analytics/AnalyticsService";

const trackedActions = [
	ndBlogSearchActions.searchPostItem.success,
	ndBlogSearchActions.searchPostItem.failure,
	ndBlogSearchActions.setPasteLink,
].map(getType);

const properties = (action: NDBlogSearchActionType) => {
	switch (action.type) {
		case getType(ndBlogSearchActions.searchPostItem.success):
			return {keywords: action.payload.keywords};
		case getType(ndBlogSearchActions.setPasteLink):
			return action.payload;
		default:
			return null;
	}
};

export const ndBlogSearchEvents = analyticsService.trackReduxActions(trackedActions, properties);
