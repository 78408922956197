import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {takeEvery, fork, call, put, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {UserAccount} from "@sense-os/goalie-js";

import {getSessionId} from "../../auth/helpers/authStorage";
import emailVerificationSDK from "../helpers/emailverificationSDK";
import authSDK from "../../auth/helpers/authSDK";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {history} from "visual/App";
import {Path} from "../../ts/app/Path";

import {toastActions} from "../../toaster/redux/toastAction";
import {authActions} from "../../auth/redux/authActions";
import {warningBarActions} from "../../warningBar/redux/warningBarAction";
import {getEmailVerificationStatus, isAcceptedToOrganization} from "../../auth/redux/authSelectors";
import Localization, {ILocalization} from "../../localization/Localization";

import {DashboardAction} from "../redux/DashboardAction";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("emailVerificationSaga", SentryTags.EmailVerification);
const loc: ILocalization = Localization;

function* handleRequestVeriToken() {
	const token = yield call(getSessionId);

	try {
		yield apiCallSaga(emailVerificationSDK.requestVerificationCode, token);
		yield put(
			toastActions.addToast({
				message: strTranslation.DASHBOARD.email_verification.request.success.toast,
				type: "info",
			}),
		);
		yield put(DashboardAction.requestVerificationToken.success());
		yield put(DashboardAction.clearError());
	} catch (error) {
		yield put(DashboardAction.requestVerificationToken.failure(error));
		log.captureException(error);
	}
}

function* handleSubmitVeriToken(action: ActionType<typeof DashboardAction.submitVerificationToken.request>) {
	const token = yield call(getSessionId);
	const isAcceptedToOrg = yield select(isAcceptedToOrganization);
	let verificationCode: string = action.payload.emailVeriToken;

	try {
		// Submit the verification token
		yield apiCallSaga(emailVerificationSDK.sendVerificationCode, token, verificationCode);

		// Refetch the user data
		const userAccount: UserAccount = yield apiCallSaga(authSDK.getUserAccount, token);
		yield put(
			authActions.updateAuthUser({
				account: userAccount,
			}),
		);

		// Show the success toast
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.DASHBOARD.email_verification.submit_code.success.toast, {
					email: userAccount.email,
				}),
				type: "success",
			}),
		);

		// Remove email verification warning bar (for existing therapist)
		if (isAcceptedToOrg) {
			yield put(warningBarActions.removeWarningBar("email-verification"));
			history.push(Path.APP);
		}

		yield put(DashboardAction.submitVerificationToken.success());
		yield put(DashboardAction.clearError());
	} catch (error) {
		if (isAcceptedToOrg) {
			// Show the error toast
			yield put(
				toastActions.addToast({
					message: loc.formatMessage(strTranslation.DASHBOARD.email_verification.error.default),
					type: "error",
				}),
			);
		}
		// report an error
		yield put(DashboardAction.submitVerificationToken.failure(error));
		log.captureException(error);
	}
}

let pathName = window.location.pathname;
function* handleVerificationFromUrl() {
	const sessionId = yield call(getSessionId);
	const isVerified = yield select(getEmailVerificationStatus);

	if (isVerified || !sessionId) {
		return;
	}

	// If pathname string include `verify-email` and `sessionId`
	// get verification code from pathname then dispatch `submitVerificationToken` action
	if (pathName.includes("verify-email")) {
		const verificationCode = pathName.substring(pathName.lastIndexOf("/") + 1);
		yield put(DashboardAction.submitVerificationToken.request({emailVeriToken: verificationCode}));
		// Reset the pathname to prevent dispatched `submitVerificationToken.request` after therapist relogin.
		pathName = "";
	}
}

export default function* emailVerificationSaga() {
	yield takeEvery(getType(DashboardAction.submitVerificationToken.request), handleSubmitVeriToken);
	yield takeEvery(getType(DashboardAction.requestVerificationToken.request), handleRequestVeriToken);
	yield takeEvery(getType(authActions.login.success), handleVerificationFromUrl);
	yield fork(handleVerificationFromUrl);
}
