import {
	LanguageTag,
	PsychoEducationItemRequestCreate,
	TaskPostBodyRequest,
	TaskRecurrenceType,
	TrackerKey,
} from "@sense-os/goalie-js";
import {getTrackerIdBySensorName} from "../../tracker/utils/trackersSdkHelper";
import {
	PresetBehaviorExperimentTodoType,
	PresetItem,
	PresetItemTodo,
	PresetItemsType,
	PresetPrefix,
	PresetPsychoItem,
} from "../views/PresetsFormContext";
import strTranslation from "../../assets/lang/strings";
import localization from "../../localization/Localization";
import moment from "moment";
import {PresetPsychoEducation, PresetTodoRepetition} from "../presetsTypes";
import {CoreTrackerId} from "../../tracker/utils/coreTracker";
import {TIME_UNITS} from "constants/time";
import {PresetPsychoEducationTag, presetPsychoEducationMap} from "../presetPsychoEducation";

interface GetFilteredFormValuesByPrefixParams {
	formKeys: string[];
	formValues: PresetItemsType;
	prefix: PresetPrefix;
}

/** Filtering Form Values by Prefix attribute key */
export const getFilteredFormValuesByPrefix = (params: GetFilteredFormValuesByPrefixParams): PresetItemsType => {
	const {formKeys, formValues, prefix} = params;

	const filteredFormValue = formKeys.reduce((result, formKey) => {
		const currentFormValue: PresetItem = formValues[formKey];

		if (formKey.startsWith(prefix)) {
			result[formKey] = currentFormValue;
		}

		return result;
	}, {});

	return filteredFormValue;
};

/**
 * The Default value of `remind_at` preset task is 1 hours before the due date
 */
const defaultReminderTime: number = 1;

function getDefaultPresetTaskRemindAt(expiresAt: Date) {
	return moment(expiresAt).subtract(defaultReminderTime, TIME_UNITS.HOURS).toDate();
}

function getPresetRecurrenceType(presetTodoRepetition: PresetTodoRepetition): TaskRecurrenceType | "" {
	switch (presetTodoRepetition) {
		case PresetTodoRepetition.THRICE_A_DAY:
		case PresetTodoRepetition.TWICE_A_DAY:
		case PresetTodoRepetition.DAILY: {
			return TaskRecurrenceType.DAILY;
		}
		case PresetTodoRepetition.TWICE_WEEKLY:
		case PresetTodoRepetition.WEEKLY: {
			return TaskRecurrenceType.WEEKLY;
		}
		case PresetTodoRepetition.WEEKDAYS: {
			return TaskRecurrenceType.WEEKDAYS;
		}
		case PresetTodoRepetition.MONTHLY: {
			return TaskRecurrenceType.MONTHLY;
		}
		default: {
			return "";
		}
	}
}

function joinDateTime(date: Date, time: Date) {
	const timeString = `${time.getHours()}:${time.getMinutes()}:00`;

	const year = date.getFullYear();
	const month = date.getMonth() + 1; // Jan is 0, dec is 11
	const day = date.getDate();
	const dateString = `${year}-${month}-${day}`;

	const combined = new Date(`${dateString} ${timeString}`);

	return combined;
}

interface GetPresetTrackerTaskPayloadsParams {
	formValues: PresetItemsType;
	userId: number;
}

export const getPresetTrackerTaskPayloads = (
	params: GetPresetTrackerTaskPayloadsParams,
): TaskPostBodyRequest<any>[] => {
	const {formValues, userId} = params;

	const trackersFormValueKeys: string[] = Object.keys(formValues);

	const results = trackersFormValueKeys.reduce((result, formKey) => {
		const currentFormValue: PresetItem = formValues[formKey];
		const sensorName: string = currentFormValue.tracker.sensorName;
		const trackerId: number = getTrackerIdBySensorName(sensorName as TrackerKey);
		const todo: PresetItemTodo = currentFormValue.todo;
		const todoDate: Date = todo.date;
		const withNotification: boolean = todo.withNotification;

		const parsedTimes: Date[] = Object.keys(todo).reduce((parsedTimesResult, currentFormValueKey) => {
			if (currentFormValueKey.startsWith("time")) {
				const currentTime: Date = todo[currentFormValueKey];
				if (!!currentTime) {
					const combinedTimeDate: Date = joinDateTime(todoDate, currentTime);

					parsedTimesResult.push(combinedTimeDate);
				}
			}

			return parsedTimesResult;
		}, []);

		const translationKey: string = strTranslation.ZZ_REMINDER.prompt.title?.[sensorName];
		const isTranslationKeyExist: boolean = !!translationKey;

		const title: string = (() => {
			// handle custom preset tracker title
			if (!!currentFormValue?.tracker?.title) {
				return currentFormValue.tracker.title;
			}

			// default preset tracker
			if (isTranslationKeyExist) {
				return localization.formatMessage(translationKey);
			}

			return "";
		})();

		const payloads = parsedTimes.map((expiresAt) => {
			const body: TaskPostBodyRequest<any> = {
				title,
				patient: userId,
				tracker: trackerId,
				expiresAt,
				remindAt: withNotification ? getDefaultPresetTaskRemindAt(expiresAt) : null,
				recurrence: getPresetRecurrenceType(todo.repetition),
			};

			return body;
		});

		result.push(...payloads);

		return result;
	}, []);

	return results;
};

interface GetPresetPsychoEducationPayloadParams {
	formValue: PresetItem;
	userId: number;
}

export const getPresetPsychoEducationPayload = (
	params: GetPresetPsychoEducationPayloadParams,
): PsychoEducationItemRequestCreate => {
	const {userId} = params;

	const psychoEducationPayload: PsychoEducationItemRequestCreate = {
		patient: userId,
	};

	return psychoEducationPayload;
};

interface GetPresetTaskPsychoEducationPayloadParams {
	formValue: PresetItem;
	userId: number;
	psychoEducationId: number;
}

export const getPresetTaskPsychoEducationPayload = (
	params: GetPresetTaskPsychoEducationPayloadParams,
): TaskPostBodyRequest<any> => {
	const {formValue, userId, psychoEducationId} = params;

	const presetPsychoEducation: PresetPsychoItem = formValue.psycho;
	const presetPsychoEducationTodo: PresetItemTodo = formValue.todo;
	const expiresAt: Date = joinDateTime(presetPsychoEducationTodo.date, presetPsychoEducationTodo.time);
	const withNotification: boolean = presetPsychoEducationTodo.withNotification;

	const taskPayload: TaskPostBodyRequest<any> = {
		title: presetPsychoEducation.title,
		patient: userId,
		tracker: CoreTrackerId.PSYCHOEDUCATION,
		expiresAt,
		remindAt: withNotification ? getDefaultPresetTaskRemindAt(expiresAt) : null,
		registrationId: psychoEducationId,
	};

	return taskPayload;
};

interface GetPresetTaskBehaviorExperimentPayloadParams {
	formValues: PresetItemsType;
	userId: number;
}

export const getPresetTaskBehaviorExperimentPayload = (
	params: GetPresetTaskBehaviorExperimentPayloadParams,
): TaskPostBodyRequest<any>[] => {
	const {formValues, userId} = params;

	const formValueKeys: string[] = Object.keys(formValues);

	const extractedTodos: PresetItemTodo[] = formValueKeys.reduce((result, formKey) => {
		const currentForm: PresetItem = formValues[formKey];
		const todos: PresetBehaviorExperimentTodoType = currentForm.behaviourExperiments.todos;
		const todoKeys: string[] = Object.keys(todos);

		todoKeys.forEach((todoKey) => {
			const currentTodo: PresetItemTodo = todos[todoKey];
			if (!!currentTodo) {
				result.push(currentTodo);
			}
		});

		return result;
	}, []);

	const payloads: TaskPostBodyRequest<any>[] = extractedTodos.map((todo, index) => {
		// the format title would be
		// {behavior experiment title} {number}
		const title = `${localization.formatMessage(strTranslation.ZZ_REMINDER.prompt.title.BehaviorExperiment)} ${
			index + 1
		}`;
		const expiresAt: Date = joinDateTime(todo.date, todo.time);
		const withNotification: boolean = todo.withNotification;

		const taskPayload: TaskPostBodyRequest<any> = {
			title,
			patient: userId,
			tracker: CoreTrackerId.BEHAVIOR_EXPERIMENT,
			expiresAt,
			remindAt: withNotification ? getDefaultPresetTaskRemindAt(expiresAt) : null,
		};

		return taskPayload;
	});

	return payloads;
};

export const getPresetPsychoConfig = (tag: PresetPsychoEducationTag, isEnabled: boolean): PresetPsychoEducation => {
	const activeLanguage: LanguageTag = localization.getLocale();

	// return english as default
	switch (activeLanguage) {
		case LanguageTag.NL: {
			return {
				...presetPsychoEducationMap[tag][LanguageTag.NL],
				isEnabled,
			};
		}
		default: {
			return {
				...presetPsychoEducationMap[tag][LanguageTag.EN],
				isEnabled,
			};
		}
	}
};
