/**
 *	Author: leo Date: 09/04/2021
 *
 *	Various random value generators into this file
 */

/**
 * Returns a random integer between `min` and `max`.
 * `min` and `max` are included into the possible outcomes
 * @param min
 * @param max
 */
export function randomInteger(min: number = 10, max: number = 20) {
	if (min > max) {
		throw new Error(`min is greater than max:  ${min} > ${max}`);
	}
	return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Returns a random string of a given length.
 * The string consists of numbers and lowercase letters.
 *  Example:
 *  		randomString(12) // "56ho77egoz2f"
 *
 * Implemented using base36.
 * @See https://en.wikipedia.org/wiki/Base36
 * @param length
 */
export function randomString(length: number = 10) {
	let buffer = "";
	while (buffer.length < length) {
		buffer += Math.random().toString(36).substr(2);
	}
	return buffer.substring(0, length);
}

/**
 * Generates a random date between `start` and `end`
 * @param start
 * @param end
 */
export function randomDate(start: Date = new Date("01-01-1980"), end: Date = new Date("01-01-2010")): Date {
	return new Date(randomInteger(start.getTime(), end.getTime()));
}

/**
 * Generates a random e-mail address in the format   <randomString>@<randomString>.<domain>
 * Example:  "3d9fleor74@d34lf4.com"
 * @param topDomain top domain zone for the email to end with. Examples: "com", "nl", "co.uk", "gov".
 */
export function randomEmail(topDomain: string = "com") {
	return `${randomString()}@${randomString(5)}.${topDomain}`;
}
