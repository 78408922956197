import {ActionType, createAsyncAction} from "typesafe-actions";
import {UserDataRequestBody, LanguageTag, JobSpecialization} from "@sense-os/goalie-js";

export namespace UserProfileAction {
	export const saveUserBio = createAsyncAction(
		"userProfileActions/saveUserBio",
		"userProfileActions/saveUserBioSuccess",
		"userProfileActions/saveUserBioFail",
	)<{userBio: string}, void, Error>();

	export const saveUserPersonal = createAsyncAction(
		"userProfileActions/saveUserPersonal",
		"userProfileActions/saveUserPersonalSuccess",
		"userProfileActions/saveUserPersonalFail",
	)<{userData: UserDataRequestBody}, void, Error>();

	export const saveUserLanguagePreference = createAsyncAction(
		"userProfileActions/saveUserLanguagePreference",
		"userProfileActions/saveUserLanguagePreferenceSuccess",
		"userProfileActions/saveUserLanguagePreferenceFail",
	)<{language: LanguageTag; forceReload?: boolean}, void, Error>();

	export const saveUserAvatar = createAsyncAction(
		"userProfileActions/saveUserAvatar",
		"userProfileActions/saveUserAvatarSuccess",
		"userProfileActions/saveUserAvatarFail",
	)<{userAvatar: string}, void, Error>();

	export const loadJobSpecializations = createAsyncAction(
		"userProfileActions/loadJobSpecializations",
		"userProfileActions/loadJobSpecializationsSuccess",
		"userProfileActions/loadJobSpecializationsFail",
	)<void, JobSpecialization[], Error>();

	/** combine all typesafe actions above to be union */
	const userProfileActions = {
		saveUserBio,
		saveUserPersonal,
		saveUserAvatar,
		loadJobSpecializations,
	};
	/** Union of all action inside `userProfileActions` */
	export type UserProfileActionType = ActionType<typeof userProfileActions>;
}
