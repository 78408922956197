import {ActionType, getType} from "typesafe-actions";
import {callActions} from "../redux/callActions";
import {Task} from "redux-saga";
import {fork, delay, put, takeEvery, select} from "redux-saga/effects";
import {AppConfig} from "app/AppConfig";
import {toastActions} from "../../toaster/redux";
import localization from "../../localization/Localization";
import {getNamesByUserIds} from "../../userProfile/redux/UserProfileSelector";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {getNumberOfJoinedParticipants} from "services/chat/video/VideoCallHelpers";
import {getActiveCall} from "../../ts/redux/videoCall/VideoCallSelectors";
import {getAuthUser} from "../../auth/redux";
import strTranslation from "../../assets/lang/strings";

const callTimeoutInSeconds = AppConfig.CALL_TIMEOUT_SECONDS * 1000;
const log = createLogger("callTimeoutSaga", SentryTags.VideoCall);

/**
 * callTimeoutHandler task mapped by inviteeId
 */
export const callTimeoutMap: Map<number, Task> = new Map();

function* startCallTimeout(action: ActionType<typeof callActions.startTimeout>) {
	const {userId} = action.payload;

	if (callTimeoutMap.get(userId)) {
		yield put(callActions.stopTimeout(userId));
	}

	const task = yield fork(callTimeoutHandler, userId);
	callTimeoutMap.set(userId, task);
}

export function* callTimeoutHandler(inviteeId: number) {
	yield delay(callTimeoutInSeconds);

	log.addBreadcrumb({message: "No answer from invitee"});

	const activeCall = yield select(getActiveCall);
	const authUser = yield select(getAuthUser);
	const inviteeName = yield select(getNamesByUserIds([inviteeId]));
	const joinedParticipants = getNumberOfJoinedParticipants(activeCall, authUser.id);

	yield put(
		toastActions.addToast({
			message: localization.formatMessage(strTranslation.CHAT.video.no_answer.toast, {
				name: inviteeName,
			}),
			type: "warning",
		}),
	);

	// When there are no participants joined the call,
	// we can simply close the call window and let the clean up being handled in `callWindowClosedSaga`.
	if (joinedParticipants === 0) {
		yield put(callActions.closeCallWindow());
		return;
	}

	// In a group call, it only make sense to send cancellation signal to the inviteeId
	// and not end the call. Because there is still another participant in the call other than
	// the invitee.
	yield put(callActions.cancelCall(inviteeId));
}

function* stopCallTimeout(action: ActionType<typeof callActions.stopTimeout>) {
	const {userId} = action.payload;
	const timeoutTask = callTimeoutMap.get(userId);
	if (timeoutTask) {
		timeoutTask.cancel();
		callTimeoutMap.delete(userId);
	}
}

function* stopAllTimeouts() {
	const invitees = Array.from(callTimeoutMap.keys());
	for (let userId of invitees) {
		yield put(callActions.stopTimeout(Number(userId)));
	}
}

export default function* callTimeoutSaga() {
	yield takeEvery(getType(callActions.startTimeout), startCallTimeout);
	yield takeEvery(getType(callActions.stopTimeout), stopCallTimeout);
	yield takeEvery(getType(callActions.stopAllTimeouts), stopAllTimeouts);
}
