import {LoadingState} from "constants/redux";
import produce from "immer";
import {getType} from "typesafe-actions";
import {PSYCHOEDUCATION_FORM_TYPE, PsychoEducationState} from "../psychoEducationTypes";
import {PsychoEducationActionType, psychoEducationActions} from "./psychoEducationActions";

const initialState: PsychoEducationState = {
	isFormOpened: false,
	savingState: LoadingState.EMPTY,
	deletingState: LoadingState.EMPTY,
	articleSavingState: LoadingState.EMPTY,
	articleDeletingState: LoadingState.EMPTY,
	savedFormValues: {},
	psychoEducationFetchState: LoadingState.EMPTY,
	psychoEducationData: null,
	removedArticleIds: [],
	userId: null,
	id: null,
	formType: null,
};

export const psychoEducationReducer = produce(
	(state: PsychoEducationState = initialState, action: PsychoEducationActionType) => {
		switch (action.type) {
			case getType(psychoEducationActions.openCreateForm): {
				const {userId} = action.payload;
				state.isFormOpened = true;
				state.userId = userId;
				state.formType = PSYCHOEDUCATION_FORM_TYPE.CREATING;
				return;
			}
			case getType(psychoEducationActions.openEditForm): {
				const {userId, psychoEducationFormValues, psychoEducationId} = action.payload;
				state.isFormOpened = true;
				state.userId = userId;
				state.id = psychoEducationId;
				state.formType = PSYCHOEDUCATION_FORM_TYPE.EDITING;
				state.savedFormValues = {
					...state.savedFormValues,
					[userId]: psychoEducationFormValues,
				};
				return;
			}
			case getType(psychoEducationActions.closeForm): {
				state.isFormOpened = false;
				state.savingState = LoadingState.EMPTY;
				state.userId = null;
				return;
			}
			case getType(psychoEducationActions.saveFormValues): {
				state.savedFormValues = {
					...state.savedFormValues,
					[action.payload.userId]: action.payload.formValues,
				};
				return;
			}
			case getType(psychoEducationActions.savePsychoEducationTask.request):
			case getType(psychoEducationActions.savePsychoEducation.request):
				state.savingState = LoadingState.LOADING;
				return;

			case getType(psychoEducationActions.savePsychoEducationTask.success):
			case getType(psychoEducationActions.savePsychoEducation.success):
				state.savingState = LoadingState.LOADED;
				state.savedFormValues = {
					...state.savedFormValues,
					[action.payload.userId]: null,
				};
				return;

			case getType(psychoEducationActions.savePsychoEducationTask.failure):
			case getType(psychoEducationActions.savePsychoEducation.failure):
				state.savingState = LoadingState.ERROR;
				return;

			case getType(psychoEducationActions.fetchPsychoEducation.request):
				state.psychoEducationFetchState = LoadingState.LOADING;
				state.psychoEducationData = null;
				return;

			case getType(psychoEducationActions.fetchPsychoEducation.success):
				const data = action.payload.psychoEducationData;
				state.psychoEducationFetchState = LoadingState.LOADED;
				state.psychoEducationData = data;
				return;

			case getType(psychoEducationActions.fetchPsychoEducation.failure):
				state.psychoEducationFetchState = LoadingState.ERROR;
				return;

			case getType(psychoEducationActions.deletePsychoEducation.request):
				state.deletingState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.deletePsychoEducation.success):
				state.deletingState = LoadingState.LOADED;
				state.psychoEducationData = null;
				state.id = null;
				return;
			case getType(psychoEducationActions.deletePsychoEducation.failure):
				state.deletingState = LoadingState.ERROR;
				return;

			case getType(psychoEducationActions.resetFormValues): {
				state.savedFormValues = {
					...state.savedFormValues,
					[action.payload.userId]: null,
				};
				return;
			}
			case getType(psychoEducationActions.saveArticles.request):
				state.articleSavingState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.saveArticles.success):
				state.articleSavingState = LoadingState.LOADED;
				return;
			case getType(psychoEducationActions.saveArticles.failure):
				state.articleSavingState = LoadingState.ERROR;
				return;

			case getType(psychoEducationActions.updateArticles.request):
				state.articleSavingState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.updateArticles.success):
				state.articleSavingState = LoadingState.LOADED;
				return;
			case getType(psychoEducationActions.updateArticles.failure):
				state.articleSavingState = LoadingState.ERROR;
				return;

			case getType(psychoEducationActions.removeArticles.request):
				state.articleDeletingState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.removeArticles.success):
				state.articleDeletingState = LoadingState.LOADED;
				return;
			case getType(psychoEducationActions.removeArticles.failure):
				state.articleDeletingState = LoadingState.ERROR;
				return;

			case getType(psychoEducationActions.setRemovedArticleId):
				state.removedArticleIds = [...state.removedArticleIds, ...action.payload.articleIds];
				return;
		}
	},
	initialState,
);
