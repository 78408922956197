import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {takeEvery, put, call} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {clientNetworkActions} from "../redux/clientNetworkActions";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getClientsConnection} from "../helpers/clientNetworkUtils";
import loc from "../../localization/Localization";
import {toastActions} from "../../toaster/redux";
import {ConnectedTherapist} from "../clientNetworkTypes";
import strTranslation from "../../assets/lang/strings";
import {makeQueue} from "../../helpers/sagas/sagaQueue";

const log = createLogger("clientNetworkSaga", SentryTags.ClientNetwork);

const generalQueue = makeQueue("general portal queue", 3);
const getClientsConnectionQueued = generalQueue.wrapFnInQueue(getClientsConnection);

function* loadClientsConnection(action: ActionType<typeof clientNetworkActions.loadClientsConnectionById.request>) {
	try {
		const token: string = yield call(getSessionId);
		const {clientId} = action.payload;
		const clientsConnection: ConnectedTherapist[] = yield apiCallSaga(getClientsConnectionQueued, token, clientId);
		yield put(
			clientNetworkActions.loadClientsConnectionById.success({
				clientId: action.payload.clientId,
				clientsConnection: clientsConnection,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(
			clientNetworkActions.loadClientsConnectionById.failure({clientId: action.payload.clientId, error: err}),
		);
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.CLIENT_NETWORK.connections.fetching.failed),
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(clientNetworkActions.loadClientsConnectionById.request), loadClientsConnection);
}
