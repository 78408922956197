import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {CustomTrackerDialogState} from "../customTrackerTypes";
import {CustomTrackerActionType, customTrackerActions} from "./customTrackerActions";

const initialState: CustomTrackerDialogState = {
	customTrackerFetchState: LoadingState.EMPTY,
	customTrackerData: null,
};

export default function customTrackerDialogReducer(
	state: CustomTrackerDialogState = initialState,
	action: CustomTrackerActionType,
): CustomTrackerDialogState {
	switch (action.type) {
		case getType(customTrackerActions.fetchCustomTrackerDialog.request):
			return {
				...state,
				customTrackerFetchState: LoadingState.LOADING,
				customTrackerData: null,
			};
		case getType(customTrackerActions.fetchCustomTrackerDialog.success):
			const {customTrackerData} = action.payload;
			return {
				...state,
				customTrackerFetchState: LoadingState.LOADED,
				customTrackerData: customTrackerData,
			};
		case getType(customTrackerActions.fetchCustomTrackerDialog.failure):
			return {
				...state,
				customTrackerFetchState: LoadingState.ERROR,
			};
		default:
			return state;
	}
}
