import {getType} from "typesafe-actions";
import produce from "immer";
import {RomState} from "../romTypes";
import {RomActionType, romActions} from "./romActions";
import {LoadingState} from "constants/redux";

const initialState: RomState = {
	smqData: null,
	omqData: null,
	romFetchState: LoadingState.EMPTY,
};

export const romReducer = produce((state: RomState = initialState, action: RomActionType) => {
	switch (action.type) {
		case getType(romActions.fetchRom.request):
			state.romFetchState = LoadingState.LOADING;
			return;
		case getType(romActions.fetchRom.success):
			state.romFetchState = LoadingState.LOADED;
			state.omqData = action.payload.omqData;
			state.smqData = action.payload.smqData;
			return;
		case getType(romActions.fetchRom.failure):
			state.romFetchState = LoadingState.ERROR;
			return;
	}
}, initialState);
