import {EventViewData} from "../ts/redux/tracking/TrackingTypes";
import {LoadingState} from "../ts/constants/redux";

export interface ClientRegistrationState {
	// user registrations
	registrations: EventViewData[];
	/**
	 * loading state for fetching activities
	 */
	loadingState: LoadingState;
	/**
	 * Error object if activities failed to be fetched
	 */
	error?: Error;
}

export interface ClientActivityState {
	// client registration per activity type
	clientRegistrations: Record<CLIENT_ACTIVITY_TYPE, ClientRegistrationState> | {};
	/** Last fetched */
	lastFetched: Date;
}

/**
 * ClientActivityState mapped by userId
 */
export interface ClientsActivityState {
	/**
	 * Contain all the fetched registrations of the clients.
	 */
	clientActivities: Record<string, ClientActivityState>;

	/**
	 * This is the id of recently opened registrations to help highlight the registration itself.
	 */
	idOfRecentlyOpenedRegistration?: string;
}

export enum CLIENT_ACTIVITY_TYPE {
	DAILY_PLANNER = "dailyPlanner",
	MEETING_NOTE = "meetingNote",
	DIARY_ENTRY = "diaryEntry",
	THOUGHT_RECORD = "thoughtRecord",
	PSYCHO_EDUCATION = "psychoEducation",
	BEHAVIOR_EXPERIMENT = "behaviorExperiment",
	CHECK_IN_FEELING = "checkInFeeling",
	TASK = "task",
}

export type ClientActivitySensors =
	| CLIENT_ACTIVITY_TYPE.MEETING_NOTE
	| CLIENT_ACTIVITY_TYPE.DIARY_ENTRY
	| CLIENT_ACTIVITY_TYPE.CHECK_IN_FEELING
	| CLIENT_ACTIVITY_TYPE.THOUGHT_RECORD;
