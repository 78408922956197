import {LoadingState} from "constants/redux";
import {InviteClientsState} from "./inviteClientsType";
import {inviteClientsActionType, inviteClientsActions} from "./inviteClientsActions";
import {getType} from "typesafe-actions";

const initialState: InviteClientsState = {
	/** Initial State for Fetching State */
	fetchingState: LoadingState.EMPTY,
	/** Initial State for `currentPage` */
	currentPage: 1,
	/** Initial State for `inviteClientsList` */
	inviteClientsList: [],
	/** Initial State for total */
	total: 0,
	/** Initial State for Saving State */
	savingState: LoadingState.EMPTY,
	/** Initial State for Deleting State */
	deletingState: LoadingState.EMPTY,
	/** Initial State for Resending State */
	resendingState: LoadingState.EMPTY,
};

export function inviteClientsReducer(
	state: InviteClientsState = initialState,
	action: inviteClientsActionType,
): InviteClientsState {
	switch (action.type) {
		case getType(inviteClientsActions.fetchInviteClientsList.request): {
			return {
				...state,
				fetchingState: LoadingState.LOADING,
				currentPage: 1,
				inviteClientsList: [],
				total: 0,
			};
		}
		case getType(inviteClientsActions.fetchInviteClientsList.success): {
			const {data} = action.payload;

			return {
				...state,
				fetchingState: LoadingState.LOADED,
				currentPage: data.page,
				inviteClientsList: data.results,
				total: data.count,
			};
		}
		case getType(inviteClientsActions.fetchInviteClientsList.failure): {
			return {
				...state,
				fetchingState: LoadingState.ERROR,
				currentPage: 1,
				inviteClientsList: [],
				total: 0,
			};
		}
		case getType(inviteClientsActions.saveInviteClient.request): {
			return {
				...state,
				savingState: LoadingState.LOADING,
			};
		}
		case getType(inviteClientsActions.saveInviteClient.success): {
			return {
				...state,
				savingState: LoadingState.LOADED,
			};
		}
		case getType(inviteClientsActions.saveInviteClient.failure): {
			return {
				...state,
				savingState: LoadingState.ERROR,
			};
		}
		case getType(inviteClientsActions.deleteInviteClient.request): {
			return {
				...state,
				deletingState: LoadingState.LOADING,
			};
		}
		case getType(inviteClientsActions.deleteInviteClient.success): {
			return {
				...state,
				deletingState: LoadingState.LOADED,
			};
		}
		case getType(inviteClientsActions.deleteInviteClient.failure): {
			return {
				...state,
				deletingState: LoadingState.ERROR,
			};
		}
		case getType(inviteClientsActions.resendInviteClient.request): {
			return {
				...state,
				resendingState: LoadingState.LOADING,
			};
		}
		case getType(inviteClientsActions.resendInviteClient.success): {
			return {
				...state,
				resendingState: LoadingState.LOADED,
			};
		}
		case getType(inviteClientsActions.resendInviteClient.failure): {
			return {
				...state,
				resendingState: LoadingState.ERROR,
			};
		}
		default: {
			return state;
		}
	}
}
