import {ConnectionStatus} from "@sense-os/goalie-js";
import {takeEvery, put, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {ChatAction} from "../redux/ChatAction";
import Localization from "../../localization/Localization";
import {toastActions} from "../../toaster/redux/toastAction";
import {isAcceptedToOrganization, isLoggedIn, isTherapist} from "../../auth/redux";
import strTranslation from "../../assets/lang/strings";

function* showConnectionStatusViaToaster(action: ActionType<typeof ChatAction.setConnectionStatus>) {
	const isAuthLoggedIn: boolean = yield select(isLoggedIn);
	const isAuthTherapists: boolean = yield select(isTherapist);
	const isAuthAcceptedToOrganization: boolean = yield select(isAcceptedToOrganization);

	if (!isAuthLoggedIn) {
		return;
	}

	if (isAuthTherapists && !isAuthAcceptedToOrganization) {
		return;
	}

	switch (action.payload.connectionStatus) {
		case ConnectionStatus.Connecting: {
			const msg = Localization.formatMessage(strTranslation.CHAT.toast.connecting);
			yield put(toastActions.addToast({message: msg, type: "info"}));
			return;
		}

		case ConnectionStatus.Disconnected: {
			const msg = Localization.formatMessage(strTranslation.CHAT.toast.chat_disconnected);
			yield put(toastActions.addToast({message: msg, type: "error"}));
			return;
		}

		case ConnectionStatus.Connected: {
			const msg = Localization.formatMessage(strTranslation.CHAT.toast.connected);
			yield put(toastActions.addToast({message: msg, type: "success"}));
			return;
		}
	}
}

export default function* () {
	yield takeEvery(getType(ChatAction.setConnectionStatus), showConnectionStatusViaToaster);
}
