import {ActionType, getType} from "typesafe-actions";
import {call, put, takeEvery} from "redux-saga/effects";
import {romActions} from "../redux/romActions";
import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSensorResolved} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {Omq, SensorDatum, Sensors, Smq} from "redux/tracking/TrackingTypes";

const log = createLogger("fetchRom", SentryTags.Rom);

export function* fetchRom(action: ActionType<typeof romActions.fetchRom.request>) {
	const {userId, startDate} = action.payload;
	const token: string = yield call(getSessionId);

	try {
		const results: SensorDatum<Omq | Smq>[] = yield apiCallSaga(
			getSensorResolved,
			token,
			userId,
			[Sensors.OMQ, Sensors.SMQ],
			startDate,
			undefined,
		);
		// sort results in ascending order by start time
		const sortedResults = results.sort((prev, next) => {
			if (prev.startTime > next.startTime) return 1;
			else return -1;
		});

		const smqResults = sortedResults.filter((data) => data.sensorName === Sensors.SMQ);

		const omqResults = sortedResults.filter((data) => data.sensorName === Sensors.OMQ);

		yield put(
			romActions.fetchRom.success({
				smqData: smqResults as SensorDatum<Smq>[],
				omqData: omqResults as SensorDatum<Omq>[],
			}),
		);
	} catch (error) {
		log.captureException(error);
		yield put(romActions.fetchRom.failure());
	}
}

export default function* () {
	yield takeEvery(getType(romActions.fetchRom.request), fetchRom);
}
