import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import createLogger from "../../../logger/createLogger";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {GScheme, SensorDatum} from "redux/tracking/TrackingTypes";
import {thoughtRecordActions} from "../redux/thoughtRecordActions";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {getThoughtRecordSensors} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {getSessionId} from "../../../auth/helpers/authStorage";

const log = createLogger("fetchThoughtRecord", SentryTags.ThoughtRecord);

export function* fetchThoughtRecord(action: ActionType<typeof thoughtRecordActions.fetchThoughtRecord.request>) {
	const {userId, thoughtRecordId} = action.payload;
	const token = yield call(getSessionId);

	try {
		const thoughtRecordsResults: SensorDatum<GScheme>[] = yield apiCallSaga(
			getThoughtRecordSensors,
			token,
			userId,
			undefined,
			undefined,
			[thoughtRecordId],
		);

		yield put(
			thoughtRecordActions.fetchThoughtRecord.success({
				thoughtRecordData: thoughtRecordsResults?.[0],
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(thoughtRecordActions.fetchThoughtRecord.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(thoughtRecordActions.fetchThoughtRecord.request), fetchThoughtRecord);
}
