import {TaskStatus} from "@sense-os/goalie-js";
import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {BehaviorExperimentDataSource, ExpireStatus} from "../interventionBehaviorExperimentType";

export const interventionBehaviorExperimentActions = {
	fetchBehaviorExperiment: createAsyncAction(
		"interventionBehaviorExperiment/fetchBehaviorExperiment",
		"interventionBehaviorExperiment/fetchBehaviorExperimentSuccess",
		"interventionBehaviorExperiment/fetchBehaviorExperimentFail",
	)<
		{hashId: string; currentPage: number; pageSize: number; status?: TaskStatus},
		{total: number; data: BehaviorExperimentDataSource[]},
		{error: Error}
	>(),
	setBehaviorExpiresStatus: createAction(
		"interventionBehaviorExperiment/setBehaviorExpiresStatus",
		(expireStatus: ExpireStatus) => {
			return {expireStatus};
		},
	)(),
	setBehaviorCurrentPage: createAction("interventionBehaviorExperiment/setBehaviorCurrentPage", (page: number) => {
		return {page};
	})(),
};

export type interventionBehaviorExperimentActionType = ActionType<typeof interventionBehaviorExperimentActions>;
