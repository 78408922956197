import {DISC} from "IoC/DISC";
import {call, cancel, fork, put, takeEvery, takeLeading} from "redux-saga/effects";
import {createCallWindow, getCallWindow} from "services/chat/video/CallWindow";
import {getType} from "typesafe-actions";
import {emdrActions} from "../../../emdr/emdrActions";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {createSendMessage} from "../../../IWC/IWC";
import {WinMsgTypes} from "../../../IWC/WinMsg";
import createLogger from "../../../logger/createLogger";
import {privateNotesActions} from "../../../privateNotes/redux/privateNotesAction";
import {toastActions} from "../../../toaster/redux";
import {callActions} from "../../redux/callActions";
import {onCallWindowClosedSaga} from "./callWindowClosedSaga";
import {onMainWindowClosedSaga} from "./mainWindowClosedSaga";
import {onIncomingWindowMsgSaga} from "./onIncomingWindowMsgSaga";
import {setChatPresenceToBusyIfOnline} from "./updateChatPresence";

const log = createLogger("callWindowSaga", SentryTags.VideoCall);

function* openCallWindowSaga() {
	try {
		const callWindow: Window = yield call(createCallWindow);

		// Check if call window is opened or not
		if (!callWindow || callWindow.closed) {
			// Popup is blocked, inform the user to troubleshoot
			yield put(
				toastActions.addToast({message: "CHAT.video.popup_blocker.toast", type: "error", timeout: 30000}),
			);
			throw new Error("Unable to open call window");
		} else {
			yield put(callActions.openCallWindow.success());
		}

		// Initialize features that need to communicate
		// with the call window via IWC
		const sendIWCMessage = yield call(createSendMessage, callWindow);
		yield put(emdrActions.initEmdrSaga(callWindow));
		yield put(privateNotesActions.startListeningToIwc(callWindow));

		// Initialize listeners
		yield fork(initCallWindow, callWindow);
		yield fork(onMainWindowClosedSaga, callWindow);

		// Ping call window to check whether call window is ready to recieve data from main window.
		// The call window will send WinMsgTypes.VIDEO_CALL_WINDOW_READY if it is.
		yield call(sendIWCMessage, {type: WinMsgTypes.CHECK_VIDEO_CALL_WINDOW});

		// Change online presence to BUSY
		yield call(setChatPresenceToBusyIfOnline);
	} catch (err) {
		log.captureException(err);
		yield put(callActions.openCallWindow.failure(err));
	}
}

function* initCallWindow(callWindow: Window) {
	log.debug("initializing call window");

	// Subscribe to call window IWC messages
	const incomingWindowMsgTask = yield fork(onIncomingWindowMsgSaga, callWindow);

	// // Wait for call window to be closed
	yield call(onCallWindowClosedSaga, callWindow);

	// Stop any running saga in `onIncomingWindowMsgSaga`
	yield cancel(incomingWindowMsgTask);
}

function* closeCallWindowSaga() {
	const callWindow: Window = yield call(getCallWindow);
	if (callWindow && callWindow.open) {
		// Simply close call window.
		// This should trigger "onclose" event, which is handled in `onCallWindowClosedSaga`
		callWindow.close();
	}
}

function* callWindowFailureSaga() {
	// Resetting call service when portal is unable to open the call window.
	// This allows user to re-initialize the call again after user has troubleshoot popup blocker issue.
	yield call(DISC.getVideoCallService().reset);
}

export function* callWindowSaga() {
	yield takeLeading(getType(callActions.openCallWindow.request), openCallWindowSaga);
	yield takeLeading(getType(callActions.openCallWindow.failure), callWindowFailureSaga);
	yield takeEvery(getType(callActions.closeCallWindow), closeCallWindowSaga);
}
