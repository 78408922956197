import {call, put, select, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {getAuthUser} from "../auth/redux";

import {doSomethingIfOrWaitUntil} from "../helpers/sagas/stateMachine";
import {userSettingsAction} from "../userSettings/redux/userSettingsActions";
import {getIsUserSettingsLoaded, getRecentChangesFlags} from "../userSettings/redux/userSettingsSelectors";

import {NewFeatures, getFeaturePopupList} from "./newFeaturePopupTypes";
import {newFeaturePopupActions} from "./redux/newFeaturePopupActions";
import {getCanPopupBeShown, getShownPopup} from "./redux/newFeaturePopupSelector";

/**
 * Set all popup to be "opened" in the user settings.
 * This is called for newly registered therapist to prevent overwhelming them
 * with all these popups.
 */
export function* setAllPopupToBeOpened(features: NewFeatures[]) {
	const flags = {};
	features.forEach((feature) => {
		flags[feature] = true;
	});

	yield put(
		userSettingsAction.saveSettings.request({
			settings: {
				flagForRecentChangesPopup: flags,
			},
		}),
	);
}

/**
 * This function will check whether portal need to show the new feature popup or not.
 */
export function* showPopupIfNeeded() {
	// First, check whether any popup is currently shown. If yes, end the function.
	const shownPopup = yield select(getShownPopup);
	if (!!shownPopup) return;

	const canPopupBeShown = yield select(getCanPopupBeShown);

	const recentChangesFlag = yield select(getRecentChangesFlags);

	/**
	 * If none of the popup is ever shown, we assume that the therapist is newly registered.
	 * Per https://github.com/senseobservationsystems/web-getgoalie/issues/3443
	 * we decide to hide all the popup for newly registered therapist.
	 */
	const authUser = yield select(getAuthUser);
	const newFeatures = getFeaturePopupList(authUser);
	const allFeatureAreNeverShown = newFeatures.every((feature) => !recentChangesFlag[feature]);
	if (allFeatureAreNeverShown) {
		yield call(setAllPopupToBeOpened, newFeatures);
		return;
	}

	// Check the features one by one and show popup if needed.
	for (let idx = 0; idx < newFeatures.length; idx++) {
		if (!recentChangesFlag[newFeatures[idx]] && canPopupBeShown[newFeatures[idx]]) {
			yield put(newFeaturePopupActions.showPopup(newFeatures[idx]));
			return;
		}
	}
}

export function* hidePopup(action: ActionType<typeof newFeaturePopupActions.hidePopup>) {
	const {feature} = action.payload;

	// just save the given feature into the relevant settings object
	// by calling `savePopupSettings` action.
	yield put(newFeaturePopupActions.savePopupSettings(feature));
}

function* savePopupSettings(action: ActionType<typeof newFeaturePopupActions.savePopupSettings>) {
	const {feature} = action.payload;
	const flags = yield select(getRecentChangesFlags);

	yield put(
		userSettingsAction.saveSettings.request({
			settings: {
				flagForRecentChangesPopup: {
					...flags,
					[feature]: true,
				},
			},
		}),
	);

	// Check whether another popup need to be shown or not.
	yield call(showPopupIfNeeded);
}

export function* newFeaturePopupSaga() {
	yield takeEvery(getType(newFeaturePopupActions.hidePopup), hidePopup);
	yield takeEvery(getType(newFeaturePopupActions.savePopupSettings), savePopupSettings);

	// Recheck whenever popup availability is changed
	yield takeEvery(getType(newFeaturePopupActions.setPopupCanBeShown), showPopupIfNeeded);
	yield takeEvery(getType(newFeaturePopupActions.setPopupCannotBeShown), showPopupIfNeeded);

	yield doSomethingIfOrWaitUntil(
		showPopupIfNeeded,
		select(getIsUserSettingsLoaded),
		getType(userSettingsAction.loadSettings.success),
	);
}
