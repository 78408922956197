import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import createLogger from "../../../logger/createLogger";
import {interventionPsychoEducationActions} from "../redux/interventionPsychoEducationActions";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {PsychoEducationTaskRegistration, TaskResponseList} from "@sense-os/goalie-js";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import taskSDK from "../../../taskRegistration/helpers/taskSDK";
import {CoreTrackerId} from "../../../tracker/utils/coreTracker";

const log = createLogger("fetchInterventionPsychoEducationSaga", SentryTags.Intervention);

export function* fetchInterventionPsychoEducation(
	action: ActionType<typeof interventionPsychoEducationActions.fetchPsychoEducations.request>,
) {
	const {hashId, page, pageSize, status} = action.payload;
	const token = yield call(getSessionId);

	try {
		const res: TaskResponseList<PsychoEducationTaskRegistration> = yield apiCallSaga(taskSDK.getTaskList, token, {
			patient: hashId,
			page,
			pageSize,
			status,
			tracker: CoreTrackerId.PSYCHOEDUCATION,
		});

		yield put(
			interventionPsychoEducationActions.fetchPsychoEducations.success({
				data: res.results,
				totalData: res.count,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(interventionPsychoEducationActions.fetchPsychoEducations.failure({err}));
	}
}

export default function* () {
	yield takeEvery(
		getType(interventionPsychoEducationActions.fetchPsychoEducations.request),
		fetchInterventionPsychoEducation,
	);
}
