import localization from "../../localization/Localization";
import {put, takeEvery, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {timeTrackingActions} from "../redux/timeTrackingActions";
import {toastActions} from "../../toaster/redux";
import {TimeTrackingEntry} from "../timeTrackingTypes";
import {getEntriesCache} from "../redux/timeTrackingSelectors";
import timeTrackingSdk from "../timeTrackingSdk";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("TimeTracking, confirmation", SentryTags.TherapistTimeTracking);
const loc = localization;

/**
 * Performs confirmation of the entries displayed in the Day Confirmation Dialog.
 * @param action
 */

function* handleConfirmEntriesRequest(action: ActionType<typeof timeTrackingActions.confirmEntries.request>) {
	const entryIdsToConfirm = action.payload.entries.map((entry) => entry.id);

	try {
		const token: string = getSessionId();

		const confirmedEntries: TimeTrackingEntry[] = yield apiCallSaga(
			timeTrackingSdk.confirmTimeEntries,
			token,
			entryIdsToConfirm,
		);

		const confirmedEntryIds: number[] = confirmedEntries.map((entry) => entry.id);

		const selectedDate = action.payload.date;
		const localEntriesMap = yield select(getEntriesCache);

		// entries of the selected date
		let entries: TimeTrackingEntry[] = localEntriesMap[selectedDate] || [];

		// mark entries of today as confirmed according to the IDs returned from the BE.
		const updatedEntries = entries.map((entry) => {
			return {...entry, isConfirmed: entry.isConfirmed || confirmedEntryIds.some((id) => id === entry.id)};
		});

		// send successfully confirmed entries to the redux store
		yield put(timeTrackingActions.confirmEntries.success({entries: updatedEntries, date: action.payload.date}));

		// refetch unconfirmed entries
		yield put(timeTrackingActions.fetchUnconfirmedEntries.request());

		// the toast of success
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.TIME_TRACKING.confirm_day.toast.success),
				type: "success",
			}),
		);
	} catch (error) {
		yield put(timeTrackingActions.confirmEntries.failure({error}));

		// the toast of failure
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.TIME_TRACKING.confirm_day.toast.failure),
				type: "error",
			}),
		);
		log.captureException(error);
	}
}

export default function* () {
	yield takeEvery(getType(timeTrackingActions.confirmEntries.request), handleConfirmEntriesRequest);
}
