import {getType} from "typesafe-actions";
import produce from "immer";

import {InterventionCustomExerciseState} from "../customExerciseInterventionType";
import {
	interventionCustomExerciseActionType,
	interventionCustomExerciseActions,
} from "./interventionCustomExerciseAction";
import {LoadingState} from "constants/redux";

const initialState: InterventionCustomExerciseState = {
	data: null,
	fetchingState: LoadingState.EMPTY,
	totalData: 0,
};

export const interventionCustomExerciseReducer = produce(
	(state: InterventionCustomExerciseState, action: interventionCustomExerciseActionType) => {
		switch (action.type) {
			case getType(interventionCustomExerciseActions.fetchCustomExercise.request): {
				state.fetchingState = LoadingState.LOADING;
				return;
			}
			case getType(interventionCustomExerciseActions.fetchCustomExercise.success): {
				const {count, results} = action.payload;
				state.totalData = count;
				state.data = results;
				state.fetchingState = LoadingState.LOADED;
				return;
			}
			case getType(interventionCustomExerciseActions.fetchCustomExercise.failure): {
				state.fetchingState = LoadingState.ERROR;
				return;
			}
		}
	},
	initialState,
);
