import {select} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {DoSomething, stateMachineHandler} from "../../helpers/sagas/stateMachine";

import * as bootstrapSelectors from "../redux/bootstrapSelectors";
import {bootstrapActions} from "../redux/bootstrapActions";

/**
 * Forks the given fn after portal has been bootstrapped
 */
export function whenBootstrapped(startEffect?: DoSomething) {
	return stateMachineHandler(
		select(bootstrapSelectors.isBootstrapped),
		getType(bootstrapActions.endBootstrapApp),
		getType(bootstrapActions.startBootstrapApp),
		startEffect,
		undefined,
	);
}
