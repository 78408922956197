import {put, takeEvery} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {shouldReportToSentry} from "../../auth/helpers";
import {getSessionId} from "../../auth/helpers/authStorage";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {refreshFileIfNeeded} from "../helpers/downloadFileHelper";
import {fileSharingActions} from "../redux/fileSharingActions";
import fileSharingSDK from "./fileSharingSDK";

const log = createLogger("loadBlobSaga", SentryTags.FileSharing);

function* loadThumbnailBlob(action: ActionType<typeof fileSharingActions.loadBlob.request>) {
	try {
		const {chatFile, type, userId} = action.payload;
		let blobUrl = null;
		let blobThumbnailUrl = null;

		const refreshedFile = yield apiCallSaga(refreshFileIfNeeded, userId, chatFile);
		if (type == "full") {
			// return if blobUrl already exists.
			if (chatFile.blobUrl) {
				return;
			} else {
				blobUrl = yield apiCallSaga(fileSharingSDK.getBlobUrl, getSessionId(), refreshedFile.url);
			}
		} else if (type == "thumb") {
			// return if blobThumbnailUrl already exists.
			if (chatFile.blobThumbnailUrl) {
				return;
			} else {
				blobThumbnailUrl = yield apiCallSaga(
					fileSharingSDK.getBlobUrl,
					getSessionId(),
					refreshedFile.thumbnailUrl,
				);
			}
		}

		yield put(
			fileSharingActions.loadBlob.success({
				userId: action.payload.userId,
				id: chatFile.id,
				urls: {
					blobUrl,
					blobThumbnailUrl,
				},
			}),
		);
	} catch (error) {
		if (shouldReportToSentry(error)) {
			log.captureException(error);
		}

		yield put(fileSharingActions.loadBlob.failure({userId: action.payload.userId, error}));
	}
}

export default function* () {
	yield takeEvery(fileSharingActions.loadBlob.request, loadThumbnailBlob);
}
