export function wrapInPromise<T>(fn: () => T): Promise<T> {
	return new Promise((resolve, reject) => {
		try {
			resolve(fn());
		} catch (e) {
			reject(e);
		}
	});
}

export function promisify<T, U extends any[]>(fn: (...args: U) => T) {
	return (...args: U) => wrapInPromise(() => fn(...args));
}
