import moment from "moment";
import {SensorData} from "@sense-os/goalie-js/dist";
import {MeetingNote} from "@sense-os/sensor-schema/goalie-2-ts/meeting_note";
import {SensorConfig, SensorConfigs, Sensors} from "../../../ts/redux/tracking/TrackingTypes";
import {MeetingNoteFormValues} from "../meetingNotesTypes";

export const transformFormValuesIntoSensorData = (authUserId: number, formValues: MeetingNoteFormValues) => {
	const sensorConfig: SensorConfig = SensorConfigs[Sensors.MEETING_NOTE];

	if (!formValues) {
		return null;
	}

	const sensorData: SensorData<MeetingNote> = {
		sensorName: sensorConfig.name,
		sourceName: sensorConfig.sourceName,
		startTime: formValues.reportTime,
		endTime: formValues.reportTime,
		version: sensorConfig.version,
		value: {
			title: formValues.title?.trim(),
			note: formValues.meetingNote.trim(),
			reportTime: moment(formValues.reportTime).format(),
			creatorId: authUserId,
		},
	};
	return sensorData;
};
