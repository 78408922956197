import produce from "immer";
import {UserManagementState} from "../userManagementTypes";
import {UserManagementActions, userManagementActions} from "./userManagementActions";
import {getType} from "typesafe-actions";
import {OrganizationUserStatus} from "@sense-os/goalie-js";
import {LoadingStateReducer} from "../../helpers/loadingStateReducer";
import {LoadingState} from "constants/redux";

const initialState: UserManagementState = {
	organizationUsers: {},
	acceptedUsersId: [],
	pendingUsersId: [],
	deactivatedUsersId: [],

	loadingMap: {},

	isFetchingUserManagementList: LoadingState.EMPTY,
	isAcceptingPendingUser: {},
	isDeactivatingAcceptedUser: {},
	isReactivatingDeactivatedUser: {},
	isRejectingPendingUser: {},
};

const userManagementLoadingLogic = new LoadingStateReducer<UserManagementState>()
	.addLogic("isFetchingUserManagementList", userManagementActions.fetchUserManagementList)
	.addLogic("isAcceptingPendingUser", userManagementActions.acceptPendingUser, (action) => action.payload.hashId)
	.addLogic("isRejectingPendingUser", userManagementActions.rejectPendingUser, (action) => action.payload.hashId)
	.addLogic(
		"isDeactivatingAcceptedUser",
		userManagementActions.deactivateAcceptedUser,
		(action) => action.payload.hashId,
	)
	.addLogic(
		"isReactivatingDeactivatedUser",
		userManagementActions.reactivateDeactivatedUser,
		(action) => action.payload.hashId,
	);

export const userManagementReducer = produce((state: UserManagementState, action: UserManagementActions) => {
	userManagementLoadingLogic.apply(state, action);

	switch (action.type) {
		case getType(userManagementActions.fetchUserManagementList.success): {
			const {organizationUsers, acceptedUsersId, pendingUsersId, deactivatedUsersId} = action.payload;
			state.organizationUsers = organizationUsers;
			state.acceptedUsersId = acceptedUsersId;
			state.pendingUsersId = pendingUsersId;
			state.deactivatedUsersId = deactivatedUsersId;

			return;
		}

		case getType(userManagementActions.acceptPendingUser.success): {
			const {hashId} = action.payload;
			if (state.organizationUsers[hashId]) {
				state.organizationUsers[hashId].status = OrganizationUserStatus.ACCEPTED;
				moveIdBetweenListWithMutation(hashId, state.pendingUsersId, state.acceptedUsersId);
			}

			return;
		}

		case getType(userManagementActions.rejectPendingUser.success): {
			const {hashId} = action.payload;
			if (state.organizationUsers[hashId]) {
				delete state.organizationUsers[hashId];
				state.pendingUsersId = state.pendingUsersId.filter((id) => id !== hashId);
			}

			return;
		}

		case getType(userManagementActions.deactivateAcceptedUser.success): {
			const {hashId} = action.payload;
			if (state.organizationUsers[hashId]) {
				state.organizationUsers[hashId].status = OrganizationUserStatus.DEACTIVATED;
				moveIdBetweenListWithMutation(hashId, state.acceptedUsersId, state.deactivatedUsersId);
			}

			return;
		}

		case getType(userManagementActions.reactivateDeactivatedUser.success): {
			const {hashId} = action.payload;
			if (state.organizationUsers[hashId]) {
				state.organizationUsers[hashId].status = OrganizationUserStatus.ACCEPTED;
				moveIdBetweenListWithMutation(hashId, state.deactivatedUsersId, state.acceptedUsersId);
			}

			return;
		}

		case getType(userManagementActions.setDeactivateCandidate): {
			state.deactivateCandidate = action.payload.hashId;
			return;
		}
	}
}, initialState);

/**
 * A helper function that will move the given `movedId` from `originList` to `destinationList`.
 */
function moveIdBetweenListWithMutation(movedId: string, originList: string[], destinationList: string[]) {
	const originIdx = originList.findIndex((id) => id === movedId);
	if (originIdx !== -1) {
		originList.splice(originIdx, 1);
	}

	const destinationIdx = destinationList.findIndex((id) => id === movedId);
	if (destinationIdx === -1) {
		destinationList.push(movedId);
	}

	return;
}
