/**
 * Author: Fajar, Leo. Date: 27/06/2019
 */

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// lib imports
import autobind from "autobind-decorator";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// app imports
import {Injectable} from "../../IoC/Injectable";
import createLogger from "../../../logger/createLogger";

/**
 * This service executes tasks periodically.
 * At the moment the tasks and the execution delays are defined in the code of the service.
 */
export interface IScheduleService {
	/** Initialize the Scheduling service */
	init(): void;

	/**
	 * Clean everything up and stop all the scheduled tasks
	 */
	reset(): void;
}

/** @inheritDoc */
@autobind
export class ScheduleService implements IScheduleService, Injectable {
	public readonly c: string = "[ScheduleService]";

	private log = createLogger("ScheduleService");

	private refetchMailTimer: number = null;

	/** @inheritDoc */
	public init(): void {
		this.log.debug(this.c, "initializing...");
	}

	/** @inheritDoc */
	public reset(): void {
		this.log.debug(this.c, "resetting...");
		window.clearInterval(this.refetchMailTimer);
		this.refetchMailTimer = null;
	}
}
