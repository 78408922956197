import {takeLatest, put, fork, call, takeEvery, select} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {PostItem} from "@sense-os/goalie-js";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {Utils} from "utils/Utils";
import {toastActions} from "../../toaster/redux/toastAction";
import loc from "../../localization/Localization";
import NDBlogSDK from "../helpers/NDBlogSDK";
import {getFilteredSearchResult} from "../helpers/NDBlogSearchHelpers";
import {ndBlogSearchActions} from "../redux/NDBlogSearchActions";
import {MIN_CHAR_SEARCH} from "../NDBlogSearchTypes";
import strTranslation from "../../assets/lang/strings";
import analyticsService from "../../analytics/AnalyticsService";
import {AnalyticsEvent} from "../../analytics/AnalyticsEventTypes";
import {getSearchResultItems} from "../redux/NDBlogSearchSelector";

const log = createLogger("NDBlogSearchSaga", SentryTags.NDBlogSearch);

function* handleSearch(action: ActionType<typeof ndBlogSearchActions.searchPostItem.request>) {
	try {
		const searchKeywords: string = action.payload.keywords;
		const isKeywordHasDesiredValues: boolean =
			!Utils.stringHasNoValue(searchKeywords) && searchKeywords.length >= MIN_CHAR_SEARCH;
		let postItems: PostItem[] = [];

		// Call the SDK if `searchKeyword` has desired values
		if (isKeywordHasDesiredValues) {
			postItems = yield apiCallSaga(NDBlogSDK.getBlogPostByKeyword, searchKeywords);
			// Filter search result
			postItems = yield call(getFilteredSearchResult, postItems);
		} else {
			postItems = yield select(getSearchResultItems);
		}

		if (postItems.length > 0) {
			yield put(ndBlogSearchActions.searchPostItem.success({keywords: searchKeywords, postItems}));
		}
	} catch (err) {
		log.captureException(err);
		yield put(ndBlogSearchActions.searchPostItem.failure(err));
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.COMMON.toast.error.internal_server_error.cannot_load_data),
				type: "error",
			}),
		);
	}
}

function* handleSearchBySlug() {
	try {
		// TODO: Add search by slug action payload and sdk here.
		let postItems: PostItem[] = [];
		yield put(ndBlogSearchActions.searchPostBySlug.success({results: postItems}));
	} catch (err) {
		log.captureException(err);
		yield put(ndBlogSearchActions.searchPostBySlug.failure(err));
	}
}

function* handleOpenDialogAnalytics(action: ActionType<typeof ndBlogSearchActions.openDialog>) {
	analyticsService.trackEvent(AnalyticsEvent.OPEN_SEARCH_DIALOG, {
		isDialogOpen: true,
		searchItemOption: action.payload.searchOption,
	});
}

function* handleResetState() {
	yield put(ndBlogSearchActions.resetState());
}

function* NDBlogSearchSaga() {
	yield takeEvery(getType(ndBlogSearchActions.openDialog), handleOpenDialogAnalytics);
	yield takeEvery(getType(ndBlogSearchActions.closeDialog), handleResetState);
	yield takeLatest(getType(ndBlogSearchActions.searchPostItem.request), handleSearch);
	yield takeLatest(getType(ndBlogSearchActions.searchPostBySlug.request), handleSearchBySlug);
}

export default function* () {
	yield fork(NDBlogSearchSaga);
}
