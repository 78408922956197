import {ActionType, createAction, createAsyncAction} from "typesafe-actions";

export const clientManagementActions = {
	/** The actions that manage the state of disconnection dialog */
	openDisconnectDialog: createAction("clientManagementActions/openDisconnectDialog")(),
	closeDisconnectDialog: createAction("clientManagementActions/closeDisconnectDialog")(),

	/**
	 * The actual async actions that represent the actual disconnection process.
	 */
	disconnectMultipleClients: createAsyncAction(
		"clientManagementActions/disconnectMultipleClients",
		"clientManagementActions/disconnectMultipleClientsSuccess",
		"clientManagementActions/disconnectMultipleClientsFail",
	)<{clientIds: number[]}, {results: Array<{error?: Error; clientId: number; isSuccess: boolean}>}, void>(),

	/** The action that will trigger the opening of the disconnection dialog */
	askConfirmationOfClientsDisconnection: createAction(
		"clientManagementActions/askConfirmationOfClientsDisconnection",
		(clientIds: number[]) => ({
			clientIds,
		}),
	)(),

	getNetworkInfo: createAction("clientManagementActions/getNetworkInfo", (id: number) => ({id}))(),
	getSessionInfo: createAction("clientManagementActions/getSessionInfo", (id: number) => ({id}))(),
	getLastRegistrationInfo: createAction("clientManagementActions/getLastRegistrationInfo")(),

	doneFetching: createAction("clientManagementActions/doneFetching")(),
};

export type ClientManagementAction = ActionType<typeof clientManagementActions>;
