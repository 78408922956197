import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {customTrackerActions} from "../redux/customTrackerActions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import trackersSDK from "../helpers/trackersSDK";

const log = createLogger("manageTrackersSaga", SentryTags.Tracking);

function* handleSaveManageTrackers(action: ActionType<typeof customTrackerActions.saveManageTrackers.request>) {
	const {userId, isEnabled, trackerId} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		yield call(trackersSDK.updateTrackerStatus, token, {
			isEnabled,
			tracker: trackerId,
			patient: userId,
		});

		yield put(customTrackerActions.saveManageTrackers.success());
	} catch (err) {
		log.captureException(err);
		yield put(customTrackerActions.saveManageTrackers.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(customTrackerActions.saveManageTrackers.request), handleSaveManageTrackers);
}
