import {
	REFERRAL_QUESTIONNAIRE_STATUS,
	IReferralQuestionnaire,
	IReferralQuestionnaireDetailResponse,
	LanguageTag,
	IReferralTherapyType,
} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";

export interface IReferredClientStatusOption {
	label: string;
	value: REFERRAL_QUESTIONNAIRE_STATUS;
}

export interface ReferralClientState {
	referralClientList: {
		fetchingState: LoadingState;
		data: IReferralQuestionnaire[];
		total: number;
		statusCount: Record<REFERRAL_QUESTIONNAIRE_STATUS, number>;
	};
	referralClientDetail: {
		fetchingState: LoadingState;
		data: IReferralQuestionnaireDetailResponse;
		id: number;
	};
	formDialog: {
		isOpen: boolean;
		savingState: LoadingState;
	};
	// Deprecated
	reviewTemplate: {
		fetchingState: LoadingState;
		id: number;
	};
	questionnaireReview: {
		savingState: LoadingState;
	};
	statusUpdateDialog: {
		id: number;
		savingState: LoadingState;
		type: REFERRAL_QUESTIONNAIRE_STATUS;
		isOpen: boolean;
		notes: string;
	};
	referralNotes: {
		id: number;
		savingState: LoadingState;
		notes: string;
		isOpen: boolean;
	};
	processReferralDialog: {
		id: number;
		savingState: LoadingState;
		notes: string;
		isOpen: boolean;
	};
	therapyType: {
		fetchingState: LoadingState;
		data: IReferralTherapyType[];
	};
}

export interface IReferralClientForm {
	email: string;
	firstName: string;
	lastName: string;
	referralCode?: string;
	language?: LanguageTag;
	insurer?: string;
	expectedStartTime?: string;
}

export enum REFERRAL_PARAMS {
	PAGE = "p",
	PAGE_SIZE = "ps",
	STATUS = "s",
}

export enum REFERRAL_QUESTIONNAIRE_MENU {
	PROCESS = "process",
}

export type referralQuestionnaireMenuType = REFERRAL_QUESTIONNAIRE_STATUS | REFERRAL_QUESTIONNAIRE_MENU;
