import {combineReducers} from "redux";
import {TaskRegistrationState} from "../taskTypes";
import {sensorTaskReducer} from "./sensorTaskReducer";
import {taskDialogDetailReducer} from "./taskDetailDialogReducer";
import {relatedTaskReducer} from "./relatedTaskReducer";

export default combineReducers<TaskRegistrationState>({
	sensor: sensorTaskReducer,
	taskDetailDialog: taskDialogDetailReducer,
	relatedTask: relatedTaskReducer,
});
