import {call, takeEvery, put} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {AccessURLResponse} from "@sense-os/goalie-js";

import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {history} from "../../helpers/routerHistory";
import {Path} from "../../ts/app/Path";

import {calendarActions} from "../redux/calendarActions";
import {calendarSDK} from "../helpers/calendarSDK";

const log = createLogger("connectCalendarSaga", SentryTags.Calendar);

function* handleRequestAuthURL() {
	try {
		const token: string = yield call(getSessionId);
		const authURL: AccessURLResponse = yield apiCallSaga(calendarSDK.getAuthURL, token);

		if (authURL.url) {
			// Open cronofy auth url in current browser tab
			window.open(authURL.url, "_self");
		}
	} catch (err) {
		log.captureException(err);
	}
}

function* handleCreateAccessToken(action: ActionType<typeof calendarActions.createAccessToken>) {
	try {
		const token = yield call(getSessionId);
		const cronofyCode = action.payload.cronofyCode;

		// Create access token
		yield apiCallSaga(calendarSDK.createCronofyAccessToken, token, cronofyCode);

		// Redirect to calendar page
		history.push(Path.APP_CALENDARS);

		// Get calendar account
		yield put(calendarActions.getCalendarAccount.request());
	} catch (err) {
		log.captureException(err);

		// Redirect to dashboard when fails
		history.push(Path.APP);
	}
}

export default function* () {
	yield takeEvery(getType(calendarActions.connectCalendar), handleRequestAuthURL);
	yield takeEvery(getType(calendarActions.createAccessToken), handleCreateAccessToken);
}
