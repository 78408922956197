import {TaskPostBodyRequest, TrackerKey} from "@sense-os/goalie-js";
import {all, call, put} from "redux-saga/effects";
import {getTrackerIdBySensorName} from "../../tracker/utils/trackersSdkHelper";
import {PresetItem, PresetItemsType} from "../views/PresetsFormContext";
import {onAutoEnableTracker} from "../../tracker/customTracker/helpers/onAutoEnableTracker";
import {getSessionId} from "../../auth/helpers/authStorage";
import {getPresetTrackerTaskPayloads} from "../helpers/presetHelpers";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import taskSDK from "../../taskRegistration/helpers/taskSDK";
import {toastActions} from "../../toaster/redux";
import strTranslation from "../../assets/lang/strings";

interface SavePresetTrackerTaskParams {
	formValues: PresetItemsType;
	userId: number;
}

/**
 * Save Preset Tracker Task
 * 1. Enable All Enabled Trackers
 * 2. Bulk Tracker Task Creation
 */
export function* savePresetTrackerTask(params: SavePresetTrackerTaskParams) {
	const {formValues, userId} = params;

	const token: string = yield call(getSessionId);
	/**
	 * Based on this feedback:
	 *
	 * 	  All to-dos need to be created in the reverse order they appear in the presets UI.
	 *    This is so that to-dos with the same due date appear in the correct order (reverse chronological) in the intervention tab & the app.
	 *
	 * the order while creating the task is reversed
	 */
	const trackersFormValueKeys: string[] = Object.keys(formValues).reverse();

	const trackerIds: number[] = trackersFormValueKeys.reduce((result, formKey) => {
		const currentFormValue: PresetItem = formValues[formKey];
		const sensorName: string = currentFormValue.tracker.sensorName;
		const trackerId: number = getTrackerIdBySensorName(sensorName as TrackerKey);
		if (!!trackerId) {
			result.push(trackerId);
		}

		return result;
	}, []);

	if (trackerIds.length > 0) {
		/** 1. Enable All Enabled Trackers */
		const enablingTrackerRequests = trackerIds.map((trackerId) => {
			return call(onAutoEnableTracker, {
				userId,
				trackerId,
			});
		});

		yield all(enablingTrackerRequests);
	}

	/** 2. Bulk Tracker Task Creation */
	/** Filtering form values that has todo */
	const filteredTrackerTodo: PresetItemsType = trackersFormValueKeys.reduce((result, formKey) => {
		const currentFormValue: PresetItem = formValues[formKey];

		if (!!currentFormValue.todo) {
			result[formKey] = currentFormValue;
		}

		return result;
	}, {});

	if (Object.keys(filteredTrackerTodo).length > 0) {
		const payloads: TaskPostBodyRequest<any>[] = yield getPresetTrackerTaskPayloads({
			formValues: filteredTrackerTodo,
			userId,
		});

		const mappedCallPayloads = payloads.map((payload) => {
			return apiCallSaga(taskSDK.createTask, token, payload);
		});

		try {
			yield all(mappedCallPayloads);
		} catch (error) {
			yield put(toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.fail.toast, type: "error"}));
		}
	}
}
