import {AppState} from "../../ts/redux/AppState";
import {createSelector} from "reselect";
import {UserData} from "@sense-os/goalie-js";
import {UserUtils} from "utils/UserUtils";
import {LoadingState} from "../../ts/constants/redux";
import {ContactsMap} from "../../contacts/contactTypes";

const getUserProfileState = (state: AppState) => state.userProfile;
export const isSavingUserAvatar = createSelector(
	getUserProfileState,
	(state) => state.savingUserAvatar === LoadingState.LOADING,
);

//export const getUserProfiles = createSelector(getUserProfileState, state => state.userProfile.userProfileMap);

/**
 * Returns array of user profiles by userIds passed into the parameter.
 * Please be advised that there might be an empty user profile inside the array
 * because the userProfile is not found in the state.
 *
 * @param {number[]} userIds
 */
export const getUserProfilesByUserIds = (userIds: number[]) =>
	createSelector(
		(state: AppState) => state.contacts.contactsMap,
		(contactsMap: ContactsMap) => {
			return userIds.map((userId) => contactsMap[userId]);
		},
	);

/**
 * Returns user names by userIds from userProfile data in state.
 * If the userProfile data is not found, then the name will not be added.
 *
 * @param {number[]} userIds
 */
export const getNamesByUserIds = (userIds: number[], emptyNameSubstitute?: string) =>
	createSelector([getUserProfilesByUserIds(userIds)], (userProfiles: UserData[]) => {
		return (
			userProfiles
				.map((profile) => {
					return profile ? UserUtils.getNameFromUserProfile(profile) : emptyNameSubstitute;
				})
				// Filter out empty names
				.filter((name) => !!name)
				.join(", ") //FIXME: it should be " and " / " en " instead of ", " before the last participant's name
		);
	});

export const getJobSpecializations = createSelector(getUserProfileState, (state) => state.specializations);
export const isLoadingJobSpecializations = createSelector(
	getUserProfileState,
	(state) => state.specializationsLoadingState === LoadingState.LOADING,
);
