import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {SessionDataSource, SessionExpireStatus} from "../InterventionSessionType";

export const interventionSessionActions = {
	fetchSessions: createAsyncAction(
		"interventionSessionActions/fetchSessions",
		"interventionSessionActions/fetchSessionsSuccess",
		"interventionSessionActions/fetchSessionsFailed",
	)<
		{
			clientId: number;
		},
		{
			clientId: number;
			data: SessionDataSource[];
		},
		Error
	>(),
	setCurrentPage: createAction("interventionSessionActions/setCurrentPage", (page: number) => {
		return {page};
	})(),
	setSessionExpireStatus: createAction(
		"interventionSessionActions/setSessionExpireStatus",
		(expiredStatus: SessionExpireStatus) => {
			return {expiredStatus};
		},
	)(),
};

export type interventionSessionActionType = ActionType<typeof interventionSessionActions>;
