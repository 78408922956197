import produce from "immer";
import {XaiReduxState} from "../XaiTypes";
import {XaiAction, xaiActions} from "./xaiActions";
import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {LoadingStateReducer} from "../../helpers/loadingStateReducer";
import {XaiFeedbackStatus} from "@sense-os/goalie-js";

const initialState: XaiReduxState = {
	predictionsMap: {},
	fetchPredictionsLoadingState: LoadingState.EMPTY,
	sendFeedbackLoadingState: {},
};

const xaiLoadingState = new LoadingStateReducer<XaiReduxState>()
	.addLogic("fetchPredictionsLoadingState", xaiActions.getPredictions)
	.addLogic("sendFeedbackLoadingState", xaiActions.sendFeedback, (action) => action.payload.clientId);

export const xaiReducer = produce((state: XaiReduxState, action: XaiAction) => {
	xaiLoadingState.apply(state, action);

	switch (action.type) {
		case getType(xaiActions.getPredictions.success): {
			state.predictionsMap = action.payload.predictionsMap;
			return;
		}

		case getType(xaiActions.setClientIdForDialog): {
			state.clientIdForDialog = action.payload.clientId;
			return;
		}

		case getType(xaiActions.sendFeedback.success): {
			state.predictionsMap[action.payload.clientId].feedbackStatus = XaiFeedbackStatus.Sent;
			return;
		}
	}
}, initialState);
