import {TSMap} from "typescript-map";

/**
 * Faster implementation of TSMap.clone
 * Original: https://github.com/ClickSimply/typescript-map/blob/master/src/index.ts#L340
 */
export function cloneMap<K, V>(m?: TSMap<K, V>): TSMap<K, V> {
	const n = new TSMap<K, V>();
	if (m === undefined) {
		return n;
	}

	// @ts-ignore
	n._keys = m._keys.slice();
	// @ts-ignore
	n._values = m._values.slice();
	n.length = m.length;
	return n;
}
