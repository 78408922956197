import {isReplanningActivity, getPlanActivityEventId, getPlannedActivityData} from "../redux/planActivitySelector";
import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {CreatePlannedEventRequestBody} from "@sense-os/goalie-js/dist";
import {DISC} from "IoC/DISC";
import moment from "moment";
import {getSensorDataBySensorName} from "redux/tracking/TrackingHelper";
import {
	EventViewData,
	EventViewType,
	ExpectedFeeling,
	PlannedEventEntry,
	PlannedEventSensorEventView,
	RecurringPlannedEventView,
	SensorDatum,
	Sensors,
} from "redux/tracking/TrackingTypes";
import {ActionType, getType} from "typesafe-actions";
import {createTimezoneUnawareTimeString, createUTCDateString} from "utils/time";
import {UpdateEventOption} from "../../../eventDialog/views/UpdateEventOptionDialog";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {
	adjustRRuleEndTime,
	convertRRuleToUIState,
	convertUIStateToRRule,
	hasSameCustomRepetition,
} from "../../../customRepetition/helpers/RRuleHelpers";
import {saveExpectedFeelingSensor, transformFormValuesToPlannedEventSensor} from "../helpers/planActivityHelpers";
import PlanEventSDK from "../planEventSDK";
import {PlanActivityFormValues} from "../redux/planActivityTypes";
import {planActivityActions} from "../redux/planActivityActions";
import {toastActions} from "../../../toaster/redux";
import {RepeatedOption} from "../../../customRepetition/helpers/CustomRepetitionTypes";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import strTranslation from "../../../assets/lang/strings";

const log = createLogger("planActivityFormSaga", SentryTags.PlanActivity);

function* saveActivity(action: ActionType<typeof planActivityActions.saveActivity.request>) {
	const {userId, formValues} = action.payload;
	const isReplan: boolean = yield select(isReplanningActivity);
	const planActivityEventId: string = yield select(getPlanActivityEventId);
	const isEditing: boolean = !!planActivityEventId;

	try {
		if (!planActivityEventId || isReplan) {
			yield call(saveRecurringPlannedEvent, userId, formValues);
		} else if (isEditing) {
			const eventData: EventViewData = yield select((state) => getPlannedActivityData(state));

			if (eventData.type === EventViewType.RECURRING_PLANNED_EVENT) {
				yield call(saveRecurringPlannedEvent, userId, formValues, eventData);
			} else if (eventData.type === EventViewType.PLANNED_EVENT_SENSOR) {
				yield call(saveLegacyPlannedEvent, userId, formValues, eventData);
			}
		}
	} catch (error) {
		log.captureException(error);
		yield put(planActivityActions.saveActivity.failure(error));
		if (planActivityEventId) {
			yield put(
				toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.fail_update.toast, type: "error"}),
			);
		} else {
			yield put(toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.fail.toast, type: "error"}));
		}
	}
}

function* saveRecurringPlannedEvent(
	userId: number,
	formValues: PlanActivityFormValues,
	existingEventData?: RecurringPlannedEventView,
) {
	const token: string = yield call(getSessionId);
	const isUpdate: boolean = !!existingEventData;
	const isCanceled: boolean = !!existingEventData?.isCanceled;
	let repeatedOption = formValues.repeatedOption;
	let updateFollowingEvents = formValues.updateEventOption === UpdateEventOption.THIS_AND_FOLLOWING_EVENTS;

	// If the current event has only one occurrence and has no status (Neither completed nor canceled),
	// we must force to update following events so that backend will not reject the save request.
	if (isUpdate) {
		const {recurringExpression} = existingEventData.source.payload.plannedEvent;
		const {repeatedOption: exisitingRepetition} = convertRRuleToUIState(recurringExpression.rrule);

		// when non repated event changed to repeated event
		if (
			exisitingRepetition === RepeatedOption.NOT_REPEATED &&
			formValues.repeatedOption != RepeatedOption.NOT_REPEATED
		) {
			updateFollowingEvents = true;
		}

		const hasStatus: boolean = existingEventData?.isCanceled || existingEventData?.isCompleted;

		if (!updateFollowingEvents && !hasStatus) {
			repeatedOption = RepeatedOption.NOT_REPEATED;
		}

		// for updating the canceled event
		if (isCanceled) {
			repeatedOption = RepeatedOption.NOT_REPEATED;
		}
	}

	const rruleObj = convertUIStateToRRule(moment(formValues.plannedFor), repeatedOption, formValues.customRepetition);

	// We want to send the DTSTART without timezone (Timezone unaware date)
	let rrule: string = rruleObj.toString().split("Z").join("");

	let plannedEventId: string, occurrenceTime: Date, prevRRule: string;
	if (existingEventData) {
		const eventSource = (existingEventData as RecurringPlannedEventView).source;
		plannedEventId = eventSource.payload.plannedEvent.id;
		occurrenceTime = eventSource.occurrenceTime;
		prevRRule = eventSource.payload.plannedEvent.recurringExpression.rrule;
	}

	if (prevRRule && hasSameCustomRepetition(prevRRule, rrule)) {
		// Try to adjust the end time of `rrule` if rrule has custom repetition
		// so that the end time of previous `rrule` and the new `rrule` will always be
		// the same.
		rrule = adjustRRuleEndTime(rrule, prevRRule, occurrenceTime).split("Z").join("");
	}

	const body: CreatePlannedEventRequestBody = {
		activityType: formValues.activityType || undefined,
		title: formValues.title,
		description: formValues.description,
		reminderEnabled: formValues.shouldSendNotification,
		rrule,
		userId: userId,
	};

	if (isUpdate) {
		const occurrenceTimeStr = createTimezoneUnawareTimeString(new Date(createUTCDateString(occurrenceTime)));
		yield apiCallSaga(
			PlanEventSDK.plannedEventIdOccurrenceOccurrenceTimePost,
			token,
			plannedEventId,
			occurrenceTimeStr,
			updateFollowingEvents,
			body,
		);
	} else {
		yield apiCallSaga(PlanEventSDK.plannedEventPost, token, body);
	}

	yield put(planActivityActions.saveActivity.success({userId}));
	if (existingEventData) {
		yield put(
			toastActions.addToast({
				message: strTranslation.GRAPHS.new_event.plan.success_update.toast,
				type: "success",
			}),
		);
	} else {
		yield put(
			toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.success.toast, type: "success"}),
		);
	}
}

function* saveLegacyPlannedEvent(
	userId: number,
	formValues: PlanActivityFormValues,
	existingEventData: PlannedEventSensorEventView,
) {
	const plannedEventData = (existingEventData && existingEventData.source) as SensorDatum<PlannedEventEntry>;
	const plannedEventId: string = plannedEventData && plannedEventData.id;
	let expectedFeelingUri: string = null;

	// Try to get expected feeling data from plannedEventData
	const previousExpectedFeelingSensor: SensorDatum<ExpectedFeeling> =
		plannedEventData && getSensorDataBySensorName(plannedEventData, Sensors.EXPECTED_FEELING);
	if (previousExpectedFeelingSensor) {
		// Create or Update expected feeling and retrieve its `uri` for planned event data
		const expectedFeeling = yield apiCallSaga(
			saveExpectedFeelingSensor,
			userId,
			formValues,
			previousExpectedFeelingSensor.id,
		);
		expectedFeelingUri = expectedFeeling.uri;
	}

	// Try to save plan event with expected feeling `uri` (if exist)
	const transformedPlannedEventValue = yield apiCallSaga(
		transformFormValuesToPlannedEventSensor,
		formValues,
		plannedEventId,
		expectedFeelingUri,
	);

	yield apiCallSaga(DISC.getTrackingService().saveSensorData, transformedPlannedEventValue, userId, plannedEventId);

	yield put(toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.success.toast, type: "success"}));
}

export default function* planActivitySaga() {
	yield takeEvery(getType(planActivityActions.saveActivity.request), saveActivity);
}
