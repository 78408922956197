// Agora window instance
let inst: Window = null;

/**
 * Creates Agora window instance if there isn't any
 */
export function createAgoraWindow(): Window {
	if (inst) {
		return inst;
	}
	inst = window.open("/meeting-ag.html");
	return inst;
}

export function getAgoraWindow(): Window | null {
	return inst;
}

export function resetAgoraWindow(): void {
	inst = null;
}
