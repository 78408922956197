import {getType} from "typesafe-actions";

import {WarningBarState} from "./warningBarTypes";
import {WarningBarActionTypes, warningBarActions} from "./warningBarAction";

const initialState: WarningBarState = {
	warningBars: [],
};

export function warningBarReducer(
	state: WarningBarState = initialState,
	action: WarningBarActionTypes,
): WarningBarState {
	switch (action.type) {
		/**
		 * Put warning bar item to the list if the warningBar key is not exists in the list.
		 */
		case getType(warningBarActions.addWarningBar): {
			if (state.warningBars.find((item) => item.key === action.payload.key)) {
				return state;
			}
			return {...state, warningBars: [...state.warningBars, action.payload]};
		}
		/**
		 * Receives warningBar key as payload and remove that warningBar
		 */
		case getType(warningBarActions.removeWarningBar): {
			return {...state, warningBars: state.warningBars.filter((warningBar) => warningBar.key !== action.payload)};
		}

		default:
			return state;
	}
}
