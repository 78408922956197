import {LastInteractionTime, UnauthorizedError, InternalServerError} from "@sense-os/goalie-js";
import {call, select, put, takeEvery, delay} from "redux-saga/effects";
import {getOwnId} from "../../auth/redux";
import {getChatRoomState} from "../redux/ChatSelector";
import {ChatRoomState} from "../redux/ChatRoomReducer";
import {NumberMap} from "services/utils/Maps";
import {ChatRoomAction} from "../redux/ChatRoomAction";
import {ChatAction} from "../redux/ChatAction";
import {ActionType} from "typesafe-actions";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import chatSDK from "../sdk";
import {isLoggable} from "../../ts/utils/muteLog/loggable";

const log = createLogger("chatHistoryFromBE", SentryTags.Chat);

/**
 * Load lastInteractionTimes with retries if it failss
 *
 * @param maxRetry
 * @param delayBetweenRetries
 */
function* loadInteractionTimesWithRetry(maxRetry: number = 5, delayBetweenRetries: number = 1000) {
	let interactionTimes = [];
	let retries = 0;

	while (retries < maxRetry) {
		try {
			interactionTimes = yield call(chatSDK.getLastInteractionTimes);
			isLoggable.lastInteractionTimes && log.debug("last interaction times", interactionTimes);
			break;
		} catch (error) {
			if (error instanceof UnauthorizedError) {
				break;
			} else if (error instanceof InternalServerError) {
				throw error;
			}
			log.debug("Retrying");

			yield delay(delayBetweenRetries);
			retries += 1;
		}
	}

	log.debug({interactionTimes});

	return interactionTimes;
}

export function* loadChatHistoryFromBE(action: ActionType<typeof ChatAction.bulkLoadChatHistoriesFromBE>) {
	const {userIds} = action.payload;
	let lastInteractionTimes: LastInteractionTime[];
	try {
		log.debug("load interaction times from BE");
		lastInteractionTimes = yield call(loadInteractionTimesWithRetry);
		log.addBreadcrumb({
			data: {lastInteractionTimes: lastInteractionTimes.length},
			message: "last interaction times from BE loaded",
		});
	} catch (e) {
		lastInteractionTimes = [];
		log.captureException(e, {message: "Failed to load last interaction times"});
	}

	const ownId = yield select(getOwnId);
	const chatRoomState = yield select(getChatRoomState);
	const partialRoomStateMap: NumberMap<Partial<ChatRoomState>> = {};

	// Here we want to map each last interaction times into `chatRoom` (ChatRoomstate)
	lastInteractionTimes.forEach((lastInteraction) => {
		const isOutgoing = lastInteraction.fromUser === ownId;
		const chatRoomUserId = isOutgoing ? lastInteraction.toUser : lastInteraction.fromUser;

		// Backend will return all LatestInteractionTime between users, no matter that these users are still connected or not.
		// Therefore to avoid unnecessary operation, we'll need to filter out users that are not exist in the `userIds` payload
		const userIdExist = userIds.some((userId) => userId === chatRoomUserId);
		if (!userIdExist) {
			return;
		}

		partialRoomStateMap[chatRoomUserId] = {
			...chatRoomState[chatRoomUserId],
			...partialRoomStateMap[chatRoomUserId],
		};
		if (isOutgoing) {
			if (lastInteraction.lastReadId) {
				// Time of when local user read the message.
				// This timestamp will be used to count UNREAD message.
				partialRoomStateMap[chatRoomUserId].sentReadMarkerTimestampMs =
					parseInt(lastInteraction.lastReadId, 10) / 1000;
			}

			if (lastInteraction.lastSentId) {
				// Time of when message has been DELIVERED to counterpart
				partialRoomStateMap[chatRoomUserId].deliveredTimestampMs =
					parseInt(lastInteraction.lastSentId, 10) / 1000;
			}
		} else {
			if (lastInteraction.lastSentId) {
				// Time of the latest message was sent by counterpart
				// This timestamp will be used to determine whether the chatRoom
				// already have the latest messages or not, so that portal can fetch the messages
				// for the chat room.
				partialRoomStateMap[chatRoomUserId].lastReceivedTimestampMs =
					parseInt(lastInteraction.lastSentId, 10) / 1000;
			}

			if (lastInteraction.lastReadId) {
				// Time of when the message has been READ by counterpart
				partialRoomStateMap[chatRoomUserId].readTimestampMs = parseInt(lastInteraction.lastReadId, 10) / 1000;
			}
		}
	});

	// Store data from BE to redux
	yield put(ChatRoomAction.bulkSetChatRoomState(partialRoomStateMap));
	yield put(ChatAction.lastInteractionTimesLoaded());

	const remoteIds = Object.keys(partialRoomStateMap)
		.map((s) => parseInt(s, 10))
		.filter((id) => id !== ownId);

	const userWithUnreadMessages = remoteIds.filter((id) => {
		const counterpartHasSentMessage = !!partialRoomStateMap[id].lastReceivedTimestampMs;
		let timestamp = partialRoomStateMap[id].lastReceivedTimestampMs;

		if (!!timestamp && timestamp < 1e13) {
			timestamp = timestamp * 1000 + 999;
		}

		const hasUnreadMessage =
			counterpartHasSentMessage && timestamp > (partialRoomStateMap[id].sentReadMarkerTimestampMs || 0);

		// Only loads chat if chat room has an unread message
		return hasUnreadMessage;
	});

	log.debug({userWithUnreadMessages});
	log.addBreadcrumb({message: "queue to load chat", data: {userWithIncompleteData: userWithUnreadMessages.length}});

	if (userWithUnreadMessages.length >= 100) {
		log.captureMessage("More than 100 user with unread messages!", {
			userWithIncompleteData: userWithUnreadMessages.length,
		});
	}

	// Fetch from ejabberd for user with incomplete information on BE
	yield put(ChatAction.queueToLoadChat(userWithUnreadMessages));
}

export default function* () {
	yield takeEvery(ChatAction.bulkLoadChatHistoriesFromBE, loadChatHistoryFromBE);
}
