import {UserProfileState} from "../UserProfileTypes";
import {UserProfileAction} from "./UserProfileAction";
import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";

const initialState: UserProfileState = {
	savingUserAvatar: LoadingState.EMPTY,
	specializations: [],
	specializationsLoadingState: LoadingState.EMPTY,
};

export default function userProfileReducer(
	state: UserProfileState = initialState,
	action: UserProfileAction.UserProfileActionType,
): UserProfileState {
	switch (action.type) {
		case getType(UserProfileAction.saveUserAvatar.request):
			return {
				...state,
				savingUserAvatar: LoadingState.LOADING,
			};
		case getType(UserProfileAction.saveUserAvatar.success):
			return {
				...state,
				savingUserAvatar: LoadingState.LOADED,
			};
		case getType(UserProfileAction.saveUserAvatar.failure):
			return {
				...state,
				savingUserAvatar: LoadingState.ERROR,
			};

		case getType(UserProfileAction.loadJobSpecializations.request):
			return {
				...state,
				specializationsLoadingState: LoadingState.LOADING,
			};
		case getType(UserProfileAction.loadJobSpecializations.success):
			return {
				...state,
				specializationsLoadingState: LoadingState.LOADED,
				specializations: action.payload,
			};
		case getType(UserProfileAction.loadJobSpecializations.failure):
			return {
				...state,
				specializationsLoadingState: LoadingState.ERROR,
			};

		default:
			return state;
	}
}
