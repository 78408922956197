import {authActions, AuthActionType} from "./redux";
import {getType} from "typesafe-actions";
import {reset} from "@redux-beacon/segment";
import analyticsService from "../analytics/AnalyticsService";

const trackedActions = [
	authActions.logout.request,
	authActions.login.request,
	authActions.login.success,
	authActions.login.failure,
	authActions.login2FACode.request,
	authActions.login2FACode.success,
	authActions.login2FACode.failure,
	authActions.login2FARecoveryCode.request,
	authActions.login2FARecoveryCode.success,
	authActions.login2FARecoveryCode.failure,
	authActions.register.request,
	authActions.register.failure,
	authActions.register.success,
	authActions.resetPassword.request,
	authActions.resetPassword.failure,
	authActions.resetPassword.success,
	authActions.changePassword.request,
	authActions.changePassword.failure,
	authActions.changePassword.success,
].map(getType);

export const authEvents = analyticsService.trackReduxActions(trackedActions, (action: AuthActionType) => {
	// logout
	if (action.type === getType(authActions.logout.request)) {
		reset();
		return {
			isSessionExpired: action.payload.isSessionExpired ? true : false,
		};
	}

	// register
	if (action.type === getType(authActions.register.success)) {
		analytics.identify(action.payload.response.externalId);
	}
	if (action.type === getType(authActions.register.failure)) {
		return {
			message: action.payload.error.message,
		};
	}

	// login
	if (action.type === getType(authActions.login.success)) {
		analytics.identify(action.payload.user.externalId);
	}
	if (action.type === getType(authActions.login.failure)) {
		return {
			message: action.payload.error.message,
		};
	}

	// login via 2FA
	if (action.type === getType(authActions.login2FACode.success)) {
		analytics.identify(action.payload.user.externalId);
	}
	if (action.type === getType(authActions.login2FACode.failure)) {
		return {
			message: action.payload.error.message,
		};
	}

	// login via 2FA Recovery Code
	if (action.type === getType(authActions.login2FARecoveryCode.success)) {
		analytics.identify(action.payload.user.externalId);
	}
	if (action.type === getType(authActions.login2FARecoveryCode.failure)) {
		return {
			message: action.payload.error.message,
		};
	}

	// reset password / forgot password
	if (action.type === getType(authActions.resetPassword.failure)) {
		return {
			message: action.payload.error.message,
		};
	}

	// change password
	if (action.type === getType(authActions.changePassword.failure)) {
		return {
			message: action.payload.error.message,
		};
	}
	return null;
});
