import {LoadingState} from "constants/redux";
import {getType} from "typesafe-actions";
import {InterventionSessionState, SessionExpireStatus} from "../InterventionSessionType";
import {interventionSessionActions, interventionSessionActionType} from "./interventionSessionAction";

const initialState: InterventionSessionState = {
	/** Fetching State of Intervention Session List */
	sessionListFetchingState: LoadingState.EMPTY,
	/** Session List */
	data: [],
	/** init current page */
	currentPage: 1,
	/** init selected session expire status */
	selectedSessionExpireStatus: SessionExpireStatus.UPCOMING,

	clientSessions: {},
};

export function interventionSessionReducer(
	state: InterventionSessionState = initialState,
	action: interventionSessionActionType,
): InterventionSessionState {
	switch (action.type) {
		case getType(interventionSessionActions.fetchSessions.request): {
			return {
				...state,
				sessionListFetchingState: LoadingState.LOADING,
				// set to empty array each the fetch session is started
				data: [],
			};
		}
		case getType(interventionSessionActions.fetchSessions.success): {
			const {clientId, data} = action.payload;

			return {
				...state,
				sessionListFetchingState: LoadingState.LOADED,
				data,
				clientSessions: {
					...state.clientSessions,
					[clientId]: data,
				},
			};
		}
		case getType(interventionSessionActions.fetchSessions.failure): {
			return {
				...state,
				sessionListFetchingState: LoadingState.ERROR,
				error: action.payload,
			};
		}
		case getType(interventionSessionActions.setCurrentPage): {
			const {page} = action.payload;

			return {
				...state,
				currentPage: page,
			};
		}
		case getType(interventionSessionActions.setSessionExpireStatus): {
			const {expiredStatus} = action.payload;

			return {
				...state,
				selectedSessionExpireStatus: expiredStatus,
				// to ensure each time the expire status changed set the current page to the 1st page
				currentPage: 1,
			};
		}
		default:
			return state;
	}
}
