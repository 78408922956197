import {OutputSelector} from "reselect";
import {AppState} from "./AppState";

/**
 * As a context, this function is created in response to the issue, where, defining a selector like this
 * ```userChatRoomState = (userId: number) => createSelector([getChatRoomState], state => state[userId] || emptyRoom)```
 * means that a new selector is created everytime ```userChatRoomState``` is called, and thus we didn't gain
 * any benefit in using createSelector.
 *
 * The correct way, would be to store/cache a different selector for each user id.
 * The following function serve the purpose on creating a cache per userId for any given selector.
 */
export const cacheCreateSelectorPerUserId = <T, F, S = AppState>(
	fn: (userId: number) => OutputSelector<S, T, F> | ((state: S) => T),
) => {
	const cache: {[userId: number]: OutputSelector<S, T, F> | ((state: S) => T)} = {};
	return (userId: number) =>
		(state: S): T => {
			if (!cache[userId]) {
				cache[userId] = fn(userId);
			}

			return cache[userId](state);
		};
};

export const cacheCreateSelectorPerKey = <T, F>(
	fn: (userId: string) => OutputSelector<AppState, T, F> | ((state: AppState) => T),
) => {
	const cache: {[userId: string]: OutputSelector<AppState, T, F> | ((state: AppState) => T)} = {};
	return (userId: string) =>
		(state: AppState): T => {
			if (!cache[userId]) {
				cache[userId] = fn(userId);
			}

			return cache[userId](state);
		};
};
