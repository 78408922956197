import {Dialog, DialogProps} from "@material-ui/core";
import {useSelector} from "react-redux";
import {getDialogState} from "./redux/dialogSelector";
import {dialogActionsWithDispatch} from "./redux/dialogAction";
import {emptyDialogMapState} from "./dialogTypes";
import {makeComponentWithPropsTranslation} from "../../helpers/hoc";
import {useEffect} from "react";

export const createDialog = (key: string) => {
	const CreatedDialog = makeComponentWithPropsTranslation(Dialog, (props: Omit<DialogProps, "open">) => {
		const dialogState = useSelector(getDialogState(key)) || emptyDialogMapState;

		// Close dialog when navigates away.
		useEffect(() => {
			return () => {
				dialogActionsWithDispatch.closeDialog(key);
			};
		}, []);

		return {...props, open: dialogState.isOpen};
	});

	const actions = {
		openDialog: () => dialogActionsWithDispatch.openDialog(key),
		closeDialog: () => dialogActionsWithDispatch.closeDialog(key),
		toggleDialog: () => dialogActionsWithDispatch.toggleDialog(key),
	};

	const useDialogActions = () => {
		return actions;
	};

	return {Dialog: CreatedDialog, useDialogActions};
};
