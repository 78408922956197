import {getType} from "typesafe-actions";

import {calendarActions, calendarActionType} from "./calendarActions";
import analyticsService from "../../analytics/AnalyticsService";

const trackedActions = [
	calendarActions.connectCalendar,
	calendarActions.getExternalEventAccess,

	calendarActions.revokeCalendarProfile.request,
	calendarActions.revokeCalendarProfile.success,
	calendarActions.revokeCalendarProfile.failure,

	// Calendar internal event actions
	calendarActions.createInternalEvent.request,
	calendarActions.createInternalEvent.success,
	calendarActions.createInternalEvent.failure,
	calendarActions.updateInternalEvent.request,
	calendarActions.updateInternalEvent.success,
	calendarActions.updateInternalEvent.failure,
	calendarActions.deleteInternalEvent.request,
	calendarActions.deleteInternalEvent.success,
	calendarActions.deleteInternalEvent.failure,

	// Calendar external event actions
	calendarActions.updateExternalEvent.request,
	calendarActions.updateExternalEvent.success,
	calendarActions.updateExternalEvent.failure,
	calendarActions.deleteExternalEvent.request,
	calendarActions.deleteExternalEvent.success,
	calendarActions.deleteExternalEvent.failure,

	// Calendar session event actions
	calendarActions.saveSessionEvent.request,
	calendarActions.saveSessionEvent.success,
	calendarActions.saveSessionEvent.failure,
	calendarActions.deleteSessionEvent.request,
	calendarActions.deleteSessionEvent.success,
	calendarActions.deleteSessionEvent.failure,
].map(getType);

const properties = (action: calendarActionType) => {
	switch (action.type) {
		case getType(calendarActions.createInternalEvent.request):
		case getType(calendarActions.updateInternalEvent.request):
		case getType(calendarActions.deleteInternalEvent.request):
		case getType(calendarActions.updateExternalEvent.request):
		case getType(calendarActions.deleteExternalEvent.request):
		case getType(calendarActions.saveSessionEvent.request):
		case getType(calendarActions.deleteSessionEvent.request):
			return action.payload;
		default:
			return null;
	}
};

export const calendarEvents = analyticsService.trackReduxActions(trackedActions, properties);
