import {PostItem, PostType} from "@sense-os/goalie-js";
import createLogger from "../../logger/createLogger";
import loc from "../../localization/Localization";
import {UserPlatform} from "../NDBlogSearchTypes";
import {ContactTabPath, Path} from "app/Path";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("NDBlogSearch");

/** Filter search result */
export function getFilteredSearchResult(items: PostItem[]): PostItem[] {
	if (!items) {
		return null;
	}
	// Only show "blog post" and "library"
	return items.filter((item) => item.subtype === PostType.POST || item.subtype === PostType.LIBRARY);
}

/** Map the translation of post types name into `PostType` */
const postTypeNameMap = {
	[PostType.LIBRARY]: loc.formatMessage(strTranslation.BLOG_SEARCH.search_item.post_type.library),
	[PostType.PAGE]: loc.formatMessage(strTranslation.BLOG_SEARCH.search_item.post_type.page),
	[PostType.PEOPLE]: loc.formatMessage(strTranslation.BLOG_SEARCH.search_item.post_type.people),
	[PostType.POST]: loc.formatMessage(strTranslation.BLOG_SEARCH.search_item.post_type.blog),
};

/** Get post type name then translate it */
export function getPostTypeName(postType: PostType): string | undefined {
	return postTypeNameMap[postType];
}

/**  Copy given text to clipboard */
export function copyToClipboard(text: string): void {
	navigator.clipboard.writeText(text).then(() => {
		log.info("text copied!");
	});
}

/** Convert string with unicode decimal code into regular string */
export function decodeHtmlCharCodes(str: string): string {
	return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
		return String.fromCharCode(charCode);
	});
}

/** Get local user platform */
export function getUserPlatform(): UserPlatform {
	const platform: string = window.navigator.platform.toLowerCase();

	if (platform.includes(UserPlatform.MAC)) {
		return UserPlatform.MAC;
	}

	return UserPlatform.OTHER;
}

/** Check is given path is allowed to show "paste link" action */
export function showPasteActionInCurrentPath(pathname: string): boolean {
	// Currently chat box only appear in client's detail page or other professional profile page
	return pathname.includes(Path.APP_CLIENT) || pathname.includes(ContactTabPath.PROFILE);
}

/** Get index from `itemIds` based on `selectedItem` (used for `KeyBindings.TAB` or `KeyBindings.DOWN` keys only) */
export function getItemIndex(selectedItem?: PostItem, itemIds?: number[]): number {
	if (!selectedItem) {
		return 0;
	}
	return itemIds.indexOf(selectedItem.id) + 1;
}
