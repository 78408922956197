import {ManageTrackersState} from "../customTrackerTypes";
import {CustomTrackerActionType, customTrackerActions} from "./customTrackerActions";
import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";

const manageTrackersState: ManageTrackersState = {
	isManageTrackersDialogOpened: false,
	savingState: LoadingState.EMPTY,
};

export default function manageTrackersReducer(
	state: ManageTrackersState = manageTrackersState,
	action: CustomTrackerActionType,
): ManageTrackersState {
	switch (action.type) {
		case getType(customTrackerActions.openManageTrackersDialog):
			return {
				...state,
				isManageTrackersDialogOpened: true,
			};
		case getType(customTrackerActions.closeManageTrackersDialog):
			return {
				...state,
				isManageTrackersDialogOpened: false,
				savingState: LoadingState.EMPTY,
			};
		case getType(customTrackerActions.saveManageTrackers.request):
			return {
				...state,
				savingState: LoadingState.LOADING,
			};
		case getType(customTrackerActions.saveManageTrackers.success):
			return {
				...state,
				savingState: LoadingState.LOADED,
			};
		case getType(customTrackerActions.saveManageTrackers.failure):
			return {
				...state,
				savingState: LoadingState.ERROR,
			};
		default:
			return state;
	}
}
