import {ActionType, getType} from "typesafe-actions";
import {takeEvery} from "redux-saga/effects";
import {taskRegistrationActions} from "../redux/taskRegistrationActions";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {call, put} from "redux-saga/effects";
import {TaskPostBodyRequest, TaskPutBodyRequest} from "@sense-os/goalie-js";
import {getSessionId} from "../../auth/helpers/authStorage";
import {getTaskReminderDateTime} from "../helpers/common";
import moment from "moment";
import {toastActions} from "../../toaster/redux";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import taskSDK from "../helpers/taskSDK";
import {TaskFormActionType} from "../taskTypes";
import {onAutoEnableTracker} from "../../tracker/customTracker/helpers/onAutoEnableTracker";

const log = createLogger("saveTaskSaga", SentryTags.Task);

export function* saveTask(action: ActionType<typeof taskRegistrationActions.saveTask.request>) {
	const {formValues, patient, taskId, formAction, tracker} = action.payload;

	const token: string = yield call(getSessionId);

	try {
		const trackerId: number = tracker?.id;
		const sensorName: string = tracker?.sensorName;

		// Set `remindedDateTime` to `null` if `reminderToggle` is not enabled
		const reminderDateTime: Date = yield call(
			getTaskReminderDateTime,
			formValues.reminderSwitchButton && formValues,
		);
		const isCreating = formAction === TaskFormActionType.CREATING;
		const isEditing = formAction === TaskFormActionType.EDITING;
		const isPlanAgain = formAction === TaskFormActionType.PLAN_AGAIN;

		// Show warning toast when `reminderDateTime` is before creation date
		if (moment(reminderDateTime).isBefore()) {
			yield put(
				toastActions.addToast({
					message: localization.formatMessage(strTranslation.VALIDATION.reminder_must_be_in_future),
					type: "warning",
				}),
			);
			yield put(taskRegistrationActions.saveTask.failure(new Error()));
			return;
		}

		if (isCreating || isPlanAgain) {
			const body: TaskPostBodyRequest<any> = {
				title: formValues.title,
				patient,
				tracker: trackerId,
				// only send the `expiresAt` if the switch button is enabled
				expiresAt: formValues.expiresAtSwitchButton ? new Date(formValues.expiresAt) : undefined,
				// only send the `remindAt` if the switch button is enabled
				remindAt: formValues.reminderSwitchButton ? reminderDateTime : undefined,
				// only send the `recurrence` if the switch button is enabled
				recurrence: formValues.reminderRepeatOptionSwitchButton ? formValues.reminderRepeatOption : undefined,
			};

			yield apiCallSaga(taskSDK.createTask, token, body);
		}

		if (isEditing) {
			const body: TaskPutBodyRequest<any> = {
				title: formValues.title,
				// only send the `expiresAt` if the switch button is enabled
				expiresAt: formValues.expiresAtSwitchButton ? new Date(formValues.expiresAt) : null,
				// only send the `remindAt` if the switch button is enabled
				remindAt: formValues.reminderSwitchButton ? reminderDateTime : null,
				// only send the `recurrence` if the switch button is enabled
				recurrence: formValues.reminderRepeatOptionSwitchButton ? formValues.reminderRepeatOption : "",
			};

			yield apiCallSaga(taskSDK.updateTask, token, taskId, body);
		}
		// reset the saved form value for the selected `userId`
		yield put(
			taskRegistrationActions.resetFormValues({
				userId: patient,
				sensorName,
			}),
		);
		yield put(taskRegistrationActions.saveTask.success());
		// success toast
		const selectedToastMessage = isEditing
			? localization.formatMessage(strTranslation.GRAPHS.new_event.plan.success_update.toast)
			: localization.formatMessage(strTranslation.GRAPHS.new_event.plan.success.toast);
		yield put(
			toastActions.addToast({
				message: selectedToastMessage,
				type: "success",
			}),
		);

		/**
		 * auto enable tracker if the tracker is inactive
		 * add optional chaining since custom to-do is a task without tracker
		 */
		if (!tracker?.isEnabled) {
			yield call(onAutoEnableTracker, {
				userId: patient,
				trackerId: trackerId,
			});
		}

		// form modal is closed
		yield put(
			taskRegistrationActions.setModalFormTask({
				tracker: null,
				open: false,
				formAction: null,
			}),
		);
		// reset the task saving state to prevent double fetching
		// https://github.com/senseobservationsystems/web-getgoalie/pull/6116#issuecomment-1723065006
		yield put(taskRegistrationActions.resetSavingState());
	} catch (error) {
		log.captureException(error);
		yield put(taskRegistrationActions.saveTask.failure(error));
		yield put(toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.fail.toast, type: "error"}));
	}
}

export default function* () {
	yield takeEvery(getType(taskRegistrationActions.saveTask.request), saveTask);
}
