import moment from "moment";
import {call, put, takeEvery} from "redux-saga/effects";
import {getSessionId} from "../../auth/helpers/authStorage";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {timeTrackingActions} from "../redux/timeTrackingActions";
import timeTrackingSdk from "../timeTrackingSdk";

const log = createLogger("TimeTracking", SentryTags.TherapistTimeTracking);

/**
 * Fetch unconfirmed entries from previous days (Excluding today)
 */
function* fetchUnconfirmedEntries() {
	const token = yield call(getSessionId);
	// Fetch from previous day backwards
	const endTime = moment().subtract(1, "day").endOf("day").toDate();
	try {
		const unconfirmedEntries = yield apiCallSaga(timeTrackingSdk.getTimeEntries, token, {
			confirmed: false,
			endTime,
		});

		// Completely replace the existing unconfirmed entries data in redux state
		yield put(timeTrackingActions.fetchUnconfirmedEntries.success({entries: unconfirmedEntries}));
	} catch (err) {
		log.captureException(err, {message: "Unable to fetch unconfirmed entries"});
	}
}

export default function* unconfirmedEntriesSaga() {
	yield takeEvery(timeTrackingActions.fetchUnconfirmedEntries.request, fetchUnconfirmedEntries);
}
