import {PsychoEducationTaskRegistration, TaskItemResponse, TaskStatus} from "@sense-os/goalie-js";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const interventionPsychoEducationActions = {
	fetchPsychoEducations: createAsyncAction(
		"interventionPsychoEducation/fetchPsychoEducations",
		"interventionPsychoEducation/fetchPsychoEducationsSuccess",
		"interventionPsychoEducation/fetchPsychoEducationsFail",
	)<
		{
			hashId: string;
			page?: number;
			pageSize?: number;
			status?: TaskStatus;
		},
		{data: TaskItemResponse<PsychoEducationTaskRegistration>[]; totalData: number},
		{err: Error}
	>(),
};

export type interventionPsychoEducationActionType = ActionType<typeof interventionPsychoEducationActions>;
