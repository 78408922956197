import {Call, CallContact, CallSDK, CallType, TwilioRoom} from "@sense-os/goalie-js";
import {call, put, race, select, takeEvery, take} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import createLogger from "../../logger/createLogger";
import {toastActions} from "../../toaster/redux";
import {getFullName} from "../../userProfile/helpers/profileHelpers";
import {getConditionalEmdrSettings} from "../../userSettings/redux/userSettingsSelectors";
import featureFlags from "../../featureFlags/FeatureFlags";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import strTranslation from "../../assets/lang/strings";

import chatSDK from "../../chat/sdk";
import twilioSDK from "../../twilio/twilioSDK";
import {callActions} from "../redux/callActions";
import {canStartCall, getCallContactInfoByUserId} from "./callSagaHelpers";
import {MediaRegion, OutgoingCallTypes} from "../callTypes";
import {getCallInfo} from "../../ts/redux/videoCall/VideoCallSelectors";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {CallDirection} from "services/chat/video/CallDirection";
import {getTokenValidUntil} from "../helpers/callUtils";

const logger = createLogger("startCallSaga");

/**
 * Handler to start a new call
 *
 * @param action
 */
export function* handleOpenCallWindow(action: ActionType<typeof callActions.startCall>) {
	const {userId, isConferenceCall, isVideo} = action.payload;
	const authUser: AuthUser = yield select(getAuthUser);
	const callType = isVideo ? CallType.Video : CallType.Audio;

	const senderInfo: CallContact = yield call(getCallContactInfoByUserId, authUser.id);
	const recipientInfo: CallContact = yield call(getCallContactInfoByUserId, userId);

	// Check whether user is able to start a new call
	const ableToStartCall = yield call(canStartCall);
	if (!ableToStartCall) {
		yield put(
			toastActions.addToast({
				message: strTranslation.CHAT.video.already_in_call.toast,
				type: "warning",
			}),
		);
		return;
	}

	yield put(callActions.setCallInfo({userId, callType, senderInfo, recipientInfo, isConferenceCall}));

	try {
		yield put(callActions.openCallWindow.request());

		const result = yield race({
			success: take(getType(callActions.openCallWindow.success)),
			failure: take(getType(callActions.openCallWindow.failure)),
		});

		if (result.failure) {
			logger.info("Call window not opened");
			// When call window is not opened, then do nothing here.
			return;
		}

		logger.info("Call window opened");
	} catch (err) {
		logger.captureException(err, {isConferenceCall, isVideo});
		yield put(callActions.closeCallWindow());
		yield put(callActions.resetCallInfo());
	}
}

export function* handleOutgoingCall(action: ActionType<typeof callActions.startOutgoingCallByType>) {
	const authUser: AuthUser = yield select(getAuthUser);
	const isConditionalEmdrEnabled: boolean = yield select(getConditionalEmdrSettings);
	const {userId, callType, senderInfo, recipientInfo, isConferenceCall} = yield select(getCallInfo);

	const selectedCallType = action.payload.callType;
	// Set it as mdo call if selected call type is `mdo` or `isConferenceCall` is true
	const isMdoCall = selectedCallType === OutgoingCallTypes.MDO || isConferenceCall;
	// Only enable EMDR call if selected call type is `emdr` or conditional EMDR is enabled (but `select outgoing call` type is off)
	const isEmdrEnabled =
		selectedCallType === OutgoingCallTypes.EMDR || (!featureFlags.outgoingCallType && isConditionalEmdrEnabled);
	// Use `gll` as media region if `callMediaRegion` flag is enabled
	const callMediaRegion = featureFlags.callMediaRegionGll ? MediaRegion.GLL : undefined;

	try {
		let callResponse: Call;
		let room: TwilioRoom;
		const token: string = yield call(getSessionId);

		// Initiate call
		if (isMdoCall) {
			// Create conference room then initiate twilio conference call
			room = yield apiCallSaga(twilioSDK.createConferenceRoom, token, false, callMediaRegion);
			callResponse = yield apiCallSaga(
				chatSDK.initiateTwilioConferenceCall,
				userId,
				callType,
				senderInfo,
				recipientInfo,
				room.roomName,
			);
		} else {
			// Create room then initiate twilio call
			room = yield apiCallSaga(twilioSDK.createGroupRoom, token, callMediaRegion);
			callResponse = yield apiCallSaga(
				chatSDK.initiateCall,
				userId,
				callType,
				senderInfo,
				recipientInfo,
				[CallSDK.Twilio],
				room.roomName,
				undefined,
				isEmdrEnabled,
				undefined,
			);
		}

		// Get twilio access token from backend
		// so that user can enter twilio call room
		const validUntil: Date = yield call(getTokenValidUntil);
		const accessToken: string = yield apiCallSaga(
			twilioSDK.createVideoAccessToken,
			authUser.token,
			callResponse.roomId,
			{
				validUntil,
				identity: senderInfo.publicId,
			},
		);

		const initiatedTime: number = Date.now();

		const activeCall: ActiveCall = {
			roomId: callResponse.roomId,
			accessToken,
			initiatorUserId: authUser.id,
			ongoingCounterPartId: userId,
			isConferenceCall: isMdoCall,
			isEmdrEnabled,
			type: callType,
			direction: CallDirection.OUTGOING,
			participantMap: {
				[userId]: {
					...recipientInfo,
					initiatedTime,
				},
				[authUser.id]: {
					...senderInfo,
					initiatedTime,
					joinedTime: initiatedTime,
				},
			},
		};

		yield put(callActions.createActiveCall(activeCall));
		yield put(callActions.startTimeout(userId));
		yield put(
			toastActions.addToast({
				message: strTranslation.CHAT.video.calling.toast,
				type: "info",
				localizationPayload: {name: getFullName(recipientInfo)},
			}),
		);
	} catch (err) {
		logger.captureException(err, {isConferenceCall});

		yield put(
			toastActions.addToast({
				message: strTranslation.CHAT.video.call_cant_be_started.toast,
				type: "error",
			}),
		);

		// Close and reset call window when things went wrong
		yield put(callActions.closeCallWindow());
	}
}

export default function* () {
	yield takeEvery(getType(callActions.startCall), handleOpenCallWindow);
	yield takeEvery(getType(callActions.startOutgoingCallByType), handleOutgoingCall);
}
