import {Options as RRuleOptions, RRule, Weekday, Frequency, rrulestr, RRuleSet} from "rrule";
import moment from "moment";
import {
	RepeatedOption,
	RepeatEvery,
	Ends,
	CustomRepetitionFormValues,
	RepeatEveryFormValues,
	EndsFormValues,
} from "./CustomRepetitionTypes";
import {ParsedOptions} from "rrule/dist/esm/src/types";
import {createUTCDateString, createLocalDateFromISODateString, weekNumberOfMonth} from "utils/time";
import {RecurringExpression} from "@sense-os/goalie-js";
import {TIME_UNITS} from "constants/time";

//
// RRULE TO UI STATE
//

/**
 * Generates `RRule` by `RepeatedOption`
 *
 * @see https://github.com/jakubroztocil/rrule#rrule-constructor
 *
 * @param {Date} startTime
 * @param {RepeatedOption} repeatedOption
 * @param {CustomRepetitionFormValues} customRepetition must exists if `repeatedOption === RepeatedOption.CUSTOM`
 */
export function convertUIStateToRRule(
	startTime: moment.Moment,
	repeatedOption: RepeatedOption = RepeatedOption.NOT_REPEATED,
	customRepetition: CustomRepetitionFormValues = null,
): RRule {
	// Original start date with local time
	const originalStartDate: Date = startTime.toDate();
	// Start date with UTC date
	const dtstart: Date = new Date(createUTCDateString(startTime.toDate()));

	let options: Partial<RRuleOptions> = {};

	switch (repeatedOption) {
		case RepeatedOption.NOT_REPEATED:
			options = {
				dtstart,
				count: 1,
				freq: RRule.DAILY,
			};
			break;
		case RepeatedOption.DAILY:
			options = {
				dtstart,
				freq: RRule.DAILY,
			};
			break;
		case RepeatedOption.WEEKLY:
			options = {
				dtstart,
				freq: RRule.WEEKLY,
				byweekday: getRRuleWeekDayByDate(originalStartDate),
			};
			break;
		case RepeatedOption.MONTHLY:
			options = {
				dtstart,
				freq: RRule.MONTHLY,
				byweekday: getRRuleWeekDayByDate(originalStartDate, weekNumberOfMonth(originalStartDate)),
			};
			break;
		default:
			if (!customRepetition) {
				throw new Error("Custom repetition must be provided!");
			}
			options = {
				dtstart,
				...customRepetitionRRuleOptions(originalStartDate, customRepetition),
			};
			break;
	}

	let rrule = new RRule(options);

	// This check below only occurs when we set occurrence using Custom Repetition
	// There are some possibilities where the Custom Repetition settings causing no occurrence at all.
	// So instead of showing error message, we set the rrule to only repeat Once.
	if (!hasOccurrence(rrule)) {
		rrule = new RRule({
			dtstart,
			count: 1,
			freq: RRule.WEEKLY,
			interval: 1,
			byweekday: getRRuleWeekDayByDate(originalStartDate),
		});
	}

	return rrule;
}

/**
 * Returns RRule weekday by date
 *
 * @param {Date} date
 * @param {number} weekNumber specify the nth occurrence of the weekday in the period
 */
function getRRuleWeekDayByDate(date: Date, weekNumber?: number): Weekday {
	// Minus 1 because `isoWeekDay` starts from 1
	const weekDay: number = moment(date).isoWeekday() - 1;

	if (weekDay > 6 || weekDay < 0) {
		return null;
	}

	const rruleWeekDay: Weekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU][weekDay];

	if (weekNumber) {
		if (weekNumber > 4) {
			// nth = -1 means that we want to set
			// the occurrence to every last week of the month.
			return rruleWeekDay.nth(-1);
		}
		return rruleWeekDay.nth(weekNumber);
	}
	return rruleWeekDay;
}

/**
 * Transform `CustomRepetitionFormValues` to `RRuleOptions`
 *
 * @param {Date} startTime
 * @param {CustomRepetitionFormValues} customRepetitions
 */
function customRepetitionRRuleOptions(
	startTime: Date,
	customRepetition: CustomRepetitionFormValues,
): Partial<RRuleOptions> {
	const rruleOptions = {
		...getEndsRRuleOptions(startTime, customRepetition.ends),
		...getRepeatDaysRRuleOptions(customRepetition.repeatDays),
		...getRepeatEveryRRuleOptions(startTime, customRepetition.repeatEvery),
	};

	if (customRepetition.repeatEvery.every === RepeatEvery.DAY) {
		rruleOptions.byweekday = null;
	}

	return rruleOptions;
}

/**
 * Transform `RepeatEveryFormValues` to `RRuleOptions`
 *
 * @param {Date} startTime
 * @param {RepeatEveryFormValues} repeatEvery
 */
function getRepeatEveryRRuleOptions(startTime: Date, repeatEvery: RepeatEveryFormValues): Partial<RRuleOptions> {
	const {every, value} = repeatEvery;

	let options: Partial<RRuleOptions> = {};

	if (every === RepeatEvery.DAY) {
		options.freq = RRule.DAILY;
	} else if (every === RepeatEvery.WEEK) {
		options.freq = RRule.WEEKLY;
	} else {
		options.freq = RRule.MONTHLY;
		options.byweekday = getRRuleWeekDayByDate(startTime, weekNumberOfMonth(startTime));
	}

	options.interval = value;

	return options;
}

/**
 * Transform `repeatDays` to `RRuleOptions`
 *
 * 0 -> Monday
 * 1 -> Tuesday
 * .
 * .
 * 6 -> Sunday
 *
 * @param {number[]} repeatDays
 */
function getRepeatDaysRRuleOptions(repeatDays: number[]): Partial<RRuleOptions> {
	if (repeatDays.length === 0) {
		return null;
	}

	return {
		byweekday: repeatDays,
	};
}

/**
 * Transform `EndsFormValues` to `RRuleOptions`
 *
 * @param {EndsFormValues}
 */
function getEndsRRuleOptions(startTime: Date, {ends, value}: EndsFormValues): Partial<RRuleOptions> {
	if (ends === Ends.NEVER) {
		return null;
	}

	if (!value) {
		throw new Error("Value must be provided!");
	}

	if (ends === Ends.AFTER_REPETITION) {
		return {
			count: Number(value),
		};
	}
	if (ends === Ends.ON_DATE) {
		return {
			until: new Date(createUTCDateString(value as Date)),
		};
	}
}

//
// UI STATE TO RRULE
//

/**
 * Convert RRule to UI State
 *
 * @param {string} rruleString
 */
export function convertRRuleToUIState(rruleString: string): {
	repeatedOption: RepeatedOption;
	customRepetition: CustomRepetitionFormValues;
} {
	// Convert rruleString to RRule
	const rrule: RRule = parseRRuleString(rruleString);

	if (!rrule) {
		return {
			repeatedOption: RepeatedOption.NOT_REPEATED,
			customRepetition: null,
		};
	}
	const options = rrule.options as ParsedOptions;

	// Check if RRule is a customRepetition
	// If it is, then generate `CustomRepetitionFormValues` object
	const repeatedOption: RepeatedOption = isRRuleCustomRepetition(options)
		? RepeatedOption.CUSTOM
		: getRepeatedOptionByFreq(options.freq, options.count);

	return {
		repeatedOption,
		customRepetition: repeatedOption === RepeatedOption.CUSTOM && getCustomRepetitionByRRuleOptions(options),
	};
}

/**
 * Check if RRule is a custom repetition or not
 *
 * @param {ParsedOptions} options
 */
function isRRuleCustomRepetition(options: ParsedOptions): boolean {
	const {freq, dtstart, byweekday, interval, count} = options;

	const repeatedOption: RepeatedOption = getRepeatedOptionByFreq(freq, count);
	const start: moment.Moment = moment(dtstart);
	const ends: EndsFormValues = getEndsByRRuleOptions(options);

	if (repeatedOption === RepeatedOption.NOT_REPEATED) {
		return false;
	}

	// If `interval` is more than 1, then RRule is definitely custom repetition
	if (interval > 1) {
		return true;
	}

	// If `ends` has value, then RRule is definitely custom repetition
	if (ends.ends === Ends.ON_DATE || ends.ends === Ends.AFTER_REPETITION) {
		return true;
	}

	if (repeatedOption === RepeatedOption.WEEKLY) {
		// If repetition occurs every week and weekday === dtstart.weekday, then its not custom repetition
		if (byweekday.length === 1 && byweekday[0] === start.isoWeekday() - 1) {
			return false;
		}
		return true;
	}

	return false;
}

/**
 * Returns `RepeatedOption` by `ParsedOptions.freq`
 *
 * `RepeatedOption.CUSTOM` is checked inside `isRRuleCustomRepetition` method
 *
 * @param {Frequency} freq
 */
function getRepeatedOptionByFreq(freq: Frequency, count?: number): RepeatedOption {
	return (
		{
			// Both NOT_REPEATED and DAILY share the same RRULE frequency.
			// The difference between those two is in `count` value. If `count` = 1, then
			// the occurrence only occurs once. Thus the RepeatedOption = `DAILY`.
			[RRule.DAILY]: count && count === 1 ? RepeatedOption.NOT_REPEATED : RepeatedOption.DAILY,
			[RRule.WEEKLY]: RepeatedOption.WEEKLY,
			[RRule.MONTHLY]: RepeatedOption.MONTHLY,
		}[freq] || RepeatedOption.NOT_REPEATED
	);
}

/**
 * Returns `CustomRepetitionFormValues` object
 *
 * @param {ParsedOptions} options
 */
function getCustomRepetitionByRRuleOptions(options: ParsedOptions): CustomRepetitionFormValues {
	const repeatedOption = getRepeatedOptionByFreq(options.freq);
	// Custom repetition must have interval value
	if (repeatedOption === RepeatedOption.NOT_REPEATED) {
		return null;
	}

	return {
		repeatEvery: getRepeatEveryByRRuleOptions(options),
		repeatDays: getRepeatedDaysByRRuleOptions(options),
		ends: getEndsByRRuleOptions(options),
	};
}

/**
 * Returns `RepeatEveryFormValues` by `ParsedOptions`
 *
 * @param {ParsedOptions}
 */
function getRepeatEveryByRRuleOptions({freq, interval}: ParsedOptions): RepeatEveryFormValues {
	return {
		every:
			freq === Frequency.DAILY
				? RepeatEvery.DAY
				: freq === Frequency.WEEKLY
				? RepeatEvery.WEEK
				: RepeatEvery.MONTH,
		value: interval || 1,
	};
}

/**
 * Returns `repeatDays` by `ParsedOptions`
 *
 * @param {ParsedOptions}
 */
function getRepeatedDaysByRRuleOptions({byweekday}: ParsedOptions): number[] {
	return byweekday || [];
}

/**
 * Returns `EndsFormValues` by `ParsedOptions`
 *
 * @param {ParsedOptions}
 */
function getEndsByRRuleOptions({count, until}: ParsedOptions): EndsFormValues {
	let value: number | Date = null,
		ends: Ends = Ends.NEVER;
	if (until) {
		ends = Ends.ON_DATE;
		value = createLocalDateFromISODateString(until.toISOString());
	} else if (count) {
		ends = Ends.AFTER_REPETITION;
		value = Number(count);
	}
	return {
		ends,
		value,
	};
}

/**
 * Get anchor time by evaluating latest occurrence from old rrule
 *
 * @param {RecurringExpression} recurringExpression
 * @param {Date} newStartTime date (don't convert to UTC)
 *
 * @returns {Date} date in UTC
 */
export function getAnchorTime(recurringExpression: RecurringExpression, newStartTime: Date): Date {
	const {rrule, margin} = recurringExpression;
	const rruleObj: RRule = parseRRuleString(rrule);

	// Convert to UTC
	const newStartTimeUTC: Date = new Date(createUTCDateString(newStartTime));

	// default anchor time is current date time in UTC.
	let anchorTime: Date = new Date(createUTCDateString());

	if (newStartTimeUTC.getTime() < anchorTime.getTime()) {
		// newStartTime is less than current date time.
		// Thus anchorTime = newStartTime.
		anchorTime = newStartTimeUTC;
	}

	// Get latest occurrence before current time.
	const latestOccurrence: Date = rruleObj.before(anchorTime, true);

	if (latestOccurrence) {
		// Add latest occurrence with margin to get end time
		const latestOccurrenceEndTime: Date = moment(latestOccurrence).add(margin.after, TIME_UNITS.MINUTES).toDate();

		// See if current time is between latest occurrence start and end time
		// If yes, then set anchorTime to latest occurrence.
		if (
			anchorTime.getTime() >= latestOccurrence.getTime() &&
			anchorTime.getTime() <= latestOccurrenceEndTime.getTime()
		) {
			// Since latestOccurrence is already in UTC, we don't need to convert it
			anchorTime = latestOccurrence;
		}
	}

	// Subtract anchorTime by one minute
	return moment(anchorTime).subtract(1, TIME_UNITS.MINUTES).toDate();
}

/**
 * Check if rrule has occurrence or not
 *
 * @param {RRule} rrule
 */
export function hasOccurrence(rrule: RRule): boolean {
	const firstOccurrence = rrule.after(new Date(createUTCDateString(moment().startOf(TIME_UNITS.DAY).toDate())), true);
	return !!firstOccurrence;
}

/**
 * Returns the first occurrence of rrule
 *
 * @param rrule
 */
export function getFirstOccurrenceFromRRule(rrule: RRule): Date {
	const {dtstart} = rrule.options;
	return rrule.after(dtstart, true);
}

/**
 * Try to adjust `newRRuleString` end time if both custom repetitions are the same
 *
 * @param {string} newRRuleString
 * @param {string} prevRRuleString
 */
export function adjustRRuleEndTime(newRRuleString: string, prevRRuleString: string, occurrenceTime: Date): string {
	if (!hasSameCustomRepetition(prevRRuleString, newRRuleString)) {
		throw new Error("RRule has different custom repetitions!");
	}
	const prevRRule = parseRRuleString(prevRRuleString);

	// Try to adjust count value to the new RRule
	if (prevRRule.options.count) {
		const newRRule = parseRRuleString(newRRuleString);
		// Convert `occurrenceTime` to UTC
		const UTCOccurrenceTime = new Date(createUTCDateString(occurrenceTime)).getTime();

		// Say there are 5 occurrences and the user edit the third one,
		// we want the new RRule to start from the third one and end at the last occurrence
		// of the previous rrule. To do this we just need to try to count the rest of occurrences left
		// from the previous rrule and use the value.
		const newCount = prevRRule
			.all()
			.map((d) => d.getTime())
			.filter((d) => d >= UTCOccurrenceTime).length;

		const updatedNewRRule = new RRule({
			...newRRule.origOptions,
			count: newCount,
		});
		return updatedNewRRule.toString();
	}

	return newRRuleString;
}

/**
 * Returns true if both rrule has the same custom repetition values
 *
 * @param {string} prevRRule
 * @param {string} newRRule
 */
export function hasSameCustomRepetition(prevRRule: string, newRRule: string): boolean {
	const {customRepetition: previousCustomRepetition, repeatedOption: previousRepeatedOption} =
		convertRRuleToUIState(prevRRule);
	const {customRepetition: newCustomRepetition, repeatedOption: newRepeatedOption} = convertRRuleToUIState(newRRule);
	return (
		JSON.stringify(newCustomRepetition) === JSON.stringify(previousCustomRepetition) &&
		newRepeatedOption === previousRepeatedOption
	);
}

/**
 * Parse rrulestring using `rrulestr` function since `RRule.fromString`
 * doesn't work properly if the rruleString contains `EXDATE`
 *
 * @param {string} rruleString
 */
function parseRRuleString(rruleString: string): RRule {
	const rruleSet: RRuleSet = rrulestr(rruleString, {forceset: true}) as RRuleSet;
	return rruleSet._rrule.length > 0 && rruleSet._rrule[0];
}

/**
 * Returns true if rrule has more than one occurrence
 *
 * @param {string} rruleString
 */
export function hasOnlyOneOccurrence(rruleString: string): boolean {
	const {origOptions} = parseRRuleString(rruleString);
	return origOptions.count === 1;
}
