import {fork} from "redux-saga/effects";
import setupTrackerSaga from "./setupTrackerSaga";
import userTrackerSaga from "./userTrackerSaga";
import manageTrackersSaga from "./manageTrackersSaga";
import customTrackerDialogSaga from "./customTrackerDialogSaga";
import trackerGraphV2Saga from "./trackerGraphV2Saga";

export default function* () {
	yield fork(setupTrackerSaga);
	yield fork(userTrackerSaga);
	yield fork(manageTrackersSaga);
	yield fork(customTrackerDialogSaga);
	yield fork(trackerGraphV2Saga);
}
