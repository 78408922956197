import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {GroupTherapyDetail} from "@sense-os/goalie-js";

import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";
import {toastActions} from "../../toaster/redux";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

import {groupTherapyActions} from "../redux/groupTherapyActions";
import {getGroupMemberIds} from "../helpers/groupTherapyHelpers";
import groupTherapySDK from "../helpers/groupTherapySdk";

const log = createLogger("saveGroupTherapySaga", SentryTags.GroupTherapy);

function* addAGroupTherapy(action: ActionType<typeof groupTherapyActions.addAGroupTherapy.request>) {
	const {group} = action.payload;
	const token: string = yield call(getSessionId);
	try {
		const groupDetail: GroupTherapyDetail = yield apiCallSaga(
			groupTherapySDK.createGroupTherapy,
			token,
			group.name,
		);
		if (!!groupDetail) {
			const groupMemberIds: number[] = yield call(getGroupMemberIds, group.clients, group.therapists);
			yield apiCallSaga(groupTherapySDK.updateGroupTherapyMembers, token, groupDetail.id, groupMemberIds);

			yield put(
				groupTherapyActions.addAGroupTherapy.success({
					group: {
						id: groupDetail.id,
						name: groupDetail.name,
						createdAt: groupDetail.createdAt,
						clients: group.clients,
						therapists: group.therapists,
					},
				}),
			);

			yield put(
				toastActions.addToast({
					message: loc.formatMessage(strTranslation.GROUPS.toast_message.create.success),
					type: "success",
				}),
			);
		}
	} catch (err) {
		log.captureException(err, {message: "Failed to create a group therapy."});
		yield put(groupTherapyActions.addAGroupTherapy.failure(err));
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.GROUPS.toast_message.create.failed),
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.addAGroupTherapy.request, addAGroupTherapy);
}
