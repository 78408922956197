import profileSDK from "../../userProfile/helpers/profileSDK";
import {OrganizationMemberStatus} from "@sense-os/goalie-js";
import {AuthUser} from "../authTypes";
import {UserAccountPermissions} from "@sense-os/goalie-js/dist/commonTypes";
import storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";
import {StorageBools} from "services/system/storage/StorageBools";
import {getAcceptedAndPendingOrganizations} from "../../organizations/helpers/organizationHelper";

/**
 * Given token and initial userAccount data (Information gotten from login response)
 * this function will get all the rest of logged in user's data
 *
 * @param {string} token
 * @param {UserAccount} userAccount
 */
export async function getAuthUserData(token: string, userId: number) {
	const userData = await profileSDK.getUserData(token, userId);

	const shouldJoinOrg = storage.read(StorageKeys.SHOULD_JOIN_ORG) === StorageBools.TRUE;
	const externalUserID = storage.read(StorageKeys.EXTERNAL_ID) as string;

	const email = storage.read(StorageKeys.EMAIL) as string;
	const username = storage.read(StorageKeys.USER_NAME) as string;
	const isActive = JSON.parse(storage.read(StorageKeys.IS_ACTIVE_USER) as string);
	const dateJoined = new Date(parseInt(storage.read(StorageKeys.DATE_JOINED) as string));
	const hashId = storage.read(StorageKeys.HASH_ID) as string;
	const roles = JSON.parse(storage.read(StorageKeys.USER_PERMISSIONS) as string) as UserAccountPermissions[];

	const userOrganization = shouldJoinOrg ? null : await getAuthUserOrganization(token, userId);
	const isAcceptedToOrg = userOrganization?.status === OrganizationMemberStatus.ACCEPTED;

	const authUser: AuthUser = {
		id: userId,
		externalId: externalUserID,
		hashId,
		fullName: userData.firstName + " " + userData.lastName,
		firstName: userData.firstName,
		lastName: userData.lastName,
		imageUrl: userData.image,
		data: userData,
		account: {
			id: userId,
			email,
			username,
			isActive,
			externalId: externalUserID,
			dateJoined,
			hashId,
			roles,
		},
		token: token,
		roles,
		organization: userOrganization
			? {
					id: userOrganization.organization.id,
					name: userOrganization.organization.name,
					isAccepted: isAcceptedToOrg,
					dateJoined: isAcceptedToOrg ? userOrganization.statusUpdatedAt : null,
					logo: userOrganization.organization.logo,
					groupId: userOrganization.organization?.group,
					formKey: userOrganization.organization.formKey,
			  }
			: null,
	};

	return authUser;
}

async function getAuthUserOrganization(token: string, userId: number) {
	const userOrg = await getAcceptedAndPendingOrganizations(token, userId);
	if (!userOrg) {
		return null;
	}

	return userOrg[0];
}
