import {call, put} from "redux-saga/effects";
import {ArticleRequestCreate, NotFoundError, PsychoEducationItem} from "@sense-os/goalie-js";
import {onAutoEnableTracker} from "../../tracker/customTracker/helpers/onAutoEnableTracker";
import {PresetItem, PresetItemsType} from "../views/PresetsFormContext";
import {getPresetPsychoEducationPayload, getPresetTaskPsychoEducationPayload} from "../helpers/presetHelpers";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import psychoEducationSDK from "../../tracker/psychoEducation/helpers/psychoEducationSDK";
import {getSessionId} from "../../auth/helpers/authStorage";
import {psychoEducationActions} from "../../tracker/psychoEducation/redux/psychoEducationActions";
import {CoreTrackerId} from "../../tracker/utils/coreTracker";
import taskSDK from "../../taskRegistration/helpers/taskSDK";
import {toastActions} from "../../toaster/redux";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

interface SavePresetPsychoEducationTaskParams {
	formValues: PresetItemsType;
	userId: number;
}

/**
 * Save Preset Tracker Task
 * 1. Enable Psycho Education Tracker
 * 2. Create Psycho Education
 * 3. Create Task Creation with created psycho education id
 */
export function* savePresetPsychoEducationTask(params: SavePresetPsychoEducationTaskParams) {
	const {formValues, userId} = params;

	const token: string = yield call(getSessionId);

	/** 1. Enable Psycho Education Tracker */
	yield call(onAutoEnableTracker, {
		userId,
		trackerId: CoreTrackerId.PSYCHOEDUCATION,
	});
	/**
	 * Based on this feedback:
	 *
	 * 	  All to-dos need to be created in the reverse order they appear in the presets UI.
	 *    This is so that to-dos with the same due date appear in the correct order (reverse chronological) in the intervention tab & the app.
	 *
	 * the order while creating the task is reversed
	 */
	const formValueKeys: string[] = Object.keys(formValues).reverse();

	for (const formKey of formValueKeys) {
		const currentFormValue: PresetItem = formValues[formKey];
		/** 2. Create Psycho Education */
		const psychoEducationPayload = getPresetPsychoEducationPayload({
			formValue: currentFormValue,
			userId,
		});

		const psychoEducationResponse: PsychoEducationItem = yield apiCallSaga(
			psychoEducationSDK.createPsychoEducation,
			token,
			psychoEducationPayload,
		);

		const articles: ArticleRequestCreate[] = [
			{
				url: currentFormValue.psycho.link,
			},
		];

		/** Create new psycho education articles */
		yield put(
			psychoEducationActions.saveArticles.request({
				psychoEducationId: psychoEducationResponse.id,
				articlesBody: articles,
			}),
		);

		/** 3. Create Task Creation with created psycho education id */
		const taskPayload = getPresetTaskPsychoEducationPayload({
			formValue: currentFormValue,
			psychoEducationId: psychoEducationResponse.id,
			userId,
		});

		try {
			yield apiCallSaga(taskSDK.createTask, token, taskPayload);
		} catch (error) {
			yield put(psychoEducationActions.savePsychoEducation.failure(error));

			if (error instanceof NotFoundError) {
				yield put(
					toastActions.addToast({
						message: localization.formatMessage(
							strTranslation.GRAPHS.event_detail.psycho_education.toast.not_found,
						),
						type: "error",
					}),
				);
			} else {
				yield put(
					toastActions.addToast({
						message: localization.formatMessage(
							strTranslation.GRAPHS.new_event.psycho_education.toast.error,
						),
						type: "error",
					}),
				);
			}
		}
	}
}
