import {takeEvery, call} from "redux-saga/effects";
import storage from "../ts/services/system/storage/Storage";
import {StorageKeys} from "../ts/services/system/storage/StorageKeys";
import {identifySentryUser, unidentifySentryUser} from "./sentryService";
import {authActions} from "../auth/redux";
import {bootstrapActions} from "../bootstrap/redux";

function* identifySentry() {
	const externalId = yield call(storage.read, StorageKeys.EXTERNAL_ID);
	if (externalId) {
		yield call(identifySentryUser, externalId);
	}
}

function* unidentifySentry() {
	yield call(unidentifySentryUser);
}

export default function* () {
	yield takeEvery(authActions.login.success, identifySentry);
	yield takeEvery(authActions.logout.success, unidentifySentry);
	yield takeEvery(bootstrapActions.endBootstrapApp, identifySentry);
}
