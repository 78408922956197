import {call, put, takeEvery} from "redux-saga/effects";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import organizationSdk from "../../organizations/helpers/organizationSdk";
import {getSessionId} from "../../auth/helpers/authStorage";
import {OrganizationUser, OrganizationUserStatus} from "@sense-os/goalie-js";
import {PortalOrganizationTherapist} from "../userManagementTypes";
import {userManagementActions} from "../redux/userManagementActions";
import {ActionType, getType} from "typesafe-actions";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {toastActions} from "../../toaster/redux";
import {arrToMap} from "../../helpers/arrToMap";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("userManagementSaga", SentryTags.UserManagement);

function* fetchOrganizationUsersList() {
	try {
		const token: string = yield call(getSessionId);
		const users: OrganizationUser[] = yield apiCallSaga(organizationSdk.getAllOrganizationsMembers, token, {});

		const transformedUsers: PortalOrganizationTherapist[] = users.map((user) => ({
			...user,
			fullName: `${user.firstName} ${user.lastName}`,
		}));

		const organizationUsers: Record<string, PortalOrganizationTherapist> = arrToMap(
			transformedUsers,
			(user) => user.hashId,
		);

		const acceptedUsersId = users
			.filter((user) => user.status === OrganizationUserStatus.ACCEPTED)
			.map((user) => user.hashId);
		const pendingUsersId = users
			.filter((user) => user.status === OrganizationUserStatus.PENDING)
			.map((user) => user.hashId);
		const deactivatedUsersId = users
			.filter((user) => user.status === OrganizationUserStatus.DEACTIVATED)
			.map((user) => user.hashId);

		yield put(
			userManagementActions.fetchUserManagementList.success({
				organizationUsers,
				acceptedUsersId,
				pendingUsersId,
				deactivatedUsersId,
			}),
		);
	} catch (error) {
		log.captureException(error);
		yield put(userManagementActions.fetchUserManagementList.failure({error}));
	}
}

function* deactivateAcceptedUser(action: ActionType<typeof userManagementActions.deactivateAcceptedUser.request>) {
	const {hashId} = action.payload;
	try {
		const token: string = yield call(getSessionId);
		yield apiCallSaga(
			organizationSdk.updateOrganizationMemberStatus,
			token,
			hashId,
			OrganizationUserStatus.DEACTIVATED,
		);

		yield put(userManagementActions.deactivateAcceptedUser.success({hashId}));

		const toastText = localization.formatMessage(strTranslation.ADMIN_MEMBERS.actions.deactivate.success);
		yield put(toastActions.addToast({message: toastText, type: "success"}));
	} catch (error) {
		log.captureException(error);
		yield put(userManagementActions.deactivateAcceptedUser.failure({error, hashId}));

		const toastText = localization.formatMessage(strTranslation.ADMIN_MEMBERS.actions.deactivate.failure);
		yield put(toastActions.addToast({message: toastText, type: "error"}));
	}
}

export function* userManagementSaga() {
	yield takeEvery(getType(userManagementActions.fetchUserManagementList.request), fetchOrganizationUsersList);
	yield takeEvery(getType(userManagementActions.deactivateAcceptedUser.request), deactivateAcceptedUser);
}
