import {customTrackerActions, CustomTrackerActionType} from "./redux/customTrackerActions";
import {getType} from "typesafe-actions";
import analyticsService from "../../analytics/AnalyticsService";

const trackedActions = [
	customTrackerActions.addTracker.request,
	customTrackerActions.addTracker.success,
	customTrackerActions.addTracker.failure,

	customTrackerActions.openSetupTrackerDialog,
	customTrackerActions.closeSetupTrackerDialog,
	customTrackerActions.openManageTrackersDialog,
	customTrackerActions.closeManageTrackersDialog,

	customTrackerActions.showTrackerAddedDialog,
	customTrackerActions.hideTrackerAddedDialog,

	customTrackerActions.saveManageTrackers.request,
	customTrackerActions.saveManageTrackers.success,
	customTrackerActions.saveManageTrackers.failure,
].map(getType);

const properties = (action: CustomTrackerActionType) => {
	switch (action.type) {
		case getType(customTrackerActions.saveManageTrackers.request):
		case getType(customTrackerActions.addTracker.request):
			return action.payload;

		default:
			return null;
	}
};

export const customTrackerEvents = analyticsService.trackReduxActions(trackedActions, properties);
