/**
 * Author: leo Date: 13/05/2018
 */

/**
 * A list of states in which VideoCallService can be.
 */
export enum States {
	WAITING_FOR_SDK = "waitingForSDK", // Waiting for video call window connects its call SDK
	CALLING = "calling", // A call is in progress.
	INITIALIZED = "init", // Initialized and ready to call or accept calls.
	REACTING_TO_CALL = "reacting", // The user is looking at the accept / reject dialog.
	HUNG_UP = "hup", // The user clicked the "hang-up" button in the video window.
	IN_CALL = "in_call", // While talking.

	// WAIT_FOR_VIDEO_WIN = "waitForVideo",
	// READY = "ready",                         // The user can call or accept a call
	// AWAITING_ROOM_NAME = "wait_room_name",   // Between VC_INIT and VC_INVITE
}
