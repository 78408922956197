import {MeetingNote, SensorDatum} from "../../ts/redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction, createAction} from "typesafe-actions";

export const clientNotePageActions = {
	fetchNotes: createAsyncAction(
		"clientNotePage/fetchNotes",
		"clientNotePage/fetchNotesSuccess",
		"clientNotePage/fetchNotesFail",
	)<{userId: number}, {userId: number; data: SensorDatum<MeetingNote>[]}, {userId: number; err: Error}>(),
	updatePrivateNoteSearchResults: createAction(
		"clientNotePage/updatePrivateNoteSearchResults",
		(searchValue: string, privateNote: string) => ({
			searchValue,
			privateNote,
		}),
	)(),
	updateMeetingNoteSearchResults: createAction(
		"clientNotePage/updateMeetingNoteSearchResults",
		(searchValue: string) => ({
			searchValue,
		}),
	)(),
	updateSearch: createAction("clientNotePage/updateSearch", (searchString: string) => ({
		searchString,
	}))(),
};

export type ClientNotePageActionType = ActionType<typeof clientNotePageActions>;
