import {Contact, ContactsMap, ContactState} from "../contactTypes";
import {LoadingState} from "constants/redux";
import {contactActions, ContactActionType} from "./contactAction";
import {getType} from "typesafe-actions";

const initialState: ContactState = {
	loadingContacts: LoadingState.EMPTY,
	removingContact: LoadingState.EMPTY,
	contactsMap: {},
	treatmentTherapistsContactsMap: {},
	pendingInvitations: [],
	invitationsLoadingMap: {},
	loadingInvitations: LoadingState.EMPTY,
	selectedContact: null,
};

export function contactReducer(state: ContactState = initialState, action: ContactActionType): ContactState {
	switch (action.type) {
		//
		// LOADING CONTACTS
		//
		case getType(contactActions.loadContacts.request):
			return {...state, loadingContacts: LoadingState.LOADING};
		case getType(contactActions.loadContacts.success):
			return {
				...state,
				loadingContacts: LoadingState.LOADED,
			};
		case getType(contactActions.loadContacts.failure):
			return {...state, loadingContacts: LoadingState.ERROR};

		case getType(contactActions.addContacts):
			return {
				...state,
				contactsMap: {...state.contactsMap, ...mapContacts(action.payload.contacts)},
			};

		case getType(contactActions.addTherapistsContacts):
			return {
				...state,
				treatmentTherapistsContactsMap: {...state.contactsMap, ...mapContacts(action.payload.contacts)},
			};

		//
		// REMOVING CONTACT
		//
		case getType(contactActions.removeContact.request):
			return {...state, removingContact: LoadingState.LOADING};
		case getType(contactActions.removeContact.success):
			// TODO: This remove contact process is quite expensive. Consider another logic
			const newContactsMap: Contact[] = Object.keys(state.contactsMap)
				.filter((userId) => Number(userId) !== action.payload.userId)
				.map((userId) => state.contactsMap[userId]);

			return {
				...state,
				removingContact: LoadingState.LOADED,
				contactsMap: mapContacts(newContactsMap),
			};
		case getType(contactActions.removeContact.failure):
			return {...state, removingContact: LoadingState.ERROR};

		case getType(contactActions.addContact):
			const userId: number = action.payload.contact.id,
				contact: Contact = action.payload.contact;

			const updatedContacts = {...state.contactsMap};
			updatedContacts[userId] = contact;

			return {...state, contactsMap: updatedContacts};

		case getType(contactActions.setPendingIncomingInvitations):
			return {...state, pendingInvitations: action.payload.invitations, loadingInvitations: LoadingState.LOADED};

		case getType(contactActions.acceptClientInvitation.request):
		case getType(contactActions.rejectClientInvitation.request):
			return {
				...state,
				invitationsLoadingMap: {
					...state.invitationsLoadingMap,
					[action.payload.contact.id]: LoadingState.LOADING,
				},
			};

		case getType(contactActions.acceptClientInvitation.failure):
		case getType(contactActions.rejectClientInvitation.failure):
			return {
				...state,
				invitationsLoadingMap: {
					...state.invitationsLoadingMap,
					[action.payload.contact.id]: LoadingState.ERROR,
				},
			};

		case getType(contactActions.acceptClientInvitation.success):
		case getType(contactActions.rejectClientInvitation.success):
			return {
				...state,
				pendingInvitations: state.pendingInvitations.filter(
					(invitation) => invitation.id !== action.payload.contact.id,
				),
				invitationsLoadingMap: {
					...state.invitationsLoadingMap,
					[action.payload.contact.id]: LoadingState.LOADED,
				},
			};
		case getType(contactActions.setSelectedContact):
			return {
				...state,
				selectedContact: action.payload.contact,
			};

		default:
			return state;
	}
}

/**
 * Returns a map: [userId] => Contact
 *
 * @param {Contact[]} contacts
 */
function mapContacts(contacts: Contact[]): ContactsMap {
	return contacts.reduce((contacts, contact) => {
		return {
			...contacts,
			[contact.id]: contact,
		};
	}, {});
}
