import {LoadingState} from "constants/redux";
import {getType} from "typesafe-actions";
import {OrganizationState} from "../organizationsTypes";
import {OrganizationActionType, organizationActions} from "./organizationActions";

const initialState: OrganizationState = {
	isSelectionDialogOpen: false,
	nonGroupOrganizations: [],
	organizationsGroups: [],
	availableOrganizations: [],
	loadingState: LoadingState.EMPTY,
	loadGroupOrganizationLoadingState: LoadingState.EMPTY,
	loadNonGroupOrganizationLoadingState: LoadingState.EMPTY,
};

export default function organizationsReducer(
	state: OrganizationState = initialState,
	action: OrganizationActionType,
): OrganizationState {
	switch (action.type) {
		case getType(organizationActions.openSelectionDialog):
			return {...state, isSelectionDialogOpen: true};

		case getType(organizationActions.loadNonGroupOrganizations.request):
			return {
				...state,
				loadNonGroupOrganizationLoadingState: LoadingState.LOADING,
			};
		case getType(organizationActions.loadNonGroupOrganizations.success):
			return {
				...state,
				loadNonGroupOrganizationLoadingState: LoadingState.LOADED,
				nonGroupOrganizations: action.payload.nonGroupOrganizations,
			};
		case getType(organizationActions.loadNonGroupOrganizations.failure):
			return {
				...state,
				loadNonGroupOrganizationLoadingState: LoadingState.ERROR,
			};

		case getType(organizationActions.loadOrganizationsGroups.request):
			return {
				...state,
				loadGroupOrganizationLoadingState: LoadingState.LOADING,
			};
		case getType(organizationActions.loadOrganizationsGroups.success):
			return {
				...state,
				loadGroupOrganizationLoadingState: LoadingState.LOADED,
				organizationsGroups: action.payload.organizationsGroups,
			};
		case getType(organizationActions.loadOrganizationsGroups.failure):
			return {
				...state,
				loadGroupOrganizationLoadingState: LoadingState.ERROR,
			};

		case getType(organizationActions.joinOrganization.request):
			return {
				...state,
				loadingState: LoadingState.LOADING,
			};
		case getType(organizationActions.joinOrganization.success):
			return {
				...state,
				isSelectionDialogOpen: false,
				loadingState: LoadingState.LOADED,
			};
		case getType(organizationActions.joinOrganization.failure):
			return {
				...state,
				loadingState: LoadingState.ERROR,
			};

		case getType(organizationActions.setAvailableOrganizations):
			return {
				...state,
				availableOrganizations: action.payload.availableOrgs,
			};

		default:
			return state;
	}
}
