import {timeTrackingActions, timeTrackingActionType} from "./redux/timeTrackingActions";
import {getType} from "typesafe-actions";

import analyticsService from "../analytics/AnalyticsService";

const trackedActions = [
	timeTrackingActions.confirmEntries.request,
	timeTrackingActions.confirmEntries.failure,
	timeTrackingActions.confirmEntries.success,
	timeTrackingActions.createTimeEntry.request,
	timeTrackingActions.createTimeEntry.failure,
	timeTrackingActions.createTimeEntry.success,
	timeTrackingActions.deleteEntry.request,
	timeTrackingActions.deleteEntry.failure,
	timeTrackingActions.deleteEntry.success,
	timeTrackingActions.fetchEntries.request,
	timeTrackingActions.fetchEntries.failure,
	timeTrackingActions.fetchEntries.success,
	timeTrackingActions.updateEntry.request,
	timeTrackingActions.updateEntry.failure,
	timeTrackingActions.updateEntry.success,
	timeTrackingActions.goToDate,
	timeTrackingActions.goToNextDay,
	timeTrackingActions.goToPrevDay,
	timeTrackingActions.goToToday,
	timeTrackingActions.setDate,
	timeTrackingActions.fetchUnconfirmedEntries.request,
	timeTrackingActions.fetchUnconfirmedEntries.success,
	timeTrackingActions.fetchUnconfirmedEntries.failure,

	// Analytics for export part of time tracking
	timeTrackingActions.clickingDownloadExport,
	timeTrackingActions.clickingExportButton,
	timeTrackingActions.clickingRequestExport,
].map(getType);

export const timeTrackingEvents = analyticsService.trackReduxActions(
	trackedActions,
	(action: timeTrackingActionType) => {
		if (action.type === getType(timeTrackingActions.setDate)) {
			return action.payload;
		}
		return null;
	},
);
