import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {wrapInDispatch} from "../../helpers/wrapInDispatch";
import {XaiPrediction} from "@sense-os/goalie-js";

export const xaiActions = {
	getPredictions: createAsyncAction("xai/getPredictions", "xai/getPredictionsSucceed", "xai/getPredictionsFailed")<
		void,
		{predictionsMap: Record<number, XaiPrediction>},
		{error: Error}
	>(),
	sendFeedback: createAsyncAction("xai/sendFeedback", "xai/sendFeedbackSucceed", "xai/sendFeedbackFailed")<
		{
			clientId: number;
			predictionId: number;
			checkRegistrations: boolean;
			feedbackIsHelpful: boolean;
			feedbackDescription: string;
		},
		{clientId: number},
		{clientId: number; error: Error}
	>(),

	setClientIdForDialog: createAction("xai/setClientIdForDialog", (clientId) => ({clientId}))(),
};

export type XaiAction = ActionType<typeof xaiActions>;
export const xaiActionsWithDispatch = wrapInDispatch(xaiActions);
