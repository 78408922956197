import {getType} from "typesafe-actions";
import {BootstrapState} from "../bootstrapTypes";
import {bootstrapActions, BootstrapActionType} from "./bootstrapActions";

const initialState: BootstrapState = {
	isBootstrapped: false,
	shouldWaitForSortedContacts: false,
};

export default function bootstrapReducer(
	state: BootstrapState = initialState,
	action: BootstrapActionType,
): BootstrapState {
	switch (action.type) {
		case getType(bootstrapActions.endBootstrapApp):
			return {
				...state,
				isBootstrapped: true,
			};
		case getType(bootstrapActions.waitForSortedContacts):
			return {
				...state,
				shouldWaitForSortedContacts: true,
			};
		case getType(bootstrapActions.contactsSorted):
			return {
				...state,
				shouldWaitForSortedContacts: false,
			};
		default:
			return state;
	}
}
