import {PresenceAvailability} from "@sense-os/goalie-js/dist";
import {getType} from "typesafe-actions";

import {ChatPresenceContext} from "../types";

import {ChatPresenceState} from "./ChatPresenceState";
import {chatPresenceActions, ChatPresenceActionType} from "./ChatPresenceAction";
import produce from "immer";

const initialState: ChatPresenceState = {
	isDialogOpen: false,
	context: ChatPresenceContext.SET_BY_PORTAL,
	presence: PresenceAvailability.Available,
};

export const ChatPresenceReducer = produce((state: ChatPresenceState, action: ChatPresenceActionType) => {
	switch (action.type) {
		case getType(chatPresenceActions.setIsDialogOpen):
			state.isDialogOpen = action.payload.isOpen;
			break;

		case getType(chatPresenceActions.updateOwnPresence):
			state.presence = action.payload.presence;
			state.context = action.payload.context;
			break;

		default:
			return state;
	}
}, initialState);
