import {getPatientsContacts, getPendingInvitations} from "../../contacts/redux/contactSelectors";
import {Contact, IncomingInvitation} from "../../contacts/contactTypes";
import {select} from "redux-saga/effects";
import {InviteClientItem} from "@sense-os/goalie-js";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import inviteClientsSDK from "../helpers/inviteClientsSdk";
import {getSessionId} from "../../auth/helpers/authStorage";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";

const log = createLogger("autoRemoveInviteClients", SentryTags.InviteClients);

interface InviteClientItemFindType extends InviteClientItem {
	found: boolean;
}

/**
 * This function is used to matching the invite clients list to pending invitations and connected patient contacts
 * if it has an overlapping points, delete the overlapping clients
 *
 * TODO: remove this file if the backend has supported this logic
 * @param inviteClientList
 */
export function* autoRemoveInviteClients(inviteClientList: InviteClientItem[]) {
	const token: string = getSessionId();

	// Getting contact and pending invitation emails
	const contacts: Contact[] = yield select(getPatientsContacts);
	const pendingInvitations: IncomingInvitation[] = yield select(getPendingInvitations);
	const contactEmails: string[] = contacts.map((contact) => {
		return contact.email;
	});
	const pendingInvitationEmails: string[] = pendingInvitations.map((pendingInvitation) => {
		return pendingInvitation.email;
	});

	// Mapping invite client item by email
	const mergedEmailContactsAndPendingInvitations: string[] = [...contactEmails, ...pendingInvitationEmails];
	const mapEmailInviteClient: Record<string, InviteClientItemFindType> = inviteClientList.reduce((result, item) => {
		const email: string = item.inviteeEmail;
		const findIndex: number = mergedEmailContactsAndPendingInvitations.findIndex((item) => item === email);
		const isFound: boolean = findIndex >= 0;
		result[email] = {
			found: isFound,
			...item,
		};
		return result;
	}, {});
	const mapEmailInviteClientKeys: string[] = Object.keys(mapEmailInviteClient);

	// Removing invitation if email was found
	for (const email of mapEmailInviteClientKeys) {
		const selectedInviteClient = mapEmailInviteClient[email];
		// if the email already connected or sent a connection request to the therapist, remove it
		if (selectedInviteClient.found) {
			try {
				yield apiCallSaga(inviteClientsSDK.deleteClientInvitation, token, selectedInviteClient.id);
			} catch (err) {
				log.captureException(err, {message: "Unable to remove client invitation"});
			}
		}
	}
}
