import {LoadingState} from "constants/redux";
import {PresetsState} from "./presetsReduxType";
import {PresetActionsType, presetsActions} from "./presetsActions";
import {getType} from "typesafe-actions";

const initialState: PresetsState = {
	/**
	 * Flag to mark saving state status for presets
	 */
	savingState: LoadingState.EMPTY,
};

export function presetsReducer(state: PresetsState = initialState, action: PresetActionsType): PresetsState {
	switch (action.type) {
		case getType(presetsActions.savePreset.request): {
			return {
				...state,
				savingState: LoadingState.LOADING,
			};
		}
		case getType(presetsActions.savePreset.success): {
			return {
				...state,
				savingState: LoadingState.LOADED,
			};
		}
		case getType(presetsActions.savePreset.failure): {
			return {
				...state,
				savingState: LoadingState.ERROR,
			};
		}
		default: {
			return state;
		}
	}
}
