import {ChatAction} from "./redux/ChatAction";
import {ChatRoomAction} from "./redux/ChatRoomAction";
import {getType} from "typesafe-actions";
import analyticsService from "../analytics/AnalyticsService";

const trackedActions = [
	ChatAction.setConnectionStatus,
	ChatAction.bulkLoadChatHistoriesFromBE,
	ChatAction.lastInteractionTimesLoaded,
	ChatRoomAction.sendTextMessage,
	ChatRoomAction.markChatAsRead,
	ChatRoomAction.setInputText,
	ChatRoomAction.setSelectedMessage,
	ChatRoomAction.setTypingState,
	ChatRoomAction.getMoreChatHistory,
].map(getType);

const properties = (action: ChatAction.ChatActionType | ChatRoomAction.ChatRoomActionType) => {
	switch (action.type) {
		case getType(ChatRoomAction.setTypingState):
			return {
				typingState: action.payload.typingState,
			};

		default:
			return null;
	}
};

export const chatEvents = analyticsService.trackReduxActions(trackedActions, properties);
