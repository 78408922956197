import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {ClientFilter, ClientsFeedLabel, FeedFilter, UserFeed} from "../clientsFeedTypes";

export const clientsFeedActions = {
	/** load feeds */
	loadFeeds: createAsyncAction(
		"clientsFeedActions/loadFeeds",
		"clientsFeedActions/loadFeedsSuccess",
		"clientsFeedActions/loadFeedsFail",
	)<void, {label: ClientsFeedLabel; feeds: UserFeed[]}, Error>(),

	/** Set feed active label. Set page to first page and clear feedsMap data */
	setActiveLabel: createAction("clientsFeedActions/setActiveLabel", (label: ClientsFeedLabel) => label)(),

	/** Set hour range */
	setHourRange: createAction("clientsFeedActions/setHourRange", (hours: number) => ({hours}))(),

	/** Set feed registration filter */
	setRegistrationFilter: createAction("clientsFeedActions/setRegistrationFilter", (feedType: FeedFilter) => ({
		feedType,
	}))(),

	/** Set feed registration filter by client id */
	setClientFilter: createAction("clientsFeedActions/setClientFilter", (client: ClientFilter) => ({client}))(),

	/** Increment feed page */
	incrementPage: createAction("clientsFeedActions/incrementPage")(),

	/** Decrement feed page */
	decrementPage: createAction("clientsFeedActions/decrementPage")(),

	/** Go to specific page action */
	goToPage: createAction("clientsFeedActions/goToPage", (pageNumber: number) => ({pageNumber}))(),
};

export type ClientsFeedActionType = ActionType<typeof clientsFeedActions>;
