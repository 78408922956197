import {getFullName} from "../userProfile/helpers/profileHelpers";
import {FIRST_PAGE_NUMBER, SortKey, InactiveClient, SortByState} from "./inactiveClientTypes";

/**
 * Get first item number of clients feed page
 *
 * e.g {firstItemNumber} - 8 of 30
 *
 * @param {number} currentPage
 * @param {maxItemPerPage} maxItemPerPage
 */
export function getFirstItemNumber(currentPage: number, maxItemPerPage: number): number {
	return (currentPage - FIRST_PAGE_NUMBER) * maxItemPerPage + FIRST_PAGE_NUMBER;
}

/**
 * Get last item number of clients feed page
 *
 * e.g 1 - {lastItemNumber} of 30
 *
 * @param {number} currentPage
 * @param {number} maxItemPerPage
 * @param {number} totalItems
 */
export function getLastItemNumber(currentPage: number, maxItemPerPage: number, totalItems?: number): number {
	const lastItemNumber: number = getFirstItemNumber(currentPage, maxItemPerPage) + maxItemPerPage - FIRST_PAGE_NUMBER;
	if (totalItems && totalItems < lastItemNumber) {
		return totalItems;
	}
	return lastItemNumber;
}

/**
 * Sort inactive clients by specific keys.
 * @param inactiveClients List of inactive clients.
 * @param sortBy Keys to sort the list.
 */
export function sortInactiveClients(inactiveClients: InactiveClient[], sortBy: SortByState) {
	if (sortBy.key === SortKey.NAME) {
		return inactiveClients.sort((first, second) => {
			const firstComparatorName = getFullName(first.contact).toLowerCase();
			const secondComparatorName = getFullName(second.contact).toLowerCase();
			if (firstComparatorName < secondComparatorName) {
				return sortBy.asc ? -1 : 1; // return negative number to prioritize the 1st parameters, otherwise return positive number.
			}
			if (firstComparatorName > secondComparatorName) {
				return sortBy.asc ? 1 : -1; // return positive number to prioritize the 1st parameters, otherwise return negative number.
			}
			return 0;
		});
	}

	if (sortBy.key === SortKey.LATEST_REGISTRATION) {
		return inactiveClients.sort((first, second) => {
			if (first.latestRegistration === null) {
				// return positive number to unprioritize the 1st parameters, otherwise return negative number.
				return sortBy.asc ? 1 : -1;
			}
			if (second.latestRegistration === null) {
				// return negative number to unprioritize the 2nd parameters, otherwise return positive number.
				return sortBy.asc ? -1 : 1;
			}

			// sort in ascending order
			// 1 -> 100 days
			if (sortBy.asc) {
				return second.latestRegistration.getTime() - first.latestRegistration.getTime();
			}
			// sort in descending order
			// 100 -> 1 days
			return first.latestRegistration.getTime() - second.latestRegistration.getTime();
		});
	}
	return inactiveClients;
}
