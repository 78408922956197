/**
 * Author: leo Date: 23/01/2018
 */

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// lib imports
import autobind from "autobind-decorator";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// app imports
import {Injectable} from "../../IoC/Injectable";
import {ITracking, SenseTracking, SensorData, SensorDataResponse} from "@sense-os/goalie-js/dist/tracking";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";

export interface ITrackingService {
	/**
	 * Initializes the tracking service.
	 */
	init(): void;

	/** Direct access to the Tracking SDK */
	readonly sdk: ITracking;

	/**
	 * create or update sensor data
	 *
	 * @param {SensorData<T>} sensorData
	 * @param {number} userId
	 * @param {string} sensorId
	 */
	saveSensorData<T>(sensorData: SensorData<T>, userId: number, sensorId?: string): Promise<SensorDataResponse<T>>;
}

/**
 *
 */
@autobind
export class TrackingService implements ITrackingService, Injectable {
	public readonly c: string = "[TrackingService]";

	//TODO: remove unused class members

	/** Direct access to the methods of Chat SDK */
	private _sdk: ITracking;

	private log = createLogger("TrackingService", SentryTags.Tracking);

	/** @inheritDoc */
	public get sdk(): ITracking {
		if (!this._sdk) {
			this.log.debug(this.c, "The service is not ready. Call init()!");
			return;
		}
		return this._sdk;
	}

	/** @inheritDoc */
	public init(): void {
		this.log.debug(this.c, "initializing...");
		this._sdk = new SenseTracking();
		this._sdk.init(getSessionId());
	}

	/** @inheritdoc */
	public saveSensorData<T>(
		sensorData: SensorData<T>,
		userId: number,
		sensorId?: string,
	): Promise<SensorDataResponse<T>> {
		if (sensorId) {
			return this._sdk.putSensorData(sensorId, sensorData);
		}
		return this._sdk.postSensorData([sensorData], userId).then((res) => res[0]);
	}
}
