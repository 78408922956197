import {callActions, CallActionType} from "./redux/callActions";
import {getType} from "typesafe-actions";
import analyticsService from "../analytics/AnalyticsService";

const trackedActions = [
	// Call window actions
	callActions.openCallWindow.request,
	callActions.openCallWindow.success,
	callActions.openCallWindow.failure,
	callActions.closeCallWindow,

	// User actions
	callActions.startCall,
	callActions.startOutgoingCallByType,
	callActions.terminateCall,
	callActions.acceptCall,
	callActions.rejectCall,
	callActions.cancelCall,

	// Call timeout actions
	callActions.startTimeout,
	callActions.stopTimeout,
	callActions.stopAllTimeouts,

	// ActiveCall actions
	callActions.createActiveCall,
	callActions.userInitiateCall,
	callActions.userJoinCall,
	callActions.userLeaveCall,

	// Call signal actions
	callActions.handleInitiationSignal,
	callActions.handleAcceptSignal,
	callActions.handleRejectSignal,
	callActions.handleCancelSignal,
	callActions.handleTerminateSignal,
	callActions.handleAcceptRejectElsewhereSignal,

	// Misc actions
	callActions.setStreamingStartedDate,
	callActions.processRemoteUserLeavesCall,
	callActions.inviteNewParticipantToCall,
	callActions.callWindowCrashed,
].map(getType);

const properties = (action: CallActionType) => {
	switch (action.type) {
		default:
			return null;
	}
};

export const callEvents = analyticsService.trackReduxActions(trackedActions, properties);
