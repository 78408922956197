import {takeEvery, put} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {toastActions} from "../../toaster/redux/toastAction";

import {calendarActions} from "../redux/calendarActions";
import {calendarEventScheduleMap} from "../calendarTypes";

function* handleUndoAction(action: ActionType<typeof calendarActions.undoEventAction>) {
	// Remove the toast with undo button
	yield put(toastActions.removeToast(action.payload.undoId));

	// Cancel creation task to prevent portal to send creation API request to BE
	const calendarEventTask = calendarEventScheduleMap.get(action.payload.undoId);
	if (calendarEventTask) {
		calendarEventTask.cancel();
		calendarEventScheduleMap.delete(action.payload.undoId);
	}
}

export default function* () {
	yield takeEvery(getType(calendarActions.undoEventAction), handleUndoAction);
}
