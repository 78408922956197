import {useMemo} from "react";
import {checkAuthUserAccess, PortalFeatures} from "./userFeatureAccess";
import useAuthUser from "../auth/hooks/useAuthUser";

/**
 * Returns a callback function to check whether auth user can access a certain feature or not.
 */
export const useCheckAuthUserAccess = () => {
	const authUser = useAuthUser();
	const userAccessFn = useMemo(() => {
		if (!authUser) {
			return (_: PortalFeatures) => false;
		}
		return checkAuthUserAccess(authUser);
	}, [authUser]);

	return userAccessFn;
};
