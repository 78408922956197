import {ActionType} from "typesafe-actions";
import {psychoEducationActions} from "../redux/psychoEducationActions";
import {call, put} from "redux-saga/effects";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getReminderDateTime} from "../helpers/psychoEducationHelpers";
import moment from "moment";
import {toastActions} from "../../../toaster/redux";
import localization from "../../../localization/Localization";
import strTranslation from "../../../assets/lang/strings";
import {NotFoundError} from "@sense-os/goalie-js";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import createLogger from "../../../logger/createLogger";
import {CoreTrackerId} from "../../utils/coreTracker";
import {onAutoEnableTracker} from "../../customTracker/helpers/onAutoEnableTracker";
import {createPsychoEducationTask} from "./createPsychoEducationTaskSaga";
import {updatePsychoEducationTask} from "./updatePsychoEducationTaskSaga";

const log = createLogger("savePsychoEducationSaga", SentryTags.PsychoEducation);

/**
 * Save Psychoeducation Task Saga
 */
export function* savePsychoEducationTask(
	action: ActionType<typeof psychoEducationActions.savePsychoEducationTask.request>,
) {
	const {userId, formValues, psychoEducationId, taskId} = action.payload;

	try {
		const token: string = yield call(getSessionId);
		// Set `remindedDateTime` to `null` if `reminderToggle` is not enabled
		const reminderDateTime: Date = yield call(getReminderDateTime, formValues.reminderToggle && formValues);
		const isEditing: boolean = !!psychoEducationId;
		// Show warning toast when `reminderDateTime` is before creation date
		if (moment(reminderDateTime).isBefore()) {
			yield put(
				toastActions.addToast({
					message: localization.formatMessage(strTranslation.VALIDATION.reminder_must_be_in_future),
					type: "warning",
				}),
			);
			yield put(psychoEducationActions.savePsychoEducation.failure(new Error()));
			return;
		}

		// handle the creation
		if (!isEditing) {
			yield call(createPsychoEducationTask, {
				token,
				formValues,
				reminderDateTime,
				userId,
			});
		}

		// handle the updating
		if (isEditing) {
			yield call(updatePsychoEducationTask, {
				token,
				formValues,
				reminderDateTime,
				psychoEducationId,
				taskId,
			});
		}

		/**
		 * Auto enable psycho education if it's disabled after create a new registration
		 */
		yield call(onAutoEnableTracker, {
			userId,
			trackerId: CoreTrackerId.PSYCHOEDUCATION,
		});

		yield put(psychoEducationActions.savePsychoEducationTask.success({userId}));
		yield put(psychoEducationActions.closeForm());

		// the toast depends on whether creating or editing
		const selectedToast: string = isEditing
			? strTranslation.GRAPHS.new_event.psycho_education.toast.update.success
			: strTranslation.GRAPHS.new_event.psycho_education.toast.create.success;

		yield put(
			toastActions.addToast({
				message: localization.formatMessage(selectedToast),
				type: "info",
			}),
		);
	} catch (error) {
		log.captureException(error);
		yield put(psychoEducationActions.savePsychoEducation.failure(error));

		if (error instanceof NotFoundError) {
			yield put(
				toastActions.addToast({
					message: localization.formatMessage(
						strTranslation.GRAPHS.event_detail.psycho_education.toast.not_found,
					),
					type: "error",
				}),
			);
		} else {
			yield put(
				toastActions.addToast({
					message: localization.formatMessage(strTranslation.GRAPHS.new_event.psycho_education.toast.error),
					type: "error",
				}),
			);
		}
	}
}
