import {createSelector} from "reselect";
import {AppState} from "../../../ts/redux/AppState";
import {getContactNameById, getSelectedContactId} from "../../../contacts/redux/contactSelectors";
import {PSYCHOEDUCATION_FORM_TYPE, PsychoEducationFormValues} from "../psychoEducationTypes";
import {LoadingState} from "constants/redux";
import {PsychoEducationItem} from "@sense-os/goalie-js";

const getPsychoEducationState = (state: AppState) => state.psychoEducation;
export const isFormOpened = createSelector(getPsychoEducationState, (state): boolean => state.isFormOpened);

export const getSavingStatePsychoEducationForm = (state: AppState): LoadingState => {
	return getPsychoEducationState(state).savingState;
};

const getSavedFormValues = (state: AppState) => state.psychoEducation.savedFormValues;

export const getSavedFormValue = createSelector(
	getSelectedContactId,
	getSavedFormValues,
	(userId, savedFormValues): PsychoEducationFormValues => savedFormValues[userId],
);

export const getPsychoEducationData = (state: AppState): PsychoEducationItem => {
	return getPsychoEducationState(state).psychoEducationData;
};
export const getPsychoEducationId = (state: AppState) => {
	return getPsychoEducationState(state).id;
};

const getPatientId = (state: AppState): number => state.psychoEducation.psychoEducationData?.patient;

export const getPatientName = (state: AppState): string => {
	const patientId = getPatientId(state);

	return getContactNameById(patientId)(state);
};

export const getPsychoEducationFormType = (state: AppState): PSYCHOEDUCATION_FORM_TYPE =>
	getPsychoEducationState(state).formType;

export const getPsychoEducationFetchState = (state: AppState): LoadingState => {
	return getPsychoEducationState(state).psychoEducationFetchState;
};

export const getRemovedArticleIds = createSelector(getPsychoEducationState, (state) => state.removedArticleIds);

export const getSelectedPsychoEdUserId = (state: AppState): number => {
	return getPsychoEducationState(state).userId;
};

export const getDeletingStatePsychoEducation = (state: AppState): LoadingState => {
	return getPsychoEducationState(state).deletingState;
};
