import {ChatState, ChatStateType} from "@sense-os/goalie-js";
import {eventChannel, Task} from "redux-saga";
import {fork, cancelled, take, select, put, delay} from "redux-saga/effects";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import chatSDK from "../sdk";
import {getAuthUser} from "../../auth/redux";
import {AuthUser} from "../../auth/authTypes";
import {ChatRoomAction} from "../redux/ChatRoomAction";

export const TYPING_TIMEOUT_MS = 7000;
const log = createLogger("chatStateSaga", SentryTags.Chat);

const createChatStateChangesChannel = () => {
	return eventChannel<ChatState>((emitter) => {
		const subscriptionId = chatSDK.subscribeToChatStateChanges(emitter);
		return () => {
			chatSDK.unsubscribeFromChatStateChanges(subscriptionId);
		};
	});
};

export function* chatStateChangesSubscriptionHandler() {
	const channel = createChatStateChangesChannel();
	try {
		while (true) {
			const chatState: ChatState = yield take(channel);
			yield fork(chatStateChangesHandler, chatState);
		}
	} finally {
		if (yield cancelled()) {
			channel.close();
		}
	}
}

/**
 * Chat state timeout Task mapped by userId.
 * Each userId will hold a Saga Task to change user's chat state to `Inactive` within designated time
 */
const chatStateTimeoutMap: Record<string, Task> = {};

export function* chatStateChangesHandler(chatState: ChatState) {
	log.debug(chatState);
	const authUser: AuthUser = yield select(getAuthUser);
	if (chatState.from === authUser.id) {
		log.debug("Ignoring chat state from authUser", chatState.from);
		return;
	}

	if (chatStateTimeoutMap[chatState.from]) {
		// Cancels deprecation task
		log.debug("Cancelling chat state timeout", chatState.from);
		chatStateTimeoutMap[chatState.from].cancel();
		delete chatStateTimeoutMap[chatState.from];
	}

	yield put(ChatRoomAction.setTypingState(chatState.from, chatState.state));

	// Deprecate chatState from redux for specific user within designated time
	chatStateTimeoutMap[chatState.from] = yield fork(function* chatTypingTimeout() {
		yield delay(TYPING_TIMEOUT_MS);
		log.debug("Chat state timed out", chatState.from);
		yield put(ChatRoomAction.setTypingState(chatState.from, ChatStateType.Inactive));
	});
}
