import {TaskItemResponse, TaskStatus} from "@sense-os/goalie-js";
import {createEventViewId} from "redux/tracking/TrackingHelper";
import {EventViewType, Sensors, TaskEventView} from "redux/tracking/TrackingTypes";

// Transform TaskItem to be able shown in client's activity
export function transformTaskItemIntoEventViewData(item: TaskItemResponse<unknown>): TaskEventView {
	/*
	 * if task hasn't completed or cancelled, the occurrence time time is the planned time (expiredAt)
	 * else, the occurrence time is the updated time since task doesn't have completed time and cancelled time
	 */
	const occurTime: Date = item.status === TaskStatus.UNCOMPLETED ? item.expiresAt : item.statusUpdatedAt;

	return {
		id: createEventViewId(EventViewType.TASK, item.id.toString(), item.createdAt),
		ownerId: item.patient,
		createdBy: item.createdBy,
		title: item.title,
		startTime: occurTime,
		endTime: occurTime,
		type: EventViewType.TASK,
		isCompleted: item.status === TaskStatus.COMPLETED,
		isCanceled: item.status === TaskStatus.CANCELED,
		createdAt: item.createdAt,
		updatedAt: item.updatedAt,
		sensors: [Sensors.TASK],
		source: {
			...item,
			expiresAt: item.expiresAt,
		},
	};
}
