import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {CustomExercisePostBodyRequest, TaskPostBodyRequest} from "@sense-os/goalie-js";
import moment from "moment";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import taskSDK from "../../../taskRegistration/helpers/taskSDK";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {customExerciseActions} from "../redux/customExerciseActions";
import customExerciseSDK from "../helper/customExerciseSDK";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getTaskReminderDateTime} from "../../../taskRegistration/helpers/common";
import {toastActions} from "../../../toaster/redux";
import strTranslation from "../../../assets/lang/strings";

const log = createLogger("editCustomExerciseSaga", SentryTags.CustomExercise);

/**
 * To edit Custom exercise, we must:
 * - Edit custom exercise
 * - Edit Task
 */
function* editCustomExercise(action: ActionType<typeof customExerciseActions.editCustomExercise.request>) {
	const {formValues, userId, taskId, registrationId} = action.payload;
	const token = yield call(getSessionId);

	// Set `remindedDateTime` to `null` if `reminderToggle` is not enabled
	const reminderDateTime: Date = yield call(getTaskReminderDateTime, formValues?.reminderSwitchButton && formValues);

	try {
		// custom exercise
		const customExerciseBody: CustomExercisePostBodyRequest = {
			content: formValues.content,
		};

		yield apiCallSaga(customExerciseSDK.updateCustomExercise, token, registrationId, customExerciseBody);

		// edit Task
		const taskBody: TaskPostBodyRequest<unknown> = {
			title: formValues.title,
			expiresAt: formValues.expiresAtSwitchButton ? moment(formValues.expiresAt).toDate() : null,
			remindAt: reminderDateTime,
			recurrence: formValues.reminderRepeatOptionSwitchButton ? formValues.reminderRepeatOption : undefined,
		};

		yield apiCallSaga(taskSDK.updateTask, token, taskId, taskBody);

		yield put(customExerciseActions.editCustomExercise.success({userId}));
		yield put(
			toastActions.addToast({
				message: strTranslation.CUSTOM_EXERCISE.edit.alert.success,
				type: "info",
			}),
		);
		yield put(customExerciseActions.closeForm());
	} catch (error) {
		log.captureException(error);
		yield put(toastActions.addToast({message: strTranslation.CUSTOM_EXERCISE.edit.alert.fail, type: "error"}));
		yield put(customExerciseActions.editCustomExercise.failure(error));
	}
}

export default function* () {
	yield takeEvery(getType(customExerciseActions.editCustomExercise.request), editCustomExercise);
}
