import {
	CallSignal,
	CallStatus,
	CallSDK,
	InitiationCallSignal,
	ProceedCallSignal,
	ResponseCallSignal,
	TerminationCallSignal,
} from "@sense-os/goalie-js";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {States} from "services/chat/video/States";
import {getCallStateFromStorage} from "./callHelpers";

/**
 * Returns true if signal can be processed.
 */
export function canProcessSignal(signal: CallSignal, activeCall: ActiveCall | null): boolean {
	const isNewIncomingCall: boolean = !activeCall && isInitiationSignal(signal);
	const isSameRoomId: boolean = activeCall?.roomId === signal.roomId;
	const isUserAvailable: boolean = isUserAvailableForCall();
	const isSdkSupported = isInitiationSignal(signal) && isCompatibleSDK(signal.availableCallSDKs);

	return isSameRoomId || (isNewIncomingCall && isUserAvailable && isSdkSupported);
}

/**
 * Returns true if the signal was sent by userId,
 * and call status is not accepted or rejected elsewhere.
 */
export function shouldIgnoreSignal(signal: CallSignal, userId: number) {
	return isSignalSentByUser(signal, userId) && !isProcessedElsewhereSignal(signal);
}

export function isInitiationSignal(signal: CallSignal): signal is InitiationCallSignal {
	return signal.callStatus === CallStatus.Initiated;
}

export function isAcceptedSignal(signal: CallSignal): signal is ProceedCallSignal {
	return signal.callStatus === CallStatus.Accepted;
}

export function isRejectedSignal(signal: CallSignal): signal is ResponseCallSignal {
	return signal.callStatus === CallStatus.Rejected;
}

export function isCanceledSignal(signal: CallSignal): signal is InitiationCallSignal {
	return signal.callStatus === CallStatus.Cancelled;
}

export function isTerminationSignal(signal: CallSignal): signal is TerminationCallSignal {
	return signal.callStatus === CallStatus.Terminated;
}

export function isProcessedElsewhereSignal(signal: CallSignal): signal is ResponseCallSignal {
	return [CallStatus.AcceptedElsewhere, CallStatus.RejectedElsewhere].includes(signal.callStatus);
}

/**
 * True if call state in local storage is either of theses:
 * States.INITIALIZED, States.HUNG_UP
 */
export function isUserAvailableForCall() {
	const idleCallStates = [States.INITIALIZED, States.HUNG_UP];
	const callState = getCallStateFromStorage();
	if (!callState) {
		return true;
	}
	return idleCallStates.indexOf(callState) > -1;
}

export function isUserInCall() {
	return !isUserAvailableForCall();
}

export function isCompatibleSDK(sdk: CallSDK[] = []) {
	return sdk.includes(CallSDK.Twilio);
}

export function isSignalInitiatedToUser(signal: CallSignal, userId: number) {
	return isInitiationSignal(signal) && signal.recipientId === userId;
}

export function isSignalSentByUser(signal: CallSignal, userId: number) {
	return signal.senderId === userId;
}
