import {ThoughtRecord, remoteThoughtRecordSensorToThoughtRecordSensor} from "@sense-os/goalie-js";
import {DiaryEntry, ResolvedSensorData, SensorDatum} from "redux/tracking/TrackingTypes";
import {isThoughtRecordSensor} from "../../../clientActivity/helpers/sensorDataActivityHelpers";

/**
 * Filter out thought records which attach to Diary entries or Planned events.
 *
 * @param thoughtRecords
 * @param diaryEntries
 * @param recurringPlannedEvents
 */
export function getFilteredThoughtRecords(
	thoughtRecords: SensorDatum<ThoughtRecord>[],
	diaryEntries: SensorDatum<DiaryEntry>[],
): SensorDatum<ThoughtRecord>[] {
	const shouldShowThoughtRecord = (thoughtRecord: SensorDatum<ThoughtRecord>) => {
		const isExistInDiaryEntry = diaryEntries.some((diaryEntry) =>
			isThoughtRecordAttachedToDiaryEntry(thoughtRecord, diaryEntry),
		);

		return !isExistInDiaryEntry;
	};
	return thoughtRecords.filter(shouldShowThoughtRecord);
}

export function isThoughtRecordAttachedToDiaryEntry(
	thoughtRecord: SensorDatum<ThoughtRecord>,
	diaryEntry: SensorDatum<DiaryEntry>,
): boolean {
	return diaryEntry.value.gscheme?.sensorData.id === thoughtRecord.id;
}

/**
 * Transforms Diary Entry's ThoughtRecord (`gscheme` version 2) so that we can use it.
 * This because the data that we get from the server doesn't actually match the interface provided by the SDK.
 * Therefore we need to manually convert it here..
 *
 * @see remoteThoughtRecordSensorToThoughtRecordSensor
 *
 * @param plannedEvent
 */
export function transformDiaryEntryGscheme(diaryEntry: SensorDatum<DiaryEntry>): SensorDatum<DiaryEntry> {
	return {
		...diaryEntry,
		value: {
			...diaryEntry.value,
			gscheme:
				diaryEntry.value.gscheme && isThoughtRecordSensor(diaryEntry.value.gscheme?.sensorData)
					? ({
							sensorData: remoteThoughtRecordSensorToThoughtRecordSensor(
								diaryEntry.value.gscheme.sensorData as any,
							),
					  } as ResolvedSensorData<ThoughtRecord>)
					: diaryEntry.value.gscheme,
		},
	};
}
