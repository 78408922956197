import {LanguageTag} from "@sense-os/goalie-js";

export enum PresetPsychoEducationTag {
	BURNOUT = "burnout",
	PANIC_ATTACKS_REGISTRATION = "panic_attacks_registration",
	TRACK_OF_SYMPTOMS = "track_of_symptoms",
	DEPRESSION = "depression",
	VICIOUS_BEHAVIOUR = "vicious_behaviour",
	COGNITIVE_THERAPY_AND_VICIOUS_THOUGHTS = "cognitive_therapy_and_vicious_thoughts",
	ACTIVITY_AND_LIFESTYLE = "activity_and_lifestyle",
	SOMEONE_SUFFERING_FROM_DEPRESSION = "someone_suffering_from_depression",
	SUICIDAL = "suicidal",
	DYSTHYMIC_DISCORDER = "dysthymic_discorder",
	DYSTHYMIA = "dysthymia",
	GRIEF = "grief",
	GRIEVING_PROCESS = "grieving_process",
	GRIEF_INFORMATION = "grief_information",
	PSYCHO_EDUCATION_ANXIETY = "psycho_education_anxiety",
	EMERGENCE_AND_NORMALIZATION_ANXIETY = "emergence_and_normalization_anxiety",
	GENERALISED_ANXIETY_DISORDER = "generalised_anxiety_disorder",
	METACOGNITIVE_MODEL = "metacognitive_model",
	WORRYING_AND_META_COGNITIONS = "worrying_and_meta_cognitions",
	PSYCHO_EDUCATION_FOR_SOCIAL_NETWORK = "psycho_education_for_social_network",
	INTOOLERANCE_UNCERTAINTY = "intoolerance_uncertainty",
	SOCIAL_ANXIETY_DISORDER = "social_anxiety_disorder",
	SELF_IMAGE_AND_SOCIAL_ANXIETY = "self_image_and_social_anxiety",
	PSYCHOEDUCATION_PANIC = "psychoeducation_panic",
	AGORAPHOBIA = "agoraphobia",
	SPECIFIC_PHOBIA = "specific_phobia",
	BLOOD_INJECTION_INJURY_PHOBIA = "blood_injection_injury_phobia",
	ANIMAL_PHOBIA = "animal_phobia",
	HYPOCHONDRIA = "hypochondria",
	ANXIETY_DISORDER_COGNITIONS_BEHAVIOUR = "anxiety_disorder_cognitions_behaviour",
	OBSESSIVE_COMPULSIVE_DISORDER = "obsessive_compulsive_disorder",
	COMPULSIVE_DISORDER = "compulsive_disorder",
	OBSESSION_OF_PEOPLE_WITHOUT_OCD = "obsession_of_people_without_ocd",
	PSYCHO_EDUCATION_TRAUMA = "psycho_education_trauma",
	REACT_TRAUMA_TREATMENT = "react_trauma_treatment",
	COPE_EMOTIONS_DURING_TRAUMA_TREATMENT = "cope_emotions_during_trauma_treatment",
	INFORMATION_OF_SOCIAL_NETWORK = "information_of_social_network",
	ONLINE_TRAUMA_TREATMENT = "online_trauma_treatment",
	TRAUMA_TREATMENT_IMAGINARY_EXPOSURE = "trauma_treatment_imaginary_exposure",
	EMDR = "emdr",
	STRESS_TO_BURNOUT = "stress_to_burnout",
	SYMPTOMS_OF_BURNOUT = "symptoms_of_burnout",
	TREATMENT_OF_BURNOUT = "treatment_of_burnout",
	SUPPORT_SOMEONE_BURNOUT = "support_someone_burnout",
	SHAME_GUILT_WITH_BURNOUT = "shame_guilt_with_burnout",
	PERSONALITY_AND_BURNOUT = "personality_and_burnout",
	ADHD = "adhd",
	SENSORY_WORK_ADHD = "sensory_work_adhd",
	TREATMENT_OF_ADHD = "treatment_of_adhd",
	OVERSTIMULATION = "overstimulation",
	ENVIRONMENT_OF_ADHD = "environment_of_adhd",
	STRENGTHS_PEOPLE_WITH_ADHD = "strengths_people_with_adhd",
	ADDICTION = "addiction",
	ADDICTION_ADVANTAGES_DISADVANTAGES = "addiction_advantages_disadvantages",
	ADDICTION_SOCIAL_ENVIRONMENT = "addiction_social_environment",
	FEELING_HALT = "feeling_halt",
	EXPERIENCE_CRAVING = "experience_craving",
	CBT = "cbt",
	THOUGHT_RECORD = "thought_record",
	THOUGHT_CHALLENGING = "thought_challenging",
	CBT_RULES_FOR_LIVING = "cbt_rules_for_living",
	CBT_THINKING_STYLES = "cbt_thinking_styles",
	CBT_CORE_BELIEFS = "cbt_core_beliefs",
	CBT_BEHAVIORAL_EXPERIMENT = "cbt_behavioral_experiment",
	RELAXATION_MINDFULNESS = "relaxation_mindfulness",
	PILLARS_OF_MINDFULNESS = "pillars_of_mindfulness",
	OVERCOMING_MINDFULNESS_PRACTICE = "overcoming_mindfulness_practice",
	REACTION_TO_STRESS = "reaction_to_stress",
	MINDFULNESS_WORK = "mindfulness_work",
	TURN_OFF_AUTOPILOT = "turn_off_autopilot",
	RELAXATION_BODY_SCAN = "relaxation_body_scan",
	RELAXATION_BREATHING_SPACE = "relaxation_breathing_space",
	IMPORTANCE_RELAXATION = "importance_relaxation",
	RELAXATION_EXERCISE = "relaxation_exercise",
	TRAUMANET = "traumanet",
	OUR_THOUGHTS_INFLUENCE_US = "our_thoughts_influence_us",
	PHYSICAL_RESPONSE_TO_STRESS = "physical_response_to_stress",
}

type PresetPsychoEducationContentType = Record<
	LanguageTag.EN | LanguageTag.NL,
	{
		title: string;
		link: string;
	}
>;
type PresetPsychoEducationMapType = Record<PresetPsychoEducationTag, PresetPsychoEducationContentType>;

export const presetPsychoEducationMap: PresetPsychoEducationMapType = {
	[PresetPsychoEducationTag.BURNOUT]: {
		[LanguageTag.NL]: {
			title: "Wat is een burn-out?",
			link: "https://niceday.app/library/burn-out/burn-out-en/wat-is-een-burn-out/",
		},
		[LanguageTag.EN]: {
			title: "What is a burnout?",
			link: "https://niceday.app/en/library/burn-out/burn-out-en/what-is-a-burnout/",
		},
	},
	[PresetPsychoEducationTag.PANIC_ATTACKS_REGISTRATION]: {
		[LanguageTag.NL]: {
			title: "Registratie van paniekaanvallen",
			link: "https://niceday.app/library/angst/paniekstoornis/registratie-van-paniekaanvallen/",
		},
		[LanguageTag.EN]: {
			title: "Registration of panic attacks",
			link: "https://niceday.app/library/angst/paniekstoornis/registratie-van-paniekaanvallen/",
		},
	},
	[PresetPsychoEducationTag.TRACK_OF_SYMPTOMS]: {
		[LanguageTag.NL]: {
			title: "Oefening: bijhouden van klachten",
			link: "https://niceday.app/library/burn-out/oefening-bijhouden-van-klachten/",
		},
		[LanguageTag.EN]: {
			title: "Exercise: Keeping track of symptoms",
			link: "https://niceday.app/en/library/burn-out/exercises-en/exercise-keeping-track-of-symptoms/",
		},
	},
	[PresetPsychoEducationTag.DEPRESSION]: {
		[LanguageTag.NL]: {
			title: "Wat is een depressie?",
			link: "https://niceday.app/library/depressie/algemene-uitleg-stemmingsklachten/wat-is-een-depressie/",
		},
		[LanguageTag.EN]: {
			title: "Depression explanation",
			link: "https://niceday.app/en/library/depression/general-explanations/depression-explanation/",
		},
	},
	[PresetPsychoEducationTag.VICIOUS_BEHAVIOUR]: {
		[LanguageTag.NL]: {
			title: "De vicieuze gedragscirkel",
			link: "https://niceday.app/library/depressie/cognitieve-gedragstherapie/de-vicieuze-gedragscirkel/",
		},
		[LanguageTag.EN]: {
			title: "The vicious circle of behaviour",
			link: "https://niceday.app/en/library/depression/cognitive-behavioural-therapy/the-vicious-circle-of-behavior/",
		},
	},
	[PresetPsychoEducationTag.COGNITIVE_THERAPY_AND_VICIOUS_THOUGHTS]: {
		[LanguageTag.NL]: {
			title: "Cognitieve therapie en de vicieuze gedachtencirkel",
			link: "https://niceday.app/library/depressie/cognitieve-gedragstherapie/cognitieve-therapie-en-de-vicieuze-gedachtencirkel/",
		},
		[LanguageTag.EN]: {
			title: "Explanation of cognitive therapy and vicious circle of thoughts",
			link: "https://niceday.app/en/library/depression/cognitive-behavioural-therapy/explanation-of-cognitive-therapy-and-vicious-circle-of-thoughts/",
		},
	},
	[PresetPsychoEducationTag.ACTIVITY_AND_LIFESTYLE]: {
		[LanguageTag.NL]: {
			title: "Activatie en Leefstijl",
			link: "https://niceday.app/library/leefstijl/activatie-en-leefstijl/",
		},
		[LanguageTag.EN]: {
			title: "Activity and Lifestyle",
			link: "https://niceday.app/en/library/lifestyle-and-nutrition/activity-and-lifestyle/",
		},
	},
	[PresetPsychoEducationTag.SOMEONE_SUFFERING_FROM_DEPRESSION]: {
		[LanguageTag.NL]: {
			title: "Informatie en tips als iemand in je omgeving een depressie heeft",
			link: "https://niceday.app/library/depressie/algemene-uitleg-stemmingsklachten/informatie-en-tips-als-iemand-in-je-omgeving-een-depressie-heeft/",
		},
		[LanguageTag.EN]: {
			title: "Information and tips if someone in your social circle is suffering from depression",
			link: "https://niceday.app/en/library/depression/general-explanations/information-and-tips-if-someone-in-your-social-circle-is-suffering-from-depression/",
		},
	},
	[PresetPsychoEducationTag.SUICIDAL]: {
		[LanguageTag.NL]: {
			title: "Suïcidegedachten",
			link: "https://niceday.app/library/depressie/algemene-uitleg-stemmingsklachten/suicidegedachten/",
		},
		[LanguageTag.EN]: {
			title: "Suicidal thoughts",
			link: "https://niceday.app/en/library/depression/general-explanations/suicidal-thoughts/",
		},
	},
	[PresetPsychoEducationTag.DYSTHYMIC_DISCORDER]: {
		[LanguageTag.NL]: {
			title: "Wat is een dysthyme stoornis?",
			link: "https://niceday.app/library/depressie/dysthyme-stoornis/wat-is-een-dysthyme-stoornis/",
		},
		[LanguageTag.EN]: {
			title: "What is dysthymic disorder?",
			link: "https://niceday.app/en/library/depression/dysthymic-disorder/what-is-dysthymic-disorder/",
		},
	},
	[PresetPsychoEducationTag.DYSTHYMIA]: {
		[LanguageTag.NL]: {
			title: "Behandeling van dysthymie",
			link: "https://niceday.app/library/depressie/dysthyme-stoornis/behandeling-van-dysthymie/",
		},
		[LanguageTag.EN]: {
			title: "Treatment of Dysthymia",
			link: "https://niceday.app/en/library/depression/dysthymic-disorder/treatment-of-dysthymia/",
		},
	},
	[PresetPsychoEducationTag.GRIEF]: {
		[LanguageTag.NL]: {
			title: "Psycho-educatie Rouw",
			link: "https://niceday.app/library/depressie/interpersoonlijke-psychotherapie/rouw/psycho-educatie-rouw/",
		},
		[LanguageTag.EN]: {
			title: "What is grief?",
			link: "https://niceday.app/en/library/depression/interpersonal-psychotherapy/grief/what-is-grief/",
		},
	},
	[PresetPsychoEducationTag.GRIEVING_PROCESS]: {
		[LanguageTag.NL]: {
			title: "Doelen en stappen in jouw rouwproces",
			link: "https://niceday.app/library/depressie/interpersoonlijke-psychotherapie/rouw/doelen-en-stappen-in-jouw-rouwproces/",
		},
		[LanguageTag.EN]: {
			title: "Goals and steps during your grieving process",
			link: "https://niceday.app/en/library/depression/interpersonal-psychotherapy/grief/goals-and-steps-during-your-grieving-process/",
		},
	},
	[PresetPsychoEducationTag.GRIEF_INFORMATION]: {
		[LanguageTag.NL]: {
			title: "Informatie voor jouw omgeving over rouw",
			link: "https://niceday.app/library/depressie/interpersoonlijke-psychotherapie/rouw/informatie-voor-jouw-omgeving-over-rouw/",
		},
		[LanguageTag.EN]: {
			title: "Information for your surroundings about grief",
			link: "https://niceday.app/en/library/depression/interpersonal-psychotherapy/grief/information-for-your-surroundings-about-grief/",
		},
	},
	[PresetPsychoEducationTag.PSYCHO_EDUCATION_ANXIETY]: {
		[LanguageTag.NL]: {
			title: "Angst, vermijding en veiligheidsgedrag",
			link: "https://niceday.app/library/angst/algemene-informatie/angst-vermijding-en-veiligheidsgedrag/",
		},
		[LanguageTag.EN]: {
			title: "Psycho-education Anxiety",
			link: "https://niceday.app/en/library/anxiety/general-anxiety-information/psycho-education-anxiety/",
		},
	},
	[PresetPsychoEducationTag.EMERGENCE_AND_NORMALIZATION_ANXIETY]: {
		[LanguageTag.NL]: {
			title: "Het ontstaan en normaliseren van angst",
			link: "https://niceday.app/library/angst/algemene-informatie/het-ontstaan-en-normaliseren-van-angst/",
		},
		[LanguageTag.EN]: {
			title: "The emergence and normalization of anxiety",
			link: "https://niceday.app/en/library/anxiety/general-anxiety-information/the-emergence-and-normalization-of-anxiety/",
		},
	},
	[PresetPsychoEducationTag.GENERALISED_ANXIETY_DISORDER]: {
		[LanguageTag.NL]: {
			title: "Gegeneraliseerde angst",
			link: "https://niceday.app/library/angst/gegeneraliseerde-angststoornis/gegeneraliseerde-angst/",
		},
		[LanguageTag.EN]: {
			title: "Generalised anxiety disorder",
			link: "https://niceday.app/en/library/anxiety/generalized-anxiety-disorder/generalised-anxiety-disorder/",
		},
	},
	[PresetPsychoEducationTag.METACOGNITIVE_MODEL]: {
		[LanguageTag.NL]: {
			title: "Metacognitieve therapie",
			link: "https://niceday.app/library/angst/gegeneraliseerde-angststoornis/metacognitieve-therapie/",
		},
		[LanguageTag.EN]: {
			title: "The metacognitive model",
			link: "https://niceday.app/en/library/anxiety/generalized-anxiety-disorder/the-metacognitive-model/",
		},
	},
	[PresetPsychoEducationTag.WORRYING_AND_META_COGNITIONS]: {
		[LanguageTag.NL]: {
			title: "Piekeren en metacognities",
			link: "https://niceday.app/library/angst/gegeneraliseerde-angststoornis/piekeren-en-metacognities/",
		},
		[LanguageTag.EN]: {
			title: "Worrying and meta-cognitions",
			link: "https://niceday.app/en/library/anxiety/generalized-anxiety-disorder/worrying-and-meta-cognitions/",
		},
	},
	[PresetPsychoEducationTag.PSYCHO_EDUCATION_FOR_SOCIAL_NETWORK]: {
		[LanguageTag.NL]: {
			title: "Informatie en tips als iemand in je omgeving een angststoornis heeft",
			link: "https://niceday.app/library/angst/algemene-informatie/informatie-en-tips-als-iemand-in-je-omgeving-een-angststoornis-heeft/",
		},
		[LanguageTag.EN]: {
			title: "Psychoeducation for the social network of someone with an anxiety disorder",
			link: "https://niceday.app/en/library/anxiety/general-anxiety-information/psychoeducation-for-the-social-network-of-someone-with-an-anxiety-disorder/",
		},
	},
	[PresetPsychoEducationTag.INTOOLERANCE_UNCERTAINTY]: {
		[LanguageTag.NL]: {
			title: "Intolerantie voor onzekerheid",
			link: "https://niceday.app/library/angst/gegeneraliseerde-angststoornis/intolerantie-voor-onzekerheid/",
		},
		[LanguageTag.EN]: {
			title: "Intolerance of Uncertainty",
			link: "https://niceday.app/en/library/anxiety/generalized-anxiety-disorder/intolerance-of-uncertainty/",
		},
	},
	[PresetPsychoEducationTag.SOCIAL_ANXIETY_DISORDER]: {
		[LanguageTag.NL]: {
			title: "Wat is sociale angst?",
			link: "https://niceday.app/library/angst/sociale-angststoornis/wat-is-sociale-angst/",
		},
		[LanguageTag.EN]: {
			title: "Social Anxiety Disorder",
			link: "https://niceday.app/en/library/anxiety/social-anxiety-disorder/social-anxiety-disorder/",
		},
	},
	[PresetPsychoEducationTag.SELF_IMAGE_AND_SOCIAL_ANXIETY]: {
		[LanguageTag.NL]: {
			title: "Zelfbeeld sociale angst",
			link: "https://niceday.app/library/angst/sociale-angststoornis/zelfbeeld-sociale-angst/",
		},
		[LanguageTag.EN]: {
			title: "Self-image and Social Anxiety",
			link: "https://niceday.app/en/library/anxiety/social-anxiety-disorder/self-image-and-social-anxiety/",
		},
	},
	[PresetPsychoEducationTag.PSYCHOEDUCATION_PANIC]: {
		[LanguageTag.NL]: {
			title: "Paniekaanval",
			link: "https://niceday.app/library/angst/paniekstoornis/paniekaanval/",
		},
		[LanguageTag.EN]: {
			title: "Psycho-education Panic Disorder",
			link: "https://niceday.app/en/library/anxiety/panic-disorder/psycho-education-panic-disorder/",
		},
	},
	[PresetPsychoEducationTag.AGORAPHOBIA]: {
		[LanguageTag.NL]: {
			title: "Agorafobie (straatvrees)",
			link: "https://niceday.app/library/angst/paniekstoornis/agorafobie/",
		},
		[LanguageTag.EN]: {
			title: "Agoraphobia (street fear)",
			link: "https://niceday.app/library/angst/paniekstoornis/agorafobie/",
		},
	},
	[PresetPsychoEducationTag.SPECIFIC_PHOBIA]: {
		[LanguageTag.NL]: {
			title: "Specifieke fobie",
			link: "https://niceday.app/library/angst/specifieke-fobie/specifieke-fobie/",
		},
		[LanguageTag.EN]: {
			title: "Specific Phobia",
			link: "https://niceday.app/en/library/anxiety/specific-phobia/specific-phobia/",
		},
	},
	[PresetPsychoEducationTag.BLOOD_INJECTION_INJURY_PHOBIA]: {
		[LanguageTag.NL]: {
			title: "Bloed-injectie-letselfobie",
			link: "https://niceday.app/library/angst/specifieke-fobie/bloed-injectie-letselfobie/",
		},
		[LanguageTag.EN]: {
			title: "Blood-injection-injury phobia",
			link: "https://niceday.app/en/library/anxiety/specific-phobia/blood-injection-injury-phobia/",
		},
	},
	[PresetPsychoEducationTag.ANIMAL_PHOBIA]: {
		[LanguageTag.NL]: {
			title: "Dierenfobie",
			link: "https://niceday.app/library/angst/specifieke-fobie/dierenfobie/",
		},
		[LanguageTag.EN]: {
			title: "Animal Phobia",
			link: "https://niceday.app/en/library/anxiety/specific-phobia/animal-phobia/",
		},
	},
	[PresetPsychoEducationTag.HYPOCHONDRIA]: {
		[LanguageTag.NL]: {
			title: "Angst voor (ernstige) ziekten (hypochondrie)",
			link: "https://niceday.app/library/angst/ziekteangststoornis/angst-voor-ernstige-ziekten-hypochondrie/",
		},
		[LanguageTag.EN]: {
			title: "Illness Anxiety Disorder (hypochondria)",
			link: "https://niceday.app/en/library/anxiety/illness-anxiety-disorder/illness-anxiety-disorder-hypochondria/",
		},
	},
	[PresetPsychoEducationTag.ANXIETY_DISORDER_COGNITIONS_BEHAVIOUR]: {
		[LanguageTag.NL]: {
			title: "Ziekteangst cognities en gedrag",
			link: "https://niceday.app/library/angst/ziekteangststoornis/ziekteangst-cognities-en-gedrag/",
		},
		[LanguageTag.EN]: {
			title: "Illness Anxiety Disorder cognitions and behaviour",
			link: "https://niceday.app/en/library/anxiety/illness-anxiety-disorder/illness-anxiety-disorder-cognitions-and-behaviour/",
		},
	},
	[PresetPsychoEducationTag.OBSESSIVE_COMPULSIVE_DISORDER]: {
		[LanguageTag.NL]: {
			title: "Obsessief-Compulsieve Stoornis of Dwangstoornis",
			link: "https://niceday.app/library/angst/dwangstoornis/obsessief-compulsieve-stoornis-of-dwangstoornis/",
		},
		[LanguageTag.EN]: {
			title: "Obsessive-Compulsive Disorder or Compulsive Disorder",
			link: "https://niceday.app/en/library/ocd/obsessive-compulsive-disorder-or-compulsive-disorder/",
		},
	},
	[PresetPsychoEducationTag.COMPULSIVE_DISORDER]: {
		[LanguageTag.NL]: {
			title: "Dwangmodel",
			link: "https://niceday.app/library/angst/dwangstoornis/dwangmodel/",
		},
		[LanguageTag.EN]: {
			title: "Compulsive model",
			link: "https://niceday.app/en/library/ocd/compulsive-model/",
		},
	},
	[PresetPsychoEducationTag.OBSESSION_OF_PEOPLE_WITHOUT_OCD]: {
		[LanguageTag.NL]: {
			title: "Lijst met obsessies van mensen zonder OCD",
			link: "https://niceday.app/library/angst/dwangstoornis/lijst-met-obsessies-van-mensen-zonder-ocd/",
		},
		[LanguageTag.EN]: {
			title: "List of obsessions of people without OCD",
			link: "https://niceday.app/en/library/ocd/list-of-obsessions-of-people-without-ocd/",
		},
	},
	[PresetPsychoEducationTag.PSYCHO_EDUCATION_TRAUMA]: {
		[LanguageTag.NL]: {
			title: "Psycho-educatie trauma",
			link: "https://niceday.app/library/trauma/algemene-informatie-trauma/psycho-educatie-trauma/",
		},
		[LanguageTag.EN]: {
			title: "Psycho-education Trauma",
			link: "https://niceday.app/en/library/trauma/general-information-trauma/psycho-education-trauma/",
		},
	},
	[PresetPsychoEducationTag.REACT_TRAUMA_TREATMENT]: {
		[LanguageTag.NL]: {
			title: "Wat zijn reacties die ik kan verwachten tijdens de behandeling?",
			link: "https://niceday.app/library/trauma/algemene-informatie-trauma/wat-zijn-reacties-die-ik-kan-verwachten-tijdens-de-behandeling/",
		},
		[LanguageTag.EN]: {
			title: "How can I expect to react during trauma treatment?",
			link: "https://niceday.app/en/library/trauma/general-information-trauma/how-can-i-expect-to-react-during-trauma-treatment/",
		},
	},
	[PresetPsychoEducationTag.COPE_EMOTIONS_DURING_TRAUMA_TREATMENT]: {
		[LanguageTag.NL]: {
			title: "Hoe ga ik om met intense emoties tijdens mijn traumabehandeling?",
			link: "https://niceday.app/library/trauma/algemene-informatie-trauma/hoe-ga-ik-om-met-intense-emoties-tijdens-mijn-traumabehandeling/",
		},
		[LanguageTag.EN]: {
			title: "How do I cope with intense emotions during my trauma treatment?",
			link: "https://niceday.app/en/library/trauma/general-information-trauma/how-do-i-cope-with-intense-emotions-during-my-trauma-treatment/",
		},
	},
	[PresetPsychoEducationTag.INFORMATION_OF_SOCIAL_NETWORK]: {
		[LanguageTag.NL]: {
			title: "Informatie voor jouw omgeving",
			link: "https://niceday.app/library/trauma/algemene-informatie-trauma/informatie-voor-jouw-omgeving/",
		},
		[LanguageTag.EN]: {
			title: "Information for your social network",
			link: "https://niceday.app/en/library/trauma/general-information-trauma/information-for-your-social-network/",
		},
	},
	[PresetPsychoEducationTag.ONLINE_TRAUMA_TREATMENT]: {
		[LanguageTag.NL]: {
			title: "Online behandeling van een trauma",
			link: "https://niceday.app/library/trauma/algemene-informatie-trauma/behoeftegestuurde-behandeling-van-een-trauma/",
		},
		[LanguageTag.EN]: {
			title: "(Online) Trauma treatment",
			link: "https://niceday.app/en/library/trauma/general-information-trauma/online-trauma-treatment/",
		},
	},
	[PresetPsychoEducationTag.TRAUMA_TREATMENT_IMAGINARY_EXPOSURE]: {
		[LanguageTag.NL]: {
			title: "Behandeling van trauma met Imaginaire Exposure",
			link: "https://niceday.app/library/trauma/imaginaire-exposure/behandeling-van-trauma-met-imaginaire-exposure/",
		},
		[LanguageTag.EN]: {
			title: "Trauma treatment with Imaginary Exposure",
			link: "https://niceday.app/en/library/trauma/trauma-imaginaire-exposure/trauma-treatment-with-imaginary-exposure/",
		},
	},
	[PresetPsychoEducationTag.EMDR]: {
		[LanguageTag.NL]: {
			title: "Wat is EMDR en hoe werkt het?",
			link: "https://niceday.app/library/trauma/emdr/wat-is-emdr-en-hoe-werkt-het/",
		},
		[LanguageTag.EN]: {
			title: "What is EMDR and how does it work?",
			link: "https://niceday.app/en/library/trauma/emdr-trauma-library/what-is-emdr-and-how-does-it-work/",
		},
	},
	[PresetPsychoEducationTag.STRESS_TO_BURNOUT]: {
		[LanguageTag.NL]: {
			title: "Van stress tot burn-out – Algemene informatie",
			link: "https://niceday.app/library/burn-out/algemene-info-burnout/van-stress-tot-burn-out/",
		},
		[LanguageTag.EN]: {
			title: "From stress to burnout",
			link: "https://niceday.app/en/library/burn-out/general-information-burn-out/from-stress-to-burnout/",
		},
	},
	[PresetPsychoEducationTag.SYMPTOMS_OF_BURNOUT]: {
		[LanguageTag.NL]: {
			title: "Symptomen van een burn-out",
			link: "https://niceday.app/library/burn-out/burn-out-en/symptomen-van-een-burn-out/",
		},
		[LanguageTag.EN]: {
			title: "Symptoms of a burnout",
			link: "https://niceday.app/en/library/burn-out/burn-out-en/symptoms-of-a-burnout/",
		},
	},
	[PresetPsychoEducationTag.TREATMENT_OF_BURNOUT]: {
		[LanguageTag.NL]: {
			title: "De behandeling van een burn-out",
			link: "https://niceday.app/library/burn-out/burn-out-en/de-behandeling-van-een-burn-out/",
		},
		[LanguageTag.EN]: {
			title: "Treatment of a burnout",
			link: "https://niceday.app/en/library/burn-out/burn-out-en/treatment-of-a-burnout/",
		},
	},
	[PresetPsychoEducationTag.SUPPORT_SOMEONE_BURNOUT]: {
		[LanguageTag.NL]: {
			title: "Hoe steun je iemand met een burn-out? (Psycho-educatie voor de sociale omgeving)",
			link: "https://niceday.app/library/burn-out/burn-out-en/hoe-steun-je-iemand-met-een-burn-out-psycho-educatie-voor-de-sociale-omgeving/",
		},
		[LanguageTag.EN]: {
			title: "How do you support someone with a burnout? (Psychoeducation for social circle)",
			link: "https://niceday.app/en/library/burn-out/burn-out-en/how-do-you-support-someone-with-a-burnout-psychoeducation-for-social-circle/",
		},
	},
	[PresetPsychoEducationTag.SHAME_GUILT_WITH_BURNOUT]: {
		[LanguageTag.NL]: {
			title: "Schaamte en schuld bij burn-out",
			link: "https://niceday.app/library/burn-out/burn-out-en/schaamte-en-schuld-bij-burn-out/",
		},
		[LanguageTag.EN]: {
			title: "Shame and guilt with a burnout",
			link: "https://niceday.app/en/library/burn-out/burn-out-en/shame-and-guilt-with-a-burnout/",
		},
	},
	[PresetPsychoEducationTag.PERSONALITY_AND_BURNOUT]: {
		[LanguageTag.NL]: {
			title: "Persoonlijkheid en burn-out",
			link: "https://niceday.app/library/burn-out/burn-out-en/persoonlijkheid-en-burn-out/",
		},
		[LanguageTag.EN]: {
			title: "Personality and burnout",
			link: "https://niceday.app/en/library/burn-out/burn-out-en/personality-and-burnout/",
		},
	},
	[PresetPsychoEducationTag.ADHD]: {
		[LanguageTag.NL]: {
			title: "Wat is ADHD?",
			link: "https://niceday.app/library/adhd/wat-is-adhd/",
		},
		[LanguageTag.EN]: {
			title: "What is ADHD?",
			link: "https://niceday.app/en/library/adhd-en/what-is-adhd/",
		},
	},
	[PresetPsychoEducationTag.SENSORY_WORK_ADHD]: {
		[LanguageTag.NL]: {
			title: "Hoe werkt prikkelverwerking bij ADHD?",
			link: "https://niceday.app/library/adhd/hoe-werkt-prikkelverwerking-bij-adhd/",
		},
		[LanguageTag.EN]: {
			title: "How does sensory processing work in ADHD?",
			link: "https://niceday.app/en/library/adhd-en/how-does-sensory-processing-work-in-adhd/",
		},
	},
	[PresetPsychoEducationTag.TREATMENT_OF_ADHD]: {
		[LanguageTag.NL]: {
			title: "De behandeling van ADHD",
			link: "https://niceday.app/library/adhd/de-behandeling-van-adhd/",
		},
		[LanguageTag.EN]: {
			title: "Treatment of ADHD",
			link: "https://niceday.app/en/library/adhd-en/treatment-of-adhd/",
		},
	},
	[PresetPsychoEducationTag.OVERSTIMULATION]: {
		[LanguageTag.NL]: {
			title: "Overprikkeling in kaart brengen",
			link: "https://niceday.app/library/adhd/overprikkeling-in-kaart-brengen/",
		},
		[LanguageTag.EN]: {
			title: "Tracking Overstimulation",
			link: "https://niceday.app/en/library/adhd-en/tracking-overstimulation/",
		},
	},
	[PresetPsychoEducationTag.ENVIRONMENT_OF_ADHD]: {
		[LanguageTag.NL]: {
			title: "Informatie en tips voor de omgeving van iemand met ADHD",
			link: "https://niceday.app/library/adhd/informatie-en-tips-voor-de-omgeving-van-iemand-met-adhd/",
		},
		[LanguageTag.EN]: {
			title: "Information and tips for the environment of someone with ADHD",
			link: "https://niceday.app/en/library/adhd-en/information-and-tips-for-the-environment-of-someone-with-adhd/",
		},
	},
	[PresetPsychoEducationTag.STRENGTHS_PEOPLE_WITH_ADHD]: {
		[LanguageTag.NL]: {
			title: "De sterke kant van mensen met ADHD",
			link: "https://niceday.app/library/adhd/de-sterke-kant-van-mensen-met-adhd/",
		},
		[LanguageTag.EN]: {
			title: "The strengths of people with ADHD",
			link: "https://niceday.app/en/library/adhd-en/the-strengths-of-people-with-adhd/",
		},
	},
	[PresetPsychoEducationTag.ADDICTION]: {
		[LanguageTag.NL]: {
			title: "Wat is een verslaving?",
			link: "https://niceday.app/library/verslaving/algemene-informatie-nl/wat-is-een-verslaving/",
		},
		[LanguageTag.EN]: {
			title: "What is addiction?",
			link: "https://niceday.app/en/library/addiction/addiction-general-information/what-is-addiction/",
		},
	},
	[PresetPsychoEducationTag.ADDICTION_ADVANTAGES_DISADVANTAGES]: {
		[LanguageTag.NL]: {
			title: "Balans voordelen en nadelen",
			link: "https://niceday.app/library/verslaving/algemene-informatie-nl/balans-voordelen-en-nadelen/",
		},
		[LanguageTag.EN]: {
			title: "Weighing up the advantages and disadvantages",
			link: "https://niceday.app/en/library/addiction/addiction-general-information/20550/",
		},
	},
	[PresetPsychoEducationTag.ADDICTION_SOCIAL_ENVIRONMENT]: {
		[LanguageTag.NL]: {
			title: "Informatie voor omgeving: wat kun je beter wel en wat kun je beter niet doen?",
			link: "https://niceday.app/library/verslaving/algemene-informatie-nl/informatie-voor-omgeving-wat-kun-je-beter-wel-en-wat-kun-je-beter-niet-doen/",
		},
		[LanguageTag.EN]: {
			title: "Information for your social environment: what should you do and what should you avoid doing?",
			link: "https://niceday.app/en/library/addiction/addiction-general-information/information-for-your-social-environment-what-should-you-do-and-what-should-you-avoid-doing/",
		},
	},
	[PresetPsychoEducationTag.FEELING_HALT]: {
		[LanguageTag.NL]: {
			title: "Gevoelens HALT",
			link: "https://niceday.app/library/verslaving/algemene-informatie-nl/gevoelens-halt/",
		},
		[LanguageTag.EN]: {
			title: "Feelings HALT",
			link: "https://niceday.app/en/library/addiction/addiction-general-information/feelings-halt/",
		},
	},
	[PresetPsychoEducationTag.EXPERIENCE_CRAVING]: {
		[LanguageTag.NL]: {
			title: "Wat te doen bij craving?",
			link: "https://niceday.app/library/verslaving/algemene-informatie-nl/wat-te-doen-bij-craving/",
		},
		[LanguageTag.EN]: {
			title: "What should you do when you experience a craving?",
			link: "https://niceday.app/en/library/addiction/addiction-general-information/what-should-you-do-when-you-experience-a-craving/",
		},
	},
	[PresetPsychoEducationTag.CBT]: {
		[LanguageTag.NL]: {
			title: "Wat is Cognitieve Gedragstherapie?",
			link: "https://niceday.app/library/cognitieve-gedragstherapie-nl/wat-is-cognitieve-gedragstherapie/",
		},
		[LanguageTag.EN]: {
			title: "What is Cognitive Behavioural Therapy (CBT)?",
			link: "https://niceday.app/en/library/cognitive-behavioural-therapy-en/what-is-cognitive-behavioural-therapy-cbt-2/",
		},
	},
	[PresetPsychoEducationTag.THOUGHT_RECORD]: {
		[LanguageTag.NL]: {
			title: "G-schema",
			link: "https://niceday.app/library/cognitieve-gedragstherapie-nl/g-schema/",
		},
		[LanguageTag.EN]: {
			title: "Thought Record",
			link: "https://niceday.app/en/library/depression/cognitive-behavioural-therapy/thought-record/",
		},
	},
	[PresetPsychoEducationTag.THOUGHT_CHALLENGING]: {
		[LanguageTag.NL]: {
			title: "Gedachten uitdagen met het G-schema",
			link: "https://niceday.app/library/cognitieve-gedragstherapie-nl/gedachten-uitdagen/",
		},
		[LanguageTag.EN]: {
			title: "Thought Challenging",
			link: "https://niceday.app/en/library/cognitive-behavioural-therapy-en/thought-challenging/",
		},
	},
	[PresetPsychoEducationTag.CBT_RULES_FOR_LIVING]: {
		[LanguageTag.NL]: {
			title: "Leefregels",
			link: "https://niceday.app/library/cognitieve-gedragstherapie-nl/leefregels/",
		},
		[LanguageTag.EN]: {
			title: "Rules for living",
			link: "https://niceday.app/en/library/cognitive-behavioural-therapy-en/rules-for-living/",
		},
	},
	[PresetPsychoEducationTag.CBT_THINKING_STYLES]: {
		[LanguageTag.NL]: {
			title: "Denkstijlen",
			link: "https://niceday.app/library/cognitieve-gedragstherapie-nl/denkstijlen/",
		},
		[LanguageTag.EN]: {
			title: "Thinking styles",
			link: "https://niceday.app/en/library/cognitive-behavioural-therapy-en/thinking-styles/",
		},
	},
	[PresetPsychoEducationTag.CBT_CORE_BELIEFS]: {
		[LanguageTag.NL]: {
			title: "Kernovertuigingen",
			link: "https://niceday.app/library/cognitieve-gedragstherapie-nl/kernovertuigingen/",
		},
		[LanguageTag.EN]: {
			title: "Core beliefs",
			link: "https://niceday.app/en/library/cognitive-behavioural-therapy-en/core-beliefs/",
		},
	},
	[PresetPsychoEducationTag.CBT_BEHAVIORAL_EXPERIMENT]: {
		[LanguageTag.NL]: {
			title: "Gedragsexperiment",
			link: "https://niceday.app/library/cognitieve-gedragstherapie-nl/gedragsexperiment/",
		},
		[LanguageTag.EN]: {
			title: "Behavioural experiment",
			link: "https://niceday.app/en/library/cognitive-behavioural-therapy-en/behavioural-experiment/",
		},
	},
	[PresetPsychoEducationTag.RELAXATION_MINDFULNESS]: {
		[LanguageTag.NL]: {
			title: "Wat is mindfulness en wat zijn de effecten?",
			link: "https://niceday.app/library/ontspanning/mindfulness/wat-is-mindfulness-en-wat-zijn-de-effecten/",
		},
		[LanguageTag.EN]: {
			title: "What is Mindfulness and What Are the Effects?",
			link: "https://niceday.app/en/library/relaxation/mindfulness-en/what-is-mindfulness-and-what-are-the-effects/",
		},
	},
	[PresetPsychoEducationTag.PILLARS_OF_MINDFULNESS]: {
		[LanguageTag.NL]: {
			title: "De pijlers van mindfulness",
			link: "https://niceday.app/library/ontspanning/mindfulness/de-pijlers-van-mindfulness/",
		},
		[LanguageTag.EN]: {
			title: "The pillars of mindfulness",
			link: "https://niceday.app/en/library/relaxation/mindfulness-en/the-pillars-of-mindfulness/",
		},
	},
	[PresetPsychoEducationTag.OVERCOMING_MINDFULNESS_PRACTICE]: {
		[LanguageTag.NL]: {
			title: "Overwin jouw hindernissen tijdens het beoefenen van mindfulness",
			link: "https://niceday.app/library/ontspanning/mindfulness/overwin-jouw-hindernissen-tijdens-het-beoefenen-van-mindfulness/",
		},
		[LanguageTag.EN]: {
			title: "Overcoming Obstacles in Mindfulness Practice",
			link: "https://niceday.app/en/library/relaxation/mindfulness-en/overcoming-obstacles-in-mindfulness-practice/",
		},
	},
	[PresetPsychoEducationTag.REACTION_TO_STRESS]: {
		[LanguageTag.NL]: {
			title: "Jouw reactie op stress – bewust of onbewust",
			link: "https://niceday.app/library/ontspanning/mindfulness/jouw-reactie-op-stress-bewust-of-onbewust/",
		},
		[LanguageTag.EN]: {
			title: "Your Reaction to Stress – Conscious or Unconscious",
			link: "https://niceday.app/en/library/relaxation/mindfulness-en/your-reaction-to-stress-conscious-or-unconscious/",
		},
	},
	[PresetPsychoEducationTag.MINDFULNESS_WORK]: {
		[LanguageTag.NL]: {
			title: "Hoe werkt mindfulness?",
			link: "https://niceday.app/library/ontspanning/mindfulness/hoe-werkt-mindfulness/",
		},
		[LanguageTag.EN]: {
			title: "How does mindfulness work?",
			link: "https://niceday.app/en/library/relaxation/mindfulness-en/how-does-mindfulness-work/",
		},
	},
	[PresetPsychoEducationTag.TURN_OFF_AUTOPILOT]: {
		[LanguageTag.NL]: {
			title: "Schakel je automatische piloot uit",
			link: "https://niceday.app/library/ontspanning/mindfulness/schakel-je-automatische-piloot-uit/",
		},
		[LanguageTag.EN]: {
			title: "Turn off your autopilot",
			link: "https://niceday.app/en/library/relaxation/mindfulness-en/turn-off-your-autopilot/",
		},
	},
	[PresetPsychoEducationTag.RELAXATION_BODY_SCAN]: {
		[LanguageTag.NL]: {
			title: "Oefening: de bodyscan",
			link: "https://niceday.app/library/ontspanning/mindfulness/oefening-de-bodyscan/",
		},
		[LanguageTag.EN]: {
			title: "Exercise: The Body Scan",
			link: "https://niceday.app/en/library/relaxation/mindfulness-en/exercise-the-body-scan/",
		},
	},
	[PresetPsychoEducationTag.RELAXATION_BREATHING_SPACE]: {
		[LanguageTag.NL]: {
			title: "Oefening: drie minuten ademruimte",
			link: "https://niceday.app/library/ontspanning/mindfulness/oefening-drie-minuten-ademruimte/",
		},
		[LanguageTag.EN]: {
			title: "Exercise: Three-Minute Breathing Space",
			link: "https://niceday.app/en/library/relaxation/mindfulness-en/exercise-three-minute-breathing-space/",
		},
	},
	[PresetPsychoEducationTag.IMPORTANCE_RELAXATION]: {
		[LanguageTag.NL]: {
			title: "Het belang van ontspannen",
			link: "https://niceday.app/library/ontspanning/algemeen-ontspanning/het-belang-van-ontspannen/",
		},
		[LanguageTag.EN]: {
			title: "The importance of relaxation",
			link: "https://niceday.app/en/library/relaxation/general-relaxation/the-importance-of-relaxation/",
		},
	},
	[PresetPsychoEducationTag.RELAXATION_EXERCISE]: {
		[LanguageTag.NL]: {
			title: "Ontspanningsoefening ‘Progressieve Relaxatie’ – Burn-out",
			link: "https://niceday.app/library/ontspanning/algemeen-ontspanning/werk/ontspanningsoefening-progressieve-relaxatie-van-jacobson/",
		},
		[LanguageTag.EN]: {
			title: "Relaxation exercise ‘Progressive Relaxation’ created by Jacobson – burn-out",
			link: "https://niceday.app/en/library/relaxation/general-relaxation/relaxation-exercise-progressive-relaxation-created-by-jacobson-burn-out/",
		},
	},
	[PresetPsychoEducationTag.TRAUMANET]: {
		[LanguageTag.NL]: {
			title: "Lees meer op www.traumanet.nl",
			link: "https://www.traumanet.nl",
		},
		[LanguageTag.EN]: {
			title: "Read more at www.traumanet.nl",
			link: "https://www.traumanet.nl",
		},
	},
	[PresetPsychoEducationTag.OUR_THOUGHTS_INFLUENCE_US]: {
		[LanguageTag.NL]: {
			title: "Hoe onze gedachten ons beïnvloeden",
			link: "https://niceday.app/library/cognitieve-gedragstherapie-nl/hoe-onze-gedachten-ons-beinvloeden/",
		},
		[LanguageTag.EN]: {
			title: "How our thoughts influence us",
			link: "https://niceday.app/en/library/cognitive-behavioural-therapy-en/how-our-thoughts-influence-us/",
		},
	},
	[PresetPsychoEducationTag.PHYSICAL_RESPONSE_TO_STRESS]: {
		[LanguageTag.NL]: {
			title: "Lichamelijke reactie op stress",
			link: "https://niceday.app/library/werk/werkstress/lichamelijke-reactie-op-stress/",
		},
		[LanguageTag.EN]: {
			title: "Physical response to stress",
			link: "https://niceday.app/en/library/work/stress-at-work/physical-response-to-stress/",
		},
	},
};
