import autobind from "autobind-decorator";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import strTranslation from "../assets/lang/strings";
/**
 * Component level error handler. Used for errors in render, constructor and lifecycle funcs.
 */
@autobind
class ErrorBoundary extends React.Component {
	public render(): React.ReactNode {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: "16px auto",
					backgroundColor: "#fff",
					padding: 24,
					borderRadius: 8,
					maxWidth: 600,
					boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.05)",
				}}
			>
				<img
					src="/assets/img/crash-screen.png"
					style={{margin: "50px auto", width: "100%", display: "block"}}
				/>
				<div style={{marginTop: 16}}>
					<button id="back" onClick={this.redirectToMainPage}>
						<FormattedMessage id={strTranslation.CRASH.label.go_back} />
					</button>
				</div>
			</div>
		);
	}

	private redirectToMainPage = () => {
		window.location.replace("/");
	};
}

export default ErrorBoundary;
