import {takeEvery, select, put} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {getAuthUser} from "../../auth/redux";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {callActions} from "../redux/callActions";

const logger = createLogger("terminateCallSaga", SentryTags.VideoCall);

function* terminateCall({payload: {reason}}: ActionType<typeof callActions.terminateCall>) {
	const authUser = yield select(getAuthUser);

	logger.info("Terminate call", reason);

	yield put(callActions.userLeaveCall(authUser.id, Date.now()));
	yield put(callActions.endCallIfNeeded(reason));
}

export function* terminateCallSaga() {
	yield takeEvery(getType(callActions.terminateCall), terminateCall);
}
