import {NotFoundError} from "@sense-os/goalie-js";
import {all, select} from "redux-saga/effects";

import treatmentStatusSdk from "../treatmentStatusSdk";

import {reconcileTreatmentPersonnels} from "./createOrUpdateTreatmentSaga";
import {postProcessFetchedTreatments} from "./loadTreatmentsSaga";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";
import {getContactByIdFn} from "../../contacts/redux/contactSelectors";
import {getClientsConnection} from "../../clientNetwork/helpers/clientNetworkUtils";
import {ConnectedTherapist} from "../../clientNetwork/clientNetworkTypes";

/**
 * Per hiding treatment to be under the hood,
 * disconnecting therapist should results in removing that therapist from personnel list
 * of client's treatment.
 * If that's the last therapist in the personnels,
 * also clean up client's treatment information
 */
export function* automaticTreatmentCleanupSaga(clientId: number, therapistId: number) {
	const token: string = getSessionId();
	const clientContact = yield select(getContactByIdFn(clientId));
	const currentTreatments = yield apiCallSaga(treatmentStatusSdk.getTreatmentsOfAClient, token, clientContact.hashId);

	const treatmentMaps = postProcessFetchedTreatments(currentTreatments);
	const treatment = treatmentMaps[clientId];
	if (!treatment) return;

	const clientsConnection: ConnectedTherapist[] = yield apiCallSaga(getClientsConnection, token, clientId);
	const otherConnection = clientsConnection.filter((c) => c.id !== therapistId);

	const [treatmentTypes, treatmentComplaints, personnels] = yield all([
		apiCallSaga(treatmentStatusSdk.getTreatmentTypesOfTreatment, token, treatment.id),
		apiCallSaga(treatmentStatusSdk.getTreatmentComplaintsOfTreatment, token, treatment.id),
		apiCallSaga(treatmentStatusSdk.getTreatmentPersonnelsOfTreatment, token, treatment.id),
	]);

	const filteredPersonnels = personnels
		.filter((personnel) => personnel.id !== therapistId)
		.map((personnel) => ({therapistId: personnel.id, role: personnel.role}));

	// If no other therapist are connected to the clients anymore
	// clean up the treatment data.
	if (otherConnection.length === 0) {
		yield apiCallSaga(treatmentStatusSdk.updateTreatment, token, {
			id: treatment.id,
			patient: clientId,
			startTime: treatment.startTime,
			disconnectedAt: treatment.disconnectedAt,
			endTime: treatment.endTime,
			terminatingReason: treatment.terminatingReason?.id,
			clientData: {
				orgKeyForm: treatment.clientData?.orgKeyForm,
				value: {},
				version: 1,
			},
			referralId: "" as any,
			goal: "",
		});

		for (let idx = 0; idx < treatmentComplaints.length; idx++) {
			try {
				yield apiCallSaga(
					treatmentStatusSdk.removeAComplaintOfTreatment,
					token,
					treatment.id,
					treatmentComplaints[idx].id,
				);
			} catch (error) {
				// For now, just ignore whenever portal failed to remove it.
			}
		}

		for (let idx = 0; idx < treatmentTypes.length; idx++) {
			try {
				yield apiCallSaga(
					treatmentStatusSdk.removeATreatmentTypeOfTreatment,
					token,
					treatment.id,
					treatmentTypes[idx].id,
				);
			} catch (error) {
				// For now, just ignore whenever portal failed to remove it.
			}
		}

		try {
			yield reconcileTreatmentPersonnels(clientId, treatment.id, personnels, []);
		} catch (error) {
			if (error instanceof NotFoundError) {
				// If it's a not found error,
				// then the treatment already ends and nothing
				// portal can do to update the therapists list.
				return;
			}

			throw error;
		}
	} else if (personnels.length !== filteredPersonnels.length) {
		try {
			yield reconcileTreatmentPersonnels(clientId, treatment.id, personnels, filteredPersonnels);
		} catch (error) {
			if (error instanceof NotFoundError) {
				// If it's a not found error,
				// then the treatment already ends and nothing
				// portal can do to update the therapists list.
				return;
			}

			throw error;
		}
	}
}
