import {UserAccountPermissions} from "@sense-os/goalie-js";

export const appAccess: UserAccountPermissions[] = [
	UserAccountPermissions.PATIENT,
	UserAccountPermissions.THERAPIST,
	UserAccountPermissions.SCREENER,
	UserAccountPermissions.SECRETARY,
];

export const orgAccess: UserAccountPermissions[] = [UserAccountPermissions.ADMIN];
