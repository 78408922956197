import {call, put} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import behaviorExprSDK from "../helpers/behaviorExprSDK";
import {behaviorExprActions} from "../redux/behaviorExprActions";
import {BehaviorExperimentDetailResponse} from "@sense-os/goalie-js";

const log = createLogger("getBehaviorExperimentById", SentryTags.BehaviorExperiment);

/**
 * Get Behavior Experiment by id
 */
export function* getBehaviorExperimentById(action: ActionType<typeof behaviorExprActions.getBehaviorExprById>) {
	const {behaviorExprId} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		const result: BehaviorExperimentDetailResponse = yield apiCallSaga(
			behaviorExprSDK.getBehaviorExperimentDetail,
			token,
			behaviorExprId,
		);

		yield put(behaviorExprActions.getBehaviorExprById.success({behaviorExprId, data: result}));
	} catch (error) {
		log.error("Error while get behavior experiment by id", error);
		yield put(behaviorExprActions.getBehaviorExprById.failure({behaviorExprId, error}));
	}
}
