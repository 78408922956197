import {LoadingState} from "constants/redux";
import produce from "immer";
import {getType} from "typesafe-actions";
import {InterventionCustomTrackerState} from "../interventionCustomTrackerType";
import {
	interventionCustomTrackerActions,
	interventionCustomTrackerActionType,
} from "./interventionCustomTrackerActions";

const initialState: InterventionCustomTrackerState = {
	loadingState: {
		customTrackers: {
			fetchingState: LoadingState.EMPTY,
			savingState: LoadingState.EMPTY,
		},
		detailCustomTrackerDiaryEntry: {
			fetchingState: LoadingState.EMPTY,
		},
		detailSelectedRegisteredTracker: {
			fetchingState: LoadingState.EMPTY,
		},
		registeredCustomTrackersList: {
			fetchingState: LoadingState.EMPTY,
		},
	},
	customTrackers: [],
	totalCustomTrackers: 0,
	registeredCustomTrackers: [],
	totalRegisteredCustomTrackers: 0,
};

export const interventionCustomTrackerReducer = produce(
	(state: InterventionCustomTrackerState, action: interventionCustomTrackerActionType) => {
		switch (action.type) {
			case getType(interventionCustomTrackerActions.fetchCustomTrackers.request): {
				state.loadingState.customTrackers.fetchingState = LoadingState.LOADING;
				state.customTrackers = [];
				state.totalCustomTrackers = 0;

				return;
			}
			case getType(interventionCustomTrackerActions.fetchCustomTrackers.success): {
				const {customTrackers, totalCustomTrackers} = action.payload;

				state.loadingState.customTrackers.fetchingState = LoadingState.LOADED;
				state.customTrackers = customTrackers;
				state.totalCustomTrackers = totalCustomTrackers;

				return;
			}
			case getType(interventionCustomTrackerActions.fetchCustomTrackers.failure): {
				const {error} = action.payload;

				state.loadingState.customTrackers.fetchingState = LoadingState.ERROR;
				state.loadingState.customTrackers.errorState = error;

				return;
			}
			case getType(interventionCustomTrackerActions.fetchRegisteredCustomTrackers.request): {
				state.loadingState.registeredCustomTrackersList.fetchingState = LoadingState.LOADING;
				state.registeredCustomTrackers = [];
				state.totalRegisteredCustomTrackers = 0;

				return;
			}
			case getType(interventionCustomTrackerActions.fetchRegisteredCustomTrackers.success): {
				const {registeredCustomTrackers, totalRegisteredCustomTrackers} = action.payload;

				state.loadingState.registeredCustomTrackersList.fetchingState = LoadingState.LOADED;
				state.registeredCustomTrackers = registeredCustomTrackers;
				state.totalRegisteredCustomTrackers = totalRegisteredCustomTrackers;

				return;
			}
			case getType(interventionCustomTrackerActions.fetchRegisteredCustomTrackers.failure): {
				const {error} = action.payload;

				state.loadingState.registeredCustomTrackersList.fetchingState = LoadingState.ERROR;
				state.loadingState.registeredCustomTrackersList.errorState = error;

				return;
			}
			case getType(interventionCustomTrackerActions.enableOrDisableTrackers.request): {
				state.loadingState.customTrackers.savingState = LoadingState.LOADING;

				return;
			}
			case getType(interventionCustomTrackerActions.enableOrDisableTrackers.success): {
				const {customTrackers} = action.payload;

				state.customTrackers = customTrackers;
				state.loadingState.customTrackers.savingState = LoadingState.LOADED;

				return;
			}
			case getType(interventionCustomTrackerActions.enableOrDisableTrackers.failure): {
				const {error} = action.payload;

				state.loadingState.customTrackers.savingState = LoadingState.ERROR;
				state.loadingState.customTrackers.errorState = error;

				return;
			}
		}
	},
	initialState,
);
