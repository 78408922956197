import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {takeEvery, put, call, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {UserProfileAction} from "../redux/UserProfileAction";
import {getSessionId, getAuthUserId} from "../../auth/helpers/authStorage";
import Localization, {ILocalization} from "../../localization/Localization";
import {toastActions} from "../../toaster/redux/toastAction";
import profileSDK from "../helpers/profileSDK";
import {authActions} from "../../auth/redux/authActions";
import {getAuthUser} from "../../auth/redux";
import {JobSpecialization, UserData} from "@sense-os/goalie-js";
import {JOB_SPECIALIZATION_VERSION} from "../UserProfileTypes";
import {localizeTherapistSpecialization} from "../helpers/profileHelpers";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("userProfileSaga", SentryTags.UserProfile);
const loc: ILocalization = Localization;

function* handleSavePersonal(action: ActionType<typeof UserProfileAction.saveUserPersonal.request>) {
	const token = yield call(getSessionId);
	const userId = yield call(getAuthUserId);
	try {
		// Update user personal data
		yield call(profileSDK.updateUserData, token, userId, action.payload.userData);
		// Refetch user data
		const userData: UserData = yield call(profileSDK.getUserData, token, userId);
		const fullName = action.payload.userData.firstName + " " + action.payload.userData.lastName;
		// Showing success toast
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.USER_PROFILE.profile_updated.toast, {
					name: userData.firstName,
				}),
				type: "info",
			}),
		);
		yield put(authActions.updateAuthUser({data: userData, fullName: fullName}));
		yield put(UserProfileAction.saveUserPersonal.success());
	} catch (error) {
		// Showing error toast
		const authUser = yield select(getAuthUser);
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.USER_PROFILE.profile_update_failed.toast, {
					name: authUser.data.firstName,
				}),
				type: "error",
			}),
		);
		yield put(UserProfileAction.saveUserPersonal.failure(error));
		log.captureException(error);
	}
}

function* loadJobSpecializations() {
	const token = yield call(getSessionId);
	try {
		const specializations: JobSpecialization[] = yield call(
			profileSDK.getJobSpecializations,
			token,
			JOB_SPECIALIZATION_VERSION,
		);

		// Try to localize the job specializations.
		// This is because the `title` inside `JobSpecialization` is a translation key of the actual title.
		const localizedSpecializations = specializations
			.map((specialization) => {
				const title = localizeTherapistSpecialization(specialization.title);

				// Return null if translation doesn't exist
				if (!title) {
					return null;
				}

				return {
					...specialization,
					title,
				};
			})
			// Filter invalid job specializations (translation not found)
			.filter(Boolean)
			// Sort by localized title ascending
			.sort((a, b) => {
				if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
				if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;

				return 0;
			});

		yield put(UserProfileAction.loadJobSpecializations.success(localizedSpecializations));
	} catch (error) {
		yield put(UserProfileAction.saveUserPersonal.failure(error));
		log.captureException(error);
	}
}

export default function* () {
	yield takeEvery(getType(UserProfileAction.saveUserPersonal.request), handleSavePersonal);
	yield takeEvery(getType(UserProfileAction.loadJobSpecializations.request), loadJobSpecializations);
}
