import {eventChannel, END, buffers} from "redux-saga";
import {all, call, put, take} from "redux-saga/effects";
import {shouldReportToSentry} from "../../auth/helpers";
import {getSessionId} from "../../auth/helpers/authStorage";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {toastActions} from "../../toaster/redux";
import {FileUploadResult} from "../fileSharingTypes";
import {fileSharingActions} from "../redux/fileSharingActions";
import {FileChatItem, UploadFilesChatRequestPayload} from "@sense-os/goalie-js/dist";
import fileSharingSDK from "./fileSharingSDK";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("uploadFilesSaga", SentryTags.FileSharing);

interface XMLUploadFileResult {
	/**
	 * json parsed data from upload api response.
	 */
	data?: FileChatItem;
	success: boolean;
	error?: Error;
}

/**
 * Converts object to FormData
 * @param data any Object
 * @returns FormData
 */
export function objectToFormData<T>(data: T): FormData {
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (["thumb_hdpi", "thumb_ldpi", "thumb_mdpi"].includes(key)) {
			// add file name at the third params,
			// so back end could identify the file name to be validated.
			if (data[key]) {
				formData.append(key, data[key], `${key}.jpg`);
			}
		} else {
			formData.append(key, data[key]);
		}
	});

	return formData;
}

/**
 * Transform object to Form Data, and return upload emitter callback.
 * XMLHttpRequest upload is used here because we need to upload file using multipart-form/data.
 * This function is because both mobile and portal use a different mechanism to uplad file.
 * @param token
 * @param body
 * @returns
 */
export function uploadXmlRequest(token: string, body?: UploadFilesChatRequestPayload) {
	const formData: FormData = objectToFormData<UploadFilesChatRequestPayload>(body);

	return (emitter: (input: XMLUploadFileResult | END) => void) => {
		const xhr = new XMLHttpRequest();
		const onFailure = (e: ProgressEvent) => {
			emitter({success: false, error: new Error(`Upload file failed: ${e.type}: ${e.loaded} bytes transferred`)});
			emitter(END);
		};

		xhr.upload.addEventListener("error", onFailure);
		xhr.upload.addEventListener("abort", onFailure);
		xhr.onreadystatechange = () => {
			const {readyState, status} = xhr;
			if (readyState === 4) {
				// 201 HTTP code which used by the API when upload success.
				if (status === 201) {
					emitter({success: true, data: JSON.parse(xhr.response)});
					emitter(END);
				} else {
					emitter({success: false, error: new Error(`Upload file failed: ${xhr.response}`)});
					emitter(END);
				}
			}
		};

		xhr.open("POST", fileSharingSDK.baseUrl(), true);
		xhr.setRequestHeader("Authorization", "Token " + token);
		xhr.send(formData);
		return () => {
			xhr.upload.removeEventListener("error", onFailure);
			xhr.upload.removeEventListener("abort", onFailure);
			xhr.onreadystatechange = null;
			xhr.abort();
		};
	};
}

/**
 * Wrap the upload xml request into EventChannel
 * @param token Session Token
 * @param formData UploadFileRequestPayload
 * @returns EventChannel
 */
export const createUploadChannel = (token: string, formData?: UploadFilesChatRequestPayload) => {
	return eventChannel(uploadXmlRequest(token, formData), buffers.sliding(2));
};

export function* uploadFileHandler(userId: number, fileIndex: number, body: UploadFilesChatRequestPayload) {
	const token = getSessionId();
	yield put(fileSharingActions.upload.request({userId, fileIndex}));
	const channel = yield call(createUploadChannel, token, body);
	while (true) {
		const {error, success, data} = yield take(channel);
		if (!!error) {
			yield put(fileSharingActions.upload.failure({userId, fileIndex, error: error}));
			return {
				success: false,
				error: error,
				data,
			};
		}
		if (success) {
			yield put(fileSharingActions.upload.success({userId, fileIndex, data}));
			return {
				success: true,
				error: null,
				data,
			};
		}
	}
}

export function* uploadFilesHandler(userId: number, files: UploadFilesChatRequestPayload[]) {
	yield put(
		fileSharingActions.uploadFiles.request({
			userId,
		}),
	);
	try {
		// upload file individually in parallel
		const results: FileUploadResult[] = yield all(
			files.map((file, fileIndex) => call(uploadFileHandler, userId, fileIndex, file)),
		);

		const uploadErrors = results.filter((result) => !result.success);

		if (uploadErrors.length > 0) {
			log.captureException(
				uploadErrors.map((result) => result.error),
				{message: "Could not send your message due to upload file error"},
			);
			yield put(
				toastActions.addToast({
					message: localization.formatMessage(strTranslation.FILE_SHARING.chatbox.upload_failed),
					type: "error",
				}),
			);
			return results;
		}

		yield put(fileSharingActions.uploadFiles.success({userId}));
		return results;
	} catch (error) {
		yield put(fileSharingActions.uploadFiles.failure({userId}));

		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.FILE_SHARING.chatbox.upload_failed),
				type: "error",
			}),
		);

		if (shouldReportToSentry(error)) {
			log.captureException(error);
		}
	}
}
