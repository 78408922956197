import {InviteClientItem} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";

/** Invite Clients Form Value */
export interface InviteClientsFormValues {
	name: string;
	email: string;
}

export const inviteClientsFormName = "invite_clients_form";

export interface InviteClientsState {
	// Invited Client fetching state
	fetchingState: LoadingState;
	// invited clients list
	inviteClientsList: InviteClientItem[];
	// total invited clients
	total: number;
	// current page
	currentPage: number;
	// Invite Clients Saving State
	savingState: LoadingState;
	// Invite Clients Deleting State
	deletingState: LoadingState;
	// Invite Clients Deleting State
	resendingState: LoadingState;
}

/**
 * the default invite client list parameters
 * since the table contains sorting feature, and form the BE hasn't support it yet
 * decided to fetch all the invited clients
 */
export const fetchInviteClientListParams = {
	currentPage: 1,
	pageSize: 1000,
};
