import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {checkInFeelingActions} from "../redux/checkInFeelingActions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {getCheckInFeelingSensors} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {CheckInFeeling, SensorDatum} from "redux/tracking/TrackingTypes";
import {SentryTags} from "../../../errorHandler/createSentryReport";

const log = createLogger("fetchCheckInFeeling", SentryTags.CheckInFeeling);

export function* fetchCheckInFeeling(action: ActionType<typeof checkInFeelingActions.fetchCheckInFeeling.request>) {
	const {userId, checkInId} = action.payload;
	const token = yield call(getSessionId);
	try {
		const checkInFeeling: SensorDatum<CheckInFeeling>[] = yield apiCallSaga(
			getCheckInFeelingSensors,
			token,
			userId,
			undefined,
			undefined,
			[checkInId],
		);
		yield put(checkInFeelingActions.fetchCheckInFeeling.success({checkInFeelingData: checkInFeeling?.[0]}));
	} catch (err) {
		log.captureException(err);
		yield put(checkInFeelingActions.fetchCheckInFeeling.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(checkInFeelingActions.fetchCheckInFeeling.request), fetchCheckInFeeling);
}
