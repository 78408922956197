import {AppState} from "../../../ts/redux/AppState";
import {createSelector} from "reselect";
import {LoadingState} from "../../../ts/constants/redux";
import {getCurrentInterval} from "../../../ts/redux/tracking/TrackingSelector";
import moment from "moment";
import {CustomTrackerEventView, EventViewType} from "../../../ts/redux/tracking/TrackingTypes";
import {createEventViewDataLink} from "../../../ts/redux/tracking/TrackingHelper";
import {getTrackerOrderSettingsByUserId} from "../../../userSettings/redux/userSettingsSelectors";
import {mergeTrackerOrderWithUserTrackers, transformTrackerToTrackerItem} from "../helpers/customTrackerHelpers";
import {
	getContactBySelectedClientId,
	getSelectedContactHashId,
	getSelectedContactId,
} from "../../../contacts/redux/contactSelectors";
import {getSelectedClientActivities} from "../../../clientActivity/redux/clientActivitySelectors";
import {transformTrackerDatumToTrackerEventView} from "../../../clientActivity/helpers/customTrackerDataHelpers";
import {TrackerDatum, CustomTrackerSensor, TrackerCategory} from "../customTrackerTypes";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import {TrackerNameWithData} from "../customTrackerGraphV2Types";
import {
	isAllTimeViewGraphSupported,
	isGraphV2Supported,
	isOverviewGraphSupported,
} from "../helpers/graphV2Utils/graphV2Helper";
import {defaultTrackerCategory, isCoreTrackers} from "../../utils/coreTracker";
import {TrackerItem} from "@sense-os/goalie-js";
import {isTrackerSupported} from "../helpers/supportedCustomTrackerConfig";
import {cacheCreateSelectorPerKey} from "redux/utils";

const getCustomTrackerState = (state: AppState) => state.customTracker;
const getSetupTrackerState = createSelector(getCustomTrackerState, (state) => state.setupTracker);
const getCustomTrackerPanelsState = createSelector(getCustomTrackerState, (state) => state.userTrackerMap);
const getManageTrackersState = createSelector(getCustomTrackerState, (state) => state.manageTrackers);

export const isSetupTrackerDialogOpened = createSelector(
	getSetupTrackerState,
	(state) => state.isSetupTrackerDialogOpened,
);

export const getTrackerCategories = createSelector(getSetupTrackerState, (state) => state.trackerCategories);

export const getTrackerInfoFromCategoriesByName = cacheCreateSelectorPerKey((sensorName: string) =>
	createSelector(getUserTrackers, getTrackerCategories, (userTrackers, categories) => {
		const alreadyEnabledTracker = userTrackers.find((t) => t.sensorName === sensorName);

		if (!!alreadyEnabledTracker) return alreadyEnabledTracker;

		for (let idx = 0; idx < categories.length; idx++) {
			const tracker = categories[idx].trackers.find((t) => t.sensorName === sensorName);
			if (!!tracker) return tracker;
		}
	}),
);

export const getSetupTrackerLoadingState = (state: AppState) => {
	return getSetupTrackerState(state).loadingState;
};
export const isLoadingTrackerCategories = createSelector(
	getSetupTrackerState,
	(state) => state.loadingState === LoadingState.LOADING,
);
export const isAddingTracker = createSelector(
	getSetupTrackerState,
	(state) => state.savingState === LoadingState.LOADING,
);
export const getNewAddedTracker = createSelector(getSetupTrackerState, (state) => state.newAddedTracker);
export const getSetupTrackerSavingState = (state: AppState) => {
	return getSetupTrackerState(state).savingState;
};

const getUserTrackerState = createSelector(getSelectedContactHashId, getCustomTrackerPanelsState, (hashId, state) => {
	return hashId ? state[hashId] : null;
});

export const getTrackerDataMap = createSelector(getUserTrackerState, (state) => (state ? state.trackerDataMap : {}));

export const getUserTrackers = createSelector(getUserTrackerState, (userTracker) => {
	if (!userTracker || !userTracker.trackers) {
		return [];
	}
	return userTracker.trackers;
});

export const getAllTrackers = createSelector(
	getTrackerCategories,
	getUserTrackers,
	(trackerCategories, userTrackers): TrackerItem[] => {
		const trackerEnableMap: Record<string, boolean> = userTrackers.reduce((result, userTracker) => {
			result[userTracker.sensorName] = userTracker.isEnabled;

			return result;
		}, {});

		const extractedTrackers: TrackerItem[] = trackerCategories.reduce((result, trackerCategory) => {
			const trackers: TrackerItem[] = trackerCategory.trackers.map((tracker) => {
				const isEnabled: boolean = trackerEnableMap?.[tracker.sensorName] ?? false;

				return transformTrackerToTrackerItem(tracker, isEnabled);
			});

			result.push(...trackers);

			return result;
		}, []);

		return extractedTrackers;
	},
);

// user tracker list v3 core trackers + custom trackers
// since those all of them is under "exercise". if the toggle will be off but it will stay in the active part.
// https://niceday.productboard.com/feature-board/1723440-niceday/features/7442669/detail
export const getCustomTrackersV3 = createSelector(getUserTrackers, (trackers) => {
	return trackers.filter((trackerItem) => {
		return isTrackerSupported(trackerItem.sensorName) || isCoreTrackers(trackerItem.sensorName);
	});
});

/** List of core trackers returns both enabled and disabled trackers */
export const getCoreTrackerList = createSelector(getUserTrackers, (trackers) => {
	return trackers.filter((trackerItem) => {
		return isCoreTrackers(trackerItem.sensorName);
	});
});

export const getUserTrackerFetchingState = (state: AppState): LoadingState => {
	// adding optional chaining to prevent crash
	return getUserTrackerState(state)?.userTrackerFetchingState;
};

export const getTrackerDataFetchingState = (state: AppState) => {
	// adding optional chaining to prevent crash
	return getUserTrackerState(state)?.trackerDataFetchingState;
};

export const getCustomTrackers = createSelector(getUserTrackers, (trackers) => {
	return trackers.filter((tracker) => {
		return isTrackerSupported(tracker.sensorName);
	});
});

export const getUserEnabledTrackersSorted = createSelector(getCustomTrackers, (trackers) => {
	const enabledTrackers = trackers.filter((tracker) => tracker.isEnabled);
	enabledTrackers.sort((a, b) => {
		if (a.trackerName < b.trackerName) return -1;
		if (a.trackerName > b.trackerName) return 1;
		return 0;
	});

	return enabledTrackers;
});

/**
 * Return enabled trackers for selected client
 */
export const getEnabledTrackers = createSelector(getCustomTrackers, (state) =>
	state.filter((tracker) => tracker.isEnabled),
);

/**
 * Return tracker categories, filtered by enabled trackers, for selected client
 */
export const getFilteredTrackerCategories = createSelector(
	[getEnabledTrackers, getTrackerCategories],
	(enabledTrackers, trackerCategories) => {
		return trackerCategories.map((trackerCategory) => {
			return {
				...trackerCategory,
				trackers: trackerCategory.trackers.filter(
					(tracker) => !enabledTrackers.some((enabledTracker) => enabledTracker.id === tracker.id),
				),
			};
		});
	},
);

/**
 * Return boolean,
 * true if no any custom trackers can be added
 * and false if at least 1 custom trackers can be added
 */
export const getIsAllTrackersOn = createSelector(getFilteredTrackerCategories, (trackerCategories) => {
	const nonDefaultTrackerCategories: TrackerCategory[] = trackerCategories.filter((trackerCategory) => {
		return trackerCategory.id !== defaultTrackerCategory;
	});
	const isAllEmpty: boolean = nonDefaultTrackerCategories.reduce((result, trackerCategory) => {
		const isEmpty: boolean = trackerCategory.trackers.length === 0;

		return result && isEmpty;
	}, true);

	return isAllEmpty;
});

/**
 * Returns sensorData by sensor name for selected client id.
 * Also filter sensor data within interval range.
 */
export const getTrackerDataBySensorName = (sensorName: string) =>
	createSelector(getCurrentInterval, getTrackerDataMap, (interval, trackerDataMap) => {
		const trackerData = trackerDataMap[sensorName] || [];
		return trackerData.filter((datum) =>
			moment(datum.date).isBetween(interval.start, interval.end, undefined, "[]"),
		);
	});

export const isManageTrackersDialogOpened = createSelector(
	getManageTrackersState,
	(state) => state.isManageTrackersDialogOpened,
);

export const getManageTrackersSavingState = (state: AppState) => {
	return getManageTrackersState(state).savingState;
};

/**
 * Returns a function to get tracker's diary entry data.
 */
export const getTrackerDiaryEntryUrlFn = createSelector(
	getContactBySelectedClientId,
	getSelectedClientActivities,
	(contactData, eventViewData) => {
		if (!contactData) {
			return null;
		}

		return (customTrackerId: string) => {
			const eventView = eventViewData.find((data) => {
				let trackers = [];
				if (data.type === EventViewType.DIARY_ENTRY_SENSOR) {
					// Get `trackers` from diary entry event view data
					trackers = data.source.value.trackers;
				} else if (data.type === EventViewType.RECURRING_PLANNED_EVENT) {
					// Get `trackers` from planned event view data
					trackers = data.source.payload.plannedEvent.reflection?.sensorData.value.trackers;
				}

				if (!trackers || trackers.length === 0) {
					return false;
				}
				return trackers.some((tracker) => tracker.sensorData.id === customTrackerId);
			});
			if (!eventView) {
				return null;
			}
			return createEventViewDataLink(contactData.hashId, eventView.id);
		};
	},
);

/**
 * Merge UserTracker with TrackerOrder from UserSettings.
 *
 * @returns list of tracker id
 */
export const getTrackerOrder = createSelector(
	getUserTrackers,
	getUserTrackerFetchingState,
	(state: AppState) => getTrackerOrderSettingsByUserId(state, state.contacts.selectedContact?.id),
	(userTrackers, userTrackerFetchingState, trackerOrder) => {
		if (
			userTrackerFetchingState !== LoadingState.LOADED ||
			trackerOrder.length === 0 ||
			userTrackers.length === 0
		) {
			return [];
		}
		return mergeTrackerOrderWithUserTrackers(
			trackerOrder,
			userTrackers.map((tracker) => tracker.id),
		);
	},
);
/*
 * Get customTracker with CustomTrackerEventView type
 * it transforms trackerDataMap into flat list
 * and transforms each value using transformTrackerDatumToTrackerEventView
 */
export const getCustomTrackerEventViewData = createSelector(
	getUserTrackerState,
	getSelectedContactId,
	(userTracker, contactId) => {
		const trackerDataMap = userTracker ? userTracker.trackerDataMap : {};

		// transform TrackerDataMap to TrackerDatum []
		const trackerData: TrackerDatum[] = Object.values(trackerDataMap).reduce((temp, tracker) => {
			temp.push(...tracker);
			return temp;
		}, []);

		// transform TrackerDatum[] to EventViewData[]
		const trackerEventData: CustomTrackerEventView[] = trackerData.map((tracker) => {
			return transformTrackerDatumToTrackerEventView(tracker, contactId);
		});

		return trackerEventData;
	},
);

/**
 * Custom Tracker Dialog
 */

export const getCustomTrackerDialog = (state: AppState) => {
	return getCustomTrackerState(state).trackerDialog;
};

export const getCustomTrackerFetchState = (state: AppState): LoadingState => {
	return getCustomTrackerDialog(state).customTrackerFetchState;
};

export const getCustomTrackerFetchData = (state: AppState): SensorDatum<CustomTrackerSensor> => {
	return getCustomTrackerDialog(state).customTrackerData;
};

/**
 * GraphV2 selector
 */

const getGraphV2 = (state: AppState) => getCustomTrackerState(state).userGraphV2Map;

export const getTrackerGraphV2 = createSelector(getUserTrackers, (trackers): TrackerItem[] => {
	return trackers.filter((trackerItem) => {
		return trackerItem.isEnabled && isGraphV2Supported(trackerItem.sensorName);
	});
});

export const getTrackersGraphV2Name = createSelector(getTrackerGraphV2, (trackers): string[] => {
	return trackers.map((tracker) => {
		return tracker.sensorName;
	});
});

export const getSupportedTrackerAllTimeViewGraphName = createSelector(getUserTrackers, (trackers): string[] => {
	return trackers
		.filter((trackerItem) => {
			return trackerItem.isEnabled && isAllTimeViewGraphSupported(trackerItem.sensorName);
		})
		.map((tracker) => tracker.sensorName);
});

export const getSupportedTrackerOverviewGraphName = createSelector(getUserTrackers, (trackers): string[] => {
	return trackers
		.filter((trackerItem) => {
			return trackerItem.isEnabled && isOverviewGraphSupported(trackerItem.sensorName);
		})
		.map((tracker) => tracker.sensorName);
});

const getUserTrackerGraphV2Map = (state: AppState) => {
	const hashId = getSelectedContactHashId(state);
	if (hashId) {
		return getGraphV2(state)[hashId];
	}
	return null;
};

export const getUserTrackerGraphV2FetchState = (state: AppState) => {
	// adding optional chaining to prevent crash
	return getUserTrackerGraphV2Map(state)?.fetchingState;
};

export const getUserTrackerGraphV2SensorData = (state: AppState) => {
	// adding optional chaining to prevent crash
	return getUserTrackerGraphV2Map(state)?.trackerDataMap;
};

export const getRecentUserTrackerGraphV2SensorData = (trackerName: string[]) =>
	createSelector(getUserTrackerGraphV2SensorData, (trackerDataMap): TrackerNameWithData[] => {
		// filter undefined tracker
		const filteredTrackerName = trackerName.filter((name) => {
			return trackerDataMap?.[name];
		});

		const recentTrackerData: SensorDatum<CustomTrackerSensor>[] = filteredTrackerName.map((name) => {
			return trackerDataMap[name][trackerDataMap[name].length - 1];
		});

		const sortedTrackerData = recentTrackerData.sort((a, b) => {
			return b.startTime.getTime() - a.startTime.getTime();
		});

		// sort tracker with registration to show first
		const sortedTrackerNameWithRegistration: string[] = sortedTrackerData
			.map((tracker) => {
				return tracker?.sensorName;
			})
			.filter((trackerName) => {
				return trackerName;
			});

		// list of tracker without any registration
		const trackerWithoutRegistration: string[] = filteredTrackerName.filter((name) => {
			return trackerDataMap?.[name].length === 0;
		});

		// merge tracker with and without registration
		const sortedTrackerNameByRecentRegistration: string[] = [
			...sortedTrackerNameWithRegistration,
			...trackerWithoutRegistration,
		];

		const recentUserTrackerGraphV2SensorData = sortedTrackerNameByRecentRegistration.map((trackerName) => {
			return {
				trackerName: trackerName,
				data: trackerDataMap[trackerName],
			};
		});

		return recentUserTrackerGraphV2SensorData;
	});
