import {createSelector} from "reselect";
import moment from "moment";

import {AppState} from "redux/AppState";
import {LoadingState} from "constants/redux";

import {TherapySessionFormValues} from "../types";
import {getDurationTimeUnit} from "../../../taskRegistration/helpers/common";

const getTherapySessionState = (state: AppState) => state.therapySession;

export const getUserId = (state: AppState) => {
	return getTherapySessionState(state).userId;
};

export const getTherapySessionForm = (state: AppState): TherapySessionFormValues => {
	const therapySession = getTherapySessionState(state).therapySession;
	if (!therapySession) {
		return;
	}
	const therapySessionValue = therapySession?.value;
	const {duration, timeUnit} = getDurationTimeUnit(therapySession.startTime, therapySessionValue?.plannedFor);

	return {
		title: therapySessionValue.title,
		description: therapySessionValue.description,
		meetInfo: therapySessionValue?.meetInfo?.meetType,
		meetDetails: therapySessionValue?.meetInfo?.meetDetails,
		startTime: therapySession.startTime,
		endTime: therapySession.endTime,
		smqToogle: !!therapySessionValue.plannedSmq,
		omqToogle: !!therapySessionValue.plannedOmq,
		reminderToggle: therapySessionValue.shouldSendNotification,
		reminderDuration: duration,
		reminderTimeUnit: timeUnit,
		sessionDuration: moment(therapySession.endTime).diff(therapySession.startTime, "minutes"),
	};
};

export const isFormOpened = (state: AppState) => {
	return getTherapySessionState(state).isFormOpened;
};

export const isSavingSession = (state: AppState) => {
	return getTherapySessionState(state).savingState === LoadingState.LOADING;
};

export const sessionSavingState = (state: AppState) => {
	return getTherapySessionState(state).savingState;
};

export const sessionDeleteState = (state: AppState) => getTherapySessionState(state).deleteState;

export const getSavedFormValuesMap = (state: AppState) => {
	return getTherapySessionState(state).savedFormValues;
};

export const getSavedFormValues = createSelector(
	getUserId,
	getSavedFormValuesMap,
	(userId, savedFormValuesMap) => savedFormValuesMap[userId],
);

export const getTherapySessionData = (state: AppState) => {
	return getTherapySessionState(state).therapySession;
};

export const getTherapySessionFetchState = (state: AppState): LoadingState => {
	return getTherapySessionState(state).fetchState;
};

export const getTherapySessionId = (state: AppState): string => {
	return getTherapySessionState(state).therapyId;
};
