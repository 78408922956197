import {call, select, take} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {createSendMessage} from "../../../IWC/IWC";
import {WinMsgTypes} from "../../../IWC/WinMsg";
import {getActiveCall} from "../../../ts/redux/videoCall/VideoCallSelectors";
import {callActions} from "../../redux/callActions";

/**
 * List of action types that can change `ActiveCall` data in redux state
 */
const activeCallActions = [
	getType(callActions.createActiveCall),
	getType(callActions.userInitiateCall),
	getType(callActions.userJoinCall),
	getType(callActions.userLeaveCall),
];

/**
 * Sync latest `ActiveCall` data between main window and call window
 */
export function* updateActiveCallData(callWindow: Window) {
	let prevActiveCall;

	yield call(sendActiveCallData, callWindow);

	while (true) {
		// Only take actions that can change `activeCall` data
		yield take(activeCallActions);

		const activeCall = yield select(getActiveCall);

		// Only send latest updated data
		if (JSON.stringify(prevActiveCall) !== JSON.stringify(activeCall)) {
			prevActiveCall = activeCall;
			yield call(sendActiveCallData, callWindow);
		}
	}
}

function* sendActiveCallData(callWindow: Window) {
	const sendIWCMessage = yield call(createSendMessage, callWindow);
	const activeCall = yield select(getActiveCall);
	yield call(sendIWCMessage, {type: WinMsgTypes.ACTIVE_CALL_UPDATE, data: activeCall});
}
