import {ActionType, createAction} from "typesafe-actions";
import {AppState} from "../AppState";
import {ThunkAction} from "redux-thunk";
import {Dispatch} from "redux";

export namespace UIAction {
	export type UIActionThunkActionType = ThunkAction<void, AppState, any, UIActionType>;

	export const openChatBox = createAction("UIActions/OpenChatBox")();
	export const closeChatBox = createAction("UIActions/closeChatBox")();
	export const toggleChatBox = createAction("UIActions/ToggleChatBox")();
	export const toggleFullscreenChatBox = createAction("UIActions/ToggleFullscreenChatBoxWindow")();

	const combinedActions = {
		openChatBox,
		closeChatBox,
		toggleChatBox,
		toggleFullscreenChatBox,
	};

	//
	// THUNK ACTIONS
	//

	/**
	 * Load recurring schedules and convert it to LoggerConfig
	 *
	 * @param {Interval} interval interval from TrackingState
	 * @param {number} userId
	 */
	export const collapseChatBox = (): UIActionThunkActionType => {
		return (dispatch: Dispatch<UIActionType>, getState) => {
			const state = getState();

			if (state.UI.chatBoxState.isChatBoxOpen) {
				dispatch(UIAction.toggleChatBox());
			}
		};
	};

	export type UIActionType = ActionType<typeof combinedActions>;
}
