import {TSMap} from "typescript-map";

/** returns the first item of the array for which the checkFn returns true */
export function first<T>(arr: Array<T>, checkFn: (d: T) => boolean): T | null {
	for (let i = 0; i < arr.length; i++) {
		if (checkFn(arr[i])) {
			return arr[i];
		}
	}
	return null;
}

/** Group array by value returned via 'keyFn'. Returns a TSMap */
export function groupBy<K, T>(arr: Array<T>, keyFn: (d: T) => K): TSMap<K, Array<T>> {
	const map: TSMap<K, Array<T>> = new TSMap();
	if (!arr) return map;
	arr.forEach((d) => {
		const key = keyFn(d);
		const group = map.get(key);
		if (group) {
			group.push(d);
		} else {
			map.set(key, [d]);
		}
	});
	return map;
}

/**
 *  Partition a collection of items 'arr' into a collection of collection of items,
 *  in which no more than 'count' number of items is grouped together.
 */
export function partition<T>(arr: T[], count: number): T[][] {
	return arr.reduce<T[][]>((accum, x) => {
		if (accum.length === 0 || accum[accum.length - 1].length >= count) {
			accum.push([]);
		}

		accum[accum.length - 1].push(x);
		return accum;
	}, []);
}
