import {Contact} from "./contactTypes";
import {UserRole} from "@sense-os/goalie-js";

/**
 * In regards to issue https://github.com/senseobservationsystems/web-getgoalie/issues/1322
 * Portal is doing a batch request to fetch last contact and unread information for each contacts.
 *
 * To support this, the following function will help portal prioritize which contact will have its information
 * be fetched first.
 */
export function prioritizeContacts(contacts: Contact[]): Contact[] {
	const sortedContacts = [...contacts];
	sortedContacts.sort((a, b) => {
		/**
		 * This sorting function will help us prioritize clients for mentioned batch request.
		 * With this function, what we prioritize are user with PATIENT role and user with high user id.
		 */
		if (a.role === b.role || (a.role !== UserRole.PATIENT && b.role !== UserRole.PATIENT)) {
			return b.id - a.id;
		}

		if (a.role === UserRole.PATIENT) {
			return -1;
		}
		if (b.role === UserRole.PATIENT) {
			return 1;
		}

		return b.id - a.id;
	});

	return sortedContacts;
}

/**
 * Returns user `hashId` from browser's url
 *
 * e.g: /app/client/A7X4e/details -> `A7X4e` is the hashId
 */
export function getContactHashIdFromPathname(pathname: string): string {
	const hashIdRegexPattern = /\/app\/(client|therapist)\/(.*)/;
	const match = pathname.match(hashIdRegexPattern);

	if (match && match.length > 0) {
		const sliced = match?.pop().split("/");
		return sliced[0];
	}
	return null;
}
