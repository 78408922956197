import {LoadingState} from "constants/redux";
import moment from "moment";
import {AppState} from "redux/AppState";
import {EventViewData, EventViewType} from "redux/tracking/TrackingTypes";
import {createSelector} from "reselect";
import {getContactById} from "../../../contacts/redux/contactSelectors";
import {convertRRuleToUIState} from "../../../customRepetition/helpers/RRuleHelpers";
import {PlanActivityFormValues} from "./planActivityTypes";

const getPlanActivityState = (state: AppState) => state.planActivity;
export const getPlanActivityUserId = createSelector(getPlanActivityState, (state) => state.userId);
export const getPlanActivityEventId = createSelector(getPlanActivityState, (state) => state.eventId);
export const isReplanningActivity = createSelector(getPlanActivityState, (state) => state.isReplan);

const planActivityClient = (state: AppState) => getContactById(state, getPlanActivityUserId(state));

export const isFormOpened = createSelector(
	planActivityClient,
	(state) => state.planActivity.isFormOpened,
	(client, isOpen) => {
		return !!client && isOpen;
	},
);

export const isConfirmationDialogOpened = createSelector(
	planActivityClient,
	(state) => state.planActivity.isConfirmationDialogOpened,
	(client, isOpen) => {
		return !!client && isOpen;
	},
);

/**
 * Transform EventViewData into PlanActivityFormValues.
 */
export const getFormValuesFromEventViewData = (state: AppState): Partial<PlanActivityFormValues> => {
	const eventData = getPlannedActivityData(state);
	if (
		!eventData ||
		(eventData.type !== EventViewType.PLANNED_EVENT_SENSOR &&
			eventData.type !== EventViewType.RECURRING_PLANNED_EVENT)
	) {
		return null;
	}

	if (eventData.type === EventViewType.PLANNED_EVENT_SENSOR) {
		const sensor = eventData.source;
		return {
			title: sensor.value.title,
			activityType: sensor.value.activityType,
			description: sensor.value.description,
			// @ts-ignore
			feelings: sensor.value.feeling ? sensor.value.feeling.sensorData.value : {},
			plannedFor: moment(sensor.value.plannedFor).toDate(),
			// Forced to be set as false, to cancel notification service on the backend.
			shouldSendNotification: eventData.isCanceled ? false : sensor.value.shouldSendNotification,
		};
	} else if (eventData.type === EventViewType.RECURRING_PLANNED_EVENT) {
		const {payload} = eventData.source;
		const {title, description, activityType, expectedFeeling, recurringExpression} = payload.plannedEvent;
		const {customRepetition, repeatedOption} = convertRRuleToUIState(recurringExpression.rrule);
		return {
			activityType,
			title,
			description,
			// @ts-ignore
			feelings: expectedFeeling ? expectedFeeling.sensorData.value : {},
			// Forced to be set as false, to cancel notification service on the backend.
			shouldSendNotification: eventData.isCanceled ? false : recurringExpression.reminderEnabled,
			customRepetition,
			repeatedOption,
			plannedFor: moment(eventData.source.occurrenceTime).toDate(),
		};
	}
};

/**
 * Return draft form values.
 */
export const getSavedFormValues = (state: AppState) => {
	const userId = getPlanActivityUserId(state);
	return state.planActivity.savedFormValues[userId];
};

export const isSavingPlanActivity = createSelector(
	getPlanActivityState,
	(state) => state.savingState === LoadingState.LOADING,
);

export const getPlannedActivitySavingState = (state: AppState): LoadingState => {
	return getPlanActivityState(state).savingState;
};

export const getPlannedActivityFetchState = (state: AppState): LoadingState => {
	return getPlanActivityState(state).fetchState;
};

export const getPlannedActivityData = (state: AppState): EventViewData => {
	return getPlanActivityState(state).plannedActivity;
};

export const getPlannedActivityDeletingState = (state: AppState): LoadingState => {
	return getPlanActivityState(state).deletingState;
};
