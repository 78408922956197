import {AppConfig} from "app/AppConfig";
import {call, delay, put, race, select} from "redux-saga/effects";
import createLogger from "../../logger/createLogger";
import {connectionStatusActions} from "../redux/connectionStatusActions";
import {isOnline} from "../redux/connectionStatusSelector";

const TIMEOUT_MS = 5000;
const INTERVAL_MS = 5000;

const logger = createLogger("connectionStatus");

function* ping(url: string) {
	const startTime = Date.now();
	let result: Partial<{success: boolean; timeout: boolean; time: number}> = {};
	try {
		const {timeout} = yield race({
			status: call(fetch, url + "?_=" + startTime),
			timeout: delay(TIMEOUT_MS),
		});

		if (timeout) {
			result.success = false;
			result.timeout = true;
		} else {
			result.success = true;
		}
	} catch (error) {
		result.success = false;
	}
	result.time = Date.now() - startTime;
	return result;
}

export default function* connectionStatusSaga() {
	// Number of ping fail attempts
	let failCount = 0;
	// Max number of ping fail attempts before changing the online status to OFFLINE
	const maxFailAttempts = 2;
	while (true) {
		yield delay(INTERVAL_MS);

		const online = yield select(isOnline);
		const url = AppConfig.internetConnectionLongPollingUrl;

		const pingResult = yield call(ping, url);

		// Ping success
		if (pingResult.success) {
			if (!online) {
				logger.addBreadcrumb({
					message: failCount > 0 ? "Portal is back online" : "Portal is online",
				});
				yield put(connectionStatusActions.changeOnlineStatus(true));
			}
			// Reset fail count
			failCount = 0;
			continue;
		}

		if (failCount === maxFailAttempts) {
			if (!online) {
				continue;
			}
			yield put(connectionStatusActions.changeOnlineStatus(false));
			logger.addBreadcrumb({
				message: "Portal is offline",
			});
		} else {
			failCount += 1;
			logger.addBreadcrumb({
				message: "Ping to " + url + " failed. Fail count: " + failCount,
				data: pingResult,
			});
		}
	}
}
