import {MeetingNote, SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction, createAction} from "typesafe-actions";
import {MeetingNoteFormValues} from "../meetingNotesTypes";

export const meetingNotesActions = {
	//
	// Meeting note form dialog
	//
	openForm: createAction("meetingNoteActions/openForm", (params: {userId?: number; noteId?: string}) => params)(),
	closeForm: createAction("meetingNoteActions/closeForm")(),
	resetForm: createAction("meetingNoteActions/resetForm")(),

	//
	// Confirmation dialog
	//
	openConfirmationDialog: createAction("meetingNoteActions/openConfirmationDialog", (userId: number) => ({
		userId,
	}))(),
	closeConfirmationDialog: createAction("meetingNoteActions/closeConfirmationDialog")(),

	//
	// Save form values actions
	//
	saveFormValues: createAction(
		"meetingNoteActions/saveFormValues",
		(userId: number, formValues?: MeetingNoteFormValues) => ({userId, formValues}),
	)(),

	//
	// Save meeting note actions
	//
	saveMeetingNotes: createAsyncAction(
		"meetingNoteActions/saveMeetingNotes",
		"meetingNoteActions/saveMeetingNotesSucceed",
		"meetingNoteActions/saveMeetingNoteFailed",
	)<{userId: number; formValues: MeetingNoteFormValues}, {userId: number}, Error>(),
	//
	// Fetch meeting note actions
	//
	fetchMeetingNote: createAsyncAction(
		"meetingNoteActions/fetchMeetingNote",
		"meetingNoteActions/fetchMeetingNoteSucceed",
		"meetingNoteActions/fetchMetingNoteFailed",
	)<{userId: number; noteId: string}, {userId: number; meetingNoteData: SensorDatum<MeetingNote>}, Error>(),
};

export type MeetingNotesActionType = ActionType<typeof meetingNotesActions>;
