import moment from "moment";
import {call, put, select, take, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {history} from "visual/App";
import {getSelectedContactId} from "../../contacts/redux/contactSelectors";

import {loadAllData, setCurrentInterval} from "../../ts/redux/tracking/TrackingAction";
import {EVENT_ID_DELIMITER, getEventIdFromQueryString} from "../../ts/redux/tracking/TrackingHelper";
import {newInterval} from "../../ts/redux/tracking/TrackingIntervalHelper";
import {TimeRange} from "../../ts/redux/tracking/TrackingTypes";
import {endOfWeek, startOfRange} from "../../ts/utils/time";

import {userSettingsAction} from "../../userSettings/redux/userSettingsActions";
import {
	getDefaultTimeRangeForActivitiesDashboard,
	getIsUserSettingsLoaded,
} from "../../userSettings/redux/userSettingsSelectors";
import {clientActivityActions} from "../redux/clientActivitiyActions";

function* changeInterval(action: ActionType<typeof clientActivityActions.changeInterval>) {
	const {interval} = action.payload;
	const selectedClientId = yield select(getSelectedContactId);

	yield put(setCurrentInterval(interval));
	yield put(
		userSettingsAction.saveSettings.request({
			settings: {defaultTimeRangeForActivitiesDashboard: interval.range},
		}),
	);

	const loadAllDataAction = yield call(loadAllData, interval, selectedClientId);
	yield put(loadAllDataAction);
}

function* setDefaultIntervalForDashboard() {
	const isUserSettingsLoaded: boolean = yield select(getIsUserSettingsLoaded);
	if (!isUserSettingsLoaded) {
		yield take(userSettingsAction.loadSettings.success);
	}

	const defaultTimeRangeForActivitiesDashboard: TimeRange = yield select(getDefaultTimeRangeForActivitiesDashboard);

	let end = endOfWeek(Date.now()).valueOf();
	const eventId: string = getEventIdFromQueryString(history.location.search);
	if (eventId) {
		const splittedEventId: string[] = eventId.split(EVENT_ID_DELIMITER);
		// There is a chance that the `splittedEventId[2]` has year less than 2017 (No sensors was posted even before 2018)
		// e.g when the user delete encoded `eventId` query string
		if (splittedEventId.length === 3 && moment(Number(splittedEventId[2])).year() >= 2017) {
			end = endOfWeek(Number(splittedEventId[2] || Date.now())).valueOf();
		}
	}

	const start = startOfRange(end, defaultTimeRangeForActivitiesDashboard || TimeRange.WEEK);

	yield put(
		clientActivityActions.changeInterval(
			newInterval(start, end, defaultTimeRangeForActivitiesDashboard || TimeRange.WEEK),
		),
	);
}

export default function* () {
	yield takeEvery(getType(clientActivityActions.changeInterval), changeInterval);
	yield takeEvery(getType(clientActivityActions.setDefaultIntervalForDashboard), setDefaultIntervalForDashboard);
}
