import {MessageMarker, MessageMarkerType} from "@sense-os/goalie-js";
import {eventChannel} from "redux-saga";
import {cancelled, fork, put, select, take} from "redux-saga/effects";
import {ChatRoomAction} from "../redux/ChatRoomAction";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import chatSDK from "../sdk";

const log = createLogger("messageMarkerSaga", SentryTags.Chat);

const createMessageMarkerSubscriptionChannel = () => {
	return eventChannel<MessageMarker>((emitter) => {
		const subscriptionId = chatSDK.subscribeToMessageMarker(emitter);
		return () => {
			chatSDK.unsubscribeFromMessageMarker(subscriptionId);
		};
	});
};

export function* messageMarkerSubscriptionHandler() {
	const channel = createMessageMarkerSubscriptionChannel();
	try {
		while (true) {
			const messageMarker: MessageMarker = yield take(channel);
			yield fork(messageMarkerChangesHandler, messageMarker);
		}
	} finally {
		if (yield cancelled()) {
			channel.close();
		}
	}
}

export function* messageMarkerChangesHandler(marker: MessageMarker) {
	log.debug("onMessageMarkerUpdate", marker);
	const authUser: AuthUser = yield select(getAuthUser);
	const userId: number = marker.remoteUserId === authUser.id ? marker.senderId : marker.remoteUserId;
	if (marker.mark === MessageMarkerType.Delivered) {
		log.debug(marker.messageId, "Delivered");
		yield put(ChatRoomAction.setDeliveredTimestampMs(userId, marker.markerTimestamp));
	} else if (marker.mark === MessageMarkerType.Read) {
		// Sender is from another instance, meaning another instance has successfully mark a message as read
		// Therefore this current instance should also mark the message as read too
		const readByOtherInstance = marker.senderId === authUser.id;
		if (readByOtherInstance) {
			log.debug(marker.messageId, "Read by other instance marker");
			yield put(ChatRoomAction.setSentReadMarkerTimestampMs(userId, marker.markerTimestamp));
		} else {
			log.debug(marker.messageId, "Read");
			yield put(ChatRoomAction.setReadTimestampMs(userId, marker.markerTimestamp));
		}
	}
}
