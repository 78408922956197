import {PrivateNote} from "@sense-os/goalie-js";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {getSessionId} from "../../auth/helpers/authStorage";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";

import {privateNotesActions} from "../redux/privateNotesAction";
import {getPatientNotes} from "../redux/privateNotesSelector";
import privateNotesSdk from "../privateNotesSdk";
import {LoadingState} from "constants/redux";

const logger = createLogger("startCallSaga", SentryTags.PrivateNotes);

/**
 * Fetch the note of a client given the hashId of the client.
 */
function* fetchNoteOfPatient(action: ActionType<typeof privateNotesActions.fetchPrivateNote.request>) {
	const {clientHashId, clientId} = action.payload;
	try {
		const token: string = yield call(getSessionId);
		const privateNotes: PrivateNote[] = yield apiCallSaga(privateNotesSdk.getPrivateNotes, token, clientHashId);

		// If the private note exists in BE, it'll be the first element of the array
		// that's returned by BE.
		const privateNote = privateNotes[0];

		yield put(privateNotesActions.fetchPrivateNote.success({clientId, privateNote}));
		yield put(
			privateNotesActions.broadcastClientNoteToCallWindow(clientId, {
				fetchState: LoadingState.LOADED,
				note: privateNote?.value,
				noteId: privateNote?.id,
			}),
		);
	} catch (err) {
		logger.captureException(err, {message: "Fail to fetch the note of a patient"});
		yield put(privateNotesActions.fetchPrivateNote.failure({clientId}));
	}
}

/**
 * Depending on the existence of the id in the private note,
 * this saga will create or update the private notes per given privateNote string.
 */
function* createOrUpdatePrivateNote(action: ActionType<typeof privateNotesActions.createOrUpdatePrivateNote.request>) {
	const {clientId, note} = action.payload;
	try {
		const token: string = yield call(getSessionId);
		const patientNotesDetails = yield select(getPatientNotes(clientId));
		let savedPrivateNote: PrivateNote;
		if (patientNotesDetails.noteId) {
			savedPrivateNote = yield apiCallSaga(
				privateNotesSdk.updatePrivateNote,
				token,
				patientNotesDetails.noteId,
				note,
			);
		} else {
			savedPrivateNote = yield apiCallSaga(privateNotesSdk.createPrivateNotes, token, clientId, note);
		}

		yield put(privateNotesActions.createOrUpdatePrivateNote.success({clientId, privateNote: savedPrivateNote}));
		yield put(
			privateNotesActions.broadcastClientNoteToCallWindow(clientId, {
				saveState: LoadingState.LOADED,
				note: savedPrivateNote?.value,
				noteId: savedPrivateNote?.id,
			}),
		);
	} catch (err) {
		logger.captureException(err, {message: "Fail to create/update the note of a patient"});
		yield put(privateNotesActions.createOrUpdatePrivateNote.failure({clientId}));
	}
}

export default function* () {
	yield takeEvery(getType(privateNotesActions.fetchPrivateNote.request), fetchNoteOfPatient);
	yield takeEvery(getType(privateNotesActions.createOrUpdatePrivateNote.request), createOrUpdatePrivateNote);
}
