import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {customTrackerActions} from "../redux/customTrackerActions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {getSensorResolved} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {CustomTrackerSensor} from "../customTrackerTypes";
import {TrackerGraphV2DataMap} from "../customTrackerGraphV2Types";

const log = createLogger("graphV2Saga", SentryTags.CustomTracker);

export function* fetchTrackerGraphV2(action: ActionType<typeof customTrackerActions.fetchTrackerGraphV2.request>) {
	const {userId, hashId, sensorName} = action.payload;
	const token: string = yield call(getSessionId);
	try {
		const response: SensorDatum<CustomTrackerSensor>[] = yield apiCallSaga(
			getSensorResolved,
			token,
			userId,
			sensorName,
			undefined,
			undefined,
		);

		// default Tracker
		const defaultTrackerGraphV2Data: TrackerGraphV2DataMap = sensorName.reduce((result, current) => {
			return {
				...result,
				[current]: [],
			};
		}, {});

		// reduce graph to each sensor name
		const respTrackerGraphV2Data: TrackerGraphV2DataMap = response.reduce((result, current) => {
			return {
				...result,
				[current.sensorName]: result[current.sensorName]
					? result[current.sensorName].concat(current)
					: [current],
			};
		}, {});

		const trackerGraphV2Data: TrackerGraphV2DataMap = {...defaultTrackerGraphV2Data, ...respTrackerGraphV2Data};

		// sort sensor ascending by startTime date
		sensorName.forEach((name) => {
			trackerGraphV2Data?.[name]?.sort((a, b) => {
				return a.startTime.getTime() - b.startTime.getTime();
			});
		});

		yield put(customTrackerActions.fetchTrackerGraphV2.success({trackerGraphV2Data, hashId}));
	} catch (err) {
		log.captureException(err);
		yield put(customTrackerActions.fetchTrackerGraphV2.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(customTrackerActions.fetchTrackerGraphV2.request), fetchTrackerGraphV2);
}
