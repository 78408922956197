import {ActionType, getType} from "typesafe-actions";
import {inviteClientsActions} from "../redux/inviteClientsActions";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {put, takeEvery} from "redux-saga/effects";
import {toastActions} from "../../toaster/redux";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import inviteClientsSDK from "../helpers/inviteClientsSdk";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("resendInviteClientSaga", SentryTags.InviteClients);

export function* resendInviteClientSaga(action: ActionType<typeof inviteClientsActions.resendInviteClient.request>) {
	const {id} = action.payload;

	try {
		const token: string = getSessionId();

		yield apiCallSaga(inviteClientsSDK.reSendClientInvitation, token, id);

		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.CASELOAD.invite_client.resend.toast.success),
				type: "success",
			}),
		);

		yield put(inviteClientsActions.resendInviteClient.success());
	} catch (error) {
		log.error("Error while resending invite client", error);
		yield put(inviteClientsActions.resendInviteClient.failure({error}));
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.CASELOAD.invite_client.resend.toast.fail),
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(inviteClientsActions.resendInviteClient.request), resendInviteClientSaga);
}
