import {takeEvery} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {interventionCustomTrackerActions} from "../redux/interventionCustomTrackerActions";
import {fetchCustomTrackers} from "./fetchCustomTrackers";
import {fetchRegisteredCustomTrackers} from "./fetchRegisteredCustomTrackers";
import {enableOrDisableTrackers} from "./enableOrDisableTrackers";

export default function* () {
	yield takeEvery(getType(interventionCustomTrackerActions.fetchCustomTrackers.request), fetchCustomTrackers);
	yield takeEvery(
		getType(interventionCustomTrackerActions.fetchRegisteredCustomTrackers.request),
		fetchRegisteredCustomTrackers,
	);
	yield takeEvery(getType(interventionCustomTrackerActions.enableOrDisableTrackers.request), enableOrDisableTrackers);
}
