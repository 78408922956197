import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import {referralClientActions} from "../redux/referralClientAction";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import ReferralClientSDK from "../helper/referralClient";
import {
	IReferralQuestionnaireQueryOptions,
	IReferralQuestionnairesResponse,
	REFERRAL_QUESTIONNAIRE_STATUS,
} from "@sense-os/goalie-js/dist/referralClient/type";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {referralQuestionnaireMenuType} from "../referralClientTypes";

const log = createLogger("fetchReferralClient", SentryTags.ReferralClient);

export function* fetchReferredClient(action: ActionType<typeof referralClientActions.fetchReferralClientList.request>) {
	const {page, pageSize, status} = action.payload;
	const token = yield call(getSessionId);

	/*
	 * delete status from query if is process
	 * process is not part of questionnaire status
	 */
	const statusQuey: REFERRAL_QUESTIONNAIRE_STATUS =
		status.toUpperCase() in REFERRAL_QUESTIONNAIRE_STATUS ? (status as REFERRAL_QUESTIONNAIRE_STATUS) : undefined;

	// processed is not a status, but portal shows it like status menu
	const isProcessed: boolean = status.toUpperCase() in REFERRAL_QUESTIONNAIRE_STATUS ? false : true;

	const options: IReferralQuestionnaireQueryOptions = {
		page,
		pageSize,
		status: statusQuey,
		isProcessed,
	};

	try {
		const response: IReferralQuestionnairesResponse = yield apiCallSaga(
			ReferralClientSDK.getReferralQuestionnaires,
			token,
			options,
		);
		yield put(
			referralClientActions.fetchReferralClientList.success({
				data: response.results,
				total: response.count,
				statusCount: response.statusCount as Record<referralQuestionnaireMenuType, number>,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(referralClientActions.fetchReferralClientList.failure());
	}
}

export default function* () {
	yield takeEvery(getType(referralClientActions.fetchReferralClientList.request), fetchReferredClient);
}
