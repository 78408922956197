import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {takeEvery, call, put, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {UserProfileAction} from "../redux/UserProfileAction";
import {getSessionId, getAuthUserId} from "../../auth/helpers/authStorage";
import {UserDataRequestBody} from "@sense-os/goalie-js";
import profileSDK from "../helpers/profileSDK";
import {toastActions} from "../../toaster/redux/toastAction";
import Localization, {ILocalization} from "../../localization/Localization";
import {authActions} from "../../auth/redux/authActions";
import {getAuthUser} from "../../auth/redux";
import {goBack} from "connected-react-router";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("userProfileSaga", SentryTags.UserProfile);
const loc: ILocalization = Localization;

function* handleSaveBio(action: ActionType<typeof UserProfileAction.saveUserBio.request>) {
	const token = yield call(getSessionId);
	const userId = yield call(getAuthUserId);

	try {
		// Update user bio
		const userBio: UserDataRequestBody = {
			bio: action.payload.userBio,
		};
		yield call(profileSDK.updateUserData, token, userId, userBio);
		// Refetch user data
		const userData = yield call(profileSDK.getUserData, token, userId);
		// Showing success toast
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.USER_PROFILE.profile_updated.toast, {
					name: userData.firstName,
				}),
				type: "info",
			}),
		);

		yield put(authActions.updateAuthUser({data: userData}));
		yield put(UserProfileAction.saveUserBio.success());
		yield put(goBack());
	} catch (error) {
		// Showing error toast
		const authUser = yield select(getAuthUser);
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.USER_PROFILE.profile_update_failed.toast, {
					name: authUser.data.firstName,
				}),
				type: "error",
			}),
		);
		yield put(UserProfileAction.saveUserBio.failure(error));
		yield put(goBack());
		log.captureException(error);
	}
}

export default function* () {
	yield takeEvery(getType(UserProfileAction.saveUserBio.request), handleSaveBio);
}
