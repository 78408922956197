import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {RawIntlProvider} from "react-intl";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ThemeProvider} from "@material-ui/styles";
import MomentUtils from "@date-io/moment";
import {SnackbarProvider} from "notistack";
import {ThemeProvider as Mui5ThemeProvider} from "@mui/material";

import {store} from "../redux/StoreContainer";
import Localization from "../../localization/Localization";
import {createNicedayTheme} from "../../material-ui";
import App, {history} from "./App";
import Notifier from "../../toaster/notifier";
import {ConnectedRouter} from "connected-react-router";
import {PrevPathnameProvider} from "../../router/PrevPathnameProvider";
import {ndTheme} from "../../materialUIV5/theme";

const theme = createNicedayTheme({});

export function renderApplication(): void {
	ReactDOM.render(
		<Provider store={store}>
			<RawIntlProvider value={Localization.intl}>
				<Mui5ThemeProvider theme={ndTheme}>
					<MuiPickersUtilsProvider utils={MomentUtils} locale={Localization.getLocale()}>
						<ThemeProvider theme={theme}>
							<ConnectedRouter history={history}>
								<SnackbarProvider
									disableWindowBlurListener
									maxSnack={5}
									anchorOrigin={{vertical: "bottom", horizontal: "center"}}
								>
									<PrevPathnameProvider>
										<App />
										<Notifier />
									</PrevPathnameProvider>
								</SnackbarProvider>
							</ConnectedRouter>
						</ThemeProvider>
					</MuiPickersUtilsProvider>
				</Mui5ThemeProvider>
			</RawIntlProvider>
		</Provider>,

		document.getElementById("app"),
	);
}
