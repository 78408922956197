import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {AssessmentDataSource, AssessmentExpireStatus, AssessmentListFilter} from "../interventionAssessmentTypes";

export const interventionAssessmentActions = {
	// Fetch planned and reflections of OMQ and SMQ actions
	fetchOmqSmq: createAsyncAction(
		"interventionAssessmentActions/fetchOmqSmq",
		"interventionAssessmentActions/fetchOmqSmqSuccess",
		"interventionAssessmentActions/fetchOmqSmqFail",
	)<{userId: number}, {dataSource: AssessmentDataSource[]; totalReflections?: number}, {err: Error}>(),

	// Assessment expire status actions
	setExpireStatus: createAction("interventionAssessmentActions/setExpireStatus", (status: AssessmentExpireStatus) => {
		return {status};
	})(),

	// Assessment data source filter action
	setDataSourceFilter: createAction(
		"interventionAssessmentActions/setDataSourceFilter",
		(filter: AssessmentListFilter) => {
			return {filter};
		},
	)(),
};

export type InterventionAssessmentActionType = ActionType<typeof interventionAssessmentActions>;
