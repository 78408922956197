export const PAGE_SIZE_OPTIONS = [15, 30, 50];

export interface PaginationProps {
	// pagination's id
	paginationId: string;
	// Total whole data
	totalData: number;
	// active current page
	currentPage: number;
	// func that will triggered when changing page
	onChangePage: (page: number) => void;
	// option for showing page size
	hidePageSize?: boolean;
	// option for hiding next and prev button if page only 1
	hideOnSinglePage?: boolean;
	// total data that will show up per page
	pageSize: number;
	// set option list of how many data that will show up
	pageSizeOptions?: number[];
	// func that will triggered when changing page sizer
	onChangePageSize?: (pageSize: number) => void;
}

export type PaginationWithoutId = Omit<PaginationProps, "paginationId">;
