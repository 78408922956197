import {storeDispatch} from "redux/StoreContainer";

/**
 * This function will automatically wrap each member of a given actions object
 * with a dispatch.
 */
export function wrapInDispatch<T>(inputMap: T): T {
	return Object.keys(inputMap).reduce((clone, key) => {
		if (typeof inputMap[key] === "function") {
			clone[key] = (...args: any[]) => {
				storeDispatch(inputMap[key](...args));
			};
		} else if (!!inputMap[key]) {
			clone[key] = wrapInDispatch(inputMap[key]);
		}

		return clone;
	}, {} as T);
}
