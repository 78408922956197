import {CallContact, CallSDK, CallType} from "@sense-os/goalie-js";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {getActiveCall} from "redux/videoCall/VideoCallSelectors";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {canInviteMoreParticipants} from "services/chat/video/VideoCallHelpers";
import {ActionType, getType} from "typesafe-actions";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import chatSDK from "../../chat/sdk";
import createLogger from "../../logger/createLogger";
import {toastActions} from "../../toaster/redux";
import {getFullName} from "../../userProfile/helpers/profileHelpers";
import {callActions} from "../redux/callActions";
import {getCallContactInfoByUserId} from "./callSagaHelpers";

const logger = createLogger("inviteParticipantSaga");

/**
 * Handler to invite new participant into the call
 *
 * @param action
 */
export function* inviteParticipantSaga(action: ActionType<typeof callActions.inviteNewParticipantToCall>) {
	const activeCall: ActiveCall = yield select(getActiveCall);
	const canInvite = yield call(canInviteMoreParticipants, activeCall);

	// Check if user is able to invite more participants to prevent room overlimit
	if (!canInvite) {
		return;
	}

	const {userId} = action.payload;
	const authUser: AuthUser = yield select(getAuthUser);
	const {participantMap, roomId, isConferenceCall} = activeCall;

	const senderInfo: CallContact = yield call(getCallContactInfoByUserId, authUser.id);
	const recipientInfo: CallContact = yield call(getCallContactInfoByUserId, userId);

	// Ensure to use existing sender public id as long as the video call session is not hunged up.
	senderInfo.publicId = participantMap[authUser.id].publicId;

	try {
		yield call(
			chatSDK.initiateCall,
			userId,
			CallType.Video, // Per Kasper request, call type should always be video by default
			senderInfo,
			recipientInfo,
			[CallSDK.Twilio],
			roomId,
			isConferenceCall,
		);
		const initiatedTime = Date.now();
		// Store recepientInfo into participantMap inside redux store
		const newActiveCall: ActiveCall = {
			...activeCall,
			participantMap: {
				...activeCall.participantMap,
				[userId]: {
					...recipientInfo,
					initiatedTime,
				},
			},
		};
		yield put(callActions.createActiveCall(newActiveCall));
		yield put(callActions.startTimeout(userId));

		yield put(
			toastActions.addToast({
				message: "CHAT.video.calling.toast",
				type: "info",
				localizationPayload: {name: getFullName(recipientInfo)},
			}),
		);
	} catch (err) {
		logger.captureException(err, {isConferenceCall});
		yield put(
			toastActions.addToast({
				message: "CHAT.video.call_cant_be_started.toast",
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(callActions.inviteNewParticipantToCall), inviteParticipantSaga);
}
