import {call, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {AccessURLResponse} from "@sense-os/goalie-js";

import {getSessionId} from "../../../auth/helpers/authStorage";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";

import {calendarActions} from "../../redux/calendarActions";
import {calendarSDK} from "../../helpers/calendarSDK";

const log = createLogger("getExternalEventAccessSaga", SentryTags.Calendar);

function* handleExternalEventAccessRequest(action: ActionType<typeof calendarActions.getExternalEventAccess>) {
	try {
		const {calendarId} = action.payload;
		const token: string = yield call(getSessionId);
		const accessURL: AccessURLResponse = yield apiCallSaga(
			calendarSDK.getExternalEventAccessURL,
			token,
			calendarId,
		);

		if (accessURL.url) {
			// Open cronofy access / elevate url in current browser tab
			window.open(accessURL.url, "_self");
		}
	} catch (err) {
		log.captureException(err);
	}
}

export default function* () {
	yield takeEvery(getType(calendarActions.getExternalEventAccess), handleExternalEventAccessRequest);
}
