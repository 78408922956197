import {eventChannel} from "redux-saga";
import {cancel, cancelled, fork, put, select, take, takeEvery, throttle, takeLeading} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {subscribeToInterWindowCommunication, createSendMessageWithType} from "../IWC/IWC";
import {EmdrWinMsg, WinMsgTypes} from "../IWC/WinMsg";

import {SentryTags} from "../errorHandler/createSentryReport";
import createLogger from "../logger/createLogger";
import chatSdk from "../chat/sdk";
import * as callSelectors from "../ts/redux/videoCall/VideoCallSelectors";

import {emdrActions, EmdrActions} from "./emdrActions";
import {EmdrSignal} from "@sense-os/goalie-js";
import {whenLoggedIn} from "../auth/sagas/helper";

const log = createLogger("EmdrSaga", SentryTags.EMDR);

function* sendSignal(
	action: ActionType<typeof emdrActions.sendSignal> | ActionType<typeof emdrActions.sendSignalWithThrottling>,
) {
	const roomId = yield select(callSelectors.getRoomId);
	if (roomId) {
		try {
			log.debug("Send emdr signal", action.payload.signal);
			chatSdk.sendEmdrSignal(roomId, action.payload.signal);
		} catch (e) {
			log.debug("Failed to send emdr signal");
		}
	}
}

/**
 * Any IWC message from video call window will be treated as an action,
 * that will be dispatched directly.
 */
function* subscribeToIwc(targetWindow: Window) {
	const chan = eventChannel<EmdrActions>((emitter) => {
		return subscribeToInterWindowCommunication({
			targetWindow,
			onMessage: (e: EmdrWinMsg) => {
				if (e.type !== WinMsgTypes.EMDR_MESSAGE) {
					return;
				}

				emitter(e.data);
			},
		});
	});

	try {
		while (true) {
			const incomingAction: EmdrActions = yield take(chan);
			yield put(incomingAction);
		}
	} finally {
		if (yield cancelled()) {
			chan.close();
		}
	}
}

/**
 * Incoming in the sense that signal will be coming from other call participants.
 * Every incoming signal will then be wrapped an dispatched with `signalIsReceived` action.
 */
function* subscribeToIncomingEmdrSignal() {
	const chan = eventChannel<EmdrSignal>((emitter) => {
		const subsId = chatSdk.subscribeToIncomingEmdrSignal(emitter);
		return () => chatSdk.unsubscribeToIncomingEmdrSignal(subsId);
	});

	try {
		while (true) {
			const signal: EmdrSignal = yield take(chan);
			yield put(emdrActions.signalIsReceived(signal));
		}
	} finally {
		if (yield cancelled()) {
			chan.close();
		}
	}
}

/**
 * Contain several setup for EMDR Saga.
 * Most notable is the subscription to IWC and Incoming EMDR Signal.
 * Also contain listener to some actions.
 */
function* initEmdrSaga(action: ActionType<typeof emdrActions.initEmdrSaga>) {
	yield takeEvery(getType(emdrActions.sendSignal), sendSignal);
	yield throttle(1000, getType(emdrActions.sendSignalWithThrottling), sendSignal);

	const sendEmdrViaIwc = createSendMessageWithType(action.payload.targetWindow)<EmdrActions>(
		WinMsgTypes.EMDR_MESSAGE,
	);
	yield takeEvery(
		getType(emdrActions.signalIsReceived),
		(action: ActionType<typeof emdrActions.signalIsReceived>) => {
			log.debug("Got incoming emdr signal", action.payload.signal);
			sendEmdrViaIwc(action);
		},
	);

	yield fork(subscribeToIncomingEmdrSignal);
	yield fork(subscribeToIwc, action.payload.targetWindow);
}

/**
 * This will make sure that each EMDR Saga tied to specific call.
 * Which in turn will make sure that all setup in EMDR Saga point to correct subscription.
 */
function* emdrSaga() {
	yield takeLeading(
		getType(emdrActions.initEmdrSaga),
		function* (action: ActionType<typeof emdrActions.initEmdrSaga>) {
			log.addBreadcrumb({message: "Initiate EMDR Saga"});
			log.debug("Initiate EMDR Saga");
			const task = yield fork(initEmdrSaga, action);
			yield take(getType(emdrActions.cleanUpEmdrSaga));
			log.addBreadcrumb({message: "Stop EMDR Saga"});
			yield cancel(task);
		},
	);
}

export default function* () {
	yield fork(whenLoggedIn(emdrSaga));
}
