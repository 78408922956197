import {SentryTags} from "../../../errorHandler/createSentryReport";
import createLogger from "../../../logger/createLogger";
import {MeetingNoteFormValues} from "../../../tracker/meetingNotes/meetingNotesTypes";
import {call, put, select} from "redux-saga/effects";
import {groupTherapyActions} from "../../redux/groupTherapyActions";
import {GroupTherapy} from "../../groupTherapy";
import {getAuthUserId, getSessionId} from "../../../auth/helpers/authStorage";
import {transformFormValuesIntoSensorData} from "../../../tracker/meetingNotes/helpers/meetingNotesHelpers";
import {SensorData as BaseSensorData} from "@sense-os/goalie-js";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import {
	GroupTherapyRegistration,
	GroupTherapyUpdateSensorData,
	RegistrationType,
} from "@sense-os/goalie-js/dist/groupTherapy/types";
import {getRegistrationDetails, getRelatedSensorList} from "../../redux/groupTherapySelectors";
import groupTherapySDK from "../../helpers/groupTherapySdk";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {meetingNotesActions} from "../../../tracker/meetingNotes/redux/meetingNotesActions";
import {toastActions} from "../../../toaster/redux";
import strTranslation from "../../../assets/lang/strings";
import localization from "../../../localization/Localization";
import {MeetingNote} from "@sense-os/sensor-schema/goalie-2-ts/meeting_note";
import moment from "moment";

const log = createLogger("editGroupTherapySessionNoteSaga", SentryTags.GroupTherapy);

/**
 * This saga will handle group registration and sensor update
 * Steps:
 * 1. Update meeting notes for each clients on the group therapy
 * 2. Update group therapy registration
 */
export function* editGroupTherapySessionNoteSaga(group: GroupTherapy, formValues: MeetingNoteFormValues) {
	const token: string = yield call(getSessionId);
	const creatorId: number = yield call(getAuthUserId);
	const relatedSensors: SensorDatum<MeetingNote>[] = yield select(getRelatedSensorList);
	const sensorIds: string[] = relatedSensors.map((sensor) => {
		return sensor.id;
	});
	const registrationDetails: GroupTherapyRegistration = yield select(getRegistrationDetails);

	try {
		/** Transform `formValues` into `SensorData` */
		const sensorData: BaseSensorData<MeetingNote> = yield call(
			transformFormValuesIntoSensorData,
			creatorId,
			formValues,
		);

		/** Update Meeting Note Bulk Payload */
		const meetingNoteBulkPayload: GroupTherapyUpdateSensorData<MeetingNote>[] = relatedSensors.map((sensor) => {
			return {
				_id: sensor.id,
				end_time: formValues.reportTime as unknown as string,
				start_time: formValues.reportTime as unknown as string,
				value: sensorData.value,
			};
		});

		/** 1. Update meeting notes for each clients on the group therapy */
		yield apiCallSaga(groupTherapySDK.updateBulkSensors, token, meetingNoteBulkPayload);

		/** 2. Update group therapy registration */
		yield apiCallSaga(groupTherapySDK.updateRegistration, token, group.id, registrationDetails.id, {
			sensorIds: sensorIds,
			title: formValues.title,
			dueDate: moment(formValues.reportTime).toDate(),
			details: {
				...formValues,
				reportTime: formValues.reportTime.toISOString(),
			},
			type: RegistrationType.SESSION_NOTE,
		});

		yield put(
			toastActions.addToast({
				message: strTranslation.MEETING_NOTES.edit_meeting_note.alert.success,
				type: "success",
			}),
		);

		yield put(groupTherapyActions.saveGroupTherapySessionNote.success());
		yield put(groupTherapyActions.fetchGroupRegistrationList.request({id: group.id}));

		// Clear registration details, relatedSensorDataMap, and registrationFetchState
		yield put(groupTherapyActions.clearFetchedRegistrationDetails());

		yield put(meetingNotesActions.closeForm());
	} catch (error) {
		log.captureException(error, {message: `Failed to update group registration session note.`});
		yield put(groupTherapyActions.saveGroupTherapySessionNote.failure(error));

		// Show error toast message
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.MEETING_NOTES.edit_meeting_note.alert.fail),
				type: "error",
			}),
		);
	}
}
