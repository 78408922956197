import {LoadingState} from "constants/redux";
import {UserTrackerGraphV2, UserTrackerGraphV2Map} from "../customTrackerGraphV2Types";
import {CustomTrackerActionType, customTrackerActions} from "./customTrackerActions";
import {getType} from "typesafe-actions";

export default function userTrackerGraphV2Map(
	state: UserTrackerGraphV2Map = {},
	action: CustomTrackerActionType,
): UserTrackerGraphV2Map {
	switch (action.type) {
		case getType(customTrackerActions.fetchTrackerGraphV2.request):
		case getType(customTrackerActions.fetchTrackerGraphV2.success):
		case getType(customTrackerActions.fetchTrackerGraphV2.failure):
			const prevState = state[action.payload.hashId];
			return {
				...state,
				[action.payload.hashId]: userTrackerGraphV2(prevState, action),
			};

		default:
			return state;
	}
}

const userTrackerGraphV2InitialState: UserTrackerGraphV2 = {
	trackerDataMap: {},
	fetchingState: LoadingState.EMPTY,
};

function userTrackerGraphV2(
	state: UserTrackerGraphV2 = userTrackerGraphV2InitialState,
	action: CustomTrackerActionType,
): UserTrackerGraphV2 {
	switch (action.type) {
		case getType(customTrackerActions.fetchTrackerGraphV2.request):
			return {
				...state,
				fetchingState: LoadingState.LOADING,
			};
		case getType(customTrackerActions.fetchTrackerGraphV2.success):
			return {
				...state,
				fetchingState: LoadingState.LOADED,
				trackerDataMap: action.payload.trackerGraphV2Data,
			};
		case getType(customTrackerActions.fetchTrackerGraphV2.failure):
			return {
				...state,
				fetchingState: LoadingState.ERROR,
			};
		default:
			return state;
	}
}
