import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import {referralClientActions} from "../redux/referralClientAction";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import ReferralClientSDK from "../helper/referralClient";
import {toastActions} from "../../toaster/redux";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";

const log = createLogger("createQuestionnaireReview", SentryTags.ReferralClient);

export function* createQuestionnaireReview(
	action: ActionType<typeof referralClientActions.createQuestionnaireReview.request>,
) {
	const {id} = action.payload;
	const token = yield call(getSessionId);

	try {
		yield apiCallSaga(ReferralClientSDK.updateReferralQuestionnaireReview, token, id, null, "");

		yield put(referralClientActions.createQuestionnaireReview.success());

		// TODO: put translation here
		yield put(toastActions.addToast({message: "Review is submitted", type: "info"}));
	} catch (err) {
		log.captureException(err);
		yield put(referralClientActions.createQuestionnaireReview.failure());
	}
}

export default function* () {
	yield takeEvery(getType(referralClientActions.createQuestionnaireReview.request), createQuestionnaireReview);
}
