import {AppState} from "../AppState";
import {TargetName, Target} from "./SensorTargetTypes";
import {LoadingState} from "constants/redux";

/** Get `SensorTargetState` from redux state */
const getSensorTargetState = (state: AppState) => state.sensorTarget;

/**
 * Get targets state from `SensorTargetState` by targetName
 *
 * @param {TargetName} targetName
 *
 * @returns {Target[]}
 */
export const getTargets =
	(targetName: TargetName) =>
	(state: AppState): Target[] =>
		getSensorTargetState(state).targets[targetName];

/**
 * Get `loadingFetchActiveTarget` state from `SensorTargetState`
 *
 * @param {TargetName} targetName
 *
 * @returns {LoadingState}
 */
export const getLoadingFetchActiveTarget =
	(targetName: TargetName) =>
	(state: AppState): LoadingState =>
		getSensorTargetState(state).loadingFetchActiveTarget[targetName];

/**
 * Get `loadingFetchTarget` state from `SensorTargetState`
 *
 * @param {TargetName} targetName
 *
 * @returns {LoadingState}
 */
export const getLoadingFetchTarget = (targetName: TargetName) => (state: AppState) =>
	getSensorTargetState(state).loadingFetchTarget[targetName];

/**
 * Get `loadingUpdateTarget` state from `SensorTargetState`
 *
 * @param {TargetName} targetName
 *
 * @returns {LoadingState}
 */
export const getLoadingUpdateTarget = (targetName: TargetName) => (state: AppState) =>
	getSensorTargetState(state).loadingUpdateTarget[targetName];

/**
 * Get `activeTarget` state from `SensorTargetState`
 *
 * @param {TargetName} targetName
 *
 * @returns {Target}
 */
export const getActiveTarget = (targetName: TargetName) => (state: AppState) =>
	getSensorTargetState(state).activeTarget[targetName];

/**
 * Get `isOpenConfigModal` state from `SensorTargetState`
 *
 * @param {TargetName} targetName
 *
 * @returns {boolean}
 */
export const isConfigModalOpened = (targetName: TargetName) => (state: AppState) => {
	return getSensorTargetState(state).isOpenConfigModal[targetName] || false;
};
