import {call, put, select, takeLatest} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {CalendarAccountResponse} from "@sense-os/goalie-js";

import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {isUnauthorizedError} from "../../errorHandler/errorHandlerUtils";
import {getCalendarSettings} from "../../userSettings/redux/userSettingsSelectors";
import {userSettingsAction} from "../../userSettings/redux/userSettingsActions";

import {calendarActions} from "../redux/calendarActions";
import {calendarSDK} from "../helpers/calendarSDK";
import {
	convertAccountResponseToCalendarAccount,
	getCalendarIdsFromProfiles,
	mapColorsIntoCalendarIds,
	mapVisibilityIntoCalendarIds,
} from "../helpers/calendarHelpers";
import {CalendarAccount, CalendarColor, CalendarProfile, CalendarSettings, TimeView} from "../calendarTypes";

const log = createLogger("getCalendarAccountSaga", SentryTags.Calendar);

function* handleGetCalendarAccount() {
	try {
		const token: string = yield call(getSessionId);
		const accountResponse: CalendarAccountResponse = yield call(calendarSDK.getCalendarAccount, token);
		const calendarAccount: CalendarAccount = yield call(convertAccountResponseToCalendarAccount, accountResponse);
		yield put(calendarActions.getCalendarAccount.success({calendarAccount}));

		if (calendarAccount?.calendarProfiles?.length > 0) {
			// Set calendar initial colors and visibility
			yield call(setInitialVisibilityAndColors, calendarAccount.calendarProfiles);
			// Fetch calendar events
			yield put(calendarActions.getCalendarEvents.request());
		}
	} catch (err) {
		yield put(calendarActions.getCalendarAccount.failure(err));

		if (isUnauthorizedError(err)) {
			return;
		}

		log.captureException(err);
	}
}

function* setInitialVisibilityAndColors(calendarProfiles: CalendarProfile[]) {
	const calendarIds: string[] = yield call(getCalendarIdsFromProfiles, calendarProfiles);
	const calendarSettings: CalendarSettings = yield select(getCalendarSettings);
	const {defaultCalendarId, calendarColors, calendarsVisibility} = calendarSettings;

	// Mapping calendar colors into connected calendars id
	const mappedCalendarsColor: Record<number, CalendarColor> = yield call(
		mapColorsIntoCalendarIds,
		calendarIds,
		Object.fromEntries(calendarColors || []),
	);

	// Mapping calendar visibility into connected calendars id
	const mappedCalendarsVisibility: Record<number, boolean> = yield call(
		mapVisibilityIntoCalendarIds,
		calendarIds,
		Object.fromEntries(calendarsVisibility || []),
	);

	if (!defaultCalendarId) {
		// Set first index from `calendarIds` as default calendar if there is no default calendar yet.
		yield put(
			userSettingsAction.saveSettings.request({
				settings: {
					calendarUserSettings: {
						...calendarSettings,
						defaultCalendarId: calendarIds[0],
						calendarTimeView: TimeView.WEEK,
						isWeekendShown: false,
					},
				},
			}),
		);
	}

	yield put(calendarActions.setInitialCalendarVisibility(mappedCalendarsVisibility));
	yield put(calendarActions.setInitialCalendarsColor(mappedCalendarsColor));
}

export default function* () {
	yield takeLatest(getType(calendarActions.getCalendarAccount.request), handleGetCalendarAccount);
}
