interface NoteContentResult {
	isSearchFound: boolean;
	searchMatchedCount: number;
	searchResults: number[];
}

/**
 * This is func for finding note content that match with the search string
 * it will only find result if search string more than 1 string
 **/
export const findNoteContent = (search: string, source: string): NoteContentResult => {
	let searchResults = [];
	if (search?.length > 1) {
		let i = 0;
		const sourceLength = source?.length;
		const searchLength = search?.length;
		while (i + searchLength <= sourceLength) {
			if (source.slice(i, i + searchLength).toLowerCase() === search.toLowerCase()) {
				searchResults = [...searchResults, i];
				i = i + searchLength;
			} else {
				i = i + 1;
			}
		}
		const searchMatchedCount: number = searchResults.length;
		const isSearchFound: boolean = searchMatchedCount > 0;
		return {isSearchFound, searchMatchedCount, searchResults};
	}
	return {
		isSearchFound: false,
		searchMatchedCount: 0,
		searchResults,
	};
};
