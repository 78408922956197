import {call, fork, put} from "redux-saga/effects";
import featureFlags from "./FeatureFlags";
import {whenLoggedIn} from "../auth/sagas/helper";
import {createAction} from "typesafe-actions";

export const featureFlagIsRefreshedAction = createAction("featureFlag/featureFlagIsRefreshed")();

function* refreshFeatureFlag() {
	yield call(featureFlags.getFeatureFlagsFromBE);
	yield put(featureFlagIsRefreshedAction());
}

export default function* () {
	yield fork(whenLoggedIn(refreshFeatureFlag, refreshFeatureFlag));
}
