import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import {referralClientActions} from "../redux/referralClientAction";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import ReferralClientSDK from "../helper/referralClient";
import {toastActions} from "../../toaster/redux";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {REFERRAL_QUESTIONNAIRE_STATUS} from "@sense-os/goalie-js";
import {unDeterminedTherapy} from "../helper/configs";

const log = createLogger("updateReferralClientStatus", SentryTags.ReferralClient);

// TODO: put translation here
const message = {
	[REFERRAL_QUESTIONNAIRE_STATUS.ACCEPTED]: "Questionnaire accepted",
	[REFERRAL_QUESTIONNAIRE_STATUS.DECLINED]: "Questionnaire declined",
};

export function* updateReferralClientStatus(
	action: ActionType<typeof referralClientActions.updateReferralClientStatus.request>,
) {
	const {id, status, notes, therapyId} = action.payload;
	const token = yield call(getSessionId);

	try {
		if (status === REFERRAL_QUESTIONNAIRE_STATUS.ACCEPTED) {
			const selectedTherapyId = therapyId === unDeterminedTherapy.id ? undefined : therapyId;
			yield apiCallSaga(ReferralClientSDK.putAcceptReferralQuestionnaire, token, id, selectedTherapyId, notes);
		} else if (status === REFERRAL_QUESTIONNAIRE_STATUS.DECLINED) {
			yield apiCallSaga(ReferralClientSDK.putDeclineReferralQuestionnaire, token, id, notes);
		}

		yield put(referralClientActions.updateReferralClientStatus.success());

		const successMessage = message[status];

		yield put(toastActions.addToast({message: successMessage, type: "info"}));
	} catch (err) {
		log.captureException(err);
		yield put(referralClientActions.updateReferralClientStatus.failure());
	}
}

export default function* () {
	yield takeEvery(getType(referralClientActions.updateReferralClientStatus.request), updateReferralClientStatus);
}
