import {useCallback, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router";
import keyboardJS from "keyboardjs";

import {useCheckAuthUserAccess} from "../../featureFlags/useAuthUserAccess";
import {PortalFeatures} from "../../featureFlags/userFeatureAccess";

import {getUserPlatform} from "../helpers/NDBlogSearchHelpers";
import {ndBlogSearchActions} from "../redux/NDBlogSearchActions";
import {getSearchItemOption, isDialogOpened} from "../redux/NDBlogSearchSelector";
import {NDBlogSearchDialog} from "./NDBlogSearchDialog";
import {KeyBindings, KeyContexts, SearchItemOption, UserPlatform} from "../NDBlogSearchTypes";

export const NDBlogSearchHotkeys = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const checkUserAccess = useCheckAuthUserAccess();
	const isNDBlogSearchAccessible = checkUserAccess(PortalFeatures.nicedayBlogSearch);

	const dialogIsOpened: boolean = useSelector(isDialogOpened);
	const searchItemOption: SearchItemOption = useSelector(getSearchItemOption);

	const handleOpenDialog = useCallback(() => {
		dispatch(ndBlogSearchActions.openDialog(searchItemOption));
	}, [dispatch, searchItemOption]);

	const handleCloseDialog = useCallback(() => {
		dispatch(ndBlogSearchActions.closeDialog());
	}, [dispatch]);

	useEffect(() => {
		// Check local user platform whether using mac or others
		const isMacUser: boolean = getUserPlatform() === UserPlatform.MAC;

		// Set keyboardJS context to "global"
		keyboardJS.setContext(KeyContexts.GLOBAL);

		if (isMacUser) {
			// Unbind previous keys
			keyboardJS.unbind(KeyBindings.CMD_DOT);
			// Bind the keys back
			keyboardJS.bind(KeyBindings.CMD_DOT, () => handleOpenDialog());
		} else {
			keyboardJS.unbind(KeyBindings.CTRL_DOT);
			keyboardJS.bind(KeyBindings.CTRL_DOT, () => handleOpenDialog());
		}

		// Unbind the keys IF search dialog already opened
		if (dialogIsOpened) {
			keyboardJS.unbind(KeyBindings.CTRL_DOT);
			keyboardJS.unbind(KeyBindings.CMD_DOT);
		}
	}, [handleOpenDialog, dialogIsOpened, searchItemOption]);

	useEffect(() => {
		// Close the dialog whenever location path is changed
		if (dialogIsOpened) {
			handleCloseDialog();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return isNDBlogSearchAccessible ? (
		<NDBlogSearchDialog isOpen={dialogIsOpened} onClose={handleCloseDialog} searchItemOption={searchItemOption} />
	) : (
		<></>
	);
};
