import {TaskItem} from "@sense-os/goalie-js";
import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {getSessionId} from "../../auth/helpers/authStorage";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import taskSDK from "../helpers/taskSDK";
import {taskRegistrationActions} from "../redux/taskRegistrationActions";

const log = createLogger("fetchTaskRegistrationDetail", SentryTags.Task);

/**
 * Saga for fetching the task detail dialog
 * @param action
 */
export function* fetchTaskRegistrationDetailDialog(
	action: ActionType<typeof taskRegistrationActions.fetchTaskRegistrationDetailDialog.request>,
) {
	const {taskRegistrationId} = action.payload;
	try {
		const token: string = yield call(getSessionId);

		const res: TaskItem<any> = yield apiCallSaga(taskSDK.getTaskById, token, taskRegistrationId);
		yield put(taskRegistrationActions.fetchTaskRegistrationDetailDialog.success({taskRegistrationData: res}));
	} catch (error) {
		log.error("Error fetch task detail dialog ", error);
		yield put(taskRegistrationActions.fetchTaskRegistrationDetailDialog.failure(error));
	}
}

export default function* () {
	yield takeEvery(
		getType(taskRegistrationActions.fetchTaskRegistrationDetailDialog.request),
		fetchTaskRegistrationDetailDialog,
	);
}
