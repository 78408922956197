import {call, takeEvery, put, select} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {userSettingsAction} from "../../userSettings/redux/userSettingsActions";
import {getCalendarSettings} from "../../userSettings/redux/userSettingsSelectors";

import {calendarSDK} from "../helpers/calendarSDK";
import {calendarActions} from "../redux/calendarActions";
import {getConnectedCalendars} from "../redux/calendarSelectors";
import {Calendar, CalendarSettings} from "../calendarTypes";

const log = createLogger("revokeCalendarProfileSaga", SentryTags.Calendar);

function* handleRevokeCalendar(action: ActionType<typeof calendarActions.revokeCalendarProfile.request>) {
	try {
		const calendar: Calendar = action.payload.calendar;
		const token: string = yield call(getSessionId);
		const calendarSettings: CalendarSettings = yield select(getCalendarSettings);
		const connectedCalendars: Calendar[] = yield select(getConnectedCalendars);
		const nonDefaultCalendars: Calendar[] = connectedCalendars.filter(
			(calendar) => calendar.calendarId !== calendarSettings.defaultCalendarId,
		);

		yield apiCallSaga(calendarSDK.revokeCalendarProfile, token, calendar.profileId);
		yield put(calendarActions.revokeCalendarProfile.success());

		if (calendarSettings.defaultCalendarId === calendar.calendarId) {
			// If disconnected calendar is a `default calendar`, here we want to
			// automatically set other calendar as a `default calendar`.
			yield put(
				userSettingsAction.saveSettings.request({
					settings: {
						calendarUserSettings: {
							...calendarSettings,
							defaultCalendarId: nonDefaultCalendars[0]?.calendarId || null,
						},
					},
				}),
			);
		}

		// Refetch calendar account
		yield put(calendarActions.getCalendarAccount.request());
	} catch (err) {
		log.captureException(err);
		yield put(calendarActions.revokeCalendarProfile.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(calendarActions.revokeCalendarProfile.request), handleRevokeCalendar);
}
