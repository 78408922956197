import {EventViewData, Interval} from "../../ts/redux/tracking/TrackingTypes";
import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {ClientActivitySensors} from "../clientActivityTypes";

export const clientActivityActions = {
	// fetch daily planner activity
	fetchClientActivityDailyPlanners: createAsyncAction(
		"clientActivity/fetchClientActivityDailyPlanners",
		"clientActivity/fetchClientActivityDailyPlannersSucceed",
		"clientActivity/fetchClientActivityDailyPlannersFailed",
	)<{userId: number; start: Date; end: Date}, {userId: number; data: EventViewData[]}, {userId: number}>(),

	// fetch sensor activity
	fetchClientActivitySensors: createAsyncAction(
		"clientActivity/fetchClientActivitySensors",
		"clientActivity/fetchClientActivitySensorsSucceed",
		"clientActivity/fetchClientActivitySensorsFailed",
	)<
		{userId: number; start: Date; end: Date; activityTypes: ClientActivitySensors[]},
		{userId: number; data: Partial<Record<ClientActivitySensors, EventViewData[]>>},
		{userId: number; activityTypes: ClientActivitySensors[]}
	>(),

	// fetch behaviour experiment
	fetchClientActivityBehaviourExp: createAsyncAction(
		"clientActivity/fetchClientActivityBehaviourExp",
		"clientActivity/fetchClientActivityBehaviourExpSucceed",
		"clientActivity/fetchClientActivityBehaviourExpFailed",
	)<{userId: number; hashId: string}, {userId: number; data: EventViewData[]}, {userId: number}>(),

	// fetch psychoeducation
	fetchClientActivityPsychoEducation: createAsyncAction(
		"clientActivity/fetchClientActivityPsychoEducation",
		"clientActivity/fetchClientActivityPsychoEducationSucceed",
		"clientActivity/fetchClientActivityPsychoEducationExpFailed",
	)<{userId: number; hashId: string}, {userId: number; data: EventViewData[]}, {userId: number}>(),

	// fetch task
	fetchClientActivityTask: createAsyncAction(
		"clientActivity/fetchClientActivityTask",
		"clientActivity/fetchClientActivityTaskSucceed",
		"clientActivity/fetchClientActivityTaskFailed",
	)<{userId: number; hashId: string}, {userId: number; data: EventViewData[]}, {userId: number}>(),

	// update last fetched time
	updateLastFetched: createAction("clientActivity/updateLastFetched", (userId: number) => ({userId}))(),

	/**
	 * This action will be dispatched anytime any registrations is opened.
	 * The expected results would be that the registrations will have some highlight
	 * to help our user easily recognize the last registrations that they open.
	 */
	markAsOpenedRecently: createAction("clientActivity/markAsOpenedRecently", (activityId: string) => ({activityId}))(),
	unmarkOpenedRecently: createAction("clientActivity/unmarkOpenedRecently")(),

	/**
	 * This action is used to change the interval in activities dashboard.
	 */
	changeInterval: createAction("clientActivity/changeInterval", (interval: Interval) => ({interval}))(),

	/**
	 * This action is used to apply the default interval that was stored in user settings.
	 */
	setDefaultIntervalForDashboard: createAction("clientActivity/setDefaultIntervalForDashboard")(),
};

export type ClientActivityActionType = ActionType<typeof clientActivityActions>;
