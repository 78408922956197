import {ActionType, createAction, createAsyncAction} from "typesafe-actions";

import {NewFeatures} from "../../newFeaturePopup/newFeaturePopupTypes";

import {UserSettings, CustomTrackerSettings} from "../userSettingsTypes";
import {LoadingState} from "constants/redux";

export const userSettingsAction = {
	loadSettings: createAsyncAction(
		"userSettings/loadSettings",
		"userSettings/loadSettingsSuccess",
		"userSettings/loadSettingsError",
	)<void, {settings: UserSettings}, void>(),
	saveSettings: createAsyncAction(
		"userSettings/saveSettings",
		"userSettings/saveSettingsSuccess",
		"userSettings/saveSettingsError",
	)<{settings: Partial<UserSettings>}, {settings: Partial<UserSettings>}, void>(),
	saveCustomTrackerSettings: createAsyncAction(
		"userSettings/saveCustomTrackerSettings",
		"userSettings/saveCustomTrackerSettingsSuccess",
		"userSettings/saveCustomTrackerSettingsError",
	)<Partial<CustomTrackerSettings>, void, void>(),
	saveSettingsForNewFeaturesPopup: createAsyncAction(
		"userSettings/saveSettingsForNewFeaturesPopup",
		"userSettings/saveSettingsForNewFeaturesPopupSuccess",
		"userSettings/saveSettingsForNewFeaturesPopupError",
	)<NewFeatures, void, void>(),
	/**
	 * Stores sorted trackers by Id
	 */
	saveTrackerOrderSettings: createAsyncAction(
		"userSettings/savesaveTrackerOrderSettings",
		"userSettings/savesaveTrackerOrderSettingsSuccess",
		"userSettings/savesaveTrackerOrderSettingsError",
	)<{userId: number; trackerOrder: number[]}, void, void>(),
	/**
	 * Set User Settings Saving State directly
	 */
	setUserSettingsSavingState: createAction("userSettings/setUserSettingsSavingState")<{savingState: LoadingState}>(),
};

export type UserSettingsActions = ActionType<typeof userSettingsAction>;
