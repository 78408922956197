import loc from "../../../localization/Localization";
import {
	CognitiveComplaintMeasures,
	DissociationMeasures,
	HallucinationsMeasures,
	OverstimulatedMeasures,
	PanicAttackSensorMeasures,
	SideEffectMedicationMeasures,
	TrackerSensorName,
} from "../customTrackerSensorTypes";
import localization from "../../../localization/Localization";
import {MeasureValuesMap} from "../customTrackerTypes";
import strTranslation from "../../../assets/lang/strings";

/** merging enums */
const trackerLocalizationMapKeyValue = {
	...TrackerSensorName,
	...PanicAttackSensorMeasures,
	...CognitiveComplaintMeasures,
	...OverstimulatedMeasures,
	...HallucinationsMeasures,
	...DissociationMeasures,
	...SideEffectMedicationMeasures,
};
type trackerLocalizationMapKeyType =
	| TrackerSensorName
	| PanicAttackSensorMeasures
	| CognitiveComplaintMeasures
	| OverstimulatedMeasures
	| HallucinationsMeasures
	| DissociationMeasures
	| SideEffectMedicationMeasures;

export const customTrackerLocalizationMap: {[key in trackerLocalizationMapKeyType]: string} = {
	[TrackerSensorName.afraid]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.afraid.name,
	[TrackerSensorName.aggression]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.aggression.name,
	[TrackerSensorName.alcohol]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.alcohol.name,
	[TrackerSensorName.angry]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.angry.name,
	[TrackerSensorName.ashamed]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.ashamed.name,
	[TrackerSensorName.askForHelp]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.ask_for_help.name,
	[TrackerSensorName.assertiveness]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.assertiveness.name,
	[TrackerSensorName.automaticPilot]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.automatic_pilot.name,
	[TrackerSensorName.avoidance]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.avoidance.name,
	[TrackerSensorName.backache]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.backache.name,
	[TrackerSensorName.binge]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.binge_eating.name,
	[TrackerSensorName.bodycheck]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.bodycheck.name,
	[TrackerSensorName.cannabis]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.cannabis.name,
	[TrackerSensorName.cocaine]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.cocaine.name,
	[TrackerSensorName.compulsion]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.compulsion.name,
	[TrackerSensorName.craving]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.craving.name,
	[TrackerSensorName.cramped]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.cramped.name,
	[TrackerSensorName.curious]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.curious.name,
	[TrackerSensorName.depersonalization]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.depersonalization.name,
	[TrackerSensorName.depressed]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.depressed.name,
	[TrackerSensorName.difficulty]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.difficulty.name,
	[TrackerSensorName.disoriented]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.disoriented.name,
	[TrackerSensorName.distracted]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.distracted.name,
	[TrackerSensorName.energy]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.energy.name,
	[TrackerSensorName.excited]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.excited.name,
	[TrackerSensorName.exercise]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.exercise.name,
	[TrackerSensorName.flashbacks]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.flashbacks.name,
	[TrackerSensorName.fatigue]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.fatigue.name,
	[TrackerSensorName.gratitude]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.gratitude.name,
	[TrackerSensorName.guilty]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.guilty.name,
	[TrackerSensorName.happy]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.happy.name,
	[TrackerSensorName.headache]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.headache.name,
	[TrackerSensorName.hyperAlert]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.hyper_alert.name,
	[TrackerSensorName.hyperAroused]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.hyper_aroused.name,
	[TrackerSensorName.hyperAssertiveness]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.hyper_assertiveness.name,
	[TrackerSensorName.inDanger]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.in_danger.name,
	[TrackerSensorName.insecure]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.insecure.name,
	[TrackerSensorName.loneliness]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.loneliness.name,
	[TrackerSensorName.memoryLoss]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.memory_loss.name,
	[TrackerSensorName.mindfulness]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.mindfulness.name,
	[TrackerSensorName.nightmares]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.nightmares.name,
	[TrackerSensorName.pain]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.pain.name,
	[TrackerSensorName.panicAttack]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.panic_attacks.name,
	[TrackerSensorName.panicAttackWithPhysicalSensation]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.panic_attacks_with_physical_sensation.name,
	[TrackerSensorName.physicalWeakness]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.physical_weakness.name,
	[TrackerSensorName.physicallyFrozen]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.physically_frozen.name,
	[TrackerSensorName.positiveThings]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.positive_things.name,
	[TrackerSensorName.researchEmotions]: strTranslation.ZZ_CUSTOM_TRACKER.tracker["3D_research_emotions"].name,
	[TrackerSensorName.restAndRelax]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.rest_and_relax.name,
	[TrackerSensorName.sad]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.sad.name,
	[TrackerSensorName.safetyBehaviour]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.safety_behaviour.name,
	[TrackerSensorName.sideEffectsMedication]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.side_effects_medication.name,
	[TrackerSensorName.smoking]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.smoking.name,
	[TrackerSensorName.socialAvoidance]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.social_avoidance.name,
	[TrackerSensorName.socialDependent]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.social_dependent.name,
	[TrackerSensorName.subAssertiveness]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.sub_assertiveness.name,
	[TrackerSensorName.tension]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.tension.name,
	[TrackerSensorName.weight]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.weight.name,
	[TrackerSensorName.withdrawal]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.withdrawal_symptoms.name,
	[TrackerSensorName.worry]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.worry.name,

	// panic attack measures
	[PanicAttackSensorMeasures.measurePalpitations]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.palpitations.name,
	[PanicAttackSensorMeasures.measureSweating]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.sweating.name,
	[PanicAttackSensorMeasures.measureShaking]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.shaking.name,
	[PanicAttackSensorMeasures.measureShortnessOfBreath]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.shortness_of_breath.name,
	[PanicAttackSensorMeasures.measureFeelingOfSuffocation]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.feeling_of_suffocation.name,
	[PanicAttackSensorMeasures.measurePainOnChest]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.pain_on_the_chest.name,
	[PanicAttackSensorMeasures.measureNausea]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.nausea_or_stomach_pain.name,
	[PanicAttackSensorMeasures.measureDizzy]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.dizzy_or_light_in_the_head.name,
	[PanicAttackSensorMeasures.measureFeelingUnreal]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.feeling_unreal.name,
	[PanicAttackSensorMeasures.measureFearOfLosingControl]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.fear_of_losing_control_or_losing_my_mind.name,
	[PanicAttackSensorMeasures.measureFearOfDying]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.fear_of_dying.name,
	[PanicAttackSensorMeasures.measureTinglingInHandsOrFeet]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.tingling_in_hands_or_feet.name,
	[PanicAttackSensorMeasures.measureSuddenlyWarmOrCold]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.suddenly_getting_warm_or_cold.name,
	[PanicAttackSensorMeasures.measureFeelingUnwell]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.feeling_unreal.name,

	// trackers v4
	[TrackerSensorName.addictionBehavior]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.addiction_behaviour.name,
	[TrackerSensorName.anxiety]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.anxiety.name,
	[TrackerSensorName.attentionDeficit]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.attention_deficit.name,
	[TrackerSensorName.cognitiveComplaints]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.cognitive_complaints.name,
	[TrackerSensorName.dissociation]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.dissociation.name,
	[TrackerSensorName.food]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.food.name,
	[TrackerSensorName.hallucinations]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.hallucinations.name,
	[TrackerSensorName.hyperActivity]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.hyperactivity.name,
	[TrackerSensorName.impulsivity]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.impulsivity.name,
	[TrackerSensorName.overstimulated]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.overstimulated.name,
	[TrackerSensorName.physicalActivity]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.physical_activity.name,
	[TrackerSensorName.sleep]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.sleep.name,
	[TrackerSensorName.suspiciousness]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.suspiciousness.name,

	// cognitive complaint measures
	[CognitiveComplaintMeasures.measureAbsentMinded]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.absent_minded.name,
	[CognitiveComplaintMeasures.measureDisoriented]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.disoriented.name,
	[CognitiveComplaintMeasures.measureForgetfulness]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.forgetfulness.name,
	[CognitiveComplaintMeasures.measureProblemsConcentrating]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.problems_concentrating.name,

	// overstimulated measures
	[OverstimulatedMeasures.measureAttentionOrConcentrationProblem]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.attention_or_concentration_problem.name,
	[OverstimulatedMeasures.measureFeelingOverwhelmedOrDifficultyWithOrganisation]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.feeling_overwhelmed_or_difficulty_with_organisation.name,
	[OverstimulatedMeasures.measureInARush]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.in_a_rush.name,
	[OverstimulatedMeasures.measureInsecureOrAnxious]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.insecure_or_anxious.name,
	[OverstimulatedMeasures.measureIrritableOrEmotional]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.irritable_or_emotional.name,
	[OverstimulatedMeasures.measureLowMood]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.low_mood.name,
	[OverstimulatedMeasures.measureMoodSwings]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.mood_swings.name,
	[OverstimulatedMeasures.measureProcrastination]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.procrastination.name,
	[OverstimulatedMeasures.measureRestlessness]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.restlessness.name,
	[OverstimulatedMeasures.measureSleepingProblems]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.sleeping_problems.name,

	// hallucinations measures
	[HallucinationsMeasures.measureSmell]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.smell.name,
	[HallucinationsMeasures.measureSoundOrVoices]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.sound_or_voices.name,
	[HallucinationsMeasures.measureTaste]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.taste.name,
	[HallucinationsMeasures.measureTouch]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.touch.name,
	[HallucinationsMeasures.measureVisual]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.visual.name,

	// dissociation measures
	[DissociationMeasures.measureDepersonalization]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.depersonalization.name,
	[DissociationMeasures.measureDerealization]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.derealization.name,

	// side effect medication measures
	[SideEffectMedicationMeasures.measureAnxiety]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.anxiety.name,
	[SideEffectMedicationMeasures.measureConstipation]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.constipation.name,
	[SideEffectMedicationMeasures.measureDryMouth]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.dry_mouth.name,
	[SideEffectMedicationMeasures.measureHeadache2]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.headache.name,
	[SideEffectMedicationMeasures.measureLossOfSexDrive]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.loss_of_sex_drive.name,
	[SideEffectMedicationMeasures.measureMemoryLoss2]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.memory_loss.name,
	[SideEffectMedicationMeasures.measureMuscleSpasmsAndCramps]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.muscle_spasms_and_cramps.name,
	[SideEffectMedicationMeasures.measureSleepinessOrProblemsSleeping]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.sleepiness_or_problems_sleeping.name,
	[SideEffectMedicationMeasures.measureSuicidalThoughts]:
		strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.suicidal_thoughts.name,
	[SideEffectMedicationMeasures.measureSuspicion2]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.suspicion.name,
	[SideEffectMedicationMeasures.measureWeightGain]: strTranslation.ZZ_CUSTOM_TRACKER.tracker.measure.weight_gain.name,
};

export const getTranslatedCustomTracker = (trackerSensorName: TrackerSensorName) => {
	if (!!customTrackerLocalizationMap[trackerSensorName]) {
		return localization.formatMessage(customTrackerLocalizationMap[trackerSensorName]);
	}
	return "";
};

/**
 * Total count
 */
export const totalCountTextFn = () => loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.total_count);

/**
 * Registrations: {value}
 */
export const totalRegistrationTextFn = (value: number) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.total_registration, {value});

/**
 * Time: {value}
 */
export const timeTextFn = (value: string) => loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.time, {value});

export const quantityTextFn = () => loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.quantity);

export const categoricalTextFn = () => loc.formatMessage(strTranslation.ZZ_CUSTOM_TRACKER.property.categorical.name);
/**
 * Quantity: {value}
 */
export const quantityTooltipTextFn = (value: string) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.quantity_tooltip, {value});

/**
 * Intensity
 */
export const intensityTextFn = () => loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.intensity);
/**
 * Average Intensity
 */
export const intensityAverageTextFn = () => loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_intensity);
/**
 * Intensity: {value}
 */
export const intensityTooltipTextFn = (value: string) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.intensity_tooltip, {value});
/**
 * Average Intensity: {value}
 */
export const intensityAverageTooltipTextFn = (value: string) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_intensity_tooltip, {value});

/**
 * Duration (minutes)
 */
export const durationTextFn = () => loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.duration);
/**
 * Average duration (minutes)
 */
export const durationAverageTextFn = () => loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_duration);
/**
 * Duration: {value} min
 */
export const durationTooltipTextFn = (value: string) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.duration_tooltip, {value});
/**
 * Average duration: {value} min
 */
export const durationAverageTooltipTextFn = (value: string) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_duration_tooltip, {value});

/**
 * It happened (:count)
 */
export const booleanTrueLabelTextFn = (count: number) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.boolean_true.label) + " (" + count + ")";
/**
 * It did not happen (:count)
 */
export const booleanFalseLabelTextFn = (count: number) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.boolean_false.label) + " (" + count + ")";
/**
 * Happened: :count
 */
export const booleanTrueTooltipTextFn = (count: number) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.boolean_true.tooltip) + ": " + count;
/**
 * Did not happen: :count
 */
export const booleanFalseTooltipTextFn = (count: number) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.boolean_false.tooltip) + ": " + count;

/**
 * Physical sensation: palpitations, sweating, shaking
 */
export const physicalSensationTooltipTextFn = (text: string) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.physical_sensation_tooltip) + ": " + text;

/**
 * Weight translations
 */
export const weightTitleFn = () => loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.weight.kilograms);
export const weightTooltipTextFn = (value: string) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.weight_tooltip, {value});
export const weightAverageTooltipTextFn = (value: string) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_weight_tooltip, {value});

export const researchEmotionSelectorLabelText = () =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.select_emotions);
export const researchEmotionsHappyText = () => getTranslatedCustomTracker(TrackerSensorName.happy);
export const researchEmotionsEnergyText = () => getTranslatedCustomTracker(TrackerSensorName.energy);
export const researchEmotionsInsecureText = () => getTranslatedCustomTracker(TrackerSensorName.insecure);
export const researchEmotionsSadText = () => getTranslatedCustomTracker(TrackerSensorName.sad);
export const researchEmotionsTensionText = () => getTranslatedCustomTracker(TrackerSensorName.tension);
export const researchEmotionsAngryText = () => getTranslatedCustomTracker(TrackerSensorName.angry);
export const researchEmotionsGuiltyText = () => getTranslatedCustomTracker(TrackerSensorName.guilty);
export const researchEmotionsAfraidText = () => getTranslatedCustomTracker(TrackerSensorName.afraid);
export const researchEmotionsAshamedText = () => getTranslatedCustomTracker(TrackerSensorName.ashamed);
export const researchEmotionsCuriosityText = () => getTranslatedCustomTracker(TrackerSensorName.curious);

/**
 * Average {text}
 */
export const averagePrefixTextFn = (text: string) =>
	loc.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_prefix, {text});

/**
 * Converts tracker panic attacks with physical sensation measures into single line of text.
 *
 * @example
 * ```js
 * {
 * 	measures: {
 * 		measurePanicAttacks: {
 * 			intensity: 8,
 * 			duration: 60
 * 		},
 * 		measurePalpitations: {
 * 			boolean: true,
 * 		},
 * 		measureSweating: {
 * 			boolean: false,
 * 		},
 * 		measureShaking: {
 * 			boolean: true,
 * 		},
 *  }
 * }
 * ```
 *
 * Then the result would be:
 * `"palpitations, sweating, shaking"`
 */
export const convertPanicAttacksWithPhysicalSensationMeasuresIntoText = (measureMap: MeasureValuesMap) => {
	return Object.keys(measureMap)
		.filter((measureName) => measureName !== "measurePanicAttacks" && measureMap[measureName].boolean === true)
		.map((measureName) => getTranslatedCustomTracker(TrackerSensorName[measureName]))
		.join(", ");
};

/**
 * Converts tracker panic attacks with physical sensation measures into array of text.
 *
 * @example
 * ```js
 * {
 * 	measures: {
 * 		measurePanicAttacks: {
 * 			intensity: 8,
 * 			duration: 60
 * 		},
 * 		measurePalpitations: {
 * 			boolean: true,
 * 		},
 * 		measureSweating: {
 * 			boolean: false,
 * 		},
 * 		measureShaking: {
 * 			boolean: true,
 * 		},
 *  }
 * }
 * ```
 *
 * Then the result would be:
 * `["palpitations", "sweating", "shaking"]`
 */
export const convertPanicAttacksWithPhysicalSensationMeasuresIntoTextArray = (measureMap: MeasureValuesMap) => {
	return Object.keys(measureMap)
		.filter((measureName) => measureName !== "measurePanicAttacks" && measureMap[measureName].boolean === true)
		.map((measureName) => getTranslatedCustomTracker(PanicAttackSensorMeasures[measureName]));
};

/**
 * Convert tracker with symptoms measures into array of text
 * @param measureMap
 * @returns string[]
 */
export const convertSymptomsMeasuresIntoTextArray = (measureMap: MeasureValuesMap) => {
	return Object.keys(measureMap)
		.filter((measureName) => measureMap[measureName]?.boolean === true)
		.map((measureName) => getTranslatedCustomTracker(trackerLocalizationMapKeyValue[measureName]));
};
