/**
 * RepeatedEvent enum
 */
export enum RepeatedOption {
	NOT_REPEATED = "not_repeated",
	DAILY = "daily",
	WEEKLY = "weekly",
	MONTHLY = "monthly",
	CUSTOM = "custom",
	DEFAULT_CUSTOM = "default_custom",
}

/**
 * RepeatEvery enum
 *
 * Used inside `RepeatEveryField` component to indicate repetition interval,
 * either its every X week or every X month.
 */
export enum RepeatEvery {
	DAY = "day",
	WEEK = "week",
	MONTH = "month",
}

/**
 * Ends enum
 *
 * Used inside `EndsOnField` checkboxes
 */
export enum Ends {
	NEVER = "never",
	ON_DATE = "on_date",
	AFTER_REPETITION = "after_repetition",
}

/**
 * Used inside `CustomRepetitionModal` as formValues interface
 */
export interface CustomRepetitionFormValues {
	repeatEvery: RepeatEveryFormValues;
	repeatDays: number[];
	ends: EndsFormValues;
}
/**
 * Used inside `RepeatEveryField` as form values interface
 */
export interface RepeatEveryFormValues {
	every: RepeatEvery;
	value: number;
}

/**
 * Used inside `EndsOnField` as form values interface
 */
export interface EndsFormValues {
	ends: Ends;
	value?: number | Date;
}
