import {CircularProgress, Container} from "@material-ui/core";
import {useEffect, FC} from "react";

export const AuthenticatorRedirectPage: FC = () => {
	useEffect(() => {
		if (navigator?.userAgent.match(/Android/i)) {
			window.location.href =
				"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2";
		} else {
			window.location.href = "https://apps.apple.com/us/app/google-authenticator/id388497605";
		}
	}, []);

	return (
		<Container>
			<div style={{margin: "300px auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
				<img src="/assets/img/all-logo/logo.png" style={{minWidth: 100, marginBottom: 20}} />
				<CircularProgress />
			</div>
		</Container>
	);
};
