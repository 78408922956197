import "./sentry";
import "core-js/stable";
import "regenerator-runtime/runtime";
import DOMPurify from "dompurify";

import "../styles/sass/main.scss";
import "../styles/css/main.css";
import {initSentry} from "../errorHandler/sentryService";
import moment from "moment";
import {renderApplication} from "visual/ApplicationView";
import analyticsService from "../analytics/AnalyticsService";
import localization from "../localization/Localization";

// With DOMPurify, links can not be opened in new tabs
// This hook is needed so links can be opened in new tabs
DOMPurify.addHook("afterSanitizeAttributes", function (node: any): any {
	// set all elements owning target to target=_blank
	if ("target" in node) {
		node.setAttribute("target", "_blank");
		// prevent reverse tabnabbing
		node.setAttribute("rel", "noopener noreferrer");
	}
	// set non-HTML/MathML links to xlink:show=new
	if (!node.hasAttribute("target") && (node.hasAttribute("xlink:href") || node.hasAttribute("href"))) {
		node.setAttribute("xlink:show", "new");
	}
});

initSentry();

// https://momentjs.com/docs/#/customization/dow-doy/
moment.updateLocale("nl", {
	week: {
		dow: 1,
		doy: 4,
	},
});
moment.updateLocale("en", {
	week: {
		dow: 1,
		doy: 4,
	},
});
analyticsService.init();
localization.init();
//             🚀
renderApplication();
