import {call, put, select, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import strTranslation from "../../assets/lang/strings";

import {groupTherapyActions} from "../redux/groupTherapyActions";
import {getGroupTherapyById} from "../redux/groupTherapySelectors";
import {GroupTherapy} from "../groupTherapy";
import groupTherapySDK from "../helpers/groupTherapySdk";
import {getUpdatedMemberIds} from "../helpers/groupTherapyHelpers";
import {toastActions} from "../../toaster/redux";

const log = createLogger("editGroupTherapySaga", SentryTags.GroupTherapy);

function* editAGroupTherapy(action: ActionType<typeof groupTherapyActions.editAGroupTherapy.request>) {
	const {id, groupUpdate} = action.payload;
	const group: GroupTherapy = yield select(getGroupTherapyById(id));
	const token: string = yield call(getSessionId);
	const updatedMemberIds: number[] = yield call(getUpdatedMemberIds, group, {
		id,
		name: groupUpdate.name,
		clients: groupUpdate.clients,
		therapists: groupUpdate.therapists,
		createdAt: groupUpdate.createdAt,
	});
	const isGroupNameUpdated: boolean = groupUpdate.name !== group.name;
	const isGroupMembersUpdated: boolean = updatedMemberIds?.length > 0;

	try {
		if (isGroupNameUpdated) {
			// Update group name if needed
			yield apiCallSaga(groupTherapySDK.updateGroupTherapyName, token, id, groupUpdate.name);
		}

		if (isGroupMembersUpdated) {
			// Update group members if needed
			yield apiCallSaga(groupTherapySDK.updateGroupTherapyMembers, token, id, updatedMemberIds);
		}

		yield put(
			groupTherapyActions.editAGroupTherapy.success({
				id,
				group: {...group, ...groupUpdate},
			}),
		);

		yield put(
			toastActions.addToast({
				message: strTranslation.CUSTOM_TRACKER.manage_trackers_dialog.save_progress.success,
				type: "success",
			}),
		);
	} catch (err) {
		log.captureException(err, {message: "Failed to edit a group therapy."});
		yield put(groupTherapyActions.editAGroupTherapy.failure(err));
		yield put(
			toastActions.addToast({
				message: strTranslation.CUSTOM_TRACKER.manage_trackers_dialog.save_progress.error,
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(groupTherapyActions.editAGroupTherapy.request), editAGroupTherapy);
}
