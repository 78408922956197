import {CheckInFeeling, SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const checkInFeelingActions = {
	//
	// Check in Feeling form dialog
	//
	fetchCheckInFeeling: createAsyncAction(
		"checkInFeelingActions/fetchCheckInFeeling",
		"checkInFeelingActions/fetchCheckInFeelingSucceed",
		"checkInFeelingActions/fetchCheckInFeelingFailed",
	)<{userId: number; checkInId: string}, {checkInFeelingData: SensorDatum<CheckInFeeling>}, Error>(),
};

export type CheckInFeelingActionType = ActionType<typeof checkInFeelingActions>;
