import {PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import {createAction, createAsyncAction, ActionType} from "typesafe-actions";
import {AssessmentType, OmqSmqFormValues} from "../types";

export const assessmentActions = {
	/**
	 * Form dialog actions
	 */
	openForm: createAction("assessmentActions/openForm", (userId: number, assessmentType: AssessmentType) => ({
		userId,
		assessmentType,
	}))(),
	closeForm: createAction("assessmentActions/closeForm")(),

	/**
	 * Save form values action
	 */
	saveFormValues: createAction(
		"assessmentActions/saveFormValues",
		(userId: number, assessmentType: AssessmentType, formValues?: OmqSmqFormValues) => ({
			userId,
			assessmentType,
			formValues,
		}),
	)(),

	/**
	 * Save OMQ or SMQ actions
	 */
	saveOmqSmq: createAsyncAction(
		"assessmentActions/saveOmqSmq",
		"assessmentActions/saveOmqSmqSuccess",
		"assessmentActions/saveOmqSmqFail",
	)<
		{userId: number; formValues: OmqSmqFormValues; assessmentType: AssessmentType},
		{userId: number; assessmentType: AssessmentType},
		Error
	>(),

	/**
	 * Fetch Omq session
	 */
	fetchOmqSession: createAsyncAction(
		"assessmentActions/fetchOmqSession",
		"assessmentActions/fetchOmqSessionSucceed",
		"assessmentActions/fetchOmqSessionFailed",
	)<{userId: number; omqId: string}, {data: SensorDatum<PlannedEventEntry>}, Error>(),

	/**
	 * Fetch Omq session
	 */
	fetchSmqSession: createAsyncAction(
		"assessmentActions/fetchSmqSession",
		"assessmentActions/fetchSmqSessionSucceed",
		"assessmentActions/fetchSmqSessionFailed",
	)<{userId: number; smqId: string}, {data: SensorDatum<PlannedEventEntry>}, Error>(),
};

export type AssessmentActionType = ActionType<typeof assessmentActions>;
