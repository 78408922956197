import {takeEvery, put, call} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {OrganizationGroup} from "@sense-os/goalie-js";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";
import {organizationActions} from "../redux/organizationActions";
import organizationSDK from "../helpers/organizationSdk";

const log = createLogger("organizationsGroupsSaga", SentryTags.Organization);

function* handleLoadOrganizationsGroups() {
	try {
		const token: string = yield call(getSessionId);
		const orgGroups: OrganizationGroup[] = yield apiCallSaga(organizationSDK.getOrganizationsGroups, token, {
			page: 1,
			pageSize: 1000,
		});

		yield put(organizationActions.loadOrganizationsGroups.success({organizationsGroups: orgGroups}));
	} catch (err) {
		log.captureException(err);
		yield put(organizationActions.loadOrganizationsGroups.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(organizationActions.loadOrganizationsGroups.request), handleLoadOrganizationsGroups);
}
