import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {diaryEntryActions} from "../redux/diaryEntryActions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {getDiaryEntrySensors} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {DiaryEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import {SentryTags} from "../../../errorHandler/createSentryReport";

const log = createLogger("fetchDiaryEntrySaga", SentryTags.DiaryEntry);

export function* fetchDiaryEntry(action: ActionType<typeof diaryEntryActions.fetchDiaryEntry.request>) {
	const {userId, diaryId} = action.payload;
	const token = yield call(getSessionId);
	try {
		const diaryEntryData: SensorDatum<DiaryEntry>[] = yield apiCallSaga(
			getDiaryEntrySensors,
			token,
			userId,
			undefined,
			undefined,
			[diaryId],
		);
		yield put(diaryEntryActions.fetchDiaryEntry.success({diaryEntryData: diaryEntryData?.[0]}));
	} catch (err) {
		log.captureException(err);
		yield put(diaryEntryActions.fetchDiaryEntry.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(diaryEntryActions.fetchDiaryEntry.request), fetchDiaryEntry);
}
