import produce from "immer";
import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {TherapySessionState} from "../types";
import {TherapySessionActionType, therapySessionActions} from "./therapySessionActions";

const initialState: TherapySessionState = {
	userId: null,
	isFormOpened: false,
	savingState: LoadingState.EMPTY,
	deleteState: LoadingState.EMPTY,
	savedFormValues: {},
	therapyId: null,
	therapySession: null,
	fetchState: LoadingState.EMPTY,
};

export const therapySessionReducer = produce(
	(state: TherapySessionState = initialState, action: TherapySessionActionType): TherapySessionState => {
		switch (action.type) {
			case getType(therapySessionActions.openForm):
				state.isFormOpened = true;
				state.userId = action.payload.userId;
				state.therapyId = action.payload.therapyId || null;
				return;
			case getType(therapySessionActions.closeForm):
				state.isFormOpened = false;
				state.savingState = LoadingState.EMPTY;
				return;
			case getType(therapySessionActions.clearFetchedSession):
				state.therapyId = null;
				return;
			case getType(therapySessionActions.saveFormValues):
				state.savedFormValues[action.payload.userId] = action.payload.formValues;
				return;
			case getType(therapySessionActions.saveTherapySession.request):
				state.savingState = LoadingState.LOADING;
				return;
			case getType(therapySessionActions.saveTherapySession.success):
				state.savingState = LoadingState.LOADED;
				state.userId = null;
				state.savedFormValues[action.payload.userId] = null;
				return;
			case getType(therapySessionActions.saveTherapySession.failure):
				state.savingState = LoadingState.ERROR;
				return;
			case getType(therapySessionActions.deleteTherapySession.request):
				state.deleteState = LoadingState.LOADING;
				return;
			case getType(therapySessionActions.deleteTherapySession.success):
				state.deleteState = LoadingState.LOADED;
				return;
			case getType(therapySessionActions.deleteTherapySession.failure):
				state.deleteState = LoadingState.ERROR;
				return;
			case getType(therapySessionActions.fetchTherapySession.request):
				state.fetchState = LoadingState.LOADING;
				return;
			case getType(therapySessionActions.fetchTherapySession.success):
				state.fetchState = LoadingState.LOADED;
				state.therapySession = action.payload.data;
				return;
			case getType(therapySessionActions.fetchTherapySession.failure):
				state.fetchState = LoadingState.ERROR;
				return;
			case getType(therapySessionActions.undoEventAction):
				state.deleteState = LoadingState.EMPTY;
				state.savingState = LoadingState.EMPTY;
				state.savedFormValues[action.payload.userId] = null;
				return;
		}
	},
	initialState,
);
