import Bowser from "bowser";
import {BrowserEntity, supportedBrowserEntities, supportedBrowserVersion} from "../browserWarningTypes";

const browser = Bowser.getParser(window.navigator.userAgent);

export const getCurrentBrowserName = (): string => {
	return browser.getBrowserName();
};

export const isCurrentBrowserSupported = () => {
	return browser.satisfies(supportedBrowserVersion);
};

export const getSupportedBrowserEntities = (): BrowserEntity[] => {
	return Object.keys(supportedBrowserEntities).map((key) => supportedBrowserEntities[key]);
};

export const isSafariBrowser = () => {
	return getCurrentBrowserName().toLowerCase().includes("safari");
};
