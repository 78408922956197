/** Author: leo Date: 28/06/2021 */

import {FC} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isUpdateConfirmationDisplayed} from "../redux/versionCheckSelector";

import loc from "../../localization/Localization";
import ConfirmDialog from "../../components/ConfirmDialog";
import {versionCheckActions} from "../redux/versionCheckAction";
import strTranslation from "../../assets/lang/strings";

/**
 * This component is shown when the portal version on the browser is out of sync with the portal version on the webserver
 * and the user clicked a button for "hard refresh". The latest portal version is then opened in a new tab and
 * this component is rendered in the old tab.
 * @param children
 * @constructor
 */
export const UpdateConfirmationDialog: FC = () => {
	const dispatch = useDispatch();
	const isDisplayed = useSelector(isUpdateConfirmationDisplayed);

	return (
		<ConfirmDialog
			confirmButtonProps={{color: "primary", variant: "text"}}
			closeButtonProps={{color: "default", variant: "text"}}
			open={isDisplayed}
			titleText={loc.formatMessage(strTranslation.VERSION.confirm_dialog.title)}
			contentText={loc.formatMessage(strTranslation.VERSION.confirm_dialog.body)}
			onClose={() => dispatch(versionCheckActions.hideUpdateConfirmation())}
			onConfirm={() => {
				dispatch(versionCheckActions.enableBackdrop());
				window.open(window.location.href, "_blank");
			}}
			confirmButtonText={loc.formatMessage(strTranslation.VERSION.confirm_dialog.button.yes)}
			closeButtonText={loc.formatMessage(strTranslation.VERSION.confirm_dialog.button.no)}
		/>
	);
};
