import {getType} from "typesafe-actions";
import produce from "immer";

import {InterventionDiaryState} from "../interventionDiaryType";
import {LoadingState} from "constants/redux";
import {interventionDiaryActions, interventionDiaryActionType} from "./interventionDiaryActions";

const initialState: InterventionDiaryState = {
	data: [],
	loadingState: LoadingState.EMPTY,
};

export const interventionDiaryReducer = produce(
	(state: InterventionDiaryState, action: interventionDiaryActionType) => {
		switch (action.type) {
			case getType(interventionDiaryActions.fetchDiaries.request): {
				state.loadingState = LoadingState.LOADING;
				state.data = [];
				state.error = null;
				return;
			}
			case getType(interventionDiaryActions.fetchDiaries.success): {
				const {data} = action.payload;
				state.loadingState = LoadingState.LOADED;
				state.data = data;
				return;
			}
			case getType(interventionDiaryActions.fetchDiaries.failure): {
				const {err} = action.payload;
				state.loadingState = LoadingState.ERROR;
				state.error = err;
				return;
			}
		}
	},
	initialState,
);
