import produce from "immer";
import {getType} from "typesafe-actions";

import {LoadingState} from "../../ts/constants/redux";
import {PrivateNoteReduxState} from "../privateNotesTypes";

import {PrivateNotesAction, privateNotesActions} from "./privateNotesAction";

const defaultState: PrivateNoteReduxState = {
	isEditingInVideoCallWindow: false,
	privateNotesMap: {},
};

export const privateNotesReducer = produce((state: PrivateNoteReduxState, action: PrivateNotesAction) => {
	switch (action.type) {
		case getType(privateNotesActions.fetchPrivateNote.request): {
			const {clientId} = action.payload;
			state.privateNotesMap[clientId] = state.privateNotesMap[clientId] || {};
			state.privateNotesMap[clientId].fetchState = LoadingState.LOADING;
			return;
		}

		case getType(privateNotesActions.fetchPrivateNote.success): {
			const {clientId, privateNote} = action.payload;
			state.privateNotesMap[clientId] = state.privateNotesMap[clientId] || {};
			state.privateNotesMap[clientId].fetchState = LoadingState.LOADED;
			if (privateNote) {
				state.privateNotesMap[clientId].note = privateNote.value;
				state.privateNotesMap[clientId].noteId = privateNote.id;
			}

			return;
		}

		case getType(privateNotesActions.fetchPrivateNote.failure): {
			const {clientId} = action.payload;
			state.privateNotesMap[clientId] = state.privateNotesMap[clientId] || {};
			state.privateNotesMap[clientId].fetchState = LoadingState.ERROR;
			return;
		}

		case getType(privateNotesActions.createOrUpdatePrivateNote.request): {
			const {clientId, note} = action.payload;
			state.privateNotesMap[clientId] = state.privateNotesMap[clientId] || {};
			state.privateNotesMap[clientId].saveState = LoadingState.LOADING;
			state.privateNotesMap[clientId].note = note;
			return;
		}

		case getType(privateNotesActions.createOrUpdatePrivateNote.success): {
			const {clientId, privateNote} = action.payload;
			state.privateNotesMap[clientId] = state.privateNotesMap[clientId] || {};
			state.privateNotesMap[clientId].noteId = privateNote.id;
			state.privateNotesMap[clientId].saveState = LoadingState.LOADED;
			return;
		}

		case getType(privateNotesActions.createOrUpdatePrivateNote.failure): {
			const {clientId} = action.payload;
			state.privateNotesMap[clientId] = state.privateNotesMap[clientId] || {};
			state.privateNotesMap[clientId].saveState = LoadingState.ERROR;
			return;
		}

		case getType(privateNotesActions.startEditingPrivateNoteInVideoCall): {
			state.isEditingInVideoCallWindow = true;
			return;
		}

		case getType(privateNotesActions.stopEditingPrivateNoteInVideoCall): {
			state.isEditingInVideoCallWindow = false;
		}
	}
}, defaultState);
