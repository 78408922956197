import moment from "moment";
import {TaskFormValues} from "../taskTypes";
import {ReminderTimeUnit} from "../../helpers/time";

/** Convert reminder duration into date time */
export function getTaskReminderDateTime(formValues: TaskFormValues = null): Date {
	if (!formValues) {
		return null;
	}

	const isHour: boolean = formValues.reminderTimeUnit === ReminderTimeUnit.HOURS;

	return moment(formValues.expiresAt)
		.subtract(formValues.reminderDuration, isHour ? "hours" : "minutes")
		.toDate();
}

/** Convert reminder date time into duration (in minutes) */
export function getReminderDurationInMinutes(expiredAt: Date, remindedAt: Date): number {
	if (!remindedAt) {
		return null;
	}
	return moment(expiredAt).diff(remindedAt, "minutes");
}

/** Convert reminder date time into duration (in hour) */
export function getReminderDurationInHour(expiredAt: Date, remindedAt: Date): number {
	if (!remindedAt) {
		return null;
	}
	return moment(expiredAt).diff(remindedAt, "hours");
}

/**
 * Reminder time range helper
 * @param {ReminderTimeUnit} unit
 * @returns {number}
 */
export function getTaskReminderRangeOptions(unit: ReminderTimeUnit = ReminderTimeUnit.HOURS): number[] {
	// For 'Minutes' time unit it should showing every 5 minutes (0 to 60 minutes)
	if (unit === ReminderTimeUnit.MINUTES) {
		return Array.from({length: 13}, (x, i) => i * 5);
	}
	// For 'Hours' should showing every 1 hour (1 to 24 hours)
	return Array.from({length: 24}, (x, i) => i + 1);
}

// func to get reminder duration and time unit, based on expiredAt and remindAt response from backend
export function getDurationTimeUnit(expiresAt: Date, remindAt: Date): {duration: number; timeUnit: ReminderTimeUnit} {
	const duration = getReminderDurationInMinutes(expiresAt, remindAt);

	/// if notification is off
	if (!remindAt) {
		return {
			duration: getTaskReminderRangeOptions()[0],
			timeUnit: ReminderTimeUnit.HOURS,
		};
	}
	// if duration > 60 minutes, set duration and timeUnit in hour
	if (duration >= 60) {
		return {
			duration: getReminderDurationInHour(expiresAt, remindAt),
			timeUnit: ReminderTimeUnit.HOURS,
		};
	}
	return {
		duration,
		timeUnit: ReminderTimeUnit.MINUTES,
	};
}
