import {getType} from "typesafe-actions";
import analyticsService from "../../../analytics/AnalyticsService";
import {behaviorExprActions, BehaviorExprActionType} from "./behaviorExprActions";

const trackedActions = [
	behaviorExprActions.saveBehaviorExpr.success,
	behaviorExprActions.saveBehaviorExpr.failure,

	behaviorExprActions.deleteBehaviorExperiment.success,
	behaviorExprActions.deleteBehaviorExperiment.failure,
].map(getType);

const properties = (action: BehaviorExprActionType) => {
	switch (action.type) {
		default:
			return null;
	}
};

export const behaviorExprEvents = analyticsService.trackReduxActions(trackedActions, properties);
