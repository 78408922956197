import {isMobile} from "react-device-detect";
import {warningBarActions} from "../redux";
import InfoIcon from "@material-ui/icons/Info";
import {put} from "redux-saga/effects";
import featureFlags from "../../featureFlags/FeatureFlags";
import strTranslation from "../../assets/lang/strings";

export function* validateBrowserSaga() {
	if (isMobile && !featureFlags.portalForTablet) {
		yield put(
			warningBarActions.addWarningBar({
				icon: InfoIcon,
				key: "unsupported-device",
				message: strTranslation.BROWSER.warn.unsupported_device,
			}),
		);
	}
}
