import {StringMap} from "services/utils/Maps";
import {LoadingState} from "constants/redux";
import {TargetValueType} from "@sense-os/goalie-js";

/**
 * Enum of available target names
 */
export enum TargetName {
	STEP_COUNT = "step_count",
}

/**
 * Override Target interface since TargetResponse interface
 * from @sense-os/goalie-js has `startTime` and `endTime`
 * with `string` while we use `Date`
 *
 * @see {TargetResponse} inside @sense-os/goalie-js
 */
export interface Target {
	targetName: TargetName;
	startTime: Date;
	endTime: Date;
	userId: number;
	createdBy: number;
	notificationEnabled: boolean;
	version: number;
	value: TargetValueType;
}

export interface SensorTargetState {
	/**
	 * Determine if active target is being fetched.
	 * Keyed by `TargetName`
	 */
	loadingFetchActiveTarget: StringMap<LoadingState>;
	/**
	 * Determine if target is being fetched.
	 * Keyed by `TargetName`
	 */
	loadingFetchTarget: StringMap<LoadingState>;
	/**
	 * Determine if target is being updated.
	 * Keyed by `TargetName`
	 */
	loadingUpdateTarget: StringMap<LoadingState>;
	/**
	 * Stores targets keyed by `TargetName`
	 */
	targets: StringMap<Target[]>;
	/**
	 * Stores active target per `TargetName`
	 */
	activeTarget: StringMap<Target>;
	/**
	 * Is configuration modal open per `TargetName`
	 */
	isOpenConfigModal: StringMap<boolean>;
}
