import {TaskItemResponse, TaskStatus} from "@sense-os/goalie-js";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const interventionTaskRegistrationActions = {
	fetchTaskRegistration: createAsyncAction(
		"interventionTaskRegistrationActions/fetchTaskRegistration",
		"interventionTaskRegistrationActions/fetchTaskRegistrationSucceed",
		"interventionTaskRegistrationActions/fetchTaskRegistrationFailed",
	)<
		{hashId: string; page: number; pageSize: number; status?: TaskStatus},
		{results: TaskItemResponse<any>[]; count: number},
		Error
	>(),
};

export type interventionTaskRegistrationActionType = ActionType<typeof interventionTaskRegistrationActions>;
