import produce from "immer";
import {LoadingState} from "constants/redux";
import {getType} from "typesafe-actions";
import {RelatedTaskState} from "../taskTypes";
import {taskRegistrationActionType, taskRegistrationActions} from "./taskRegistrationActions";

const initialState: RelatedTaskState = {
	/**
	 * To determine fetching state while fetching
	 */
	fetchingState: LoadingState.EMPTY,
	/**
	 * The Fetched related task
	 */
	data: null,
};

export const relatedTaskReducer = produce((state: RelatedTaskState, action: taskRegistrationActionType) => {
	switch (action.type) {
		case getType(taskRegistrationActions.fetchTaskByTaskRegistrationId.request): {
			state.fetchingState = LoadingState.LOADING;
			return;
		}
		case getType(taskRegistrationActions.fetchTaskByTaskRegistrationId.success): {
			const {taskRegistration} = action.payload;

			state.fetchingState = LoadingState.LOADED;
			state.data = taskRegistration;
			return;
		}
		case getType(taskRegistrationActions.fetchTaskByTaskRegistrationId.failure): {
			state.fetchingState = LoadingState.ERROR;
			return;
		}
		case getType(taskRegistrationActions.resetRelatedTask): {
			state.data = null;
			state.fetchingState = LoadingState.EMPTY;
			return;
		}
		// while fetching the detail dialog, also set the linked related task
		case getType(taskRegistrationActions.fetchTaskRegistrationDetailDialog.success): {
			const {taskRegistrationData} = action.payload;

			state.data = taskRegistrationData;
			return;
		}
	}
}, initialState);
