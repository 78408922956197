import {Sensors} from "redux/tracking/TrackingTypes";
import strTranslation from "../../../../assets/lang/strings";
import {IGraphConfig} from "../../customTrackerGraphV2Types";
import {TrackerSensorName} from "../../customTrackerSensorTypes";
import {averageDurationConfig, IAverageDurationGraphConfig} from "./averageDurationConfig";
import {
	averageDurationPhysicalSensationConfig,
	averageIntensityPhysicalSensationConfig,
} from "./physicalSensationConfig";
import {averageIntensityConfig} from "./averageIntensityConfig";
import {averageQuantityConfig} from "./averageQuantityConfig";
import {booleanConfig} from "./booleanConfig";
import {checkInConfig} from "./checkInConfig";
import {quantityConfig} from "./quantityConfig";
import {researchEmotionsConfig} from "./researchEmotionsConfig";
import {totalRegistrationConfig} from "./totalRegistrationConfig";
import {NDColors} from "../../../../material-ui/NDColors";
import localization from "../../../../localization/Localization";
import {totalRegistrationWithSymptomConfig} from "./totalRegistrationWithSymptomConfig";
import {customTrackerLocalizationMap} from "../trackerLocalizations";
import {totalRegistrationCategoricalConfig} from "./categoricalConfig";
import {MeasureCategoricalType} from "@sense-os/goalie-js/dist/tracking";

export const supportedSensorGraphV2 = {
	check_in: Sensors.CHECK_IN_FEELING,
};

export enum GRAPH_TIME_VIEW_VALUE {
	ALL_TIME = "AllTime",
	MONTH = "Month",
	WEEK = "Week",
}

export const GRAPH_TIME_VIEW_OPTIONS = [
	{
		value: GRAPH_TIME_VIEW_VALUE.ALL_TIME,
		translation: localization.formatMessage(strTranslation.GRAPH.GRAPHV2.time_options.all_time),
	},
	{
		value: GRAPH_TIME_VIEW_VALUE.MONTH,
		translation: localization.formatMessage(strTranslation.GRAPH.GRAPHV2.time_options.month),
	},
	{
		value: GRAPH_TIME_VIEW_VALUE.WEEK,
		translation: localization.formatMessage(strTranslation.GRAPH.GRAPHV2.time_options.week),
	},
];

type TotalIntensityDuration = [IGraphConfig, IGraphConfig, IGraphConfig];
type BooleanDuration = [IGraphConfig, IGraphConfig];

const aggressionAverageDurationConfig: IAverageDurationGraphConfig[] = [
	{
		id: strTranslation.CUSTOM_TRACKER.chart.physical_aggression.average_duration,
		color: NDColors.ORANGE,
	},
	{
		id: strTranslation.CUSTOM_TRACKER.chart.verbal_aggression.average_duration,
		color: NDColors.ROYAL_BLUE,
	},
];

const aggressionAverageIntensityConfig: IAverageDurationGraphConfig[] = [
	{
		id: strTranslation.CUSTOM_TRACKER.chart.physical_aggression.average_intensity,
		color: NDColors.ORANGE,
	},
	{
		id: strTranslation.CUSTOM_TRACKER.chart.verbal_aggression.average_intensity,
		color: NDColors.ROYAL_BLUE,
	},
];

export interface IGraphV2TrackerConfigs {
	// tracker with total, intensity , and duration
	[TrackerSensorName.compulsion]: TotalIntensityDuration;
	[TrackerSensorName.craving]: TotalIntensityDuration;
	[TrackerSensorName.depersonalization]: TotalIntensityDuration;
	[TrackerSensorName.depressed]: TotalIntensityDuration;
	[TrackerSensorName.distracted]: TotalIntensityDuration;
	[TrackerSensorName.gratitude]: TotalIntensityDuration;
	[TrackerSensorName.hyperActivity]: TotalIntensityDuration;
	[TrackerSensorName.hyperAlert]: TotalIntensityDuration;
	[TrackerSensorName.hyperAroused]: TotalIntensityDuration;
	[TrackerSensorName.loneliness]: TotalIntensityDuration;
	[TrackerSensorName.pain]: TotalIntensityDuration;
	[TrackerSensorName.physicalWeakness]: TotalIntensityDuration;
	[TrackerSensorName.sleep]: TotalIntensityDuration;
	[TrackerSensorName.withdrawal]: TotalIntensityDuration;

	// tracker with average intensity
	[TrackerSensorName.afraid]: [IGraphConfig];
	[TrackerSensorName.angry]: [IGraphConfig];
	[TrackerSensorName.anxiety]: [IGraphConfig];
	[TrackerSensorName.ashamed]: [IGraphConfig];
	[TrackerSensorName.energy]: [IGraphConfig];
	[TrackerSensorName.exercise]: [IGraphConfig];
	[TrackerSensorName.excited]: [IGraphConfig];
	[TrackerSensorName.fatigue]: [IGraphConfig];
	[TrackerSensorName.food]: [IGraphConfig];
	[TrackerSensorName.guilty]: [IGraphConfig];
	[TrackerSensorName.happy]: [IGraphConfig];
	[TrackerSensorName.hyperAssertiveness]: [IGraphConfig];
	[TrackerSensorName.insecure]: [IGraphConfig];
	[TrackerSensorName.panicAttack]: [IGraphConfig];
	[TrackerSensorName.sad]: [IGraphConfig];
	[TrackerSensorName.socialAvoidance]: [IGraphConfig];
	[TrackerSensorName.suspiciousness]: [IGraphConfig];
	[TrackerSensorName.tension]: [IGraphConfig];

	// tracker with average duration
	[TrackerSensorName.attentionDeficit]: [IGraphConfig];
	[TrackerSensorName.exercise]: [IGraphConfig];
	[TrackerSensorName.mindfulness]: [IGraphConfig];
	[TrackerSensorName.physicalActivity]: [IGraphConfig];
	[TrackerSensorName.restAndRelax]: [IGraphConfig];
	[TrackerSensorName.worry]: [IGraphConfig];

	// tracker with boolean and average duration
	[TrackerSensorName.avoidance]: BooleanDuration;
	[TrackerSensorName.bodycheck]: BooleanDuration;
	[TrackerSensorName.flashbacks]: BooleanDuration;
	[TrackerSensorName.memoryLoss]: BooleanDuration;
	[TrackerSensorName.nightmares]: BooleanDuration;
	[TrackerSensorName.safetyBehaviour]: BooleanDuration;

	// tracker with total quantity
	[TrackerSensorName.alcohol]: [IGraphConfig];
	[TrackerSensorName.cocaine]: [IGraphConfig];
	[TrackerSensorName.positiveThings]: [IGraphConfig];
	[TrackerSensorName.smoking]: [IGraphConfig];
	[TrackerSensorName.addictionBehavior]: [IGraphConfig];

	// tracker with boolean
	[TrackerSensorName.impulsivity]: [IGraphConfig];

	// tracker with total, physical, verbal aggression
	[TrackerSensorName.aggression]: [IGraphConfig, IGraphConfig, IGraphConfig];

	// tracker with total quantity and average duration
	[TrackerSensorName.binge]: [IGraphConfig, IGraphConfig];

	// tracker with total, and panic attack with physical sensation
	[TrackerSensorName.panicAttackWithPhysicalSensation]: [IGraphConfig, IGraphConfig, IGraphConfig];

	// multi intensity 3d research emotions
	[TrackerSensorName.researchEmotions]: [IGraphConfig];

	// average quantity
	[TrackerSensorName.weight]: [IGraphConfig];

	// check-in Feeling
	[Sensors.CHECK_IN_FEELING]: [IGraphConfig];

	// total registration with symptom
	[TrackerSensorName.cognitiveComplaints]: [IGraphConfig];
	[TrackerSensorName.dissociation]: [IGraphConfig];
	[TrackerSensorName.hallucinations]: [IGraphConfig];
	[TrackerSensorName.sideEffectsMedication]: [IGraphConfig];

	// total registration with symptom and intensity
	[TrackerSensorName.overstimulated]: [IGraphConfig, IGraphConfig];

	// categorical
	[TrackerSensorName.assertiveness]: [IGraphConfig];
}

export const graphV2TrackerConfigs: IGraphV2TrackerConfigs = {
	// tracker with total, intensity, and duration
	[TrackerSensorName.compulsion]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureCompulsion"]),
		averageDurationConfig(["measureCompulsion"]),
	],
	[TrackerSensorName.craving]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureCraving"]),
		averageDurationConfig(["measureCraving"]),
	],
	[TrackerSensorName.depersonalization]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureDepersonalization"]),
		averageDurationConfig(["measureDepersonalization"]),
	],
	[TrackerSensorName.depressed]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureDepressed"]),
		averageDurationConfig(["measureDepressed"]),
	],
	[TrackerSensorName.distracted]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureDistracted"]),
		averageDurationConfig(["measureDistracted"]),
	],
	[TrackerSensorName.gratitude]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureGratitude"]),
		averageDurationConfig(["measureGratitude"]),
	],
	[TrackerSensorName.hyperActivity]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureHyperactive"]),
		averageDurationConfig(["measureHyperactive"]),
	],
	[TrackerSensorName.hyperAlert]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureHyperAlert"]),
		averageDurationConfig(["measureHyperAlert"]),
	],
	[TrackerSensorName.hyperAroused]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureHyperAroused"]),
		averageDurationConfig(["measureHyperAroused"]),
	],
	[TrackerSensorName.loneliness]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureLoneliness"]),
		averageDurationConfig(["measureLoneliness"]),
	],
	[TrackerSensorName.pain]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measurePain"]),
		averageDurationConfig(["measurePain"]),
	],
	[TrackerSensorName.physicalWeakness]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measurePhysicalWeakness"]),
		averageDurationConfig(["measurePhysicalWeakness"]),
	],
	[TrackerSensorName.sleep]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureSleepQuality"]),
		averageDurationConfig(["measureSleepQuality"]),
	],

	[TrackerSensorName.withdrawal]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureWithdrawalSymptoms"]),
		averageDurationConfig(["measureWithdrawalSymptoms"]),
	],

	// tracker with average intensity
	[TrackerSensorName.afraid]: [averageIntensityConfig(["measureAfraid"])],
	[TrackerSensorName.angry]: [averageIntensityConfig(["measureAngry"])],
	[TrackerSensorName.anxiety]: [averageIntensityConfig(["measureAnxiousness"])],
	[TrackerSensorName.ashamed]: [averageIntensityConfig(["measureAshamed"])],
	[TrackerSensorName.energy]: [averageIntensityConfig(["measureEnergy"])],
	[TrackerSensorName.excited]: [averageIntensityConfig(["measureExcitement"])],
	[TrackerSensorName.fatigue]: [averageIntensityConfig(["measureFatigue"])],
	[TrackerSensorName.food]: [averageIntensityConfig(["measureMealHealthiness"])],
	[TrackerSensorName.guilty]: [averageIntensityConfig(["measureGuilty"])],
	[TrackerSensorName.happy]: [averageIntensityConfig(["measureHappy"])],
	[TrackerSensorName.hyperAssertiveness]: [averageIntensityConfig(["measureHyperAssertiveness"])],
	[TrackerSensorName.insecure]: [averageIntensityConfig(["measureInsecure"])],
	[TrackerSensorName.panicAttack]: [averageIntensityConfig(["measurePanicAttacks"])],
	[TrackerSensorName.sad]: [averageIntensityConfig(["measureSad"])],
	[TrackerSensorName.socialAvoidance]: [averageIntensityConfig(["measureSocialAvoidance"])],
	[TrackerSensorName.suspiciousness]: [averageIntensityConfig(["measureSuspicion"])],
	[TrackerSensorName.tension]: [averageIntensityConfig(["measureTension"])],

	// tracker with average duration
	[TrackerSensorName.attentionDeficit]: [averageDurationConfig(["measureAttentionDeficit"])],
	[TrackerSensorName.exercise]: [averageDurationConfig(["measureExercise"])],
	[TrackerSensorName.mindfulness]: [averageDurationConfig(["measureMindfulness"])],
	[TrackerSensorName.physicalActivity]: [averageDurationConfig(["measurePhysicalActivity"])],
	[TrackerSensorName.restAndRelax]: [averageDurationConfig(["measureRelaxation"])],
	[TrackerSensorName.worry]: [averageDurationConfig(["measureWorry"])],

	// tracker with boolean and average duration
	[TrackerSensorName.avoidance]: [averageDurationConfig(["measureAvoidance"]), booleanConfig("measureAvoidance")],
	[TrackerSensorName.bodycheck]: [averageDurationConfig(["measureBodycheck"]), booleanConfig("measureBodycheck")],
	[TrackerSensorName.flashbacks]: [averageDurationConfig(["measureFlashbacks"]), booleanConfig("measureFlashbacks")],
	[TrackerSensorName.memoryLoss]: [averageDurationConfig(["measureMemoryLoss"]), booleanConfig("measureMemoryLoss")],
	[TrackerSensorName.nightmares]: [averageDurationConfig(["measureNightmares"]), booleanConfig("measureNightmares")],
	[TrackerSensorName.safetyBehaviour]: [
		averageDurationConfig(["measureSafetyBehaviour"]),
		booleanConfig("measureSafetyBehaviour"),
	],

	// tracker with total quantity
	[TrackerSensorName.alcohol]: [quantityConfig("measureUnitOfAlcohol")],
	[TrackerSensorName.cocaine]: [quantityConfig("measureCocaine")],
	[TrackerSensorName.positiveThings]: [quantityConfig("measurePositiveThings")],
	[TrackerSensorName.smoking]: [quantityConfig("measureCigarettes")],
	[TrackerSensorName.addictionBehavior]: [quantityConfig("measureAddictionBehaviour")],

	// tracker with boolean
	[TrackerSensorName.impulsivity]: [booleanConfig("measureImpulsive")],

	// tracker with total, physical, and verbal aggression
	[TrackerSensorName.aggression]: [
		totalRegistrationConfig(),
		averageDurationConfig(
			["measurePhysicalAggression", "measureVerbalAggression"],
			aggressionAverageDurationConfig,
		),
		averageIntensityConfig(
			["measurePhysicalAggression", "measureVerbalAggression"],
			aggressionAverageIntensityConfig,
		),
	],

	// tracker with total quantity and average duration
	[TrackerSensorName.binge]: [quantityConfig("measureBinge"), averageDurationConfig(["measureBinge"])],

	// tracker with total, and panic attack with physical sensation
	// TODO: it will be merged in tracker v4
	[TrackerSensorName.panicAttackWithPhysicalSensation]: [
		totalRegistrationConfig(),
		averageIntensityPhysicalSensationConfig(["measurePanicAttacks"]),
		averageDurationPhysicalSensationConfig(["measurePanicAttacks"]),
	],

	// multi intensity 3d research emotions
	[TrackerSensorName.researchEmotions]: [researchEmotionsConfig()],

	// average quantity
	[TrackerSensorName.weight]: [
		averageQuantityConfig("measureWeight", strTranslation.CUSTOM_TRACKER.chart.weight.kilograms),
	],

	// check-in Feeling
	[Sensors.CHECK_IN_FEELING]: [checkInConfig()],

	// total registration with symptom
	[TrackerSensorName.cognitiveComplaints]: [
		totalRegistrationWithSymptomConfig([
			{attr: "measureForgetfulness", translation: customTrackerLocalizationMap.measure_forgetfulness},
			{
				attr: "measureProblemsConcentrating",
				translation: customTrackerLocalizationMap.measure_problems_concentrating,
			},
			{attr: "measureAbsentMinded", translation: customTrackerLocalizationMap.measure_absentMinded},
			{attr: "measureDisoriented", translation: customTrackerLocalizationMap.measure_disoriented},
		]),
	],
	[TrackerSensorName.dissociation]: [
		totalRegistrationWithSymptomConfig([
			{attr: "measureDepersonalization", translation: customTrackerLocalizationMap.measure_depersonalization},
			{attr: "measureDerealization", translation: customTrackerLocalizationMap.measure_derealization},
		]),
	],
	[TrackerSensorName.hallucinations]: [
		totalRegistrationWithSymptomConfig([
			{attr: "measureSoundOrVoices", translation: customTrackerLocalizationMap.measure_sound_or_voices},
			{attr: "measureVisual", translation: customTrackerLocalizationMap.measure_visual},
			{attr: "measureTouch", translation: customTrackerLocalizationMap.measure_touch},
			{attr: "measureSmell", translation: customTrackerLocalizationMap.measure_smell},
			{attr: "measureTaste", translation: customTrackerLocalizationMap.measure_taste},
		]),
	],
	[TrackerSensorName.sideEffectsMedication]: [
		totalRegistrationWithSymptomConfig([
			{
				attr: "measureHeadache2",
				translation: customTrackerLocalizationMap.measure_headache_2,
			},
			{attr: "measureWeightGain", translation: customTrackerLocalizationMap.tracker_weight},
			{
				attr: "measureDizzy",
				translation: customTrackerLocalizationMap.measure_dizzy,
			},
			{attr: "measureDryMouth", translation: customTrackerLocalizationMap.measure_dry_mouth},
			{
				attr: "measureMuscleSpasmsAndCramps",
				translation: customTrackerLocalizationMap.measure_muscle_spasms_and_cramps,
			},
			{attr: "measureNausea", translation: customTrackerLocalizationMap.measure_nausea},
			{attr: "measureLossOfSexDrive", translation: customTrackerLocalizationMap.measure_loss_of_sex_drive},
			{
				attr: "measureAnxiety",
				translation: customTrackerLocalizationMap.measure_anxiety,
			},
			{attr: "measureLowMood", translation: customTrackerLocalizationMap.measure_low_mood},
			{attr: "measureMemoryLoss2", translation: customTrackerLocalizationMap.measure_memory_loss_2},
			{
				attr: "measureSuicidalThoughts",
				translation: customTrackerLocalizationMap.measure_suicidal_thoughts,
			},
			{attr: "measureSuspicion2", translation: customTrackerLocalizationMap.measure_suspicion_2},
			{attr: "measureConstipation", translation: customTrackerLocalizationMap.measure_constipation},
			{
				attr: "measureSleepinessOrProblemsSleeping",
				translation: customTrackerLocalizationMap.measure_sleepiness_or_problems_sleeping,
			},
		]),
	],

	// total registration with symptom and intensity

	[TrackerSensorName.overstimulated]: [
		totalRegistrationWithSymptomConfig([
			{
				attr: "measureAttentionOrConcentrationProblem",
				translation: customTrackerLocalizationMap.measure_attention_or_Concentration_problem,
			},
			{attr: "measureMoodSwings", translation: customTrackerLocalizationMap.measure_mood_swings},
			{
				attr: "measureIrritableOrEmotional",
				translation: customTrackerLocalizationMap.measure_irritable_or_emotional,
			},
			{attr: "measureRestlessness", translation: customTrackerLocalizationMap.measure_restlessness},
			{attr: "measureProcrastination", translation: customTrackerLocalizationMap.measure_procrastination},
			{attr: "measureSleepingProblems", translation: customTrackerLocalizationMap.measure_sleeping_problems},
			{attr: "measureInsecureOrAnxious", translation: customTrackerLocalizationMap.measure_insecure_or_anxious},
			{
				attr: "measureFeelingOverwhelmedOrDifficultyWithOrganisation",
				translation: customTrackerLocalizationMap.measure_feeling_overwhelmed_or_difficulty_with_organisation,
			},
			{attr: "measureInARush", translation: customTrackerLocalizationMap.measure_in_a_rush},
			{attr: "measureLowMood", translation: customTrackerLocalizationMap.measure_low_mood},
		]),
		averageIntensityConfig(["measureOverstimulation"]),
	],

	// categorical
	[TrackerSensorName.assertiveness]: [
		totalRegistrationCategoricalConfig("measureAssertiveness", [
			{
				categorical: MeasureCategoricalType.SUB_ASSERTIVE,
				translation: strTranslation.ZZ_CUSTOM_TRACKER.property.categorical.sub_assertive,
			},
			{
				categorical: MeasureCategoricalType.ASSERTIVE,
				translation: strTranslation.ZZ_CUSTOM_TRACKER.property.categorical.assertive,
			},
			{
				categorical: MeasureCategoricalType.HYPER_ASSERTIVE,
				translation: strTranslation.ZZ_CUSTOM_TRACKER.property.categorical.hyper_assertive,
			},
		]),
	],
};
