import {NewFeatures} from "../newFeaturePopup/newFeaturePopupTypes";

import {UserSettings} from "./userSettingsTypes";

/**
 * This function will reconcile the user settings that was stored in the backend
 * and the user settings that was stored locally in redux.
 * This reconciling is needed because every "update user settings" operation to backend
 * will replace entirely the stored user settings in BE with whatever the payload of the
 * "update user settings" operation. That is, every payload of "update user settings operation to BE"
 * need to contain the correct latest user settings, and thus, before updating, portal need
 * to know what is the correct latest user settings is.
 *
 * Mostly, we treat the settings from backend as the source of truth.
 * And by doing that, we solve the staleness of local user settings that might arise
 * from opening portal in multiple tabs.
 *
 * But, there's also a tradeoff, where problem can arise if portal try to update user settings
 * successively in short time. For example:
 * 1) Portal update settings of field A with value ValueA in two steps:
 *    - Step A1: Portal get the latest settings in backend, let's called it US1.
 *    - Step A2: Portal update the settings with payload "{...US1, A: ValueA}"
 * 2) Portal update settings of field B with value ValueB:
 *    - Step B1: Portal get the latest setting from BE, let's called it US2.
 *    - Step B2: Portal update the settings with payload "{...US2, B: ValueB}"
 *    Here, in step B1, if portal do this operation before the step A2 finish completely,
 *    then it's possible that portal still get US1 payload, and in turn, revert what has been
 *    done in step A2.
 *
 *  Mostly, we accept this tradeoff. But, in one case for settings of "New Feature Popup",
 *  we can mitigate this issue by putting priorty to field which already flagged true.
 */
export const reconcileUserSettings = (
	settingsFromBE: Partial<UserSettings>,
	localSettings: Partial<UserSettings>,
): Partial<UserSettings> => {
	const flagForRecentChangesPopup = {
		...settingsFromBE?.flagForRecentChangesPopup,
	};

	const localFlags = localSettings?.flagForRecentChangesPopup || {};
	Object.keys(localFlags).forEach((key: NewFeatures) => {
		if (localFlags[key]) {
			flagForRecentChangesPopup[key] = true;
		}
	});

	return {...settingsFromBE, flagForRecentChangesPopup};
};
