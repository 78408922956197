import {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Paper, IconButton, InputBase, makeStyles, CircularProgress} from "@material-ui/core";
import {Search, Clear} from "@material-ui/icons";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {Utils} from "utils/Utils";
import {ndBlogSearchActions} from "../redux/NDBlogSearchActions";
import {isLoading} from "../redux/NDBlogSearchSelector";
import {MAX_CHAR_SEARCH} from "../NDBlogSearchTypes";

export const NDBlogSearchInput = () => {
	const classes = useSearchInputStyles({});
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState<string>("");
	const searchIsLoading: boolean = useSelector(isLoading);
	const inputRef = useRef<HTMLInputElement>();

	// Input field should focus everytime component is rendered
	// we're using it because "autoFocus" is not always works
	useEffect(() => {
		inputRef.current.focus();
	}, []);

	useEffect(() => {
		// Add delay to prevent rapid action dispatch during search text typing.
		const timer = setTimeout(() => {
			if (Utils.stringHasNoValue(searchText)) {
				dispatch(ndBlogSearchActions.clearResult());
			} else {
				dispatch(ndBlogSearchActions.searchPostItem.request({keywords: searchText}));
			}
		}, 500);

		return () => clearTimeout(timer);
	}, [dispatch, searchText]);

	const handleOnClear = () => {
		setSearchText("");
		inputRef.current.focus();
		dispatch(ndBlogSearchActions.clearResult());
	};

	return (
		<Paper className={classes.root} elevation={3}>
			<IconButton disabled>
				<Search />
			</IconButton>
			<InputBase
				id="psychoeducation_search_input"
				type="text"
				placeholder={loc.formatMessage(strTranslation.BLOG_SEARCH.search_input.placeholder.text)}
				value={searchText}
				inputRef={inputRef}
				inputProps={{maxLength: MAX_CHAR_SEARCH}}
				onChange={(e) => setSearchText(e.currentTarget.value)}
				className={classes.input}
			/>
			{searchText.length > 0 &&
				(searchIsLoading ? (
					<CircularProgress size={25} />
				) : (
					<IconButton
						id="psychoeducation_clear_btn"
						aria-label="clear"
						onClick={handleOnClear}
						disableFocusRipple
					>
						<Clear />
					</IconButton>
				))}
		</Paper>
	);
};

const useSearchInputStyles = makeStyles(() => ({
	root: {
		margin: "5px 0px",
		paddingRight: "15px",
		height: "60px",
		display: "flex",
		alignItems: "center",
		backgroundColor: "#fff",
		borderRadius: "10px",
	},
	input: {
		flex: 1,
	},
}));
