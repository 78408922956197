import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {RegistrationListResponse} from "@sense-os/goalie-js";

import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import groupTherapySDK from "../helpers/groupTherapySdk";
import {getSessionId} from "../../auth/helpers/authStorage";

import {groupTherapyActions} from "../redux/groupTherapyActions";
import {GroupRegistrationItem} from "../groupTherapy";
import {convertRegistrationListIntoRegistrationItems} from "../helpers/groupTherapyHelpers";

const log = createLogger("fetchGroupRegistrationListSaga", SentryTags.GroupTherapy);

/**
 * This saga will handling group registrations fetch.
 * Steps:
 * 1. Fetch selected group registration list from backend
 * 2. Convert response into local `GroupRegistrationItem` type
 */
function* fetchGroupRegistrations(action: ActionType<typeof groupTherapyActions.fetchGroupRegistrationList.request>) {
	const {id, page, pageSize} = action.payload;
	const token: string = yield call(getSessionId);

	try {
		// 1. Get group registration list
		const response: RegistrationListResponse = yield apiCallSaga(
			groupTherapySDK.getGroupRegistrationList,
			token,
			id,
			page,
			pageSize,
		);

		// 2. Convert into array of `GroupRegistrationItem`
		const registrationItems: GroupRegistrationItem[] = yield call(
			convertRegistrationListIntoRegistrationItems,
			response.results,
		);

		yield put(
			groupTherapyActions.fetchGroupRegistrationList.success({
				id,
				registrations: registrationItems,
				totalRegistrations: response.count,
			}),
		);
	} catch (err) {
		log.captureException(err, {message: "Unable to fetch group registration list."});
		yield put(groupTherapyActions.fetchGroupRegistrationList.failure(err));
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.fetchGroupRegistrationList.request, fetchGroupRegistrations);
}
