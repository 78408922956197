import {CSSProperties, ComponentType} from "react";
import {GroupTherapyMember, GroupTherapyRegistration, GroupTherapyTracker, RegistrationType} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";
import {MeetingNote, SensorDatum} from "redux/tracking/TrackingTypes";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";

export interface GroupRegistrationItem {
	Icon: ComponentType<{style?: CSSProperties}>;
	title: string;
	dueDate: Date;
	time?: string;
	hasReminder?: boolean;
	registrationId?: number;
	type: RegistrationType;
	createdBy: number;
	createdAt: Date;
}

export interface GroupTherapy {
	id: number;
	name: string;
	createdAt: Date;
	therapists: GroupTherapyMember[];
	clients: GroupTherapyMember[];
	groupRegistrations?: GroupRegistrationItem[];
	totalRegistrations?: number;
}

export interface GroupTherapyReduxState {
	loadingState: LoadingState;
	savingState: LoadingState;
	deletingState: LoadingState;
	registrationFetchState: LoadingState;
	registrationSavingState: LoadingState;
	registrationDeletingState: LoadingState;
	registrationDetailFetchingState: LoadingState;
	groupMap: Record<number, GroupTherapy>;
	groupForDialog?: Partial<GroupTherapy>;
	registrationDetails?: GroupTherapyRegistration;
	relatedSensorDataMap?: Record<number, SensorDatum<BasePlannedEventEntry | MeetingNote>>;
	// group therapy manage tracker state
	isManageTrackersDialogOpened: boolean;
	trackers: GroupTherapyTracker[];
	trackersFetchingState: LoadingState;
	trackersSavingState: LoadingState;
}

/** The first page of table pagination */
export const FIRST_PAGE = 1;

/** The page size of table pagination */
export const PAGE_SIZE = 100;
