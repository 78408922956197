import {getType} from "typesafe-actions";
import produce from "immer";

import {InterventionNoteState} from "../interventionNoteType";
import {interventionNoteActionType, interventionNoteActions} from "./interventionNoteActions";
import {LoadingState} from "constants/redux";

const initialState: InterventionNoteState = {
	data: [],
	loadingState: LoadingState.EMPTY,
};

export const interventionNoteReducer = produce((state: InterventionNoteState, action: interventionNoteActionType) => {
	switch (action.type) {
		case getType(interventionNoteActions.fetchNotes.request): {
			state.loadingState = LoadingState.LOADING;
			state.data = [];
			state.error = null;
			return;
		}
		case getType(interventionNoteActions.fetchNotes.success): {
			const {data} = action.payload;
			state.loadingState = LoadingState.LOADED;
			state.data = data;
			return;
		}
		case getType(interventionNoteActions.fetchNotes.failure): {
			const {err} = action.payload;
			state.loadingState = LoadingState.ERROR;
			state.error = err;
			return;
		}
	}
}, initialState);
