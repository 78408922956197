import {ActionType, createAction} from "typesafe-actions";

export const bootstrapActions = {
	startBootstrapApp: createAction("bootstrap/start")(),
	endBootstrapApp: createAction("bootstrap/end")(),

	waitForSortedContacts: createAction("bootstrap/waitForSortedContacts")(),
	contactsSorted: createAction("bootstrap/contactsSorted")(),
};

export type BootstrapActionType = ActionType<typeof bootstrapActions>;
