import {fileSharingActions, FileSharingActionType} from "./fileSharingActions";
import {getType} from "typesafe-actions";
import analyticsService from "../../analytics/AnalyticsService";

const trackedActions = [
	fileSharingActions.openFileBrowser,
	fileSharingActions.closeFileBrowser,

	fileSharingActions.setSelectedFile,

	fileSharingActions.upload.request,
	fileSharingActions.upload.success,
	fileSharingActions.upload.failure,

	fileSharingActions.uploadFiles.request,
	fileSharingActions.uploadFiles.success,
	fileSharingActions.uploadFiles.failure,

	fileSharingActions.deleteFile.request,
	fileSharingActions.deleteFile.success,
	fileSharingActions.deleteFile.failure,
].map(getType);

const properties = (action: FileSharingActionType) => {
	switch (action.type) {
		case getType(fileSharingActions.setSelectedFile):
			return action.payload;
		default:
			return null;
	}
};

export const fileSharingEvents = analyticsService.trackReduxActions(trackedActions, properties);
