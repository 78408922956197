import {call, fork, put, select, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import strTranslation from "../../assets/lang/strings";
import {getAuthUserId, getSessionId} from "../../auth/helpers/authStorage";
import contactSdk from "../../contacts/contactSdk";
import {contactActions} from "../../contacts/redux/contactAction";
import {getContactByIdFn} from "../../contacts/redux/contactSelectors";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {makeQueue} from "../../helpers/sagas/sagaQueue";
import localization from "../../localization/Localization";
import createLogger from "../../logger/createLogger";
import {toastActions} from "../../toaster/redux";
import {clientManagementActions} from "../redux/clientManagementActions";

import clientManagementAuxInfoSaga from "./clientManagementAuxInfoSaga";

const log = createLogger("clientManagementSaga", SentryTags.ClientManagement);

const disconnectClientsQueue = makeQueue("disconnectMultipleClients", 3);
const queuedRemoveContactSdk = disconnectClientsQueue.wrapFnInQueue(contactSdk.removeContactFromUserContact);

function* disconnectMultipleClients(
	action: ActionType<typeof clientManagementActions.disconnectMultipleClients.request>,
) {
	try {
		const token: string = yield call(getSessionId);
		const userId: number = yield call(getAuthUserId);
		const {clientIds} = action.payload;

		let results = [];
		for (let idx = 0; idx < clientIds.length; idx++) {
			const id = clientIds[idx];
			const contact = yield select(getContactByIdFn(id));

			try {
				yield apiCallSaga(queuedRemoveContactSdk, token, userId, id);
				results.push({isSuccess: true, clientId: id});

				if (!!contact.fullName) {
					yield put(
						toastActions.addToast({
							message: localization.formatMessage(
								strTranslation.CLIENT_NETWORK.disconnect_yourself_multiple.toast.success,
								{name: contact?.fullName},
							),
							type: "success",
						}),
					);
					yield put(contactActions.removeContact.success({userId: id}));
				}
			} catch (error) {
				log.captureException(error);
				yield put(
					toastActions.addToast({
						message: localization.formatMessage(
							strTranslation.CLIENT_NETWORK.disconnect_yourself_multiple.toast.error,
							{name: contact?.fullName},
						),
						type: "error",
					}),
				);

				results.push({isSuccess: false, clientId: id, error});
			}
		}

		yield put(clientManagementActions.disconnectMultipleClients.success({results}));
	} catch (err) {
		log.captureException(err);
	}
}

export default function* () {
	yield takeEvery(getType(clientManagementActions.disconnectMultipleClients.request), disconnectMultipleClients);

	yield fork(clientManagementAuxInfoSaga);
}
