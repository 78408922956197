import {TrackerKey, TrackersHelper} from "@sense-os/goalie-js";

const trackersHelper = new TrackersHelper();

export const getTrackerIdBySensorName = (sensorName: TrackerKey) => {
	return trackersHelper.getTrackerIdBySensorName(sensorName);
};

export const getSensorNameByTrackerId = (trackerId: number) => {
	return trackersHelper.getSensorNameByTrackerId(trackerId);
};
