import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {InactiveClient, SortByState} from "../inactiveClientTypes";

export const inactiveClientActions = {
	/** Load inactive clients */
	loadInactiveClients: createAsyncAction(
		"InactiveClient/loadingInactiveClients",
		"InactiveClient/loadedInactiveClients",
		"InactiveClient/errorLoadingInactiveClients",
	)<void, {clients: InactiveClient[]; lastRegistration: InactiveClient[]}, {error: Error}>(),
	/** Go to the next page */
	goToTheNextPage: createAction("InactiveClient/goToTheNextPage")(),
	/** Go to the previous page */
	goToThePrevPage: createAction("InactiveClient/goToThePrevPage")(),
	/** Increment inactive client page */
	incrementPage: createAction("InactiveClient/incrementPage")(),
	/** Decrement inactive client page */
	decrementPage: createAction("InactiveClient/decrementPage")(),
	/** Sort inactive clients based on specific key and expected sort value (asc / desc) */
	applyDataSort: createAction("InactiveClient/applyDataSort", (state: SortByState) => ({state}))(),
};

export type InactiveClientAction = ActionType<typeof inactiveClientActions>;
