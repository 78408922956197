import {call, fork, takeEvery} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {whenLoggedIn} from "../../auth/sagas/helper";
import {connectionStatusActions} from "../../connectionStatus/redux";

import chatHistorySaga from "./chatHistorySaga";
import chatHistorySyncSaga from "./chatHistorySyncSaga";
import chatPresenceSaga from "./chatPresenceSaga";
import {connectChatWithRetry, connectionStatusHandler, disconnectChat, internetOfflineHandler} from "./connectionSaga";
import connectionStatusToasterSaga from "./connectionStatusToasterSaga";
import {messageMarkerSubscriptionHandler} from "./messageMarkerSaga";
import {chatStateChangesSubscriptionHandler} from "./chatStateSaga";
import {incomingMessageSubscriptionHandler} from "./incomingMessageSaga";
import sendMessageSaga from "./sendMessageSaga";
import markChatAsReadSaga from "./markChatAsReadSaga";
import chatHistoryFromBESaga from "./chatHistoryFromBESaga";
import getMoreHistorySaga from "./getMoreHistorySaga";
import chatSDK from "../sdk";
import {getAuthUserExternalId} from "../../auth/helpers/authStorage";
import loadChatAfterConnectSaga from "./loadChatAfterConnectSaga";

function* onLoggedIn() {
	yield takeEvery(getType(connectionStatusActions.changeOnlineStatus), internetOfflineHandler);
	yield call(connectChatWithRetry);
	yield call(chatSDK.registerDeviceForPushNotification, getAuthUserExternalId());
}

function* onLoggedOut() {
	yield call(disconnectChat);
}

export default function* () {
	// This chat subscription doesn't depend on whether there's user logged in or not
	yield fork(connectionStatusHandler);
	yield fork(connectionStatusToasterSaga);
	yield fork(chatPresenceSaga);
	yield fork(chatHistorySaga);
	yield fork(chatHistorySyncSaga);
	yield fork(messageMarkerSubscriptionHandler);
	yield fork(chatStateChangesSubscriptionHandler);
	yield fork(incomingMessageSubscriptionHandler);
	yield fork(sendMessageSaga);
	yield fork(markChatAsReadSaga);
	yield fork(chatHistoryFromBESaga);
	yield fork(getMoreHistorySaga);
	yield fork(loadChatAfterConnectSaga);

	yield fork(whenLoggedIn(onLoggedIn, onLoggedOut));
}
