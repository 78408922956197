import {UserAccount} from "@sense-os/goalie-js";
import {call, put, select, takeEvery} from "redux-saga/effects";
import strTranslation from "../../assets/lang/strings";
import featureFlags from "../../featureFlags/FeatureFlags";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {warningBarActions} from "../../warningBar/redux";
import {authSDK} from "../helpers";
import {getSessionId} from "../helpers/authStorage";
import {authActions, isAcceptedToOrganization} from "../redux";
import {history} from "visual/App";
import {Path} from "../../ts/app/Path";
import CalendarIcon from "@material-ui/icons/DateRange";
import localization from "../../localization/Localization";
import {getType} from "typesafe-actions";

export function* emailVerificationChecker() {
	const isTherapistAccepted = yield select(isAcceptedToOrganization);

	const token = yield call(getSessionId);

	try {
		const userAccount: UserAccount = yield apiCallSaga(authSDK.getUserAccount, token);
		const isVerified = userAccount.emailVerified;
		// Only show add the bar when therapist is Not verified and Accepted to an organization
		if (!isVerified && isTherapistAccepted && featureFlags.therapistEmailVerification) {
			yield put(
				warningBarActions.addWarningBar({
					icon: CalendarIcon,
					key: "email-verification",
					message: strTranslation.DASHBOARD.email_verification.warning_bar.text,
					action: (
						<button
							style={{
								padding: "5px 10px",
								cursor: "pointer",
								border: "2px solid white",
								borderRadius: "4px",
							}}
							onClick={() => history.push(Path.APP_THERAPIST_EMAIL_VERIFICATION)}
						>
							{localization.formatMessage(
								strTranslation.DASHBOARD.email_verification.warning_bar.text_button,
							)}
						</button>
					),
					isClosable: true,
				}),
			);
		}
		yield put(authActions.fetchEmailVerificationStatus.success({isVerified: isVerified}));
	} catch {
		yield put(authActions.fetchEmailVerificationStatus.failure({}));
	}
}

export default function* () {
	yield takeEvery(getType(authActions.fetchEmailVerificationStatus.request), emailVerificationChecker);
}
