import {InviteClientsRequestBody, InviteClientsResponseList} from "@sense-os/goalie-js";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const inviteClientsActions = {
	/**
	 * Fetch Invite Clients List Saga Actions
	 */
	fetchInviteClientsList: createAsyncAction(
		"inviteClientsActions/fetchInviteClientsList",
		"inviteClientsActions/fetchInviteClientsListSucceed",
		"inviteClientsActions/fetchInviteClientsListFailed",
	)<{currentPage: number; pageSize: number}, {data: InviteClientsResponseList}, {error: Error}>(),

	/**
	 * Save Invite Client Saga Actions
	 */
	saveInviteClient: createAsyncAction(
		"inviteClientsActions/saveInviteClient",
		"inviteClientsActions/saveInviteClientSucceed",
		"inviteClientsActions/saveInviteClientFailed",
	)<{form: InviteClientsRequestBody}, void, {error: Error}>(),

	/**
	 * Delete Invite Client Saga Actions
	 */
	deleteInviteClient: createAsyncAction(
		"inviteClientsActions/deleteInviteClient",
		"inviteClientsActions/deleteInviteClientSucceed",
		"inviteClientsActions/deleteInviteClientFailed",
	)<{id: number}, void, {error: Error}>(),

	/**
	 * Delete Invite Client Saga Actions
	 */
	resendInviteClient: createAsyncAction(
		"inviteClientsActions/resendInviteClient",
		"inviteClientsActions/resendInviteClientSucceed",
		"inviteClientsActions/resendInviteClientFailed",
	)<{id: number}, void, {error: Error}>(),
};

export type inviteClientsActionType = ActionType<typeof inviteClientsActions>;
