import produce from "immer";
import {getType} from "typesafe-actions";

import {LoadingState} from "../../ts/constants/redux";

import {TreatmentStatusReduxState} from "../treatmentStatusTypes";

import {treatmentStatusActions, TreatmentStatusAction} from "./treatmentStatusActions";

const defaultState: TreatmentStatusReduxState = {
	treatmentTypesOptions: [],
	clientComplaintsOptions: [],
	endTreatmentReasons: [],
	clientTreatmentsMap: {},
	fetchingTreatmentMap: {},
	fetchingAllTreatmentsState: LoadingState.EMPTY,
	isClientDetailsFormOpen: false,
	mutatingTreatmentLoadingState: LoadingState.EMPTY,
	fetchingEndTreatmentBlocker: LoadingState.EMPTY,
	fetchingAllTreatmentPersonnelLoadingState: LoadingState.EMPTY,
	timeEntriesOwnerMap: {},
};

export const treatmentStatusReducer = produce((state: TreatmentStatusReduxState, action: TreatmentStatusAction) => {
	switch (action.type) {
		case getType(treatmentStatusActions.setTreatmentStatus): {
			state.clientTreatmentsMap[action.payload.userId] = action.payload.treatmentDetails;
			return;
		}

		case getType(treatmentStatusActions.setTreatmentTypesOfTreatment): {
			state.clientTreatmentsMap[action.payload.userId].treatmentTypes = action.payload.treatmentTypes;
			return;
		}

		case getType(treatmentStatusActions.setTreatmentComplaintsOfTreatment): {
			state.clientTreatmentsMap[action.payload.userId].clientComplaints = action.payload.treatmentComplaints;
			return;
		}

		case getType(treatmentStatusActions.setTreatmentPersonnelsOfTreatment): {
			state.clientTreatmentsMap[action.payload.userId].personnels = action.payload?.personnels || [];
			return;
		}

		case getType(treatmentStatusActions.editTreatment): {
			state.editedTreatment = action.payload.treatment;
			return;
		}

		case getType(treatmentStatusActions.openClientDetailsForm): {
			state.isClientDetailsFormOpen = true;
			return;
		}

		case getType(treatmentStatusActions.closeClientDetailsForm): {
			state.isClientDetailsFormOpen = false;
			return;
		}

		case getType(treatmentStatusActions.updateTreatmentMaps): {
			state.clientTreatmentsMap = {...state.clientTreatmentsMap, ...action.payload.treatmentMaps};
			return;
		}

		case getType(treatmentStatusActions.loadTreatmentDetails.request): {
			state.fetchingTreatmentMap[action.payload.clientId] = LoadingState.LOADING;
			return;
		}

		case getType(treatmentStatusActions.loadTreatmentDetails.success): {
			state.fetchingTreatmentMap[action.payload.clientId] = LoadingState.LOADED;
			state.clientTreatmentsMap[action.payload.clientId] = {
				...state.clientTreatmentsMap[action.payload.clientId],
				clientComplaints: action.payload.treatmentComplaints,
				treatmentTypes: action.payload.treatmentTypes,
				personnels: action.payload.treatmentPersonnels,
			};
			return;
		}

		case getType(treatmentStatusActions.loadAllTreatmentPersonnels.request): {
			state.fetchingAllTreatmentPersonnelLoadingState = LoadingState.LOADING;
			return;
		}

		case getType(treatmentStatusActions.loadAllTreatmentPersonnels.success): {
			Object.keys(action.payload).forEach((clientIdStr) => {
				const clientId = Number(clientIdStr);
				state.clientTreatmentsMap[clientId] = {
					...state.clientTreatmentsMap[clientId],
					personnels: action.payload[clientId],
				};
			});
			state.fetchingAllTreatmentPersonnelLoadingState = LoadingState.LOADED;
			return;
		}

		case getType(treatmentStatusActions.loadAllTreatmentPersonnels.failure): {
			state.fetchingAllTreatmentPersonnelLoadingState = LoadingState.ERROR;
			return;
		}

		case getType(treatmentStatusActions.loadTreatmentDetails.failure): {
			state.fetchingTreatmentMap[action.payload.clientId] = LoadingState.ERROR;
			return;
		}

		case getType(treatmentStatusActions.setFetchingAllTreatmentsState): {
			state.fetchingAllTreatmentsState = action.payload.fetchingState;
			return;
		}

		case getType(treatmentStatusActions.loadTreatmentComplaints.success): {
			state.clientComplaintsOptions = action.payload.treatmentComplaints;
			return;
		}

		case getType(treatmentStatusActions.loadTreatmentTypes.success): {
			state.treatmentTypesOptions = action.payload.treatmentTypes;
			return;
		}
		case getType(treatmentStatusActions.loadEndTreatmentReasons.success): {
			state.endTreatmentReasons = action.payload.endTreatmentReasons;
			return;
		}

		case getType(treatmentStatusActions.updateTreatmentMutationLoadingState): {
			state.mutatingTreatmentLoadingState = action.payload.loadingState;
			return;
		}

		case getType(treatmentStatusActions.addTreatmentType): {
			// If the new treatment-type is already exists in the redux state,
			// simply ignores it to prevent entry duplication.
			const newTreatmentType = action.payload.treatmentType;
			if (state.treatmentTypesOptions.find((treatmentType) => treatmentType === newTreatmentType)) return;

			state.treatmentTypesOptions.push(newTreatmentType);
			return;
		}

		case getType(treatmentStatusActions.addClientComplaint): {
			// If the new complaint is already exists in the redux state,
			// simply ignores it to prevent entry duplication.
			const newComplaint = action.payload.complaint;
			if (state.clientComplaintsOptions.find((treatmentComplaint) => treatmentComplaint === newComplaint)) return;
			state.clientComplaintsOptions.push(newComplaint);
			return;
		}

		case getType(treatmentStatusActions.loadTreatmentsOfClient.request): {
			state.fetchingTreatmentMap[action.payload.clientId] = LoadingState.LOADING;
			return;
		}

		case getType(treatmentStatusActions.loadTreatmentsOfClient.success): {
			state.fetchingTreatmentMap[action.payload.clientId] = LoadingState.LOADED;
			return;
		}

		case getType(treatmentStatusActions.loadTreatmentsOfClient.failure): {
			state.fetchingTreatmentMap[action.payload.clientId] = LoadingState.ERROR;
			return;
		}

		case getType(treatmentStatusActions.checkEndTreatmentBlocker.success): {
			state.fetchingEndTreatmentBlocker = LoadingState.LOADED;
			state.timeEntriesOwnerMap[action.payload.treatmentId] = action.payload.owners;
			return;
		}

		case getType(treatmentStatusActions.checkEndTreatmentBlocker.failure): {
			state.fetchingEndTreatmentBlocker = LoadingState.ERROR;
			return;
		}
	}
}, defaultState);
