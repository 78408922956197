import {ActionType, createAsyncAction} from "typesafe-actions";
import {PresetItemsType} from "../views/PresetsFormContext";

export const presetsActions = {
	/**
	 * Create Preset through API
	 */
	savePreset: createAsyncAction(
		"presetActions/createPreset",
		"presetActions/createPresetSucceed",
		"presetActions/createPresetFailed",
	)<{formValues: PresetItemsType; userId: number; userHashId: string}, void, Error>(),
};

export type PresetActionsType = ActionType<typeof presetsActions>;
