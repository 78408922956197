import {ActionType, getType} from "typesafe-actions";
import {call, put, takeEvery} from "redux-saga/effects";
import {taskRegistrationActions} from "../redux/taskRegistrationActions";
import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import taskSDK from "../helpers/taskSDK";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {TaskItemResponse, TaskResponseList} from "@sense-os/goalie-js";

const log = createLogger("fetchTaskByTaskRegistrationId", SentryTags.Task);

export function* fetchTaskByTaskRegistrationId(
	action: ActionType<typeof taskRegistrationActions.fetchTaskByTaskRegistrationId.request>,
) {
	const {registrationId} = action.payload;

	const token: string = yield call(getSessionId);

	try {
		const taskListResponse: TaskResponseList<any> = yield apiCallSaga(taskSDK.getTaskList, token, {
			registrationId,
		});
		const relatedTaskResponse: TaskItemResponse<any> = taskListResponse.results?.[0] || null;

		yield put(
			taskRegistrationActions.fetchTaskByTaskRegistrationId.success({
				taskRegistration: relatedTaskResponse,
			}),
		);
	} catch (error) {
		log.captureException(error);
		yield put(taskRegistrationActions.fetchTaskByTaskRegistrationId.failure(error));
	}
}

export default function* () {
	yield takeEvery(
		getType(taskRegistrationActions.fetchTaskByTaskRegistrationId.request),
		fetchTaskByTaskRegistrationId,
	);
}
