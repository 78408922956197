import {createAsyncAction, createAction, ActionType} from "typesafe-actions";

export const twoFAActions = {
	/**
	 * Action to fetch 2FA status of the user (Enabled/disabled and how many active code left)
	 */
	loadStatus: createAsyncAction("twoFA/loadStatus", "twoFA/loadStatusSuccess", "twoFA/loadStatusFail")<
		void,
		{isActive: boolean; tokensRemaining: number; tokensTotal: number},
		{error: Error}
	>(),

	/**
	 * Action to fetch setup key and qr code
	 */
	loadSetupKey: createAsyncAction("twoFA/loadSetupKey", "twoFA/loadSetupKeySuccess", "twoFA/loadSetupKeyFail")<
		void,
		{setupKey: string; qrCodeUrl: string},
		{error: Error}
	>(),

	/**
	 * Send verification code to BE
	 */
	submitVerificationCode: createAsyncAction(
		"twoFA/submitVerificationCode",
		"twoFA/submitVerificationCodeSuccess",
		"twoFA/submitVerificationCodeFail",
	)<{verificationKey: string; changeDevice?: boolean}, {recoveryCodes: string[]}, {error: Error}>(),

	openDisable2FAFormDialog: createAction("twoFA/openDisable2FAFormDialog")(),
	closeDisable2FAFormDialog: createAction("twoFA/closeDisable2FAFormDialog")(),

	submitDisable2FACode: createAsyncAction(
		"twoFA/submitDisable2FACode",
		"twoFA/submitDisable2FACodeSuccess",
		"twoFA/submitDisable2FACodeFail",
	)<{code: string; useRecoveryCode?: boolean}, void, {error: Error}>(),

	openChange2FADeviceDialog: createAction("twoFA/openChange2FADeviceDialog")(),
	closeChange2FADeviceDialog: createAction("twoFA/closeChange2FADeviceDialog")(),

	submitChange2FADeviceCode: createAsyncAction(
		"twoFA/submitChange2FADeviceCode",
		"twoFA/submitChange2FADeviceCodeSuccess",
		"twoFA/submitChange2FADeviceCodeFail",
	)<{code: string; useRecoveryCode?: boolean}, void, {error: Error}>(),

	openUsedRecoveryCodeDialog: createAction("twoFA/openUsedRecoveryCodeDialog")(),
	closeUsedRecoveryCodeDialog: createAction("twoFA/closeUsedRecoveryCodeDialog")(),

	openGenerateRecoveryCodeDialog: createAction("twoFA/openGenerateRecoveryCodeDialog")(),
	closeGenerateRecoveryCodeDialog: createAction("twoFA/closeGenerateRecoveryCodeDialog", (success?: boolean) => ({
		success,
	}))(),

	/**
	 * Open recovery code dialog to show the recovery codes to user
	 */
	openSaveRecoveryCodeDialog: createAction("twoFA/openSaveRecoveryCodeDialog", (recoveryCodes: string[]) => ({
		recoveryCodes,
	}))(),
	closeSaveRecoveryCodeDialog: createAction("twoFA/closeSaveRecoveryCodeDialog", (stay?: boolean) => ({stay}))(),

	/**
	 * Generate new recovery codes for user
	 */
	generateNewRecoveryCodes: createAsyncAction(
		"twoFA/generateNewRecoveryCodes",
		"twoFA/generateNewRecoveryCodesSuccess",
		"twoFA/generateNewRecoveryCodesFailed",
	)<{password: string}, {recoveryCodes: string[]}, {error: Error}>(),

	/**
	 * Below is the actions that is used to track some events for analytics purpose.
	 */
	enableFromNewFeaturePopup: createAction("twoFA/enableFromNewFeaturePopup")(),
	enableFromSettings: createAction("twoFA/enableFromSettings")(),
	enableFromOnboarding: createAction("twoFA/enableFromOnboarding")(),
	disableTwoFactorAuth: createAction("twoFA/disableTwoFactorAuth")(),
	changeTwoFactorAuthDevice: createAction("twoFA/changeTwoFactorAuthDevice")(),
	loginIsBlockedForFailing2FA: createAction("twoFA/loginIsBlockedForFailing2FA")(),
};

export type TwoFAActionType = ActionType<typeof twoFAActions>;
