import {Chat} from "@sense-os/goalie-js";

const chatSDK = new Chat();

chatSDK.connect = chatSDK.connect.bind(chatSDK);
chatSDK.disconnect = chatSDK.disconnect.bind(chatSDK);

chatSDK.getChatHistory = chatSDK.getChatHistory.bind(chatSDK);
chatSDK.getLastInteractionTimes = chatSDK.getLastInteractionTimes.bind(chatSDK);
chatSDK.getLatestMessagesPerUser = chatSDK.getLatestMessagesPerUser.bind(chatSDK);
chatSDK.getReceivedMarker = chatSDK.getReceivedMarker.bind(chatSDK);
chatSDK.getUnreadHistoryAndMarker = chatSDK.getUnreadHistoryAndMarker.bind(chatSDK);
chatSDK.markMessageAsRead = chatSDK.markMessageAsRead.bind(chatSDK);
chatSDK.registerDeviceForPushNotification = chatSDK.registerDeviceForPushNotification.bind(chatSDK);
chatSDK.sendAvailabilityStatus = chatSDK.sendAvailabilityStatus.bind(chatSDK);
chatSDK.sendChatState = chatSDK.sendChatState.bind(chatSDK);
chatSDK.sendEmdrSignal = chatSDK.sendEmdrSignal.bind(chatSDK);
chatSDK.sendProbeForPresenceStatus = chatSDK.sendProbeForPresenceStatus.bind(chatSDK);
chatSDK.sendTextMessage = chatSDK.sendTextMessage.bind(chatSDK);
chatSDK.setLastReadTime = chatSDK.setLastReadTime.bind(chatSDK);
chatSDK.setLastSentTime = chatSDK.setLastSentTime.bind(chatSDK);

chatSDK.subscribeToChatStateChanges = chatSDK.subscribeToChatStateChanges.bind(chatSDK);
chatSDK.unsubscribeFromChatStateChanges = chatSDK.unsubscribeFromChatStateChanges.bind(chatSDK);

chatSDK.subscribeToConnectionStatusChanges = chatSDK.subscribeToConnectionStatusChanges.bind(chatSDK);
chatSDK.unsubscribeFromConnectionStatusChanges = chatSDK.unsubscribeFromConnectionStatusChanges.bind(chatSDK);

chatSDK.subscribeToIncomingEmdrSignal = chatSDK.subscribeToIncomingEmdrSignal.bind(chatSDK);
chatSDK.unsubscribeToIncomingEmdrSignal = chatSDK.unsubscribeToIncomingEmdrSignal.bind(chatSDK);

chatSDK.subscribeToIncomingMessage = chatSDK.subscribeToIncomingMessage.bind(chatSDK);
chatSDK.unsubscribeFromIncomingMessage = chatSDK.unsubscribeFromIncomingMessage.bind(chatSDK);

chatSDK.subscribeToMessageMarker = chatSDK.subscribeToMessageMarker.bind(chatSDK);
chatSDK.unsubscribeFromMessageMarker = chatSDK.unsubscribeFromMessageMarker.bind(chatSDK);

chatSDK.subscribeToPresenceChanges = chatSDK.subscribeToPresenceChanges.bind(chatSDK);
chatSDK.unsubscribeFromPresenceChanges = chatSDK.unsubscribeFromPresenceChanges.bind(chatSDK);

chatSDK.initiateCall = chatSDK.initiateCall.bind(chatSDK);
chatSDK.initiateTwilioConferenceCall = chatSDK.initiateTwilioConferenceCall.bind(chatSDK);
chatSDK.getCallParticipantsInIntervals = chatSDK.getCallParticipantsInIntervals.bind(chatSDK);

export default chatSDK;
