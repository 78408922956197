import {AppState} from "../../ts/redux/AppState";
import {createSelector} from "reselect";

const getOrganizationsState = (state: AppState) => state.organizations;

export const getSelectionDialogState = createSelector(getOrganizationsState, (state) => state.isSelectionDialogOpen);

export const getOrganizationsGroups = createSelector(getOrganizationsState, (state) => state.organizationsGroups);

export const getNonGroupOrganizations = createSelector(getOrganizationsState, (state) => state.nonGroupOrganizations);

export const getAvailableOrganizations = createSelector(getOrganizationsState, (state) => state.availableOrganizations);

export const getLoadingState = createSelector(getOrganizationsState, (state) => state.loadingState);

export const getLoadGroupOrganizationLoadingState = (state) =>
	getOrganizationsState(state).loadGroupOrganizationLoadingState;

export const getLoadNonGroupOrganizationLoadingState = (state) =>
	getOrganizationsState(state).loadNonGroupOrganizationLoadingState;
