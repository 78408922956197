import {PsychoEducation} from "@sense-os/goalie-js";

const psychoEducationSDK = new PsychoEducation();

psychoEducationSDK.createPsychoEducation = psychoEducationSDK.createPsychoEducation.bind(psychoEducationSDK);
psychoEducationSDK.getPsychoEducationList = psychoEducationSDK.getPsychoEducationList.bind(psychoEducationSDK);
psychoEducationSDK.getPsychoEducationDetails = psychoEducationSDK.getPsychoEducationDetails.bind(psychoEducationSDK);
psychoEducationSDK.updatePsychoEducation = psychoEducationSDK.updatePsychoEducation.bind(psychoEducationSDK);
psychoEducationSDK.removePsychoEducation = psychoEducationSDK.removePsychoEducation.bind(psychoEducationSDK);
psychoEducationSDK.addArticles = psychoEducationSDK.addArticles.bind(psychoEducationSDK);
psychoEducationSDK.updateArticle = psychoEducationSDK.updateArticle.bind(psychoEducationSDK);
psychoEducationSDK.removeArticle = psychoEducationSDK.removeArticle.bind(psychoEducationSDK);

export default psychoEducationSDK;

/**
 * Get psychoeducation getPsychoEducationDetails
 */
export async function getPsychoEducationDetails(token: string, id: number) {
	return await psychoEducationSDK.getPsychoEducationDetails(token, id);
}
