import {JobSpecialization} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";

export interface UserProfileState {
	savingUserAvatar: LoadingState;
	/**
	 * List of available job specializations
	 */
	specializations: JobSpecialization[];
	/**
	 * Fetching state of job specializations
	 */
	specializationsLoadingState: LoadingState;
}

export interface DropdownItemProps {
	[key: string]: any;
	value: any;
	text: string;
}

/**
 * Version to be used to fetch job specializations from the backend.
 * This is to support backward compatibility just in case there are new job specializations available
 * in the backend.
 */
export const JOB_SPECIALIZATION_VERSION = 2;
