import {AppState} from "redux/AppState";
import {FIRST_PAGE_NUMBER, UserFeed, DropdownFilter} from "../clientsFeedTypes";
import {getFirstItemNumber, getLastItemNumber, getSourceLink} from "../helpers/clientsFeedHelpers";
import {Contact} from "../../contacts/contactTypes";
import {getContactById} from "../../contacts/redux/contactSelectors";
import {PureUserUtils} from "utils/PureUserUtils";
import {createSelector} from "reselect";

/** Extract `clientsFeed` state from AppState */
const getClientsFeedState = (state: AppState) => state.clientsFeed;

/** Get feedsMap object from `clientsFeed` state */
const getFeedsMap = (state: AppState) => getClientsFeedState(state).feedsMap;

/** get `loadingFeeds` state from `clientsFeed` state */
export const getLoadingFeeds = (state: AppState) => getClientsFeedState(state).loadingFeeds;

/** get `activeLabel` state from `clientsFeed` state */
export const getActiveLabel = (state: AppState) => getClientsFeedState(state).activeLabel;

/** get `currentPage` state from `clientsFeed` state */
export const getCurrentPage = (state: AppState) => getClientsFeedState(state).currentPage;

/** get `maxItemPerPage` state from `clientsFeed` state */
export const getMaxItemPerPage = (state: AppState) => getClientsFeedState(state).maxItemPerPage;

/** get `hourRange` state from `clientsFeed` state */
export const getHourRange = (state: AppState) => getClientsFeedState(state).hourRange;

/** get `selectedFeedFilter` state from `clientsFeed` state */
export const getSelectedFeedFilter = (state: AppState) => getClientsFeedState(state).selectedFeedFilter;

/** get `selectedClientFilter` state from `clientsFeed` state */
export const getSelectedClientFilter = (state: AppState) => getClientsFeedState(state).selectedClientFilter;

/** returns all feeds from `clientsFeed` state as array */
export const getAllFeeds = (state: AppState): UserFeed[] => {
	const feedsMap = getFeedsMap(state);
	return Object.keys(feedsMap).map((k: string) => {
		const feed = feedsMap[k];
		// FIXME: See if backend able to provides profile image
		const ownerContactData: Contact = feed.patient ? getContactById(state, feed.patient.id) : null;
		const creatorContactData: Contact = feed.createdBy ? getContactById(state, feed.createdBy.id) : null;
		return {
			...feed,
			patient: {
				...feed.patient,
				image: (ownerContactData && ownerContactData.image) || feed.patient.image,
			},
			ownerLink: ownerContactData ? getProfileLink(ownerContactData) : null,
			creatorLink: creatorContactData ? getProfileLink(creatorContactData) : null,
			sourceLink: ownerContactData ? getSourceLink(feed, ownerContactData) : null,
		};
	});
};

/**
 * Return client profile link
 *
 * @param {Contact} contactData
 */
function getProfileLink(contactData: Contact): string {
	if (!contactData) {
		return null;
	}
	return PureUserUtils.getProfileLink(contactData.hashId, contactData.role);
}

/** returns total filtered feeds */
export const getTotalFilteredFeeds = (state: AppState) => {
	const allFeeds = getAllFeeds(state);
	return allFeeds.length;
};

/** Returns all feeds from `clientsFeed` state as array, sliced by page settings, and sorted by date */
export const getFeeds = (state: AppState): UserFeed[] => {
	const allFeeds = getAllFeeds(state),
		currentPage = getCurrentPage(state),
		maxItemPerPage = getMaxItemPerPage(state);

	const firstItemNumber: number = getFirstItemNumber(currentPage, maxItemPerPage),
		lastItemNumber: number = getLastItemNumber(currentPage, maxItemPerPage);

	// minus `1` because `firstItemNumber` always start with `1`.
	// `slice` starts with `0`
	return allFeeds.slice(firstItemNumber - FIRST_PAGE_NUMBER, lastItemNumber);
};

export const getFilteredFeeds = createSelector(
	getAllFeeds,
	getSelectedClientFilter,
	getSelectedFeedFilter,
	(allFeeds, client, feedType) => {
		let feeds = allFeeds;

		if (client !== DropdownFilter.ALL) {
			feeds = feeds.filter((feed) => feed.patient.id === client.id);
		}

		if (feedType !== DropdownFilter.ALL) {
			feeds = feeds.filter((feed) => feed.feedType === feedType);
		}

		return feeds;
	},
);
