import produce from "immer";
import {getType} from "typesafe-actions";

import {NewFeaturePopupReduxState, NewFeatures} from "../newFeaturePopupTypes";

import {newFeaturePopupActions, NewFeaturePopupAction} from "./newFeaturePopupActions";

const defaultState: NewFeaturePopupReduxState = {
	// The popup for these features can be shown anywhere.
	canPopupBeShown: {
		[NewFeatures.TREATMENT_STATUS]: true,
		[NewFeatures.SMQ_OMQ_NEW_CHART]: true,
		[NewFeatures.NICEDAY_BLOG_SEARCH]: true,
		[NewFeatures.PRIVATE_NOTES]: true,
		[NewFeatures.PSYCHO_EDUCATION]: true,
		[NewFeatures.TWO_FA]: true,
		[NewFeatures.DEDICATED_NOTE_PAGE]: true,
	},
};

export const newFeaturePopupReducer = produce((state: NewFeaturePopupReduxState, action: NewFeaturePopupAction) => {
	switch (action.type) {
		case getType(newFeaturePopupActions.hidePopup): {
			state.shownPopup = undefined;
			return;
		}

		case getType(newFeaturePopupActions.showPopup): {
			state.shownPopup = action.payload.feature;
			return;
		}

		case getType(newFeaturePopupActions.setPopupCanBeShown): {
			const {feature} = action.payload;
			state.canPopupBeShown[feature] = true;
			return;
		}

		case getType(newFeaturePopupActions.setPopupCannotBeShown): {
			const {feature} = action.payload;
			state.canPopupBeShown[feature] = false;
			return;
		}
	}
}, defaultState);
