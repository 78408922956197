import {eventChannel, EventChannel} from "redux-saga";
import {call, take} from "redux-saga/effects";
import {BrowserEvents} from "constants/BrowserEvents";

let mainWindowOnCloseChannel: EventChannel<unknown>;

/**
 * When portal main window is closed, this function will also close any window instance passed in the param.
 */
export function* onMainWindowClosedSaga(agoraWindow: Window) {
	mainWindowOnCloseChannel = yield call(createMainWindowOnCloseChannel);
	yield take(mainWindowOnCloseChannel);

	yield call(agoraWindow.close);
	yield call(clearMainWindowOnCloseChannel);
}

export function* clearMainWindowOnCloseChannel() {
	mainWindowOnCloseChannel.close();
}

function* createMainWindowOnCloseChannel() {
	return eventChannel((emit) => {
		window.addEventListener(BrowserEvents.BEFORE_UNLOAD, emit);

		return () => {
			window.removeEventListener(BrowserEvents.BEFORE_UNLOAD, emit);
		};
	});
}
