import {
	REFERRAL_QUESTIONNAIRE_STATUS,
	IReferralQuestionnaire,
	IReferralQuestionnaireDetailResponse,
	LanguageTag,
	IReferralTherapyType,
} from "@sense-os/goalie-js";
import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {referralQuestionnaireMenuType} from "../referralClientTypes";

export const referralClientActions = {
	// fetch client list table
	fetchReferralClientList: createAsyncAction(
		"referralClient/fetchReferralClientList",
		"referralClient/fetchReferralClientListSuccess",
		"referralClient/fetchReferralClientListFailed",
	)<
		{page: number; pageSize: number; status: referralQuestionnaireMenuType},
		{total: number; data: IReferralQuestionnaire[]; statusCount: Record<referralQuestionnaireMenuType, number>},
		void
	>(),

	// fetch client detail
	fetchReferralClientDetail: createAsyncAction(
		"referralClient/fetchReferralClientDetail",
		"referralClient/fetchReferralClientDetailSuccess",
		"referralClient/fetchReferralClientDetailFailed",
	)<{id: number}, {data: IReferralQuestionnaireDetailResponse}, void>(),

	// create referral client
	createReferralClient: createAsyncAction(
		"referralClient/createReferralClient",
		"referralClient/createReferralClientSuccess",
		"referralClient/createReferralClientFailed",
	)<
		{
			email: string;
			firstName: string;
			lastName: string;
			referralCode?: string;
			language?: LanguageTag;
			insurer?: string;
			expectedStartTime?: string;
		},
		void,
		void
	>(),

	// Deprecated
	// fetch review template
	fetchReviewTemplate: createAsyncAction(
		"referralClient/fetchReviewTemplate",
		"referralClient/fetchReviewTemplateSuccess",
		"referralClient/fetchReviewTemplateFailed",
	)<{id: number}, void, void>(),

	// create questionnaire review
	createQuestionnaireReview: createAsyncAction(
		"referralClient/createQuestionnaireReview",
		"referralClient/createQuestionnaireReviewSuccess",
		"referralClient/createQuestionnaireReviewFailed",
	)<{id: number}, void, void>(),

	// update questionnaire status
	updateReferralClientStatus: createAsyncAction(
		"referralClient/updateReferralClientStatus",
		"referralClient/updateReferralClientStatusSuccess",
		"referralClient/updateReferralClientStatusFailed",
	)<{id: number; status: REFERRAL_QUESTIONNAIRE_STATUS; notes: string; therapyId?: number}, void, void>(),

	// open status dialog
	openStatusDialog: createAction(
		"referralClient/openStatusDialog",
		(id: number, status: REFERRAL_QUESTIONNAIRE_STATUS, notes: string) => ({
			id,
			status,
			notes,
		}),
	)(),

	// close status dialog
	closeStatusDialog: createAction("referralClient/closeStatusDialog", () => ({}))(),

	// open status dialog
	openNotesDialog: createAction("referralClient/openNotesDialog", (id: number, notes: string) => ({
		id,
		notes,
	}))(),

	// close status dialog
	closeNotesDialog: createAction("referralClient/closeNotesDialog", () => ({}))(),

	// open process referral dialog
	openProcessReferralDialog: createAction(
		"referralClient/openProcessReferralDialog",
		(id: number, notes: string) => ({
			id,
			notes,
		}),
	)(),

	// close process referral dialog
	closeProcessReferralDialog: createAction("referralClient/closeProcessReferralDialog", () => ({}))(),

	// set open form dialog
	setOpenFormDialog: createAction("referralClient/setOpenFormDialog", (value: boolean) => ({value}))(),

	// update questionnaire notes
	processReferralClient: createAsyncAction(
		"referralClient/processReferralClient",
		"referralClient/processReferralClientSuccess",
		"referralClient/processReferralClientFailed",
	)<{id: number; notes: string}, void, void>(),

	// reset questionnaire review state
	resetQuestionnaireReviewState: createAction("referralClient/resetQuestionnaireReviewState")(),

	// update questionnaire notes
	updateReferralNotes: createAsyncAction(
		"referralClient/updateReferralNotes",
		"referralClient/updateReferralNotesSuccess",
		"referralClient/updateReferralNotesFailed",
	)<{id: number; notes: string}, void, void>(),

	// fetch review template
	fetchReferralTherapyType: createAsyncAction(
		"referralClient/fetchReferralTherapyType",
		"referralClient/fetchReferralTherapyTypeSuccess",
		"referralClient/fetchReferralTherapyTypeFailed",
	)<void, {data: IReferralTherapyType[]}, void>(),
};

export type ReferralClientActions = ActionType<typeof referralClientActions>;
