import {getType} from "typesafe-actions";

import {ToastState} from "./toastTypes";
import {ToastActionTypes, toastActions} from "./toastAction";

const initialState: ToastState = {
	toasts: [],
};

export function toastReducer(state: ToastState = initialState, action: ToastActionTypes): ToastState {
	switch (action.type) {
		case getType(toastActions.addToast): {
			// if no key provided in the payload, generate one (needed for filtering out duplicate toasts/snackbars)
			const key = action.payload.key ? action.payload.key : Date.now().toString();
			return {...state, toasts: [...state.toasts, {...action.payload, key}]};
		}
		/**
		 * Receives toast key as payload and remove that toast
		 */
		case getType(toastActions.removeToast): {
			return {...state, toasts: state.toasts.filter((toast) => toast.key !== action.payload)};
		}

		case getType(toastActions.clearToasts): {
			return {...state, toasts: []};
		}

		default:
			return state;
	}
}
