import {AppState} from "../redux/AppState";
import {Contact} from "../../contacts/contactTypes";
import {getAllContacts} from "../../contacts/redux/contactSelectors";
import {getChatRoomState, getLastInteractionTime} from "../../chat/redux/ChatSelector";
import {createSelector} from "reselect";
import {getAuthUser} from "../../auth/redux";
import {countUnreadMessage, sortContactByLatestInteractionTime} from "../../chat/helpers/chatRoomUtils";

/**
 * A set of functions to facilitate sorting of the user-list.
 */
export namespace UserlistUtils {
	/**
	 * Sorts the given list of clients by the last time of interaction (last chat msg or a call)
	 * The client with the most recent interaction time will be the first one in the returned list.
	 * The original list is not modified.
	 * @param clients
	 * @param state
	 */
	export function sortByLastInteraction(clients: Contact[], state: AppState): Contact[] {
		// these users have the latest message available
		const sortable: Contact[] = [],
			// these users don't have the latest message available, probably they haven't sent any messages yet.
			unsortable: Contact[] = [];

		// Separate the users with the latest message from the ones without it
		// The ones with the latest messages can be sorted, the ones without them cannot.
		clients.forEach((client) => {
			if (getLastInteractionTime(state, client.id)) {
				sortable.push(client);
			} else {
				unsortable.push(client);
			}
		});

		sortable.sort(
			(clientOne: Contact, clientTwo: Contact) =>
				getLastInteractionTime(state, clientTwo.id) - getLastInteractionTime(state, clientOne.id),
		);

		return sortable.concat(unsortable);
	}

	/**
	 * Filters the user list according to the provided search string
	 * @param searchStr
	 * @param contacts
	 */
	export function filterBySearchString(searchStr: string, contacts: Contact[]): Contact[] {
		// no text search applied to the user list, so simply return it as it is
		if (!searchStr) {
			return contacts;
		}

		// pass a function to map
		return contacts.filter((contact) => {
			const {firstName, lastName, email} = contact;
			const haystack: string = [firstName, lastName, email].filter(Boolean).join(" ").toLowerCase();

			if (haystack.indexOf(searchStr.toLowerCase()) > -1) {
				return contact;
			}
			return null;
		});
	}

	/**
	 * Returns contact with unread message
	 * Sort by latest interaction time
	 */
	const contactsWithUnreadMessages = createSelector(
		[getAuthUser, getAllContacts, getChatRoomState],
		(authUser, contacts, chatRooms) => {
			return contacts
				.filter((contact) => countUnreadMessage(contact.id, authUser?.id, chatRooms) > 0)
				.sort(sortContactByLatestInteractionTime(chatRooms));
		},
	);

	/**
	 * Returns contact without unread message
	 * Sort by latest interaction time
	 */
	const contactsWithoutUnreadMessages = createSelector(
		[getAuthUser, getAllContacts, getChatRoomState],
		(authUser, contacts, chatRooms) => {
			return contacts
				.filter((contact) => countUnreadMessage(contact.id, authUser?.id, chatRooms) === 0)
				.sort(sortContactByLatestInteractionTime(chatRooms));
		},
	);

	/**
	 * Sorts userlist according to the rule: there are two groups in the list: the top group contains
	 * users with unread messages, the bottom group contains users without unread messages.
	 * Within each group the users are sorted by the time of the last interaction with the portal user
	 *
	 * @param state
	 */
	export const userlistSorting = createSelector([contactsWithUnreadMessages, contactsWithoutUnreadMessages], (a, b) =>
		a.concat(b),
	);
}
