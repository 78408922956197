import {ConnectionStatus} from "@sense-os/goalie-js";
import {ActionType, createAction} from "typesafe-actions";

export namespace ChatAction {
	// By manipulating this number-as-string here,
	// we can retrigger initial syncing of chat histories to BE.
	export const CHAT_HISTORY_SYNC_VERSION = "1000";

	/**
	 * If `isError` is true, the user will not be able to chat
	 */
	export const reportChatError = createAction("ChatActions/reportChatError", (isError: boolean) => ({isError}))();

	/**
	 * Set chat connection status. The status will determine whether to
	 * enable the chat box or not.
	 */
	export const setConnectionStatus = createAction(
		"ChatActions/setConnectionStatus",
		(connectionStatus: ConnectionStatus) => ({connectionStatus}),
	)();

	export const bulkLoadChatHistoriesFromBE = createAction(
		"ChatActions/bulkLoadChatHistoriesFromBE",
		(userIds: number[]) => ({userIds}),
	)();
	export const loadChat = createAction("ChatActions/loadChat", (userId: number) => ({userId}))();
	export const queueToLoadChat = createAction("ChatActions/queueToLoadChat", (userIds: number[]) => ({userIds}))();

	export const lastInteractionTimesLoaded = createAction("ChatActions/lastInteractionTimesLoaded")();

	const combinedActions = {
		reportChatError,
		setConnectionStatus,
		loadChat,
		queueToLoadChat,
		lastInteractionTimesLoaded,
		bulkLoadChatHistoriesFromBE,
	};

	export type ChatActionType = ActionType<typeof combinedActions>;
}
