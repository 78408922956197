import {put} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";

/**
 * A put where we put a limit such that
 * any same action can't be dispatched more than once in a given time span.
 */
export function createTimeLimitedPut<T>(time: number, identityFn: (action: ActionType<T>) => string) {
	const cache: {[key: string]: number} = {};
	return function* (action: ActionType<T>) {
		const id = identityFn(action);
		const now = new Date().getTime();

		if (!!cache[id] && now - cache[id] < time) {
			return;
		}

		cache[id] = now;
		yield put(action);
	};
}
