import {AvailabilityStatus} from "@sense-os/goalie-js";
import {ActionType, createAction} from "typesafe-actions";

export const onlineUsersActions = {
	/**
	 * availability status action
	 */
	availabilityStatusUpdate: createAction(
		"onlineUsersActions/availabilityStatusUpdate",
		(payload: {userId?: number; statusDescriptor?: AvailabilityStatus}) => payload,
	)(),

	/**
	 * reset users presence type
	 */
	resetUsersPresenceType: createAction("onlineUsersActions/resetUsersPresenceType")(),
};

export type OnlineUsersActionsType = ActionType<typeof onlineUsersActions>;
