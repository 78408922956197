import {call, takeEvery, put, all} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSessionId} from "../../../auth/helpers/authStorage";

import {psychoEducationActions} from "../redux/psychoEducationActions";
import psychoEducationSDK from "../helpers/psychoEducationSDK";

const log = createLogger("articlesSaga", SentryTags.PsychoEducation);

function* createArticles(action: ActionType<typeof psychoEducationActions.saveArticles.request>) {
	const {psychoEducationId, articlesBody} = action.payload;
	const token: string = yield call(getSessionId);
	try {
		yield apiCallSaga(psychoEducationSDK.addArticles, token, psychoEducationId, articlesBody);
		yield put(psychoEducationActions.saveArticles.success());
	} catch (e) {
		log.captureException(e);
		yield put(psychoEducationActions.saveArticles.failure(e));
	}
}

function* updateArticles(action: ActionType<typeof psychoEducationActions.updateArticles.request>) {
	const {psychoEducationId, articles} = action.payload;
	const token: string = yield call(getSessionId);
	try {
		yield all(
			articles.map((article) => {
				if (!article?.id) {
					// Add new article if `id` is "null"
					return put(
						psychoEducationActions.saveArticles.request({
							psychoEducationId,
							articlesBody: [{url: article.url}],
						}),
					);
				}

				// Update article
				return apiCallSaga(psychoEducationSDK.updateArticle, token, psychoEducationId, article.id, {
					url: article.url,
				});
			}),
		);
		yield put(psychoEducationActions.updateArticles.success());
	} catch (e) {
		log.captureException(e);
		yield put(psychoEducationActions.updateArticles.failure(e));
	}
}

function* removeArticles(action: ActionType<typeof psychoEducationActions.removeArticles.request>) {
	const {psychoEducationId, articleIds} = action.payload;
	const token: string = yield call(getSessionId);
	try {
		yield all(articleIds.map((id) => apiCallSaga(psychoEducationSDK.removeArticle, token, psychoEducationId, id)));
		yield put(psychoEducationActions.removeArticles.success());
	} catch (e) {
		log.captureException(e);
		yield put(psychoEducationActions.removeArticles.failure(e));
	}
}

export default function* () {
	yield takeEvery(getType(psychoEducationActions.saveArticles.request), createArticles);
	yield takeEvery(getType(psychoEducationActions.updateArticles.request), updateArticles);
	yield takeEvery(getType(psychoEducationActions.removeArticles.request), removeArticles);
}
