import {getType} from "typesafe-actions";
import produce from "immer";
import {AssessmentState} from "../types";
import {AssessmentActionType, assessmentActions} from "./assessmentActions";
import {LoadingState} from "constants/redux";

const initialState: AssessmentState = {
	isFormOpened: false,
	assessmentType: null,
	savedFormValues: [],
	userId: null,
	savingState: LoadingState.EMPTY,
	omqData: null,
	omqFetchState: LoadingState.EMPTY,
	smqData: null,
	smqFetchState: LoadingState.EMPTY,
};

export const assessmentReducer = produce((state: AssessmentState = initialState, action: AssessmentActionType) => {
	switch (action.type) {
		case getType(assessmentActions.openForm):
			state.isFormOpened = true;
			state.assessmentType = action.payload.assessmentType;
			state.userId = action.payload.userId;
			return;
		case getType(assessmentActions.closeForm):
			state.isFormOpened = false;
			state.assessmentType = null;
			state.userId = null;
			return;
		case getType(assessmentActions.saveFormValues): {
			const formValues = action.payload.formValues;
			state.savedFormValues = {
				...state.savedFormValues,
				[action.payload.userId]: {
					...state.savedFormValues[action.payload.userId],
					[action.payload.assessmentType]: {
						title: formValues.title,
						dueDate: formValues.dueDate,
						dueDateToggle: formValues.dueDateToggle,
						notificationToggle: formValues.notificationToggle,
						notificationTimeUnit: formValues.notificationTimeUnit,
						notificationDuration: formValues.notificationDuration,
					},
				},
			};
			return;
		}
		case getType(assessmentActions.saveOmqSmq.request): {
			state.savingState = LoadingState.LOADING;
			return;
		}
		case getType(assessmentActions.saveOmqSmq.success): {
			state.savingState = LoadingState.LOADED;
			state.savedFormValues = {
				...state.savedFormValues,
				[action.payload.userId]: {
					...state.savedFormValues[action.payload.userId],
					[action.payload.assessmentType]: null,
				},
			};
			return;
		}
		case getType(assessmentActions.saveOmqSmq.failure): {
			state.savingState = LoadingState.ERROR;
			return;
		}
		case getType(assessmentActions.fetchOmqSession.request):
			state.omqFetchState = LoadingState.LOADING;
			return;
		case getType(assessmentActions.fetchOmqSession.success):
			state.omqFetchState = LoadingState.LOADED;
			state.omqData = action.payload.data;
			return;
		case getType(assessmentActions.fetchOmqSession.failure):
			state.omqFetchState = LoadingState.ERROR;
			return;
		case getType(assessmentActions.fetchSmqSession.request):
			state.smqFetchState = LoadingState.LOADING;
			return;
		case getType(assessmentActions.fetchSmqSession.success):
			state.smqFetchState = LoadingState.LOADED;
			state.smqData = action.payload.data;
			return;
		case getType(assessmentActions.fetchSmqSession.failure):
			state.smqFetchState = LoadingState.ERROR;
			return;
	}
}, initialState);
