import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";

import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";

import {groupTherapyActions} from "../redux/groupTherapyActions";
import groupTherapySDK from "../helpers/groupTherapySdk";
import {getSensorResolved} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";
import {GroupTherapyRegistration} from "@sense-os/goalie-js";

const log = createLogger("fetchGroupRegistrationDetailsSaga", SentryTags.GroupTherapy);

function* fetchGroupRegistrationDetails(
	action: ActionType<typeof groupTherapyActions.fetchGroupRegistrationDetails.request>,
) {
	const {groupId, registrationId} = action.payload;
	const token: string = yield call(getSessionId);

	try {
		// Get registration details
		const registrationDetails: GroupTherapyRegistration = yield apiCallSaga(
			groupTherapySDK.getRegistrationDetail,
			token,
			groupId,
			registrationId,
		);

		// Get the related sensor by the sensor ids
		const relatedSensors: SensorDatum<BasePlannedEventEntry>[] = yield apiCallSaga(
			getSensorResolved,
			token,
			undefined,
			undefined,
			undefined,
			undefined,
			registrationDetails.sensorIds,
		);
		const relatedSensorDataMap: Record<number, SensorDatum<BasePlannedEventEntry>> = relatedSensors.reduce(
			(result, currentValue) => {
				const userId: number = currentValue.userId;
				result[userId] = currentValue;

				return result;
			},
			{},
		);

		yield put(
			groupTherapyActions.fetchGroupRegistrationDetails.success({
				registrationDetails,
				relatedSensorDataMap,
			}),
		);
	} catch (err) {
		log.captureException(err, {message: "Unable to fetch group registration details."});
		yield put(groupTherapyActions.fetchGroupRegistrationDetails.failure(err));
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.fetchGroupRegistrationDetails.request, fetchGroupRegistrationDetails);
}
