import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import {MeetingNote, SensorDatum} from "redux/tracking/TrackingTypes";
import createLogger from "../../../logger/createLogger";
import {interventionNoteActions} from "../redux/interventionNoteActions";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getMeetingNoteSensors} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {SentryTags} from "../../../errorHandler/createSentryReport";

const log = createLogger("fetchInterventionNoteSaga", SentryTags.Intervention);

export function* fetchInterventionNotes(action: ActionType<typeof interventionNoteActions.fetchNotes.request>) {
	const {userId} = action.payload;
	const token = yield call(getSessionId);
	try {
		const meetingNotes: SensorDatum<MeetingNote>[] = yield apiCallSaga(
			getMeetingNoteSensors,
			token,
			userId,
			undefined,
			undefined,
		);
		yield put(interventionNoteActions.fetchNotes.success({data: meetingNotes, userId}));
	} catch (err) {
		log.captureException(err);
		yield put(interventionNoteActions.fetchNotes.failure({err, userId}));
	}
}

export default function* () {
	yield takeEvery(getType(interventionNoteActions.fetchNotes.request), fetchInterventionNotes);
}
