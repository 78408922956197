import {DiaryEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const interventionDiaryActions = {
	fetchDiaries: createAsyncAction(
		"interventionDiary/fetchDiaries",
		"interventionDiary/fetchDiariesSuccess",
		"interventionDiary/fetchDiariesFail",
	)<{userId: number}, {data: SensorDatum<DiaryEntry>[]}, {err: Error}>(),
};

export type interventionDiaryActionType = ActionType<typeof interventionDiaryActions>;
