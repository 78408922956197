import {useMemo} from "react";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {NDColors} from "../../../../material-ui/NDColors";
import {
	GraphV2Type,
	IGraphConfig,
	IGraphV2Data,
	IUseGraphData,
	TrackerRegistrationGroupByDate,
} from "../../customTrackerGraphV2Types";
import {CustomTrackerSensor} from "../../customTrackerTypes";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";
import {getActiveStringDates, getEndDate, transformRegistrationDateFromString} from "./graphV2ConfigHelper";
import {groupTrackerDataByDate} from "./graphV2Helper";
import {useGetXTickValuePlainData, useGetXTickValues, useGetYTickValues} from "./graphV2TickHooks";

// minimum value of Y scale
const HIGHEST_Y_SCALE: number = 10;
// always return 6 lines start from 0
const Y_LINES: number = 6;

const useGraphData = (
	trackerRegistration,
	selectedTimeView,
	startDate,
	endDate,
	trackerName,
	graphType,
	measureString,
) => {
	return useMemo(() => {
		const activeDates: string[] = getActiveStringDates(trackerRegistration, selectedTimeView, startDate, endDate);
		const data = [
			{
				id: strTranslation.CUSTOM_TRACKER.chart.quantity,
				color: NDColors.ORANGE,
				data: activeDates.map((date) => {
					const xDate = transformRegistrationDateFromString(date);
					const totalQuantity = trackerRegistration[date].reduce((total, tracker) => {
						const quantity = tracker.value.measures[measureString]?.sensorData?.value?.quantity ?? 0;
						return total + quantity;
					}, 0);
					return {
						x: xDate,
						y: totalQuantity,
					};
				}),
			},
		];

		// filter graph that contains zero value
		// https://github.com/plouc/nivo/issues/1854
		return data.filter((res) => res.data.length > 0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackerRegistration, trackerName, graphType, selectedTimeView, startDate]);
};

export const quantityConfig = (measureString: string): IGraphConfig => {
	return {
		graphType: GraphV2Type.QUANTITY,
		graphName: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.quantity),
		yLegend: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.quantity),
		xLegend: localization.formatMessage(strTranslation.TIME.date),
		useGraphData: (
			trackerRegistration: TrackerRegistrationGroupByDate,
			trackerName: string,
			graphType: GraphV2Type,
			startDate: Date,
			selectedTimeView: GRAPH_TIME_VIEW_VALUE,
		): IUseGraphData => {
			const endDate = getEndDate(selectedTimeView, startDate);
			// data
			const graphData: IGraphV2Data[] = useGraphData(
				trackerRegistration,
				selectedTimeView,
				startDate,
				endDate,
				trackerName,
				graphType,
				measureString,
			);
			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);
			const xTickValues: Date[] = useGetXTickValues(graphData, selectedTimeView, startDate, endDate);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},

		useAllTimePlainGraphData: (trackerRegistration: SensorDatum<CustomTrackerSensor>[], trackerName: string) => {
			const trackerRegistrationGroupByDate: TrackerRegistrationGroupByDate =
				groupTrackerDataByDate(trackerRegistration);

			const graphData: IGraphV2Data[] = useGraphData(
				trackerRegistrationGroupByDate,
				undefined,
				undefined,
				undefined,
				trackerName,
				GraphV2Type.QUANTITY,
				measureString,
			);

			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);

			const xTickValues: Date[] = useGetXTickValuePlainData(graphData);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},
	};
};
