import {eventChannel} from "redux-saga";
import {CallSignal} from "@sense-os/goalie-js";
import chatSDK from "../../../chat/sdk";
import {take, cancelled, select, put, call} from "redux-saga/effects";
import {
	canProcessSignal,
	isCompatibleSDK,
	isSignalInitiatedToUser,
	shouldIgnoreSignal,
	isInitiationSignal,
	isAcceptedSignal,
	isRejectedSignal,
	isCanceledSignal,
	isTerminationSignal,
	isProcessedElsewhereSignal,
} from "../../helpers/callSignalUtils";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {getActiveCall} from "redux/videoCall/VideoCallSelectors";
import {toastActions} from "../../../toaster/redux";
import {getAuthUser} from "../../../auth/redux";
import {sendBusyTerminationSignal, sentrySafeCallSignal} from "../../helpers/callHelpers";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {callActions} from "../../redux/callActions";
import strTranslation from "../../../assets/lang/strings";

const log = createLogger("callSignalSaga", SentryTags.VideoCall);

const createCallSignalChannel = () => {
	return eventChannel<CallSignal>((emitter) => {
		const subscriptionId = chatSDK.subscribeToCallSignalChanges(emitter);
		return () => {
			chatSDK.unsubscribeFromChatStateChanges(subscriptionId);
		};
	});
};

export function* callSignalSubscription() {
	const channel = createCallSignalChannel();
	try {
		while (true) {
			const callSignal: CallSignal = yield take(channel);
			log.addBreadcrumb({
				message: "Incoming call signal: " + callSignal.type,
				data: sentrySafeCallSignal(callSignal),
			});
			yield call(callSignalHandler, callSignal);
		}
	} finally {
		if (yield cancelled()) {
			channel.close();
		}
	}
}

export function* callSignalHandler(signal: CallSignal) {
	const activeCall: ActiveCall = yield select(getActiveCall);
	const authUser = yield select(getAuthUser);

	const signalCanBeProcessed = canProcessSignal(signal, activeCall);
	const sdkIsNotSupported = isInitiationSignal(signal) && !isCompatibleSDK(signal.availableCallSDKs);
	const isInitiationToAuthUser = isSignalInitiatedToUser(signal, authUser.id);
	const signalShouldBeIgnored = shouldIgnoreSignal(signal, authUser.id);

	log.addBreadcrumb({
		data: {
			signalCanBeProcessed,
			sdkIsNotSupported,
			isInitiationToAuthUser,
			signalShouldBeIgnored,
			signal: sentrySafeCallSignal(signal),
		},
	});

	if (!signalCanBeProcessed) {
		if (sdkIsNotSupported) {
			log.addBreadcrumb({message: "Incompatible SDK"});
			yield put(
				toastActions.addToast({
					message: strTranslation.VIDEO.toast.unable_to_connect.update_app_version,
					type: "warning",
				}),
			);
		}

		if (isInitiationToAuthUser) {
			log.addBreadcrumb({message: "User is busy"});
			yield call(sendBusyTerminationSignal, signal.roomId);
		}
		log.addBreadcrumb({message: "Signal cannot be processed."});
		return;
	}

	if (signalShouldBeIgnored) {
		log.addBreadcrumb({message: "Signal is ignored."});
		return;
	}

	if (isInitiationSignal(signal)) {
		yield put(callActions.handleInitiationSignal(signal));
	} else if (isAcceptedSignal(signal)) {
		yield put(callActions.handleAcceptSignal(signal));
	} else if (isRejectedSignal(signal)) {
		yield put(callActions.handleRejectSignal(signal));
	} else if (isCanceledSignal(signal)) {
		yield put(callActions.handleCancelSignal(signal));
	} else if (isTerminationSignal(signal)) {
		yield put(callActions.handleTerminateSignal(signal));
	} else if (isProcessedElsewhereSignal(signal)) {
		yield put(callActions.handleAcceptRejectElsewhereSignal(signal));
	} else {
		log.warn("Unknown signal", sentrySafeCallSignal(signal));
	}
}
