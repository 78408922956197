import {TSMap} from "typescript-map";
import {getType} from "typesafe-actions";

import {cloneMap} from "../../utils/clone";
import * as Actions from "./TrackingAction";

import {
	Emotions,
	EventFilter,
	Interval,
	IntervalId,
	SensorData,
	Sensors,
	TrackingEntry,
	TrackingState,
} from "./TrackingTypes";
import {LoadingState} from "../../constants/redux";
import {trackingId} from "./TrackingHelper";
import {ChatAction} from "../../../chat/redux/ChatAction";

//
// STATE
//

export const defaultState: TrackingState = {
	currentInterval: null,
	eventFilters: [],
	activeEmotions: [Emotions.HAPPY, Emotions.ENERGIZED, Emotions.FEARFUL],
	userTrackingDataMap: {},
};

//
// REDUCERS
//

export function trackingReducer(
	state: TrackingState = defaultState,
	action: Actions.TrackingAction | ChatAction.ChatActionType,
): TrackingState {
	if (state === null) {
		return null;
	}

	switch (action.type) {
		case getType(Actions.setCurrentInterval):
			return setCurrentInterval(state, action.payload.interval);

		/** Turns the visibility of the specified feeling on or off */
		case getType(Actions.toggleEmotion):
			return toggleEmotion(state, action.payload.feeling, action.payload.on);

		//
		// LOADING SENSOR-DATA
		//

		/** Sets the sensor-loading status for the interval to 'loading' */
		case getType(Actions.loadingSensor): {
			const {intervalId, sensor, userId} = action.payload;
			const newState = clearSensorDataState(state, userId);
			return setSensorLoadingState(newState, userId, intervalId, sensor, LoadingState.LOADING);
		}

		/**
		 * Adds sensor-data to map with all sensor-data.
		 * Also updates the loading-status of the sensor in the loaded interval
		 */
		case getType(Actions.loadedSensor): {
			const {intervalId, sensor, userId, data} = action.payload;
			state = setSensorLoadingState(state, userId, intervalId, sensor, LoadingState.LOADED);
			const newState = addSensorData(state, userId, sensor, data);
			return updateDataLastFetched(newState, userId);
		}

		/** Sets the sensor-loading status for the interval to 'error' */
		case getType(Actions.errorLoadingSensor): {
			const {intervalId, sensor, userId} = action.payload;
			return setSensorLoadingState(state, userId, intervalId, sensor, LoadingState.ERROR);
		}

		case getType(Actions.loadedDataLastSync): {
			const {results, userId} = action.payload;
			const newData = results;

			let lastDataSync: TSMap<Sensors, Date> = new TSMap();

			if (state.userTrackingDataMap[userId]) {
				lastDataSync = cloneMap(state.userTrackingDataMap[userId].lastDataSync);
			}

			newData.forEach((nd) => lastDataSync.set(nd.sensorName as Sensors, nd.lastUploaded));

			return setUserTrackingDataMap(state, userId, lastDataSync, "lastDataSync");
		}

		case getType(Actions.toggleEventFilter): {
			return toggleEventFilter(state, action.payload.filter, action.payload.on);
		}

		default:
			return state;
	}
}

/**
 * Set `userTrackingDataMap` state by `userId`
 *
 * @param {TrackingState} state
 * @param {number} userId
 * @param {any} newData
 * @param {string} key
 */
function setUserTrackingDataMap(state: TrackingState, userId: number, newData: any, key: string): TrackingState {
	return {
		...state,
		userTrackingDataMap: {
			...state.userTrackingDataMap,
			[userId]: {
				...state.userTrackingDataMap[userId],
				[key]: newData,
			},
		},
	};
}

//
// MODIFIERS
//

/** Sets the given interval as current interval */
function setCurrentInterval(state: TrackingState, interval: Interval): TrackingState {
	return state.currentInterval && state.currentInterval.id === interval.id
		? state
		: {...state, currentInterval: interval};
}

/** Sets the loading-state of a sensor for a specific interval */
export function setSensorLoadingState(
	state: TrackingState,
	userId: number,
	intervalId: IntervalId,
	sensor: Sensors,
	loading: LoadingState,
): TrackingState {
	const loadingState = cloneMap(state.userTrackingDataMap[userId].loadingState);
	const intervalState = cloneMap(loadingState.get(intervalId));
	intervalState.set(sensor, loading);
	loadingState.set(intervalId, intervalState);

	return setUserTrackingDataMap(state, userId, loadingState, "loadingState");
}

/** Adds new sensor-data to the state */
export function addSensorData(
	state: TrackingState,
	userId: number,
	sensor: Sensors,
	newData: SensorData<TrackingEntry>,
): TrackingState {
	if (newData.length === 0) {
		return state;
	}
	const data = cloneMap(state.userTrackingDataMap[userId].data);
	const sensorData = cloneMap(data.get(sensor));

	// create new state object with added sensor-data
	newData.forEach((d) => sensorData.set(trackingId(d), d));
	return setUserTrackingDataMap(state, userId, data.set(sensor, sensorData), "data");
}

/** Turns the visibility of the specified feeling on or off */
export function toggleEmotion(state: TrackingState, emotion: Emotions, on: boolean): TrackingState {
	const active = state.activeEmotions.slice();

	if (on) {
		active.push(emotion);
	} else {
		let idx = active.indexOf(emotion);
		idx > -1 && active.splice(idx, 1);
	}

	return {
		...state,
		activeEmotions: active,
	};
}

/** Turns the visibility of the specified event filter on or off */
export function toggleEventFilter(state: TrackingState, eventFilter: EventFilter, on: boolean): TrackingState {
	const active = state.eventFilters.slice();

	if (on) {
		active.push(eventFilter);
	} else {
		let idx = active.indexOf(eventFilter);
		idx > -1 && active.splice(idx, 1);
	}

	return {
		...state,
		eventFilters: active,
	};
}

/** Update data last fetched */
function updateDataLastFetched(state: TrackingState, userId: number): TrackingState {
	return setUserTrackingDataMap(state, userId, new Date(), "lastFetchedSensorDate");
}

/**
 * Clear sensorData state
 *
 * @deprecated
 * We're not using dailyplanner to show events anymore.
 *
 * @param {TrackingState} state
 */
function clearSensorDataState(state: TrackingState, userId: number): TrackingState {
	return {
		...setUserTrackingDataMap(state, userId, new TSMap(), "data"),
	};
}
