import {States as VideoCallStates} from "../ts/services/chat/video/States";
import {IStorage} from "../ts/services/system/storage/IStorage";
import Storage from "../ts/services/system/storage/Storage";
import {StorageBools} from "../ts/services/system/storage/StorageBools";
import {StorageKeys} from "../ts/services/system/storage/StorageKeys";
import {ActiveCall} from "../ts/services/chat/video/ActiveCall";
import {getFullName} from "../userProfile/helpers/profileHelpers";

const storage: IStorage = Storage;

/**
 * Check whether the Notification function exists in the browser.
 */
export function isSupported(): boolean {
	return "Notification" in window;
}

/**
 * Ask permission to enable notifications in the browser
 */
export function askNotificationsPermission(): Promise<NotificationPermission> {
	return new Promise((resolve, reject) => {
		if (!isSupported()) {
			return reject("This browser does not support desktop notifications");
		}

		// Fixme: This may not work in Safari: https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
		Notification.requestPermission()
			.then((permission) => resolve(permission))
			.catch(reject);
	});
}

/**
 * Try to get the name of the client who sent us the message
 *
 * @param clientID
 */
export function getNameByUserIds(userIds: number[], activeCall: ActiveCall): string | null {
	if (!activeCall) {
		return null;
	}

	return (
		activeCall &&
		activeCall.participantMap &&
		userIds
			.map((id) => activeCall.participantMap[id])
			.filter((participant) => participant && !!participant.joinedTime && !participant.leaveTime)
			.map((participant) => getFullName(participant))
			.join(", ")
	);
}

/**
 * Returns user page link.
 * If the returned value is `null`, the user will not be redirected anywhere.
 *
 * @param {number[]} userIds
 */

/** Check from internal storage whether a portal instance is in foreground */
export function isInForeground(): boolean {
	return storage.read(StorageKeys.IS_PORTAL_IN_FOCUS) === StorageBools.TRUE;
}

/** Check in the internal storage whether the user is in an active call */
export function isInCall(): boolean {
	const videoCallState = storage.read(StorageKeys.VIDEO_CALL_STATE);
	return videoCallState === VideoCallStates.WAITING_FOR_SDK || videoCallState === VideoCallStates.IN_CALL;
}

/** Browser event handlers to know if the window is in foreground or background */
export function onWindowInBackground(): void {
	storage.write(StorageKeys.IS_PORTAL_IN_FOCUS, StorageBools.FALSE);
}

export function onWindowInForeground(): void {
	storage.write(StorageKeys.IS_PORTAL_IN_FOCUS, StorageBools.TRUE);
}
