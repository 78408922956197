import moment from "moment";
import {NumberMap} from "services/utils/Maps";
import {ReminderTimeUnit} from "../../helpers/time";
import {TIME_UNITS} from "constants/time";
import {LoadingState} from "constants/redux";
import {PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";

/**
 * Available assessment types
 */
export enum AssessmentType {
	OMQ = "omq",
	SMQ = "smq",
}

/**
 * Assessment redux state
 */
export interface AssessmentState {
	// Assessment form open state
	isFormOpened: boolean;
	// Selected assessment type
	assessmentType: AssessmentType;
	// Selected user id
	userId: number;
	// Assessment saved form values map
	savedFormValues: NumberMap<OmqSmqFormValues | {}>;
	// Assessment saving state
	savingState: LoadingState;
	// omq Data
	omqData?: SensorDatum<PlannedEventEntry>;
	// omq fetch status
	omqFetchState?: LoadingState;
	// smq Data
	smqData?: SensorDatum<PlannedEventEntry>;
	// smq fetch status
	smqFetchState?: LoadingState;
}

/**
 * OMQ and SMQ react-hook-form form values
 */
export interface OmqSmqFormValues {
	// OMQ and SMQ title
	title: string;
	// OMQ and SMQ due date
	dueDate: Date;
	// OMQ and SMQ due date toggle
	dueDateToggle: boolean;
	// Notification toggle button
	notificationToggle: boolean;
	// Notification time unit options
	notificationTimeUnit: ReminderTimeUnit;
	// Notification time duration
	notificationDuration: number;
}

/**
 * OMQ and SMQ initial form values
 */
export const omqSmqInitialValues: OmqSmqFormValues = {
	title: "",
	dueDate: moment().add(1, TIME_UNITS.HOURS).add(1, TIME_UNITS.DAY).startOf(TIME_UNITS.HOURS).toDate(),
	dueDateToggle: true,
	notificationToggle: true,
	notificationTimeUnit: ReminderTimeUnit.MINUTES,
	notificationDuration: 10,
};

/**
 * OMQ or SMQ maximum title field length
 */
export const TITLE_MAX_LENGTH = 60;
