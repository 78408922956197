import {SvgIconComponent} from "@material-ui/icons";

/**
 * Default height defined in figma of a single warning bar.
 */
export const WARNING_BAR_HEIGHT = 38;

export interface WarningBarType {
	message: string;
	icon?: SvgIconComponent;
	key: string | number;
	isClosable?: boolean;
	onClose?: () => void;
	action?: JSX.Element;
}

export interface WarningBarState {
	warningBars: WarningBarType[];
}
