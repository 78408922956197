import {ResolvedSensorData, SensorData, SensorDataResponse} from "@sense-os/goalie-js";
import {DailyPlannerItem, SensorDatum, Sensors} from "../../ts/redux/tracking/TrackingTypes";
import {getDailyPlannerSDK, getTrackingSDK} from "./clientActivitySDK";
import psychoEducationSDK from "../../tracker/psychoEducation/helpers/psychoEducationSDK";
import dailyPlannerSDK from "./dailyPlannerSDK";

/**
 * Fetches dailyplanner data from backend and convert ISO date string into date object.
 * Dailyplanner data is a combination of planned event sensor, recurring planned event, therapy session event,
 * smq event, and omq event.
 * We can utilize this endpoint to get these data, but we'll need to extract them manually...
 *
 * @param token
 * @param userId
 * @param start
 * @param end
 */
export async function getDailyPlannerItems(token: string, userId: number, start: Date, end: Date) {
	const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const dailyPlannerResponse = await dailyPlannerSDK.getDailyPlannerItems(token, {
		startTime: start.toISOString(),
		endTime: end.toISOString(),
		userId,
		timezone,
	});

	// SDK interface `DailyPlannerItem` is misconfigured (`startTime` and `endTime` is in `string`. Should be in `Date`).
	return dailyPlannerResponse as unknown as DailyPlannerItem[];
}

export async function getPsychoEducationItems(token: string, start: Date, end: Date, hashId: string) {
	const psychoEducationResponse = await psychoEducationSDK.getPsychoEducationList(
		token,
		start.toISOString(),
		end.toISOString(),
		hashId,
		1,
		1000,
	);
	return psychoEducationResponse?.results;
}

export function getMeetingNoteSensors(token: string, userId: number, start: Date, end: Date, sensorId?: string[]) {
	return getSensorResolved(token, userId, [Sensors.MEETING_NOTE], start, end, sensorId);
}

export function getDiaryEntrySensors(token: string, userId: number, start: Date, end: Date, sensorId?: string[]) {
	return getSensorResolved(token, userId, [Sensors.DIARY], start, end, sensorId);
}

export function getCheckInFeelingSensors(token: string, userId: number, start: Date, end: Date, sensorId?: string[]) {
	return getSensorResolved(token, userId, [Sensors.CHECK_IN_FEELING], start, end, sensorId);
}

export function getTherapySessionSensors(token: string, userId: number, start: Date, end: Date, sensorId?: string[]) {
	return getSensorResolved(token, userId, [Sensors.PLANNED_EVENT], start, end, sensorId);
}

export function getThoughtRecordSensors(token: string, userId: number, start: Date, end: Date, sensorId?: string[]) {
	return getSensorResolved(token, userId, [Sensors.GSCHEME], start, end, sensorId);
}

export async function getSensorResolved(
	token: string,
	userId: number,
	sensorNames: string[],
	start: Date,
	end: Date,
	sensorId?: string[],
) {
	const sensors = await getDailyPlannerSDK().sensorResolvedGet(
		token,
		userId,
		sensorNames,
		start ? start.toISOString() : undefined,
		end ? end.toISOString() : undefined,
		undefined,
		true,
		undefined,
		undefined,
		false,
		sensorId,
	);

	return sensors.map(convertResolvedSensorDataResponse);
}

function convertResolvedSensorDataResponse({sensorData}: ResolvedSensorData): SensorDatum<any> {
	// SDK interface `ResolvedSensorData` is misconfigured (`startTime` and `endTime` is in `string`. Should be in `Date`).
	return sensorData as unknown as SensorDatum<any>;
}

/**
 *  Create or update sensor data
 */
export async function saveSensorData<T>(
	sensorData: SensorData<T>,
	userId: number,
	sensorId?: string,
): Promise<SensorDataResponse<T>> {
	if (sensorId) {
		// Update sensor data if `sensorId` is persist.
		return getTrackingSDK().putSensorData(sensorId, sensorData);
	}
	return getTrackingSDK()
		.postSensorData([sensorData], userId)
		.then((res) => res[0]);
}
