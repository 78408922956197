import {BehaviorExperimentPutRequestBody, TaskPutBodyRequest} from "@sense-os/goalie-js";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import behaviorExprSDK from "../helpers/behaviorExprSDK";
import {BehaviorExprFormValues} from "../redux/behaviorExprTypes";
import moment from "moment";
import taskSDK from "../../../taskRegistration/helpers/taskSDK";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import createLogger from "../../../logger/createLogger";

const log = createLogger("updateBehaviorExperimentTaskSaga", SentryTags.BehaviorExperiment);

export function* updateBehaviorExperimentTask(params: {
	behaviorExprId: number;
	taskId: number;
	formValues: BehaviorExprFormValues;
	reminderDateTime: Date;
	token: string;
}) {
	const {behaviorExprId, taskId, formValues, reminderDateTime, token} = params;

	try {
		/** 1. Update behavior experiment */
		const body = {
			expectation: {
				negativeThought: formValues.negativeExpectation,
				negativeBelievability: formValues.negativeExpectationBelievability,
				negativeConsequence: formValues.negativeExpectationConsequence,
				rationalThought: formValues.rationalExpectation,
				rationalBelievability: formValues.rationalExpectationBelievability,
				rationalConsequence: formValues.rationalExpectationConsequence,
			},
		};

		yield apiCallSaga(
			behaviorExprSDK.updateBehaviorExperiment,
			token,
			behaviorExprId.toString(),
			body as BehaviorExperimentPutRequestBody,
		);

		/** 2. Update Behavior Experiment Task */
		const doesHaveTask: boolean = !!taskId;

		/** only update task if the psycho education has a task */
		if (doesHaveTask) {
			const updateTaskPayload: TaskPutBodyRequest<any> = {
				title: formValues.title,
				expiresAt: moment(formValues.expiresAt).toDate(),
				remindAt: reminderDateTime,
				description: formValues.description,
			};

			yield apiCallSaga(taskSDK.updateTask, token, taskId, updateTaskPayload);
		}
	} catch (error) {
		log.captureException(error);
	}
}
