import moment from "moment";
import {AttendeeStatus, CalendarAccountResponse, CalendarEventAttendee, SensorDataResponse} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";

import {TIME} from "constants/time";
import {Utils} from "utils/Utils";
import {SensorConfigs, Sensors} from "redux/tracking/TrackingTypes";
import {DISC} from "IoC/DISC";

import {
	MIN_ALL_DAY_EVENT_DURATION,
	DateString,
	CalendarAccount,
	CalendarProfile,
	Calendar,
	CalendarEvent,
	CalendarColor,
	TimeView,
	CalendarProvider,
} from "../calendarTypes";

/**
 * Format of `DateString` type.
 * @See src/timeTracking/timeTrackingTypes.ts
 */
export const DATE_FORMAT = "YYYY-MM-DD";

/**
 * Returns today's date in `DateString` format
 */
export function getTodaysDate(): DateString {
	return moment(Date.now()).format(DATE_FORMAT);
}

/** Returns true if the given date and today's date are the same */
export function isItToday(date: DateString): boolean {
	return date === getTodaysDate();
}

/**
 * Returns a given date in `DateString` format
 */
export function dateToString(date: Date): DateString {
	return moment(date).format(DATE_FORMAT);
}

/**
 * Converts a `DateString` value to a `Moment` object
 * @param date
 */
export function dateStringToMoment(date: DateString): moment.Moment {
	return moment(date, DATE_FORMAT);
}

/**
 * Shifts the date by given `direction`
 */
export function shiftDate(date: DateString, direction: number): DateString {
	const momentDate = dateStringToMoment(date);
	momentDate.add(direction, "d");

	return momentDate.format(DATE_FORMAT);
}

/**
 *  Shifts the date by given `direction` for `weekly` calendar view
 */
export function weeklyShiftDate(date: DateString, isWeekendShown: boolean, direction: number): DateString {
	const momentDate = dateStringToMoment(date);
	let daysForSubtract = momentDate.day();

	if (!isWeekendShown) {
		daysForSubtract -= 1;
	}

	momentDate.subtract(daysForSubtract, "d");
	momentDate.add(direction, "d");

	return momentDate.format(DATE_FORMAT);
}

/**
 * Get vertical space for every calendar event
 */
export function getEventsSpace(events: CalendarEvent[], scale: number): {top: number; bottom: number}[] {
	return events.map((event) => {
		const start = new Date(event.start.time);
		const end = new Date(event.end.time);

		const hourOfStartTime = start.getHours() || 0;
		const minuteOfStartTime = start.getMinutes() || 0;
		const top = hourOfStartTime * scale + (minuteOfStartTime * scale) / TIME.MINUTES_IN_HOUR;

		const hourOfEndTime = end.getHours() || 0;
		const minuteOfEndTime = end.getMinutes() || 0;
		const bottom = hourOfEndTime * scale + (minuteOfEndTime * scale) / TIME.MINUTES_IN_HOUR;

		return {top, bottom};
	});
}

/**
 * This is the function that will calculate on how to divide the spaces in calendar
 * to every time entry, especially in the case that there are entries with
 * overlapping time.
 *
 */
export function allocateSpace(
	spaces: {top: number; bottom: number}[],
): {top: number; bottom: number; column: number; maxColumn: number}[] {
	const sizes = Array.from(new Array(spaces.length)).map(() => 1);

	// This represents the "union-set" array that will group together
	// entries that need to be taken inconsideration together
	// when deciding how to divide the calendar space.
	//
	// TODO: Refine the algorithm more later, to check for the possibility
	// that some columns can be merged together.
	const parents = Array.from(new Array(spaces.length)).map((_, x) => x);
	function getParent(idx: number) {
		let curr = idx;
		while (curr !== parents[curr]) {
			curr = parents[curr];
		}

		return curr;
	}

	// A simple check of whether the entry is overlapping,
	// with assumption that the secondIdx is always bigger than the firstIdx.
	function checkIsOverlapping(firstIdx, secondIdx) {
		return spaces[firstIdx].bottom - spaces[firstIdx].top > 2
			? spaces[firstIdx].bottom > spaces[secondIdx].top
			: spaces[firstIdx].bottom > spaces[secondIdx].top - 1;
	}

	// This `result` here indicates to which column
	// shall the entry should be rendered to.
	const result = spaces.reduce((acc, space, idx) => {
		let ownIdx = 0;

		const reversedAcc = [...acc];
		reversedAcc.reverse();

		for (let i = 0; i < idx; i++) {
			const isOverlapping = checkIsOverlapping(i, idx);
			if (!isOverlapping) {
				continue;
			}

			const x = getParent(idx);
			const y = getParent(i);
			if (x !== y) {
				parents[x] = y;
			}
		}

		while (true) {
			const comparedIdx = acc.length - 1 - reversedAcc.findIndex((x) => x === ownIdx);
			const isOverlapping = checkIsOverlapping(comparedIdx, idx);

			if (comparedIdx === acc.length || !isOverlapping) {
				return acc.concat([ownIdx]);
			}

			ownIdx++;
		}
	}, [] as number[]);

	// This is to let the entry know how many columns are there in their group.
	for (let i = 0; i < spaces.length; i++) {
		const x = getParent(i);
		sizes[x] = Math.max(sizes[x], result[i] + 1);
	}

	return result.map((x, idx) => {
		return {...spaces[idx], column: x, maxColumn: sizes[getParent(idx)]};
	});
}

/**
 * Converting calendar account from backend response into `CalendarAccount`
 */
export function convertAccountResponseToCalendarAccount(response: CalendarAccountResponse): CalendarAccount {
	const profiles: CalendarProfile[] = response.cronofyData.profiles.map((profile) => {
		const calendars: Calendar[] = profile.profileCalendars.map((calendar) => {
			return {
				profileId: profile.profileId,
				providerService: profile.providerService,
				calendarId: calendar.calendarId,
				calendarName: calendar.calendarName,
				permissionLevel: calendar.permissionLevel,
				isPrimary: calendar.calendarPrimary,
				isDeleted: calendar.calendarDeleted,
				isReadOnly: calendar.calendarReadonly,
			};
		});

		// Filtering out non-primary calendars, except calendar from `icloud`,
		// it's because no primary calendar from `icloud` calendar.
		const primaryCalendars: Calendar[] = calendars.filter(
			(calendar) => calendar.isPrimary || calendar.providerService === CalendarProvider.ICLOUD,
		);

		return {
			profileId: profile.profileId,
			providerName: profile.providerName,
			profileName: profile.profileName,
			isProfileConnected: profile.profileConnected,
			calendars: primaryCalendars,
		};
	});

	return {
		email: response.email,
		name: response.name,
		calendarProfiles: profiles,
	};
}

/**
 * Get the calendar ids from fetched calendar profiles
 */
export function getCalendarIdsFromProfiles(profiles: CalendarProfile[]): string[] {
	let calendars: Calendar[] = [];

	profiles.forEach((profile) => {
		if (profile.calendars) {
			profile.calendars.forEach((calendar) => calendars.push(calendar));
		}
	});

	return calendars.map((calendar) => calendar.calendarId);
}

/**
 * Get the start date and end date from given dateRange
 */
export function getDateRangeBorders(dateRange: DateString[]): {startDate: string; endDate: string} {
	if (!dateRange) {
		return;
	}

	const startDate = dateRange[0];
	let endDate = dateRange[dateRange.length - 1];

	// We need to add +1 day to the endDate due Cronofy requirement param
	// see: https://docs.cronofy.com/developers/api/events/read-events/#param-to
	endDate = dateToString(dateStringToMoment(endDate).add(1, "day").toDate());

	return {startDate, endDate};
}

/**
 * Mapping calendar events into displayed `dateRange`
 */
export function mapCalendarEventsIntoDate(
	dateRange: DateString[],
	events: CalendarEvent[],
): Record<DateString, CalendarEvent[]> {
	let mappedEvents: Record<DateString, CalendarEvent[]> = {};

	events.forEach((event) => {
		const eventStartTime = event.start?.time;
		const startDate = new Date(eventStartTime);

		for (let idx = 0; idx < dateRange.length; idx++) {
			const date = dateRange[idx];
			const isAllDayEventOfDay = checkIsAllDayEvent(eventStartTime) && isDateBetweenEventRange(date, event);

			if (dateToString(startDate) === date || isAllDayEventOfDay) {
				// Map regular and all-day calendar events into given date
				mappedEvents[date] = mappedEvents[date] || [];
				mappedEvents[date].push(event);
				break;
			}
		}
	});

	return mappedEvents;
}

/**
 * Mapping colors into calendar ids
 */
export function mapColorsIntoCalendarIds(
	calendarIds: string[],
	existingCalendarColor: Record<string, CalendarColor> = {},
): Record<string, CalendarColor> {
	let mappedCalendarColor: Record<string, CalendarColor> = {};

	calendarIds.forEach((calendarId, idx) => {
		if (existingCalendarColor[calendarId]) {
			// Set calendar color with existing calendar color
			mappedCalendarColor[calendarId] = existingCalendarColor[calendarId];
		} else {
			// Set calendar color according to available colors in `CalendarColor`
			mappedCalendarColor[calendarId] = Object.values(CalendarColor)[idx];
		}
	});

	return mappedCalendarColor;
}

/**
 * Mapping visibility into calendar ids
 */
export function mapVisibilityIntoCalendarIds(
	calendarIds: string[],
	existingCalendarVisibility: Record<string, boolean> = {},
): Record<string, boolean> {
	let mappedCalendarVisibility: Record<string, boolean> = {};

	calendarIds.forEach((calendarId) => {
		if (existingCalendarVisibility[calendarId] !== undefined) {
			// Set calendar visibility with existing data
			mappedCalendarVisibility[calendarId] = existingCalendarVisibility[calendarId];
		} else {
			// Set calendar visibility according to `true` as default
			mappedCalendarVisibility[calendarId] = true;
		}
	});

	return mappedCalendarVisibility;
}

/**
 * Get calendars from fetched calendar profiles
 */
export function getCalendarsFromProfiles(profiles: CalendarProfile[] = []): Calendar[] {
	let calendars: Calendar[] = [];

	profiles.forEach((profile) => {
		if (profile.calendars) {
			calendars = calendars.concat(profile.calendars);
		}
	});

	return calendars;
}

/**
 * Get the calendar names from fetched calendar profiles
 */
export function getCalendarNamesFromProfiles(profiles: CalendarProfile[]): string[] {
	const calendars = getCalendarsFromProfiles(profiles);
	return calendars.map((calendar) => calendar.calendarName);
}

/**
 * Get the calendar from fetched calendar profiles by given id
 */
export function getCalendarById(profiles: CalendarProfile[], calendarId: string): Calendar {
	const calendars = getCalendarsFromProfiles(profiles);
	return calendars.find((calendar) => calendar.calendarId === calendarId);
}

/**
 * Get the calendar id by calendar name from fetched calendar profile
 */
export function getCalendarIdFromProfiles(profiles: CalendarProfile[], calendarName: string): string {
	let calendars: Calendar[] = [];

	if (!profiles || !calendarName) {
		return;
	}

	profiles.forEach((profile) => {
		if (profile.calendars) {
			profile.calendars.forEach((calendar) => calendars.push(calendar));
		}
	});

	return calendars.find((calendar) => calendar.calendarName === calendarName)?.calendarId;
}

/**
 * Convert given guest list into calendar event attendees
 */
export function convertGuestListIntoEventAttendees(guestList: {email: string}[]): CalendarEventAttendee[] {
	const attendees: CalendarEventAttendee[] = [];

	if (!guestList) {
		return [];
	}

	guestList.forEach((guest) => {
		if (!Utils.stringHasNoValue(guest.email)) {
			// Currently we set `displayName` with `email` because it's an optional field `String | Null`,
			// and we don't have actual display name value to set
			attendees.push({email: guest.email, displayName: guest.email});
		}
	});

	return attendees;
}

/**
 * Get attendees status, is all attendees declined to the given calendar event
 */
export function checkIsAllAttendeesDeclined(event: CalendarEvent): boolean {
	const attendees = event?.attendees;

	if (attendees.length === 0) {
		return false;
	}

	return attendees.every((attendee) => attendee.status === AttendeeStatus.DECLINED);
}

/**
 * Get invitees status, is all invitees declined to the given calendar event
 */
export function checkIsAllInviteesDeclined(event: CalendarEvent, eventCalendarName: string): boolean {
	const attendees = event?.attendees;

	if (attendees.length === 0) {
		return false;
	}

	// Get only invitee attendees
	const invitees = attendees.filter((attendee) => attendee.email !== eventCalendarName);

	return invitees.every((attendee) => attendee.status === AttendeeStatus.DECLINED);
}

/**
 * Get session sensor data by given calendar event id
 */
export async function getSensorDataById(eventId: string): Promise<SensorDataResponse<BasePlannedEventEntry>> {
	const [sensorData] = await DISC.getTrackingService().sdk.querySensorDataByIds<BasePlannedEventEntry>(
		[eventId],
		SensorConfigs[Sensors.PLANNED_EVENT].sourceName,
	);

	return sensorData;
}

/**
 * Check is given date string have time
 * Use this function for checking whether calendar event is all day or not,
 * since cronofy response didn't have all day flag.
 */
export function checkIsAllDayEvent(dateString: string): boolean {
	return !dateString.includes("T");
}

/**
 * Check is given date is between event start - end date,
 * Use this function for all day event.
 */
export function isDateBetweenEventRange(currentDate: DateString, event: CalendarEvent): boolean {
	// Current given date
	const currDate = new Date(currentDate);
	// Event start and end date
	const startEvent = new Date(event.start.time);
	const endEvent = new Date(event.end.time);

	if (currDate > startEvent && currDate < endEvent) {
		// Check is current date is within event start and end date
		return true;
	}

	return false;
}

/**
 * Get start and end date format for regular and all day event
 */
export function convertStartAndEndDateFormat(
	startTime: Date,
	endTime: Date,
	isAllDay: boolean,
): {start: Date | string; end: Date | string} {
	let start: Date | string = startTime;
	let end: Date | string = endTime;

	if (isAllDay) {
		start = moment(start).format(DATE_FORMAT);
		// For all day event we need to add +1 day to the end time, due cronofy requirements.
		end = moment(end).add(1, "day").format(DATE_FORMAT);
	}

	return {start, end};
}

/**
 * Get all day event width (in percentage) based on given `timeView` and `isWeekendShown`
 */
export function getAllDayEventWidth(timeView: TimeView, isWeekendShown: boolean): number {
	let length: number = 0;

	switch (timeView) {
		case TimeView.DAY:
			length = 100; // 100%
			break;
		case TimeView.WEEK:
			length = 20; // 20%
			break;
	}

	if (isWeekendShown && timeView == TimeView.WEEK) {
		length = 14.25; // 14.25%
	}

	return length;
}

/**
 * Get calendar all day event duration
 */
function getAllDayEventDuration(shownDates: string[], startTime: string, endTime: string): number {
	if (shownDates.includes(startTime)) {
		// If `startTime` included in `shownDates, set `durationInDays` from event `startTime` until `endTime`
		return moment.duration(moment(endTime).diff(moment(startTime))).asDays();
	}

	// Otherwise set `durationInDays` from the beginning of `shownDates` until `endTime`
	// This case happened when showing all day event between weeks
	return moment.duration(moment(endTime).diff(moment(shownDates[0]))).asDays();
}

/**
 * Get all day events stack order and total stack rows
 */
export function getAllDayEventsStackOrderAndRows(
	shownDates: string[],
	selectedTimeView: TimeView,
	multipleAllDayEvents: CalendarEvent[],
): {eventsStackOrder: {rowIndex: number; start: number; length: number}[]; totalStackRows: number} {
	if (multipleAllDayEvents.length === 0) return {eventsStackOrder: [], totalStackRows: 0};

	// Get all day events with spacing info (duration, startIndex, endIndex)
	const eventsWithSpacingInfo = multipleAllDayEvents.map((event) => {
		const startTime = event.start.time;
		const endTime = event.end.time;

		const durationInDays =
			selectedTimeView === TimeView.DAY
				? MIN_ALL_DAY_EVENT_DURATION
				: getAllDayEventDuration(shownDates, startTime, endTime);
		const index = shownDates.findIndex((date) => date === startTime);
		const startIndex = index > 0 ? index : 0;
		const endIndex = startIndex + durationInDays;

		return {event, durationInDays, startIndex, endIndex};
	});

	// Recursively get all day events stack index
	function getEventsStackIndex(stackLimit: number, stacksIndex: number[], recurDepth: number): number[] {
		if (recurDepth >= multipleAllDayEvents.length) return stacksIndex;

		for (let depth = 0; depth < stackLimit; depth++) {
			const isPossible = stacksIndex.every(
				(prevDepth, prevIdx) =>
					prevDepth !== depth ||
					eventsWithSpacingInfo[prevIdx].endIndex <= eventsWithSpacingInfo[recurDepth].startIndex ||
					eventsWithSpacingInfo[recurDepth].endIndex <= eventsWithSpacingInfo[prevIdx].startIndex,
			);

			if (!isPossible) continue;

			const foundSolutions = getEventsStackIndex(stackLimit, [...stacksIndex, depth], recurDepth + 1);
			if (foundSolutions.length > 0) return foundSolutions;
		}

		return [];
	}

	let maxStackRows = 0;
	while (true) {
		maxStackRows += 1;
		const eventsStackIndex = getEventsStackIndex(maxStackRows, [], 0);

		if (eventsStackIndex.length > 0) {
			return {
				eventsStackOrder: eventsStackIndex.map((rowIndex, idx) => ({
					rowIndex,
					start: eventsWithSpacingInfo[idx].startIndex,
					length: eventsWithSpacingInfo[idx].durationInDays,
				})),
				totalStackRows: maxStackRows,
			};
		}
	}
}
