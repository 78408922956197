import {TIME} from "constants/time";
import {ActivityTypes} from "redux/tracking/TrackingTypes";
import strTranslation from "../../../assets/lang/strings";
import localization from "../../../localization/Localization";
import {SessionDataSource, SessionExpireStatus, SessionSensorDatum} from "../InterventionSessionType";
import {PlannedEventStatus} from "../../../ts/redux/tracking/TrackingTypes";

/** Transform Sensor Datum to Data Source */
export const transformSessionSensorDatumToDataSource = (sessions: SessionSensorDatum[]): SessionDataSource[] => {
	return sessions.map((sessionItem) => {
		return {
			...sessionItem,
			key: sessionItem.id,
		};
	});
};

/**
 * Transform Sensor Data Source to Datum
 * due to the difference between the session data source (the data type that is used for mapping the table)
 * and datum is only on the key attribute. just delete the key attribute to transform from data source to datum
 *  */
export const transformSessionDataSourceToDatumItem = (session: SessionDataSource): SessionSensorDatum => {
	delete session.key;

	return session;
};

/**
 * Filtering only planned_event_entry sensor with THERAPY_SESSION activity type
 */
export const filteringSessions = (sessions: SessionDataSource[]): SessionDataSource[] => {
	return sessions.filter((sessionItem) => {
		return sessionItem.value.activityType === ActivityTypes.THERAPY_SESSION;
	});
};

/**
 * Sorting sessions by `plannedFor` attribute to descending
 * @param sessions
 */
export const sortSessionsByLatestPlannedTime = (sessions: SessionDataSource[]): SessionDataSource[] => {
	return sessions.sort((a, b) => {
		return b.value.plannedFor.getTime() - a.value.plannedFor.getTime();
	});
};

/**
 * formatting minutes to hours
 * ex: 90 minutes => 1 hours 30 minutes
 */
export const minutesToHoursFormatter = (totalMinutes: number) => {
	const hours = Math.floor(totalMinutes / TIME.MINUTES_IN_HOUR);
	const minutes = totalMinutes % TIME.MINUTES_IN_HOUR;

	const minutesText = minutes
		? `${minutes} ${localization.formatMessage(
				strTranslation.GRAPHS.new_event.psycho_education.timing.unit.minutes,
		  )}`
		: "";
	const hoursText = hours
		? `${hours} ${localization.formatMessage(strTranslation.GRAPHS.new_event.psycho_education.timing.unit.hours)}`
		: "";

	const formattedText = `${hoursText} ${minutesText}`;

	return {
		formattedText,
		hours,
		minutes,
	};
};

type FilterSessionByExpiredStatusStatusParams = {
	expiredStatus: SessionExpireStatus;
	sessions: SessionDataSource[];
};

/**
 * Filter Session Data source by the `plannedFor` attribute
 * @param params
 */
export const filterSessionByExpiredStatus = (params: FilterSessionByExpiredStatusStatusParams): SessionDataSource[] => {
	const {expiredStatus, sessions} = params;

	const now = new Date();

	/**
	 * if the selected expired status is concluded,
	 * show the sessions with `plannedFor` attribute less than equal than now
	 */
	if (expiredStatus === SessionExpireStatus.CONCLUDED) {
		return sessions.filter((sessionItem) => {
			return sessionItem.value.plannedFor <= now;
		});
	}

	/**
	 * if the selected expired status is upcoming,
	 * show the sessions with `plannedFor` attribute greater than now
	 */
	return sessions.filter((sessionItem) => {
		return sessionItem.value.plannedFor > now;
	});
};

type FilterSessionsByStatusParams = {
	status: PlannedEventStatus;
	sessions: SessionDataSource[];
};

/**
 * Filtering sessions by status
 * session status: "COMPLETED", "INCOMPLETED", "CANCELED"
 * https://gitlab.almende.org/niceday/development/sensor-schema/blob/develop/goalie-2/planned_event.json
 */
export const filterSessionsByStatus = (params: FilterSessionsByStatusParams): SessionDataSource[] => {
	const {sessions, status} = params;

	return sessions.filter((sessionItem) => {
		return sessionItem.value.status === status;
	});
};
