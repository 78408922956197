import {ActionType, createAction} from "typesafe-actions";
import {NewFeatures} from "../newFeaturePopupTypes";

export const newFeaturePopupActions = {
	// The action to show or hide a New-Feature-Popup
	hidePopup: createAction("newFeaturePopup/hideCurrentPopup", (feature: NewFeatures) => ({feature}))(),
	showPopup: createAction("newFeaturePopup/showPopup", (feature: NewFeatures) => ({feature}))(),

	// Store to settings that some New-Feature-Popup has been shown
	savePopupSettings: createAction("newFeaturePopup/savePopupSettings", (feature: NewFeatures) => ({feature}))(),

	// Some popup can only be shown in some screen.
	// The next two actions helps notify this module's saga when deciding
	// which popup to be shown next.
	setPopupCanBeShown: createAction("newFeaturePopup/setPopupCanBesShown", (feature: NewFeatures) => ({feature}))(),
	setPopupCannotBeShown: createAction("newFeaturePopup/setPopupCannotBeShown", (feature: NewFeatures) => ({
		feature,
	}))(),
};

export type NewFeaturePopupAction = ActionType<typeof newFeaturePopupActions>;
