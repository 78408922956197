import {Message} from "@sense-os/goalie-js";
import {Contact} from "../../contacts/contactTypes";
import {emptyRoom, UserMapChatRoomState} from "../redux/ChatRoomReducer";
import {isUnreadMessage} from "./messageUtils";

/**
 * Count unread messages from the given message array
 */
export function calculateUnreadMessages(authUserId: number, messages: Message[], readTimestamp: number): number {
	if (!messages || messages.length === 0) {
		return 0;
	}

	let unreadMessageCount: number = 0;
	let i: number;
	let latestMessageIndex: number = messages.length - 1;
	for (i = latestMessageIndex; i >= 0; i--) {
		const message = messages[i];
		if (!message) {
			continue;
		}

		// If readTimestamp is more recent than the latest message, then no need to continue because
		// there's definitely no unread message from the messages
		if (message.timestamp < readTimestamp) {
			break;
		}

		// Check if message is considered as unread message or not
		if (isUnreadMessage(authUserId, readTimestamp, message)) {
			unreadMessageCount++;
		}
	}

	return unreadMessageCount;
}

/**
 * Count user unread messages from chat room data
 */
export const countUnreadMessage = (userId: number, authUserId: number, chatRooms: UserMapChatRoomState) => {
	const {messages, sentReadMarkerTimestampMs} = chatRooms[userId] || emptyRoom;
	if (!messages || messages.length === 0 || !authUserId) {
		return 0;
	}

	return calculateUnreadMessages(authUserId, messages, sentReadMarkerTimestampMs);
};

/**
 * Get latest interaction time given user id
 */
export const getLatestInteractionTime = (userId: number, chatRooms: UserMapChatRoomState) => {
	// If messages exist for this user, then use the latest message timestamp
	if (chatRooms[userId] && chatRooms[userId].messages && chatRooms[userId].messages.length > 0) {
		const messageIndex = chatRooms[userId].messages.length - 1;
		return chatRooms[userId].messages[messageIndex].timestamp;
	}

	// Need at least one of this information to determine the last interaction times.
	if (!chatRooms[userId]?.lastReceivedTimestampMs && !chatRooms[userId]?.deliveredTimestampMs) {
		return 0;
	}

	// Based on the conditional above, one of these informations is available.
	return Math.max(chatRooms[userId].lastReceivedTimestampMs || 0, chatRooms[userId].deliveredTimestampMs || 0);
};

/**
 * Sort by latest time descending (Most recent first)
 */
export const sortContactByLatestInteractionTime =
	(chatRooms: UserMapChatRoomState) => (contactA: Contact, contactB: Contact) => {
		return getLatestInteractionTime(contactB.id, chatRooms) - getLatestInteractionTime(contactA.id, chatRooms);
	};
