import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import {referralClientActions} from "../redux/referralClientAction";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import ReferralClientSDK from "../helper/referralClient";
import {IReferralQuestionnaireDetailResponse, LanguageTag} from "@sense-os/goalie-js";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import localization from "../../localization/Localization";

const log = createLogger("fetchReferralClientDetail", SentryTags.ReferralClient);

export function* fetchReferredClientDetail(
	action: ActionType<typeof referralClientActions.fetchReferralClientDetail.request>,
) {
	const {id} = action.payload;
	const token = yield call(getSessionId);
	const activeLanguage: LanguageTag = localization.getLocale();

	try {
		const response: IReferralQuestionnaireDetailResponse = yield apiCallSaga(
			ReferralClientSDK.getReferralQuestionnairesDetail,
			token,
			id,
			activeLanguage,
		);
		yield put(referralClientActions.fetchReferralClientDetail.success({data: response}));
	} catch (err) {
		log.captureException(err);
		yield put(referralClientActions.fetchReferralClientDetail.failure());
	}
}

export default function* () {
	yield takeEvery(getType(referralClientActions.fetchReferralClientDetail.request), fetchReferredClientDetail);
}
