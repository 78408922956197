import {ConnectedContact, InvitedContact} from "@sense-os/goalie-js/dist";
import contactSdk from "../../contacts/contactSdk";
import {AuthUser} from "../../auth/authTypes";
import {ConnectedTherapist, AvailableTherapist} from "../clientNetworkTypes";
import loc from "../../localization/Localization";
import {Contact} from "../../contacts/contactTypes";
import strTranslation from "../../assets/lang/strings";

/**
 * Returns client's connections:
 * - Connected therapists
 * - Invited therapists
 * and combine them into one array
 *
 * @param {string} token
 * @param {number} userId
 * @returns {ConnectedTherapist}
 */
export async function getClientsConnection(token: string, userId: number): Promise<ConnectedTherapist[]> {
	const [activeContacts, invitedContacts] = await Promise.all([
		contactSdk.getConnectedContacts(token, userId),
		contactSdk.getInvitedContacts(token, userId),
	]);

	return [
		...activeContacts.map(mapConnectedContactToConnectedTherapist),
		...invitedContacts.map(mapInvitedContactToConnectedTherapist),
	];
}

/**
 * Converts `ConnectedContact` into `ConnectedTherapist` object
 *
 * @param activeContact
 * @returns
 */
function mapConnectedContactToConnectedTherapist(activeContact: ConnectedContact): ConnectedTherapist {
	return {
		firstName: activeContact.firstName,
		lastName: activeContact.lastName,
		hashId: activeContact.hashId,
		id: activeContact.id,
		image: activeContact.image,
		organizationName: activeContact.organization?.name,
		connectedSince: activeContact.connectedSince,
	};
}

/**
 * Converts `InvitedContact` into `ConnectedTherapist` object
 *
 * @param activeContact
 * @returns
 */
function mapInvitedContactToConnectedTherapist(requestContact: InvitedContact): ConnectedTherapist {
	return {
		firstName: requestContact.firstName,
		lastName: requestContact.lastName,
		hashId: requestContact.hashId,
		id: requestContact.id,
		image: requestContact.image,
		organizationName: requestContact.organization?.name,
		connectedSince: null, // null because therapist is not connected yet.
		invitationId: requestContact.invitationId,
	};
}

/**
 * Filter available therapists from connected therapists (connected & pending therapist)
 * @param {AvailableTherapist} availableTherapists
 * @param {ConnectedTherapist} connectedTherapists
 * @returns {AvailableTherapist}
 */
export function getFilteredAvailableTherapist(
	availableTherapists: AvailableTherapist[],
	connectedTherapists: ConnectedTherapist[],
): AvailableTherapist[] {
	const filteredTherapists = availableTherapists.filter(
		(therapist) => !connectedTherapists?.find((connection) => connection.id === therapist.id),
	);

	return filteredTherapists;
}

/**
 * Return therapist name.
 * @param {AvailableTherapist} therapist
 * @return {string}
 */
export function getTherapistName(therapist: AvailableTherapist | ConnectedTherapist): string {
	const {firstName, lastName} = therapist;
	const therapistName = [firstName, lastName].filter(Boolean).join(" ");

	if (therapistName.length === 0) {
		return loc.formatMessage(strTranslation.COMMON.other_care_provider);
	}

	return therapistName;
}

export function mapContactIntoAvailableTherapist(contact: Contact): AvailableTherapist {
	return {
		firstName: contact.firstName,
		lastName: contact.lastName,
		hashId: contact.hashId,
		id: contact.id,
		image: contact.image,
		organizationName: contact.organization.name,
	};
}

export function mapAuthUserIntoAvailableTherapist(authUser: AuthUser): AvailableTherapist {
	return {
		firstName: authUser.data.firstName,
		lastName: authUser.data.lastName,
		hashId: authUser.hashId,
		id: authUser.id,
		image: authUser.data.image,
		organizationName: authUser.organization.name,
	};
}
