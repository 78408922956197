import {Task} from "redux-saga";
import {CalendarEventResponse} from "@sense-os/goalie-js";

import {LoadingState} from "constants/redux";
import {PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";

/**
 *  A string with a date in format `YYYY-MM-DD`.
 *  Examples: `2021-01-01`, `2022-12-25`
 */
export type DateString = string;

/**
 * Calendar redux state
 */
export interface CalendarState {
	// Currently selected date
	selectedDate: DateString;
	// Currently selected time
	selectedTime: Date;
	// Currently selected time view
	selectedTimeView: TimeView;
	// Calendar fetch loading state
	loadingState: LoadingState;
	// Create calendar event loading state
	createLoadingState: LoadingState;
	// Update calendar event loading state
	updateLoadingState: LoadingState;
	// Remove calendar event loading state
	removeLoadingState: LoadingState;
	// Session event loading state
	sessionLoadingState: LoadingState;
	// Is showing weekend days in calendar view
	isWeekendShown: boolean;
	// Connected calendar account
	calendarAccount?: CalendarAccount;
	// Synced calendar events
	calendarEvents?: Record<DateString, CalendarEvent[]>;
	// Synced calendar color
	calendarColors?: Record<string, CalendarColor>;
	// Synced calendar visibility
	calendarsVisibility?: Record<string, boolean>;
	// Fetched session data
	sessionData?: SensorDatum<PlannedEventEntry>;
	// Calendar view scroll
	isScrollDisabled: boolean;
}

/**
 * Cronofy calendar account
 */
export interface CalendarAccount {
	email: string;
	name: string;
	calendarProfiles: CalendarProfile[];
}

/**
 * Cronofy calendar profile
 */
export interface CalendarProfile {
	profileId: string;
	providerName: string;
	profileName: string;
	isProfileConnected: boolean;
	calendars?: Calendar[];
}

/**
 * TODO @puguh move to goalie-js in calendar session
 * Cronofy calendar permission level
 */
export enum CalendarPermission {
	SANDBOX = "sandbox",
	UNRESTRICTED = "unrestricted",
}

/**
 * Cronofy calendar
 */
export interface Calendar {
	profileId: string;
	providerService: string;
	calendarId: string;
	calendarName: string;
	permissionLevel: string;
	isPrimary: boolean;
	isDeleted: boolean;
	isReadOnly: boolean;
}

/**
 * Calendar event type
 */
export interface CalendarEvent extends CalendarEventResponse {}

/**
 * Calendar time view
 */
export enum TimeView {
	// For showing `day` view
	DAY = "day",
	// For showing `week` view
	WEEK = "week",
}

/**
 * On its first render, the calendar will scroll so that this value is the hour time what will be shown at the top part of the calendar.
 */
export const DEFAULT_START_HOUR_SHOWN = 8;

/**
 * Number of columns of days in calendar view
 */
export enum DayColumns {
	ONE_DAY = 1,
	FIVE_DAYS = 5,
	SEVEN_DAYS = 7,
}

/**
 * Colors of synced calendar
 */
export enum CalendarColor {
	blue = "#3F5ED2",
	orange = "#FF8176",
	green = "#1AB496",
	lavender = "#7D4BA5",
	dark = "#424267",
}

/**
 * Undo action timeout in milliseconds
 */
export const UNDO_ACTION_TIMEOUT_MS = 4000;

/**
 * A map: [calendarId] => calendar create / update / delete task
 * Needed for tracking user's click on "undo" per each entry.
 */
export const calendarEventScheduleMap: Map<string, Task> = new Map();

/**
 * Settings of calendar for user settings
 */
export interface CalendarSettings {
	defaultCalendarId?: string;
	calendarColors?: Array<[string, CalendarColor]>;
	calendarsVisibility?: Array<[string, boolean]>;
	calendarTimeView?: TimeView;
	isWeekendShown?: boolean;
}

/**
 * Calendar session event id prefix
 * Attach `session#` prefix for `eventId` according to BE requirements here:
 * https://niceday.slab.com/posts/pt-3-calendar-plan-session-6iypsymd
 */
export const SESSION_EVENT_ID_PREFIX = "session#";

/**
 * Calendar provider service
 */
export enum CalendarProvider {
	GOOGLE = "google",
	GSUITE = "gsuite",
	ICLOUD = "icloud",
	OUTLOOK = "outlook_com",
	OFFICE365 = "office365",
	EXCHANGE = "exchange",
}

/**
 * Calendar all day event min duration (in days)
 */
export const MIN_ALL_DAY_EVENT_DURATION = 1;
