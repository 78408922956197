import {fork} from "redux-saga/effects";
import userBioSaga from "./UserBioSaga";
import userPersonalSaga from "./UserPersonalSaga";
import userAvatarSaga from "./UserAvatarSaga";

export default function* () {
	yield fork(userBioSaga);
	yield fork(userPersonalSaga);
	yield fork(userAvatarSaga);
}
