import {call, put, select, debounce, fork} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";

import {calendarActions} from "../redux/calendarActions";
import {getShownDates, getCalendarProfiles} from "../redux/calendarSelectors";
import {calendarSDK} from "../helpers/calendarSDK";
import {DateString, CalendarEvent, CalendarProfile} from "../calendarTypes";
import {getDateRangeBorders, getCalendarIdsFromProfiles, mapCalendarEventsIntoDate} from "../helpers/calendarHelpers";

const log = createLogger("getCalendarEventsSaga", SentryTags.Calendar);

function* handleGetCalendarEvents() {
	try {
		const token: string = yield call(getSessionId);
		const calendarProfiles: CalendarProfile[] = yield select(getCalendarProfiles);
		const dateRange: DateString[] = yield select(getShownDates);
		const calendarIds: string[] = yield call(getCalendarIdsFromProfiles, calendarProfiles);
		const {startDate, endDate} = yield call(getDateRangeBorders, dateRange);

		const calendarEvents: CalendarEvent[] = yield apiCallSaga(
			calendarSDK.getCalendarEvents,
			token,
			calendarIds,
			startDate,
			endDate,
		);

		// Map fetched calendar events into `dateRange`
		const calendarEventsMap: Record<string, CalendarEvent[]> = yield call(
			mapCalendarEventsIntoDate,
			dateRange,
			calendarEvents,
		);

		yield put(
			calendarActions.getCalendarEvents.success({
				calendarEvents: calendarEventsMap,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(calendarActions.getCalendarEvents.failure(err));
	}
}

function* getCalendarEventsSaga() {
	// Adding delay 500ms for `dateRange` ready to use from redux
	yield debounce(500, getType(calendarActions.getCalendarEvents.request), handleGetCalendarEvents);
}

export default function* () {
	yield fork(getCalendarEventsSaga);
}
