import {AppState} from "../../ts/redux/AppState";
import {createSelector} from "reselect";
import {getContactsByRole, getSelectedContactId} from "../../contacts/redux/contactSelectors";
import {getAuthUser} from "../../auth/redux";
import {AvailableTherapist} from "../clientNetworkTypes";
import {mapAuthUserIntoAvailableTherapist, mapContactIntoAvailableTherapist} from "../helpers/clientNetworkUtils";
import {UserRole} from "@sense-os/goalie-js";
import {cacheCreateSelectorPerUserId} from "redux/utils";

const getClientNetworkState = (state: AppState) => state.clientNetwork;

export const isLoadingAvailableTherapists = createSelector(
	getClientNetworkState,
	(state) => state.isLoadingAvailableTherapists,
);

export const getAvailableTherapists = createSelector(
	getContactsByRole(UserRole.THERAPIST),
	getAuthUser,
	(contacts, authUser) => {
		let availableTherapists: AvailableTherapist[] = [];
		availableTherapists = availableTherapists.concat(contacts.map(mapContactIntoAvailableTherapist));
		availableTherapists.push(mapAuthUserIntoAvailableTherapist(authUser));
		return availableTherapists;
	},
);

export const getClientsConnectionById = cacheCreateSelectorPerUserId((userId: number) =>
	createSelector(getClientNetworkState, (state) => state.clientsConnectionMap[userId]),
);

export const getClientsConnectionLoadingState = (userId: number) =>
	createSelector(getClientNetworkState, (state) => state.clientsConnectionMap[userId]?.loadingState);

/**
 * Returns connected therapists with it's organization name.
 */
export const getConnectedTherapistsWithOrgName = (state: AppState) => {
	const clientId = getSelectedContactId(state);
	const clientsConnection = getClientsConnectionById(clientId)(state);

	return clientsConnection?.connectedTherapists?.filter((therapist) => !!therapist.organizationName) || [];
};

/**
 * Primarily used in Client Management feature,
 * to get all the connections of a client.
 */
export const getAllClientConnections = cacheCreateSelectorPerUserId((clientId: number) =>
	createSelector(
		getAvailableTherapists,
		getClientsConnectionById(clientId),
		(availableTherapists, clientsConnection) => {
			return !clientsConnection?.connectedTherapists
				? []
				: clientsConnection?.connectedTherapists.map((connection) => {
						const therapist = availableTherapists.find((therapist) => therapist.id === connection.id);
						return {
							...connection,
							organizationName: therapist?.organizationName,
						};
				  });
		},
	),
);

export const getAllClientConnectionsName = cacheCreateSelectorPerUserId((clientId: number) =>
	createSelector(getAllClientConnections(clientId), (connections) => {
		return connections
			.map((connection) => `${connection.firstName} ${connection.lastName}`)
			.filter((name) => name.trim().length > 0)
			.join(", ");
	}),
);
