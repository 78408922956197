import {LoadingState} from "constants/redux";
import produce from "immer";
import {getType} from "typesafe-actions";
import {
	ClientsActivityState,
	ClientActivityState,
	CLIENT_ACTIVITY_TYPE,
	ClientRegistrationState,
} from "../clientActivityTypes";
import {clientActivityActions, ClientActivityActionType} from "./clientActivitiyActions";

export const clientActivityMapReducer = produce(
	(state: ClientsActivityState, action: ClientActivityActionType) => {
		switch (action.type) {
			case getType(clientActivityActions.fetchClientActivityDailyPlanners.request):
			case getType(clientActivityActions.fetchClientActivityDailyPlanners.success):
			case getType(clientActivityActions.fetchClientActivityDailyPlanners.failure):
			case getType(clientActivityActions.fetchClientActivitySensors.request):
			case getType(clientActivityActions.fetchClientActivitySensors.success):
			case getType(clientActivityActions.fetchClientActivitySensors.failure):
			case getType(clientActivityActions.fetchClientActivityBehaviourExp.request):
			case getType(clientActivityActions.fetchClientActivityBehaviourExp.success):
			case getType(clientActivityActions.fetchClientActivityBehaviourExp.failure):
			case getType(clientActivityActions.fetchClientActivityPsychoEducation.request):
			case getType(clientActivityActions.fetchClientActivityPsychoEducation.success):
			case getType(clientActivityActions.fetchClientActivityPsychoEducation.failure):
			case getType(clientActivityActions.fetchClientActivityTask.request):
			case getType(clientActivityActions.fetchClientActivityTask.success):
			case getType(clientActivityActions.fetchClientActivityTask.failure):
			case getType(clientActivityActions.updateLastFetched): {
				const {userId} = action.payload;
				state.clientActivities[userId] = clientActivityReducer(state.clientActivities[userId], action);
				break;
			}

			case getType(clientActivityActions.markAsOpenedRecently): {
				state.idOfRecentlyOpenedRegistration = action.payload.activityId;
				break;
			}

			case getType(clientActivityActions.unmarkOpenedRecently): {
				state.idOfRecentlyOpenedRegistration = undefined;
				break;
			}
		}
	},
	{clientActivities: {}} as ClientsActivityState,
);

const clientActivityReducer = produce(
	(state: ClientActivityState, action: ClientActivityActionType) => {
		switch (action.type) {
			case getType(clientActivityActions.fetchClientActivityDailyPlanners.request):
			case getType(clientActivityActions.fetchClientActivityDailyPlanners.success):
			case getType(clientActivityActions.fetchClientActivityDailyPlanners.failure): {
				const clientActivityType = CLIENT_ACTIVITY_TYPE.DAILY_PLANNER;
				state.clientRegistrations[clientActivityType] = clientRegistrationsReducer(
					state.clientRegistrations[clientActivityType],
					action,
				);
				break;
			}

			case getType(clientActivityActions.fetchClientActivityBehaviourExp.request):
			case getType(clientActivityActions.fetchClientActivityBehaviourExp.success):
			case getType(clientActivityActions.fetchClientActivityBehaviourExp.failure): {
				const clientActivityType = CLIENT_ACTIVITY_TYPE.BEHAVIOR_EXPERIMENT;
				state.clientRegistrations[clientActivityType] = clientRegistrationsReducer(
					state.clientRegistrations[clientActivityType],
					action,
				);
				break;
			}

			case getType(clientActivityActions.fetchClientActivityPsychoEducation.request):
			case getType(clientActivityActions.fetchClientActivityPsychoEducation.success):
			case getType(clientActivityActions.fetchClientActivityPsychoEducation.failure): {
				const clientActivityType = CLIENT_ACTIVITY_TYPE.PSYCHO_EDUCATION;
				state.clientRegistrations[clientActivityType] = clientRegistrationsReducer(
					state.clientRegistrations[clientActivityType],
					action,
				);
				break;
			}

			case getType(clientActivityActions.fetchClientActivityTask.request):
			case getType(clientActivityActions.fetchClientActivityTask.success):
			case getType(clientActivityActions.fetchClientActivityTask.failure): {
				const clientActivityType = CLIENT_ACTIVITY_TYPE.TASK;
				state.clientRegistrations[clientActivityType] = clientRegistrationsReducer(
					state.clientRegistrations[clientActivityType],
					action,
				);
				break;
			}

			case getType(clientActivityActions.fetchClientActivitySensors.request):
			case getType(clientActivityActions.fetchClientActivitySensors.failure): {
				const {activityTypes} = action.payload;
				activityTypes.forEach((activityType: CLIENT_ACTIVITY_TYPE) => {
					state.clientRegistrations[activityType] = clientRegistrationsReducer(
						state.clientRegistrations[activityType],
						action,
					);
				});
				break;
			}
			case getType(clientActivityActions.fetchClientActivitySensors.success): {
				const {data} = action.payload;
				const activityTypes: string[] = Object.keys(data);
				activityTypes.forEach((activityType: CLIENT_ACTIVITY_TYPE) => {
					state.clientRegistrations[activityType] = clientRegistrationsReducer(
						state.clientRegistrations[activityType],
						action,
						activityType,
					);
				});
				break;
			}
			case getType(clientActivityActions.updateLastFetched): {
				state.lastFetched = new Date();
				break;
			}
		}
	},
	{clientRegistrations: {}, lastFetched: null},
);

const clientRegistrationsReducer = produce(
	(state: ClientRegistrationState, action: ClientActivityActionType, sensor?: CLIENT_ACTIVITY_TYPE) => {
		switch (action.type) {
			case getType(clientActivityActions.fetchClientActivityTask.request):
			case getType(clientActivityActions.fetchClientActivitySensors.request):
			case getType(clientActivityActions.fetchClientActivityPsychoEducation.request):
			case getType(clientActivityActions.fetchClientActivityBehaviourExp.request):
			case getType(clientActivityActions.fetchClientActivityDailyPlanners.request): {
				state.loadingState = LoadingState.LOADING;
				break;
			}

			case getType(clientActivityActions.fetchClientActivityTask.failure):
			case getType(clientActivityActions.fetchClientActivitySensors.failure):
			case getType(clientActivityActions.fetchClientActivityPsychoEducation.failure):
			case getType(clientActivityActions.fetchClientActivityBehaviourExp.failure):
			case getType(clientActivityActions.fetchClientActivityDailyPlanners.failure): {
				state.loadingState = LoadingState.ERROR;
				break;
			}

			case getType(clientActivityActions.fetchClientActivityBehaviourExp.success):
			case getType(clientActivityActions.fetchClientActivityPsychoEducation.success):
			case getType(clientActivityActions.fetchClientActivityDailyPlanners.success):
			case getType(clientActivityActions.fetchClientActivityTask.success): {
				state.registrations = action.payload.data;
				state.loadingState = LoadingState.LOADED;
				break;
			}

			case getType(clientActivityActions.fetchClientActivitySensors.success): {
				state.registrations = action.payload.data[sensor];
				state.loadingState = LoadingState.LOADED;
				break;
			}
		}
	},
	{registrations: [], loadingState: LoadingState.EMPTY},
);
