import {put, takeEvery, fork, call} from "@redux-saga/core/effects";
import {ActionType, getType} from "typesafe-actions";
import {TimeTrackingEntry as BaseTimeTrackingEntry, TimeTrackingAPIPayload, UserRole} from "@sense-os/goalie-js";
import {getSessionId} from "../../auth/helpers/authStorage";

import {timeTrackingActions} from "../redux/timeTrackingActions";
import timeTrackingSdk from "../timeTrackingSdk";
import {dateToString, isItToday, processTimeTrackingEntry} from "../timeTrackingHelpers";
import {toastActions} from "../../toaster/redux";
import Localization, {ILocalization} from "../../localization/Localization";

import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {TimeTrackingEntry} from "../timeTrackingTypes";
import {delay, select} from "redux-saga/effects";
import {getSelectedDateEntriesCache} from "../redux/timeTrackingSelectors";
import moment from "moment";
import {getDeletionUndoButton} from "../views/DeletionUndoButton";
import {Task} from "redux-saga";
import featureFlags from "../../featureFlags/FeatureFlags";
import {
	automaticTreatmentCreationSaga,
	addCurrentUserToTreatment,
} from "../../treatmentStatus/sagas/automaticTreatmentCreationSaga";
import strTranslation from "../../assets/lang/strings";
import {getContactById} from "../../contacts/redux/contactSelectors";
import {Contact} from "../../contacts/contactTypes";

const log = createLogger("TherapistTimeTracking - CRUD time entry saga", SentryTags.TherapistTimeTracking);

const loc: ILocalization = Localization;

/**
 * This is the first saga that will be triggered whenever user create
 * a new time tracking entry manually with the active entry input.
 */
function* createTimeEntry(action: ActionType<typeof timeTrackingActions.createTimeEntry.request>) {
	try {
		const token: string = getSessionId();
		const {entry} = action.payload;

		if (entry.treatmentId < 0) {
			if (!featureFlags.automaticTreatmentForTimeTracking) {
				return;
			}

			// A negative treatmentId is a hack
			// where the value actually refers to user id without treatment.
			const contact: Contact = yield select((state) => getContactById(state, entry.treatmentId * -1));

			// Don't create treatment if the user isn't patient.
			if (contact?.role !== UserRole.PATIENT) return;

			try {
				const treatment = yield call(automaticTreatmentCreationSaga, entry.treatmentId * -1);
				entry.treatmentId = treatment.id;
			} catch (err) {
				log.captureException(err);
				yield put(timeTrackingActions.createTimeEntry.failure({error: err}));
				return;
			}
		} else {
			yield call(addCurrentUserToTreatment, entry.treatmentId);
		}

		const createdEntry: BaseTimeTrackingEntry = yield apiCallSaga(timeTrackingSdk.createTimeEntry, token, {
			activity: entry.activityKey || undefined,
			endTime: entry.endTime,
			isAutoTracked: entry.isAutoTracked,
			startTime: entry.startTime,
			treatment: entry.treatmentId || undefined,
			type: entry.typeKey || undefined,
			tzEndTime: Intl.DateTimeFormat().resolvedOptions().timeZone,
			tzStartTime: Intl.DateTimeFormat().resolvedOptions().timeZone,
			url: window.location.href,
			note: entry.note,
		});

		yield put(
			timeTrackingActions.createTimeEntry.success({
				entry: processTimeTrackingEntry(createdEntry),
			}),
		);
		yield put(timeTrackingActions.resetActiveEntry());
	} catch (error) {
		yield put(timeTrackingActions.createTimeEntry.failure({error}));
		yield put(
			toastActions.addToast({
				message: Localization.formatMessage(strTranslation.TIME_TRACKING.error.fail_to_create_new_entry),
				type: "error",
			}),
		);

		log.captureException(error);
	}
}

/**
 * The saga to update any time tracking entry.
 */
function* updateTimeTrackingEntrySaga(action: ActionType<typeof timeTrackingActions.updateEntry.request>) {
	try {
		const token: string = getSessionId();
		const {entry, date} = action.payload;

		// `updatePayload` is constructed this way,
		// to leverage the fact that `timeTrackingSdk.updateTimeEntry`
		// can update a time tracking entry partially.
		const updatePayload: Partial<TimeTrackingAPIPayload> = {
			// @ts-ignore
			confirmedAt: null,
		};

		// Don't update to BE if either startTime or endTime is null/undefined.
		// A null startTime or endTime is invalid, and portal should not
		// send to BE an invalid entry.
		//
		// Also, until BE makes startTime and endTime mandatory, updating startTime or endTime
		// to null can results in "missing" entry that won't be shown
		// in any day/page.

		if (!!entry.startTime) {
			updatePayload.startTime = entry.startTime;
			updatePayload.tzStartTime = Intl.DateTimeFormat().resolvedOptions().timeZone;
		}

		if (!!entry.endTime) {
			updatePayload.endTime = entry.endTime;
			updatePayload.tzEndTime = Intl.DateTimeFormat().resolvedOptions().timeZone;
		}

		if ("activityKey" in entry) {
			updatePayload.activity = entry.activityKey || undefined;
		}

		if ("typeKey" in entry) {
			updatePayload.type = entry.typeKey || undefined;
		}

		if ("treatmentId" in entry) {
			updatePayload.treatment = entry.treatmentId;

			if (entry.treatmentId < 0) {
				// A negative treatmentId is a hack
				// where the value actually refers to user id without treatment.
				const contact: Contact = yield select((state) => getContactById(state, entry.treatmentId * -1));

				// Don't create treatment if the user isn't patient.
				if (contact?.role !== UserRole.PATIENT) return;

				const treatment = yield call(automaticTreatmentCreationSaga, entry.treatmentId * -1);
				updatePayload.treatment = treatment.id;
			} else {
				yield call(addCurrentUserToTreatment, entry.treatmentId);
			}
		}

		const updatedEntry: BaseTimeTrackingEntry = yield apiCallSaga(
			timeTrackingSdk.updateTimeEntry,
			token,
			action.payload.entryId,
			updatePayload,
		);

		const processedEntry = processTimeTrackingEntry(updatedEntry);
		yield put(
			timeTrackingActions.updateEntry.success({
				entryId: action.payload.entryId,
				entry: processedEntry,
				date,
			}),
		);

		if (!isItToday(dateToString(processedEntry.startTime)))
			yield put(timeTrackingActions.addUnconfirmedEntry(processedEntry));
	} catch (error) {
		yield put(timeTrackingActions.updateEntry.failure({entryId: action.payload.entryId, error}));
		yield put(
			toastActions.addToast({
				message: Localization.formatMessage(strTranslation.TIME_TRACKING.error.fail_to_update_entry),
				type: "error",
			}),
		);

		log.captureException(error);
	}
}

const TOAST_UNDO_KEY_PREFIX = "TT_deletion_confirmation_";
const UNDELETE_TIMEOUT_MS: number = 4000;

/**
 * A map: [entryId] => deletion_status
 * Needed for tracking user's click on "undelete" per each entry.
 * Relevant right after  the user clicks the trash bin icon (i.e. decides to delete an entry)
 */
export const deleteEntryScheduleMap: Map<number, Task> = new Map();

/**
 * The saga to delete the entry in the BE.
 */
function* deleteTimeTrackingEntrySaga(action: ActionType<typeof timeTrackingActions.deleteEntry.request>) {
	const {entryId: entryToDeleteId, date: dateString} = action.payload;
	const entries: TimeTrackingEntry[] = yield select(getSelectedDateEntriesCache);
	const entryToDelete = entries.find(({id}) => id === entryToDeleteId);

	if (!entryToDelete) {
		// Do nothing if entry doesn't exist
		return;
	}

	// Show toast message to give a chance to user to Undo the delete action
	const startTimeStr = moment(entryToDelete.startTime).format("HH:mm");
	const endTimeStr = moment(entryToDelete.endTime).format("HH:mm");
	const onScreenEntryName = startTimeStr + " - " + endTimeStr;
	yield put(
		toastActions.addToast({
			message: loc.formatMessage(strTranslation.TIME_TRACKING.deletion_toast.is_being_deleted, {
				entry_name: onScreenEntryName,
			}),

			type: "warning",
			persist: false,
			key: TOAST_UNDO_KEY_PREFIX + entryToDeleteId,
			action: getDeletionUndoButton(entryToDeleteId),
		}),
	);

	// Put the actual deletion task inside a map. This way the saga task can be canceled later in `timeTrackingActions.undoEntryDeletion` saga handler.
	const deletionSagaTask = yield fork(deleteTimeEntrySagaTask, dateString, entryToDeleteId, onScreenEntryName);
	deleteEntryScheduleMap.set(entryToDeleteId, deletionSagaTask);
}

/**
 * Saga task to delete time entry. Can be canceled by `timeTrackingActions.undoEntryDeletion` action below.
 */
function* deleteTimeEntrySagaTask(dateString: string, entryId: number, onScreenEntryName: string) {
	// Delay the execution to allow user to cancel the task by clicking the deletion warning toast
	yield delay(UNDELETE_TIMEOUT_MS);

	// Remove the toast with undo button
	yield put(toastActions.removeToast(TOAST_UNDO_KEY_PREFIX + entryId));

	try {
		const token: string = getSessionId();
		yield apiCallSaga(timeTrackingSdk.deleteTimeEntry, token, entryId);
		yield put(timeTrackingActions.deleteEntry.success({date: dateString, entryId: entryId}));

		// the toast of success
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.TIME_TRACKING.deletion_toast.was_deleted_successfully, {
					entry_name: onScreenEntryName,
				}),
				type: "success",
			}),
		);
	} catch (error) {
		yield put(timeTrackingActions.deleteEntry.failure({entryId: entryId, error}));
		yield put(
			toastActions.addToast({
				message: Localization.formatMessage(strTranslation.TIME_TRACKING.error.fail_to_delete_entry),
				type: "error",
			}),
		);

		log.captureException(error);
	} finally {
		deleteEntryScheduleMap.delete(entryId);
	}
}

/**
 * Marks an entry as 'undeleted' and hides the corresponding 'undo' toast.
 * @param action
 */
function* onUndoEntryDeletion(action: ActionType<typeof timeTrackingActions.undoEntryDeletion>) {
	// Remove the toast with undo button
	yield put(toastActions.removeToast(TOAST_UNDO_KEY_PREFIX + action.payload.entryId));

	// Cancel deletion task to prevent portal to send deletion API request to BE
	const deletionTask = deleteEntryScheduleMap.get(action.payload.entryId);
	if (deletionTask) {
		deletionTask.cancel();
		deleteEntryScheduleMap.delete(action.payload.entryId);
	}
}

export default function* () {
	yield takeEvery(getType(timeTrackingActions.updateEntry.request), updateTimeTrackingEntrySaga);
	yield takeEvery(getType(timeTrackingActions.deleteEntry.request), deleteTimeTrackingEntrySaga);
	yield takeEvery(getType(timeTrackingActions.createTimeEntry.request), createTimeEntry);
	yield takeEvery(getType(timeTrackingActions.undoEntryDeletion), onUndoEntryDeletion);
}
