import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {takeEvery, call, put} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {UserProfileAction} from "../redux/UserProfileAction";
import {getSessionId, getAuthUserId} from "../../auth/helpers/authStorage";
import profileSDK from "../helpers/profileSDK";
import {toastActions} from "../../toaster/redux/toastAction";
import Localization, {ILocalization} from "../../localization/Localization";
import {authActions} from "../../auth/redux/authActions";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {UserData} from "@sense-os/goalie-js/dist";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("userProfileSaga", SentryTags.UserProfile);
const loc: ILocalization = Localization;

function* handleSaveAvatar(action: ActionType<typeof UserProfileAction.saveUserAvatar.request>) {
	const token = yield call(getSessionId);
	const userId = yield call(getAuthUserId);
	try {
		// Update user avatar
		yield apiCallSaga(profileSDK.setUserImage, token, userId, action.payload.userAvatar);
		// Showing success toast
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.USER_PROFILE.avatar_updated.toast),
				type: "info",
			}),
		);
		// Refetch user data
		const userData: UserData = yield apiCallSaga(profileSDK.getUserData, token, userId);
		yield put(authActions.updateAuthUser({imageUrl: userData.image}));
		yield put(UserProfileAction.saveUserAvatar.success());
	} catch (error) {
		// Showing error toast
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.USER_PROFILE.avtar_upload_failed.toast) + " " + error.message,
				type: "error",
			}),
		);
		yield put(UserProfileAction.saveUserAvatar.failure(error));
		log.captureException(error);
	}
}

export default function* () {
	yield takeEvery(getType(UserProfileAction.saveUserAvatar.request), handleSaveAvatar);
}
