import {Message, ChatStateType} from "@sense-os/goalie-js";
import {Reducer} from "redux";
import {getType} from "typesafe-actions";

import {LoadingState} from "../../ts/constants/redux";
import {ChatRoomAction} from "./ChatRoomAction";
import {NumberMap} from "services/utils/Maps";
import {mergeMessages} from "../../ts/services/chat/chatHelpers";

export interface ChatRoomState {
	/** Timestamp of when messages has been delivered to the counterpart user in millisecond */
	deliveredTimestampMs?: number;
	/** Fetching state of chat */
	fetchingState: LoadingState;
	/** uploading state of chat */
	uploadingState: LoadingState;
	/** Input text to be restored when needed */
	inputText: string;
	/** True if all chat history has been fetched */
	isAllHistoryFetched: boolean;
	/** List of user's chat messages */
	messages: Message[];
	/** Timestamp of when messages has been read by the counterpart user in millisecond */
	readTimestampMs?: number;
	/** Selected message of which the user want to reply to */
	selectedMessage?: Message;
	/** Counterpart typing state */
	typingState: ChatStateType;
	/** Timestamp of when the user read the message from counterpart in millisecond */
	sentReadMarkerTimestampMs?: number;
	/** Timestamp we get from BE (not ejabberd!) of the last message that our counterpart sent us */
	lastReceivedTimestampMs?: number;
}

export const emptyRoom: ChatRoomState = {
	fetchingState: LoadingState.EMPTY,
	uploadingState: LoadingState.EMPTY,
	inputText: "",
	isAllHistoryFetched: false,
	messages: [],
	typingState: ChatStateType.Inactive,
};

export type UserMapChatRoomState = NumberMap<ChatRoomState>;

/**
 * Set chat room state
 *
 * @param {UserMapChatRoomState} state
 * @param {number} userId
 * @param {string} key
 * @param {any} newValue
 */
function setChatRoomState(
	state: UserMapChatRoomState,
	userId: number,
	key: keyof ChatRoomState,
	newValue: any,
): UserMapChatRoomState {
	const userChatRoomState = state[userId];
	return {
		...state,
		[userId]: {
			...emptyRoom,
			...userChatRoomState,
			[key]: newValue,
		},
	};
}

export const ChatRoomReducer: Reducer<UserMapChatRoomState> = (
	state: UserMapChatRoomState = {},
	action: ChatRoomAction.ChatRoomActionType,
): UserMapChatRoomState => {
	switch (action.type) {
		case getType(ChatRoomAction.bulkSetChatRoomState): {
			const newState = {...state};
			Object.keys(action.payload.partialState).forEach((id) => {
				const userId = Number(id);
				newState[userId] = {...newState[userId], ...action.payload.partialState[userId]};
			});

			return newState;
		}

		case getType(ChatRoomAction.setAllHistoryIsFetched): {
			return setChatRoomState(state, action.payload.userId, "isAllHistoryFetched", true);
		}

		case getType(ChatRoomAction.setDeliveredTimestampMs): {
			return setChatRoomState(state, action.payload.userId, "deliveredTimestampMs", action.payload.timestamp);
		}

		case getType(ChatRoomAction.setChatRoomFetchingState): {
			return setChatRoomState(state, action.payload.userId, "fetchingState", action.payload.fetchingState);
		}

		case getType(ChatRoomAction.setChatRoomUploadingState): {
			return setChatRoomState(state, action.payload.userId, "uploadingState", action.payload.uploadingState);
		}
		case getType(ChatRoomAction.setInputText): {
			return setChatRoomState(state, action.payload.userId, "inputText", action.payload.text);
		}

		case getType(ChatRoomAction.setMessages): {
			return setChatRoomState(state, action.payload.userId, "messages", action.payload.messages);
		}

		case getType(ChatRoomAction.addMessages): {
			/**
			 * We put this logic here, because we need to guarantee
			 * that the first argument to addMessages function always refer to latest state.
			 */
			const updatedMessages = mergeMessages(
				state[action.payload.userId]?.messages || [],
				action.payload.messages,
			);
			return setChatRoomState(state, action.payload.userId, "messages", updatedMessages);
		}

		case getType(ChatRoomAction.setReadTimestampMs): {
			return setChatRoomState(state, action.payload.userId, "readTimestampMs", action.payload.timestamp);
		}

		case getType(ChatRoomAction.setSelectedMessage): {
			return setChatRoomState(state, action.payload.userId, "selectedMessage", action.payload.msg);
		}

		case getType(ChatRoomAction.setTypingState): {
			return setChatRoomState(state, action.payload.userId, "typingState", action.payload.typingState);
		}

		case getType(ChatRoomAction.setSentReadMarkerTimestampMs): {
			return setChatRoomState(
				state,
				action.payload.userId,
				"sentReadMarkerTimestampMs",
				action.payload.timestamp,
			);
		}

		default:
			return state;
	}
};
