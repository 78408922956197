import {put, select, takeEvery} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import createLogger from "../../logger/createLogger";
import {getSelectedContactId} from "../../contacts/redux/contactSelectors";

import {startCallScreenActions} from "../redux/startCallScreenActions";
import {getSelectedCallType, isVideoCall} from "../redux/startCallScreenSelector";
import {callActions} from "../../call/redux/callActions";
import {OutgoingCallTypes} from "../../call/callTypes";

const log = createLogger("initiateCallSaga");

export function* handleInitiateCall() {
	const isVideo: boolean = yield select(isVideoCall);
	const callType: OutgoingCallTypes = yield select(getSelectedCallType);
	const userId: number = yield select(getSelectedContactId);

	try {
		// Open call window and check whether user is able to start a new call
		yield put(callActions.startCall({userId, isVideo}));
		// Close new start call dialog
		yield put(startCallScreenActions.closeCallScreen());
		// Create call room, activeCall then initiate call signalling
		yield put(callActions.startOutgoingCallByType(callType));
	} catch (error) {
		log.captureException(error, {message: "Unable to initiate call from start call screen"});
	}
}

export default function* () {
	yield takeEvery(getType(startCallScreenActions.initiateCall), handleInitiateCall);
}
