import featureFlags from "../../../featureFlags/FeatureFlags";
import {TrackerSensorName} from "../customTrackerSensorTypes";

/*
 * supported Tracker by portal configuration
 * the type is object of TrackerSensorName and the value is a function that's returning boolean
 * The value is a function so that it can use feature flags
 * ex :
 * 	 [TrackerSensorName.worry]:  featureFlags.taskRegistration,
 *   [TrackerSensorName.worry]:  featureFlags.assessment,
 *
 */

export const supportedTrackerConfigurations: {[key in TrackerSensorName]: () => boolean} = {
	// trackers v1
	[TrackerSensorName.researchEmotions]: () => true,
	[TrackerSensorName.afraid]: () => true,
	[TrackerSensorName.alcohol]: () => true,
	[TrackerSensorName.angry]: () => true,
	[TrackerSensorName.ashamed]: () => true,
	[TrackerSensorName.automaticPilot]: () => true,
	[TrackerSensorName.backache]: () => true,
	[TrackerSensorName.binge]: () => true,
	[TrackerSensorName.bodycheck]: () => true,
	[TrackerSensorName.compulsion]: () => true,
	[TrackerSensorName.cramped]: () => true,
	[TrackerSensorName.craving]: () => true,
	[TrackerSensorName.curious]: () => true,
	[TrackerSensorName.depersonalization]: () => true,
	[TrackerSensorName.depressed]: () => true,
	[TrackerSensorName.difficulty]: () => true,
	[TrackerSensorName.disoriented]: () => true,
	[TrackerSensorName.distracted]: () => true,
	[TrackerSensorName.energy]: () => true,
	[TrackerSensorName.excited]: () => true,
	[TrackerSensorName.exercise]: () => true,
	[TrackerSensorName.fatigue]: () => true,
	[TrackerSensorName.guilty]: () => true,
	[TrackerSensorName.happy]: () => true,
	[TrackerSensorName.headache]: () => true,
	[TrackerSensorName.hyperAlert]: () => true,
	[TrackerSensorName.hyperAroused]: () => true,
	[TrackerSensorName.hyperAssertiveness]: () => true,
	[TrackerSensorName.inDanger]: () => true,
	[TrackerSensorName.insecure]: () => true,
	[TrackerSensorName.loneliness]: () => true,
	[TrackerSensorName.panicAttack]: () => true,
	[TrackerSensorName.pain]: () => true,
	[TrackerSensorName.physicalWeakness]: () => true,
	[TrackerSensorName.physicallyFrozen]: () => true,
	[TrackerSensorName.positiveThings]: () => true,
	[TrackerSensorName.restAndRelax]: () => true,
	[TrackerSensorName.sad]: () => true,
	[TrackerSensorName.sideEffectsMedication]: () => true,
	[TrackerSensorName.smoking]: () => true,
	[TrackerSensorName.socialAvoidance]: () => true,
	[TrackerSensorName.socialDependent]: () => true,
	[TrackerSensorName.subAssertiveness]: () => true,
	[TrackerSensorName.tension]: () => true,
	[TrackerSensorName.withdrawal]: () => true,
	[TrackerSensorName.worry]: () => true,

	// trackers v2
	[TrackerSensorName.aggression]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.askForHelp]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.assertiveness]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.avoidance]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.cannabis]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.cocaine]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.flashbacks]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.gratitude]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.memoryLoss]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.mindfulness]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.nightmares]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.panicAttackWithPhysicalSensation]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.safetyBehaviour]: () => featureFlags.customTrackerPart2,
	[TrackerSensorName.weight]: () => featureFlags.customTrackerPart2,

	// trackers v4
	[TrackerSensorName.addictionBehavior]: () => featureFlags.trackersV4,
	[TrackerSensorName.anxiety]: () => featureFlags.trackersV4,
	[TrackerSensorName.attentionDeficit]: () => featureFlags.trackersV4,
	[TrackerSensorName.cognitiveComplaints]: () => featureFlags.trackersV4,
	[TrackerSensorName.dissociation]: () => featureFlags.trackersV4,
	[TrackerSensorName.food]: () => featureFlags.trackersV4,
	[TrackerSensorName.hallucinations]: () => featureFlags.trackersV4,
	[TrackerSensorName.hyperActivity]: () => featureFlags.trackersV4,
	[TrackerSensorName.impulsivity]: () => featureFlags.trackersV4,
	[TrackerSensorName.overstimulated]: () => featureFlags.trackersV4,
	[TrackerSensorName.physicalActivity]: () => featureFlags.trackersV4,
	[TrackerSensorName.sleep]: () => featureFlags.trackersV4,
	[TrackerSensorName.suspiciousness]: () => featureFlags.trackersV4,
};

/**
 * Returns true if Tracker is supported by the web portal.
 */
export function isTrackerSupported(sensorName: string): boolean {
	if (!!supportedTrackerConfigurations[sensorName]) {
		return supportedTrackerConfigurations[sensorName]();
	}

	return false;
}
