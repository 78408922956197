import {CallContact, Contact, ParticipantData, UserData} from "@sense-os/goalie-js";
import {DefaultLocalUser} from "app/SenseDefaults";
import {AuthUser} from "../../auth/authTypes";
import localization from "../../localization/Localization";
import {TimeTrackingEntryPatient} from "../../timeTracking/timeTrackingTypes";

/**
 * Try to localize specialization translation key provided by the backend.
 * See this specification: https://niceday.slab.com/posts/enter-job-title-as-a-therapist-jv7iyrnw
 *
 * @param specializationFromBE
 * @returns
 */
export function localizeTherapistSpecialization(specializationFromBE: string) {
	// Add `ZZ_JOB_TITLES` prefix because the BE doesn't provide the complete translation key..
	const translationKey = "ZZ_JOB_TITLES." + specializationFromBE;

	// Return null if translation key does not exist.
	if (!localization.isTranslationIdExist(translationKey)) {
		return null;
	}

	return localization.formatMessage(translationKey);
}

/**
 * Concatenate firstName and lastName
 * 	if firstName or lastName is null or undefined use empty string instead.
 *  if both firstName and lastName is null or undefined return ellipsis.
 * @param data Object which contains firstName and lastName key
 * 			   AuthUser | CallContact | TimeTrackingEntryPatient | DefaultLocalUser | Contact | UserData | ParticipantData
 * @returns String Fullname
 */
export function getFullName(
	data: AuthUser | CallContact | TimeTrackingEntryPatient | DefaultLocalUser | Contact | UserData | ParticipantData,
) {
	if (!!data?.firstName || !!data.lastName) {
		return [data?.firstName || "", data?.lastName || ""].join(" ");
	}

	// Context: https://github.com/senseobservationsystems/web-getgoalie/issues/4517
	// We use ts-ignore here, in case we are dealing with old data structure,
	// as the current structure doesn't have attribute `fullName` anymore.
	// @ts-ignore
	if (!!data?.fullName) {
		// @ts-ignore
		return data.fullName;
	}

	return "...";
}
