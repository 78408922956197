import moment from "moment";
import strTranslation from "../../assets/lang/strings";

import Localization, {ILocalization} from "../../localization/Localization";
import {TIME, TIME_UNITS} from "../constants/time";
import {TimeRange} from "../redux/tracking/TrackingTypes";

type Timestamp = number | moment.Moment;

/** checks if the given timestamp is within the boundaries */
export const isWithin = (ts: number, start: number, end: number) => {
	return ts >= start && ts <= end;
};

/**
 * checks if the given timestamp is ahead of the given compared timestamp
 */
export const isFuture = (timestamp: number, timestampToCompare: number = Date.now()) => {
	return timestamp > timestampToCompare;
};

/** converts the given timestamp to the beginning of the week (Monday) */
export const startOfWeek = (ts: Timestamp) => {
	let m = moment(ts);
	return m.startOf("isoWeek");
};

/** converts the given timestamp to the end of the week (Sunday) */
export const endOfWeek = (ts: Timestamp) => {
	let m = moment(ts);
	return m.endOf("isoWeek");
};

/** returns a timestamp to either beginning of given week, or to beginning of 4 weeks ago */
export const startOfRange = (endTs: Timestamp, range: TimeRange) => {
	let start = startOfWeek(endTs);
	if (range === TimeRange.FOUR_WEEKS) {
		start = start.subtract(3, "weeks");
	}
	return start.valueOf();
};

/** returns a timestamp to either end of given week, or to end of 4 weeks ahead */
export const endOfRange = (startTs: Timestamp, range: TimeRange) => {
	let end = endOfWeek(startTs);
	if (range === TimeRange.FOUR_WEEKS) {
		end = end.add(3, "weeks");
	}
	return end.valueOf();
};

/** Returns the TimeRange for the given start and end timestamp */
export const startEndToRange = (start: number, end: number): TimeRange => {
	switch (moment(end).diff(start, "days")) {
		case 6:
			return TimeRange.WEEK;
		case 27:
		default:
			return TimeRange.FOUR_WEEKS;
	}
};

const isBefore = (date: Date, hour: number): boolean => {
	return date.getHours() < hour || (date.getHours() === hour && date.getMinutes() === 0 && date.getSeconds() === 0);
};

/** Returns 'night', 'morning', 'afternoon' or 'evening' based on the given timestamp */
export const displayDayPart = (timestamp: number): string => {
	const date = new Date(timestamp);
	let localisationKey: string = strTranslation.COMMON.evening;

	if (isBefore(date, 6)) {
		localisationKey = strTranslation.COMMON.night;
	} else if (isBefore(date, 12)) {
		localisationKey = strTranslation.COMMON.morning;
	} else if (isBefore(date, 18)) {
		localisationKey = strTranslation.COMMON.afternoon;
	}
	return Localization.formatMessage(localisationKey);
};

/**
 * Returns difference in days between two moment objects.
 * @param {Moment} d1
 * @param {Moment} d2
 */
export function getDayDiff(d1: moment.Moment, d2: moment.Moment): number {
	return d1.startOf(TIME_UNITS.DAY).diff(d2.startOf(TIME_UNITS.DAY), TIME_UNITS.DAYS);
}

/**
 * Renders a date as a localized human-readable string.
 * If the given date happens to be today, it's replaced with a localised word "today".
 * The same goes for "yesterday" if the given date was yesterday.
 * If the date was earlier than yesterday, a fully formatted represenation is returned, such as "Su, 17 dec. 1995"
 * @param {Date} date
 * @param {boolean} includeTime if true, time as hours:minutes will be appended to the returned result, e.g. "today, 18:30"
 * @param {string} wrongDateStub if `date` cannot be converted to a human readable string (null, undefined, etc), the wrongDateStub will be returned instead.
 * @returns {string}
 */
export function renderDate(date: Date, includeTime: boolean = false, wrongDateStub: string = "-"): string {
	if (!date) {
		return wrongDateStub;
	}

	const earlier: moment.Moment = moment(date);

	const time: string = earlier.format("HH:mm");
	let dateString: string = getHumanReadableDayString(date);

	if (includeTime) {
		dateString += ", " + time;
	}

	return dateString;
}

/**
 * Returns humand readable day string
 *
 * @param {Date} date
 * @param {string} dateFormat
 * @param {boolean} usePreposition Date preposition "on" in English and "op" in Dutch
 * - if true and it's not a today / tomorrow / yesterday then it'll add an "on" as a prefix.
 */
export function getHumanReadableDayString(
	date: Date,
	dateFormat: string = "dd, DD MMM YYYY",
	usePreposition: boolean = false,
): string {
	const now = moment(),
		momentObj = moment(date),
		loc: ILocalization = Localization;
	const dayDiff: number = getDayDiff(now, momentObj);

	if (dayDiff === -1) {
		return loc.formatMessage(strTranslation.TIME.tomorrow);
	}
	if (dayDiff === 0) {
		return loc.formatMessage(strTranslation.TIME.today);
	}
	if (dayDiff === 1) {
		return loc.formatMessage(strTranslation.TIME.yesterday);
	}

	if (usePreposition) {
		return `${loc.formatMessage(strTranslation.TIME.preposition.date)} ${momentObj.format(dateFormat)}`;
	}

	return momentObj.format(dateFormat);
}

/**
 * Returns humand readable day and time string
 *
 * @param {Date} date
 * @param {string} dateFormat
 * @param {string} timeFormat
 * @param {boolean} usePreposition Date/Time preposition "on"/"at" in English and "op"/"om" in Dutch
 * - if true and it's not a today / tomorrow / yesterday then it'll add an "on" as a prefix e.g "on Thursday 09/12/2021"
 * - and an "at" as a prefix of time string e.g "at 09:00am".
 */
export function getHumanReadableDayTimeString(
	date: Date,
	dateFormat: string = "ddd, DD MMM YYYY",
	timeFormat: string = "HH:mm",
	usePreposition: boolean = false,
): string {
	const dateString: string = getHumanReadableDayString(date, dateFormat, usePreposition);
	const timeString: string = moment(date).format(timeFormat).toString();

	if (usePreposition) {
		const loc: ILocalization = Localization;
		return `${dateString} ${loc.formatMessage(strTranslation.TIME.preposition.time)} ${timeString}`;
	}
	return `${dateString} ${timeString}`;
}

/**
 * Returns true if both moment objects contain the same date
 *
 * @param {moment.Moment} m1
 * @param {moment.Moment} m2
 */
export function isDateEqual(m1: moment.Moment, m2: moment.Moment): boolean {
	return m1.date() === m2.date() && m1.month() === m2.month() && m1.year() === m2.year();
}

/**
 * Create/Convert Local date to UTC Date string
 *
 * @param {Date} localDate
 */
export function createUTCDateString(localDate?: Date): string {
	const date: Date = localDate || new Date();
	const timestamp: number = Date.UTC(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		date.getHours(),
		date.getMinutes(),
		date.getSeconds(),
		date.getMilliseconds(),
	);
	return new Date(timestamp).toISOString();
}

/**
 * Convert ISO Date string to local date
 *
 * @param {Date} utcDate
 */
export function createLocalDateFromISODateString(dateString: string): Date {
	const date: Date = new Date(dateString);
	return new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds(),
		date.getUTCMilliseconds(),
	);
}

/**
 * Create timezone unaware time string
 *
 * @param {Date} date
 */
export function createTimezoneUnawareTimeString(date?: Date): string {
	if (date) {
		return date.toISOString().replace("Z", "");
	}
	return new Date().toISOString().replace("Z", "");
}

/**
 * Returns week number of month depends on date.
 *
 * @param {Date} date
 */
export function weekNumberOfMonth(date: Date = new Date()): number {
	if (date.getDate() % TIME.DAYS_IN_WEEK !== 0) {
		return Math.ceil(date.getDate() / TIME.DAYS_IN_WEEK);
	}
	return date.getDate() / TIME.DAYS_IN_WEEK;
}

/**
 * Check if date is today
 * @param {moment.Moment} date
 * @returns {boolean}
 */
export function isToday(date: moment.Moment): boolean {
	return date.isSame(moment(), TIME_UNITS.DAY);
}

/**
 * Get yesterday date
 */
export function yesterday(): moment.Moment {
	return moment().subtract(1, TIME_UNITS.DAY);
}

/**
 * Only enable today and forward date on datetime calendar
 * @param {moment.Moment} current as moment date
 */
export function isValidTodayAndAfterDate(current: moment.Moment): boolean {
	return current.isAfter(yesterday());
}

/**
 * Update date with selected hour
 * or reset to current hour if (date=today and hour < current hour)
 * @param {moment.Moment} date
 * @param {number} hour
 * @returns {Date}
 */
export function updateDateHour(date: moment.Moment, hour: number): Date {
	const minHour: number = isToday(date) ? new Date().getHours() : 0;
	return date.set({h: hour < minHour ? minHour : hour}).toDate();
}

/**
 * Returns the current timestamp in seconds
 * @returns {number}
 */
export function nowInSeconds(): number {
	return Math.round(new Date().getTime() / TIME.MILLISECONDS_IN_SECOND);
}

/**
 * Returns the current timestamp in milliseconds
 * @returns {number}
 */
export function nowInMilliseconds(): number {
	return new Date().getTime();
}
