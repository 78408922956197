import {TIME} from "constants/time";
import {useMemo} from "react";
import {IGraphV2Data} from "../../customTrackerGraphV2Types";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";
import {getYTickValues, getTotalXTick} from "./graphV2ConfigHelper";

// this hook will return list of date that shows in x tick
export const useGetXTickValues = (
	graphData: IGraphV2Data[],
	selectedTimeView: GRAPH_TIME_VIEW_VALUE,
	startDate: Date,
	endDate: Date,
): Date[] => {
	return useMemo(() => {
		const oldestDate: Date =
			selectedTimeView !== GRAPH_TIME_VIEW_VALUE.ALL_TIME && startDate
				? startDate
				: graphData.reduce((oldest, graphDatum) => {
						const dateX = graphDatum.data.length ? new Date(graphDatum.data[0].x) : oldest;
						return oldest < dateX ? oldest : dateX;
				  }, new Date());

		const recentDate: Date =
			selectedTimeView !== GRAPH_TIME_VIEW_VALUE.ALL_TIME && endDate
				? endDate
				: graphData.reduce((recent, graphDatum) => {
						const dateX = graphDatum.data.length
							? new Date(graphDatum.data[graphDatum.data.length - 1].x)
							: recent;
						return recent > dateX ? recent : dateX;
				  }, new Date("16 January 1993"));

		const rangeDateTime: number = recentDate.valueOf() - oldestDate.valueOf();

		const rangeDateData = rangeDateTime / TIME.MILLISECONDS_IN_24HOURS + 1;

		const totalXTicks = getTotalXTick(rangeDateData);

		return Array.from({length: totalXTicks}, (value, index) => {
			const temp = new Date();
			if (rangeDateData < totalXTicks - 1) {
				temp.setTime(oldestDate.getTime() + (index - 1) * TIME.MILLISECONDS_IN_24HOURS);
			} else {
				temp.setTime(oldestDate.getTime() + rangeDateTime * (index / (totalXTicks - 1)));
			}
			temp.setHours(0);
			temp.setMinutes(0);
			return new Date(temp);
		});
	}, [endDate, graphData, selectedTimeView, startDate]);
};

// this hook will return list of number that shows in y tick
export const useGetYTickValues = (graphData: IGraphV2Data[], highestYScale: number, yLines: number): number[] => {
	return useMemo(() => {
		const maxYData = graphData.reduce((tempList, data) => {
			const highestY = data.data.reduce((temp, dataXY) => {
				return dataXY.y > temp ? dataXY.y : temp;
			}, highestYScale);
			return highestY > tempList ? highestY : tempList;
		}, highestYScale);
		return getYTickValues(yLines, maxYData);
	}, [graphData, highestYScale, yLines]);
};

export const useGetXTickValuePlainData = (graphData: IGraphV2Data[]): Date[] => {
	return useMemo(() => {
		// first tick is time of first registration
		const firstTick: Date = graphData.reduce((oldest, graphDatum) => {
			const dateX = graphDatum.data.length ? new Date(graphDatum.data[0].x) : oldest;
			return oldest < dateX ? oldest : dateX;
		}, new Date());

		// last tick is time of last registration
		const lastTick: Date = graphData.reduce((recent, graphDatum) => {
			const dateX = graphDatum.data.length ? new Date(graphDatum.data[graphDatum.data.length - 1].x) : recent;
			return recent > dateX ? recent : dateX;
		}, new Date("16 January 1993"));

		// set last tick to end of day
		lastTick.setHours(24);
		lastTick.setMinutes(0);

		return [firstTick, lastTick];
	}, [graphData]);
};
