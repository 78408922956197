import {Task} from "redux-saga";
import {call, cancel, cancelled, fork, select, take} from "redux-saga/effects";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import {featureFlagIsRefreshedAction} from "../../featureFlags/FeatureFlagSaga";

import {checkAuthUserAccess, PortalFeatures} from "../../featureFlags/userFeatureAccess";

/**
 * This saga will allow us to start and stop any functions,
 * based on whether a flag is turned on or off.
 */
export function whenEnabled(flag: PortalFeatures, onEffect: () => void, offEffect?: () => void) {
	return function* () {
		let prevIsEnabled: boolean | undefined;
		let curEffect: Task | undefined;

		try {
			while (true) {
				const authUser: AuthUser = yield select(getAuthUser);
				const isEnabled: boolean = checkAuthUserAccess(authUser)(flag);

				if (prevIsEnabled !== isEnabled) {
					if (curEffect !== undefined) {
						yield cancel(curEffect);
						curEffect = undefined;
					}

					if (isEnabled) {
						curEffect = yield fork(onEffect);
					} else if (offEffect) {
						curEffect = yield fork(offEffect);
					}

					prevIsEnabled = isEnabled;
				}

				yield take(featureFlagIsRefreshedAction);
			}
		} finally {
			const isCanceled = yield cancelled();
			if (prevIsEnabled && offEffect !== undefined && isCanceled) {
				yield call(offEffect);
			}
		}
	};
}
