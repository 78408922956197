import moment from "moment";
import {fork, put, call, select, takeEvery, takeLatest} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {InactiveContact} from "@sense-os/goalie-js/dist/contacts/";
import {TIME_UNITS} from "constants/time";
import {PureUserUtils} from "utils/PureUserUtils";
import contactSdk from "../contactSdk";
import {getFirstItemNumber} from "../inactiveClientHelpers";
import {getCurrentPage, getTotalInactiveClients, getMaxItemPerPage} from "../redux";
import {inactiveClientActions} from "../redux/inactiveClientActions";
import {DAYS_OF_INACTIVITY, InactiveClient, FIRST_PAGE_NUMBER} from "../inactiveClientTypes";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";

const logger = createLogger("InactiveClientActions", SentryTags.InactiveClient);

function* loadInactiveClients() {
	const token: string = getSessionId();
	try {
		const lastRegistrationContacts: InactiveContact[] = yield apiCallSaga(contactSdk.getInactiveClients, token, 0);

		const anchorDateOfInactivity = moment()
			.startOf(TIME_UNITS.DAY)
			.subtract(DAYS_OF_INACTIVITY, TIME_UNITS.DAYS)
			.toDate();

		const inactiveContacts = lastRegistrationContacts.filter(
			(contact) => !contact.latestDataRegistration || contact.latestDataRegistration < anchorDateOfInactivity,
		);

		const hasContactWithNoSensorData = inactiveContacts.some((contact) => !contact.latestDataRegistration);

		let inactiveClients: InactiveClient[] = inactiveContacts.map((contact) => {
			return {
				contact,
				latestRegistration: contact.latestDataRegistration || contact.dateJoined,
				dataLink: PureUserUtils.getDataLink(contact.hashId),
			};
		});

		const lastRegistrationClients = lastRegistrationContacts.map((contact) => {
			return {
				contact,
				latestRegistration: contact.latestDataRegistration,
				dataLink: PureUserUtils.getDataLink(contact.hashId),
			};
		});

		if (hasContactWithNoSensorData) {
			/**
			 * This is required to address an edge case when some Patients that connected to particular Therapist
			 * haven't registered any data to BE after they joined with NiceDay.
			 *
			 * For example:
			 * - PatientX joined NiceDay 8 days ago.
			 * - PatientX asks a connection to TherapistY and they connected each other.
			 * - PatientX goes for some vacation and hasn't submitted any data through NiceDay. i.e. their feeling, mood, etc.
			 * - This means PatientX doesn't have information on the date of the latest registration.
			 *
			 * Hence as a replacement of data latest registration date, we agree to use `dateJoined` as comparison for
			 * client inactivity. See: https://github.com/senseobservationsystems/niceday-planning/issues/111#issuecomment-658513936
			 *
			 * However, for the sake of simplicity, BE can only indicate that particular Patients are considered as inactive contact
			 * and delegate the filtering contacts to the API consumer. Because technically, combining two filters from MongoDB (sensor latest
			 * registration check) and Postgres (date joined check) is impossible to do via django Queryset.
			 */
			inactiveClients = inactiveClients.filter((contact) => contact.latestRegistration < anchorDateOfInactivity);
		}

		// Store inactiveClientsMap to state
		yield put(
			inactiveClientActions.loadInactiveClients.success({
				clients: inactiveClients,
				lastRegistration: lastRegistrationClients,
			}),
		);
	} catch (error) {
		yield put(inactiveClientActions.loadInactiveClients.failure({error}));
		logger.captureException(error);
	}
}

function* goToTheNextPage() {
	const totalItems: number = yield select(getTotalInactiveClients),
		currentPage: number = yield select(getCurrentPage),
		maxItemPerPage: number = yield select(getMaxItemPerPage),
		firstItemNumber: number = yield call(getFirstItemNumber, currentPage, maxItemPerPage);

	// Do nothing if there is no next page
	const hasNextPage: boolean = firstItemNumber + maxItemPerPage > totalItems;
	if (hasNextPage) {
		logger.debug("This is the last page of inactive clients");
		return;
	}

	yield put(inactiveClientActions.incrementPage());
}

function* goToThePrevPage() {
	// Do nothing if current page is the first page
	const currentPage: number = yield select(getCurrentPage);
	if (currentPage === FIRST_PAGE_NUMBER) {
		logger.debug("This is the first page of inactive clients");
		return;
	}
	yield put(inactiveClientActions.decrementPage());
}

function* inactiveClientSaga() {
	yield takeLatest(getType(inactiveClientActions.loadInactiveClients.request), loadInactiveClients);
	yield takeEvery(getType(inactiveClientActions.goToTheNextPage), goToTheNextPage);
	yield takeEvery(getType(inactiveClientActions.goToThePrevPage), goToThePrevPage);
}

export default function* () {
	yield fork(inactiveClientSaga);
}
