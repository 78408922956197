import produce from "immer";
import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {InterventionAssessmentActionType, interventionAssessmentActions} from "./interventionAssessmentActions";
import {
	AssessmentExpireStatus,
	AssessmentListFilter,
	InterventionAssessmentState,
} from "../interventionAssessmentTypes";

const initialState: InterventionAssessmentState = {
	omqSmqFetchingState: LoadingState.EMPTY,
	dataSource: [],
	totalReflections: null,
	selectedStatus: AssessmentExpireStatus.PAST,
	selectedFilter: AssessmentListFilter.ALL,
};

export const interventionAssessmentReducer = produce(
	(state: InterventionAssessmentState = initialState, action: InterventionAssessmentActionType) => {
		switch (action.type) {
			case getType(interventionAssessmentActions.fetchOmqSmq.request):
				state.omqSmqFetchingState = LoadingState.LOADING;
				return;
			case getType(interventionAssessmentActions.fetchOmqSmq.success):
				state.omqSmqFetchingState = LoadingState.LOADED;
				state.dataSource = action.payload.dataSource;
				state.totalReflections = action.payload.totalReflections;
				return;
			case getType(interventionAssessmentActions.fetchOmqSmq.failure):
				state.omqSmqFetchingState = LoadingState.ERROR;
				return;
			case getType(interventionAssessmentActions.setExpireStatus):
				state.selectedStatus = action.payload.status;
				return;
			case getType(interventionAssessmentActions.setDataSourceFilter):
				state.selectedFilter = action.payload.filter;
				return;
		}
	},
	initialState,
);
