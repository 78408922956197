import {PresenceAvailability} from "@sense-os/goalie-js";
import {put, select} from "redux-saga/effects";
import {chatPresenceActions} from "../../../chat/redux/ChatPresenceAction";
import {getCurrentPresence, getCurrentPresenceContext} from "../../../chat/redux/ChatPresenceSelector";
import {ChatPresenceContext} from "../../../chat/types";

/**
 * Set online presence to BUSY, if current presence is Online
 */
export function* setChatPresenceToBusyIfOnline() {
	const currentChatPresence: PresenceAvailability = yield select(getCurrentPresence);
	if (currentChatPresence === PresenceAvailability.Available) {
		yield put(
			chatPresenceActions.updateOwnPresence({
				presence: PresenceAvailability.DND,
				context: ChatPresenceContext.SET_BY_PORTAL,
			}),
		);
	}
}

/**
 * Change online presence to ONLINE,
 * if the previous DnD status was set automatically because of the call.
 */
export function* restoreChatPresenceToOnline() {
	const currentChatPresence: PresenceAvailability = yield select(getCurrentPresence);
	const currentChatPresenceContext: ChatPresenceContext = yield select(getCurrentPresenceContext);
	if (
		currentChatPresence === PresenceAvailability.DND &&
		currentChatPresenceContext === ChatPresenceContext.SET_BY_PORTAL
	) {
		yield put(
			chatPresenceActions.updateOwnPresence({
				presence: PresenceAvailability.Available,
				context: ChatPresenceContext.SET_BY_PORTAL,
			}),
		);
	}
}
