/**
 * The key for the migration flags in user settings.
 */
export enum UserSettingsBasedMigrationKey {
	/**
	 * This key is related to this issue: https://github.com/senseobservationsystems/web-getgoalie/issues/6156, where we want to unify different fields of client number into one field in
	 * treatment form.
	 */
	CLIENT_NUMBER_UNIFICATION = "clientNumberUnification",
}
