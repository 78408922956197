import {takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {clientNetworkActions} from "../../clientNetwork/redux";
import {createTimeLimitedPut} from "../../helpers/sagas/timeLimitedPut";
import {inactiveClientActions} from "../../inactiveClient/redux";
import {interventionSessionActions} from "../../intervention/session/redux/interventionSessionAction";
import {clientManagementActions} from "../redux/clientManagementActions";

export const timeLimitedPut = createTimeLimitedPut<
	| typeof clientNetworkActions.loadClientsConnectionById.request
	| typeof interventionSessionActions.fetchSessions.request
	| typeof inactiveClientActions.loadInactiveClients.request
>(5 * 60 * 1000, (action) => {
	switch (action.type) {
		case getType(inactiveClientActions.loadInactiveClients.request):
			return action.type;

		default:
			return `${action.type}-${action.payload.clientId.toString()}`;
	}
});

function* getNetworkInfo(action: ActionType<typeof clientManagementActions.getNetworkInfo>) {
	yield timeLimitedPut(clientNetworkActions.loadClientsConnectionById.request({clientId: action.payload.id}));
}

function* getSessionInfo(action: ActionType<typeof clientManagementActions.getSessionInfo>) {
	yield timeLimitedPut(
		interventionSessionActions.fetchSessions.request({
			clientId: action.payload.id,
		}),
	);
}

function* getLastRegistration() {
	yield timeLimitedPut(inactiveClientActions.loadInactiveClients.request());
}

export default function* () {
	yield takeEvery(getType(clientManagementActions.getNetworkInfo), getNetworkInfo);
	yield takeEvery(getType(clientManagementActions.getSessionInfo), getSessionInfo);
	yield takeEvery(getType(clientManagementActions.getLastRegistrationInfo), getLastRegistration);
}
