import {AppState} from "../../ts/redux/AppState";
import {PresenceAvailability} from "@sense-os/goalie-js";
import {isInCall, isInForeground, isSupported} from "../notificationHelpers";

/** Check user's PresenceAvailability to determine whether the user is busy.
 *  Returns true when user's PresenceAvailability is DND.
 */
export const isBusy = (state: AppState) => state.chatPresence.presence === PresenceAvailability.DND;

/** Check whether we should show the notification.
 *  We don't need to show any notifications if we are:
 *  1. In foreground
 *  2. Is in call
 *  3. Is busy
 */
export const shouldShowNotification = (state: AppState) =>
	!isInForeground() && !isInCall() && !isBusy(state) && isSupported();
