import {AuthUser} from "../auth/authTypes";
import {checkAuthUserAccess, PortalFeatures} from "../featureFlags/userFeatureAccess";

export interface NewFeaturePopupReduxState {
	// Only one popup can be shown at a time and it's stored in this field.
	shownPopup?: NewFeatures;

	// Flags whether popup can be shown or not.
	// Mostly, this flags exists because some popup can only be shown in some screens.
	// For example, popup for chat should only be shown in client or therapist's page
	canPopupBeShown: {
		[feature in NewFeatures]?: boolean;
	};
}

/**
 * This is the enum that enumerate the list of features that is
 * accompanied with a "New Feature" popup in its implementation.
 *
 * As this enum will be part of the body of the request to backend,
 * the value of the key is written in camelCase to be consistent
 * with the casing of other part of the request's body.
 */
export enum NewFeatures {
	CHAT_BOX_DEFAULT_OPEN = "chatBoxDefaultOpen",
	TREATMENT_STATUS = "treatmentStatus",
	SMQ_OMQ_NEW_CHART = "smqOmqNewChart",
	NICEDAY_BLOG_SEARCH = "nicedayBlogSearch",
	PRIVATE_NOTES = "privateNotes",
	PSYCHO_EDUCATION = "psychoEducation",
	TWO_FA = "twoFa",
	INCOMING_FEATURE = "incomingFeature",
	DEDICATED_NOTE_PAGE = "dedicatedNotePage",
}

/**
 * This is the explicit list of all features with popup to help with the logic in these modules.
 *
 * The order of features in this array also determine the priority of which
 * popup is shown first, but the current order is decided arbitrarily.
 * Feel free to change it when needs arise.
 */
export const getFeaturePopupList = (authUser: AuthUser) =>
	[
		NewFeatures.CHAT_BOX_DEFAULT_OPEN,
		checkAuthUserAccess(authUser)(PortalFeatures.treatmentStatus) && NewFeatures.TREATMENT_STATUS,
		checkAuthUserAccess(authUser)(PortalFeatures.viewClientPage) && NewFeatures.SMQ_OMQ_NEW_CHART,
		checkAuthUserAccess(authUser)(PortalFeatures.nicedayBlogSearch) && NewFeatures.NICEDAY_BLOG_SEARCH,
		checkAuthUserAccess(authUser)(PortalFeatures.privateNotes) && NewFeatures.PRIVATE_NOTES,
		checkAuthUserAccess(authUser)(PortalFeatures.psychoEducationV2) && NewFeatures.PSYCHO_EDUCATION,
		checkAuthUserAccess(authUser)(PortalFeatures.twoFactorAuthentication) && NewFeatures.TWO_FA,
		checkAuthUserAccess(authUser)(PortalFeatures.dedicatedNotePage) && NewFeatures.DEDICATED_NOTE_PAGE,
	].filter(Boolean);
