import {fileSharingActions} from "./../redux/fileSharingActions";
import {all, call, put, takeEvery} from "redux-saga/effects";
import fileSharingSDK from "./fileSharingSDK";
import {getSessionId} from "../../auth/helpers/authStorage";
import {ActionType} from "typesafe-actions";
import {shouldReportToSentry} from "../../auth/helpers";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {toastActions} from "../../toaster/redux";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("fileSharing", SentryTags.FileSharing);

function* deleteFile(action: ActionType<typeof fileSharingActions.deleteFile.request>) {
	try {
		const token = getSessionId();
		const deleteRequests = action.payload.ids?.map((id) => call(fileSharingSDK.delete, token, id));
		yield all(deleteRequests);
		yield put(
			fileSharingActions.deleteFile.success({
				userId: action.payload.userId,
				ids: action.payload.ids,
			}),
		);

		yield put(fileSharingActions.closeDeleteDialog(action.payload.userId));
		// Showing error toast
		yield put(
			toastActions.addToast({
				message: strTranslation.FILE_SHARING.toast.file_deleted,
				type: "success",
			}),
		);
	} catch (error) {
		if (shouldReportToSentry(error)) {
			log.captureException(error);
		}
		yield put(
			fileSharingActions.deleteFile.failure({userId: action.payload.userId, ids: action.payload.ids, error}),
		);
		// Showing error toast
		yield put(
			toastActions.addToast({
				message: strTranslation.FILE_SHARING.toast.failed_delete,
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(fileSharingActions.deleteFile.request, deleteFile);
}
