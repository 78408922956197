import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {DiaryEntryState} from "../dairyEntryTypes";
import {DiaryEntryActionType, diaryEntryActions} from "./diaryEntryActions";

const initialState: DiaryEntryState = {
	diaryEntryFetchState: LoadingState.EMPTY,
	diaryEntryData: null,
};

export function diaryEntryReducer(
	state: DiaryEntryState = initialState,
	action: DiaryEntryActionType,
): DiaryEntryState {
	switch (action.type) {
		case getType(diaryEntryActions.fetchDiaryEntry.request):
			return {
				...state,
				diaryEntryFetchState: LoadingState.LOADING,
				diaryEntryData: null,
			};
		case getType(diaryEntryActions.fetchDiaryEntry.success):
			const {diaryEntryData} = action.payload;
			return {
				...state,
				diaryEntryFetchState: LoadingState.LOADED,
				diaryEntryData: diaryEntryData,
			};
		case getType(diaryEntryActions.fetchDiaryEntry.failure):
			return {
				...state,
				diaryEntryFetchState: LoadingState.ERROR,
			};
		default:
			return state;
	}
}
