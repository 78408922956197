/**
 * Author: leo Date: 31/07/2018
 */

// lib imports
import autobind from "autobind-decorator";
import {ChatStateType} from "@sense-os/goalie-js";
// app imports
import {Injectable} from "../../IoC/Injectable";

// TODO: Remove it once we move the needed specific chat functionality into saga
import chatSdk from "../../../chat/sdk";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {getStoreState} from "redux/StoreContainer";
import {isConnected} from "../../../chat/redux/ChatSelector";

/**
 * This service notifies the chat counterpart on the typing status, i.e. when the local user is
 * typing a chat message and when not. The service is meant to be used in `ChatBox`.
 */
export interface ITypingIndicationService {
	/**
	 * Checks if there's a change in the user input and updates the other participant of the chat accordingly
	 * @param {string} chatInput the latest user input in the chatbox
	 */
	registerInputChange(chatInput: string): void;

	/**
	 * Sends the typing status over the network to the currently selected client.
	 * Does it only if the provided status differs from the previously sent one.
	 * @param {ChatStateType} state
	 */
	sendTypingStatus(state: ChatStateType): void;

	/**
	 * Shoud be called once the user has sent the message
	 */
	onMessageSent(): void;

	/**
	 * Initialises or re-initialises the service.
	 */
	init(userId: number): void;

	/**
	 * Sends `Inactive` to the last selected client,
	 * releases all the resources and stops the activity of the service.
	 */
	reset(): void;
}

@autobind
export class TypingIndicationService implements ITypingIndicationService, Injectable {
	public readonly c: string = "[TypingIndicationService]";

	private log = createLogger("TypingIndicationService", SentryTags.Chat);

	/** User input on the previous typing step */
	private prevInput: string = "";

	/** ID of the interval for checking the changes in user input */
	private intervalId: number = NaN;

	/** ID of the timer for figuring out if the counterpart is still active. */
	private counterpartTimeoutTimerId: number = NaN;

	/** The previously sent ChatStateType */
	private prevTypingStatus: ChatStateType;

	/** Timestamp of when the latest change in the input happened */
	private lastChangeTime: number = 0;

	/** How often we check for changes in the user's input */
	private INPUT_CHECK_FREQ_MS: number = 1300;

	/** If user stops typing in the middle of the sentence, `Paused` will be sent after TYPING_TIMEOUT_MS milliseconds */
	private TYPING_TIMEOUT_MS: number = 3000; //FIXME: consider changing to 5000! Discuss with @ruben

	/** Currently selected client */
	private selectedClientId: number = NaN;

	/**
	 * @inheritDoc
	 */
	public sendTypingStatus(state: ChatStateType): void {
		// the typing is already paused, no need to pause it again.
		if (this.prevTypingStatus === ChatStateType.Paused && state === ChatStateType.Paused) {
			return;
		}

		// `Active` must always be the first status to send if the last status was `Inactive`
		if (this.prevTypingStatus === ChatStateType.Inactive && state !== ChatStateType.Active) {
			return;
		}

		if (!this.selectedClientId) {
			this.log.debug("Selected client ID not found");
		}

		if (!isConnected(getStoreState())) {
			this.log.debug(
				"Cannot send typing status. Chat is not connected. >>>>",
				state + " >> " + this.selectedClientId,
			);
			return;
		}

		this.log.info(state + " >> " + this.selectedClientId);

		// this.log.warn( "this.chatService.isConnected: ", this.chatService.isConnected);

		chatSdk.sendChatState(this.selectedClientId, state).catch((error: any) => {
			this.log.captureException(error, {message: "Unable to send chat state", state});
		});

		this.prevTypingStatus = state;
	}

	/**
	 * Checks if the user input has changed
	 */
	private checkUserInput(): void {
		// no changes in the user input recorded
		if (this.lastChangeTime < 1) {
			return;
		}

		const now: number = new Date().valueOf();
		const isRecentUpdate = now - this.lastChangeTime < this.TYPING_TIMEOUT_MS;

		if (isRecentUpdate) {
			this.sendTypingStatus(ChatStateType.Composing);
		} else {
			if (this.prevTypingStatus === ChatStateType.Composing) {
				this.sendTypingStatus(ChatStateType.Paused);
			}
		}
	}

	/**
	 * @inheritDoc
	 */
	public registerInputChange(chatInput: string): void {
		const curInput: string = chatInput || ""; // current chat input

		// No text in the input field, send `Active` and that's it.
		if (!curInput.trim()) {
			this.sendTypingStatus(ChatStateType.Active);
			this.lastChangeTime = 0;
			return;
		}

		// There's a change in the input field, send `Composing`
		if (this.prevInput !== curInput) {
			const now: number = new Date().valueOf();

			// send the first `Composing` as soon as the user starts typing
			if (now - this.lastChangeTime > this.INPUT_CHECK_FREQ_MS) {
				this.sendTypingStatus(ChatStateType.Composing);
			}

			this.lastChangeTime = new Date().valueOf();
		}

		this.prevInput = curInput; // save the latest user input for the next comparison
	}

	/**
	 * @inheritDoc
	 */
	public onMessageSent(): void {
		this.lastChangeTime = 0;
		this.sendTypingStatus(ChatStateType.Active); // The message has been sent. Reset the typing status
	}
	/**
	 * @inheritDoc
	 */
	public init(userId: number): void {
		this.log.info(" initialising...");
		this.reset();
		this.selectedClientId = userId;
		this.sendTypingStatus(ChatStateType.Active);
		this.intervalId = window.setInterval(this.checkUserInput, this.INPUT_CHECK_FREQ_MS);
	}

	/**
	 * @inheritDoc
	 */
	public reset(): void {
		if (this.selectedClientId) {
			this.sendTypingStatus(ChatStateType.Inactive);
		}

		if (!isNaN(this.intervalId)) {
			window.clearInterval(this.intervalId);
			this.intervalId = NaN;
		}

		if (!isNaN(this.counterpartTimeoutTimerId)) {
			window.clearTimeout(this.counterpartTimeoutTimerId);
			this.counterpartTimeoutTimerId = NaN;
		}

		this.prevInput = "";
		this.prevTypingStatus = null;
		this.selectedClientId = NaN;
		this.lastChangeTime = 0;
	}
}
