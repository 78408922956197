import moment from "moment";
import {Emotions, Interval as BaseInterval, TimeRange, SensorDatum, TrackingEntry} from "./TrackingTypes";
import {TIME_UNITS} from "constants/time";

// hide UserTrackingData from graph related code
export type Interval = BaseInterval;
export {TimeRange};

/** Value of a data-point */
interface Data {
	value: number;
	label?: string;
}

/** Metadata for a data-point */
interface MetaData<T extends Readonly<T>> {
	source: T;
	startDate: Date;
	endDate: Date;
	/**
	 * Indicates the data target value
	 *
	 * TODO: see if we can make Target property more generic
	 */
	targetValue?: number;
}

//
// BAR LINE DATA
//

/** Represents one bar/line data-point */
export interface DataPoint<T> extends Data, MetaData<T> {}
export type DataPoints<T> = Array<DataPoint<T>>;

export type SensorDataPoints = DataPoints<SensorDatum<TrackingEntry>>;

export function toDataPoint<T extends TrackingEntry>(
	d: SensorDatum<T>,
	valueFn: (d: T) => number,
): DataPoint<SensorDatum<T>> {
	return {
		source: d,
		startDate: d.startTime, //TODO: #track_refactor
		endDate: d.endTime, //TODO: #track_refactor
		value: valueFn(d.value),
	};
}

/**
 * Makes sure that the start- and end-time of the first and last data-point are within the given interval.
 * Note: Currently only used for step-count-target data.
 */
export const checkStartEndBoundaries = <T>(data: DataPoint<T>[], interval?: Interval) => {
	if (interval && data.length > 0) {
		// correct first point
		data[0].startDate = new Date(Math.max(data[0].startDate.getTime(), interval.start.getTime())); //TODO: #track_refactor
		// add new point add end of array containing the end-date of the last point as start-date
		const last = data[data.length - 1];
		data.push({...last, startDate: new Date(Math.min(last.endDate.getTime(), interval.end.getTime()))}); //TODO: #track_refactor
	}
	return data;
};

/**
 * There is a possibility where dataPoint's startDate is less than interval's start date.
 * We need to adjust the startDate to be the same as interval's start date if that happens.
 * Also the same with the endDate.
 *
 * @param {DataPoints<T>} data
 * @param {Interval} interval
 */
export function adjustStartEndTime<T>(
	data: DataPoints<T>,
	interval: Interval,
	stretchToEndInterval?: boolean,
): DataPoints<T> {
	if (data.length === 0) {
		return data;
	}
	const firstData = data[0],
		lastData = data[data.length - 1];

	if (firstData.startDate.getTime() < interval.start.getTime()) {
		firstData.startDate = interval.start;
	}

	if (stretchToEndInterval) {
		// Set endDate same with interval
		lastData.endDate = interval.end;
	} else if (lastData.endDate.getTime() > interval.end.getTime()) {
		// last data end date must not more than end of interval
		lastData.endDate = interval.end;
	}

	return data;
}

/**
 * Group data lines by data points
 *
 * This function takes dataPoints and group them by startDate and endDate with specific gap
 *
 * @param {DataPoints<T>} dataPoints
 * @param {number} maxDateDiff
 */
export function groupDataLinesByDataPoints<T>(dataPoints: DataPoints<T>, maxDateDiff: number = 1): DataPoints<T>[] {
	let results: DataPoints<T>[] = [];
	let tempResult: DataPoints<T> = [];

	let i: number;
	for (i = 0; i < dataPoints.length; i++) {
		const currentDataPoint = dataPoints[i];
		const prevDataPoint = dataPoints[i - 1];

		// Get dateDiff between two datapoints
		let newDateDiff: number = 0;
		if (prevDataPoint) {
			newDateDiff = Math.abs(prevDataPoint.endDate.getDate() - currentDataPoint.startDate.getDate());
		}

		if (newDateDiff > maxDateDiff) {
			// End of group. Add last item to current group and create a new one.
			results.push(tempResult);
			tempResult = [];
		}
		tempResult.push(currentDataPoint);
	}

	if (tempResult.length > 0) {
		results.push(tempResult);
	}

	return results;
}

/**
 * Add a 'success' label to step-count datapoints within the target.
 * Note: Currently only used for step-count-target data.
 */
export const checkStepCountTargets = <T>(dataPoint: DataPoint<T>, targets: SensorDataPoints) => {
	for (let i = 0; i < targets.length; i++) {
		const target = targets[i];

		// Since the endTime of a step data is always 00:00, we need to adjust it to 23:59
		// because target data is always 23:59
		// e.g  step target data -> start: 2018-10-01 00:00, end: 2018-10-01 23:59:59
		//      step count data -> start: 2018-10-01 00:00, end: 2018-10-02 00:00:00
		//      converted step count data -> start: 2018-10-01 00:00, end: 2018-10-01 23:59:59
		const stepsEndDate: number = moment(dataPoint.startDate).endOf(TIME_UNITS.DAY).unix() * 1000; // Convert to millisecond

		if (dataPoint.startDate.getTime() >= target.startDate.getTime() && stepsEndDate <= target.endDate.getTime()) {
			if (dataPoint.value >= target.value) {
				dataPoint.label = "success";
			}
			dataPoint.targetValue = target.value;
			return dataPoint;
		}
	}
	return dataPoint;
};

//
// MULTIPLE BARS DATA
//

/** Represents a moment in time with multiple values (like feelings) */
export interface MultiDataPoint<T extends Readonly<T>> extends MetaData<T> {
	values: Data[];
}
export type MultiDataPoints<T> = Array<MultiDataPoint<T>>;

//
// EMOTION PICKER
//

/** Emotion name with the count of how many times it appears in the current interval */
export interface EmotionTag {
	emotion: Emotions;
	count: number;
	active: boolean;
}

export type GraphPoint<T> = MultiDataPoint<T> | DataPoint<T>;
