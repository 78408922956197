import {AuthState} from "../authTypes";
import {LoadingState} from "constants/redux";
import {AuthActionType, authActions} from "./authActions";
import {getType} from "typesafe-actions";
import {isLoginBlocked} from "../helpers/authStorage";

export const initialState: AuthState = {
	emailVerificationLoadingState: LoadingState.EMPTY,
	loginLoadingState: LoadingState.EMPTY,
	loginTwoFA: {
		codeError: null,
		recoveryCodeError: null,
		loadingState: LoadingState.EMPTY,
		codeAttemptCount: 0,
		recoveryCodeAttemptCount: 0,
	},
	passwordResetTwoFA: {
		codeError: null,
		recoveryCodeError: null,
		loadingState: LoadingState.EMPTY,
		codeAttemptCount: 0,
		recoveryCodeAttemptCount: 0,
	},
	isLoginBlocked: isLoginBlocked(),
	isLoggedIn: false,
	registrationLoadingState: LoadingState.EMPTY,
	resetPasswordLoadingState: LoadingState.EMPTY,
	user: null,
	changePasswordLoadingState: LoadingState.EMPTY,
	logoutLoadingState: LoadingState.EMPTY,
};

export default function authReducer(state: AuthState = initialState, action: AuthActionType): AuthState {
	switch (action.type) {
		case getType(authActions.resetState):
			return initialState;

		case getType(authActions.login.request):
			return {
				...state,
				loginLoadingState: LoadingState.LOADING,
				error: null,
			};
		case getType(authActions.login.success):
			return {
				...state,
				loginLoadingState: LoadingState.LOADED,
				user: action.payload.user,
				error: null,
				// Set registration loading state to loaded
				// This because the registration process is not completed
				// until the login process has completed.
				registrationLoadingState: LoadingState.LOADED,
			};
		case getType(authActions.login.failure):
			return {
				...state,
				loginLoadingState: LoadingState.ERROR,
				error: action.payload.error,
			};
		case getType(authActions.resetLoginLoadingState):
			return {...state, loginLoadingState: LoadingState.EMPTY};

		case getType(authActions.login2FACode.request): {
			return {
				...state,
				loginTwoFA: {
					...state.loginTwoFA,
					loadingState: LoadingState.LOADING,
					codeError: null,
					codeAttemptCount: state.loginTwoFA.codeAttemptCount + 1,
				},
			};
		}
		case getType(authActions.login2FACode.success): {
			return {
				...state,
				loginTwoFA: {
					...state.loginTwoFA,
					loadingState: LoadingState.LOADED,
					codeAttemptCount: 0,
					recoveryCodeAttemptCount: 0,
				},
			};
		}
		case getType(authActions.login2FACode.failure): {
			return {
				...state,
				loginTwoFA: {
					...state.loginTwoFA,
					loadingState: LoadingState.ERROR,
					codeError: action.payload.error,
				},
			};
		}

		case getType(authActions.login2FARecoveryCode.request): {
			return {
				...state,
				loginTwoFA: {
					...state.loginTwoFA,
					loadingState: LoadingState.LOADING,
					recoveryCodeError: null,
					recoveryCodeAttemptCount: state.loginTwoFA.recoveryCodeAttemptCount + 1,
				},
			};
		}
		case getType(authActions.login2FARecoveryCode.success): {
			return {
				...state,
				loginTwoFA: {
					...state.loginTwoFA,
					loadingState: LoadingState.LOADED,
					codeAttemptCount: 0,
					recoveryCodeAttemptCount: 0,
				},
			};
		}
		case getType(authActions.login2FARecoveryCode.failure): {
			return {
				...state,
				loginTwoFA: {
					...state.loginTwoFA,
					loadingState: LoadingState.ERROR,
					recoveryCodeError: action.payload.error,
				},
			};
		}

		case getType(authActions.resetPassword2FACode.request): {
			return {
				...state,
				passwordResetTwoFA: {
					...state.passwordResetTwoFA,
					loadingState: LoadingState.LOADING,
					codeError: null,
					codeAttemptCount: state.passwordResetTwoFA.codeAttemptCount + 1,
				},
			};
		}
		case getType(authActions.resetPassword2FACode.success): {
			return {
				...state,
				passwordResetTwoFA: {
					...state.passwordResetTwoFA,
					loadingState: LoadingState.LOADED,
					codeAttemptCount: 0,
					recoveryCodeAttemptCount: 0,
				},
			};
		}
		case getType(authActions.resetPassword2FACode.failure): {
			return {
				...state,
				passwordResetTwoFA: {
					...state.passwordResetTwoFA,
					loadingState: LoadingState.ERROR,
					codeError: action.payload.error,
				},
			};
		}

		case getType(authActions.resetPassword2FARecoveryCode.request): {
			return {
				...state,
				passwordResetTwoFA: {
					...state.passwordResetTwoFA,
					loadingState: LoadingState.LOADING,
					recoveryCodeError: null,
					recoveryCodeAttemptCount: state.passwordResetTwoFA.recoveryCodeAttemptCount + 1,
				},
			};
		}
		case getType(authActions.resetPassword2FARecoveryCode.success): {
			return {
				...state,
				passwordResetTwoFA: {
					...state.passwordResetTwoFA,
					loadingState: LoadingState.LOADED,
					codeAttemptCount: 0,
					recoveryCodeAttemptCount: 0,
				},
			};
		}
		case getType(authActions.resetPassword2FARecoveryCode.failure): {
			return {
				...state,
				passwordResetTwoFA: {
					...state.passwordResetTwoFA,
					loadingState: LoadingState.ERROR,
					recoveryCodeError: action.payload.error,
				},
			};
		}

		case getType(authActions.blockLogin):
			return {
				...state,
				isLoginBlocked: true,
			};
		case getType(authActions.unblockLogin):
			return {
				...state,
				isLoginBlocked: false,
			};

		case getType(authActions.register.request):
			return {
				...state,
				registrationLoadingState: LoadingState.LOADING,
				error: null,
			};
		case getType(authActions.register.success):
			return {
				...state,
				error: null,
			};
		case getType(authActions.register.failure):
			return {
				...state,
				registrationLoadingState: LoadingState.ERROR,
				error: action.payload.error,
			};

		case getType(authActions.resetPassword.request):
			return {
				...state,
				resetPasswordLoadingState: LoadingState.LOADING,
				error: null,
			};
		case getType(authActions.resetPassword.success):
			return {
				...state,
				resetPasswordLoadingState: LoadingState.LOADED,
				error: null,
			};
		case getType(authActions.resetPassword.failure):
			return {
				...state,
				resetPasswordLoadingState: LoadingState.ERROR,
				error: action.payload.error,
			};
		case getType(authActions.resetError):
			return {
				...state,
				error: null,
			};
		case getType(authActions.updateAuthUser):
			return {
				...state,
				user: {
					...state.user,
					...action.payload,
					account: {
						...state.user?.account,
						...action.payload.account,
					},
					data: {
						...state.user?.data,
						...action.payload.data,
					},
				},
			};

		case getType(authActions.changePassword.request):
			return {
				...state,
				changePasswordLoadingState: LoadingState.LOADING,
				error: null,
			};
		case getType(authActions.changePassword.success):
			return {
				...state,
				changePasswordLoadingState: LoadingState.LOADED,
				error: null,
			};
		case getType(authActions.changePassword.failure):
			return {
				...state,
				changePasswordLoadingState: LoadingState.ERROR,
				error: action.payload.error,
			};

		case getType(authActions.logout.request):
			return {
				...state,
				logoutLoadingState: LoadingState.LOADING,
			};
		case getType(authActions.logout.success):
			return {
				...state,
				logoutLoadingState: LoadingState.LOADED,
			};
		case getType(authActions.logout.failure):
			return {
				...state,
				logoutLoadingState: LoadingState.ERROR,
			};

		case getType(authActions.initLoggedInUser):
			return {
				...state,
				user: action.payload.user,
			};

		case getType(authActions.fetchEmailVerificationStatus.request):
			return {
				...state,
				emailVerificationLoadingState: LoadingState.LOADING,
			};
		case getType(authActions.fetchEmailVerificationStatus.success):
			return {
				...state,
				emailVerificationLoadingState: LoadingState.LOADED,
				user: {
					...state.user,
					account: {
						...state.user.account,
						emailVerified: action.payload.isVerified,
					},
				},
			};
		case getType(authActions.fetchEmailVerificationStatus.failure):
			return {
				...state,
				emailVerificationLoadingState: LoadingState.ERROR,
			};
		default:
			return state;
	}
}
