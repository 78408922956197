import {planActivityActions, PlanActivitysActionType} from "./planActivityActions";
import {getType} from "typesafe-actions";
import analyticsService from "../../../analytics/AnalyticsService";

const trackedActions = [
	planActivityActions.openForm,
	planActivityActions.closeForm,

	planActivityActions.saveActivity.request,
	planActivityActions.saveActivity.success,
	planActivityActions.saveActivity.failure,

	planActivityActions.deletePlannedActivity.request,
	planActivityActions.saveActivity.success,
	planActivityActions.deletePlannedActivity.failure,
].map(getType);

const properties = (action: PlanActivitysActionType) => {
	switch (action.type) {
		case getType(planActivityActions.saveActivity.request):
			return action.payload;
		case getType(planActivityActions.deletePlannedActivity.request):
			return action.payload;
		default:
			return null;
	}
};

export const planActivityEvents = analyticsService.trackReduxActions(trackedActions, properties);
