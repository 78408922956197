import {SetupTrackerState} from "../customTrackerTypes";
import {LoadingState} from "constants/redux";
import {CustomTrackerActionType, customTrackerActions} from "./customTrackerActions";
import {getType} from "typesafe-actions";

const setupTrackerState: SetupTrackerState = {
	isSetupTrackerDialogOpened: false,
	loadingState: LoadingState.EMPTY,
	savingState: LoadingState.EMPTY,
	trackerCategories: [],
};

export default function setupTrackerReducer(
	state: SetupTrackerState = setupTrackerState,
	action: CustomTrackerActionType,
): SetupTrackerState {
	switch (action.type) {
		case getType(customTrackerActions.openSetupTrackerDialog):
			return {
				...state,
				isSetupTrackerDialogOpened: true,
			};
		case getType(customTrackerActions.closeSetupTrackerDialog):
			return {
				...state,
				isSetupTrackerDialogOpened: false,
			};
		case getType(customTrackerActions.loadTrackerCategories.request):
			return {
				...state,
				loadingState: LoadingState.LOADING,
			};
		case getType(customTrackerActions.loadTrackerCategories.success):
			return {
				...state,
				loadingState: LoadingState.LOADED,
				trackerCategories: action.payload,
			};
		case getType(customTrackerActions.loadTrackerCategories.failure):
			return {
				...state,
				loadingState: LoadingState.ERROR,
			};

		case getType(customTrackerActions.addTracker.request):
			return {
				...state,
				savingState: LoadingState.LOADING,
			};
		case getType(customTrackerActions.addTracker.success):
			return {
				...state,
				savingState: LoadingState.LOADED,
			};
		case getType(customTrackerActions.addTracker.failure):
			return {
				...state,
				savingState: LoadingState.ERROR,
			};
		case getType(customTrackerActions.showTrackerAddedDialog):
			return {
				...state,
				newAddedTracker: action.payload.newTracker,
			};
		case getType(customTrackerActions.hideTrackerAddedDialog):
			return {
				...state,
				newAddedTracker: null,
			};
		default:
			return state;
	}
}
