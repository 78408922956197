import {SenseOrganization} from "@sense-os/goalie-js";

const sdk = new SenseOrganization();
sdk.getOrganizations = sdk.getOrganizations.bind(sdk);
sdk.getOrganizationsOfUser = sdk.getOrganizationsOfUser.bind(sdk);
sdk.getAllTherapists = sdk.getAllTherapists.bind(sdk);
sdk.getOrganizationsByGroupId = sdk.getOrganizationsByGroupId.bind(sdk);
sdk.getOrganizationsGroups = sdk.getOrganizationsGroups.bind(sdk);
sdk.getAllOrganizationsMembers = sdk.getAllOrganizationsMembers.bind(sdk);
sdk.updateOrganizationMemberStatus = sdk.updateOrganizationMemberStatus.bind(sdk);

export default sdk;
