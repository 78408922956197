import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {xaiActions} from "../redux/xaiActions";
import {getSessionId} from "../../auth/helpers/authStorage";
import {xaiSdk} from "../xaiSdk";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {XaiPrediction} from "@sense-os/goalie-js";
import {arrToMap} from "../../helpers/arrToMap";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {toastActions} from "../../toaster/redux";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("XAI - Main saga", SentryTags.XAI);

function* getPredictions() {
	try {
		const token: string = yield call(getSessionId);
		const predictions: XaiPrediction[] = yield apiCallSaga(xaiSdk.getPredictions, token);

		predictions.forEach((prediction) => {
			prediction.explanation.forEach((explanation) => {
				switch (explanation.CriteriaID) {
					case "a":
						explanation.Value = prediction.criteria.criterionA;
						return;
					case "b":
						explanation.Value = prediction.criteria.criterionB;
						return;
					case "c":
						explanation.Value = prediction.criteria.criterionC;
						return;
					case "d":
						explanation.Value = prediction.criteria.criterionD;
						return;
					case "e":
						explanation.Value = prediction.criteria.criterionE;
						return;
					case "f":
						explanation.Value = prediction.criteria.criterionF;
						return;
					case "g":
						explanation.Value = prediction.criteria.criterionG;
						return;
					case "h":
						explanation.Value = prediction.criteria.criterionH;
						return;
					case "i":
						explanation.Value = prediction.criteria.criterionI;
						return;
				}
			});
		});

		const predictionsMap: Record<number, XaiPrediction> = arrToMap(predictions, (prediction) => prediction.patient);

		yield put(xaiActions.getPredictions.success({predictionsMap}));
	} catch (error) {
		log.captureException(error);
		yield put(xaiActions.getPredictions.failure({error}));
	}
}

function* sendFeedback(action: ActionType<typeof xaiActions.sendFeedback.request>) {
	const {clientId, predictionId, checkRegistrations, feedbackDescription, feedbackIsHelpful} = action.payload;
	try {
		const token: string = yield call(getSessionId);

		yield apiCallSaga(xaiSdk.setFeedback, token, predictionId, {
			checkRegistrations,
			feedbackDescription,
			feedbackIsHelpful,
		});

		yield put(xaiActions.sendFeedback.success({clientId}));
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.XAI.send_feedback.toast.succeed),
				type: "success",
			}),
		);
	} catch (error) {
		log.captureException(error);
		yield put(xaiActions.sendFeedback.failure({clientId, error}));
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.XAI.send_feedback.toast.failed),
				type: "error",
			}),
		);
	}
}

export function* xaiSaga() {
	yield takeEvery(getType(xaiActions.getPredictions.request), getPredictions);
	yield takeEvery(getType(xaiActions.sendFeedback.request), sendFeedback);
}
