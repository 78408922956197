import * as React from "react";
import {Dialog, DialogContent, DialogActions, Radio, RadioGroup, FormControlLabel} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

import {Button} from "../../material-ui/buttons";
import strTranslation from "../../assets/lang/strings";
import localization from "../../localization/Localization";

export enum UpdateEventOption {
	THIS_EVENT_ONLY = "this_event_only",
	THIS_AND_FOLLOWING_EVENTS = "this_and_following_events",
}

interface IUpdateEventOptionDialog {
	/** Title of the dialog */
	title: string;
	/** True if you want to show the dialog */
	open: boolean;
	/** on close callback */
	onClose: () => void;
	/** on save callback with selected option value */
	onSave: (options: UpdateEventOption) => void;
	/** True if you want to show `UpdateEventOption.THIS_EVENT_ONLY` option */
	allowUpdateThisEventOnly?: boolean;
}

const CHECKBOX_OPTION_TEXTS: Record<string, string> = {
	[UpdateEventOption.THIS_EVENT_ONLY]: localization.formatMessage(
		strTranslation.GRAPHS.edit_event.event_option.this_event_only,
	),
	[UpdateEventOption.THIS_AND_FOLLOWING_EVENTS]: localization.formatMessage(
		strTranslation.GRAPHS.edit_event.event_option.this_and_following_events,
	),
};

const UpdateEventOptionDialog: React.FC<IUpdateEventOptionDialog> = (props) => {
	const {open, onClose, title, onSave, allowUpdateThisEventOnly} = props;
	const [selectedOption, setSelectedOption] = React.useState<UpdateEventOption>(UpdateEventOption.THIS_EVENT_ONLY);

	const RenderRadio = (eventOptionKey: string) => {
		const value: UpdateEventOption = UpdateEventOption[eventOptionKey];
		const label: string = CHECKBOX_OPTION_TEXTS[value];
		const disabled = value === UpdateEventOption.THIS_EVENT_ONLY && !allowUpdateThisEventOnly;

		return (
			<FormControlLabel
				key={eventOptionKey}
				value={value}
				control={<Radio color="default" />}
				label={label}
				disabled={disabled}
				checked={selectedOption === value}
				onChange={() => {
					setSelectedOption(value);
				}}
			/>
		);
	};
	return (
		<Dialog open={open} onClose={onClose} className="UpdateEventOptionDialog" maxWidth="sm" fullWidth scroll="body">
			<DialogContent>
				<h2 className="UpdateEventOptionDialog__header">{title}</h2>
				<div className="UpdateEventOptionDialog__options">
					<RadioGroup aria-label="update-event-option">
						{Object.keys(UpdateEventOption).map(RenderRadio)}
					</RadioGroup>
				</div>
			</DialogContent>
			<DialogActions>
				<Button id="close_button" variant="text" color="primary" onClick={onClose}>
					<FormattedMessage id={strTranslation.COMMON.alert.cancel} />
				</Button>
				<Button id="reset_button" variant="text" color="secondary" onClick={() => onSave(selectedOption)}>
					<FormattedMessage id={strTranslation.COMMON.alert.confirm} />
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UpdateEventOptionDialog;
