import {createSelector} from "reselect";
import moment from "moment";
import {MeetingNote} from "@sense-os/sensor-schema/goalie-2-ts/meeting_note";
import {AppState} from "redux/AppState";
import {SensorDatum} from "../../../ts/redux/tracking/TrackingTypes";
import {MeetingNoteFormValues} from "../meetingNotesTypes";
import {getContactById} from "../../../contacts/redux/contactSelectors";
import loc from "../../../localization/Localization";
import {LoadingState} from "constants/redux";
import strTranslation from "../../../assets/lang/strings";

const getMeetingNotesState = (state: AppState) => state.meetingNotes;
export const getMeetingNoteUserId = createSelector(getMeetingNotesState, (state) => state.userId);
export const getMeetingNoteId = createSelector(getMeetingNotesState, (state) => state.noteId);

const meetingNotesClient = (state: AppState) => getContactById(state, getMeetingNoteUserId(state));

export const isFormOpened = createSelector(getMeetingNotesState, (state) => {
	return state.isFormOpened;
});

export const isConfirmationDialogOpened = createSelector(getMeetingNotesState, (state) => {
	return state.isConfirmationDialogOpened;
});

export const meetingNotesClientName = createSelector(meetingNotesClient, (client) => client?.fullName || "");

export const getFormValuesFromEventViewData = (state: AppState): MeetingNoteFormValues => {
	const meetingNoteSensor: SensorDatum<MeetingNote> = state.meetingNotes.meetingNoteData;

	if (!meetingNoteSensor) {
		return null;
	}

	return {
		meetingNote: meetingNoteSensor.value.note,
		title: meetingNoteSensor.value.title,
		reportTime: moment(meetingNoteSensor.value.reportTime).toDate(),
	};
};

export const getSavedFormValues = (state: AppState) => {
	const userId = getMeetingNoteUserId(state);
	return state.meetingNotes.savedFormValues[userId];
};

// Return string to be used as form title in MeetingNotesForm.
export const getMeetingNoteFormTitle = (fullName: string, isEditing: boolean): string => {
	const selectedTitle: string = isEditing
		? strTranslation.MEETING_NOTES.edit_meeting_note.title
		: strTranslation.MEETING_NOTES.add_meeting_note.title;

	return loc.formatMessage(selectedTitle, {
		name: fullName,
	});
};

export const isSavingMeetingNotes = createSelector(
	getMeetingNotesState,
	(state) => state.savingState === LoadingState.LOADING,
);

export const getFetchMeetingNoteDataState = (state: AppState): LoadingState => {
	return state.meetingNotes.fetchMeetingNoteDataState;
};
export const getFetchMeetingNoteData = (state: AppState) => {
	return state.meetingNotes.meetingNoteData;
};

export const savingStateMeetingNotes = createSelector(getMeetingNotesState, (state) => state.savingState);
