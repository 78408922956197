import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {getTherapySessionSensors} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import {therapySessionActions} from "../redux/therapySessionActions";
import {SentryTags} from "../../../errorHandler/createSentryReport";

const log = createLogger("fetchTherapySession", SentryTags.TherapySession);

export function* fetchTherapySession(action: ActionType<typeof therapySessionActions.fetchTherapySession.request>) {
	const {userId, therapyId} = action.payload;
	const token = yield call(getSessionId);
	try {
		const therapyData: SensorDatum<PlannedEventEntry>[] = yield apiCallSaga(
			getTherapySessionSensors,
			token,
			userId,
			undefined,
			undefined,
			[therapyId],
		);
		yield put(therapySessionActions.fetchTherapySession.success({data: therapyData?.[0]}));
	} catch (err) {
		log.captureException(err);
		yield put(therapySessionActions.fetchTherapySession.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(therapySessionActions.fetchTherapySession.request), fetchTherapySession);
}
