import strTranslation from "../assets/lang/strings";
import Localization, {ILocalization} from "../localization/Localization";

import {activities} from "./activityConfig";
import {durationStringToSecond, getDurationBetweenDates} from "./timeTrackingHelpers";
import {ActivityKey, ActivityTypeKey, TimeTrackingEntry, TimeTrackingEntryInput} from "./timeTrackingTypes";

export enum InputEntryErrors {
	START_TIME_INVALID = "start_time_invalid",
	END_TIME_INVALID = "end_time_invalid",
	DURATION_INVALID = "duration_invalid",
	START_AFTER_END = "start_after_end",
	START_IN_FUTURE = "start_time_future",
	END_IN_FUTURE = "end_time_future",
	CLIENT_IS_MANDATORY = "client_is_mandatory",
	ACTIVITY_IS_MANDATORY = "activity_is_mandatory",
	DURATION_TOO_LONG = "duration_too_long",
	DURATION_IS_NOT_POSITIVE = "duration_is_not_positive",
}

const MAX_DURATION_IN_SEC = 3 * 60 * 60; // Max duration is 3 hours.
// export type ErrorsMap = Partial<{[key in InputEntryErrors]: boolean}>;

// An entry qualifies as "invalid" if it has one or more errors.
export const checkHasErrors = (errors: InputEntryErrors[]) => errors.length > 0;

/**
 * This validation function use a trick of checking whether an attribute is "in" the object,
 * so, if needed, this validation can run selectively to check only given attributes.
 */
export function getErrorsOfATimeTrackingEntry(entry: Partial<TimeTrackingEntryInput>): InputEntryErrors[] {
	const errors: Partial<Record<InputEntryErrors, boolean>> = {};

	// Check whether startTime is valid or not.
	if ("startTime" in entry) {
		Object.assign(errors, {
			[InputEntryErrors.START_TIME_INVALID]: !entry.startTime,
			[InputEntryErrors.START_IN_FUTURE]: !!entry.startTime && entry.startTime.getTime() > new Date().getTime(),
		});
	}

	// Check whether endTime is valid or not.
	if ("endTime" in entry) {
		Object.assign(errors, {
			[InputEntryErrors.END_TIME_INVALID]: !entry.endTime,
			[InputEntryErrors.END_IN_FUTURE]: !!entry.endTime && entry.endTime.getTime() > new Date().getTime(),
		});
	}

	// Check whether there's an error where startTime isn't preceeding the endTime.
	if ("startTime" in entry && "endTime" in entry) {
		const startAfterEnd =
			!!entry.startTime && !!entry.endTime && entry.startTime.getTime() > entry.endTime.getTime();

		Object.assign(errors, {
			[InputEntryErrors.START_AFTER_END]: startAfterEnd,
		});
	}

	// Check whether the duration is valid or not.
	if ("duration" in entry) {
		Object.assign(errors, {
			[InputEntryErrors.DURATION_INVALID]: !entry.duration,
		});
	}

	// Note that duration can be given directly or calculated from given startTime and endTime
	if ("duration" in entry || ("startTime" in entry && "endTime" in entry)) {
		const duration = entry.duration || getDurationBetweenDates(entry.startTime, entry.endTime);
		const durationInSecond = durationStringToSecond(duration);
		Object.assign(errors, {
			[InputEntryErrors.DURATION_TOO_LONG]: durationInSecond > MAX_DURATION_IN_SEC,
			[InputEntryErrors.DURATION_IS_NOT_POSITIVE]: durationInSecond <= 0,
		});
	}

	// Check whether activity is filled
	if ("activityKey" in entry) {
		Object.assign(errors, {[InputEntryErrors.ACTIVITY_IS_MANDATORY]: !entry.activityKey});
	}

	// Check whether client (represented by treatmentId) is mandatory and filled
	if ("activityKey" in entry && "typeKey" in entry) {
		let clientMandatoryError = false;
		if (!!entry.activityKey && !!entry.typeKey) {
			// Note that, in the activity config object,
			// the types is a "map" with typeKey as the key and
			// a boolean value. The boolean value is what determines
			// whether the activity must be tied to a client or not.
			const selectedActivity = activities[entry.activityKey];
			if (selectedActivity.types[entry.typeKey] && !entry.treatmentId) {
				clientMandatoryError = true;
			}
		}

		Object.assign(errors, {[InputEntryErrors.CLIENT_IS_MANDATORY]: clientMandatoryError});
	}

	return (Object.keys(errors) as InputEntryErrors[]).filter((key) => errors[key]);
}

/**
 * Part of the requirement of time tracking feature,
 * is to never have any entry overlapping with each other.
 * This is a logic that'll check whether a given entry,
 * has overlap with any entry in the given list.
 */
export const checkHasOverlap = (list: TimeTrackingEntry[], entry: TimeTrackingEntry) =>
	!!entry.startTime &&
	!!entry.endTime &&
	list.some(
		(e) =>
			e.id !== entry.id &&
			!!e.startTime &&
			!!e.endTime &&
			e.startTime.getTime() < entry.endTime.getTime() &&
			entry.startTime.getTime() < e.endTime.getTime(),
	);

interface DirtyMap {
	startTime: boolean;
	endTime: boolean;
	duration: boolean;
	activityKey: boolean;
}

/**
 * This function returns all the error details of given time tracking entry values.
 */
export const getErrorDetailsOfATimeTrackingEntry = (
	dirty: DirtyMap,
	startTime: Date,
	endTime: Date,
	duration: string,
	activity: ActivityKey,
	activityType: ActivityTypeKey,
	treatmentId?: string,
) => {
	const entryToBeValidated: Partial<TimeTrackingEntryInput> = {
		typeKey: activityType,
		treatmentId,
	};

	if (dirty.startTime || !!startTime) {
		entryToBeValidated.startTime = startTime;
	}

	if (dirty.endTime || !!endTime) {
		entryToBeValidated.endTime = endTime;
	}

	if (dirty.duration || !!duration) {
		entryToBeValidated.duration = duration;
	}

	if (dirty.activityKey || !!activity) {
		entryToBeValidated.activityKey = activity;
	}

	const errors = getErrorsOfATimeTrackingEntry(entryToBeValidated);

	// The flag that determine whether there's any error or not.
	const hasError = checkHasErrors(errors);

	return {errors, hasError};
};

const loc: ILocalization = Localization;
export const getErrorMessage = (errors: InputEntryErrors[], hasOverlap: boolean, failedToBeUpdated?: boolean) => {
	const errorStr = {
		[InputEntryErrors.START_TIME_INVALID]: strTranslation.TIME_TRACKING.error.start_time_invalid,
		[InputEntryErrors.END_TIME_INVALID]: strTranslation.TIME_TRACKING.error.end_time_invalid,
		[InputEntryErrors.DURATION_INVALID]: strTranslation.TIME_TRACKING.error.duration_invalid,
		[InputEntryErrors.START_AFTER_END]: strTranslation.TIME_TRACKING.error.start_after_end,
		[InputEntryErrors.START_IN_FUTURE]: strTranslation.TIME_TRACKING.error.start_time_future,
		[InputEntryErrors.END_IN_FUTURE]: strTranslation.TIME_TRACKING.error.end_time_future,
		[InputEntryErrors.CLIENT_IS_MANDATORY]: strTranslation.TIME_TRACKING.error.client_is_mandatory,
		[InputEntryErrors.ACTIVITY_IS_MANDATORY]: strTranslation.TIME_TRACKING.error.activity_is_mandatory,
		[InputEntryErrors.DURATION_TOO_LONG]: strTranslation.TIME_TRACKING.error.duration_too_long,
		[InputEntryErrors.DURATION_IS_NOT_POSITIVE]: strTranslation.TIME_TRACKING.error.duration_is_not_positive,
	};
	if (errors.length > 0) return loc.formatMessage(errorStr[errors[0]]);
	if (hasOverlap) return loc.formatMessage(strTranslation.TIME_TRACKING.error.time_overlaps);
	if (failedToBeUpdated) return loc.formatMessage(strTranslation.TIME_TRACKING.error.fail_to_update_entry);

	return loc.formatMessage(strTranslation.TIME_TRACKING.error.default);
};
