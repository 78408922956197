import {Activity, ActivityKey, ActivityKeysMapping, ActivityType, ActivityTypeKey} from "./timeTrackingTypes";
import Localization, {ILocalization} from "../localization/Localization";
import strTranslation from "../assets/lang/strings";

const loc: ILocalization = Localization;

export function getActivityName(activity: ActivityKey) {
	if (activity in ActivityKeysMapping) return loc.formatMessage(`TIME_TRACKING.activity.${activity}`);

	const trimmedActivity = activity.split("#")[0];
	return loc.formatMessage(`TIME_TRACKING.dropdown.activity.${trimmedActivity}`);
}

function getActivityTypeName(activityTypeKey: ActivityTypeKey) {
	// We rely on the fact that translation keys for activity types contain members of `ActivityTypeKey`.
	const translationString = {
		[ActivityTypeKey.ADMINISTRATION]: strTranslation.TIME_TRACKING.type.Administration,
		[ActivityTypeKey.CARE_DIRECT]: strTranslation.TIME_TRACKING.type.CareDirect,
		[ActivityTypeKey.CARE_INDIRECT]: strTranslation.TIME_TRACKING.type.CareIndirect,
		[ActivityTypeKey.CONTACT_WITH_COLLEAGUES]: strTranslation.TIME_TRACKING.type.ContactWithColleagues,
		[ActivityTypeKey.OTHER]: strTranslation.TIME_TRACKING.type.Other,
	};
	const translationKey = translationString[activityTypeKey];
	return loc.formatMessage(translationKey);
}

/**
 * Activity type literals (concrete type values)
 */
export const activityTypes: Record<ActivityTypeKey, ActivityType> = {
	[ActivityTypeKey.CARE_DIRECT]: {
		key: ActivityTypeKey.CARE_DIRECT,
		name: getActivityTypeName(ActivityTypeKey.CARE_DIRECT),
	},
	[ActivityTypeKey.CARE_INDIRECT]: {
		key: ActivityTypeKey.CARE_INDIRECT,
		name: getActivityTypeName(ActivityTypeKey.CARE_INDIRECT),
	},
	[ActivityTypeKey.ADMINISTRATION]: {
		key: ActivityTypeKey.ADMINISTRATION,
		name: getActivityTypeName(ActivityTypeKey.ADMINISTRATION),
	},
	[ActivityTypeKey.CONTACT_WITH_COLLEAGUES]: {
		key: ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
		name: getActivityTypeName(ActivityTypeKey.CONTACT_WITH_COLLEAGUES),
	},
	[ActivityTypeKey.OTHER]: {key: ActivityTypeKey.OTHER, name: getActivityTypeName(ActivityTypeKey.OTHER)},
};

/**
 * Activity literals, used to track how much time a therapist spent on each activity.
 * FIXME: at the MVP-2 stage all the entries from `ActivityKey` will be in use,
 * FIXME: hence please replace `Partial<Record<ActivityKey, Activity>>`
 * FIXME: with `Record<ActivityKey, Activity>`.
 * FIXME: . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
 * FIXME: Once (Auto) Time Tracking feature will have been completely defined and implemented,
 * FIXME: check whether the configuration object below can be simplified / optimised.
 * FIXME: particularly, `showClientList` per `typeKey` can be defined only once outside the object
 * FIXME: and then referred in the object. (@leo, @agung)
 */

export const activities: Partial<Record<ActivityKey, Activity>> = {
	[ActivityKey.ADMIN_TIME]: {
		name: getActivityName(ActivityKey.ADMIN_TIME),
		key: ActivityKey.ADMIN_TIME,
		types: {[ActivityTypeKey.ADMINISTRATION]: false},
		defaultType: ActivityTypeKey.ADMINISTRATION,
	},
	[ActivityKey.SESSION]: {
		name: getActivityName(ActivityKey.SESSION),
		key: ActivityKey.SESSION,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},
	// chat with a client
	[ActivityKey.CHAT]: {
		name: getActivityName(ActivityKey.CHAT),
		key: ActivityKey.CHAT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.FACE_TO_FACE_SESSION]: {
		name: getActivityName(ActivityKey.FACE_TO_FACE_SESSION),
		key: ActivityKey.FACE_TO_FACE_SESSION,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.CALL_WITH_COLLEAGUE]: {
		name: getActivityName(ActivityKey.CALL_WITH_COLLEAGUE),
		key: ActivityKey.CALL_WITH_COLLEAGUE,
		types: {
			[ActivityTypeKey.CONTACT_WITH_COLLEAGUES]: false,
			[ActivityTypeKey.CARE_INDIRECT]: true,
			[ActivityTypeKey.OTHER]: false,
		},
		defaultType: ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	},

	[ActivityKey.CHAT_WITH_COLLEAGUE]: {
		name: getActivityName(ActivityKey.CHAT_WITH_COLLEAGUE),
		key: ActivityKey.CHAT_WITH_COLLEAGUE,
		types: {
			[ActivityTypeKey.CONTACT_WITH_COLLEAGUES]: false,
			[ActivityTypeKey.CARE_INDIRECT]: true,
			[ActivityTypeKey.OTHER]: false,
		},
		defaultType: ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	},

	[ActivityKey.INTAKE_DIAGNOSTICS]: {
		name: getActivityName(ActivityKey.INTAKE_DIAGNOSTICS),
		key: ActivityKey.INTAKE_DIAGNOSTICS,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.DIAGNOSTIC_INTAKE]: {
		name: getActivityName(ActivityKey.DIAGNOSTIC_INTAKE),
		key: ActivityKey.DIAGNOSTIC_INTAKE,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.TREATMENT_CONSULT]: {
		name: getActivityName(ActivityKey.TREATMENT_CONSULT),
		key: ActivityKey.TREATMENT_CONSULT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.ASYNC_CONTACT]: {
		name: getActivityName(ActivityKey.ASYNC_CONTACT),
		key: ActivityKey.ASYNC_CONTACT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.DIRECT_TIME_MISC]: {
		name: getActivityName(ActivityKey.DIRECT_TIME_MISC),
		key: ActivityKey.DIRECT_TIME_MISC,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.ONLINE_INTAKE]: {
		name: getActivityName(ActivityKey.ONLINE_INTAKE),
		key: ActivityKey.ONLINE_INTAKE,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.PHONE_CALL_INTAKE]: {
		name: getActivityName(ActivityKey.PHONE_CALL_INTAKE),
		key: ActivityKey.PHONE_CALL_INTAKE,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.FACE_TO_FACE_INTAKE]: {
		name: getActivityName(ActivityKey.FACE_TO_FACE_INTAKE),
		key: ActivityKey.FACE_TO_FACE_INTAKE,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.ONLINE_CONSULT]: {
		name: getActivityName(ActivityKey.ONLINE_CONSULT),
		key: ActivityKey.ONLINE_CONSULT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.PHONE_CONSULT]: {
		name: getActivityName(ActivityKey.PHONE_CONSULT),
		key: ActivityKey.PHONE_CONSULT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.FACE_TO_FACE_CONSULT]: {
		name: getActivityName(ActivityKey.FACE_TO_FACE_CONSULT),
		key: ActivityKey.FACE_TO_FACE_CONSULT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.FACE_TO_FACE_COLLEAGUE_CLIENT]: {
		name: getActivityName(ActivityKey.FACE_TO_FACE_COLLEAGUE_CLIENT),
		key: ActivityKey.FACE_TO_FACE_COLLEAGUE_CLIENT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true, [ActivityTypeKey.CARE_INDIRECT]: true},
		defaultType: ActivityTypeKey.CARE_INDIRECT,
	},

	[ActivityKey.CALL_COLLEAGUE_CLIENT]: {
		name: getActivityName(ActivityKey.CALL_COLLEAGUE_CLIENT),
		key: ActivityKey.CALL_COLLEAGUE_CLIENT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true, [ActivityTypeKey.CARE_INDIRECT]: true},
		defaultType: ActivityTypeKey.CARE_INDIRECT,
	},

	[ActivityKey.CHAT_COLLEAGUE_CLIENT]: {
		name: getActivityName(ActivityKey.CHAT_COLLEAGUE_CLIENT),
		key: ActivityKey.CHAT_COLLEAGUE_CLIENT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true, [ActivityTypeKey.CARE_INDIRECT]: true},
		defaultType: ActivityTypeKey.CARE_INDIRECT,
	},

	[ActivityKey.DIRECT_TIME_ASYNC]: {
		name: getActivityName(ActivityKey.DIRECT_TIME_ASYNC),
		key: ActivityKey.DIRECT_TIME_ASYNC,
		types: {[ActivityTypeKey.CARE_DIRECT]: true, [ActivityTypeKey.CARE_INDIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.RTMS]: {
		name: getActivityName(ActivityKey.RTMS),
		key: ActivityKey.RTMS,
		types: {[ActivityTypeKey.CARE_DIRECT]: true, [ActivityTypeKey.CARE_INDIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.MISC]: {
		name: getActivityName(ActivityKey.MISC),
		key: ActivityKey.MISC,
		types: {[ActivityTypeKey.CARE_DIRECT]: true, [ActivityTypeKey.CARE_INDIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	[ActivityKey.DIRECT_TIME_MISC_OPT]: {
		name: getActivityName(ActivityKey.DIRECT_TIME_MISC_OPT),
		key: ActivityKey.DIRECT_TIME_MISC_OPT,
		types: {[ActivityTypeKey.CARE_DIRECT]: true, [ActivityTypeKey.CARE_INDIRECT]: true},
		defaultType: ActivityTypeKey.CARE_DIRECT,
	},

	/* The rest of the activities will be used in the MVP-2 of Time Tracking,
	 * they are not needed for the MVP-1, hence commented out for now (01-10-2021)
	 *************************************************************************************************/

	//FIXME: When it's time to work on the MVP-2, the commented entries below should be
	//FIXME: updated according to the tables under "MVP #2 (automatic time tracking)":
	//FIXME: @See https://paper.dropbox.com/doc/Time-tracking-Activitytypecontact-dropdown-relationship--BTchE35VAlXo73E3OR6oFtS8Ag-ZeABy2aLiGYIDWkg5NFSp#:uid=769089746868680182353488&h2=MVP-#2-(automatic-time-trackin

	// [ActivityKey.READ_REGISTRATION]: {
	// 	name: getActivityName(ActivityKey.READ_REGISTRATION),
	// 	key: ActivityKey.READ_REGISTRATION,
	// 	types: [
	// 		ActivityTypeKey.CARE_DIRECT,
	// 		ActivityTypeKey.CARE_INDIRECT,
	// 		ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	// 		ActivityTypeKey.ADMINISTRATION,
	// 		ActivityTypeKey.OTHER,
	// 	],
	// },
	//
	// [ActivityKey.WRITE_REGISTRATION]: {
	// 	name: getActivityName(ActivityKey.WRITE_REGISTRATION),
	// 	key: ActivityKey.WRITE_REGISTRATION,
	// 	types: [
	// 		ActivityTypeKey.CARE_DIRECT,
	// 		ActivityTypeKey.CARE_INDIRECT,
	// 		ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	// 		ActivityTypeKey.ADMINISTRATION,
	// 		ActivityTypeKey.OTHER,
	// 	],
	// },
	// [ActivityKey.READ_CLIENT_PAGE]: {
	// 	name: getActivityName(ActivityKey.READ_CLIENT_PAGE),
	// 	key: ActivityKey.READ_CLIENT_PAGE,
	// 	types: [
	// 		ActivityTypeKey.CARE_DIRECT,
	// 		ActivityTypeKey.CARE_INDIRECT,
	// 		ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	// 		ActivityTypeKey.ADMINISTRATION,
	// 		ActivityTypeKey.OTHER,
	// 	],
	// },
	//
	// [ActivityKey.VISIT_COLLEAGUE_PROFILE]: {
	// 	name: getActivityName(ActivityKey.VISIT_COLLEAGUE_PROFILE),
	// 	key: ActivityKey.VISIT_COLLEAGUE_PROFILE,
	// 	types: [
	// 		ActivityTypeKey.CARE_DIRECT,
	// 		ActivityTypeKey.CARE_INDIRECT,
	// 		ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	// 		ActivityTypeKey.ADMINISTRATION,
	// 		ActivityTypeKey.OTHER,
	// 	],
	// },
	//
	// [ActivityKey.BROWSE_MESSAGE_INBOX]: {
	// 	name: getActivityName(ActivityKey.BROWSE_MESSAGE_INBOX),
	// 	key: ActivityKey.BROWSE_MESSAGE_INBOX,
	// 	types: [
	// 		ActivityTypeKey.CARE_DIRECT,
	// 		ActivityTypeKey.CARE_INDIRECT,
	// 		ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	// 		ActivityTypeKey.ADMINISTRATION,
	// 		ActivityTypeKey.OTHER,
	// 	],
	// },
	// [ActivityKey.BROWSE_LIBRARY]: {
	// 	name: getActivityName(ActivityKey.BROWSE_LIBRARY),
	// 	key: ActivityKey.BROWSE_LIBRARY,
	// 	types: [
	// 		ActivityTypeKey.CARE_DIRECT,
	// 		ActivityTypeKey.CARE_INDIRECT,
	// 		ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	// 		ActivityTypeKey.ADMINISTRATION,
	// 		ActivityTypeKey.OTHER,
	// 	],
	// },
	//
	// [ActivityKey.EDIT_PROFILE]: {
	// 	name: getActivityName(ActivityKey.EDIT_PROFILE),
	// 	key: ActivityKey.EDIT_PROFILE,
	// 	types: [
	// 		ActivityTypeKey.CARE_DIRECT,
	// 		ActivityTypeKey.CARE_INDIRECT,
	// 		ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	// 		ActivityTypeKey.ADMINISTRATION,
	// 		ActivityTypeKey.OTHER,
	// 	],
	// },
	//
	// [ActivityKey.WRITE_TO_GP]: {
	// 	name: getActivityName(ActivityKey.WRITE_TO_GP),
	// 	key: ActivityKey.WRITE_TO_GP,
	// 	types: [
	// 		ActivityTypeKey.CARE_DIRECT,
	// 		ActivityTypeKey.CARE_INDIRECT,
	// 		ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	// 		ActivityTypeKey.ADMINISTRATION,
	// 		ActivityTypeKey.OTHER,
	// 	],
	// },
	//
	// [ActivityKey.OTHER]: {
	// 	name: getActivityName(ActivityKey.OTHER),
	// 	key: ActivityKey.OTHER,
	// 	types: [
	// 		ActivityTypeKey.CARE_DIRECT,
	// 		ActivityTypeKey.CARE_INDIRECT,
	// 		ActivityTypeKey.CONTACT_WITH_COLLEAGUES,
	// 		ActivityTypeKey.ADMINISTRATION,
	// 		ActivityTypeKey.OTHER,
	// 	],
	// },
};
