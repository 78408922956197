import {createTheme, Theme} from "@mui/material";
import {NDColors} from "../material-ui/NDColors";
import {muiButtonStyle} from "./button/muiButtonStyle";

export const ndTheme: Theme = createTheme({
	palette: {
		primary: {
			main: NDColors.BLURPLE,
		},
		error: {
			main: NDColors.ERROR,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: muiButtonStyle,
		},
	},
});
