import {TerminationReason} from "@sense-os/goalie-js";
import {DISC} from "IoC/DISC";
import {put, takeEvery, select, call} from "redux-saga/effects";
import {getActiveCall} from "redux/videoCall/VideoCallSelectors";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {getType} from "typesafe-actions";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import chatSDK from "../../chat/sdk";
import {callActions} from "../redux/callActions";

function* rejectCall() {
	const authUser: AuthUser = yield select(getAuthUser);
	const activeCall: ActiveCall = yield select(getActiveCall);

	// Set leave time for logged in user
	yield put(callActions.userLeaveCall(authUser.id, Date.now()));
	// Send rejection signal
	yield call(chatSDK.rejectCall, activeCall.roomId);
	// Send call summary
	yield call(DISC.getVideoCallService().sendCallSummary, TerminationReason.Rejected);
	// Reset the call
	yield call(DISC.getVideoCallService().reset);
}

export function* rejectCallSaga() {
	yield takeEvery(getType(callActions.rejectCall), rejectCall);
}
