import {takeEvery, call, put, select, fork} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {callActions} from "../../redux/callActions";
import {getAuthUser} from "../../../auth/redux";
import {CallStatus} from "@sense-os/goalie-js";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {setCallState} from "../../helpers/callHelpers";
import {States} from "services/chat/video/States";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {CallDirection} from "services/chat/video/CallDirection";
import {DISC} from "IoC/DISC";

const log = createLogger("initiationCallSaga", SentryTags.VideoCall);

function* handleInitiationSignal(action: ActionType<typeof callActions.handleInitiationSignal>) {
	const {signal} = action.payload;

	if (signal.callStatus === CallStatus.Cancelled) {
		return;
	}

	const authUser = yield select(getAuthUser);

	if (signal.recipientId !== authUser.id) {
		log.addBreadcrumb({message: "Initiation to other party"});
		// Signal is sent for other participants. We just need to update the redux state.
		yield put(callActions.userInitiateCall(signal.recipientId, Date.now(), signal.deviceInformation));
	} else {
		log.addBreadcrumb({message: "User is reacting to a call"});
		yield call(setCallState, States.REACTING_TO_CALL);
		const activeCall: ActiveCall = {
			roomId: signal.roomId,
			direction: CallDirection.INCOMING,
			initiatorUserId: signal.senderId,
			type: signal.callType,
			isConferenceCall: signal.isConferenceCall,
			participantMap: {},
		};
		yield put(callActions.createActiveCall(activeCall));
	}

	yield fork(DISC.getVideoCallService().updateActiveCallParticipants);
}

export function* handleInitiationSignalSaga() {
	yield takeEvery(getType(callActions.handleInitiationSignal), handleInitiationSignal);
}
