import {getType} from "typesafe-actions";

import {LoadingState} from "constants/redux";
import {inactiveClientActions, InactiveClientAction} from "./inactiveClientActions";
import {InactiveClientState, SortKey, FIRST_PAGE_NUMBER, MAX_ITEM_PER_PAGE} from "../inactiveClientTypes";

export const initialState: InactiveClientState = {
	currentPage: FIRST_PAGE_NUMBER,
	maxItemPerPage: MAX_ITEM_PER_PAGE,
	loadingInactiveClients: LoadingState.EMPTY,
	inactiveClientsMap: {},
	lastRegistrationMap: {},
	sortByState: {key: SortKey.LATEST_REGISTRATION, asc: true},
};

export function inactiveClientReducer(
	state: InactiveClientState = initialState,
	action: InactiveClientAction,
): InactiveClientState {
	switch (action.type) {
		case getType(inactiveClientActions.loadInactiveClients.request):
			return {
				...state,
				loadingInactiveClients: LoadingState.LOADING,
				currentPage: FIRST_PAGE_NUMBER,
			};
		/** Stop loading indicator and store inactive `clientsMap` */
		case getType(inactiveClientActions.loadInactiveClients.success): {
			const newInactiveClientsMap = action.payload.clients.reduce((clientsMap, client) => {
				clientsMap[client.contact.id] = client;
				return clientsMap;
			}, {});

			const lastRegistrationMap = {};
			action.payload.lastRegistration.forEach((client) => {
				lastRegistrationMap[client.contact.id] = client;
			}, {});

			return {
				...state,
				inactiveClientsMap: newInactiveClientsMap,
				lastRegistrationMap,
				loadingInactiveClients: LoadingState.LOADED,
			};
		}
		/** Stop loading indicator */
		case getType(inactiveClientActions.loadInactiveClients.failure): {
			return {
				...state,
				loadingInactiveClients: LoadingState.ERROR,
			};
		}
		case getType(inactiveClientActions.applyDataSort): {
			return {...state, sortByState: {...action.payload.state}};
		}
		/** Add page number by 1 */
		case getType(inactiveClientActions.incrementPage): {
			return {...state, currentPage: state.currentPage + 1};
		}
		/** Subtract page number by 1 */
		case getType(inactiveClientActions.decrementPage): {
			return {...state, currentPage: state.currentPage - 1};
		}

		default:
			return state;
	}
}
