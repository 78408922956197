import {call, put, takeEvery} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {referralClientActions} from "../redux/referralClientAction";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import ReferralClientSDK from "../helper/referralClient";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {IReferralTherapyTypeResponse, LanguageTag} from "@sense-os/goalie-js";
import localization from "../../localization/Localization";

const log = createLogger("fetchReferralTherapyType", SentryTags.ReferralClient);

export function* fetchReferralTherapyType() {
	const token = yield call(getSessionId);
	const activeLanguage: LanguageTag = localization.getLocale();
	try {
		const response: IReferralTherapyTypeResponse = yield apiCallSaga(
			ReferralClientSDK.getReferralTherapyType,
			token,
			activeLanguage,
			{pageSize: 100},
		);

		yield put(
			referralClientActions.fetchReferralTherapyType.success({
				data: response.results,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(referralClientActions.fetchReferralTherapyType.failure());
	}
}

export default function* () {
	yield takeEvery(getType(referralClientActions.fetchReferralTherapyType.request), fetchReferralTherapyType);
}
