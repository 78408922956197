import {Message} from "@sense-os/goalie-js";

/**
 * Currently, there's a bug that can create an invalid summary for group call.
 * This invalid call summary, can't be properly parsed by portal.
 * As the invalid summary is already stored in database, all we can do in front end level,
 * is to ignore this call summary.
 *
 * This function is about checking whether a message is an invalid call summary.
 */
export function isInvalidCallSummary(message: Message): boolean {
	// If it isn't a call summary, it is not an invalid call summary
	if (message.content.GROUPCALL === undefined) {
		return false;
	}

	const senderId = message.from;
	const recipientId = message.to;
	const participantsData = message.content.GROUPCALL.participants;

	// A valid call summary between two users, is only created when at least one of the user left the call.
	// A valid call summary, should always contain initiatedTime of all users.

	return (
		participantsData === undefined ||
		participantsData[senderId] === undefined ||
		participantsData[recipientId] === undefined ||
		participantsData[senderId].initiatedTime === undefined ||
		participantsData[recipientId].initiatedTime === undefined
	);
}

/**
 * Check whether these two messages is the same.
 */
export function isSameMessage(firstMessage: Message, secondMessage: Message): boolean {
	const isSameMessageId: boolean = firstMessage.id === secondMessage.id;

	if (isSameMessageId) {
		return true;
	}

	const isBothLegacyCallSummary: boolean =
		firstMessage.content.CALL !== undefined && secondMessage.content.CALL !== undefined;
	const isBothGroupCallSummary: boolean =
		firstMessage.content.GROUPCALL !== undefined && secondMessage.content.GROUPCALL !== undefined;

	/** Legacy call summary considered to be a same messages if they point to same roomId */
	if (isBothLegacyCallSummary) {
		return firstMessage.content.CALL.roomId === secondMessage.content.CALL.roomId;
	}

	// For group video call, chances are there will be multiple call summaries
	// with same roomId. This because there might be a case where user can join, leave, and join again
	// in the same room. Thus we also need to check, not only the roomId, but also the initiated time
	// of localuser and counterpart. This will create a unique combination and will prevent message duplication.
	if (isBothGroupCallSummary) {
		/** If either of them is invalid call summary, let's report them as not duplicate */
		if (isInvalidCallSummary(firstMessage) || isInvalidCallSummary(secondMessage)) {
			return false;
		}

		const isSameRoomId: boolean = firstMessage.content.GROUPCALL.roomId === secondMessage.content.GROUPCALL.roomId;

		// Check if initiated time between two messages are the same,
		// and only check local user initiated time and counterpart initiated time,
		// where both are represented as senderId and receiverId.
		const senderId = firstMessage.from;
		const receiverId = firstMessage.to;
		const isSameInitiatedTime: boolean =
			firstMessage.content.GROUPCALL.participants[senderId].initiatedTime ===
				secondMessage.content.GROUPCALL.participants[senderId].initiatedTime &&
			firstMessage.content.GROUPCALL.participants[receiverId].initiatedTime ===
				secondMessage.content.GROUPCALL.participants[receiverId].initiatedTime;

		return isSameRoomId && isSameInitiatedTime;
	}

	return false;
}

/**
 * Add a collection of messages to another collection of messages,
 * while guaranteeing uniqueness and sorted ordering.
 */
export function mergeMessages(currentMessages: Message[], newMessages: Message[]) {
	return newMessages
		.filter((msg) => !isInvalidCallSummary(msg))
		.reduce(
			(accum: Message[], msg: Message) => {
				if (accum.some((m) => isSameMessage(m, msg))) {
					return accum;
				}

				accum.push(msg);
				return accum;
			},
			[...currentMessages],
		)
		.sort((firstMsg, secondMsg) => firstMsg.timestamp - secondMsg.timestamp);
}
