import {TreatmentComplaint, TreatmentPersonnelRole, TreatmentType} from "@sense-os/goalie-js";
import moment from "moment";

import Localization from "../localization/Localization";
import {getDayDiff} from "utils/time";
import {EndTreatmentReason, Treatment, TreatmentProgress} from "./treatmentStatusTypes";
import strTranslation from "../assets/lang/strings";
import {Contact} from "../contacts/contactTypes";
import {UserMapChatRoomState} from "../chat/redux/ChatRoomReducer";
import {getLatestInteractionTime} from "../chat/helpers/chatRoomUtils";
import {SessionDataSource} from "../intervention/session/InterventionSessionType";

/**
 * As time goes, the progress of a treatment can changes.
 * This helpers will help portal label the current state of the treatment,
 * based on the starting and ending date of the treatment.
 */
export const getTreatmentProgress = (treatment?: Treatment): TreatmentProgress => {
	if (!treatment) return TreatmentProgress.NOT_IN_TREATMENT_YET;

	const {startTime: startDate, terminatingReason: endTreatmentReason} = treatment;
	if (!startDate) return TreatmentProgress.NOT_IN_TREATMENT_YET;

	if (endTreatmentReason?.value) {
		switch (endTreatmentReason.value) {
			case EndTreatmentReason.COMPLETED:
				return TreatmentProgress.COMPLETED;
			case EndTreatmentReason.DROPOUT:
				return TreatmentProgress.DROPOUT;
			case EndTreatmentReason.INSUFFICIENT:
				return TreatmentProgress.INSUFFICIENT;
			case EndTreatmentReason.STOPPED_ON_CLIENT:
				return TreatmentProgress.STOPPED_ON_CLIENT;
			case EndTreatmentReason.STOPPED_ON_THERAPIST:
				return TreatmentProgress.STOPPED_ON_THERAPIST;
			default:
				return TreatmentProgress.COMPLETED;
		}
	}

	const now = moment();
	const startDateInMoment = moment(startDate);
	const dayPassedSinceStart = getDayDiff(now, startDateInMoment);

	if (dayPassedSinceStart < 0) return TreatmentProgress.PLANNED;
	return TreatmentProgress.IN_PROGRESS;
};

/**
 * This function will translate each public treatment types to its translation.
 */
export const translateTreatmentTypes = (treatmentTypes: TreatmentType[]) => {
	return treatmentTypes.map((treatmentType) => {
		return {
			...treatmentType,
			value: treatmentType.isPrivate
				? treatmentType.value
				: Localization.formatMessage(`TREATMENT_STATUS.treatment_type.tag.${treatmentType.value}`),
		};
	});
};

/**
 * This function will translate each public treatment complaints to its translation.
 */
export const translateTreatmentComplaints = (treatmentComplaints: TreatmentComplaint[]) => {
	return treatmentComplaints.map((treatmentComplaint) => {
		return {
			...treatmentComplaint,
			value: treatmentComplaint.isPrivate
				? treatmentComplaint.value
				: Localization.formatMessage(`TREATMENT_STATUS.treatment_complaint.tag.${treatmentComplaint.value}`),
		};
	});
};

export const getTherapistRoles = (): TreatmentPersonnelRole[] => {
	return [
		TreatmentPersonnelRole.THERAPIST,
		TreatmentPersonnelRole.SUPERVISING_THERAPIST,
		TreatmentPersonnelRole.PSYCHIATRIST,
		TreatmentPersonnelRole.ADMINISTRATOR,
	];
};

export const getTherapistRolesTranslation = (role: TreatmentPersonnelRole): string => {
	const therapistString = {
		[TreatmentPersonnelRole.THERAPIST]: strTranslation.TREATMENT_STATUS.therapist_role.therapist,
		[TreatmentPersonnelRole.SUPERVISING_THERAPIST]: strTranslation.TREATMENT_STATUS.therapist_role.supervisor,
		[TreatmentPersonnelRole.PSYCHIATRIST]: strTranslation.TREATMENT_STATUS.therapist_role.psychiatrist,
		[TreatmentPersonnelRole.ADMINISTRATOR]: strTranslation.TREATMENT_STATUS.therapist_role.administrator,
	};

	return Localization.formatMessage(therapistString[role]);
};

/**
 * The helper function to get a client list that both contains the clients
 * that are in the contacts of the therapists and the clients that is connected
 * to the therapists via treatment.
 */
export function getClientListByMergingContactsAndTreatments(
	treatments: Array<Treatment & {userId: number}>,
	contacts: Contact[],
	chatState?: UserMapChatRoomState,
	sessionsState: {
		[key: string]: SessionDataSource[];
	} = {},
) {
	/**
	 * This map is a helper object to guarantee that each client
	 * of the resulting merged list are unique.
	 * The key refers to userId of the client, and the value
	 * refers to its index in the mergedList.
	 */
	const existenceMap: Record<number, number> = {};
	const mergedList: Array<{
		userId: number;
		name: string;
		treatment?: Treatment & {userId: number};
		contact?: Contact;
		lastContact?: number;
		weeks4?: boolean;
		sessionsCompleted?: number;
		sessionsPlanned?: number;
		dropped?: boolean;
	}> = [];

	contacts.forEach((contact) => {
		existenceMap[contact.id] = mergedList.length;
		const lastContact = chatState ? getLatestInteractionTime(contact.id, chatState) : null;
		const weeks4 = !!lastContact && moment(lastContact).diff(moment(), "weeks") < -4;
		const today = new Date();
		const completed = (sessionsState[contact.id] || []).filter(
			(session) => session.value.plannedFor < today,
		).length;
		const planned = (sessionsState[contact.id] || []).filter((session) => session.value.plannedFor >= today).length;

		mergedList.push({
			userId: contact.id,
			name: contact.fullName,
			contact,
			lastContact,
			weeks4,
			sessionsCompleted: completed,
			sessionsPlanned: planned,
			dropped: weeks4 && planned === 0,
		});
	});

	treatments.forEach((treatment) => {
		if (existenceMap[treatment.patient] !== undefined) {
			mergedList[existenceMap[treatment.patient]].treatment = treatment;
		} else {
			existenceMap[treatment.patient] = mergedList.length;
			mergedList.push({
				userId: treatment.userId,
				name: treatment.patientName,
				treatment,
			});
		}
	});

	return mergedList;
}
