import {InterventionCustomTrackerListItem} from "@sense-os/goalie-js";
import {CustomTrackerEventView} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const interventionCustomTrackerActions = {
	fetchCustomTrackers: createAsyncAction(
		"interventionCustomTracker/fetchCustomTrackers",
		"interventionCustomTracker/fetchCustomTrackersSuccess",
		"interventionCustomTracker/fetchCustomTrackersFail",
	)<
		{hashId: string},
		{totalCustomTrackers: number; customTrackers: InterventionCustomTrackerListItem[]},
		{error: Error}
	>(),
	fetchRegisteredCustomTrackers: createAsyncAction(
		"interventionCustomTracker/fetchRegisteredCustomTrackers",
		"interventionCustomTracker/fetchRegisteredCustomTrackersSuccess",
		"interventionCustomTracker/fetchRegisteredCustomTrackersFail",
	)<
		{userId: number; sensorName: string},
		{registeredCustomTrackers: CustomTrackerEventView[]; totalRegisteredCustomTrackers: number},
		{error: Error}
	>(),
	enableOrDisableTrackers: createAsyncAction(
		"interventionCustomTracker/enableOrDisableTrackers",
		"interventionCustomTracker/enableOrDisableTrackersSuccess",
		"interventionCustomTracker/enableOrDisableTrackersFail",
	)<
		{userId: number; customTrackers: InterventionCustomTrackerListItem[]; trackerId: number; isEnabled: boolean},
		{customTrackers: InterventionCustomTrackerListItem[]},
		{error: Error}
	>(),
};

export type interventionCustomTrackerActionType = ActionType<typeof interventionCustomTrackerActions>;
