import {put, call, select, takeLeading} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser, authActions} from "../../auth/redux";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import profileSDK from "../helpers/profileSDK";
import {UserProfileAction} from "../redux/UserProfileAction";
import storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";
const log = createLogger("languagePreferenceSaga", SentryTags.UserProfile);

function* handleLanguagePreferenceChanges(
	action: ActionType<typeof UserProfileAction.saveUserLanguagePreference.request>,
) {
	const {language, forceReload} = action.payload;
	const authUser: AuthUser = yield select(getAuthUser);

	if (!authUser) {
		yield call(storage.write, StorageKeys.LOCALE, language);
		window.location.reload();
		return;
	}

	try {
		yield apiCallSaga(profileSDK.updateUserData, authUser.token, authUser.id, {
			preferredLanguage: language,
		});
		yield call(storage.write, StorageKeys.LOCALE, language);
		yield put(UserProfileAction.saveUserLanguagePreference.success());
		yield put(authActions.updateAuthUser({data: {preferredLanguage: language}}));

		if (forceReload) {
			window.location.reload();
		}
	} catch (err) {
		log.captureException(err);
		yield put(UserProfileAction.saveUserLanguagePreference.failure(err));
	}
}

export default function* languagePreferenceSaga() {
	yield takeLeading(getType(UserProfileAction.saveUserLanguagePreference.request), handleLanguagePreferenceChanges);
}
