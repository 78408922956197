import {call, takeEvery, put} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {SensorData as BaseSensorData} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {toastActions} from "../../../toaster/redux/toastAction";
import strTranslation from "../../../assets/lang/strings";
import {getOmqSmqSensorDataPayload, saveOmqSmq} from "../helpers/omqSmqHelpers";
import {assessmentActions} from "../redux/assessmentActions";

const log = createLogger("saveOmqSmqSaga", SentryTags.Assessment);

function* handleSaveOmqSmq(action: ActionType<typeof assessmentActions.saveOmqSmq.request>) {
	const {formValues, userId, assessmentType} = action.payload;

	try {
		const omqSmqPayload: BaseSensorData<BasePlannedEventEntry> = yield call(
			getOmqSmqSensorDataPayload,
			formValues,
			assessmentType,
		);
		yield apiCallSaga(saveOmqSmq, omqSmqPayload, userId);
		yield put(assessmentActions.saveOmqSmq.success({userId, assessmentType}));
		yield put(
			toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.success.toast, type: "success"}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(assessmentActions.saveOmqSmq.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(assessmentActions.saveOmqSmq.request), handleSaveOmqSmq);
}
