import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {AgoraWindowAction} from "./types";

export const agoraActions = {
	// Open Agora window actions
	openAgoraWindow: createAsyncAction(
		"agora/openAgoraWindow",
		"agora/openAgoraWindowSucceed",
		"agora/openAgoraWindowFailed",
	)<{windowAction: AgoraWindowAction; meetingText?: string}, void, Error>(),

	// Close Agora window action
	closeAgoraWindow: createAction("agora/closeAgoraWindow")(),

	// Reset Agora window state action
	resetAgoraWindowState: createAction("agora/resetAgoraWindowState")(),

	// Open Agora invitation dialog action
	openInvitationDialog: createAction(
		"agora/openInvitationDialog",
		(invitationCreator?: string, invitationLink?: string) => ({invitationCreator, invitationLink}),
	)(),

	// Close Agora invitation dialog action
	closeInvitationDialog: createAction("agora/closeInvitationDialog")(),

	// Agora created meeting action
	userCreatedMeeting: createAction("agora/userCreatedMeeting", (createdLink: string) => ({createdLink}))(),

	// Agora meeting joined time action
	userJoinedMeeting: createAction("agora/userJoinedMeeting", (joinedTime: number) => ({joinedTime}))(),

	// Agora meeting leave time action
	userLeaveMeeting: createAction("agora/userLeaveMeeting", (leaveTime: number) => ({leaveTime}))(),
};

export type AgoraActionType = ActionType<typeof agoraActions>;
