import {call, put} from "redux-saga/effects";
import {CustomTrackerEventView, SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getSensorResolved} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {CustomTrackerSensor} from "../../../tracker/customTracker/customTrackerTypes";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {interventionCustomTrackerActions} from "../redux/interventionCustomTrackerActions";
import {transformSensorCustomTrackerIntoClientActivity} from "../../../clientActivity/helpers/sensorDataActivityHelpers";

const log = createLogger("fetchRegisteredCustomTrackers", SentryTags.Intervention);

export function* fetchRegisteredCustomTrackers(
	action: ActionType<typeof interventionCustomTrackerActions.fetchRegisteredCustomTrackers.request>,
) {
	const {userId, sensorName} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		const response: SensorDatum<CustomTrackerSensor>[] = yield apiCallSaga(
			getSensorResolved,
			token,
			userId,
			[sensorName],
			undefined,
			undefined,
		);

		const trackerEventView: CustomTrackerEventView[] = response.map(transformSensorCustomTrackerIntoClientActivity);

		const sortedTrackerEventView: CustomTrackerEventView[] = trackerEventView.sort((a, b) => {
			// sort by the latest date
			return b.startTime.valueOf() - a.startTime.valueOf();
		});

		yield put(
			interventionCustomTrackerActions.fetchRegisteredCustomTrackers.success({
				registeredCustomTrackers: sortedTrackerEventView,
				totalRegisteredCustomTrackers: sortedTrackerEventView.length,
			}),
		);
	} catch (error) {
		log.captureException(error);
		yield put(interventionCustomTrackerActions.fetchRegisteredCustomTrackers.failure({error}));
	}
}
