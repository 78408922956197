import moment from "moment";
import {PsychoEducationItem, TaskPostBodyRequest} from "@sense-os/goalie-js";
import {put} from "redux-saga/effects";
import {PsychoEducationFormValues} from "../psychoEducationTypes";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import psychoEducationSDK from "../helpers/psychoEducationSDK";
import {psychoEducationActions} from "../redux/psychoEducationActions";
import {CoreTrackerId} from "../../utils/coreTracker";
import taskSDK from "../../../taskRegistration/helpers/taskSDK";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import createLogger from "../../../logger/createLogger";

const log = createLogger("createPsychoEducationTaskSaga", SentryTags.PsychoEducation);
/**
 * Handle Create PsychoEducation Task
 * Flow:
 * 1. Create PsychoEducation, then get PsychoEducation id
 * 2. Create Task, then set `registration_id` by created PsychoEducation id
 */
export function* createPsychoEducationTask(params: {
	token: string;
	formValues: PsychoEducationFormValues;
	userId: number;
	reminderDateTime: Date;
}) {
	const {token, formValues, userId, reminderDateTime} = params;

	try {
		/** 1st Create PsychoEducation */
		// Create new psycho education
		const psychoEducationResponse: PsychoEducationItem = yield apiCallSaga(
			psychoEducationSDK.createPsychoEducation,
			token,
			{
				patient: userId,
			},
		);

		// Create new psycho education articles
		yield put(
			psychoEducationActions.saveArticles.request({
				psychoEducationId: psychoEducationResponse.id,
				articlesBody: formValues.articles,
			}),
		);

		/** 2nd Create Task */
		const creationTaskBody: TaskPostBodyRequest<any> = {
			title: formValues.title,
			description: formValues.description,
			patient: userId,
			tracker: CoreTrackerId.PSYCHOEDUCATION,
			expiresAt: moment(formValues.dueDate).toDate(),
			remindAt: reminderDateTime,
			registrationId: psychoEducationResponse.id,
		};

		yield apiCallSaga(taskSDK.createTask, token, creationTaskBody);
	} catch (error) {
		log.captureException(error);
	}
}
