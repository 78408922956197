import {isDocumentFile} from "./../helpers/fileSharingHelper";
import {FileTypeFilter} from "./../fileSharingTypes";
import {all, put, takeEvery} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {shouldReportToSentry} from "../../auth/helpers";
import {getSessionId} from "../../auth/helpers/authStorage";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {fileChatToFileItemParser} from "../helpers/fileSharingHelper";
import {fileSharingActions} from "../redux/fileSharingActions";
import fileSharingSDK from "./fileSharingSDK";
import {GetFilesChatResponse} from "@sense-os/goalie-js";

const log = createLogger("loadFilesSaga", SentryTags.FileSharing);

function* loadFile(action: ActionType<typeof fileSharingActions.loadFiles.request>) {
	try {
		let type = [];
		switch (action.payload.type) {
			case FileTypeFilter.MEDIA:
				type = ["jpg", "png"];
				break;
			case FileTypeFilter.DOCUMENTS:
				type = ["pdf"];
				break;
		}

		const {results: loadFileResult, links}: GetFilesChatResponse = yield apiCallSaga(
			fileSharingSDK.getFilesChat,
			getSessionId(),
			action.payload.userId,
			{
				ids: action.payload.ids || [],
				type,
				page: action.payload.page,
				pageSize: action.payload.pageSize,
			},
		);

		const fileItems = loadFileResult.map((item) => fileChatToFileItemParser(item));

		// request from files tab
		if (!action.payload.ids) {
			yield put(
				fileSharingActions.loadFiles.success({
					userId: action.payload.userId,
					hasMore: !!links.next,
					currentPage: action.payload.page,
					fileItems,
				}),
			);
		} else {
			// request from individual component, no need to mutate pagination properties.
			yield put(
				fileSharingActions.loadFiles.success({
					userId: action.payload.userId,
					fileItems,
				}),
			);
		}

		// Load thumbnails of images, pdf doesn't need thumbnail to be loaded.
		const loadBlobs = fileItems
			.filter((chatFile) => !isDocumentFile(chatFile))
			.map((chatFile) =>
				put(
					fileSharingActions.loadBlob.request({
						userId: action.payload.userId,
						chatFile,
						type: "thumb",
					}),
				),
			);

		yield all(loadBlobs);
	} catch (error) {
		if (shouldReportToSentry(error)) {
			log.captureException(error);
		}

		yield put(fileSharingActions.loadFiles.failure({userId: action.payload.userId, error}));
	}
}

export default function* () {
	yield takeEvery(fileSharingActions.loadFiles.request, loadFile);
}
