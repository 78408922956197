import {eventChannel} from "redux-saga";
import {cancel, cancelled, fork, put, select, take, takeEvery, takeLeading} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {subscribeToInterWindowCommunication, createSendMessageWithType} from "../../IWC/IWC";
import {WinMsgTypes} from "../../IWC/WinMsg";

import {privateNotesActions, PrivateNotesAction} from "../redux/privateNotesAction";
import {whenLoggedIn} from "../../auth/sagas/helper";
import {getPatientNotes} from "../redux/privateNotesSelector";
import {NoteState} from "../privateNotesTypes";
import {LoadingState} from "constants/redux";

/**
 * Any IWC message from video call window will be treated as an action,
 * that will be dispatched directly.
 */
function* subscribeToIwc(targetWindow: Window) {
	const chan = eventChannel<PrivateNotesAction>((emitter) => {
		return subscribeToInterWindowCommunication({
			targetWindow,
			onMessage: (e) => {
				if (e.type !== WinMsgTypes.PRIVATE_NOTE__MESSAGE) {
					return;
				}

				emitter(e.data);
			},
		});
	});

	try {
		while (true) {
			const incomingAction: PrivateNotesAction = yield take(chan);
			yield put(incomingAction);
		}
	} finally {
		if (yield cancelled()) {
			chan.close();
		}
	}
}

/**
 * This saga will handle an inquiry that's coming from video call window
 * about the state of client's note.
 */
function* inquiryClientNote(action: ActionType<typeof privateNotesActions.inquiryClientNote>) {
	const {clientId, clientHashId} = action.payload;
	const notes: NoteState = yield select(getPatientNotes(clientId));

	// If portal never fetch the data from BE, fetch it now.
	// Else, simply send the data to the call window.
	if (notes.fetchState !== LoadingState.LOADED && notes.fetchState !== LoadingState.LOADING) {
		yield put(privateNotesActions.fetchPrivateNote.request({clientId, clientHashId}));
	} else {
		yield put(privateNotesActions.broadcastClientNoteToCallWindow(clientId, notes));
	}
}

/**
 * Contain setup for IWC of private notes feature.
 *
 * There is the handler of broadcastClientNoteToCallWindow that will send
 * the action to video call window.
 *
 * Also, there's a listener for call window's inquiry of client's private notes.
 */
function* startListeningToIwc(action: ActionType<typeof privateNotesActions.startListeningToIwc>) {
	const sendIwc = createSendMessageWithType(action.payload.targetWindow)<PrivateNotesAction>(
		WinMsgTypes.PRIVATE_NOTE__MESSAGE,
	);

	yield takeEvery(
		getType(privateNotesActions.broadcastClientNoteToCallWindow),
		function* (action: ActionType<typeof privateNotesActions.broadcastClientNoteToCallWindow>) {
			sendIwc(action);
		},
	);

	yield takeEvery(getType(privateNotesActions.inquiryClientNote), inquiryClientNote);
	yield fork(subscribeToIwc, action.payload.targetWindow);
}

/**
 * This will make sure that this saga is tied to a specific call,
 * and the saga will be stopped whenever the call ends.
 */
function* privateNotesCallWindowSaga() {
	yield takeLeading(
		getType(privateNotesActions.startListeningToIwc),
		function* (action: ActionType<typeof privateNotesActions.startListeningToIwc>) {
			const task = yield fork(startListeningToIwc, action);
			yield take(getType(privateNotesActions.stopListeningToIwc));
			yield cancel(task);
		},
	);
}

export default function* () {
	yield fork(whenLoggedIn(privateNotesCallWindowSaga));
}
