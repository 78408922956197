import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import {clientNotePageActions} from "../redux/clientNoteActions";
import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import {getMeetingNoteSensors} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {MeetingNote, SensorDatum} from "redux/tracking/TrackingTypes";
import {isNiceDayBlogUrl, isValidUrl} from "../../helpers/url";
import {IMeetingNoteData, IUrlContent} from "../clientNotesTypes";

const log = createLogger("fetchClientNoteSaga");

export function* fetchClientNotes(action: ActionType<typeof clientNotePageActions.fetchNotes.request>) {
	const {userId} = action.payload;
	const token = yield call(getSessionId);
	try {
		const meetingNotes: SensorDatum<MeetingNote>[] = yield apiCallSaga(
			getMeetingNoteSensors,
			token,
			userId,
			undefined,
			undefined,
		);
		const noteWithUrlContent: IMeetingNoteData[] = meetingNotes.map((note) => {
			const splittedStr = note.value.note.replace(/^\s+|\s+$/g, "").split(/\s+/);
			const validUrl = splittedStr.filter((noteWord) => {
				return isValidUrl(noteWord);
			});
			const checkedUrl: IUrlContent[] = validUrl.map((urlStr) => {
				return {
					value: urlStr,
					isNiceDayBlogUrl: isNiceDayBlogUrl(urlStr),
				};
			});
			return {...note, checkedUrl};
		});

		yield put(clientNotePageActions.fetchNotes.success({data: noteWithUrlContent, userId}));
	} catch (err) {
		log.captureException(err);
		yield put(clientNotePageActions.fetchNotes.failure({err, userId}));
	}
}

export default function* () {
	yield takeEvery(getType(clientNotePageActions.fetchNotes.request), fetchClientNotes);
}
