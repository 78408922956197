import {Treatment} from "@sense-os/goalie-js";
import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {TreatmentProgress} from "../../../treatmentStatus/treatmentStatusTypes";
import {TreatmentGoalPayload} from "../interventionTreatmentGoalType";

export const interventionTreatmentGoalActions = {
	getDetailTreatment: createAsyncAction(
		"interventionTreatmentGoalActions/getDetailTreatment",
		"interventionTreatmentGoalActions/getDetailTreatmentSuccess",
		"interventionTreatmentGoalActions/getDetailTreatmentFailed",
	)<{hashId: string}, {treatment: Treatment}, {err: Error}>(),
	putTreatmentStatus: createAsyncAction(
		"interventionTreatmentGoalActions/putTreatmentStatus",
		"interventionTreatmentGoalActions/putTreatmentStatusSuccess",
		"interventionTreatmentGoalActions/putTreatmentStatusFailed",
	)<
		{
			treatmentGoal: TreatmentGoalPayload;
			treatmentStatus: TreatmentProgress;
		},
		{treatment: Treatment},
		{err: Error}
	>(),
	openModal: createAction("interventionTreatmentGoalActions/openModal")<{isOpen: boolean}>(),
};

export type interventionTreatmentGoalActionType = ActionType<typeof interventionTreatmentGoalActions>;
