import {CustomExerciseItem, TaskItemResponse, TaskStatus} from "@sense-os/goalie-js";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const interventionCustomExerciseActions = {
	fetchCustomExercise: createAsyncAction(
		"interventionCustomExerciseActions/fetchCustomExercise",
		"interventionCustomExerciseActions/fetchCustomExerciseSucceed",
		"interventionCustomExerciseActions/fetchCustomExerciseFailed",
	)<
		{hashId: string; page: number; pageSize: number; status?: TaskStatus},
		{results: TaskItemResponse<CustomExerciseItem>[]; count: number},
		Error
	>(),
};

export type interventionCustomExerciseActionType = ActionType<typeof interventionCustomExerciseActions>;
