import {ActionType, createAction} from "typesafe-actions";
import {wrapInDispatch} from "../../../helpers/wrapInDispatch";

export const dialogActions = {
	openDialog: createAction("dialog_actions/open_dialog", (key: string) => ({key}))(),
	closeDialog: createAction("dialog_actions/close_dialog", (key: string) => ({key}))(),
	toggleDialog: createAction("dialog_actions/toggle_dialog", (key: string) => ({key}))(),
};

export type DialogAction = ActionType<typeof dialogActions>;
export const dialogActionsWithDispatch = wrapInDispatch(dialogActions);
