import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {ThoughtRecordState} from "../thoughtRecordTypes";
import {ThoughtRecordActionType, thoughtRecordActions} from "./thoughtRecordActions";
import produce from "immer";

const initialState: ThoughtRecordState = {
	thoughtRecordFetchState: LoadingState.EMPTY,
	thoughtRecordData: null,
};

export const thoughtRecordReducer = produce(
	(state: ThoughtRecordState = initialState, action: ThoughtRecordActionType) => {
		switch (action.type) {
			case getType(thoughtRecordActions.fetchThoughtRecord.request): {
				state.thoughtRecordFetchState = LoadingState.LOADING;
				state.thoughtRecordData = null;
				return;
			}
			case getType(thoughtRecordActions.fetchThoughtRecord.success): {
				const {thoughtRecordData} = action.payload;
				state.thoughtRecordFetchState = LoadingState.LOADED;
				state.thoughtRecordData = thoughtRecordData;
				return;
			}
			case getType(thoughtRecordActions.fetchThoughtRecord.failure): {
				state.thoughtRecordFetchState = LoadingState.ERROR;
				return;
			}
		}
	},
	initialState,
);
