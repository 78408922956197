import {call, takeEvery, put, fork, delay} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {toastActions} from "../../../toaster/redux/toastAction";
import loc from "../../../localization/Localization";
import strTranslation from "../../../assets/lang/strings";

import {calendarActions} from "../../redux/calendarActions";
import {calendarSDK} from "../../helpers/calendarSDK";
import {getUndoButton} from "../../views/UndoButton";
import {UNDO_ACTION_TIMEOUT_MS, calendarEventScheduleMap} from "../../calendarTypes";

const log = createLogger("deleteSessionEventSaga", SentryTags.Calendar);

const UNDO_KEY_PREFIX = "CALENDAR_session_event_delete_";

function* handleDeleteSessionEvent(action: ActionType<typeof calendarActions.deleteSessionEvent.request>) {
	const {calendarId, eventId} = action.payload;
	const undoId = UNDO_KEY_PREFIX + eventId;

	yield put(
		toastActions.addToast({
			type: "warning",
			message: loc.formatMessage(strTranslation.CALENDAR.session.deleted.toast.text),
			persist: false,
			key: undoId,
			action: getUndoButton(undoId),
		}),
	);

	const deleteEventSagaTask = yield fork(deleteSessionEventTask, calendarId, eventId, true);
	calendarEventScheduleMap.set(undoId, deleteEventSagaTask);
}

export function* deleteSessionEventTask(calendarId: string, eventId: string, isUseDelay?: boolean) {
	if (isUseDelay) {
		yield delay(UNDO_ACTION_TIMEOUT_MS);
	}
	const token: string = yield call(getSessionId);

	try {
		yield apiCallSaga(calendarSDK.deleteSessionEvent, token, calendarId, eventId);
		yield put(calendarActions.deleteSessionEvent.success());
		// Re-fetch calendar events
		yield put(calendarActions.getCalendarEvents.request());
	} catch (err) {
		log.captureException(err);
		yield put(calendarActions.deleteSessionEvent.failure(err));
		yield put(
			toastActions.addToast({
				type: "error",
				message: loc.formatMessage(strTranslation.CALENDAR.event.deleted.error.toast.text),
			}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(calendarActions.deleteSessionEvent.request), handleDeleteSessionEvent);
}
