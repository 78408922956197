import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {DailyPlannerItem, EventViewData, SensorDatum, Sensors} from "../../ts/redux/tracking/TrackingTypes";
import {transformDailyPlannerIntoEventViewData} from "../helpers/dailyPlannerActivityHelpers";
import {getDailyPlannerItems, getSensorResolved} from "../helpers/clientActivitySDKHelpers";
import {clientActivityActions} from "../redux/clientActivitiyActions";
import {clientActivitySensorMap} from "../helpers/utils";
import {transformSensorDatumIntoClientActivity} from "../helpers/sensorDataActivityHelpers";
import {CLIENT_ACTIVITY_TYPE} from "../clientActivityTypes";
import {
	BehaviorExperimentTaskRegistration,
	CustomExerciseItem,
	PsychoEducationItem,
	TaskResponseList,
	TaskStatus,
} from "@sense-os/goalie-js";
import taskSDK from "../../taskRegistration/helpers/taskSDK";
import {CoreTrackerId} from "../../tracker/utils/coreTracker";
import {transformTaskItemIntoEventViewData} from "../helpers/taskActivityHelpers";

const log = createLogger("clientActivity");

/**
 * Loads client's activities data:
 * - Recurring planned events
 * - Planned event sensors
 * - Therapy sessions
 * - SMQ questionnaires
 * - OMQ questionnaires
 */
export function* fetchClientActivityDailyPlanners(
	action: ActionType<typeof clientActivityActions.fetchClientActivityDailyPlanners.request>,
) {
	const {userId, start, end} = action.payload;
	const token: string = yield call(getSessionId);

	try {
		const dailyPlannerItems: DailyPlannerItem[] = yield apiCallSaga(
			getDailyPlannerItems,
			token,
			userId,
			start,
			end,
		);

		// Transform raw data from backend into `EventViewData`
		const dpActivities: EventViewData[] = dailyPlannerItems
			.map(transformDailyPlannerIntoEventViewData)
			.filter(Boolean);

		yield put(
			clientActivityActions.fetchClientActivityDailyPlanners.success({
				userId,
				data: dpActivities,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(clientActivityActions.fetchClientActivityDailyPlanners.failure({userId}));
	}
}

// Transform SensorDatum type into EventViewData based on SensorName
const transformSensorDataToClientActivity = (sensorItems: SensorDatum<any>[], SensorName: Sensors): EventViewData[] => {
	return sensorItems
		.filter((value) => value.sensorName === SensorName)
		.map(transformSensorDatumIntoClientActivity)
		.filter(Boolean);
};

/**
 * Loads client's activities data:
 * - Meeting notes
 * - Diary entries
 * - Thought records
 * - Check-in Feeling
 */
export function* fetchClientActivitySensors(
	action: ActionType<typeof clientActivityActions.fetchClientActivitySensors.request>,
) {
	const {userId, start, end, activityTypes} = action.payload;
	const token: string = yield call(getSessionId);

	const sensorNames: string[] = activityTypes.map((activityType) => clientActivitySensorMap[activityType]);

	try {
		const sensorItems: SensorDatum<any>[] = yield apiCallSaga(
			getSensorResolved,
			token,
			userId,
			sensorNames,
			start,
			end,
		);

		// activities config
		const activitiesConfig = {
			[CLIENT_ACTIVITY_TYPE.MEETING_NOTE]: transformSensorDataToClientActivity(
				sensorItems,
				clientActivitySensorMap.meetingNote,
			),
			[CLIENT_ACTIVITY_TYPE.DIARY_ENTRY]: transformSensorDataToClientActivity(
				sensorItems,
				clientActivitySensorMap.diaryEntry,
			),
			[CLIENT_ACTIVITY_TYPE.CHECK_IN_FEELING]: transformSensorDataToClientActivity(
				sensorItems,
				clientActivitySensorMap.checkInFeeling,
			),
			[CLIENT_ACTIVITY_TYPE.THOUGHT_RECORD]: transformSensorDataToClientActivity(
				sensorItems,
				clientActivitySensorMap.thoughtRecord,
			),
		};
		// only returns requested sensors
		const activities = activityTypes.reduce((prev, activityType) => {
			return {
				...prev,
				...{[activityType]: activitiesConfig[activityType]},
			};
		}, {});

		yield put(
			clientActivityActions.fetchClientActivitySensors.success({
				userId,
				data: activities,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(
			clientActivityActions.fetchClientActivitySensors.failure({
				userId,
				activityTypes,
			}),
		);
	}
}

/**
 * Loads client's activities data:
 * - Behavior Experiments
 */
export function* fetchClientActivityBehaviourExp(
	action: ActionType<typeof clientActivityActions.fetchClientActivityBehaviourExp.request>,
) {
	const {userId, hashId} = action.payload;
	const token = yield call(getSessionId);

	try {
		const taskData: TaskResponseList<BehaviorExperimentTaskRegistration> = yield apiCallSaga(
			taskSDK.getTaskList,
			token,
			{
				patient: hashId,
				tracker: CoreTrackerId.BEHAVIOR_EXPERIMENT,
				// fetch all task data because of task can't filter based on registered time
				pageSize: 1000,
			},
		);

		const {results} = taskData;

		// only show task with registration
		const taskWithRegistration = results.filter((value) => value.registration);

		// Transform raw data from backend into `EventViewData`
		const eventViewData: EventViewData[] = taskWithRegistration.map(transformTaskItemIntoEventViewData);

		yield put(
			clientActivityActions.fetchClientActivityBehaviourExp.success({
				userId,
				data: eventViewData,
			}),
		);
	} catch (err) {
		log.captureException(err);
	}
}

/**
 * Loads client's activities data:
 * - PsychoEducation
 */
export function* fetchClientActivityPsychoEducation(
	action: ActionType<typeof clientActivityActions.fetchClientActivityPsychoEducation.request>,
) {
	const {userId, hashId} = action.payload;
	const token = yield call(getSessionId);

	try {
		const psychoEducations: TaskResponseList<PsychoEducationItem> = yield apiCallSaga(taskSDK.getTaskList, token, {
			patient: hashId,
			tracker: CoreTrackerId.PSYCHOEDUCATION,
			// fetch all task data because of task can't filter based on registered time
			pageSize: 1000,
		});

		// Transform raw data from backend into `EventViewData`
		const eventViewData: EventViewData[] = psychoEducations.results.map(transformTaskItemIntoEventViewData);

		yield put(
			clientActivityActions.fetchClientActivityPsychoEducation.success({
				userId,
				data: eventViewData,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(
			clientActivityActions.fetchClientActivityPsychoEducation.failure({
				userId,
			}),
		);
	}
}

/**
 * Saga for fetching the task list that is mapped on the intervention page
 * currently portal only shows custom exercise task
 */
export function* fetchClientActivityTask(
	action: ActionType<typeof clientActivityActions.fetchClientActivityTask.request>,
) {
	const {userId, hashId} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		const taskData: TaskResponseList<CustomExerciseItem> = yield apiCallSaga(taskSDK.getTaskList, token, {
			patient: hashId,
			tracker: CoreTrackerId.CUSTOM_EXERCISE,
			// fetch all task data because of task can't filter based on registered time
			pageSize: 1000,
			// filter completed and canceled
			status: TaskStatus.CONCLUDED,
		});

		const {results} = taskData;

		// Transform raw data from backend into `EventViewData`
		const eventViewData: EventViewData[] = results.map(transformTaskItemIntoEventViewData).filter(Boolean);

		yield put(clientActivityActions.fetchClientActivityTask.success({userId, data: eventViewData}));
	} catch (error) {
		log.captureException(error);
		yield put(clientActivityActions.fetchClientActivityTask.failure({userId}));
	}
}

export default function* () {
	yield takeEvery(
		getType(clientActivityActions.fetchClientActivityDailyPlanners.request),
		fetchClientActivityDailyPlanners,
	);
	yield takeEvery(getType(clientActivityActions.fetchClientActivitySensors.request), fetchClientActivitySensors);
	yield takeEvery(
		getType(clientActivityActions.fetchClientActivityBehaviourExp.request),
		fetchClientActivityBehaviourExp,
	);
	yield takeEvery(
		getType(clientActivityActions.fetchClientActivityPsychoEducation.request),
		fetchClientActivityPsychoEducation,
	);
	yield takeEvery(getType(clientActivityActions.fetchClientActivityTask.request), fetchClientActivityTask);
}
