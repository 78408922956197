import {Typography} from "@material-ui/core";
import {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {NDColors} from "../../../../material-ui/NDColors";
import {
	GraphV2Type,
	IGraphConfig,
	IGraphV2Data,
	IUseGraphData,
	TrackerRegistrationGroupByDate,
} from "../../customTrackerGraphV2Types";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";
import {getActiveStringDates, getEndDate, transformRegistrationDateFromString} from "./graphV2ConfigHelper";
import {useGetXTickValues, useGetYTickValues} from "./graphV2TickHooks";

// minimum value of Y scale
const HIGHEST_Y_SCALE: number = 10;
// always return 6 lines start from 0
const Y_LINES: number = 6;

const getGraphData = (trackerRegistration, selectedTimeView, startDate, endDate): IGraphV2Data[] => {
	const activeDates: string[] = getActiveStringDates(trackerRegistration, selectedTimeView, startDate, endDate);
	const data = [
		{
			id: strTranslation.GRAPHS.events.header,
			color: NDColors.ORANGE,
			data: activeDates.map((date) => {
				const xDate = transformRegistrationDateFromString(date);
				return {
					x: xDate,
					y: trackerRegistration[date].length,
				};
			}),
		},
	];
	// filter graph that contains zero value
	// https://github.com/plouc/nivo/issues/1854
	return data.filter((res) => res.data.length > 0);
};

interface Props {
	attr: string;
	translation: string;
}

export const totalRegistrationWithSymptomConfig = (measures: Props[]): IGraphConfig => {
	return {
		graphType: GraphV2Type.TOTAL_REGISTRATION_WITH_SYMPTOM,
		graphName: localization.formatMessage(strTranslation.GRAPHS.events.header),
		yLegend: localization.formatMessage(strTranslation.GRAPHS.events.header),
		xLegend: localization.formatMessage(strTranslation.TIME.date),
		yToolTipFormatter: (label, value, registrations) => {
			const symptoms: string[] = registrations.reduce((res, registration) => {
				const happenedSymptoms = measures.filter((measure) => {
					return registration.value.measures[measure.attr].sensorData.value.boolean;
				});
				const happenedSymptomTranslations = happenedSymptoms.map((symptom) => {
					return localization.formatMessage(symptom.translation);
				});
				return Array.from(new Set([...res, ...happenedSymptomTranslations]));
			}, []);

			const symptomLength = symptoms.length;

			return (
				<>
					<Typography variant="body2" style={{fontWeight: 500}}>
						<FormattedMessage id={strTranslation.CUSTOM_TRACKER.measure.symptom.label} />
					</Typography>
					<Typography variant="body2">
						{symptoms.map((symptom, i) => {
							const punctuation = symptomLength - 1 > i ? ", " : "";
							return `${symptom}${punctuation}`;
						})}
					</Typography>
				</>
			);
		},
		useGraphData: (
			trackerRegistration: TrackerRegistrationGroupByDate,
			trackerName: string,
			graphType: GraphV2Type,
			startDate: Date,
			selectedTimeView: GRAPH_TIME_VIEW_VALUE,
		): IUseGraphData => {
			const endDate = getEndDate(selectedTimeView, startDate);
			// data
			const graphData: IGraphV2Data[] = useMemo(() => {
				return getGraphData(trackerRegistration, selectedTimeView, startDate, endDate);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [trackerRegistration, trackerName, graphType, selectedTimeView, startDate]);

			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);
			const xTickValues: Date[] = useGetXTickValues(graphData, selectedTimeView, startDate, endDate);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},
	};
};
