import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {TimeEntriesOwnerOfTreatment} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";
import {ActionType, getType} from "typesafe-actions";

import {getSessionId} from "../../auth/helpers/authStorage";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";

import {treatmentStatusActions} from "../redux/treatmentStatusActions";
import {getClientStatus} from "../redux/treatmentStatusSelectors";
import treatmentStatusSdk from "../treatmentStatusSdk";
import {Treatment} from "../treatmentStatusTypes";

const log = createLogger("TreatmentStatus - end treatment saga", SentryTags.TreatmentStatus);

/**
 * The saga that will be triggered when end a treatment
 * with EndTreatmentForm component.
 */
function* endTreatment(action: ActionType<typeof treatmentStatusActions.endTreatment.request>) {
	yield put(treatmentStatusActions.updateTreatmentMutationLoadingState(LoadingState.LOADING));

	// Per client management feature, we are not supplying reason anymore.
	// That's why at this step, we are making sure that there's a "null" reason.

	try {
		const {clientId, endFormValue} = action.payload;
		const treatment: Treatment = yield select(getClientStatus(clientId));

		const token: string = getSessionId();
		const sdkTreatment = yield apiCallSaga(treatmentStatusSdk.updateTreatment, token, {
			id: treatment.id,
			patient: clientId,
			startTime: treatment.startTime,
			disconnectedAt: endFormValue.shouldSetReminder ? endFormValue.reminderDate : undefined,
			endTime: endFormValue.endDate,
			terminatingReason: endFormValue.endTreatmentReason.id,
			clientData: treatment.clientData || undefined,
		});

		yield put(
			treatmentStatusActions.setTreatmentStatus(clientId, {
				...sdkTreatment,
				patient: clientId,
				treatmentTypes: treatment?.treatmentTypes || [],
				clientComplaints: treatment?.clientComplaints || [],
				personnels: treatment?.personnels || [],
			}),
		);
		yield put(treatmentStatusActions.closeEndTreatmentForm());
		yield put(treatmentStatusActions.updateTreatmentMutationLoadingState(LoadingState.LOADED));
	} catch (error) {
		log.captureException(error);
		yield put(treatmentStatusActions.updateTreatmentMutationLoadingState(LoadingState.ERROR));
		return;
	}
}

/**
 * The saga that will be triggered every time endTreatmentForm is opened,
 * to check whether there're blockers to end the treatment.
 */
function* checkEndTreatmentBlocker(action: ActionType<typeof treatmentStatusActions.openEndTreatmentForm>) {
	const {clientId} = action.payload;

	const token: string = yield call(getSessionId);
	const treatment: Treatment = yield select(getClientStatus(clientId));
	const treatmentId = treatment?.id;

	try {
		const owners: TimeEntriesOwnerOfTreatment[] = yield apiCallSaga(
			treatmentStatusSdk.getTimeEntriesOwnerOfTreatment,
			token,
			treatmentId,
		);

		yield put(treatmentStatusActions.checkEndTreatmentBlocker.success({treatmentId: treatmentId, owners}));
	} catch (error) {
		log.captureException(error);
		yield put(treatmentStatusActions.checkEndTreatmentBlocker.failure({treatmentId, error}));
	}
}

/**
 * Just like its name, this is where the logic to end treatment lies.
 */
export default function* () {
	yield takeEvery(getType(treatmentStatusActions.endTreatment.request), endTreatment);
	yield takeEvery(getType(treatmentStatusActions.openEndTreatmentForm), checkEndTreatmentBlocker);
}
