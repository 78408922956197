import {getType} from "typesafe-actions";

import {VersionCheckActionTypes, versionCheckActions} from "./versionCheckAction";

export interface VersionCheckState {
	outdatedPortalBackdropEnabled: boolean;
	isUpdateConfirmationDisplayed: boolean;
}

const initialState: VersionCheckState = {
	outdatedPortalBackdropEnabled: false,
	isUpdateConfirmationDisplayed: false,
};

export function versionCheckReducer(
	state: VersionCheckState = initialState,
	action: VersionCheckActionTypes,
): VersionCheckState {
	switch (action.type) {
		case getType(versionCheckActions.enableBackdrop): {
			return {outdatedPortalBackdropEnabled: true, isUpdateConfirmationDisplayed: false};
		}
		case getType(versionCheckActions.displayUpdateConfirmation): {
			return {outdatedPortalBackdropEnabled: false, isUpdateConfirmationDisplayed: true};
		}
		case getType(versionCheckActions.hideUpdateConfirmation): {
			return {...state, isUpdateConfirmationDisplayed: false};
		}

		default:
			return state;
	}
}
