import {call, put} from "redux-saga/effects";
import {authActions} from "../../auth/redux";
import {storeDispatch} from "redux/StoreContainer";
import {isNetworkFailureError, isUnauthorizedError} from "../../errorHandler/errorHandlerUtils";

type PromiseFn = (...args: any[]) => Promise<any>;
type ContextAndFn<Fn extends PromiseFn> = [any, Fn];
type ApiCallParam<Fn extends PromiseFn> = Fn | ContextAndFn<Fn>;

interface QueryConfig {
	/**
	 * Maximum amount of retries should the query run when the fetch fails
	 */
	maxRetry?: number;
	/**
	 * Delay between retries in milliseconds
	 */
	retryDelayMS?: number;
	/**
	 * True if the promise function throws an exception which meets a criteria to be retried
	 */
	shouldRetry?: (err: Error) => boolean;
}

/**
 * Wrapper for API/SDK Calls
 * Kicks out user when portal catches an UnauthorizedError
 *
 * @param param
 * @param args
 */
export async function apiCall<Fn extends PromiseFn>(param: ApiCallParam<Fn>, ...args: Parameters<Fn>) {
	try {
		const promiseFn = serializeParam(param);
		const query = makeQuery(promiseFn, {shouldRetry: isNetworkFailureError});
		return await query(...args);
	} catch (err) {
		if (isUnauthorizedError(err)) {
			// Invalidate token
			storeDispatch(authActions.logout.request({isSessionExpired: true}));
		}
		throw err;
	}
}

/**
 * Wrapper for API/SDK Calls
 * Kicks out user when portal catches an UnauthorizedError
 *
 * @param param
 * @param args
 */
export function* apiCallSaga<Fn extends PromiseFn>(param: ApiCallParam<Fn>, ...args: Parameters<Fn>) {
	try {
		const promiseFn = serializeParam(param);
		const query = makeQueryWithCall(promiseFn, {shouldRetry: isNetworkFailureError});
		return yield call(query, ...args);
	} catch (err) {
		if (isUnauthorizedError(err)) {
			// Invalidate token
			yield put(authActions.logout.request({isSessionExpired: true}));
		}
		throw err;
	}
}

function serializeParam<Fn extends PromiseFn>(param: ApiCallParam<Fn>) {
	let context = null;
	let promiseFn: Fn = null;
	if (Array.isArray(param)) {
		context = param[0];
		promiseFn = param[1].bind(context);
	} else {
		promiseFn = param;
	}

	return promiseFn;
}

// This default query config is defined here so both
// makeQuery and makeQueryWithCall below use the same default query config.
const defaultMakeQueryConfig: QueryConfig = {
	maxRetry: 3,
	retryDelayMS: 3000,
};

function makeQuery<Fn extends PromiseFn>(fn: Fn, optConfig?: QueryConfig) {
	let retries = 0;
	let config: QueryConfig = {
		...defaultMakeQueryConfig,
		...optConfig,
	};

	const runQuery = async (...args: Parameters<Fn>) => {
		try {
			return await fn(...args);
		} catch (err) {
			const shouldRetry = !!config.shouldRetry ? config.shouldRetry(err) : true;
			if (shouldRetry && (config.maxRetry === Infinity || retries < config.maxRetry)) {
				retries += 1;
				await delay(config.retryDelayMS);
				return await runQuery(...args);
			}
			throw err;
		}
	};

	return runQuery;
}

/**
 * Similar with makeQuery above, but with call effect,
 * so it can be easily mocked.
 */
function makeQueryWithCall<Fn extends PromiseFn>(fn: Fn, optConfig?: QueryConfig) {
	let retries = 0;
	let config: QueryConfig = {
		...defaultMakeQueryConfig,
		...optConfig,
	};

	const runQuery = function* (...args: Parameters<Fn>) {
		while (true) {
			try {
				const result = yield call(fn, ...args);
				return result;
			} catch (err) {
				const shouldRetry = !!config.shouldRetry ? config.shouldRetry(err) : true;
				if (!shouldRetry || (config.maxRetry !== Infinity && retries >= config.maxRetry)) throw err;

				retries += 1;
				yield delay(config.retryDelayMS);
			}
		}
	};

	return runQuery;
}

async function delay(delayMS: number = 1000) {
	return new Promise((resolve) => {
		setTimeout(resolve, delayMS);
	});
}
