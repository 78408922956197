import {fork, put, select, take, takeEvery} from "@redux-saga/core/effects";
import {userSettingsAction} from "../../userSettings/redux/userSettingsActions";
import {getTimeTrackingSettings} from "../../userSettings/redux/userSettingsSelectors";
import {timeTrackingActions} from "../redux/timeTrackingActions";
import {TimeTrackingSettings} from "../timeTrackingTypes";
import {ActionType, getType} from "typesafe-actions";
import {getActiveEntryInput, getIsActiveEntryTimerRunning} from "../redux/timeTrackingSelectors";

/**
 * This is the saga that's responsible to set the active entry input
 * to the correct value when portal first loaded.
 */
function* loadActiveEntry() {
	yield take(userSettingsAction.loadSettings.success);

	const timeTrackingSettings: TimeTrackingSettings = yield select(getTimeTrackingSettings);
	yield put(
		timeTrackingActions.storeActiveEntryInputToRedux(
			timeTrackingSettings?.activeEntryInput,
			!!timeTrackingSettings?.isActiveEntryTimerRunning,
		),
	);
}

/**
 * This is the saga where we store time tracking information
 * to user settings so it can persists between sessions.
 */
function* storeTimeTrackingSettings(action: ActionType<typeof timeTrackingActions.storeTimeTrackingSettings>) {
	const timeTrackingSettings = yield select(getTimeTrackingSettings);
	yield put(
		userSettingsAction.saveSettings.request({
			settings: {
				timeTrackingUserSettings: {
					...timeTrackingSettings,
					...action.payload.settings,
				},
			},
		}),
	);
}

/**
 * This is the saga where we store an ongoing time entry tracking
 * to user settings so it can persists between sessions.
 */
function* storeOngoingTrackedActiveEntry() {
	const activeEntry = yield select(getActiveEntryInput);
	const isTimerRunning = yield select(getIsActiveEntryTimerRunning);

	yield put(
		timeTrackingActions.storeTimeTrackingSettings({
			activeEntryInput: activeEntry,
			isActiveEntryTimerRunning: isTimerRunning,
		}),
	);
}

/**
 * This is the saga where we store time tracking information
 * to user settings so it can persists between sessions.
 */
function* storeIsAutomaticTracking(action: ActionType<typeof timeTrackingActions.setDoesUserAllowAutoTracking>) {
	yield put(
		timeTrackingActions.storeTimeTrackingSettings({
			doesUserAllowAutoTracking: action.payload.doesUserAllowAutoTracking,
		}),
	);
}

export default function* () {
	yield takeEvery(getType(timeTrackingActions.storeTimeTrackingSettings), storeTimeTrackingSettings);
	yield takeEvery(getType(timeTrackingActions.updateActiveEntry), storeOngoingTrackedActiveEntry);
	yield takeEvery(getType(timeTrackingActions.setIsTimerRunningForActiveEntry), storeTimeTrackingSettings);
	yield takeEvery(getType(timeTrackingActions.setDoesUserAllowAutoTracking), storeIsAutomaticTracking);

	// Load the entry at the start of the portal.
	yield fork(loadActiveEntry);
}
