import {OrganizationGroup, OrganizationMemberStatus} from "@sense-os/goalie-js";
import organizationSDK from "../../organizations/helpers/organizationSdk";
import {AvailableOrganization} from "../organizationsTypes";

/**
 * Returns `accepted` and `pending` user organizations
 *
 * @param token
 * @param userId
 */
export async function getAcceptedAndPendingOrganizations(token: string, userId: number) {
	return organizationSDK.getOrganizationsOfUser(userId, token, [
		OrganizationMemberStatus.ACCEPTED,
		OrganizationMemberStatus.PENDING,
	]);
}

// Convert given organization group list into available organizations
export function convertOrganizationGroupsIntoAvailableOrganizations(
	orgGroupList: OrganizationGroup[],
): AvailableOrganization[] {
	let availableOrganizations: AvailableOrganization[] = [];

	orgGroupList.forEach((orgGroup) => {
		availableOrganizations.push({
			id: orgGroup.id,
			name: orgGroup.name,
			isOrgGroup: true,
		});
	});

	return availableOrganizations;
}

// Sort given organizations by organization name
export function sortOrganizationsByOrgName(organizations: AvailableOrganization[]) {
	let orgs: AvailableOrganization[] = [...organizations];

	return orgs.sort((first: {name: string}, second: {name: string}) => {
		const firstOrgName = first.name.toLowerCase();
		const secondOrgName = second.name.toLowerCase();

		if (firstOrgName < secondOrgName) return -1;
		if (firstOrgName > secondOrgName) return 1;
		return 0;
	});
}
