import {MeetingNote, SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const interventionNoteActions = {
	fetchNotes: createAsyncAction(
		"interventionNote/fetchNotes",
		"interventionNote/fetchNotesSuccess",
		"interventionNote/fetchNotesFail",
	)<{userId: number}, {userId: number; data: SensorDatum<MeetingNote>[]}, {userId: number; err: Error}>(),
};

export type interventionNoteActionType = ActionType<typeof interventionNoteActions>;
