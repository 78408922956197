import {select, put, fork} from "redux-saga/effects";
import {getAllContacts} from "../../contacts/redux/contactSelectors";
import {Contact} from "../../contacts/contactTypes";
import {ChatAction} from "../redux/ChatAction";
import {whenChatConnect} from "./helpers";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";

const log = createLogger("loadChatAfterConnect", SentryTags.Chat);

function* loadChatsForAllContacts() {
	const allContacts: Contact[] = yield select(getAllContacts);
	const allContactIds = allContacts.map((c) => c.id);
	log.debug("chat is connected, loading chats for all contacts!", allContactIds);
	log.addBreadcrumb({
		message: "chat is connected, loading chats for all contacts!",
		data: {contactsCount: allContactIds.length},
	});
	yield put(ChatAction.bulkLoadChatHistoriesFromBE(allContactIds));
}

export default function* loadChatAfterConnectSaga() {
	yield fork(whenChatConnect(loadChatsForAllContacts));
}
