import {LoadingState} from "constants/redux";
import {getType} from "typesafe-actions";
import {ClientNetworkActionType, clientNetworkActions} from "./clientNetworkActions";
import {ClientNetworkState, ClientsConnection} from "../clientNetworkTypes";

const initialState: ClientNetworkState = {
	isLoadingAvailableTherapists: false,
	clientsConnectionMap: {},
};

export default function clientNetworkReducer(
	state: ClientNetworkState = initialState,
	action: ClientNetworkActionType,
): ClientNetworkState {
	switch (action.type) {
		case getType(clientNetworkActions.loadClientsConnectionById.request): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {
				loadingState: LoadingState.LOADING,
			});
		}
		case getType(clientNetworkActions.loadClientsConnectionById.success): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {
				loadingState: LoadingState.LOADED,
				connectedTherapists: action.payload.clientsConnection,
			});
		}
		case getType(clientNetworkActions.loadClientsConnectionById.failure): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {loadingState: LoadingState.ERROR});
		}

		case getType(clientNetworkActions.inviteExtraTherapist.request): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {
				loadingState: LoadingState.LOADING,
			});
		}
		case getType(clientNetworkActions.inviteExtraTherapist.success): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {loadingState: LoadingState.LOADED});
		}
		case getType(clientNetworkActions.inviteExtraTherapist.failure): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {loadingState: LoadingState.ERROR});
		}
		case getType(clientNetworkActions.cancelInvitation.request): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {
				loadingState: LoadingState.LOADING,
			});
		}
		case getType(clientNetworkActions.cancelInvitation.success): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {loadingState: LoadingState.LOADED});
		}
		case getType(clientNetworkActions.cancelInvitation.failure): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {loadingState: LoadingState.ERROR});
		}
		case getType(clientNetworkActions.disconnectTherapist.request): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {
				loadingState: LoadingState.LOADING,
			});
		}
		case getType(clientNetworkActions.disconnectTherapist.success): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {loadingState: LoadingState.LOADED});
		}
		case getType(clientNetworkActions.disconnectTherapist.failure): {
			return setClientsConnectionMapByUserId(state, action.payload.clientId, {loadingState: LoadingState.ERROR});
		}
		default:
			return state;
	}
}

function setClientsConnectionMapByUserId(
	state: ClientNetworkState,
	userId: number,
	newData: Partial<ClientsConnection>,
) {
	const userConnectionData = state.clientsConnectionMap[userId] || {};
	return {
		...state,
		clientsConnectionMap: {
			...state.clientsConnectionMap,
			[userId]: {
				...userConnectionData,
				...newData,
			},
		},
	};
}
