/** Author: leo Date: 26/04/2021 */

import {Reducer} from "redux";
import {getType} from "typesafe-actions";
import {DashboardAction} from "./DashboardAction";
import {LoadingState} from "../../ts/constants/redux";

export interface DashboardState {
	/** Has the email of the therapist already been verified? */
	isEmailVerified: boolean;

	/** Helps controlling loader while fetching the verification status from the BE */
	emailVeriTokenLoadingState: LoadingState;

	/** Error message text */
	errorMessage: string;
}

const defaultState: DashboardState = {
	isEmailVerified: false,
	emailVeriTokenLoadingState: LoadingState.EMPTY,
	errorMessage: null,
};

export const dashboardReducer: Reducer<DashboardState> = (
	state: DashboardState = defaultState,
	action: DashboardAction.DashboardActionType,
): DashboardState => {
	switch (action.type) {
		case getType(DashboardAction.clearError):
			return {...state, emailVeriTokenLoadingState: LoadingState.EMPTY, errorMessage: null};

		case getType(DashboardAction.requestVerificationToken.request):
			return {...state, emailVeriTokenLoadingState: LoadingState.LOADING};

		case getType(DashboardAction.requestVerificationToken.success):
			return {...state, emailVeriTokenLoadingState: LoadingState.LOADED};

		case getType(DashboardAction.requestVerificationToken.failure):
			return {...state, emailVeriTokenLoadingState: LoadingState.ERROR, errorMessage: action.payload.message};

		case getType(DashboardAction.submitVerificationToken.success):
			return {
				...state,
				emailVeriTokenLoadingState: LoadingState.LOADED,
				isEmailVerified: true,
			};

		case getType(DashboardAction.submitVerificationToken.failure):
			return {
				...state,
				emailVeriTokenLoadingState: LoadingState.ERROR,
				errorMessage: action.payload.message,
			};

		case getType(DashboardAction.submitVerificationToken.request):
			return {...state, emailVeriTokenLoadingState: LoadingState.LOADING};

		default:
			return state;
	}
};
