import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {PlanActivityState} from "./planActivityTypes";
import {PlanActivitysActionType, planActivityActions} from "./planActivityActions";

const initialState: PlanActivityState = {
	/**
	 * userId of whom the plan event form was opened for
	 */
	userId: null,
	/**
	 * the plan event id which wanted to be edited.
	 * if not empty, this means that the plan event form is in editing mode
	 */
	eventId: null,
	/**
	 * Flag to marks if user want to create a new event based on cancelled event.
	 */
	isReplan: false,
	isFormOpened: false,
	isConfirmationDialogOpened: false,
	/**
	 * To determine saving data request to backend
	 */
	savingState: LoadingState.EMPTY,
	/**
	 * Saved planned event form values keyed by `userId`
	 */
	savedFormValues: {},
	// plan activity fetching state
	fetchState: LoadingState.EMPTY,
	// plannedActivity data
	plannedActivity: null,
	// plan activity deleting state
	deletingState: LoadingState.EMPTY,
};

export function planActivityReducer(
	state: PlanActivityState = initialState,
	action: PlanActivitysActionType,
): PlanActivityState {
	switch (action.type) {
		case getType(planActivityActions.openForm):
			const eventId: string = action.payload.eventViewId;
			const isEditing: boolean = !!eventId;

			return {
				...state,
				userId: action.payload.userId,
				eventId: eventId || null,
				isReplan: action.payload.isReplan,
				isFormOpened: true,
				// if in the creation state, reset the planned activity data
				plannedActivity: isEditing ? state.plannedActivity : null,
			};
		case getType(planActivityActions.closeForm):
			return {
				...state,
				isFormOpened: false,
				savingState: LoadingState.EMPTY,
			};
		case getType(planActivityActions.openConfirmationDialog):
			return {
				...state,
				userId: action.payload.userId,
				isConfirmationDialogOpened: true,
			};
		case getType(planActivityActions.closeConfirmationDialog):
			return {
				...state,
				isConfirmationDialogOpened: false,
			};
		case getType(planActivityActions.saveFormValues):
			return {
				...state,
				savedFormValues: {
					...state.savedFormValues,
					[action.payload.userId]: action.payload.formValues,
				},
			};
		case getType(planActivityActions.saveActivity.request):
			return {
				...state,
				savingState: LoadingState.LOADING,
			};

		case getType(planActivityActions.saveActivity.success):
			return {
				...state,
				userId: null,
				savingState: LoadingState.LOADED,
				savedFormValues: {
					...state.savedFormValues,
					[action.payload.userId]: null,
				},
			};
		case getType(planActivityActions.saveActivity.failure):
			return {
				...state,
				savingState: LoadingState.ERROR,
			};
		case getType(planActivityActions.fetchPlannedActivity.request):
			return {
				...state,
				fetchState: LoadingState.LOADING,
			};

		case getType(planActivityActions.fetchPlannedActivity.success):
			return {
				...state,
				fetchState: LoadingState.LOADED,
				plannedActivity: action.payload.data,
			};
		case getType(planActivityActions.fetchPlannedActivity.failure):
			return {
				...state,
				fetchState: LoadingState.ERROR,
			};

		case getType(planActivityActions.deletePlannedActivity.request):
			return {
				...state,
				deletingState: LoadingState.LOADING,
			};

		case getType(planActivityActions.deletePlannedActivity.success):
			return {
				...state,
				deletingState: LoadingState.LOADED,
			};
		case getType(planActivityActions.deletePlannedActivity.failure):
			return {
				...state,
				deletingState: LoadingState.ERROR,
			};
		default:
			return state;
	}
}
