import * as React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	ButtonProps,
	DialogProps,
	CircularProgress,
	makeStyles,
} from "@material-ui/core";
import {Button} from "../material-ui/buttons";
import {NDColors} from "../material-ui/NDColors";

const useStyles = makeStyles(() => {
	return {
		circularProgress: {
			marginRight: 8,
			position: "relative",
		},
		dialogContent: {
			minWidth: 500,
			minHeight: 60,
		},
		dialogAction: {
			padding: 14,
			minHeight: 60,
		},
		dialogContentText: {color: NDColors.DEFAULT_TEXT},
	};
});

interface Props {
	open?: boolean;
	titleText?: string;
	contentText?: string;
	closeButtonText?: string | JSX.Element;
	closeButtonProps?: ButtonProps;
	confirmButtonText?: string | JSX.Element;
	confirmButtonProps?: ButtonProps;
	onClose: () => void;
	onConfirm: () => void;
	/**
	 * If set, the confirmation button on the dialog has red color in order to warn the user
	 * that the action to be performed is critical, such as deletion which cannot be undone.
	 */
	destructive?: boolean;
	isSaving?: boolean;
}

const ConfirmDialog: React.FC<Props & DialogProps> = ({
	open,
	titleText,
	contentText,
	closeButtonText,
	closeButtonProps,
	confirmButtonText,
	confirmButtonProps,
	onClose,
	onConfirm,
	destructive,
	isSaving = false,
	...props
}) => {
	const style = useStyles();

	return (
		<Dialog
			open={open}
			onClose={() => {
				// only can be close while not saving
				if (!isSaving) {
					onClose();
				}
			}}
			maxWidth={props.maxWidth || "md"}
			{...props}
		>
			{titleText && <DialogTitle>{titleText}</DialogTitle>}
			<DialogContent className={style.dialogContent}>
				<DialogContentText className={style.dialogContentText}>{contentText}</DialogContentText>
			</DialogContent>
			<DialogActions className={style.dialogAction}>
				<Button
					id="btn_close"
					color="default"
					onClick={onClose}
					variant={closeButtonProps?.variant || "contained"}
					{...closeButtonProps}
					disabled={isSaving}
				>
					{closeButtonText}
				</Button>
				<Button
					id="btn_confirm"
					color={destructive ? "secondary" : "primary"}
					onClick={onConfirm}
					variant={confirmButtonProps?.variant || "contained"}
					disabled={isSaving}
					{...confirmButtonProps}
				>
					{isSaving && <CircularProgress size={18} className={style.circularProgress} />}
					{confirmButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
