import {ActionType} from "typesafe-actions";
import {groupTherapyActions} from "../redux/groupTherapyActions";
import {call, put, takeEvery} from "redux-saga/effects";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import groupTherapySDK from "../helpers/groupTherapySdk";
import {getSessionId} from "../../auth/helpers/authStorage";
import {GroupTherapyTracker, GroupTherapyTrackerResponse, TrackerItem} from "@sense-os/goalie-js";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {getTranslatedTrackerNameFromBE} from "../../tracker/customTracker/helpers/customTrackerHelpers";

/** total supported custom tracker
 * since the response is mixed default trackers & custom trackers, the page size request is updated to 100 */
const PAGE_SIZE = 100;

const log = createLogger("fetchGroupTherapySaga", SentryTags.GroupTherapy);

function* fetchGroupTherapyTrackersSaga(
	action: ActionType<typeof groupTherapyActions.fetchGroupTherapyTrackers.request>,
) {
	const {groupId} = action.payload;

	const token: string = yield call(getSessionId);

	try {
		const response: GroupTherapyTrackerResponse = yield apiCallSaga(
			groupTherapySDK.getGroupTherapyTrackers,
			token,
			groupId,
			{
				pageSize: PAGE_SIZE,
			},
		);

		/** translate the tracker name first before store the list to the redux state */
		const trackers: GroupTherapyTracker[] = response.results.map((tracker) => {
			return {
				...tracker,
				trackerName: getTranslatedTrackerNameFromBE(tracker as TrackerItem, tracker.sensorName),
			};
		});

		yield put(groupTherapyActions.fetchGroupTherapyTrackers.success({trackers}));
	} catch (error) {
		log.captureException(error, {message: "Failed to fetch group therapy tracker list."});
		yield put(groupTherapyActions.fetchGroupTherapyTrackers.failure({error}));
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.fetchGroupTherapyTrackers.request, fetchGroupTherapyTrackersSaga);
}
