import {UserRole, TerminationReason} from "@sense-os/goalie-js";
import {AppConfig} from "app/AppConfig";
import {TIME} from "constants/time";
import {DISC} from "IoC/DISC";
import {call, select, takeEvery, put} from "redux-saga/effects";
import {getActiveCall} from "redux/videoCall/VideoCallSelectors";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {getNumberOfJoinedParticipants} from "services/chat/video/VideoCallHelpers";
import {ActionType, getType} from "typesafe-actions";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import chatSDK from "../../chat/sdk";
import {getContactById} from "../../contacts/redux/contactSelectors";
import {checkAuthUserAccess, PortalFeatures} from "../../featureFlags/userFeatureAccess";
import {meetingNotesActions} from "../../tracker/meetingNotes/redux/meetingNotesActions";
import {isEditingInVideoCallWindow} from "../../privateNotes/redux/privateNotesSelector";
import {rateCallActions} from "../../rateCall/redux/rateCallActions";
import {callActions} from "../redux/callActions";
import featureFlags from "../../featureFlags/FeatureFlags";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";

const logger = createLogger("endCallSaga", SentryTags.VideoCall);

export function* endCallSaga() {
	yield takeEvery(getType(callActions.endCallIfNeeded), endCall);
}

function* endCall({payload: {reason}}: ActionType<typeof callActions.endCallIfNeeded>) {
	const activeCall: ActiveCall = yield select(getActiveCall);
	const authUser: AuthUser = yield select(getAuthUser);

	const isEditingPrivateNote = yield select(isEditingInVideoCallWindow);
	const shouldEndActiveCall = yield call(shouldEndCall, activeCall, authUser.id);

	// We don't want to end the call when user is still editing the work notes
	if (!shouldEndActiveCall || isEditingPrivateNote) {
		return;
	}

	if (activeCall && activeCall.participantMap) {
		try {
			// Send termination signal
			yield call(chatSDK.terminateCall, activeCall.roomId, reason);
			// Send call summary
			yield call(DISC.getVideoCallService().sendCallSummary, reason);
		} catch (error) {
			logger.captureException(error);
		}

		const callHungUp = yield call(isHangup, reason);

		if (featureFlags.postCallDialogs && callHungUp) {
			// Open meeting note if needed
			yield call(openMeetingNoteDialog, activeCall, authUser);
			// Open rate call quality dialog if needed
			yield call(openRateCallQualityDialog, activeCall, authUser);
		}
	}

	// Reset everything
	yield call(DISC.getVideoCallService().reset);
}

function isHangup(reason: TerminationReason) {
	return [
		TerminationReason.NormalHangUp,
		TerminationReason.NormalHangUpByInitiator,
		TerminationReason.NormalHangUpByRecipient,
		TerminationReason.AbnormalHangUp,
		TerminationReason.UnexpectedError,
	].includes(reason);
}

/**
 * Returns true if call should be ended/terminated.
 * That is when auth user has left, OR every one has left but auth user
 */
function shouldEndCall(activeCall: ActiveCall, authUserId: number) {
	const noActiveCall = !activeCall || !activeCall.participantMap;
	if (noActiveCall) {
		return true;
	}

	const authUserHasLeft = activeCall.participantMap[authUserId] && activeCall.participantMap[authUserId].leaveTime;
	const everyoneHasLeft = getNumberOfJoinedParticipants(activeCall) === 1; // Auth user is the last person in the room

	return authUserHasLeft || everyoneHasLeft;
}

function* openMeetingNoteDialog(activeCall: ActiveCall, authUser: AuthUser) {
	const hasMeetingNotePermission = checkAuthUserAccess(authUser)(PortalFeatures.addMeetingNote);
	const callDuration = yield call(getCallDuration, activeCall, authUser.id);
	const meetingNoteUserId = yield call(getMeetingNoteUserId, activeCall, authUser.id);

	const userContact = yield select((state) => getContactById(state, meetingNoteUserId));
	const counterpartIsPatient = userContact?.role === UserRole.PATIENT;

	const openMeetingNote: boolean =
		hasMeetingNotePermission &&
		!!meetingNoteUserId &&
		counterpartIsPatient &&
		callDuration > AppConfig.MIN_MEETING_NOTES_DURATION_SECONDS;

	if (openMeetingNote) {
		yield put(meetingNotesActions.openConfirmationDialog(meetingNoteUserId));
	}
}

/**
 * Returns user id of whom the meeting note dialog would be opened
 *
 * Returns null if the participants who the local user interacted are more than 1
 *
 */
function* getMeetingNoteUserId(activeCall: ActiveCall, localUserId: number) {
	const participantsInteracted = getParticipantsInteracted(activeCall, localUserId);

	if (participantsInteracted.length > 1) {
		return null;
	}

	return Number(participantsInteracted[0]);
}

function getCallDuration(activeCall: ActiveCall, localUserId: number) {
	const luParticipantData = activeCall.participantMap[localUserId];

	// Calculate duration by subtracting `joinedTime` from `leaveTime`
	const duration = !luParticipantData.joinedTime
		? 0
		: Math.floor(
				((luParticipantData.leaveTime || Date.now()) - luParticipantData.joinedTime) /
					TIME.MILLISECONDS_IN_SECOND,
		  );
	return duration;
}

function* openRateCallQualityDialog(activeCall: ActiveCall, authUser: AuthUser) {
	const callDuration = yield call(getCallDuration, activeCall, authUser.id);

	const hadTalkedWithParticipants = getParticipantsInteracted(activeCall, authUser.id).length >= 1;

	const openRateCall = callDuration > AppConfig.MIN_DURATION_FOR_RATE_CALL_FORM_SECONDS && hadTalkedWithParticipants;
	if (openRateCall) {
		yield put(rateCallActions.showRateCallForm(activeCall.roomId));
	}
}

function getParticipantsInteracted(activeCall: ActiveCall, localUserId: number) {
	return Object.keys(activeCall.participantMap).filter(
		(id) => id !== localUserId.toString() && !!activeCall.participantMap[id].joinedTime,
	);
}
