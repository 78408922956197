import {ThoughtRecord, ThoughtRecordStage} from "@sense-os/goalie-js";
import {transformDiaryEntryGscheme} from "services/tracking/eventViewDataHelpers";
import {CustomTrackerSensor, MeasureValuesMap} from "../../tracker/customTracker/customTrackerTypes";
import {
	createEventViewId,
	getSensorNamesFromObject,
	recursivelyGetSensorDataBySensorName,
	shouldFilterDiaryEntry,
} from "../../ts/redux/tracking/TrackingHelper";
import {
	SensorDatum,
	Sensors,
	EventViewData,
	EventViewType,
	DiaryEntrySensorEventView,
	ThoughtRecordsSensorEventView,
	DiaryEntry,
	MeetingNote,
	CustomTrackerEventView,
	GScheme,
} from "../../ts/redux/tracking/TrackingTypes";

export function transformSensorCustomTrackerIntoClientActivity(
	sensorCustomTracker: SensorDatum<CustomTrackerSensor>,
): CustomTrackerEventView {
	return {
		id: createEventViewId(EventViewType.CUSTOM_TRACKER, sensorCustomTracker.id, sensorCustomTracker.startTime),
		ownerId: sensorCustomTracker.userId,
		title: sensorCustomTracker.sensorName,
		startTime: sensorCustomTracker.startTime,
		endTime: sensorCustomTracker.endTime,
		type: EventViewType.CUSTOM_TRACKER,
		source: {
			id: sensorCustomTracker.id,
			trackers: {
				sensorId: sensorCustomTracker.id,
				trackerName: sensorCustomTracker.sensorName,
				date: sensorCustomTracker.createdAt,
				values: Object.entries(sensorCustomTracker.value.measures).reduce(
					(trackerValues: MeasureValuesMap, [key, value]) => {
						trackerValues[key] = value.sensorData.value;
						return trackerValues;
					},
					{},
				),
				note: sensorCustomTracker.value?.note,
			},
		},
		createdAt: sensorCustomTracker.createdAt,
		updatedAt: sensorCustomTracker.updatedAt,
		createdBy: sensorCustomTracker.createdBy,
		updatedBy: sensorCustomTracker.updatedBy,
	};
}

/**
 * Transforms diary entry, meeting note, thought record, and check-in feeling SensorDatum into EventViewData.
 * Returns `null` if sensorDatum doesn't have transformer
 *
 * @param sensorDatum
 */
export function transformSensorDatumIntoClientActivity(sensorDatum: SensorDatum<any>): EventViewData | null {
	if (isDiaryEntrySensor(sensorDatum)) {
		return transformDiaryEntry(sensorDatum);
	}
	if (isMeetingNoteSensor(sensorDatum)) {
		return transformMeetingNoteSensorDatum(sensorDatum);
	}
	if (isThoughtRecordSensor(sensorDatum)) {
		return transformThoughtRecord(sensorDatum);
	}
	if (isCheckInFeelingSensor(sensorDatum)) {
		return transformSensorDatum(sensorDatum, EventViewType.CHECK_IN_FEELING);
	}
	return null;
}

export function isDiaryEntrySensor(sensorDatum: SensorDatum<any>): boolean {
	return sensorDatum.sensorName === Sensors.DIARY;
}
export function isMeetingNoteSensor(sensorDatum: SensorDatum<any>): boolean {
	return sensorDatum.sensorName === Sensors.MEETING_NOTE;
}
export function isThoughtRecordSensor(sensorDatum: SensorDatum<any>): boolean {
	// Thought record sensor is basically a `gscheme` sensor with version > 1
	return sensorDatum.sensorName === Sensors.GSCHEME && sensorDatum.version > 1;
}

export function isCheckInFeelingSensor(sensorDatum: SensorDatum<any>): boolean {
	return sensorDatum.sensorName === Sensors.CHECK_IN_FEELING;
}
/**
 * Transforms thought record sensor into EventViewData.
 *
 * @param sensorDatum
 */
function transformThoughtRecord(sensorDatum: SensorDatum<ThoughtRecord>): EventViewData {
	const eventViewData = transformSensorDatum(sensorDatum, EventViewType.THOUGHT_RECORDS_SENSOR);
	eventViewData.sensors = [Sensors.THOUGHT_RECORDS];
	const thoughtRecordValue: GScheme = sensorDatum.value;
	eventViewData.title = thoughtRecordValue.gebeurtenis ?? sensorDatum.value.eventName;

	// Show alternative thought as subtitle if thought record has reached step 3 / completed
	if (
		sensorDatum.value.alternativeThought &&
		[
			ThoughtRecordStage.Step3AUnfinished,
			ThoughtRecordStage.Step3AFinished,
			ThoughtRecordStage.Step3BUnfinished,
			ThoughtRecordStage.Step3BFinished,
			ThoughtRecordStage.Step3Finished,
			ThoughtRecordStage.Complete,
		].indexOf(sensorDatum.value.stage) !== -1
	) {
		eventViewData.subtitle = sensorDatum.value.alternativeThought;
	}

	return eventViewData;
}

/**
 * Transforms diary entry sensor into EventViewData.
 *
 * @param sensorDatum
 */
function transformDiaryEntry(sensorDatum: SensorDatum<DiaryEntry>): EventViewData {
	// Transform ThoughtRecord data which exist in Diary Entry.
	// This is because ThoughtRecords which exist in Diary entry are not usable until we transform them manually... :sad_faces:
	const diaryEntryWithThoughtRecord = transformDiaryEntryGscheme(sensorDatum);
	const hasThoughtRecord = !!diaryEntryWithThoughtRecord.value.gscheme;

	const eventViewData = transformSensorDatum(diaryEntryWithThoughtRecord, EventViewType.DIARY_ENTRY_SENSOR);

	// If diary entry has no title, use `description` as title replacement to be shown to the user
	if (!eventViewData.title && sensorDatum.value.description) {
		// Use description as title by default
		eventViewData.title = sensorDatum.value.description;
	} else if (hasThoughtRecord) {
		// If diary entry doesn't have `title` or `description`, use `thoughtrecord`'s eventname as title replacement
		const {title, subtitle} = transformThoughtRecord(diaryEntryWithThoughtRecord.value.gscheme.sensorData);
		eventViewData.title = title;
		eventViewData.subtitle = subtitle;
		// Replace GSCHEME with THOUGHT_RECORDS
		eventViewData.sensors = [
			...eventViewData.sensors.filter((sensor) => sensor !== Sensors.GSCHEME),
			Sensors.THOUGHT_RECORDS,
		];
	}

	return eventViewData;
}

/**
 * Transforms meeting note sensor into EventViewData.
 *
 * @param sensorDatum
 */
function transformMeetingNoteSensorDatum(sensorDatum: SensorDatum<MeetingNote>) {
	const activity = transformSensorDatum(sensorDatum, EventViewType.MEETING_NOTE_SENSOR);

	// Use `note` as title if `title` is empty
	if (!activity.title) {
		activity.title = sensorDatum.value.note;
	}

	return activity;
}

/**
 * Transforms `SensorDatum` into `EventViewData`
 *
 * @param sensorDatum
 * @param type
 */
function transformSensorDatum(
	sensorDatum: SensorDatum<any>,
	type:
		| EventViewType.DIARY_ENTRY_SENSOR
		| EventViewType.MEETING_NOTE_SENSOR
		| EventViewType.THOUGHT_RECORDS_SENSOR
		| EventViewType.CHECK_IN_FEELING,
): EventViewData {
	return {
		id: createEventViewId(type, sensorDatum.id, sensorDatum.startTime),
		ownerId: sensorDatum.userId,
		title: sensorDatum.value.title,
		startTime: sensorDatum.startTime,
		endTime: sensorDatum.endTime,
		type,
		source: sensorDatum,
		sensors: [sensorDatum.sensorName, ...getSensorNamesFromObject(sensorDatum)],
		createdAt: sensorDatum.createdAt,
		updatedAt: sensorDatum.updatedAt,
		createdBy: sensorDatum.createdBy,
		createdByProfile: sensorDatum.createdByProfile,
		updatedBy: sensorDatum.updatedBy,
		updatedByProfile: sensorDatum.createdByProfile,
	};
}

/**
 * Returns diary entries which doesn't exist in given `dpData` (Daily planner EventViewData)
 *
 * @param data
 * @param dpData
 */
export function getFilteredDiaryEntries(data: DiaryEntrySensorEventView[], dpData: EventViewData[]) {
	const plannedEventReflections = getPlannedEventReflections(dpData);
	return data.filter((activity) => filterDiaryEntryReflections(activity, plannedEventReflections));
}

/**
 * Returns thought records which doesn't exist in given `diary entries`
 *
 * @param data
 * @param dpData
 */
export function getFilteredThoughtRecords(
	data: ThoughtRecordsSensorEventView[],
	diaryEntries: DiaryEntrySensorEventView[],
) {
	const diaryEntrySensors = diaryEntries.map(({source}) => source);
	return data.filter((thoughtRecordActivity) => filterThoughtRecords(thoughtRecordActivity, diaryEntrySensors));
}

/**
 * Returns all reflections (Diary entries) that exists in given `EventViewData[]`
 *
 *  @param data
 */
function getPlannedEventReflections(data: EventViewData[]): SensorDatum<DiaryEntry>[] {
	// List of EventViewType that may contains diary entry (planned event reflections)
	const eventTypesWithReflection = [
		EventViewType.RECURRING_PLANNED_EVENT,
		EventViewType.PLANNED_EVENT_SENSOR,
		EventViewType.THERAPY_SESSION_SENSOR,
	];
	const plannedEvents = data.filter(({type}) => eventTypesWithReflection.includes(type)).map((d) => d.source);

	const reflections = [];
	// Get all diary entry sensors
	recursivelyGetSensorDataBySensorName(plannedEvents, Sensors.DIARY, reflections);

	return reflections;
}

/**
 * Returns true if Diary Entry should be filtered out because it exists in the given `reflections`
 *
 * @param data
 * @param reflections
 */
function filterDiaryEntryReflections(data: DiaryEntrySensorEventView, reflections: SensorDatum<DiaryEntry>[]): boolean {
	return shouldFilterDiaryEntry(data.source, reflections);
}

/**
 * Returns true if Thought record should be filtered out because it exists in the given `diaryEntries`
 *
 * @param data
 * @param diaryEntries
 */
function filterThoughtRecords(data: ThoughtRecordsSensorEventView, diaryEntries: SensorDatum<DiaryEntry>[]): boolean {
	return !diaryEntries.some((diaryEntry) => diaryEntry.value.gscheme?.sensorData.id === data.source.id);
}
