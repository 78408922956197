import {put, select, takeEvery, call} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {calendarActions} from "../redux/calendarActions";
import {getSelectedDate, getCalendarAccount} from "../redux/calendarSelectors";
import {shiftDate, getTodaysDate} from "../helpers/calendarHelpers";
import {CalendarAccount, DateString} from "../calendarTypes";

function* handleGoToPrevWeek() {
	let userSelectedDate: DateString = yield select(getSelectedDate);
	userSelectedDate = shiftDate(userSelectedDate, -7);
	yield put(calendarActions.setDate(userSelectedDate));
	yield call(getCalendarEvents);
}

function* handleGoToNextWeek() {
	let userSelectedDate: DateString = yield select(getSelectedDate);
	userSelectedDate = shiftDate(userSelectedDate, 7);
	yield put(calendarActions.setDate(userSelectedDate));
	yield call(getCalendarEvents);
}

function* handleGoToPrevDay() {
	let userSelectedDate: DateString = yield select(getSelectedDate);
	userSelectedDate = shiftDate(userSelectedDate, -1);
	yield put(calendarActions.setDate(userSelectedDate));
	yield call(getCalendarEvents);
}

function* handleGoToNextDay() {
	let userSelectedDate: DateString = yield select(getSelectedDate);
	userSelectedDate = shiftDate(userSelectedDate, 1);
	yield put(calendarActions.setDate(userSelectedDate));
	yield call(getCalendarEvents);
}

function* handleGoToToday() {
	yield put(calendarActions.setDate(getTodaysDate()));
	yield call(getCalendarEvents);
}

function* getCalendarEvents() {
	const calendarAccount: CalendarAccount = yield select(getCalendarAccount);
	if (!calendarAccount) {
		return;
	}
	yield put(calendarActions.getCalendarEvents.request());
}

export default function* () {
	yield takeEvery(getType(calendarActions.goToPrevWeek), handleGoToPrevWeek);
	yield takeEvery(getType(calendarActions.goToNextWeek), handleGoToNextWeek);
	yield takeEvery(getType(calendarActions.goToPrevDay), handleGoToPrevDay);
	yield takeEvery(getType(calendarActions.goToNextDay), handleGoToNextDay);
	yield takeEvery(getType(calendarActions.goToToday), handleGoToToday);
}
