import produce from "immer";
import {getType} from "typesafe-actions";
import {onlineUsersActions, OnlineUsersActionsType} from "./onlineUsersActions";
import {OnlineUsersState} from "./onlineUsersType";

/** A map: [user ID] => online availability */
const initialState: OnlineUsersState = {};

export const onlineUsersReducer = produce(
	(state: OnlineUsersState = initialState, action: OnlineUsersActionsType): OnlineUsersState => {
		switch (action.type) {
			case getType(onlineUsersActions.availabilityStatusUpdate): {
				const {userId, statusDescriptor} = action.payload;

				if (state[userId]) {
					state[userId].availability = statusDescriptor.availability;
					state[userId].lastAvailable = statusDescriptor.lastAvailable;
					state[userId].status = statusDescriptor.status;
				} else {
					state[userId] = statusDescriptor;
				}
				return;
			}

			case getType(onlineUsersActions.resetUsersPresenceType): {
				state = initialState;
				return;
			}
		}
	},
	initialState,
);
