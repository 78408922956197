import {fork} from "redux-saga/effects";
import calendarNavigationSaga from "./calendarNavigationSaga";
import connectCalendarSaga from "./connectCalendarSaga";
import revokeCalendarSaga from "./revokeCalendarSaga";
import getCalendarAccountSaga from "./getCalendarAccountSaga";
import getCalendarEventsSaga from "./getCalendarEventsSaga";
import createInternalEventSaga from "./internalEvent/createInternalEventSaga";
import updateInternalEventSaga from "./internalEvent/updateInternalEventSaga";
import deleteInternalEventSaga from "./internalEvent/deleteInternalEventSaga";
import getExternalEventAccessSaga from "./externalEvent/getExternalEventAccessSaga";
import updateExternalEventSaga from "./externalEvent/updateExternalEventSaga";
import deleteExternalEventSaga from "./externalEvent/deleteExternalEventSaga";
import saveSessionEventSaga from "./sessionEvent/saveSessionEventSaga";
import getSessionDetailsSaga from "./sessionEvent/getSessionDetailsSaga";
import deleteSessionEventSaga from "./sessionEvent/deleteSessionEventSaga";
import undoActionSaga from "./undoActionSaga";

export default function* () {
	yield fork(calendarNavigationSaga);
	yield fork(connectCalendarSaga);
	yield fork(revokeCalendarSaga);
	yield fork(getCalendarAccountSaga);
	yield fork(getCalendarEventsSaga);
	yield fork(createInternalEventSaga);
	yield fork(updateInternalEventSaga);
	yield fork(deleteInternalEventSaga);
	yield fork(getExternalEventAccessSaga);
	yield fork(updateExternalEventSaga);
	yield fork(deleteExternalEventSaga);
	yield fork(saveSessionEventSaga);
	yield fork(getSessionDetailsSaga);
	yield fork(deleteSessionEventSaga);
	yield fork(undoActionSaga);
}
