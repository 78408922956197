import {getType} from "typesafe-actions";

import analyticsService from "../analytics/AnalyticsService";
import {twoFAActions} from "./redux/twoFAActions";

const trackedActions = [
	twoFAActions.enableFromNewFeaturePopup,
	twoFAActions.enableFromOnboarding,
	twoFAActions.enableFromSettings,
	twoFAActions.disableTwoFactorAuth,
	twoFAActions.changeTwoFactorAuthDevice,
	twoFAActions.loginIsBlockedForFailing2FA,
].map(getType);

export const twoFAEvents = analyticsService.trackReduxActions(trackedActions, () => null);
