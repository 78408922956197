import {PlannedEventApi, PlannedEventService as BasePlannedEventService} from "@sense-os/goalie-js";
import autobind from "autobind-decorator";
import {Injectable} from "IoC/Injectable";
import {createTimezoneUnawareTimeString, createUTCDateString} from "utils/time";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";

export interface IPlannedEventService {
	/**
	 * Initializes the plannedEventService service.
	 */
	init(): void;

	/** Direct access to the PlannedEventApi SDK */
	readonly sdk: PlannedEventApi;

	/**
	 * Delete INCOMPLETE recurring planned events
	 *
	 * @param {string} plannedEventId ID of the event that wants to be deleted
	 * @param {Date} occurrenceTime time of the event that wants to be deleted
	 * @param {boolean} deleteFollowingEvents True if user wants to delete following events
	 */
	deleteRecurringPlannedEvent: (
		plannedEventId: string,
		occurrenceTime: Date,
		deleteFollowingEvents: boolean,
	) => Promise<void>;
}
/**
 * @deprecated
 */
@autobind
export class PlannedEventService implements IPlannedEventService, Injectable {
	public readonly c: string = "[PlannedEventService]";

	private _sdk: PlannedEventApi;

	private log = createLogger("PlannedEventService", SentryTags.Tracking);

	/** @inheritDoc */
	public get sdk(): PlannedEventApi {
		if (!this._sdk) {
			this.log.debug("The service is not ready. Call init()!");
			return;
		}
		return this._sdk;
	}

	/** @inheritDoc */
	public init(): void {
		this.log.debug("initializing...");
		this._sdk = BasePlannedEventService;
	}

	/** @inheritDoc */
	public async deleteRecurringPlannedEvent(
		plannedEventId: string,
		occurrenceTime: Date,
		deleteFollowingEvents: boolean,
	): Promise<void> {
		await this.sdk.plannedEventIdOccurrenceOccurrenceTimeExcludePost(
			this.sessionId,
			plannedEventId,
			this.convertOccurrenceTimeToTimezoneUnaware(occurrenceTime),
			deleteFollowingEvents,
		);
	}

	/**
	 * Returns sessionId of Logged in user
	 */
	private get sessionId(): string {
		return getSessionId();
	}

	/**
	 * Converts occurrenceTime to UTC and make it Timezone unaware
	 *
	 * Example:
	 * "2019-05-20T18:00:00.000Z"
	 * If user is in GMT+2 -> "2019-05-20T20:00:00.000"
	 * If user is in GMT+5 -> "2019-05-20T23:00:00.000"
	 * ....
	 *
	 * @param {Date} occurrenceTime
	 */
	private convertOccurrenceTimeToTimezoneUnaware(occurrenceTime: Date): string {
		const utcDateString = createUTCDateString(occurrenceTime);
		return createTimezoneUnawareTimeString(new Date(utcDateString));
	}
}
