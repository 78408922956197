import {IReferralTherapyType, LanguageTag, REFERRAL_QUESTIONNAIRE_STATUS} from "@sense-os/goalie-js";
import {
	IReferredClientStatusOption,
	REFERRAL_QUESTIONNAIRE_MENU,
	referralQuestionnaireMenuType,
} from "../referralClientTypes";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

export const updatedAtLabel: Record<referralQuestionnaireMenuType, string> = {
	[REFERRAL_QUESTIONNAIRE_STATUS.OPEN]: localization.formatMessage(
		strTranslation.REFERRAL_CLIENT.attribute.created_at,
	),
	[REFERRAL_QUESTIONNAIRE_STATUS.UNREVIEWED]: localization.formatMessage(
		strTranslation.REFERRAL_CLIENT.attribute.updated_at,
	),
	[REFERRAL_QUESTIONNAIRE_STATUS.ACCEPTED]: localization.formatMessage(
		strTranslation.REFERRAL_CLIENT.attribute.accepted_at,
	),
	[REFERRAL_QUESTIONNAIRE_STATUS.DECLINED]: localization.formatMessage(
		strTranslation.REFERRAL_CLIENT.attribute.declined_at,
	),
	[REFERRAL_QUESTIONNAIRE_MENU.PROCESS]: localization.formatMessage(
		strTranslation.REFERRAL_CLIENT.attribute.processed_at,
	),
};

export const referredClientStatusOptions: IReferredClientStatusOption[] = [
	{
		label: localization.formatMessage(strTranslation.REFERRAL_CLIENT.status.open),
		value: REFERRAL_QUESTIONNAIRE_STATUS.OPEN,
	},
	{
		label: localization.formatMessage(strTranslation.REFERRAL_CLIENT.status.unreviewed),
		value: REFERRAL_QUESTIONNAIRE_STATUS.UNREVIEWED,
	},
	{
		label: localization.formatMessage(strTranslation.REFERRAL_CLIENT.status.accepted),
		value: REFERRAL_QUESTIONNAIRE_STATUS.ACCEPTED,
	},
	{
		label: localization.formatMessage(strTranslation.REFERRAL_CLIENT.status.declined),
		value: REFERRAL_QUESTIONNAIRE_STATUS.DECLINED,
	},
];

const activeLanguage: LanguageTag = localization.getLocale();

// special case for SOL
const unDeterminedTranslation = {
	[LanguageTag.EN]: "Determine in intake",
	[LanguageTag.NL]: "In intake bepalen",
};

export const unDeterminedTherapy: IReferralTherapyType = {
	id: 0,
	type: unDeterminedTranslation[activeLanguage],
};
