import {CallSignalType, TerminationReason} from "@sense-os/goalie-js";
import {DISC} from "IoC/DISC";
import {put, call, takeEvery} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {callActions} from "../../redux/callActions";

/**
 * Handle reject signal from counterpart.
 * - Stop call timeout (Only if logged in user is the call initiator)
 * - Update leave time in redux state
 * - Send call summary to the room
 */
function* handleRejectSignal(action: ActionType<typeof callActions.handleRejectSignal>) {
	const {signal} = action.payload;

	if (signal.type !== CallSignalType.Response) {
		return;
	}

	const {senderId} = signal;
	const leaveTime = Date.now();

	// Stop call initiation timer
	yield put(callActions.stopTimeout(senderId));
	// Add counterpart `joinedTime` in redux state
	yield put(callActions.userLeaveCall(senderId, leaveTime));
	// Send call summary only to the participant who sends the signal (The one who rejected the call)
	yield call(DISC.getVideoCallService().sendCallSummary, TerminationReason.Rejected, senderId);
	// Check if we need to terminate the call, then send call summary to all participants that still in the call
	yield put(callActions.endCallIfNeeded(TerminationReason.Rejected));
	// Show toast to inform the user
	yield call(DISC.getVideoCallService().showTerminationToast, senderId, TerminationReason.Rejected);
}

export function* handleRejectSignalSaga() {
	yield takeEvery(callActions.handleRejectSignal, handleRejectSignal);
}
