/**
 * List of custom tracker sensor names
 */
export enum TrackerSensorName {
	researchEmotions = "tracker_3D_research_emotions",
	afraid = "tracker_afraid",
	aggression = "tracker_aggression",
	alcohol = "tracker_alcohol",
	angry = "tracker_angry",
	ashamed = "tracker_ashamed",
	askForHelp = "tracker_ask_for_help",
	assertiveness = "tracker_assertiveness",
	automaticPilot = "tracker_automatic_pilot",
	avoidance = "tracker_avoidance",
	backache = "tracker_backache",
	binge = "tracker_binge",
	bodycheck = "tracker_bodycheck",
	cannabis = "tracker_cannabis",
	cocaine = "tracker_cocaine",
	compulsion = "tracker_compulsion",
	cramped = "tracker_cramped",
	craving = "tracker_craving",
	curious = "tracker_curious",
	depersonalization = "tracker_depersonalization",
	depressed = "tracker_depressed",
	difficulty = "tracker_difficulty",
	disoriented = "tracker_disoriented",
	distracted = "tracker_distracted",
	energy = "tracker_energy",
	excited = "tracker_excited",
	exercise = "tracker_exercise",
	flashbacks = "tracker_flashbacks",
	fatigue = "tracker_fatigue",
	gratitude = "tracker_gratitude",
	guilty = "tracker_guilty",
	happy = "tracker_happy",
	headache = "tracker_headache",
	hyperAlert = "tracker_hyper_alert",
	hyperAroused = "tracker_hyper_aroused",
	hyperAssertiveness = "tracker_hyper_assertiveness",
	inDanger = "tracker_in_danger",
	insecure = "tracker_insecure",
	loneliness = "tracker_loneliness",
	memoryLoss = "tracker_memory_loss",
	mindfulness = "tracker_mindfulness",
	nightmares = "tracker_nightmares",
	pain = "tracker_pain",
	panicAttack = "tracker_panic_attacks",
	panicAttackWithPhysicalSensation = "tracker_panic_attacks_with_physical_sensation",
	physicalWeakness = "tracker_physical_weakness",
	physicallyFrozen = "tracker_physically_frozen",
	positiveThings = "tracker_positive_things",
	restAndRelax = "tracker_rest_and_relax",
	sad = "tracker_sad",
	safetyBehaviour = "tracker_safety_behaviour",
	sideEffectsMedication = "tracker_side_effects_medication",
	smoking = "tracker_smoking",
	socialAvoidance = "tracker_social_avoidance",
	socialDependent = "tracker_social_dependent",
	subAssertiveness = "tracker_sub_assertiveness",
	tension = "tracker_tension",
	weight = "tracker_weight",
	withdrawal = "tracker_withdrawal_symptoms",
	worry = "tracker_worry",

	// trackers v4
	addictionBehavior = "tracker_addiction_behaviour",
	anxiety = "tracker_anxiety",
	attentionDeficit = "tracker_attention_deficit",
	cognitiveComplaints = "tracker_cognitive_complaints",
	dissociation = "tracker_dissociation",
	food = "tracker_food",
	hallucinations = "tracker_hallucinations",
	hyperActivity = "tracker_hyperactivity",
	impulsivity = "tracker_impulsivity",
	overstimulated = "tracker_overstimulated",
	physicalActivity = "tracker_physical_activity",
	sleep = "tracker_sleep",
	suspiciousness = "tracker_suspiciousness",
}

/**
 * Pain attack with physical sensation measures
 */
export enum PanicAttackSensorMeasures {
	measurePalpitations = "measure_palpitations",
	measureSweating = "measure_sweating",
	measureShaking = "measure_shaking",
	measureShortnessOfBreath = "measure_shortness_of_breath",
	measureFeelingOfSuffocation = "measure_feeling_of_suffocation",
	measurePainOnChest = "measure_pain_on_chest",
	measureNausea = "measure_nausea",
	measureDizzy = "measure_dizzy",
	measureFeelingUnreal = "measure_feeling_unreal",
	measureFearOfLosingControl = "measure_fear_of_losing_control",
	measureFearOfDying = "measure_fear_of_dying",
	measureTinglingInHandsOrFeet = "measure_tingling_in_hands_or_feet",
	measureSuddenlyWarmOrCold = "measure_suddenly_warm_or_cold",
	// new measure from tracker v4
	measureFeelingUnwell = "measure_feeling_unwell",
}

/**
 * Cognitive Complain measures
 */
export enum CognitiveComplaintMeasures {
	measureAbsentMinded = "measure_absentMinded",
	measureDisoriented = "measure_disoriented",
	measureForgetfulness = "measure_forgetfulness",
	measureProblemsConcentrating = "measure_problems_concentrating",
}

/**
 * Overstimulated measures
 */
export enum OverstimulatedMeasures {
	measureAttentionOrConcentrationProblem = "measure_attention_or_Concentration_problem",
	measureMoodSwings = "measure_mood_swings",
	measureIrritableOrEmotional = "measure_irritable_or_emotional",
	measureRestlessness = "measure_restlessness",
	measureProcrastination = "measure_procrastination",
	measureSleepingProblems = "measure_sleeping_problems",
	measureInsecureOrAnxious = "measure_insecure_or_anxious",
	measureFeelingOverwhelmedOrDifficultyWithOrganisation = "measure_feeling_overwhelmed_or_difficulty_with_organisation",
	measureInARush = "measure_in_a_rush",
	measureLowMood = "measure_low_mood",
}

/**
 * Hallucinations measures
 */
export enum HallucinationsMeasures {
	measureSmell = "measure_smell",
	measureSoundOrVoices = "measure_sound_or_voices",
	measureTaste = "measure_taste",
	measureTouch = "measure_touch",
	measureVisual = "measure_visual",
}

/**
 * Dissociation measures
 */
export enum DissociationMeasures {
	measureDepersonalization = "measure_depersonalization",
	measureDerealization = "measure_derealization",
}

/**
 * Side Effect Medication measures
 */
export enum SideEffectMedicationMeasures {
	measureHeadache2 = "measure_headache_2",
	measureWeightGain = "measure_weight_gain",
	measureDizzy = "measure_dizzy",
	measureDryMouth = "measure_dry_mouth",
	measureMuscleSpasmsAndCramps = "measure_muscle_spasms_and_cramps",
	measureLossOfSexDrive = "measure_loss_of_sex_drive",
	measureAnxiety = "measure_anxiety",
	measureMemoryLoss2 = "measure_memory_loss_2",
	measureSuicidalThoughts = "measure_suicidal_thoughts",
	measureSuspicion2 = "measure_suspicion_2",
	measureConstipation = "measure_constipation",
	measureSleepinessOrProblemsSleeping = "measure_sleepiness_or_problems_sleeping",
	measureLowMood = "measure_low_mood",
	measureNausea = "measure_nausea",
}
