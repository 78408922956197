import {AppState} from "redux/AppState";
import {createSelector} from "reselect";
import {GroupTherapy} from "../groupTherapy";
import {LoadingState} from "constants/redux";
import {MemberRole} from "@sense-os/goalie-js";
import {getTrackerCategories} from "../../tracker/customTracker/redux/customTrackerSelectors";

export const getGroupTherapyList = createSelector(
	(state: AppState) => state.groupTherapy.groupMap,
	(groupMap): GroupTherapy[] => {
		return Object.entries(groupMap).map((items) => items[1]);
	},
);
export const getSortedGroupTherapyList = createSelector(getGroupTherapyList, (group) => {
	return [...group].sort((a, b) => {
		if (a.createdAt > b.createdAt) {
			return -1;
		}
		return 1;
	});
});

export const getLoadingState = (state: AppState) => state.groupTherapy.loadingState;

export const getSavingState = (state: AppState) => state.groupTherapy.savingState;

export const getDeletingState = (state: AppState) => state.groupTherapy.deletingState;

export const isLoading = createSelector(getLoadingState, (loadingState) => loadingState === LoadingState.LOADING);

export const isShowingLoadingIcon = createSelector(
	getLoadingState,
	getSavingState,
	getDeletingState,
	(loading, saving, deleting) =>
		loading === LoadingState.LOADING || saving === LoadingState.LOADING || deleting === LoadingState.LOADING,
);

export const getRegistrationFetchingState = (state: AppState) => state.groupTherapy.registrationFetchState;

export const getRegistrationSavingState = (state: AppState) => state.groupTherapy.registrationSavingState;

export const getRegistrationDeletingState = (state: AppState) => state.groupTherapy.registrationDeletingState;

export const getRegistrationDetailFetchingState = (state: AppState) =>
	state.groupTherapy.registrationDetailFetchingState;

export const isRegistrationLoading = createSelector(
	getRegistrationFetchingState,
	(loading) => loading === LoadingState.LOADING,
);

export const isSaveOrDeleteRegistration = createSelector(
	getRegistrationSavingState,
	getRegistrationDeletingState,
	(saving, deleting) => saving === LoadingState.LOADING || deleting === LoadingState.LOADING,
);

export const getGroupTherapyById = (id: number) => (state: AppState) => state.groupTherapy.groupMap[id];

export const getGroupTherapyListCount = createSelector(getGroupTherapyList, (list) => list.length);

export const getGroupForEditDialog = (state: AppState) => state.groupTherapy.groupForDialog;

export const hasAnyGroupForGroupTherapy = createSelector(getGroupTherapyList, (groups) => groups.length > 0);

export const getRegistrationsByGroupId = (id: number) => (state: AppState) =>
	state.groupTherapy.groupMap[id]?.groupRegistrations || [];

export const getRegistrationsTotalByGroupId = (id: number) => (state: AppState) =>
	state.groupTherapy.groupMap[id]?.totalRegistrations || 0;

export const getGroupMembersByMemberRole = (id: number, role: MemberRole) => (state: AppState) => {
	if (role === MemberRole.THERAPIST) {
		return state.groupTherapy.groupMap[id]?.therapists || [];
	}

	if (role === MemberRole.CLIENT) {
		return state.groupTherapy.groupMap[id]?.clients || [];
	}
};

export const getRegistrationDetails = (state: AppState) => state.groupTherapy.registrationDetails;

export const getRelatedSensorDataMap = (state: AppState) => state.groupTherapy.relatedSensorDataMap;

export const getRelatedSensorList = createSelector(getRelatedSensorDataMap, (relatedSensorDataMap) => {
	return Object.keys(relatedSensorDataMap).map((userId) => {
		return relatedSensorDataMap[userId];
	});
});

export const getGroupTherapyTrackersState = (state: AppState) => state.groupTherapy.trackers;

export const getGroupTherapyTrackersFetchingState = (state: AppState) => state.groupTherapy.trackersFetchingState;

export const getGroupTherapyTrackersSavingState = (state: AppState) => state.groupTherapy.trackersSavingState;

const getEnabledGroupTherapyTrackers = createSelector(getGroupTherapyTrackersState, (trackers) => {
	return trackers.filter((tracker) => tracker.isEnabled);
});

/**
 * Return tracker categories, filtered by enabled group therapy trackers for selected group therapy
 */
export const getGroupTherapyFilteredTrackerCategories = createSelector(
	[getTrackerCategories, getEnabledGroupTherapyTrackers],
	(trackerCategories, enabledTrackers) => {
		return trackerCategories.map((trackerCategory) => {
			return {
				...trackerCategory,
				trackers: trackerCategory.trackers.filter((tracker) => {
					return !enabledTrackers.some((enabledTracker) => enabledTracker.id === tracker.id);
				}),
			};
		});
	},
);

export const getManageTrackersDialogState = (state: AppState) => state.groupTherapy.isManageTrackersDialogOpened;
