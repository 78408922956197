import {getType} from "typesafe-actions";
import {SidebarNavActionType, sidebarNavActions} from "./sidebarNavActions";
import {SidebarNavState} from "../sidebarNavTypes";
import {isMobileOnly} from "react-device-detect";

const initialState: SidebarNavState = {
	isSidebarCollapse: isMobileOnly ? true : false,
};

export default function sidebarNavReducer(
	state: SidebarNavState = initialState,
	action: SidebarNavActionType,
): SidebarNavState {
	switch (action.type) {
		case getType(sidebarNavActions.expandSidebar):
			return {
				...state,
				isSidebarCollapse: false,
			};
		case getType(sidebarNavActions.collapseSidebar):
			return {
				...state,
				isSidebarCollapse: true,
			};
		default:
			return state;
	}
}
