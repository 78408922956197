import {CORE_TRACKER_SENSOR_KEY, TrackerCategory, TRACKER_MODEL_NAME} from "@sense-os/goalie-js";
import strTranslation from "../../assets/lang/strings";
import localization from "../../localization/Localization";
import {getTrackerIdBySensorName} from "./trackersSdkHelper";

/** List of core tracker sensors */
export enum CoreTrackerSensorNames {
	check_in = "check_in",
	diary_entry = "diary_entry",
	gscheme = "gscheme",
	meeting_note = "meeting_note",
	step_count = "step_count",
}

/** List of core tracker model name except `SensorData` */
export enum CoreTrackerModelNames {
	Homework = "Homework",
	BehaviorExperiment = "BehaviorExperiment",
	Exercise = "Exercise",
}

/**
 * Returns true if Tracker is a core trackers.
 */
export function isCoreTrackers(sensorName: string): boolean {
	return !!CoreTrackerSensorNames[sensorName] || !!CoreTrackerModelNames[sensorName];
}

/** List of core tracker id */
export enum CoreTrackerId {
	BEHAVIOR_EXPERIMENT = getTrackerIdBySensorName(TRACKER_MODEL_NAME.BEHAVIOR_EXPERIMENT),
	CHECK_IN = getTrackerIdBySensorName(CORE_TRACKER_SENSOR_KEY.CHECK_IN),
	DIARY_NOTE = getTrackerIdBySensorName(CORE_TRACKER_SENSOR_KEY.DIARY_ENTRY),
	MEETING_NOTES = getTrackerIdBySensorName(CORE_TRACKER_SENSOR_KEY.MEETING_NOTE),
	PSYCHOEDUCATION = getTrackerIdBySensorName(TRACKER_MODEL_NAME.HOMEWORK),
	THOUGHT_RECORD = getTrackerIdBySensorName(CORE_TRACKER_SENSOR_KEY.GSCHEME),
	STEP_COUNT = getTrackerIdBySensorName(CORE_TRACKER_SENSOR_KEY.STEP_COUNT),
	CUSTOM_EXERCISE = getTrackerIdBySensorName(TRACKER_MODEL_NAME.CUSTOM_EXERCISE),
}

/** Default Tracker Category */
export const defaultTrackerCategory = "tracker.category.default";

/** Get Filtered Tracker Categories without `defaultTrackerCategory` */
export function filterDefaultTrackerCategory(trackerCategory: TrackerCategory) {
	return trackerCategory.category !== defaultTrackerCategory;
}

/** Sensor Data Model Name */
export const SensorDataModelName = "SensorData";

/** Core Tracker Translation Map */
export const coreTrackerTranslationMap = {
	[CoreTrackerSensorNames.check_in]: strTranslation.ZZ_CORE_TRACKER.tracker.check_in.name,
	[CoreTrackerSensorNames.diary_entry]: strTranslation.ZZ_CORE_TRACKER.tracker.diary_entry.name,
	[CoreTrackerSensorNames.gscheme]: strTranslation.ZZ_CORE_TRACKER.tracker.gscheme.name,
	[CoreTrackerSensorNames.meeting_note]: strTranslation.ZZ_CORE_TRACKER.tracker.meeting_note.name,
	[CoreTrackerSensorNames.step_count]: strTranslation.ZZ_CORE_TRACKER.tracker.step_count.name,

	[CoreTrackerModelNames.BehaviorExperiment]: strTranslation.ZZ_CORE_TRACKER.tracker.behavior_experiment.name,
	[CoreTrackerModelNames.Homework]: strTranslation.ZZ_CORE_TRACKER.tracker.homework.name,
	[CoreTrackerModelNames.Exercise]: strTranslation.CUSTOM_EXERCISE.name,
};

/** Translated Core Tracker Name */
export const getTranslatedCoreTrackerName = (sensorName: string): string => {
	if (!!coreTrackerTranslationMap[sensorName]) {
		return localization.formatMessage(coreTrackerTranslationMap[sensorName]);
	}
	return "";
};
