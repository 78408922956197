import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";

import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";
import {toastActions} from "../../toaster/redux";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

import {groupTherapyActions} from "../redux/groupTherapyActions";
import groupTherapySDK from "../helpers/groupTherapySdk";

const log = createLogger("deleteGroupTherapySaga", SentryTags.GroupTherapy);

function* deleteAGroupTherapy(action: ActionType<typeof groupTherapyActions.deleteAGroupTherapy.request>) {
	const {id} = action.payload;
	const token: string = yield call(getSessionId);
	try {
		yield apiCallSaga(groupTherapySDK.deleteGroupTherapy, token, id);

		yield put(groupTherapyActions.deleteAGroupTherapy.success({id}));
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.GROUPS.toast_message.delete.success),
				type: "success",
			}),
		);
	} catch (err) {
		log.captureException(err, {message: "Failed to delete a group therapy."});

		yield put(groupTherapyActions.deleteAGroupTherapy.failure(err));
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.GROUPS.toast_message.delete.failed),
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.deleteAGroupTherapy.request, deleteAGroupTherapy);
}
