import {LoginResponse} from "@sense-os/goalie-js";
import {TwoFA} from "@sense-os/goalie-js/dist/twoFA/TwoFA";
import {OTPType, TwoFASetupConfig, TwoFAStatus} from "@sense-os/goalie-js/dist/twoFA/types";

export const twoFASDK = new TwoFA();

export async function fetchSetupKey(token: string): Promise<TwoFASetupConfig> {
	return twoFASDK.enable(token);
}

export async function postVerificationKey(token: string, otp: string): Promise<string[]> {
	const result = await twoFASDK.enableConfirm(token, otp);
	return result.staticTokens;
}

export async function disable2FA(token: string, data: {otp: number; type: OTPType}): Promise<void> {
	return twoFASDK.disable(token, data.otp.toString(), data.type);
}

export async function changeDeviceOTP(token: string, data: {otp: number; type: OTPType}): Promise<TwoFASetupConfig> {
	return twoFASDK.changeDevice(token, data.otp.toString(), data.type);
}

export async function changeDeviceConfirm(token: string, otp: string): Promise<string[]> {
	const result = await twoFASDK.changeDeviceConfirm(token, otp);
	return result.staticTokens;
}

export async function sendOTPForLogin(csrfToken: string, otp: string, type: OTPType): Promise<LoginResponse> {
	return twoFASDK.loginOTP(csrfToken, otp, type);
}

export async function getStatus(token?: string): Promise<TwoFAStatus> {
	return twoFASDK.status(token);
}

export async function resetPasswordOTP(
	token: string,
	uid: string,
	newPassword: string,
	otp: string,
	type: OTPType,
): Promise<void> {
	return twoFASDK.resetPasswordOTP(token, uid, newPassword, otp, type);
}

export async function generateNewRecoveryCodes(token: string, password: string): Promise<string[]> {
	const result = await twoFASDK.generateRecoveryCodes(token, password);
	return result.staticTokens;
}
