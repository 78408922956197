import {TreatmentStatus} from "@sense-os/goalie-js";

const sdk = new TreatmentStatus();

sdk.addComplaintsToTreatment = sdk.addComplaintsToTreatment.bind(sdk);
sdk.addTreatmentPersonnelToTreatment = sdk.addTreatmentPersonnelToTreatment.bind(sdk);
sdk.addTreatmentTypesToTreatment = sdk.addTreatmentTypesToTreatment.bind(sdk);
sdk.createTreatment = sdk.createTreatment.bind(sdk);
sdk.createTreatmentComplaint = sdk.createTreatmentComplaint.bind(sdk);
sdk.createTreatmentType = sdk.createTreatmentType.bind(sdk);
sdk.getTreatmentComplaints = sdk.getTreatmentComplaints.bind(sdk);
sdk.getTreatmentComplaintsOfTreatment = sdk.getTreatmentComplaintsOfTreatment.bind(sdk);
sdk.getTreatmentPersonnelsOfTreatment = sdk.getTreatmentPersonnelsOfTreatment.bind(sdk);
sdk.getTreatmentTerminatingReasons = sdk.getTreatmentTerminatingReasons.bind(sdk);
sdk.getTreatmentTypes = sdk.getTreatmentTypes.bind(sdk);
sdk.getTreatmentTypesOfTreatment = sdk.getTreatmentTypesOfTreatment.bind(sdk);
sdk.getAllTreatments = sdk.getAllTreatments.bind(sdk);
sdk.getTreatmentsOfAClient = sdk.getTreatmentsOfAClient.bind(sdk);
sdk.removeAComplaintOfTreatment = sdk.removeAComplaintOfTreatment.bind(sdk);
sdk.removeATreatmentTypeOfTreatment = sdk.removeATreatmentTypeOfTreatment.bind(sdk);
sdk.removeAPersonnelOfTreatment = sdk.removeAPersonnelOfTreatment.bind(sdk);
sdk.updateTreatment = sdk.updateTreatment.bind(sdk);
sdk.updateTreatmentPersonnelRole = sdk.updateTreatmentPersonnelRole.bind(sdk);
sdk.getTimeEntriesOwnerOfTreatment = sdk.getTimeEntriesOwnerOfTreatment.bind(sdk);

export default sdk;
