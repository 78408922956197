import {FC, ComponentType} from "react";
import {Tooltip, TooltipProps} from "@material-ui/core";
import {useIntl} from "react-intl";

export interface LocalizedTooltipProps extends Omit<TooltipProps, "title"> {
	/**
	 * Localisation key to be translated and put inside the Tooltip's content
	 */
	localizationKey: string;
	/**
	 * Localisation key to be translated and put inside the Tooltip's content
	 */
	localizationValues?: Record<string, string | number | boolean | Date>;
	/**
	 * Fallback for localisation failure (If localisation key doesn't exist in our translation file)
	 */
	defaultMessage?: string;
}

const LocalizedTooltip: FC<LocalizedTooltipProps> = (props) => {
	const intl = useIntl();
	const {localizationKey, localizationValues, defaultMessage, children, ...rest} = props;
	let titleText = intl.formatMessage({id: localizationKey, defaultMessage}, localizationValues);
	return (
		<Tooltip title={titleText} {...rest}>
			{children}
		</Tooltip>
	);
};

export function withLocalizedTooltip<P>(
	C: ComponentType<P>,
	localizationKey: string,
	localizationValues: Record<string, string | number | boolean | Date> = {},
) {
	return (props: P) => (
		<LocalizedTooltip localizationKey={localizationKey} localizationValues={localizationValues}>
			<C {...props} />
		</LocalizedTooltip>
	);
}
export default LocalizedTooltip;
