import {call, takeEvery, put, fork, delay} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {CalendarEventAttendee} from "@sense-os/goalie-js";

import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {toastActions} from "../../../toaster/redux/toastAction";
import loc from "../../../localization/Localization";
import strTranslation from "../../../assets/lang/strings";

import {calendarActions} from "../../redux/calendarActions";
import {calendarSDK} from "../../helpers/calendarSDK";
import {convertGuestListIntoEventAttendees, convertStartAndEndDateFormat} from "../../helpers/calendarHelpers";
import {CalendarEventFormValues} from "../../views/forms/formTypes";
import {getUndoButton} from "../../views/UndoButton";
import {UNDO_ACTION_TIMEOUT_MS, calendarEventScheduleMap} from "../../calendarTypes";

const log = createLogger("updateInternalEventSaga", SentryTags.Calendar);

const UNDO_KEY_PREFIX = "CALENDAR_internal_update_";

function* handleUpdateInternalEvent(action: ActionType<typeof calendarActions.updateInternalEvent.request>) {
	const {calendarId, eventId, formValues} = action.payload;
	const undoId = UNDO_KEY_PREFIX + eventId;

	yield put(
		toastActions.addToast({
			type: "info",
			message: loc.formatMessage(strTranslation.CALENDAR.event.updated.toast.text),
			persist: false,
			key: undoId,
			action: getUndoButton(undoId),
		}),
	);

	const deleteEventSagaTask = yield fork(updateInternalEventTask, calendarId, eventId, formValues);
	calendarEventScheduleMap.set(undoId, deleteEventSagaTask);
}

function* updateInternalEventTask(calendarId: string, eventId: string, formValues: CalendarEventFormValues) {
	yield delay(UNDO_ACTION_TIMEOUT_MS);

	try {
		const token: string = yield call(getSessionId);
		const addedAttendees: CalendarEventAttendee[] = yield call(
			convertGuestListIntoEventAttendees,
			formValues.guestList,
		);
		const removedAttendees: CalendarEventAttendee[] = yield call(
			convertGuestListIntoEventAttendees,
			formValues.removedGuestList,
		);
		const {start, end} = yield call(
			convertStartAndEndDateFormat,
			formValues.startDateTime,
			formValues.endDateTime,
			formValues.isAllDay,
		);

		yield apiCallSaga(calendarSDK.updateInternalEvent, token, calendarId, eventId, {
			summary: formValues.title,
			attendees: {
				invite: addedAttendees,
				remove: removedAttendees,
			},
			description: formValues.description,
			start,
			end,
		});

		yield put(calendarActions.updateInternalEvent.success());

		// Re-fetch calendar events
		yield put(calendarActions.getCalendarEvents.request());
	} catch (err) {
		log.captureException(err);
		yield put(calendarActions.updateInternalEvent.failure(err));
		yield put(
			toastActions.addToast({
				type: "error",
				message: loc.formatMessage(strTranslation.CALENDAR.event.saved.error.toast.text),
			}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(calendarActions.updateInternalEvent.request), handleUpdateInternalEvent);
}
