import {put, takeEvery} from "@redux-saga/core/effects";
import {getType} from "typesafe-actions";

import {getSessionId} from "../../auth/helpers/authStorage";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";

import {translateTreatmentComplaints, translateTreatmentTypes} from "../treatmentStatusHelpers";
import {treatmentStatusActions} from "../redux/treatmentStatusActions";
import treatmentStatusSdk from "../treatmentStatusSdk";
import {TreatmentTerminatingReason, TreatmentType} from "../treatmentStatusTypes";

const log = createLogger("TreatmentStatus - load treatment options saga", SentryTags.TreatmentStatus);

/**
 * Load the default options of treatment complaints
 */
function* loadTreatmentComplaints() {
	const token: string = getSessionId();

	try {
		const treatmentComplaints: TreatmentType[] = yield apiCallSaga(
			treatmentStatusSdk.getTreatmentComplaints,
			token,
		);

		yield put(
			treatmentStatusActions.loadTreatmentComplaints.success({
				treatmentComplaints: translateTreatmentComplaints(treatmentComplaints),
			}),
		);
	} catch (error) {
		log.captureException(error);
		return;
	}
}

/**
 * Load the default options of treatment types
 */
function* loadTreatmentTypes() {
	const token: string = getSessionId();

	try {
		const treatmentTypes: TreatmentType[] = yield apiCallSaga(treatmentStatusSdk.getTreatmentTypes, token);

		yield put(
			treatmentStatusActions.loadTreatmentTypes.success({
				treatmentTypes: translateTreatmentTypes(treatmentTypes),
			}),
		);
	} catch (error) {
		log.captureException(error);
		return;
	}
}

/**
 * Load the default options of reasons for terminating treatment.
 */
function* loadEndTreatmentReasons() {
	const token: string = getSessionId();

	try {
		const endTreatmentReasons: TreatmentTerminatingReason[] = yield apiCallSaga(
			treatmentStatusSdk.getTreatmentTerminatingReasons,
			token,
		);
		yield put(treatmentStatusActions.loadEndTreatmentReasons.success({endTreatmentReasons}));
	} catch (error) {
		log.captureException(error);
		return;
	}
}

/**
 * All these requests are for options to be shown in
 * treatment status form.
 */
export default function* () {
	yield takeEvery(getType(treatmentStatusActions.loadTreatmentComplaints.request), loadTreatmentComplaints);
	yield takeEvery(getType(treatmentStatusActions.loadTreatmentTypes.request), loadTreatmentTypes);
	yield takeEvery(getType(treatmentStatusActions.loadEndTreatmentReasons.request), loadEndTreatmentReasons);

	yield put(treatmentStatusActions.loadTreatmentComplaints.request());
	yield put(treatmentStatusActions.loadTreatmentTypes.request());
	yield put(treatmentStatusActions.loadEndTreatmentReasons.request());
}
