import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {GroupTherapyRegistration, GroupTherapyTracker} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";
import {wrapInDispatch} from "../../helpers/wrapInDispatch";
import {GroupRegistrationItem, GroupTherapy} from "../groupTherapy";
import {TherapySessionFormValues} from "../../tracker/therapySession/types";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import {MeetingNoteFormValues} from "../../tracker/meetingNotes/meetingNotesTypes";

export const groupTherapyActions = {
	fetchGroupTherapyList: createAsyncAction(
		"groupTherapyActions/fetchGroupTherapyList",
		"groupTherapyActions/fetchGroupTherapyListSucceed",
		"groupTherapyActions/fetchGroupTherapyListFailed",
	)<void, {groups: GroupTherapy[]}, {error: Error}>(),

	addAGroupTherapy: createAsyncAction(
		"groupTherapyActions/addAGroupTherapy",
		"groupTherapyActions/addAGroupTherapySucceed",
		"groupTherapyActions/addAGroupTherapyFailed",
	)<{group: Omit<GroupTherapy, "id" | "createdAt">}, {group: GroupTherapy}, {error: Error}>(),

	editAGroupTherapy: createAsyncAction(
		"groupTherapyActions/editAGroupTherapy",
		"groupTherapyActions/editAGroupTherapySucceed",
		"groupTherapyActions/editAGroupTherapyFailed",
	)<
		{id: number; groupUpdate: Partial<GroupTherapy>},
		{id: number; group: GroupTherapy},
		{id: number; error: Error}
	>(),

	deleteAGroupTherapy: createAsyncAction(
		"groupTherapyActions/deleteAGroupTherapy",
		"groupTherapyActions/deleteAGroupTherapySucceed",
		"groupTherapyActions/deleteAGroupTherapyFailed",
	)<{id: number}, {id: number}, {error: Error}>(),

	fetchGroupRegistrationList: createAsyncAction(
		"groupTherapyActions/fetchGroupRegistrationList",
		"groupTherapyActions/fetchGroupRegistrationListSucceed",
		"groupTherapyActions/fetchGroupRegistrationListFailed",
	)<
		{id: number; page?: number; pageSize?: number},
		{id: number; registrations: GroupRegistrationItem[]; totalRegistrations: number},
		{error: Error}
	>(),

	saveGroupTherapySession: createAsyncAction(
		"groupTherapyActions/saveGroupTherapySession",
		"groupTherapyActions/saveGroupTherapySessionSucceed",
		"groupTherapyActions/saveGroupTherapySessionFailed",
	)<{group: GroupTherapy; formValues: TherapySessionFormValues; isEdit: boolean}, void, {error: Error}>(),

	saveGroupTherapySessionNote: createAsyncAction(
		"groupTherapyActions/saveGroupTherapySessionNote",
		"groupTherapyActions/saveGroupTherapySessionNoteSucceed",
		"groupTherapyActions/saveGroupTherapySessionNoteFailed",
	)<{group: GroupTherapy; formValues: MeetingNoteFormValues; isEdit: boolean}, void, {error: Error}>(),

	setEditDialogForGroupTherapy: createAction(
		"groupTherapyActions/setEditDialogForGroupTherapy",
		(group: Partial<GroupTherapy>) => ({group}),
	)(),

	fetchGroupRegistrationDetails: createAsyncAction(
		"groupTherapyActions/fetchGroupRegistrationDetails",
		"groupTherapyActions/fetchGroupRegistrationDetailsSucceed",
		"groupTherapyActions/fetchGroupRegistrationDetailsFailed",
	)<
		{groupId: number; registrationId: number},
		{
			registrationDetails: GroupTherapyRegistration;
			relatedSensorDataMap: Record<number, SensorDatum<BasePlannedEventEntry>>;
		},
		{error: Error}
	>(),

	clearFetchedRegistrationDetails: createAction("groupTherapyActions/clearFetchedRegistrationDetails")(),

	deleteGroupRegistration: createAsyncAction(
		"groupTherapyActions/deleteGroupRegistration",
		"groupTherapyActions/deleteGroupRegistrationSucceed",
		"groupTherapyActions/deleteGroupRegistrationFailed",
	)<{groupId: number; registrationDetails: GroupTherapyRegistration}, void, {error: Error}>(),

	openManageTrackersDialog: createAction(
		"groupTherapyActions/openManageTrackersDialog",
		(params: {open: boolean}) => params,
	)(),

	fetchGroupTherapyTrackers: createAsyncAction(
		"groupTherapyActions/fetchGroupTherapyTrackers",
		"groupTherapyActions/fetchGroupTherapyTrackersSucceed",
		"groupTherapyActions/fetchGroupTherapyTrackersFailed",
	)<{groupId: number}, {trackers: GroupTherapyTracker[]}, {error: Error}>(),

	/** the `setTrackerStatus` is used to update the tracker status by updating the state directly */
	setTrackerStatus: createAction(
		"groupTherapyActions/setTrackerStatus",
		(params: {trackerId: number; isEnabled: boolean}) => params,
	)(),

	/**
	 * the updateTrackerStatus is used to update the tracker status by hitting the endpoint without updating the state itself
	 * In this way, there will be no "lagging" effect while switching the tracker status to wait for the response.
	 * So the hitting endpoint is done in the background, meanwhile, the tracker status is also updated by updating the state (`setTrackerStatus` action).
	 */
	updateTrackerStatus: createAsyncAction(
		"groupTherapyActions/updateTrackerStatus",
		"groupTherapyActions/updateTrackerStatusSucceed",
		"groupTherapyActions/updateTrackerStatusFailed",
	)<{groupId: number; trackerId: number; isEnabled: boolean}, void, {error: Error}>(),
};

export type GroupTherapyActions = ActionType<typeof groupTherapyActions>;
export const groupTherapyActionsWithDispatch = wrapInDispatch(groupTherapyActions);
