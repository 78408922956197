/**
 * Additional information about the context of presence being changed.
 * This will be added to presence signal that is being sent to ejabberd.
 */
export enum ChatPresenceContext {
	SET_MANUALLY = "SET_MANUALLY",
	SET_BY_PORTAL = "SET_BY_PORTAL",

	/**
	 * After chat connect, portal need to inform ejabberd about its presence.
	 * Presence with this context, should just be ignored by each portal instance.
	 * Notice that it use the word "SENT" and not "SET".
	 *
	 * Also, never assign local state with this context.
	 */
	SENT_WHEN_CHAT_CONNECT = "SENT_WHEN_CHAT_CONNECT",
}
