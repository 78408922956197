import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {
	InitiationCallSignal,
	DeviceInformation,
	ProceedCallSignal,
	ResponseCallSignal,
	TerminationCallSignal,
	TerminationReason,
	CallType,
	CallContact,
} from "@sense-os/goalie-js";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {OutgoingCallTypes} from "../callTypes";

export const callActions = {
	openCallWindow: createAsyncAction("call/openCallWindow", "call/openCallWindowSuccess", "call/openCallWindowFail")<
		void,
		void,
		Error
	>(),
	closeCallWindow: createAction("call/closeCallWindow")(),

	setCallInfo: createAction(
		"call/setCallInfo",
		(payload: {
			userId: number;
			callType: CallType;
			senderInfo: CallContact;
			recipientInfo: CallContact;
			isConferenceCall: boolean;
		}) => payload,
	)(),
	resetCallInfo: createAction("call/resetCallInfo")(),

	startCall: createAction(
		"call/startCall",
		(payload: {userId: number; isVideo?: boolean; isConferenceCall?: boolean}) => payload,
	)(),
	startOutgoingCallByType: createAction("call/startOutgoingCallByType", (callType: OutgoingCallTypes) => ({
		callType,
	}))(),

	acceptCall: createAction("call/acceptCall", (payload: {isVideo?: boolean}) => payload)(),
	rejectCall: createAction("call/rejectCall")(),
	cancelCall: createAction("call/cancelCall", (userId: number) => ({userId}))(),
	terminateCall: createAction("call/terminateCall", (reason: TerminationReason) => ({reason}))(),
	endCallIfNeeded: createAction("call/endCallIfNeeded", (reason: TerminationReason) => ({reason}))(),
	inviteNewParticipantToCall: createAction(
		"call/inviteNewParticipantToCall",
		(payload: {userId: number}) => payload,
	)(),

	/** Set a flag in the state to indicate that the call window has crashed */
	callWindowCrashed: createAction("call/callWindowCrashed")(),

	/**
	 * start call timer to automatically terminate call if invitee doesn't pick up for certain time
	 */
	startTimeout: createAction("call/startTimeout", (userId: number) => ({userId}))(),
	/**
	 * stop active timer. Usually called when invitee has responded the call signal sent by auth user
	 */
	stopTimeout: createAction("call/stopTimeout", (userId: number) => ({userId}))(),
	/**
	 * stop all active timers
	 */
	stopAllTimeouts: createAction("call/stopAllTimeouts")(),

	createActiveCall: createAction("call/createActiveCall", (activeCall: ActiveCall) => ({activeCall}))(),
	/**
	 * Set the time of when local user has joined the twilio room with other participants.
	 * If it's local user is the only participant in the room, then this action shouldn't be dispatched.
	 */
	setStreamingStartedDate: createAction("call/setStreamingStartedDate", (date: Date) => ({date}))(),

	/**
	 * Dispatched when the call is over (finished, rejected or terminated in any other way)
	 */
	resetActiveCall: createAction("call/resetActiveCall")(),

	userInitiateCall: createAction(
		"call/userInitiateCall",
		(userId: number, time: number, deviceInformation?: DeviceInformation) => ({userId, time, deviceInformation}),
	)(),
	userJoinCall: createAction(
		"call/userJoinCall",
		(userId: number, time: number, deviceInformation?: DeviceInformation) => ({userId, time, deviceInformation}),
	)(),
	userLeaveCall: createAction("call/userLeaveCall", (userId: number, time: number) => ({userId, time}))(),

	handleInitiationSignal: createAction("call/handleInitiationSignal", (signal: InitiationCallSignal) => ({signal}))(),
	/**
	 * Action to dispatch when portal gets `ProceedCallSignal`.
	 * It's a signal when counterpart accepts the call initiation
	 */
	handleAcceptSignal: createAction("call/handleAcceptSignal", (signal: ProceedCallSignal) => ({signal}))(),
	/**
	 * Action to dispatch when portal gets `ResponseCallSignal` about rejection.
	 * It's a signal when counterpart rejects the call initiation
	 */
	handleRejectSignal: createAction("call/handleRejectSignal", (signal: ResponseCallSignal) => ({signal}))(),
	/**
	 * Action to dispatch when portal gets `handleCancelSignal` about cancelation.
	 * It's a signal when counterpart cancels the call initiation
	 */
	handleCancelSignal: createAction("call/handleCancelSignal", (signal: InitiationCallSignal) => ({signal}))(),
	/**
	 * Action to dispatch when portal gets `TerminationCallSignal`.
	 * It's a signal when counterpart terminate the ongoing call
	 */
	handleTerminateSignal: createAction("call/handleTerminateSignal", (signal: TerminationCallSignal) => ({signal}))(),
	/**
	 * Action to dispatch when portal gets `ProceedCallSignal`.
	 * It's a signal when counterpart accepts the call initiation
	 */
	handleAcceptRejectElsewhereSignal: createAction(
		"call/handleAcceptRejectElsewhereSignal",
		(signal: ResponseCallSignal) => ({signal}),
	)(),

	/**
	 * This is to trigger the saga that will handle whenever another user
	 * leaves the call.
	 */
	processRemoteUserLeavesCall: createAction(
		"call/processRemoteUserLeavesCall",
		(userId: number, terminationReason: TerminationReason) => ({userId, terminationReason}),
	)(),
};

export type CallActionType = ActionType<typeof callActions>;
