import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {CheckInFeelingState} from "../checkInFeelingTypes";
import {CheckInFeelingActionType, checkInFeelingActions} from "./checkInFeelingActions";
import produce from "immer";

const initialState: CheckInFeelingState = {
	fetchCheckInFeelingState: LoadingState.EMPTY,
	checkInFeelingData: null,
};

export const checkInFeelingReducer = produce(
	(state: CheckInFeelingState = initialState, action: CheckInFeelingActionType) => {
		switch (action.type) {
			case getType(checkInFeelingActions.fetchCheckInFeeling.request): {
				state.fetchCheckInFeelingState = LoadingState.LOADING;
				state.checkInFeelingData = null;
				return;
			}
			case getType(checkInFeelingActions.fetchCheckInFeeling.success): {
				const {checkInFeelingData} = action.payload;
				state.fetchCheckInFeelingState = LoadingState.LOADED;
				state.checkInFeelingData = checkInFeelingData;
				return;
			}
			case getType(checkInFeelingActions.fetchCheckInFeeling.failure): {
				state.fetchCheckInFeelingState = LoadingState.ERROR;
				return;
			}
		}
	},
	initialState,
);
