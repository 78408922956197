import {getType} from "typesafe-actions";

import {ConnectionState} from "../connectionStatusTypes";
import {ConnectionStatusActionType, connectionStatusActions} from "./connectionStatusActions";

export const initialState: ConnectionState = {
	isOnline: true,
};

export default function connectionStatusReducer(
	state: ConnectionState = initialState,
	action: ConnectionStatusActionType,
): ConnectionState {
	switch (action.type) {
		case getType(connectionStatusActions.changeOnlineStatus):
			return {
				...state,
				isOnline: action.payload.status,
			};
		default:
			return state;
	}
}
