import {LoadingState} from "constants/redux";
import {getContactsMap, getSelectedContactId} from "../../contacts/redux/contactSelectors";
import {AppState, AppStateNames} from "../../ts/redux/AppState";
import {EventViewData} from "../../ts/redux/tracking/TrackingTypes";
import {ClientActivityState, CLIENT_ACTIVITY_TYPE} from "../clientActivityTypes";

/**
 * Returns `clientActivityMap` state
 */
const getClientActivityMap = (state: AppState) => state[AppStateNames.clientActivityMap].clientActivities;

/**
 * Returns user's `ClientActivityState`
 */
const getClientActivityMapByUserId = (state: AppState, userId: number): ClientActivityState | undefined =>
	getClientActivityMap(state)[userId];

/**
 * Returns user's `ClientActivity` last fetched
 */
export const getLastFetchedClientActivity = (state: AppState): Date => {
	const selectedClientId = getSelectedContactId(state);
	return getClientActivityMapByUserId(state, selectedClientId)?.lastFetched;
};

/**
 * Returns user's client activities registration
 */
const getClientRegistrations = (state: AppState, userId: number, activityType: CLIENT_ACTIVITY_TYPE) =>
	getClientActivityMapByUserId(state, userId)?.clientRegistrations[activityType]?.registrations || [];

/**
 * Returns all user's activities fetched are loading or not
 * if one of them is in loading state, it will return true
 */
export const getIsActivityLoading = (state: AppState): boolean => {
	const selectedClientId = getSelectedContactId(state);
	const activityTypes = Object.keys(CLIENT_ACTIVITY_TYPE);
	const isLoading: boolean = activityTypes.reduce((prev, activityType) => {
		const registrationLoading =
			getClientActivityMapByUserId(state, selectedClientId)?.clientRegistrations[
				CLIENT_ACTIVITY_TYPE[activityType]
			]?.loadingState || LoadingState.LOADING;
		if (registrationLoading === LoadingState.LOADING) {
			return true;
		}
		return prev;
	}, false);

	return isLoading;
};
/**
 * Returns user's activities data with contact data.
 * This is because backend doesn't send creator and updater information, and we need these data in the component.
 * Therefore we need to combine this manually, in the front end :sad_faces:
 */
export const getClientActivities = (state: AppState, userId: number): EventViewData[] => {
	const clientActivityTypeKey = Object.keys(CLIENT_ACTIVITY_TYPE);

	const activities = clientActivityTypeKey.reduce((prev, activityType) => {
		return [...prev, ...getClientRegistrations(state, userId, CLIENT_ACTIVITY_TYPE[activityType])];
	}, []);

	const sortedActivities = activities.slice().sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
	const contactsMap = getContactsMap(state);

	const eventViewDataWithUserProfiles: EventViewData[] = sortedActivities.map((eventData) => {
		return {
			...eventData,
			createdByProfile: contactsMap[eventData.createdBy],
			updatedByProfile: contactsMap[eventData.updatedBy],
		};
	});

	return eventViewDataWithUserProfiles;
};

/**
 * Returns selected client activities
 */
export const getSelectedClientActivities = (state: AppState) => {
	const selectedClientId = getSelectedContactId(state);
	return getClientActivities(state, selectedClientId);
};

/**
 * Return the id of recently opened activity.
 */
export const getIdOfRecentlyOpenedActivity = (state: AppState) =>
	state.clientActivityMap.idOfRecentlyOpenedRegistration;
