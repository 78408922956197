import {ConnectionStatus} from "@sense-os/goalie-js";
import {eventChannel} from "redux-saga";
import {call, cancelled, put, select, take, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import {getSessionId} from "../../auth/helpers/authStorage";
import {getOwnId} from "../../auth/redux/authSelectors";
import {incrementalRetryIfFailed} from "../../helpers/sagas/incrementalRetry";
import {ChatAction} from "../redux/ChatAction";
import {getConnectionStatus} from "../redux/ChatSelector";
import {connectionStatusActions} from "../../connectionStatus/redux";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";

import chatSdk from "../sdk";
import {shouldPortalBeClosed} from "../../versionCheck/redux/versionCheckSelector";
import {AppConfig} from "app/AppConfig";

const logger = createLogger("ChatConnectionSaga", SentryTags.Chat);

/**
 * Listen to "portal being offline" event.
 * In that case, set chat as disconnected.
 * This is to trigger chat reconnection.
 */
export function* internetOfflineHandler(action: ActionType<typeof connectionStatusActions.changeOnlineStatus>) {
	if (!action.payload.status) {
		yield call(disconnectChat);
	}
}

/**
 * This is a subscription to listen to chat status changes from goalie-js.
 */
export function* connectionStatusHandler() {
	const chan = eventChannel<ConnectionStatus>((emitter) => {
		const subId = chatSdk.subscribeToConnectionStatusChanges(emitter);
		return () => {
			chatSdk.unsubscribeFromConnectionStatusChanges(subId);
		};
	});

	let lastStatus: ConnectionStatus | null = null;

	try {
		while (true) {
			const status: ConnectionStatus = yield take(chan);

			if (status === lastStatus) {
				continue;
			}

			// dispatch event for status change
			lastStatus = status;
			yield put(ChatAction.setConnectionStatus(status));
			logger.addBreadcrumb({message: "Chat connection status changed", data: {status}});
		}
	} finally {
		if (yield cancelled()) {
			chan.close();
		}
	}
}

/**
 * This is the base function to connect chat.
 * This function shouldn't be call directly outside this module.
 */
function* connectChat() {
	const status: ConnectionStatus = yield select(getConnectionStatus);
	if (status === ConnectionStatus.Connected) {
		return;
	}

	const ownId: number = yield select(getOwnId);
	yield call(chatSdk.connect, ownId, getSessionId());
}

/**
 * This is the "loop" that will make sure the chat is always connected.
 * No need to call `connectChat` from anywhere, as this loop will always
 * trigger chat reconnection when chat is disconnected.
 *
 * Note that, we will wrap this function in a `whenLoggedIn` so
 * it doesn't run when user is logged out.
 */
export function* connectChatWithRetry() {
	const connectChatWithIncrementalRetry = incrementalRetryIfFailed(connectChat);
	let failedAttempt: number = 0;
	let error: any = undefined;

	function* triggerConnectChat() {
		if (failedAttempt >= AppConfig.CHAT_ERROR_MAX_ATTEMPT) {
			// Send error to sentry when it's reach max attempt
			logger.captureException(error, {
				message: `[connectChatWithRetry] Failed to connect to chat after ${failedAttempt} failed attempt`,
			});
		}

		try {
			yield call(connectChatWithIncrementalRetry);

			// Reset failed attempt when chat is connected
			failedAttempt = 0;
		} catch (e) {
			logger.debug("[Chat Saga Connection]", "connectChatWithRetry", "error", e);

			// Increment failed attempt when retry is failed
			failedAttempt = failedAttempt + 1;
			error = e;
		}
	}

	yield takeEvery(
		getType(ChatAction.setConnectionStatus),
		function* (action: ActionType<typeof ChatAction.setConnectionStatus>) {
			const giveUp: boolean = yield select(shouldPortalBeClosed);
			if (action.payload.connectionStatus === ConnectionStatus.Disconnected && !giveUp) {
				yield call(triggerConnectChat);
			}
		},
	);

	yield call(triggerConnectChat);
}

export function* disconnectChat() {
	try {
		yield call(chatSdk.disconnect);
	} catch (e) {
		logger.captureException(e, {message: "Fail to disconnect"});
	}
}
