import {ActionType, createAsyncAction, createAction} from "typesafe-actions";
import {ICustomExerciseForm} from "../customExerciseTypes";
import {TaskFormActionType} from "../../../taskRegistration/taskTypes";

export const customExerciseActions = {
	//
	// custom exercise form dialog
	//
	openForm: createAction(
		"customExerciseActions/openForm",
		(
			userId: number,
			formAction: TaskFormActionType,
			exerciseId?: number,
			taskId?: number,
			formValue?: ICustomExerciseForm,
		) => ({
			userId,
			exerciseId,
			taskId,
			formAction,
			formValue,
		}),
	)(),

	closeForm: createAction("customExerciseActions/closeForm")(),
	resetForm: createAction("customExerciseActions/resetForm")(),

	//
	// Save form value actions
	//
	saveFormValue: createAction(
		"customExerciseActions/saveFormValue",
		(userId: number, formValues?: ICustomExerciseForm) => ({userId, formValues}),
	)(),

	//
	// Create custom exercise actions
	//
	createCustomExercise: createAsyncAction(
		"customExerciseActions/createCustomExercise",
		"customExerciseActions/createCustomExerciseSucceed",
		"customExerciseActions/createCustomExerciseFailed",
	)<{userId: number; formValues: ICustomExerciseForm}, {userId: number}, Error>(),
	//
	// Edit custom exercise actions
	//
	editCustomExercise: createAsyncAction(
		"customExerciseActions/editCustomExercise",
		"customExerciseActions/editCustomExerciseSucceed",
		"customExerciseActions/editCustomExerciseFailed",
	)<
		{userId: number; formValues: ICustomExerciseForm; taskId: number; registrationId: number},
		{userId: number},
		Error
	>(),
};

export type CustomExerciseActionType = ActionType<typeof customExerciseActions>;
