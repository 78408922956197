import produce from "immer";
import {DialogAction, dialogActions} from "./dialogAction";
import {getType} from "typesafe-actions";
import {DialogState, emptyDialogMapState} from "../dialogTypes";

const initialState: DialogState = {
	dialogMap: {},
};

export const dialogReducer = produce((state: DialogState, action: DialogAction) => {
	switch (action.type) {
		case getType(dialogActions.openDialog): {
			state.dialogMap[action.payload.key] = state.dialogMap[action.payload.key] || {...emptyDialogMapState};
			state.dialogMap[action.payload.key].isOpen = true;
			return;
		}

		case getType(dialogActions.closeDialog): {
			state.dialogMap[action.payload.key] = state.dialogMap[action.payload.key] || {...emptyDialogMapState};
			state.dialogMap[action.payload.key].isOpen = false;
			return;
		}

		case getType(dialogActions.toggleDialog): {
			state.dialogMap[action.payload.key] = state.dialogMap[action.payload.key] || {...emptyDialogMapState};
			state.dialogMap[action.payload.key].isOpen = !state.dialogMap[action.payload.key].isOpen;
			return;
		}
	}
}, initialState);
