import {fork} from "redux-saga/effects";
import getInviteClientsList from "./getInviteClientsListSaga";
import saveInviteClientSaga from "./saveInviteClientSaga";
import deleteInviteClientSaga from "./deleteInviteClientSaga";
import resendInviteClientSaga from "./resendInviteClientSaga";

export default function* () {
	yield fork(getInviteClientsList);
	yield fork(saveInviteClientSaga);
	yield fork(deleteInviteClientSaga);
	yield fork(resendInviteClientSaga);
}
