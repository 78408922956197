import {LoadingState} from "constants/redux";
import {PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {MenuButtonItemProps} from "../../components/menuButton/menuButtonType";

/**
 * Intervention assessment list filter
 */
export enum AssessmentListFilter {
	ALL = "all",
	OMQ = "omq",
	SMQ = "smq",
}

/** Assessment expire status */
export enum AssessmentExpireStatus {
	UPCOMING = "upcoming",
	PAST = "past",
}

/** Assessment expire status list */
export const expireStatusList: MenuButtonItemProps<AssessmentExpireStatus>[] = [
	{
		value: AssessmentExpireStatus.UPCOMING,
		label: localization.formatMessage(strTranslation.INTERVENTION.table.menu.upcoming),
		id: "upcoming",
	},
	{
		value: AssessmentExpireStatus.PAST,
		label: localization.formatMessage(strTranslation.INTERVENTION.table.menu.past),
		id: "past",
	},
];

/**
 * Assessment table data source
 */
export interface AssessmentDataSource extends SensorDatum<PlannedEventEntry> {
	key: string;
}

/**
 * Intervention assessment redux state
 */
export interface InterventionAssessmentState {
	// OMQ and SMQ fetch or save loading state
	omqSmqFetchingState: LoadingState;
	// OMQ and SMQ total reflections
	totalReflections: number;
	// Assessment table data source
	dataSource: AssessmentDataSource[];
	// Selected expire status
	selectedStatus: AssessmentExpireStatus;
	// Selected data source filter
	selectedFilter: AssessmentListFilter;
}
