import {fork} from "redux-saga/effects";
import callTimeoutSaga from "./callTimeoutSaga";
import startCallSaga from "./startCallSaga";
import inviteParticipantSaga from "./inviteParticipantSaga";
import {callSignalSubscription} from "./callSignal/callSignalSaga";
import {handleInitiationSignalSaga} from "./callSignal/handleInitiationSignalSaga";
import {handleAcceptSignalSaga} from "./callSignal/handleAcceptSignalSaga";
import {handleRejectSignalSaga} from "./callSignal/handleRejectSignalSaga";
import {handleCancelSignalSaga} from "./callSignal/handleCancelSignalSaga";
import {handleTerminateSignalSaga} from "./callSignal/handleTerminateSignalSaga";
import {handleAcceptRejectElsewhereSignalSaga} from "./callSignal/handleAcceptedRejectedElsewhereSignalSaga";
import {callWindowSaga} from "./callWindow/callWindowSaga";
import {acceptCallSaga} from "./acceptCallSaga";
import {rejectCallSaga} from "./rejectCallSaga";
import {cancelCallSaga} from "./cancelCallSaga";
import {terminateCallSaga} from "./terminateCallSaga";
import {endCallSaga} from "./endCallSaga";

export default function* callSaga() {
	yield fork(callSignalSubscription);
	yield fork(handleInitiationSignalSaga);
	yield fork(handleAcceptSignalSaga);
	yield fork(handleRejectSignalSaga);
	yield fork(handleCancelSignalSaga);
	yield fork(handleTerminateSignalSaga);
	yield fork(handleAcceptRejectElsewhereSignalSaga);
	yield fork(callTimeoutSaga);
	yield fork(startCallSaga);
	yield fork(acceptCallSaga);
	yield fork(rejectCallSaga);
	yield fork(cancelCallSaga);
	yield fork(endCallSaga);
	yield fork(terminateCallSaga);
	yield fork(inviteParticipantSaga);
	yield fork(callWindowSaga);
}
