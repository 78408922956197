import {PostItem} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";

/** Niceday blog search state */
export interface NDBlogSearchState {
	// Keyword string for searching post in niceday blog
	searchKeywords?: string;
	// An array of search result from niceday blog
	searchResultItems?: PostItem[];
	// Paste link of selected search result
	pasteLink: string;
	// Search loading state
	loadingState: LoadingState;
	// Current page number
	currentPage: number;
	// is Dialog opened state
	isOpened: boolean;
}

/** Option for determine which action to show in search result item*/
export enum SearchItemOption {
	// Used for showing copy option in search result items
	COPY_ITEM = "copy_item",
	// Used for showing paste option in search result items
	PASTE_ITEM = "paste_item",
}

/** KeyboardJS key context */
export enum KeyContexts {
	GLOBAL = "global",
	SEARCH_ITEM = "search_item",
}

/** Keybindings for niceday blog search */
export enum KeyBindings {
	// Used for open search dialog hotkey in Mac
	CMD_DOT = "command+.",
	// Used for open search dialog hotkey in Windows and others
	CTRL_DOT = "ctrl+.",
	// Used for call "copy" or "paste" function
	ENTER = "enter",
	// Used for navigate search item
	TAB = "tab",
	// Used for navigate search item
	UP = "up",
	// Used for navigate search item
	DOWN = "down",
}

/** User platform */
export enum UserPlatform {
	// Macintosh
	MAC = "mac",
	// Other
	OTHER = "other",
}

/** Min characters for search */
export const MIN_CHAR_SEARCH = 3;

/** Max characters for search */
export const MAX_CHAR_SEARCH = 140;

/** Max search items per page */
export const MAX_ITEMS_PER_PAGE = 9;

/** Min char for showing tooltip */
export const MIN_TOOLTIP_CHAR = 44;

/** First page number */
export const FIRST_PAGE_NUMBER = 1;
