import {call, takeEvery, put, fork, delay} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {CalendarSessionRequestBody} from "@sense-os/goalie-js";
import moment from "moment";

import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {toastActions} from "../../../toaster/redux/toastAction";
import loc from "../../../localization/Localization";
import strTranslation from "../../../assets/lang/strings";

import {calendarActions} from "../../redux/calendarActions";
import {calendarSDK} from "../../helpers/calendarSDK";
import {getUndoButton} from "../../views/UndoButton";
import {UNDO_ACTION_TIMEOUT_MS, calendarEventScheduleMap} from "../../calendarTypes";

const log = createLogger("saveSessionEventSaga", SentryTags.Calendar);

const UNDO_KEY_PREFIX = "CALENDAR_session_save_";

function* handleSaveSessionEvent(action: ActionType<typeof calendarActions.saveSessionEvent.request>) {
	const {calendarId, body} = action.payload;
	const startTimeISO = moment(body.start).toISOString();
	const undoId = UNDO_KEY_PREFIX + startTimeISO;

	yield put(
		toastActions.addToast({
			type: "info",
			message: loc.formatMessage(strTranslation.CALENDAR.session.saved.toast.text),
			persist: false,
			key: undoId,
			action: getUndoButton(undoId),
		}),
	);

	const saveSessionSagaTask = yield fork(saveSessionEventTask, calendarId, body, true);
	calendarEventScheduleMap.set(undoId, saveSessionSagaTask);
}

export function* saveSessionEventTask(calendarId: string, body: CalendarSessionRequestBody, isUseDelay: boolean) {
	if (isUseDelay) {
		yield delay(UNDO_ACTION_TIMEOUT_MS);
	}

	try {
		const token: string = yield call(getSessionId);

		yield apiCallSaga(calendarSDK.upsertSessionEvent, token, calendarId, {
			eventId: body.eventId,
			summary: body.summary,
			description: body.description,
			start: moment(body.start).toDate(),
			end: moment(body.end).toDate(),
		});

		yield put(calendarActions.saveSessionEvent.success());

		// Re-fetch calendar events
		yield put(calendarActions.getCalendarEvents.request());
	} catch (err) {
		log.captureException(err);
		yield put(calendarActions.saveSessionEvent.failure(err));
		yield put(
			toastActions.addToast({
				type: "error",
				message: loc.formatMessage(strTranslation.CALENDAR.event.saved.error.toast.text),
			}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(calendarActions.saveSessionEvent.request), handleSaveSessionEvent);
}
