import {
	SensorDatum,
	TrackingId,
	Sensors,
	SensorData,
	TrackingEntry,
	SensorConfigs,
	SensorConfig,
	DiaryEntry,
	EventViewType,
	Mood,
} from "./TrackingTypes";
import {TypeOf} from "services/utils/constants/TypeOf";
import {DistributionResponse} from "@sense-os/goalie-js/dist";
import {ContactTabPath} from "app/Path";
import {getClientTabMenuRouteWithEvent} from "../../../helpers/routeGenerator";

/** A separator for `trackingId` that separates `sensorData.sensorName` and `sensorData.id` */
const TRACKING_ID_SEPARATOR: string = "___";

/** Tmp function: generates an id for a sensor-datum until tracking SDK provides one */
export function trackingId(data: SensorDatum<any>): TrackingId {
	return `${data.sensorName}${TRACKING_ID_SEPARATOR}${data.id}`;
}

/**
 * Object that contains `sensorData` as property key
 * whether its nested or not
 */
interface SensorDataContainer extends Object {}

/**
 * Recursively traverses sensor data inside any object by sensorName.
 * The found sensor data gets placed into arrRef.
 *
 * @param {SensorDataContainer} sensorDataContainer object that contains `sensorData` as property key
 * inside whether its nested or not.
 * @param {Sensors} sensorName
 * @param {SensorData} arrRef Pass array as reference
 * @param {string} sensorDataKey object key that contains sensor data
 */
export function recursivelyGetSensorDataBySensorName<T extends TrackingEntry>(
	sensorDataContainer: SensorDataContainer,
	sensorName: Sensors,
	arrRef: SensorData<T>,
	sensorDataKey: string = "sensorData",
): void {
	if (typeof sensorDataContainer !== TypeOf.OBJECT) {
		return;
	}
	for (let key in sensorDataContainer) {
		if (sensorDataContainer[key] && typeof sensorDataContainer[key] === TypeOf.OBJECT) {
			// Object with key = `sensorDataKey` is found.
			// This means that the object should be a SensorData.
			if (key === sensorDataKey) {
				const sensorData = sensorDataContainer[sensorDataKey] as SensorDatum<T>;
				// Check if this `sensorData` is what the function wanted
				if (sensorData.sensorName === sensorName) {
					// Add to `arrRef`
					arrRef.push(sensorDataContainer[sensorDataKey]);
				}
			}
			// If the object key !== `sensorDataKey`, go inside the object and check again
			recursivelyGetSensorDataBySensorName(sensorDataContainer[key], sensorName, arrRef);
		}
	}
}

/**
 * Get single sensorData by Sensor Name from an object
 *
 * @param {Object} obj any object form
 * @param {Sensors} sensorName sensor name to be search inside the object
 */
export function getSensorDataBySensorName<T extends TrackingEntry>(obj: Object, sensorName: Sensors): SensorDatum<T> {
	let results: SensorData<T> = [];
	recursivelyGetSensorDataBySensorName(obj, sensorName, results);

	if (results.length === 0) {
		return null;
	}
	return results[0];
}

/**
 * get sensor names as array inside an object
 *
 * @param {Object} obj
 */
export function getSensorNamesFromObject(obj: Object): Sensors[] {
	let sensors = [];
	const sensorNames = Object.keys(Sensors).map((key) => Sensors[key]);
	sensorNames.forEach((name) => recursivelyGetSensorDataBySensorName(obj, name, sensors));

	return sensors.map((sensor) => sensor.sensorName);
}

/**
 * Transform sensor distribution array to `SensorDatum<T>`
 *
 * @see {SensorDatum<T>}
 */
export function transformSensorDistributionToSensorData<D, T extends TrackingEntry>(
	distributions: DistributionResponse<D>[],
	sensorName: Sensors,
	userId: number,
	valueFn: (value: D) => T,
): SensorDatum<T>[] {
	const sensorConfig: SensorConfig = SensorConfigs[sensorName];
	return distributions.map((distribution) => {
		const sensorData: SensorDatum<T> = {
			id: `${distribution.startTime.getTime()}__${distribution.endTime.getTime()}`,
			startTime: distribution.startTime,
			endTime: distribution.endTime,
			sensorName,
			sourceName: sensorConfig.sourceName,
			userId,
			value: valueFn(distribution.value),
			version: sensorConfig.version,
			createdAt: null,
			updatedAt: null,
			createdBy: null,
			updatedBy: null,
		};
		return sensorData;
	});
}

/**
 * Returns eventViewType by `sensorName`
 *
 * @param {Sensors} sensorName
 */
export function getEventViewTypeBySensorName(sensorName: Sensors): EventViewType {
	return {
		[Sensors.PLANNED_EVENT]: EventViewType.PLANNED_EVENT_SENSOR,
		[Sensors.DIARY]: EventViewType.DIARY_ENTRY_SENSOR,
		[Sensors.MEETING_NOTE]: EventViewType.MEETING_NOTE_SENSOR,
		[Sensors.GSCHEME]: EventViewType.THOUGHT_RECORDS_SENSOR,
	}[sensorName];
}

export function getSensorNameByEventViewType(eventViewType: EventViewType): Sensors {
	return {
		[EventViewType.DIARY_ENTRY_SENSOR]: Sensors.DIARY,
		[EventViewType.MEETING_NOTE_SENSOR]: Sensors.MEETING_NOTE,
		[EventViewType.PLANNED_EVENT_SENSOR]: Sensors.PLANNED_EVENT,
		[EventViewType.THOUGHT_RECORDS_SENSOR]: Sensors.GSCHEME,
	}[eventViewType];
}

export const EVENT_ID_DELIMITER = "___";
/**
 * Returns temporary eventViewData Id string
 *
 * @param {string} prefix
 * @param {Date} startTime
 * @param {Date} endTime
 */
export function createEventViewId(eventViewType: EventViewType, id: string, occurrenceTime: Date): string {
	return eventViewType + EVENT_ID_DELIMITER + id + EVENT_ID_DELIMITER + occurrenceTime.getTime();
}

export function extractEventViewId(eventId: string) {
	const [eventViewType, id, occurrenceTime] = eventId.split(EVENT_ID_DELIMITER);
	return {
		eventViewType: eventViewType as EventViewType,
		id,
		occurrenceTime: occurrenceTime ? new Date(Number(occurrenceTime)) : null,
	};
}

/**
 * Extract event id from query string. And decode the value.
 */
export function getEventIdFromQueryString(queryString: string): string {
	const urlParams = new URLSearchParams(queryString);
	const eventId: string = urlParams.get("event");

	if (!eventId) {
		return null;
	}
	try {
		const decodedEventId: string = atob(eventId);
		return decodedEventId;
	} catch (e) {
		return null;
	}
}

/**
 * Returns true if a diary entry sensor should be hidden or not.
 * We should hide diary entry sensor if:
 * 1. If Diary entry has no title or description, check if there is
 * a g-scheme sensor inside diary entry. If there is, we should not hide
 * the diary entry.
 * 2. If Diary entry already exist inside a plan event as a reflection.
 *
 * @param diaryEntrySensor
 * @param diaryEntriesAsReflections
 */
export function shouldFilterDiaryEntry(
	diaryEntrySensor: SensorDatum<DiaryEntry>,
	diaryEntriesAsReflections: SensorData<DiaryEntry>,
): boolean {
	// We only want to show diary entries that have title and description
	const hasNoTitle: boolean = !diaryEntrySensor.value.title,
		hasNoDescription: boolean = !diaryEntrySensor.value.description;
	// If diary entry at least has title or description, we won't hide it
	if (hasNoTitle && hasNoDescription) {
		// If there's no title and description, check if gscheme exists or not.
		// Because in app, there is a case where a user try to add a feeling data
		// but also add a gscheme without adding a title or description to the diary entry.
		// Then when the therapist want to see the gscheme data, it's impossible
		// because the diary entry has no title and we hide them.
		const hasGschemeData: boolean = !!diaryEntrySensor.value.gscheme;
		if (!hasGschemeData) {
			return false;
		}
	}
	// Check if diary entry is in planned event as a reflection. If yes, don't show the diary entry.
	const isInPlannedEvent: boolean = diaryEntriesAsReflections.some((diaryEntry) => {
		return diaryEntry.id === diaryEntrySensor.id;
	});
	return !isInPlannedEvent;
}

/**
 * Returns a link to show an event details
 *
 * @param {string} userHashId hashId of the user
 * @param {string} eventId
 */
export function createEventViewDataLink(userHashId: string, eventId: string): string {
	// Encode eventId to be passed inside query string
	const encodedEventId: string = window.btoa(eventId);
	return getClientTabMenuRouteWithEvent(userHashId, ContactTabPath.DATA, encodedEventId);
}

/**
 * Returns mood_v2 value for support 11 smiley
 *
 * @param {Mood} moodValue mood_v1 value
 */
export function convertMoodScore(moodValue: Mood): Mood {
	// Convert given mood_v1 value (1 - 5 value range) to be compatible with mood_v2 value (0 - 10 data range).
	// 10 is mood_v2 max value, where previously (in mood_v1) the max value is 5, details is here:
	// https://docs.google.com/spreadsheets/d/14Hj0qOd9fuFhKoNM9cFPL75AJcN9xzu8Z2IuLq2AW10/edit?ts=5f9944af#gid=0
	const convertedValue: Mood = {
		score: ((moodValue.score - 1) * 10.0) / 4,
	};
	return convertedValue;
}
