import {LoadingState} from "constants/redux";
import {getType} from "typesafe-actions";
import {FileSharingActionType, fileSharingActions} from "./fileSharingActions";
import {FileSharingUploadState} from "../fileSharingTypes";

const initialState: FileSharingUploadState = {
	isFileBrowserOpened: false,
	selectedFiles: {},
	uploadingState: LoadingState.EMPTY,
};

export const fileSharingUploadReducer = (
	state: FileSharingUploadState = initialState,
	action: FileSharingActionType,
) => {
	switch (action.type) {
		case getType(fileSharingActions.uploadFiles.request):
			return {
				...state,
				uploadingState: LoadingState.LOADING,
			};

		case getType(fileSharingActions.uploadFiles.success):
			return {
				...state,
				uploadingState: LoadingState.LOADED,
			};

		case getType(fileSharingActions.uploadFiles.failure):
			return {
				...state,
				uploadingState: LoadingState.LOADED,
			};

		case getType(fileSharingActions.openFileBrowser):
			return {
				...state,
				isFileBrowserOpened: true,
			};
		case getType(fileSharingActions.closeFileBrowser):
			return {
				...state,
				isFileBrowserOpened: false,
			};
		case getType(fileSharingActions.setSelectedFile):
			return {
				...state,
				selectedFiles: {
					...state.selectedFiles,
					[action.payload.userId]: action.payload.selectedFiles,
				},
			};
		default:
			return state;
	}
};
