import {ActionType, createAsyncAction} from "typesafe-actions";
import {ConnectedTherapist, AvailableTherapist} from "../clientNetworkTypes";

export const clientNetworkActions = {
	loadAvailableTherapists: createAsyncAction(
		"clientNetworkActions/loadAvailableTherapists",
		"clientNetworkActions/loadAvailableTherapistsSuccess",
		"clientNetworkActions/loadAvailableTherapistsFail",
	)<void, {availableTherapists: AvailableTherapist[]}, {error: Error}>(),

	loadClientsConnectionById: createAsyncAction(
		"clientNetworkActions/loadClientsConnections",
		"clientNetworkActions/loadClientsConnectionsSuccess",
		"clientNetworkActions/loadClientsConnectionsFail",
	)<
		{clientId: number},
		{clientId: number; clientsConnection: ConnectedTherapist[]},
		{clientId: number; error: Error}
	>(),

	inviteExtraTherapist: createAsyncAction(
		"clientNetworkActions/inviteExtraTherapist",
		"clientNetworkActions/inviteExtraTherapistSuccess",
		"clientNetworkActions/inviteExtraTherapistFail",
	)<
		{clientId: number; invitedTherapist: AvailableTherapist},
		{clientId: number},
		{clientId?: number; error: Error}
	>(),

	cancelInvitation: createAsyncAction(
		"clientNetworkActions/cancelInvitation",
		"clientNetworkActions/cancelInvitationSuccess",
		"clientNetworkActions/cancelInvitationFail",
	)<
		{clientId: number; pendingTherapist: ConnectedTherapist},
		{clientId: number},
		{clientId?: number; error: Error}
	>(),

	disconnectTherapist: createAsyncAction(
		"clientNetworkActions/disconnectTherapist",
		"clientNetworkActions/disconnectTherapistSuccess",
		"clientNetworkActions/disconnectTherapistFail",
	)<
		{clientId: number; therapistToDisconnect: ConnectedTherapist},
		{clientId: number},
		{clientId?: number; error: Error}
	>(),
};

export type ClientNetworkActionType = ActionType<typeof clientNetworkActions>;
