import {call, delay} from "redux-saga/effects";

/**
 * Wrap a function so that it in case of errors,
 * it will wait for some delay time before it run again.
 *
 * The delay time is increasing by 1.75 times the next time its error, with cap on 30s.
 * The delay time is resetted when the function is called successfully.
 */
export function incrementalRetryIfFailed(fn: (...args: any[]) => void) {
	let delayMs = 0;
	return function* (...args: any[]) {
		try {
			yield delay(delayMs);
			yield call(fn, ...args);
			delayMs = 0;
		} catch (e) {
			delayMs = Math.min(30000, delayMs > 0 ? delayMs * 1.75 : 1000);
			throw e;
		}
	};
}
