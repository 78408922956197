import strTranslation from "../assets/lang/strings";
import localization from "../localization/Localization";

export enum PresetsTag {
	// Categories as tag
	CATEGORY_TRACKING_COMPLAINTS = "category__tracking_complaints",
	CATEGORY_PSYCHOEDUCATION = "category__psychoeducation",
	CATEGORY_INTERVENTIONS = "category__interventions",

	// Tools as tag
	TOOLS_BEHAVIOUR_EXPERIMENT = "tools_behaviour_experiment",
	TOOLS_PSYCHOEDUCATION = "tools_psychoeducation",
	TOOLS_THOUGHT_RECORD = "tools_thought_record",
	TOOLS_TRACKING = "tools_tracking",

	// Complaints as tag
	ADDICTION = "addiction",
	ADHD = "adhd",
	ANXIETY = "anxiety",
	BURNOUT = "burnout",
	COMPULSIONS = "compulsions",
	DEPRESSION = "depression",
	EATING = "eating",
	GRIEF = "grief",
	LIFESTYLE = "lifestyle",
	MINDFULNESS = "mindfulness",
	OCD = "ocd",
	PANIC = "panic",
	PHOBIA = "phobia",
	PSYCHOSIS = "psychosis",
	RELAXATION = "relaxation",
	ROM = "rom",
	TRAUMA = "trauma",
}

export enum PresetTodoRepetition {
	THRICE_A_DAY = "thrice_a_day",
	TWICE_A_DAY = "twice_a_day",
	DAILY = "daily",
	TWICE_WEEKLY = "twice_weekly",
	WEEKLY = "weekly",
	WEEKDAYS = "weekdays",
	MONTHLY = "monthly",
	NO_REPETITION = "no_repetition",
}

export const presetRepetitionOptions = [
	PresetTodoRepetition.THRICE_A_DAY,
	PresetTodoRepetition.TWICE_A_DAY,
	PresetTodoRepetition.DAILY,
	PresetTodoRepetition.WEEKLY,
	PresetTodoRepetition.WEEKDAYS,
	PresetTodoRepetition.MONTHLY,
];

export const presetRepetitionTranslationsMap = {
	[PresetTodoRepetition.THRICE_A_DAY]: localization.formatMessage(
		strTranslation.MODULE_PRESETS.dialog.repetition.thrice_a_day,
	),
	[PresetTodoRepetition.TWICE_A_DAY]: localization.formatMessage(
		strTranslation.MODULE_PRESETS.dialog.repetition.twice_a_day,
	),
	[PresetTodoRepetition.DAILY]: localization.formatMessage(strTranslation.MODULE_PRESETS.dialog.repetition.daily),
	[PresetTodoRepetition.WEEKLY]: localization.formatMessage(strTranslation.MODULE_PRESETS.dialog.repetition.weekly),
	[PresetTodoRepetition.WEEKDAYS]: localization.formatMessage(
		strTranslation.MODULE_PRESETS.dialog.repetition.weekdays,
	),
	[PresetTodoRepetition.MONTHLY]: localization.formatMessage(strTranslation.MODULE_PRESETS.dialog.repetition.monthly),
};

export enum PresetTodoTimeOption {
	MORNING = "morning",
	AFTERNOON = "afternoon",
	EVENING = "evening",
}

export interface PresetTodo {
	repetition: PresetTodoRepetition;
	time:
		| [PresetTodoTimeOption, PresetTodoTimeOption, PresetTodoTimeOption]
		| [PresetTodoTimeOption, PresetTodoTimeOption]
		| [PresetTodoTimeOption];
}

export interface PresetTracker {
	sensorName: string;
	isEnabled: boolean;
	defaultTodo: PresetTodo;
	title?: string;
}

export interface PresetTrackerWithId extends PresetTracker {
	id: number;
}

export interface PresetCustomTodo {
	sensorName: string;
	isEnabled: boolean;
	defaultTodo: PresetTodo;
	title: string;
	id: number;
}

export interface PresetPsychoEducation {
	title: string;
	link: string;
	isEnabled: boolean;
}

interface PresetBehaviorExperimentTodoItem {
	id: number;
}

export interface PresetBehaviorExperiment {
	id: number;
	isEnabled: boolean;
	// TODO: update this structure to use the same structure to `PresetTracker`
	// if later it's final if 1 switch button of behavior experiment only consists of 1 todo
	defaultTodos?: PresetBehaviorExperimentTodoItem[];
}

export interface ToolboxPreset {
	id: number;
	title: string;
	tags: PresetsTag[];
	trackers?: PresetTracker[];
	psychoeducations?: PresetPsychoEducation[];
	rom?: {type: "omq" | "smq"}[];
	thoughtRecords?: PresetTracker[];
	behaviourExperiments?: PresetBehaviorExperiment[];
	diaries?: PresetTrackerWithId[];
	checkIn?: PresetTrackerWithId[];
	customTodos?: PresetCustomTodo[];
}
