import {BehaviorExperimentTaskRegistration, TaskItemResponse, TaskStatus} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";
import strTranslation from "../../assets/lang/strings";
import {MenuButtonItemProps} from "../../components/menuButton/menuButtonType";
import localization from "../../localization/Localization";

export interface InterventionBehaviorExperimentState {
	/**
	 * Loading State of intervention behavior experiment
	 */
	fetchingState: LoadingState;
	/**
	 * Behavior Experiment Total used in intervention card
	 */
	total: number;
	/**
	 * Error object if behavior experiment failed to be fetched
	 */
	error?: Error;
	/**
	 * Behavior Experiment List
	 */
	data: BehaviorExperimentDataSource[];
	/**
	 * selected expires status
	 */
	selectedExpiresStatus: ExpireStatus;
	/**
	 * current page
	 */
	currentPage: number;
}

/**
 * Define the intervention behavior experiment table data source
 */
export interface BehaviorExperimentDataSource extends TaskItemResponse<BehaviorExperimentTaskRegistration> {
	key: number;
}

export enum ExpireStatus {
	OPEN = TaskStatus.UNCOMPLETED,
	CONCLUDED = TaskStatus.CONCLUDED,
}

export const expireStatusList: MenuButtonItemProps<ExpireStatus>[] = [
	{
		value: ExpireStatus.OPEN,
		label: localization.formatMessage(strTranslation.INTERVENTION.table.menu.open),
		id: "open",
	},
	{
		value: ExpireStatus.CONCLUDED,
		label: localization.formatMessage(strTranslation.INTERVENTION.table.menu.concluded),
		id: "concluded",
	},
];

/** representation for the first page */
export const FIRST_PAGE = 1;
