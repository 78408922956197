import {LoadingState} from "constants/redux";
import {getType} from "typesafe-actions";
import {FileSharingActionType, fileSharingActions} from "./fileSharingActions";
import {FileItem, FileSharingState, UserFileSharingState} from "../fileSharingTypes";
import {mergeFileItems} from "../helpers/fileSharingHelper";
import {NumberMap} from "services/utils/Maps";

export const emptyFileSharing: UserFileSharingState = {
	currentPage: 1,
	loadedFiles: [],
	loadingState: LoadingState.EMPTY,
};

export const fileSharingState: FileSharingState = {
	deletingIds: [],
	deletingState: LoadingState.EMPTY,
	isDeleteDialogOpen: false,
	files: {},
};

export type UserMapFileItemState = NumberMap<UserFileSharingState>;

/**
 * Set chat room state
 *
 * @param {UserMapFileItemState} state
 * @param {number} userId
 * @param {Partial<UserFileSharingState>} data
 */
function setFileItemState(
	state: FileSharingState,
	userId: number,
	data: Partial<UserFileSharingState>,
): FileSharingState {
	const userFileItemState: UserFileSharingState = state.files?.[userId] || emptyFileSharing;
	return {
		...state,
		files: {
			...state.files,
			[userId]: {
				...userFileItemState,
				...data,
			},
		},
	};
}

export const fileSharingReducer = (state: FileSharingState = fileSharingState, action: FileSharingActionType) => {
	switch (action.type) {
		case getType(fileSharingActions.loadFiles.request):
			return setFileItemState(state, action.payload.userId, {
				loadingState: LoadingState.LOADING,
			});
		case getType(fileSharingActions.loadFiles.success):
			/**
			 * We put this logic here, because we need to guarantee
			 * that the first argument to addMessages function always refer to latest state.
			 */
			const updatedFiles = mergeFileItems(
				state.files[action.payload.userId]?.loadedFiles || [],
				action.payload.fileItems,
			);
			if (!action.payload.currentPage) {
				return setFileItemState(state, action.payload.userId, {
					loadedFiles: updatedFiles,
				});
			}
			return setFileItemState(state, action.payload.userId, {
				hasMore: action.payload.hasMore,
				loadingState: LoadingState.LOADED,
				currentPage: action.payload.currentPage,
				loadedFiles: updatedFiles,
			});
		case getType(fileSharingActions.loadFiles.failure):
			return setFileItemState(state, action.payload.userId, {
				loadingState: LoadingState.ERROR,
			});

		case getType(fileSharingActions.openDeleteDialog):
			return {
				...state,
				deletingIds: action.payload.deletingIds,
				isDeleteDialogOpen: true,
			};
		case getType(fileSharingActions.closeDeleteDialog):
			return {
				...state,
				deletingIds: [],
				isDeleteDialogOpen: false,
			};

		case getType(fileSharingActions.deleteFile.request):
			return {
				...state,
				deletingState: LoadingState.LOADING,
			};
		case getType(fileSharingActions.deleteFile.success):
			// Set isDeleted as true to prevent data to be re-fetched from backend.
			const loadedFiles = state.files[action.payload.userId].loadedFiles.map((item) => ({
				...item,
				isDeleted: action.payload.ids.includes(item.id) ? true : item.isDeleted,
			}));
			return setFileItemState(
				{
					...state,
					deletingState: LoadingState.EMPTY,
				},
				action.payload.userId,
				{
					loadedFiles: loadedFiles,
				},
			);
		case getType(fileSharingActions.deleteFile.failure):
			return {
				...state,
				deletingState: LoadingState.ERROR,
			};

		case getType(fileSharingActions.loadBlob.success):
			const files = state.files[action.payload.userId].loadedFiles;
			const file: FileItem = files.filter(({id}) => id == action.payload.id)[0];
			/**
			 * We put this logic here, because we need to guarantee
			 * that the first argument to addMessages fu
			 * nction always refer to latest state.
			 */
			const mergedFiles = mergeFileItems(state.files[action.payload.userId].loadedFiles || [], [
				{
					...file,
					blobThumbnailUrl: action.payload.urls.blobThumbnailUrl,
					blobUrl: action.payload.urls.blobUrl,
				},
			]);

			return setFileItemState(state, action.payload.userId, {
				loadedFiles: mergedFiles,
			});

		default:
			return state;
	}
};
