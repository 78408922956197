import * as React from "react";
import {Dialog, DialogContent, makeStyles} from "@material-ui/core";
import {NDBlogSearchInput} from "./NDBlogSearchInput";
import {NDBlogSearchList} from "./NDBlogSearchList";
import {SearchItemOption} from "../NDBlogSearchTypes";

interface Props {
	isOpen: boolean;
	onClose: () => void;
	searchItemOption: SearchItemOption;
}

export const NDBlogSearchDialog: React.FC<Props> = (props) => {
	const {isOpen, onClose, searchItemOption} = props;
	const classes = useSearchDialogStyles();

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth classes={{paper: classes.paper}} onBackdropClick={onClose}>
			<DialogContent>
				<NDBlogSearchInput />
				<NDBlogSearchList searchItemOption={searchItemOption} onClose={onClose} />
			</DialogContent>
		</Dialog>
	);
};

const useSearchDialogStyles = makeStyles(() => ({
	paper: {
		maxWidth: "850px",
		minWidth: "650px",
		backgroundColor: "#ECECEC",
		borderRadius: "10px",
		position: "absolute",
		margin: 0,
		top: "10%",
	},
}));
