import moment from "moment";
import {SensorData as BaseSensorData} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";
import {ActivityTypes, PlannedEventStatus, SensorConfig, SensorConfigs, Sensors} from "redux/tracking/TrackingTypes";
import {AssessmentType, OmqSmqFormValues} from "../types";
import {saveSensorData} from "../../../clientActivity/helpers/clientActivitySDKHelpers";

/**
 * Transform `OmqSmqFormValues` into `PlannedEvent` sensor data payload
 */
export function getOmqSmqSensorDataPayload(
	formValues: OmqSmqFormValues,
	assessmentType: AssessmentType,
): BaseSensorData<BasePlannedEventEntry> {
	// Set activityType based on assessmentType
	const activityType: ActivityTypes =
		assessmentType === AssessmentType.OMQ ? ActivityTypes.FILL_OMQ : ActivityTypes.FILL_SMQ;

	// Set sensor config as planned event entry
	const sensorConfig: SensorConfig = SensorConfigs[Sensors.PLANNED_EVENT];

	// Set notification time before due date
	const notificationTime: string = moment(formValues.dueDate)
		.subtract(formValues.notificationDuration, formValues.notificationTimeUnit)
		.format();

	return {
		sourceName: sensorConfig.sourceName,
		sensorName: sensorConfig.name,
		version: sensorConfig.version,
		startTime: formValues.dueDate,
		endTime: formValues.dueDate,
		value: {
			title: formValues.title,
			activityType: activityType,
			shouldSendNotification: formValues.notificationToggle,
			plannedFor: notificationTime,
			status: PlannedEventStatus.INCOMPLETE,
			description: "",
			plannedOmq: undefined,
			plannedSmq: undefined,
			reflection: undefined,
			feeling: undefined,
			gscheme: undefined,
			mood: undefined,
		},
	};
}

/**
 * Save (create or update) OMQ or SMQ
 */
export async function saveOmqSmq(
	omqSmqPayload: BaseSensorData<BasePlannedEventEntry>,
	userId: number,
	existingOmqSmqId?: string,
): Promise<void> {
	await saveSensorData(omqSmqPayload, userId, existingOmqSmqId);
}
