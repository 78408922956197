import {Action, applyMiddleware, combineReducers, compose, createStore, Reducer, Store, Unsubscribe} from "redux";
import thunk, {ThunkDispatch} from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import {AppState} from "./AppState";
import {rootReducer} from "./rootReducer";
import rootSaga from "./rootSaga";
import {AppConfig} from "app/AppConfig";
import setupAnalyticsMiddleware from "../../analytics/setupAnalyticsMiddleware";
import {routerMiddleware} from "connected-react-router";
import {history} from "../../helpers/routerHistory";

/**
 * TODO: This is temporary action type.
 * Consider move it to a specific action if necessary.
 */
export const RESET_APP_STATE = "@@App/RESET_APP_STATE";

interface EnhancedStore<S> extends Store<S> {
	asyncReducers: Reducer<any>;
	injectReducer: (key: string, reducer: Reducer<any>) => void;
	injectSaga: (key: string, saga: any) => void;
}

function createReducer(asyncReducers?: Reducer<any>): Reducer<any> {
	const appReducer = combineReducers({
		...rootReducer,
		...asyncReducers,
	});
	return (state: AppState, action: Action) => {
		if (action.type === RESET_APP_STATE) {
			return appReducer(undefined, action);
		}

		return appReducer(state, action);
	};
}

// tslint:disable-next-line
function createSagaInjector(runSaga, rootSaga) {
	// Create a dictionary to keep track of injected sagas
	const injectedSagas = new Map();

	const isInjected = (key) => injectedSagas.has(key);

	const injectSaga = (key, saga) => {
		// We won't run saga if it is already injected
		if (isInjected(key)) {
			return;
		}

		// Sagas return task when they executed, which can be used
		// to cancel them
		const task = runSaga(saga);

		// Save the task if we want to cancel it in the future
		injectedSagas.set(key, task);
	};

	// Inject the root saga as it a staticlly loaded file,
	injectSaga("root", rootSaga);

	return injectSaga;
}

function setupStore(): EnhancedStore<AppState> {
	//@ts-ignore // enable the Redux Tools in Chrome
	const REDUX_TOOLS: any = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

	const sagaMiddleware = createSagaMiddleware();

	const middlewares = [thunk, sagaMiddleware, setupAnalyticsMiddleware, routerMiddleware(history)];

	let store: EnhancedStore<AppState>;

	// Enable Redux Tools support only in Developer's build.
	if (REDUX_TOOLS) {
		// @ts-ignore
		store = createStore(createReducer(), {} as any, compose(applyMiddleware(...middlewares), ...[REDUX_TOOLS]));
	} else {
		// @ts-ignore
		store = createStore(createReducer(), {} as any, compose(applyMiddleware(...middlewares)));
	}

	// @ts-ignore
	store.asyncReducers = {};

	store.injectReducer = (key, asyncReducer) => {
		if (store.asyncReducers[key]) {
			return;
		}
		store.asyncReducers[key] = asyncReducer;
		store.replaceReducer(createReducer(store.asyncReducers));
	};

	store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

	return store;
}

export const store = setupStore();

/////////////////////////////////////////////////////////////
//   Below are  pass-through methods for Store<AppState>   //
/////////////////////////////////////////////////////////////

export const storeDispatch = store.dispatch as ThunkDispatch<AppState, any, Action>;

export function getStoreState(): AppState {
	return store.getState();
}

export function replaceReducer(nextReducer: Reducer<AppState>): void {
	store.replaceReducer(nextReducer);
}

export function subscribeStore(listener: () => void): Unsubscribe {
	return store.subscribe(listener);
}

if (!AppConfig.isProduction) {
	// @ts-ignore
	window.store = store;
}
