import {CallSignalType, TerminationReason} from "@sense-os/goalie-js";
import {DISC} from "IoC/DISC";
import {put, call, takeEvery, select} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {getAuthUser} from "../../../auth/redux";
import {callActions} from "../../redux/callActions";

/**
 * Handle cancel signal from counterpart.
 * - Stop call timeout (Only if logged in user is the call initiator)
 * - Update leave time in redux state
 * - Send call summary to the room
 */
function* handleCancelSignal(action: ActionType<typeof callActions.handleCancelSignal>) {
	const {signal} = action.payload;
	const authUser = yield select(getAuthUser);

	if (signal.type !== CallSignalType.Initiation) {
		return;
	}

	const cancelationTime = Date.now();

	// Update receipent's leave time.
	yield put(callActions.userLeaveCall(signal.recipientId, cancelationTime));

	if (signal.recipientId !== authUser.id) {
		// In a group call, there's a case where user can invite another participant, but the participant doesn't answer.
		// When this happens, the call initiator will send Cancellation signal to all participants in the room.
		// The call summary should only be sent to the recipient (The participant who didn't show up).
		yield call(DISC.getVideoCallService().sendCallSummary, TerminationReason.Cancelled, signal.recipientId);
	}

	// Check if we need to terminate the call, then send call summary to all participants that still in the call
	yield put(callActions.endCallIfNeeded(TerminationReason.Cancelled));
	yield call(DISC.getVideoCallService().showTerminationToast, signal.senderId, TerminationReason.Cancelled);
}

export function* handleCancelSignalSaga() {
	yield takeEvery(callActions.handleCancelSignal, handleCancelSignal);
}
