import {createAction, createAsyncAction, ActionType} from "typesafe-actions";
import {FileItem, FileTypeFilter, SelectedFile} from "../fileSharingTypes";

export const fileSharingActions = {
	/**
	 * Form dialog actions
	 */
	openFileBrowser: createAction("fileSharingActions/openFileBrowser")(),
	closeFileBrowser: createAction("fileSharingActions/closeFileBrowser")(),
	/**
	 * Delete dialog actions
	 */
	openDeleteDialog: createAction("fileSharingActions/openDeleteDialog", (userId: number, deletingIds: number[]) => ({
		userId,
		deletingIds,
	}))(),
	closeDeleteDialog: createAction("fileSharingActions/closeDeleteDialog", (userId: number) => ({userId}))(),

	/**
	 * Select and remove files actions
	 */
	setSelectedFile: createAction(
		"fileSharingActions/setSelectedFile",
		(userId: number, selectedFiles?: SelectedFile[]) => ({
			userId,
			selectedFiles,
		}),
	)(),
	/**
	 * Select and remove files actions
	 */
	addFileItems: createAction("fileSharingActions/addFileItems", (userId: number, fileItems?: FileItem[]) => ({
		userId,
		fileItems,
	}))(),
	/**
	 * Upload a single file.
	 */
	upload: createAsyncAction(
		"fileSharingActions/upload",
		"fileSharingActions/uploadDidSuccess",
		"fileSharingActions/uploadDidFail",
	)<
		{userId: number; fileIndex: number},
		{userId: number; fileIndex: number; data: any},
		{userId: number; fileIndex: number; error: Error}
	>(),
	/**
	 * Upload multiple files.
	 */
	uploadFiles: createAsyncAction(
		"fileSharingActions/uploadFiles",
		"fileSharingActions/uploadFilesDidSuccess",
		"fileSharingActions/uploadFilesDidFail",
	)<{userId: number}, {userId: number}, {userId: number}>(),
	/**
	 * Delete a single file.
	 */
	deleteFile: createAsyncAction(
		"fileSharingActions/delete",
		"fileSharingActions/deleteDidSuccess",
		"fileSharingActions/deleteDidFail",
	)<
		{userId: number; ids: number[]},
		{ids: number[]; userId: number},
		{ids: number[]; userId: number; error: Error}
	>(),
	/**
	 * Fetch files with filter and pagination params.
	 */
	loadFiles: createAsyncAction(
		"fileSharingActions/loadFiles",
		"fileSharingActions/loadFilesDidSuccess",
		"fileSharingActions/loadFilesDidFail",
	)<
		{
			userId: number;
			ids?: number[];
			type?: FileTypeFilter;
			page?: number;
			pageSize?: number;
		},
		{
			userId: number;
			hasMore?: boolean;
			currentPage?: number;
			fileItems: FileItem[];
		},
		{userId: number; error: Error}
	>(),
	/**
	 * Generate blob url from google cloud storage link.
	 */
	loadBlob: createAsyncAction(
		"fileSharingActions/loadBlob",
		"fileSharingActions/loadBlobDidSuccess",
		"fileSharingActions/loadBlobDidFail",
	)<
		{userId: number; chatFile: FileItem; type: "thumb" | "full"},
		{
			userId: number;
			id: number;
			urls: {
				blobUrl?: string;
				blobThumbnailUrl?: string;
			};
		},
		{userId: number; error: Error}
	>(),
};
export type FileSharingActionType = ActionType<typeof fileSharingActions>;
