import {takeEvery, put} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";

import {calendarActions} from "../../redux/calendarActions";
import {getSensorDataById} from "../../helpers/calendarHelpers";
import {PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";

const log = createLogger("getSessionDetailsSaga", SentryTags.Calendar);

function* handleFetchSessionDetails(action: ActionType<typeof calendarActions.fetchSessionDetails.request>) {
	const {sessionId} = action.payload;
	try {
		// Fetch the sensor data of session for getting the `userId`, `startTime` and `endTime`
		const sensorData: SensorDatum<PlannedEventEntry> = yield apiCallSaga(getSensorDataById, sessionId);
		yield put(calendarActions.fetchSessionDetails.success({sessionData: sensorData}));
	} catch (err) {
		log.captureException(err);
		yield put(calendarActions.fetchSessionDetails.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(calendarActions.fetchSessionDetails.request), handleFetchSessionDetails);
}
