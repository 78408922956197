import {SensorDataResponse} from "@sense-os/goalie-js";
import {BaseSensorDataModel, ExpectedFeeling} from "@sense-os/goalie-js/dist/tracking/SensorData";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";
import {DISC} from "IoC/DISC";
import {UserUtils} from "utils/UserUtils";
import strTranslation from "../../../assets/lang/strings";
import {Contact} from "../../../contacts/contactTypes";
import {compareCustomRepetition} from "../../../customRepetition/helpers/CustomRepetitionHelpers";
import {RepeatedOption, CustomRepetitionFormValues} from "../../../customRepetition/helpers/CustomRepetitionTypes";
import {convertRRuleToUIState} from "../../../customRepetition/helpers/RRuleHelpers";
import localization from "../../../localization/Localization";
import {
	EventViewData,
	EventViewType,
	PlannedEventStatus,
	SensorConfig,
	SensorConfigs,
	Sensors,
} from "../../../ts/redux/tracking/TrackingTypes";
import {defaultEmotionValues} from "../../../ts/visual/feature/clientProfile/trackingData/Emotions";
import {PlanActivityFormValues} from "../redux/planActivityTypes";

/**
 * Save expected feeling
 *
 * Pass `expectedFeelingId` to update expected feeling
 *
 * @param {number} userId
 * @param {PlanActivityFormValues} formValues
 * @param {string} expectedFeelingId Previous expected feeling sensor id
 */
export function saveExpectedFeelingSensor(
	userId: number,
	formValues: PlanActivityFormValues,
	expectedFeelingId?: string,
): Promise<SensorDataResponse<ExpectedFeeling>> {
	const expectedFeelingConfig: SensorConfig = SensorConfigs[Sensors.EXPECTED_FEELING];
	/**
	 * feeling value doesn't accept `0`, so if there is any, then convert to `null`
	 */
	Object.keys(formValues.feelings).forEach((key) => {
		if (formValues.feelings[key] === 0) {
			formValues.feelings[key] = null;
		}
	});
	const expectedFeelingSensor: BaseSensorDataModel<ExpectedFeeling> = {
		sourceName: expectedFeelingConfig.sourceName,
		sensorName: expectedFeelingConfig.name,
		startTime: formValues.plannedFor,
		endTime: formValues.plannedFor,
		version: expectedFeelingConfig.version,
		value: {...defaultEmotionValues, ...formValues.feelings},
	};

	return DISC.getTrackingService().saveSensorData(expectedFeelingSensor, userId, expectedFeelingId);
}

/**
 * Transform `PlanActivityFormValues` to `BaseSensorData<BasePlannedEventEntry>`
 *
 * @param {PlanActivityFormValues} formValues
 * @param {string} expectedFeelingUri
 */
export async function transformFormValuesToPlannedEventSensor(
	formValues: PlanActivityFormValues,
	plannedEventId: string,
	expectedFeelingUri?: string,
): Promise<BaseSensorDataModel<BasePlannedEventEntry>> {
	// Transform form values to planned event sensor data
	let plannedEventSensor = transformPlanActivityValueToSensorData(formValues);

	// Check if plannedEventId exists. (Means this is an update operation)
	if (plannedEventId) {
		const [previousPlannedEventSensor] =
			await DISC.getTrackingService().sdk.querySensorDataByIds<BasePlannedEventEntry>(
				[plannedEventId],
				SensorConfigs[Sensors.PLANNED_EVENT].sourceName,
			);

		if (!previousPlannedEventSensor) {
			throw new Error("Planned event does not exist:" + plannedEventId);
		}

		// Set plan event status to existing status
		plannedEventSensor.value.status = previousPlannedEventSensor.value.status;

		// Set plan event reflection to existing reflection
		if (previousPlannedEventSensor.value.reflection) {
			plannedEventSensor.value.reflection = previousPlannedEventSensor.value.reflection;
		}

		// Set version to existing version
		plannedEventSensor.version = previousPlannedEventSensor.version;
	}

	if (expectedFeelingUri) {
		// Attach expected feeling to sensor data if expectedFeelingUri exists
		plannedEventSensor.value.feeling = {
			"%uri": expectedFeelingUri,
		};
	}

	return plannedEventSensor;
}

/**
 * Transforms given formValues into SensorData<PlannedEventEntry>
 */
export function transformPlanActivityValueToSensorData(
	formValues: PlanActivityFormValues,
): BaseSensorDataModel<BasePlannedEventEntry> {
	const {plannedFor, title, description, activityType, shouldSendNotification} = formValues;
	const plannedEventConfig: SensorConfig = SensorConfigs[Sensors.PLANNED_EVENT];
	return {
		sourceName: plannedEventConfig.sourceName,
		sensorName: plannedEventConfig.name,
		startTime: plannedFor,
		endTime: plannedFor,
		version: plannedEventConfig.version,
		value: {
			title: title.trim(),
			description: description ? description.trim() : "",
			plannedFor: plannedFor.toISOString(),
			activityType: activityType as any,
			status: PlannedEventStatus.INCOMPLETE,
			shouldSendNotification,
		},
	};
}

/**
 * Determine if UpdateOptionDialog can show `This event only` option.
 *
 * @param {RepeatedOption} newRepeatedOption
 * @param {CustomRepetitionFormValues} newCustomRepetition
 * @param {EventViewData} eventData
 */
export const canShowThisEventOnlyUpdateOption = (
	newRepeatedOption: RepeatedOption,
	newCustomRepetition: CustomRepetitionFormValues,
	eventData: EventViewData,
): boolean => {
	if (!eventData) {
		return false;
	}
	if (eventData.type !== EventViewType.RECURRING_PLANNED_EVENT) {
		return false;
	}

	const {customRepetition: previousCustomRepetition, repeatedOption: previousRepeatedOption} = convertRRuleToUIState(
		eventData.source.payload.plannedEvent.recurringExpression.rrule,
	);

	if (newRepeatedOption === RepeatedOption.CUSTOM) {
		return compareCustomRepetition(previousCustomRepetition, newCustomRepetition);
	}

	return newRepeatedOption === previousRepeatedOption;
};

/**
 * Return string to be used as form title in PlanActivityForm.
 */
export const getFormTitle = (eventId: string, isReplan: boolean, client: Contact): string => {
	const isEditing = !!eventId;

	const selectedKey: string =
		isEditing && !isReplan
			? strTranslation.GRAPHS.edit_event.plan.header
			: strTranslation.GRAPHS.new_event.plan.header;
	return localization.formatMessage(selectedKey, {
		name: !client ? "" : UserUtils.getName(client.firstName, client.lastName, client.email),
	});
};
