/**
 * This file is where we will trigger any portal-based data migration.
 * The idea is to have a flag in user settings that indicate whether or not
 * we will run the migration for the user.
 *
 * As this flag depends on user settings, we will only trigger the migration
 * once the initial "load settings" at the start of the portal succeed.
 *
 * To make it easy for us to know what kind of migration will be running,
 * we put it all in this file.
 */

import {call, put, select} from "redux-saga/effects";
import {getIsUserSettingsLoaded, getSettings} from "./redux/userSettingsSelectors";
import {UserSettings} from "./userSettingsTypes";
import {UserSettingsBasedMigrationKey} from "./userSettingsMigrationKeys";
import {migrateClientNumberToTreatmentSaga} from "../treatmentStatus/sagas/migrateClientNumberToTreatmentSaga";
import {userSettingsAction} from "./redux/userSettingsActions";
import {SentryTags} from "../errorHandler/createSentryReport";
import createLogger from "../logger/createLogger";
import {DoSomething, doSomethingIfOrWaitUntil} from "../helpers/sagas/stateMachine";
import {getType} from "typesafe-actions";

const log = createLogger("User Settings - Migration", SentryTags.UserSettings);

/**
 * This is the main saga function. Though, we export another saga at the bottom as
 * we need to wrap this saga so it only run once we finish loading the settings first.
 */
function* userSettingsBasedMigrationSaga() {
	const settings: UserSettings = yield select(getSettings);

	if (!settings.migrationFlags[UserSettingsBasedMigrationKey.CLIENT_NUMBER_UNIFICATION]) {
		yield migrationWrapper(
			UserSettingsBasedMigrationKey.CLIENT_NUMBER_UNIFICATION,
			migrateClientNumberToTreatmentSaga,
		);
	}
}

/**
 * This is the wrapper for migration.
 * If we ever need to do another migration, simply reuse this wrapper,
 * and add it to `userSettingsBasedMigrationSaga`.
 */
function* migrationWrapper(key: UserSettingsBasedMigrationKey, fn: DoSomething) {
	try {
		yield call(fn);

		const settings: UserSettings = yield select(getSettings);
		yield put(
			userSettingsAction.saveSettings.request({
				settings: {
					migrationFlags: {
						...settings.migrationFlags,
						[key]: true,
					},
				},
			}),
		);
	} catch (error) {
		log.captureException(error);
	}
}

/**
 * Run the migration only when we finish loading the user settings first.
 */
export default function* () {
	yield doSomethingIfOrWaitUntil(
		userSettingsBasedMigrationSaga,
		select(getIsUserSettingsLoaded),
		getType(userSettingsAction.loadSettings.success),
	);
}
