export enum SortDirection {
	Ascending = "asc",
	Descending = "desc",
	Neutral = "neutral",
}

export interface SortingReduxState {
	sortingMap: {
		[key in string]: {
			sortingKeys: string[];
			sortingDirections: Record<string, SortDirection>;
		};
	};
}

export const emptySortingMapItem = {
	sortingKeys: [] as string[],
	sortingDirections: {} as Record<string, SortDirection>,
};
