import produce from "immer";
import {getType} from "typesafe-actions";

import {startCallScreenActions, StartCallScreenTypeActions} from "./startCallScreenActions";
import {StartCallScreenState} from "../types";

const defaultState: StartCallScreenState = {
	isDialogOpen: false,
	isAudio: false,
	isVideo: false,
	selectedCallType: null,
};

export const startCallScreenReducer = produce((state: StartCallScreenState, action: StartCallScreenTypeActions) => {
	switch (action.type) {
		case getType(startCallScreenActions.openCallScreen): {
			state.isDialogOpen = true;
			state.isVideo = action.payload.isVideo;
			state.isAudio = action.payload.isAudio;
			return;
		}

		case getType(startCallScreenActions.closeCallScreen): {
			state.isDialogOpen = false;
			return;
		}

		case getType(startCallScreenActions.setVideoCallType): {
			state.isVideo = true;
			state.isAudio = false;
			return;
		}

		case getType(startCallScreenActions.setAudioCallType): {
			state.isVideo = false;
			state.isAudio = true;
			return;
		}

		case getType(startCallScreenActions.setOutgoingCallType): {
			state.selectedCallType = action.payload.selectedCallType;
			return;
		}
	}
}, defaultState);
