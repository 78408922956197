import {takeLatest, put, select, call} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {Organization, OrganizationGroup} from "@sense-os/goalie-js";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {organizationActions} from "../redux/organizationActions";
import {getOrganizationsGroups, getNonGroupOrganizations} from "../redux/organizationSelectors";
import {AvailableOrganization} from "../organizationsTypes";
import {
	convertOrganizationGroupsIntoAvailableOrganizations,
	sortOrganizationsByOrgName,
} from "../helpers/organizationHelper";

import organizationSDK from "../helpers/organizationSdk";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";

const log = createLogger("setAvailableOrganizationsSaga", SentryTags.Organization);

function* handleSetAvailableOrganizations() {
	try {
		const token = yield call(getSessionId);

		// Get `orgGroups` and `orgList` from redux
		const orgGroups: OrganizationGroup[] = yield select(getOrganizationsGroups);
		const nonGroupOrgList: Organization[] = yield select(getNonGroupOrganizations);

		// Convert `orgGroups` and `orgList` into AvailableOrganization
		const convertedGroups: AvailableOrganization[] = convertOrganizationGroupsIntoAvailableOrganizations(orgGroups);
		const convertedNonGroupOrgs: AvailableOrganization[] = nonGroupOrgList;

		// Merged `convertedGroups` and `convertedOrgs` then sort it by org name
		const sortedAvailableOrgs: AvailableOrganization[] = sortOrganizationsByOrgName([
			...convertedGroups,
			...convertedNonGroupOrgs,
		]);

		// Fill `orgMembers` of organization group
		for (let i = 0; i < sortedAvailableOrgs.length; i++) {
			if (sortedAvailableOrgs[i].isOrgGroup) {
				try {
					const members = yield apiCallSaga(
						organizationSDK.getOrganizationsByGroupId,
						token,
						{page: 1, pageSize: 1000},
						sortedAvailableOrgs[i].id,
					);

					sortedAvailableOrgs[i].orgMembers = members;
				} catch (err) {
					log.captureException(err);
				}
			}
		}

		yield put(organizationActions.setAvailableOrganizations(sortedAvailableOrgs));
	} catch (err) {
		log.captureException(err);
	}
}

export default function* () {
	yield yield takeLatest(
		[
			getType(organizationActions.loadOrganizationsGroups.success),
			getType(organizationActions.loadNonGroupOrganizations.success),
		],
		handleSetAvailableOrganizations,
	);
}
