import {takeEvery, select, put} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import moment from "moment";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {TIME_UNITS} from "constants/time";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import needDrivenDashboardService from "../helpers/NeedDrivenDashboardService";
import {clientsFeedActions} from "../redux/ClientsFeedActions";
import {getHourRange, getActiveLabel} from "../redux/ClientsFeedSelectors";
import {ClientsFeedLabel, UserFeed} from "../clientsFeedTypes";

const log = createLogger("loadClientsFeedSaga", SentryTags.NDD);

/**
 * Load clients feed based on active label & hour range
 */
function* loadClientsFeed() {
	const activeLabel: ClientsFeedLabel = yield select(getActiveLabel);
	const hourRange: number = yield select(getHourRange);
	const isPastActivity: boolean = activeLabel === ClientsFeedLabel.PAST_ACTIVITY;

	// Set time for past activity as default selected activity
	let startTime = moment().subtract(hourRange, TIME_UNITS.HOURS).toDate();
	let endTime = moment().toDate();

	if (!isPastActivity) {
		// Set time for upcoming activity
		startTime = moment().toDate();
		endTime = moment().add(hourRange, TIME_UNITS.HOURS).toDate();
	}

	try {
		const userFeeds: UserFeed[] = yield apiCallSaga(
			[needDrivenDashboardService, needDrivenDashboardService.getUserFeeds],
			startTime,
			endTime,
			isPastActivity,
		);
		// Store clientFeeds to state
		yield put(clientsFeedActions.loadFeeds.success({label: activeLabel, feeds: userFeeds}));
	} catch (err) {
		yield put(clientsFeedActions.loadFeeds.failure(err));
		log.captureException(err);
	}
}

export default function* () {
	yield takeEvery(getType(clientsFeedActions.loadFeeds.request), loadClientsFeed);
}
