import {getType} from "typesafe-actions";

import {startCallScreenActions, StartCallScreenTypeActions} from "./startCallScreenActions";
import analyticsService from "../../analytics/AnalyticsService";

const trackedActions = [
	startCallScreenActions.openCallScreen,
	startCallScreenActions.closeCallScreen,

	startCallScreenActions.setAudioCallType,
	startCallScreenActions.setVideoCallType,
	startCallScreenActions.setOutgoingCallType,
].map(getType);

const properties = (action: StartCallScreenTypeActions) => {
	switch (action.type) {
		case getType(startCallScreenActions.openCallScreen):
		case getType(startCallScreenActions.setOutgoingCallType):
			return action.payload;
		default:
			return null;
	}
};

export const startCallScreenEvents = analyticsService.trackReduxActions(trackedActions, properties);
