import {takeEvery} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {interventionTaskRegistrationActions} from "../redux/interventionTaskRegistrationAction";
import {fetchInterventionTaskRegistration} from "./fetchInterventionTaskRegistration";

export default function* () {
	yield takeEvery(
		getType(interventionTaskRegistrationActions.fetchTaskRegistration.request),
		fetchInterventionTaskRegistration,
	);
}
