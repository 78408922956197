import {generatePath} from "react-router";
import {CaseloadSubPath, Param, Path} from "../ts/app/Path";

const generateCaseloadPath = (subpath: CaseloadSubPath) =>
	generatePath(Path.APP_THERAPIST_CASELOAD_SUBPATH, {[Param.CASELOAD_SUBPATH]: subpath});

export const caseloadManagementPaths: Record<CaseloadSubPath, string> = {
	[CaseloadSubPath.INVITE_CLIENTS]: generateCaseloadPath(CaseloadSubPath.INVITE_CLIENTS),
	[CaseloadSubPath.CONNECTED]: generateCaseloadPath(CaseloadSubPath.CONNECTED),
	[CaseloadSubPath.GROUPS]: generateCaseloadPath(CaseloadSubPath.GROUPS),
	[CaseloadSubPath.PENDING]: generateCaseloadPath(CaseloadSubPath.PENDING),
};
