import {useEffect, FC} from "react";
import {useDispatch} from "react-redux";
import {CircularProgress, Container} from "@material-ui/core";

import {history} from "visual/App";
import {Path} from "../../ts/app/Path";

import {calendarActions} from "../redux/calendarActions";

export const CalendarConnectRedirect: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const locationSearch = window.location.search;
		const params = new URLSearchParams(locationSearch);

		if (params.has("code")) {
			// Get `code` value from query params
			const cronofyCode = params.get("code");
			// Dispatch `createAccessToken` action
			dispatch(calendarActions.createAccessToken(cronofyCode));
		} else {
			// For other call back url, we'll redirected it to calendar page
			history.push(Path.APP_CALENDARS);
		}
	}, [dispatch]);

	return (
		<Container>
			<div style={{margin: "300px auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
				<img src="/assets/img/all-logo/logo.png" style={{minWidth: 100, marginBottom: 20}} />
				<CircularProgress />
			</div>
		</Container>
	);
};
