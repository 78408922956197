import {Omq} from "@sense-os/sensor-schema/goalie-2-ts/omq";
import {SensorDatum, Smq} from "redux/tracking/TrackingTypes";
import {createAsyncAction, ActionType} from "typesafe-actions";

export const romActions = {
	/**
	 * Fetch rom
	 */
	fetchRom: createAsyncAction("romActions/fetchRom", "romActions/fetchRomSucceed", "romActions/fetchRomFailed")<
		{userId: number; startDate: Date},
		{omqData: SensorDatum<Omq>[]; smqData: SensorDatum<Smq>[]},
		void
	>(),
};

export type RomActionType = ActionType<typeof romActions>;
