import {ImageSize} from "../fileSharingTypes";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";

const log = createLogger("imageResizeHelper", SentryTags.FileSharing);

/**
 * Resize File with image type and will return Blob
 * @param file
 * @param maxWidth  maximum target width, default value is 512px
 * @param maxHeight maximum target height,  default value is 512px
 * @param quality  default value is 100, means 100%
 * @param minWidth minimum target width,  default value is 0px
 * @param minHeight minimum target width,  default value is 0px
 *
 * @returns Promise<Blob>
 */
export const resizeFileImage = async (
	file: File,
	maxWidth: number = 1024,
	maxHeight: number = 1024,
	quality: number = 100,
	minWidth: number = 0,
	minHeight: number = 0,
): Promise<Blob> => {
	// read file
	const reader = new FileReader();
	await reader.readAsDataURL(file);
	// load file
	return new Promise((resolve, reject) => {
		// reject if file.type !== image
		if (file.type && !file.type.includes("image")) {
			reject("File Is not Image!");
		}
		reader.onload = () => {
			try {
				let image = new Image();
				image.src = reader.result as string;
				// load image
				image.onload = async () => {
					const resizedDataUrl = resizeImage(
						image,
						maxWidth,
						maxHeight,
						minWidth,
						minHeight,
						file.type,
						quality,
					);
					// convert resizedDataUrl type from Based 64 to Blob
					const blob = await convertB64toBlob(resizedDataUrl, file.type);
					resolve(blob);
				};
			} catch (error) {
				reject(error);
				log.captureException(error);
			}
			reader.onerror = (error) => {
				reject(error);
				log.captureException(error);
			};
		};
	});
};

/**
 * resize image to expected target size
 */
const resizeImage = (
	image: HTMLImageElement,
	maxWidth: number,
	maxHeight: number,
	minWidth: number,
	minHeight: number,
	contentType: string,
	quality: number,
) => {
	// set quality (percent, 1 to 100)
	const qualityDecimal = quality / 100;

	const newHeightWidth = setHeightWidth(image.height, maxHeight, image.width, maxWidth, minWidth, minHeight);

	// set canvas height and width
	const canvas = document.createElement("canvas");
	canvas.width = newHeightWidth.width;
	canvas.height = newHeightWidth.height;

	// target size
	const width = newHeightWidth.width;
	const height = newHeightWidth.height;

	let ctx = canvas.getContext("2d");
	ctx.fillStyle = "rgba(0, 0, 0, 0)";
	ctx.fillRect(0, 0, width, height);

	if (ctx.imageSmoothingEnabled && ctx.imageSmoothingQuality) {
		ctx.imageSmoothingQuality = "high";
	}

	ctx.drawImage(image, 0, 0, width, height);

	return canvas.toDataURL(contentType, qualityDecimal);
};

/**
 * set target height and width
 */
const setHeightWidth = (
	height: number,
	maxHeight: number,
	width: number,
	maxWidth: number,
	minWidth: number,
	minHeight: number,
): {height: number; width: number} => {
	let newHeight: number = height;
	let newWidth: number = width;

	if (width > maxWidth) {
		newHeight = Math.round((height * maxWidth) / width);
		newWidth = maxWidth;
	}
	if (height > maxHeight) {
		newWidth = Math.round((width * maxHeight) / height);
		newHeight = maxHeight;
	}
	if (minWidth && width < minWidth) {
		newHeight = Math.round((height * minWidth) / width);
		newWidth = minWidth;
	}
	if (minHeight && height < minHeight) {
		newWidth = Math.round((width * minHeight) / height);
		newHeight = minHeight;
	}
	return {height: newHeight, width: newWidth};
};

/**
 * Resize base64 image file
 */
export const resizeB64Image = (base64String: string, imageSize: ImageSize): Promise<Blob> => {
	// Get the mime type of file from `base64String`
	const mimeType = base64String?.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
	// Create canvas
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	const img = new Image();

	return new Promise((resolve, reject) => {
		img.onload = () => {
			try {
				// Set canvas height and width
				if (img.height > img.width) {
					const ratio = img.height / img.width;
					canvas.width = imageSize;
					canvas.height = ratio * imageSize;
				} else {
					const ratio = img.width / img.height;
					canvas.width = ratio * imageSize;
					canvas.height = imageSize;
				}
				ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
				resolve(convertB64toBlob(canvas.toDataURL(mimeType)));
			} catch (error) {
				reject(error);
				log.captureException(error);
			}
		};
		img.onerror = (error) => {
			reject(error);
			log.captureException(error);
		};
		img.src = base64String;
	});
};

/**
 * Convert base64String to Object Blob.
 * @param b64Data base64 string to be converted
 * @param contentType Content Type
 * @param sliceSize Length of array item per convertion, default value is a sampling result which good enough.
 * @returns
 */
export const convertB64toBlob = (b64Data: string, contentType = "image/jpeg", sliceSize = 512): Blob => {
	// Decode a Base64-encoded string into a new string with a character for each byte of the binary data.
	const byteCharacters = atob(b64Data.split(",")[1]);

	const byteArrays: Uint8Array[] = [];
	// Loop process to, convert each caracter of binary data into a real typed byte array.
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		// slicing the array to improve the convertion performance.
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, {type: contentType});
};
