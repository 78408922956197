import {Typography} from "@material-ui/core";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {NDColors} from "../../../../material-ui/NDColors";
import {GraphV2Type, IGraphConfig} from "../../customTrackerGraphV2Types";
import {averageIntensityConfig} from "./averageIntensityConfig";

enum RESEARCH_EMOTION_MEASURE {
	AFRAID = "measureAfraid",
	ANGRY = "measureAngry",
	ASHAMED = "measureAshamed",
	CURIOSITY = "measureCuriosity",
	ENERGY = "measureEnergy",
	GUILTY = "measureGuilty",
	HAPPY = "measureHappy",
	INSECURE = "measureInsecure",
	SAD = "measureSad",
	TENSION = "measureTension",
}

type MeasureResearchList = {
	measureName: RESEARCH_EMOTION_MEASURE;
	id: string;
	color: string;
};

const measureResearchList: MeasureResearchList[] = [
	{
		measureName: RESEARCH_EMOTION_MEASURE.AFRAID,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.afraid.name,
		color: NDColors.ORANGE,
	},
	{
		measureName: RESEARCH_EMOTION_MEASURE.ANGRY,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.angry.name,
		color: NDColors.ROYAL_BLUE,
	},
	{
		measureName: RESEARCH_EMOTION_MEASURE.ASHAMED,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.ashamed.name,
		color: NDColors.FRENCH_VIOLET,
	},
	{
		measureName: RESEARCH_EMOTION_MEASURE.CURIOSITY,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.curious.name,
		color: NDColors.BABY_PINK,
	},
	{
		measureName: RESEARCH_EMOTION_MEASURE.ENERGY,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.energy.name,
		color: NDColors.VIVID_HARLEQUIN,
	},
	{
		measureName: RESEARCH_EMOTION_MEASURE.GUILTY,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.guilty.name,
		color: NDColors.RED_PEPPER,
	},
	{
		measureName: RESEARCH_EMOTION_MEASURE.HAPPY,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.happy.name,
		color: NDColors.AQUA_BLUE,
	},
	{
		measureName: RESEARCH_EMOTION_MEASURE.INSECURE,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.insecure.name,
		color: NDColors.ELF_GREEN,
	},
	{
		measureName: RESEARCH_EMOTION_MEASURE.SAD,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.sad.name,
		color: NDColors.CARMINE,
	},
	{
		measureName: RESEARCH_EMOTION_MEASURE.TENSION,
		id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.tension.name,
		color: NDColors.FUCHSIA,
	},
];

const measures: string[] = measureResearchList.map((measure) => measure.measureName);

export const researchEmotionsConfig = (): IGraphConfig => {
	return {
		graphType: GraphV2Type.RESEARCH_EMOTION,
		graphName: localization.formatMessage(strTranslation.ZZ_CUSTOM_TRACKER.tracker["3D_research_emotions"].name),
		yLegend: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_intensity),
		xLegend: localization.formatMessage(strTranslation.TIME.date),
		yToolTipFormatter: (label, value) => {
			const text = `${localization.formatMessage(
				strTranslation.CUSTOM_TRACKER.chart.average_intensity,
			)} : ${value}`;
			return (
				<div>
					<Typography variant="body2" style={{fontWeight: 500}}>
						{localization.formatMessage(label)}
					</Typography>
					<Typography variant="body2">{text}</Typography>
				</div>
			);
		},
		useGraphData: averageIntensityConfig(measures, measureResearchList).useGraphData,
	};
};
