/** Author: leo Date: 28/06/2021 */

import {FC} from "react";
import {Backdrop, makeStyles} from "@material-ui/core";
import {useSelector} from "react-redux";
import {shouldPortalBeClosed} from "../redux/versionCheckSelector";
import {FormattedMessage} from "react-intl";
import strTranslation from "../../assets/lang/strings";

interface Props {}

const useStyles = makeStyles(() => ({
	backdrop: {
		zIndex: Number.MAX_SAFE_INTEGER,
		color: "#fff",
		backgroundColor: "#000000db",
	},
}));

/**
 * This component is shown when the portal version on the browser is out of sync with the portal version on the webserver
 * and the user clicked a button for "hard refresh". The latest portal version is then opened in a new tab and
 * this component is rendered in the old tab.
 * @constructor
 */
export const BackdropOfObsolescence: FC<Props> = () => {
	const classes = useStyles();
	const displayBackdropOfObsolescence = useSelector(shouldPortalBeClosed);
	return (
		<>
			<Backdrop className={classes.backdrop} open={displayBackdropOfObsolescence} onClick={null}>
				<h1 style={{textAlign: "center"}}>
					<FormattedMessage id={strTranslation.VERSION.close_window.prompt.p1} />
					<br />
					<FormattedMessage id={strTranslation.VERSION.close_window.prompt.p2} />
				</h1>
			</Backdrop>
		</>
	);
};
