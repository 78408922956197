import {fork} from "redux-saga/effects";

import {whenLoggedIn} from "../../auth/sagas/helper";

import createOrUpdateTreatmentSaga from "./createOrUpdateTreatmentSaga";
import endTreatmentSaga from "./endTreatmentSaga";
import loadTreatmentFormOptionsSaga from "./loadTreatmentFormOptionsSaga";
import loadTreatmentsSaga from "./loadTreatmentsSaga";

function* treatmentStatusSaga() {
	yield fork(createOrUpdateTreatmentSaga);
	yield fork(endTreatmentSaga);
	yield fork(loadTreatmentFormOptionsSaga);
	yield fork(loadTreatmentsSaga);
}

export default function* () {
	yield fork(whenLoggedIn(treatmentStatusSaga));
}
