import {LoadingState} from "constants/redux";
import {Action, PayloadAction, PayloadActionCreator, getType} from "typesafe-actions";

/**
 * A helper type to help the internal of LoadingStateReducer below.
 */
interface AsyncAction<X extends string, Y extends string, Z extends string, A, B, C> {
	request: PayloadActionCreator<X, A>;
	success: PayloadActionCreator<Y, B>;
	failure: PayloadActionCreator<Z, C>;
}

export class LoadingStateReducer<T> {
	loadingFns: Array<{
		key: keyof T;
		action: AsyncAction<any, any, any, any, any, any>;

		/**
		 * This is the function to get the identity of an action.
		 * In the case where the reducer has a map of loading state,
		 * we need to find the "key"/"identity" of the action
		 * so that we can set the correct loading state.
		 */
		idFn?: (action: PayloadAction<any, any> | PayloadAction<any, any> | PayloadAction<any, any>) => string | number;
	}> = [];

	addLogic<X extends string, Y extends string, Z extends string, A, B, C>(
		key: keyof T,
		action: AsyncAction<X, Y, Z, A, B, C>,
		idFn?: (action: PayloadAction<X, A> | PayloadAction<Y, B> | PayloadAction<Z, C>) => string | number,
	): this {
		this.loadingFns.push({key, action, idFn});
		return this;
	}

	apply(state: T, inputAction: Action) {
		this.loadingFns.forEach(({key, action, idFn}) => {
			switch (inputAction.type) {
				case getType(action.request): {
					if (!!idFn) {
						state[key][idFn(inputAction as PayloadAction<any, any>)] = LoadingState.LOADING;
					} else {
						state[key] = LoadingState.LOADING as any;
					}

					return;
				}

				case getType(action.success): {
					if (!!idFn) {
						state[key][idFn(inputAction as PayloadAction<any, any>)] = LoadingState.LOADED;
					} else {
						state[key] = LoadingState.LOADED as any;
					}

					return;
				}

				case getType(action.failure): {
					if (!!idFn) {
						state[key][idFn(inputAction as PayloadAction<any, any>)] = LoadingState.ERROR;
					} else {
						state[key] = LoadingState.ERROR as any;
					}

					return;
				}
			}
		});
	}
}
