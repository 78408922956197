import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {call, delay, put} from "redux-saga/effects";
import {randomString} from "../../testing/utils/random";
import {toastActions} from "../../toaster/redux";
import {UpdatePortalButton} from "../views/UpdatePortalButton";
import Localization, {ILocalization} from "../../localization/Localization";
import {AppVersion} from "../../ts/app/config/AppVersion";
import {AppConfig} from "../../ts/app/AppConfig";
import strTranslation from "../../assets/lang/strings";

const VERSION_URL = "/ver/ver.json";

const log = createLogger("serviceStatus", SentryTags.VersionCheck);
const loc: ILocalization = Localization;
const localVersion = AppVersion.VERSION;

interface PortalVersion {
	version: string;
}

async function fetchVersion(): Promise<PortalVersion> {
	const response = await fetch(VERSION_URL + "?" + randomString());
	const jsonVerData = await response.json();
	return {version: jsonVerData.version};
}

const errorMessage: string = "Could not fetch portal version from the web server";

let firstRun = true;

export function* checkVersionByTimeoutSaga() {
	while (true) {
		//NB: For quicker debugging replace `AppConfig.VERSION_CHECK_INTERVAL_MS` with `2500` or whatever works for you.

		log.debug("Checking version ...");

		if (!firstRun) {
			yield delay(AppConfig.VERSION_CHECK_INTERVAL_MS);
		} else {
			log.debug(" ... upon the first run");
		}
		firstRun = false;

		try {
			const verObject: PortalVersion = yield call(fetchVersion);
			const remoteVersion = verObject.version;

			if (remoteVersion !== localVersion) {
				yield put(
					toastActions.addToast({
						message: loc.formatMessage(strTranslation.VERSION.new_version.toast.text),
						type: "warning",
						persist: true,
						key: "PortalVersionChecker",
						action: UpdatePortalButton,
					}),
				);
			}
		} catch (error) {
			log.captureException(error, {message: errorMessage});
		}
	}
}
