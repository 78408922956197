import {
	ActivityTypes,
	EventViewData,
	EventViewType,
	PlannedEventEntry,
	PlannedEventStatus,
	SensorDatum,
} from "redux/tracking/TrackingTypes";
import {AssessmentDataSource} from "../interventionAssessmentTypes";
import {createEventViewId, getSensorNamesFromObject} from "redux/tracking/TrackingHelper";

/**
 * Get planned omq and smq from `plannedEvents`
 */
export const getOmqSmqFromPlannedEvents = (plannedEvents: SensorDatum<PlannedEventEntry>[]) => {
	return plannedEvents?.filter(
		(event) =>
			event.value.activityType === ActivityTypes.FILL_OMQ || event.value.activityType === ActivityTypes.FILL_SMQ,
	);
};

/**
 * Get reflections total of completed omq and smq
 */
export const getOmqSmqTotalReflections = (plannedOmqSmq: SensorDatum<PlannedEventEntry>[]) => {
	const completedOmqSmq = plannedOmqSmq.filter((event) => !!event.value.reflection);
	return completedOmqSmq?.length;
};

/**
 * Transform sesor datum into assessment data source
 * */
export const transformSensorDatumToDataSource = (
	plannedOmqSmq: SensorDatum<PlannedEventEntry>[],
): AssessmentDataSource[] => {
	const dataSource = plannedOmqSmq?.map((event) => {
		return {
			...event,
			key: event.id,
		};
	});

	// Return with sorted data due table data source requirement
	return dataSource.sort((a, b) => b.value.plannedFor.getTime() - a.value.plannedFor.getTime());
};

/**
 * Transform assessment data source into event view datda
 */
export const transformDataSourceToEventViewData = (dataSource: AssessmentDataSource): EventViewData => {
	const sensorValue = dataSource.value;
	const isCompleted = sensorValue.status === PlannedEventStatus.COMPLETE;
	const isCanceled = sensorValue.status === PlannedEventStatus.CANCELED;
	let type = EventViewType.OMQ_SENSOR;

	if (sensorValue.activityType === ActivityTypes.FILL_SMQ) {
		type = EventViewType.SMQ_SENSOR;
	}

	return {
		id: createEventViewId(type, dataSource.id, dataSource.startTime),
		ownerId: dataSource.userId,
		title: sensorValue.title,
		startTime: dataSource.startTime,
		endTime: dataSource.endTime, // related to `transformPlannedEventSensorDP` function
		sensors: [dataSource.sensorName, ...getSensorNamesFromObject(dataSource.value)],
		type,
		source: dataSource,
		isCompleted,
		isCanceled,
		createdAt: dataSource.createdAt,
		updatedAt: dataSource.updatedAt,
		createdBy: dataSource.createdBy,
		updatedBy: dataSource.updatedBy,
	};
};
