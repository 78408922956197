import {
	BehaviorExperimentParams,
	BehaviorExperimentStatus,
	BehaviorExperimentTaskRegistration,
	TaskItemResponse,
} from "@sense-os/goalie-js";
import {PAGE_SIZE_OPTIONS} from "../../../components/pagination/PaginationType";
import behaviorExprSDK from "../../../tracker/behaviorExpr/helpers/behaviorExprSDK";
import {BehaviorExperimentDataSource} from "../interventionBehaviorExperimentType";

export const defaultPageSize = PAGE_SIZE_OPTIONS[0];

export type getInterventionBehaviorExperimentsParams = {
	token: string;
	hashId: string;
	page: number;
	pageSize: number;
	status?: BehaviorExperimentStatus;
};

/**
 * Get Behavior Experiment by patient hash id
 * @param token
 * @param hashId
 * @returns
 */
export async function getInterventionBehaviorExperiments(params: getInterventionBehaviorExperimentsParams) {
	const {token, hashId, page = 1, pageSize = defaultPageSize, status} = params;

	const queries: BehaviorExperimentParams = {
		patient: hashId,
		page,
		pageSize,
		status,
	};

	const behaviorExperimentResponse = await behaviorExprSDK.getBehaviorExperimentList(token, queries);

	return behaviorExperimentResponse;
}

/**
 * Convert from `BehaviorExperimentResultItem` to `BehaviorExperimentDataSource`
 * @returns
 */
export const behaviorExperimentDataSourceFormatter = (
	behaviorExperimentList: TaskItemResponse<BehaviorExperimentTaskRegistration>[],
): BehaviorExperimentDataSource[] => {
	return behaviorExperimentList.map((behaviorExperimentItem) => {
		return {
			...behaviorExperimentItem,
			key: behaviorExperimentItem.id,
		};
	});
};
