import {takeEvery, call} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {meetingNotesActions} from "../redux/meetingNotesActions";
import {UIAction} from "redux/UI/UIAction";
import {storeDispatch} from "../../../ts/redux/StoreContainer";

function* openMeetingNotesForm() {
	// Collapse chatbox for focusing on meeting notes form
	// TODO: Remove storeDispatch collapseChatBox when chatbox action already refactored
	yield call(storeDispatch, UIAction.collapseChatBox());
}

export default function* () {
	yield takeEvery(getType(meetingNotesActions.openForm), openMeetingNotesForm);
}
