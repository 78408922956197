import {TrackerItem} from "@sense-os/goalie-js";
import {call, put, select} from "redux-saga/effects";
import {getAllTrackers} from "../redux/customTrackerSelectors";
import {customTrackerActions} from "../redux/customTrackerActions";
import featureFlags from "../../../featureFlags/FeatureFlags";
import {CoreTrackerId} from "../../utils/coreTracker";

interface OnAutoEnableCoreTrackerParams {
	userId: number;
	trackerId: CoreTrackerId;
}

function* getTrackerByTrackerId(coreTrackerId: number) {
	const trackerList: TrackerItem[] = yield select(getAllTrackers);
	const selectedTracker: TrackerItem = trackerList.find((tracker) => {
		return tracker.id === coreTrackerId;
	});

	return selectedTracker;
}

/**
 * Automatic enable core tracker, if the core tracker is disabled
 * only can be used on saga action
 */
export function* onAutoEnableTracker(params: OnAutoEnableCoreTrackerParams) {
	const {userId, trackerId} = params;

	// only used if core tracker feature flag is enabled
	if (featureFlags.trackersV3) {
		const selectedTracker: TrackerItem = yield call(getTrackerByTrackerId, trackerId);
		// check if the tracker status is disabled
		if (!!selectedTracker && !selectedTracker?.isEnabled) {
			yield put(
				customTrackerActions.saveManageTrackers.request({
					userId,
					isEnabled: true,
					trackerId,
				}),
			);
		}
	}
}
