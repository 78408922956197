import {ActionType, createAction} from "typesafe-actions";

export namespace NotificationAction {
	export const init = createAction("NotificationActions/init")();
	export const reset = createAction("NotificationActions/reset")();

	export const onReceivedChatMessage = createAction(
		"NotificationActions/onReceivedChatMessage",
		(fromID: number, message: string) => ({fromID, message}),
	)();

	export const onIncomingCallNotification = createAction(
		"NotificationActions/onIncomingCallNotification",
		(fromIDs: number[]) => ({fromIDs}),
	)();

	export const onMissedCallNotification = createAction(
		"NotificationActions/onMissedCallNotification",
		(fromIDs: number[]) => ({fromIDs}),
	)();

	const combinedActions = {
		init,
		reset,
		onReceivedChatMessage,
		onIncomingCallNotification,
		onMissedCallNotification,
	};

	export type NotificationActionType = ActionType<typeof combinedActions>;
}
