/** Author: leo Date: 23/04/2021 */

import storage from "../../services/system/storage/Storage";
import {StorageKeys} from "../../services/system/storage/StorageKeys";
import createLogger from "../../../logger/createLogger";

/**
 * This module allows blacklisting some log outputs. That's mainly needed for disabling particularly noisy log outputs.
 * Here's an example how to mute excessive chat and incoming presence logging.
 * 1. Go to the dev tools in the browser
 *    and create a new entry in the portal local storage with the following KEY and VALUE:
 *    - KEY: nd_log_blacklist
 *    - VALUE: {"loadChat":true, "incomingPresence":true}
 *
 * 2. Make sure that in `chatPresenceSaga.ts` the following line exists:
 *									isLoggable.updateOtherUserPresence && log.debug("updateOtherUserPresence:", presence);
 *
 * 3. Make sure that in `chatHistorySaga.ts` the following line exists:
 * 									isLoggable.loadChat && log.debug("loadChat", userId);
 *
 *
 * If you want to mute something else, add a corresponding key to enum `LogName` below
 * and set it in `nd_log_blacklist` in the local storage to `true`.
 */

const log = createLogger("loggable");

/**
 *  Contains names of the flags used for muting noisy log outputs.
 */
enum LogName {
	loadChat = "loadChat",
	incomingPresence = "incomingPresence",
	updateOtherUserPresence = "updateOtherUserPresence",
	lastInteractionTimes = "lastInteractionTimes",
}

/** This type is used for both black list and the flag set */
export type LogList = {
	[key in LogName]: boolean;
};

/** Let's see what is blacklisted in the browser of a developer */
const logsMutedInBrowser: Partial<LogList> = storage.readFromLocalStorage<LogList>(StorageKeys.LOG_BLACKLIST) || {};

/** A list of enableds logs   */
let enabledLogs: LogList = {} as LogList;

// Let's convert the blacklist into a collection of flags
// What was `true` in the blacklist must become `false` in the collection of flags.
Object.keys(LogName).forEach((logName) => {
	enabledLogs[logName] = !logsMutedInBrowser[logName];
});

log.debug(`log flags: ${JSON.stringify(enabledLogs)}`);

/** A collection of flags used to enable or disable log outputs */
export const isLoggable: LogList = enabledLogs;
