import {LoadingState} from "constants/redux";
import {getType} from "typesafe-actions";
import {NDBlogSearchState, FIRST_PAGE_NUMBER} from "../NDBlogSearchTypes";
import {NDBlogSearchActionType, ndBlogSearchActions} from "./NDBlogSearchActions";

const initialState: NDBlogSearchState = {
	searchKeywords: null,
	searchResultItems: [],
	pasteLink: null,
	loadingState: LoadingState.EMPTY,
	currentPage: FIRST_PAGE_NUMBER,
	isOpened: false,
};

export function ndBlogSearchReducer(
	state: NDBlogSearchState = initialState,
	action: NDBlogSearchActionType,
): NDBlogSearchState {
	switch (action.type) {
		case getType(ndBlogSearchActions.openDialog):
			return {
				...state,
				isOpened: true,
			};
		case getType(ndBlogSearchActions.closeDialog):
			return {
				...state,
				isOpened: false,
			};
		case getType(ndBlogSearchActions.clearResult):
			return {
				...state,
				searchResultItems: [],
				loadingState: LoadingState.EMPTY,
			};
		case getType(ndBlogSearchActions.resetState):
			return {
				// Reset state to initialState except 'pasteLink'
				...initialState,
				pasteLink: state.pasteLink,
			};

		case getType(ndBlogSearchActions.searchPostItem.request):
			return {
				...state,
				searchKeywords: action.payload.keywords,
				loadingState: LoadingState.LOADING,
			};

		case getType(ndBlogSearchActions.searchPostItem.success):
			return {
				...state,
				searchResultItems: action.payload.postItems,
				loadingState: LoadingState.LOADED,
			};

		case getType(ndBlogSearchActions.searchPostItem.failure):
			return {...state, loadingState: LoadingState.ERROR};

		case getType(ndBlogSearchActions.searchPostBySlug.request):
			return {...state, loadingState: LoadingState.LOADING};

		case getType(ndBlogSearchActions.searchPostBySlug.success):
			return {...state, loadingState: LoadingState.LOADED};

		case getType(ndBlogSearchActions.searchPostBySlug.failure):
			return {...state, loadingState: LoadingState.ERROR};

		case getType(ndBlogSearchActions.setPasteLink):
			return {...state, pasteLink: action.payload.url};

		case getType(ndBlogSearchActions.clearPasteLink):
			return {...state, pasteLink: null};

		case getType(ndBlogSearchActions.incrementPage):
			return {...state, currentPage: state.currentPage + 1};

		case getType(ndBlogSearchActions.decrementPage):
			return {...state, currentPage: state.currentPage - 1};

		default:
			return state;
	}
}
