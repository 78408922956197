import moment from "moment";
import {TIME_UNITS} from "constants/time";
import {CustomRepetitionFormValues, RepeatEvery, Ends, RepeatedOption} from "./CustomRepetitionTypes";
import Localization, {ILocalization} from "../../localization/Localization";
import {weekNumberOfMonth} from "utils/time";
import {equalArray, equalObj} from "utils/compare";
import strTranslation from "../../assets/lang/strings";

/**
 * Returns default start time.
 *
 * Add one hour from current time.
 *
 * @param {Moment} initialMoment
 */
export function getDefaultStartTime(initialMoment?: moment.Moment): moment.Moment {
	return moment(initialMoment).add(1, TIME_UNITS.HOURS).startOf(TIME_UNITS.HOURS);
}

/**
 * Returns default end time.
 *
 * Add one hour from start time.
 *
 * @param {Moment} initialMoment
 */
export function getDefaultEndTime(initialMoment?: moment.Moment): moment.Moment {
	return getDefaultStartTime(initialMoment).add(1, TIME_UNITS.HOURS);
}

/**
 * Returns an array with names of the days of the week from Monday to Sunday
 */
export function getDayNames(): string[] {
	let days: string[] = [];
	for (let i = 0; i < 7; i++) {
		days.push(moment().weekday(i).format("dddd"));
	}
	return days;
}

/**
 * @returns an array of repeat every select options
 * `day`, `week`, and `month`
 */
export const getRepeatEveryOptions = (): {id: string; value: RepeatEvery; locKey: string}[] => {
	const translationsKey = {
		[RepeatEvery.DAY]: strTranslation.TIME.day.plural,
		[RepeatEvery.WEEK]: strTranslation.TIME.week.plural,
		[RepeatEvery.MONTH]: strTranslation.TIME.month.plural,
	};
	return Object.values(RepeatEvery).map((value, index) => {
		return {
			// Id for QA Automation test
			id: `dropdown_repeat_every_${index}`,
			value,
			locKey: translationsKey[value],
		};
	});
};

/**
 * Default custom repetition value
 */
export function getDefaultCustomRepetition(): CustomRepetitionFormValues {
	return {
		repeatEvery: {
			every: RepeatEvery.WEEK,
			value: 1,
		},
		ends: {
			ends: Ends.NEVER,
		},
		repeatDays: [moment().weekday()],
	};
}

/**
 * Returns human readable custom repetition text
 *
 * @param {CustomRepetitionFormValues}
 */
export function getCustomRepetitionText(
	startTime: moment.Moment,
	{repeatEvery, repeatDays, ends}: CustomRepetitionFormValues,
): string {
	const loc: ILocalization = Localization;

	// No matter what other values are, if it ends after one repetition, then return `Once`
	if (ends.ends === Ends.AFTER_REPETITION && ends.value === 1) {
		return loc.formatMessage(strTranslation.LOGGER_CONFIG.custom_repetition.text.once);
	}

	let result = [];

	if (repeatEvery.every === RepeatEvery.DAY) {
		result.push(getRepeatEveryDayAsString(repeatEvery.value));
	} else if (repeatEvery.every === RepeatEvery.WEEK) {
		result.push(getRepeatEveryWeekAsString(repeatEvery.value, repeatDays));
	} else {
		result.push(getRepeatEveryMonthAsString(repeatEvery.value, startTime));
	}

	if (ends.ends === Ends.ON_DATE) {
		result.push(
			loc.formatMessage(strTranslation.LOGGER_CONFIG.custom_repetition.text.until_date, {
				date: moment(ends.value).format("MMM D, YYYY"),
			}),
		);
	} else if (ends.ends === Ends.AFTER_REPETITION) {
		result.push(
			loc.formatMessage(strTranslation.LOGGER_CONFIG.custom_repetition.text.after_repetition, {
				repetition: ends.value,
			}),
		);
	}

	return result.join(", ").trim();
}

/**
 * Returns repeat every day repetition rule as human readable text
 *
 * This function uses plural helper from `formatjs` (helper inside `react-intl` library)
 *
 * @see https://formatjs.io/guides/message-syntax/#plural-format
 *
 * @param {number} count repeat every value
 */
export function getRepeatEveryDayAsString(count: number): string {
	return Localization.formatMessage(strTranslation.LOGGER_CONFIG.custom_repetition.text.repeat_every.day, {
		count,
	});
}

/**
 * Returns repeat every month repetition rule as human readable text
 *
 * This function uses plural helper from `formatjs` (helper inside `react-intl` library)
 *
 * @see https://formatjs.io/guides/message-syntax/#plural-format
 *
 * @param {number} count repeat every value
 * @param {Moment} startTime
 */
export function getRepeatEveryMonthAsString(count: number, startTime: moment.Moment): string {
	return Localization.formatMessage(strTranslation.LOGGER_CONFIG.custom_repetition.text.repeat_every.month, {
		count,
		weekDay: startTime.format("dddd"),
		weekNumberText: getWeekNumberText(startTime),
	});
}

/**
 * Returns repeat every week repetition rule as human readable text
 *
 * This function uses plural helper from `formatjs` (helper inside `react-intl` library)
 *
 * @see https://formatjs.io/guides/message-syntax/#plural-format
 *
 * @param {number} count repeat every value
 * @param {number} weekDays repeat days
 */
function getRepeatEveryWeekAsString(count: number, weekDays: number[]): string {
	return Localization.formatMessage(strTranslation.LOGGER_CONFIG.custom_repetition.text.repeat_every.week, {
		count,
		weekDays: getRepeatDaysAsString(weekDays),
	});
}

/**
 * Convert `repeatDays` array of number to week day names.
 *
 * 0 -> Monday
 * 1 -> Tuesday
 * .
 * .
 * 6 -> Sunday
 *
 * @param {number[]} repeatDays
 */
function getRepeatDaysAsString(repeatDays: number[]): string {
	if (repeatDays.length === 7) {
		return Localization.formatMessage(strTranslation.LOGGER_CONFIG.custom_repetition.text.all_days);
	}
	return repeatDays
		.sort((a, b) => a - b)
		.map((weekDayNum) => moment().weekday(weekDayNum).format("dddd"))
		.join(", ");
}

/**
 * Return monthly option text
 *
 * @param {Moment} momentObj
 */
export function getWeekNumberText(momentObj: moment.Moment = moment()): string {
	const weekNumber: number = weekNumberOfMonth(momentObj.toDate());

	const weekNumberText: string =
		{
			1: strTranslation.COMMON.number.first,
			2: strTranslation.COMMON.number.second,
			3: strTranslation.COMMON.number.third,
			4: strTranslation.COMMON.number.fourth,
		}[weekNumber] || strTranslation.COMMON.number.last;

	return Localization.formatMessage(weekNumberText);
}

/**
 * Returns `RepeatedOption` by evaluating `CustomRepetitionFormValues` value.
 *
 * Returns `RepeatedOption.DAILY` (Daily), `RepeatedOption.WEEKLY` (Weekly), or `RepeatedOption.MONTHLY` (Monthly)
 * if the `customRepetition` value meets criteria of any of the above `RepeatedOption`.
 * Otherwise, return `RepeatedOption.CUSTOM`
 *
 * @param {Moment} startTime
 * @param {RepeatedOption} repeatedOption
 * @param {CustomRepetitionFormValues} customRepetition
 */
export function getRepeatedOptionByCustomRepetetionValues(
	startTime: moment.Moment,
	repeatedOption: RepeatedOption,
	customRepetition: CustomRepetitionFormValues,
): RepeatedOption {
	if (repeatedOption === RepeatedOption.NOT_REPEATED || repeatedOption === RepeatedOption.DAILY) {
		return repeatedOption;
	}

	if (!customRepetition) {
		return repeatedOption;
	}

	const {ends, repeatDays, repeatEvery} = customRepetition;

	if (repeatEvery.value > 1) {
		return RepeatedOption.CUSTOM;
	}

	if (ends.ends === Ends.ON_DATE || ends.ends === Ends.AFTER_REPETITION) {
		return RepeatedOption.CUSTOM;
	}

	if (repeatEvery.every === RepeatEvery.DAY && repeatEvery.value === 1) {
		return RepeatedOption.DAILY;
	}

	if (repeatEvery.every === RepeatEvery.MONTH && repeatEvery.value === 1) {
		return RepeatedOption.MONTHLY;
	}

	if (repeatDays.length === 1 && repeatDays[0] === startTime.isoWeekday() - 1) {
		return RepeatedOption.WEEKLY;
	}

	return repeatedOption;
}

/**
 * Returns `RepeatedOption` localised text
 *
 * @param {Moment} startTime
 * @param {CustomRepetitionFormValues} customRepetition
 */
export function getRepeatedOptionText(
	startTime: moment.Moment,
	customRepetition: CustomRepetitionFormValues,
): (option: RepeatedOption) => string {
	const loc: ILocalization = Localization;
	return (option: RepeatedOption) => {
		if (option === RepeatedOption.MONTHLY) {
			return getRepeatEveryMonthAsString(1, startTime);
		}

		if (option === RepeatedOption.DEFAULT_CUSTOM) {
			return loc.formatMessage(strTranslation.LOGGER_CONFIG.form.repeat.custom);
		}

		if (option !== RepeatedOption.CUSTOM || !customRepetition) {
			const weekDay: string = startTime.format("dddd");
			return loc.formatMessage(strTranslation.LOGGER_CONFIG.form.repeat[option], {weekDay});
		}
		return getCustomRepetitionText(startTime, customRepetition);
	};
}

/**
 * Returns true if both custom repetition value has the same value
 *
 * @param {CustomRepetitionFormValues} a
 * @param {CustomRepetitionFormValues} b
 */
export function compareCustomRepetition(a: CustomRepetitionFormValues, b: CustomRepetitionFormValues): boolean {
	if (!a || !b) {
		return false;
	}

	const hasSameRepeatDays: boolean = equalArray(a.repeatDays, b.repeatDays);
	const hasSameRepeatEvery: boolean = equalObj(a.repeatEvery, b.repeatEvery);

	let hasSameEnds: boolean;

	if (a.ends.ends !== b.ends.ends) {
		hasSameEnds = false;
	} else if (a.ends.ends === b.ends.ends && a.ends.ends === Ends.ON_DATE) {
		const aDateString = moment(a.ends.value).format("DD-MM-YYYY");
		const bDateString = moment(b.ends.value).format("DD-MM-YYYY");
		hasSameEnds = aDateString === bDateString;
	} else {
		hasSameEnds = a.ends.value === b.ends.value;
	}

	return hasSameRepeatDays && hasSameRepeatEvery && hasSameEnds;
}

/**
 * Returns true if repetition value in CustomRepetitionModal is valid
 *
 * @param {string} rawValue
 */
export function isValidRepetitionValue(rawValue: string): boolean {
	const numValue = Number(rawValue);
	const maxRepetitionValue: number = 999;

	const noComma: boolean = !rawValue.includes(".") && !rawValue.includes(",");
	const validInteger: boolean = !isNaN(numValue) && Number.isInteger(numValue);

	return validInteger && noComma && numValue <= maxRepetitionValue;
}
