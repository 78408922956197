import {TerminationReason} from "@sense-os/goalie-js";
import {DISC} from "IoC/DISC";
import {put, takeEvery, select, call} from "redux-saga/effects";
import {getActiveCall} from "redux/videoCall/VideoCallSelectors";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {ActionType, getType} from "typesafe-actions";
import chatSDK from "../../chat/sdk";
import {callActions} from "../redux/callActions";

function* cancelCall({payload: {userId}}: ActionType<typeof callActions.cancelCall>) {
	const activeCall: ActiveCall = yield select(getActiveCall);

	// Set leave time for auth user
	yield put(callActions.userLeaveCall(userId, Date.now()));
	// Send cancel signal to counterpart
	yield call(chatSDK.cancelCall, userId, activeCall.roomId);
	// Send call summary specifically to the counterpart
	yield call(DISC.getVideoCallService().sendCallSummary, TerminationReason.Cancelled, userId);
	// End the call if needed (Usually will end if auth user is the last participant in the room)
	yield put(callActions.endCallIfNeeded(TerminationReason.Cancelled));
}

export function* cancelCallSaga() {
	yield takeEvery(getType(callActions.cancelCall), cancelCall);
}
