import {LoadingState} from "constants/redux";
import {Contact} from "@sense-os/goalie-js/dist/contacts";

/** Sort by state */
export interface SortByState {
	key: SortKey;
	asc: boolean;
}

/** Inactive Client State */
export interface InactiveClientState {
	currentPage: number;
	maxItemPerPage: number;
	loadingInactiveClients: LoadingState;
	inactiveClientsMap: {[id: number]: InactiveClient};
	lastRegistrationMap: {[id: number]: InactiveClient};
	sortByState: SortByState;
}

/** Inactive client object */
export interface InactiveClient {
	contact: Contact;
	latestRegistration: Date;
	dataLink: string;
}

/** Keys to sort inactive client list */
export enum SortKey {
	NONE = "None",
	NAME = "Name",
	LATEST_REGISTRATION = "LatestRegistration",
}

/** First client feed page number value  */
export const FIRST_PAGE_NUMBER: number = 1;
/** Maximum client feed items per page value  */
export const MAX_ITEM_PER_PAGE: number = 10;
/** Days of inactivity in total */
export const DAYS_OF_INACTIVITY: number = 3;
