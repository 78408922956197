import {TargetService} from "@sense-os/goalie-js";
import {DefaultsApi as TargetApi} from "@sense-os/goalie-js/dist/swaggerGenerated/target/api";
import autobind from "autobind-decorator";
import {Injectable} from "IoC/Injectable";

export interface ISensorTargetService {
	/**
	 * Initializes the tracking service.
	 */
	init(): void;

	/** Direct access to the TargetAPI SDK */
	readonly sdk: TargetApi;
}

@autobind
export class SensorTargetService implements ISensorTargetService, Injectable {
	public readonly c: string = "[SensorTargetService]";

	private _sdk: TargetApi;

	/** @inheritDoc */
	public get sdk(): TargetApi {
		return this._sdk;
	}

	/** @inheritDoc */
	public init(): void {
		this._sdk = TargetService;
	}
}
