import {useMemo} from "react";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {NDColors} from "../../../../material-ui/NDColors";
import {
	GraphV2Type,
	IGraphConfig,
	IGraphV2Data,
	IUseGraphData,
	TrackerRegistrationGroupByDate,
} from "../../customTrackerGraphV2Types";
import {CustomTrackerSensor} from "../../customTrackerTypes";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";
import {getActiveStringDates, getEndDate, transformRegistrationDateFromString} from "./graphV2ConfigHelper";
import {useGetXTickValuePlainData, useGetXTickValues, useGetYTickValues} from "./graphV2TickHooks";

const defaultGraphConfig = {
	color: NDColors.ORANGE,
	id: strTranslation.CUSTOM_TRACKER.chart.average_intensity,
};

export interface IAverageIntensityGraphConfig {
	color: string;
	id: string;
}

interface IAverageIntensityGraphConfigWithMeasure extends IAverageIntensityGraphConfig {
	measureKey: string;
}

const mapConfigToMeasure = (
	measures: string[],
	graphConfig: IAverageIntensityGraphConfig[],
): IAverageIntensityGraphConfigWithMeasure[] => {
	return measures.map((measure, i) => {
		return {
			measureKey: measure,
			color: graphConfig ? graphConfig[i].color : defaultGraphConfig.color,
			id: graphConfig ? graphConfig[i].id : defaultGraphConfig.id,
		};
	});
};

// minimum value of Y scale
const HIGHEST_Y_SCALE: number = 10;
// always return 6 lines start from 0
const Y_LINES: number = 6;

export const averageIntensityConfig = (
	measures: string[],
	graphConfig?: IAverageIntensityGraphConfig[],
): IGraphConfig => {
	return {
		graphType: GraphV2Type.AVERAGE_INTENSITY,
		graphName: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_intensity),
		yLegend: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_intensity),
		xLegend: localization.formatMessage(strTranslation.TIME.date),
		useGraphData: (
			trackerRegistration: TrackerRegistrationGroupByDate,
			trackerName: string,
			graphType: GraphV2Type,
			startDate: Date,
			selectedTimeView: GRAPH_TIME_VIEW_VALUE,
		): IUseGraphData => {
			const endDate = getEndDate(selectedTimeView, startDate);

			// data
			const graphData: IGraphV2Data[] = useMemo(() => {
				const activeDates: string[] = getActiveStringDates(
					trackerRegistration,
					selectedTimeView,
					startDate,
					endDate,
				);
				const measureWithConfig = mapConfigToMeasure(measures, graphConfig);

				const data = measureWithConfig.map((measure) => {
					return {
						id: measure.id,
						color: measure.color,
						data: activeDates
							.map((date) => {
								const xDate = transformRegistrationDateFromString(date);
								const totalIntensity = trackerRegistration[date].reduce((total, tracker) => {
									const intensity =
										tracker.value.measures[measure.measureKey]?.sensorData?.value?.intensity ?? 0;
									return total + intensity;
								}, 0);
								const lengthData = trackerRegistration[date].length;
								const averageIntensity = totalIntensity / lengthData;
								return {
									x: xDate,
									y: Number(averageIntensity.toPrecision(2)),
								};
							})
							.filter((data) => data.y > 0),
					};
				});

				// filter graph that contains zero value
				// https://github.com/plouc/nivo/issues/1854
				return data.filter((res) => res.data.length > 0);

				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [trackerRegistration, trackerName, graphType, selectedTimeView, startDate]);

			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);
			const xTickValues: Date[] = useGetXTickValues(graphData, selectedTimeView, startDate, endDate);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},

		useAllTimePlainGraphData: (trackerRegistration: SensorDatum<CustomTrackerSensor>[], trackerName: string) => {
			const graphData: IGraphV2Data[] = useMemo(() => {
				const measureWithConfig = mapConfigToMeasure(measures, graphConfig);
				const data = measureWithConfig.map((measure) => {
					return {
						id: measure.id,
						color: measure.color,
						data: trackerRegistration
							.map((registration) => {
								const intensity =
									registration.value.measures[measure.measureKey]?.sensorData?.value?.intensity ?? 0;
								return {
									x: registration.startTime,
									y: intensity,
								};
							})
							.filter((data) => data.y > 0),
					};
				});
				// filter graph that contains zero value
				// https://github.com/plouc/nivo/issues/1854
				return data.filter((res) => res.data.length > 0);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [trackerRegistration, trackerName]);

			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);

			const xTickValues: Date[] = useGetXTickValuePlainData(graphData);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},
	};
};
