import {SelectedFile} from "./../../fileSharing/fileSharingTypes";
import {createAction, ActionType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {Message, ChatStateType} from "@sense-os/goalie-js";
import {ChatRoomState} from "./ChatRoomReducer";
import {NumberMap} from "services/utils/Maps";

export namespace ChatRoomAction {
	/** Set all history fetched by userId so that therapist won't be able to load more chat history */
	export const setAllHistoryIsFetched = createAction("ChatActions/setAllHistoryIsFetched", (userId: number) => ({
		userId,
	}))();
	/** Set timestamp of when the sent chat is delivered */
	export const setDeliveredTimestampMs = createAction(
		"ChatActions/setDeliveredTimestampMs",
		(userId: number, timestamp: number) => ({userId, timestamp}),
	)();
	/** Set chat room fetching state */
	export const setChatRoomFetchingState = createAction(
		"ChatActions/setChatRoomFetchingState",
		(userId: number, fetchingState: LoadingState) => ({userId, fetchingState}),
	)();
	/** Set chat room uploading state */
	export const setChatRoomUploadingState = createAction(
		"ChatActions/setChatRoomUploadingState",
		(userId: number, uploadingState: LoadingState) => ({userId, uploadingState}),
	)();
	/** Save chat textarea value so it could be restored when needed */
	export const setInputText = createAction("ChatActions/setInputText", (userId: number, text: string) => ({
		userId,
		text,
	}))();
	/** Save chat messages for userId */
	export const setMessages = createAction("ChatActions/setMessages", (userId: number, messages: Message[]) => ({
		userId,
		messages,
	}))();
	/**
	 * Compared to setMessages, this will just add to
	 * messages that already stored in state.
	 */
	export const addMessages = createAction("ChatRoomActions/addMessags", (userId: number, messages: Message[]) => ({
		userId,
		messages,
	}))();
	/** Set timestamp of when the sent chat is read by other party */
	export const setReadTimestampMs = createAction(
		"ChatActions/setReadTimestampMs",
		(userId: number, timestamp: number) => ({userId, timestamp}),
	)();
	/** Set selected message of which the user want to reply */
	export const setSelectedMessage = createAction(
		"ChatActions/setSelectedMessage",
		(userId: number, msg?: Message) => ({userId, msg}),
	)();
	/** Set counterpart typing state */
	export const setTypingState = createAction(
		"ChatActions/setTypingState",
		(userId: number, typingState: ChatStateType) => ({userId: userId, typingState}),
	)();
	/** Set sent read marker timestamp. The timestamp will be used to calculate unread messages */
	export const setSentReadMarkerTimestampMs = createAction(
		"ChatActions/setSentReadMarkerTimestampMs",
		(userId: number, timestamp: number) => ({userId: userId, timestamp}),
	)();
	/** Set chat room state in bulk */
	export const bulkSetChatRoomState = createAction(
		"ChatActions/bulkSetChatRoomState",
		(partialState: NumberMap<Partial<ChatRoomState>>) => ({partialState}),
	)();
	/** Marks chat as read */
	export const markChatAsRead = createAction("ChatRoomAction/markChatAsRead", (userId: number) => ({userId}))();

	/**
	 * Send text message to userId
	 *
	 * @param {number} payload.userId
	 * @param {string} payload.text
	 * @param {string} payload.replyId - message which user selected (if exists)
	 */
	export const sendTextMessage = createAction(
		"ChatRoomAction/sendTextMessage",
		(userId: number, text: string, additionalContents: {replyMessageId?: string; files?: SelectedFile[]} = {}) => ({
			userId,
			text,
			additionalContents,
		}),
	)();

	export const setLastSentTime = createAction("ChatActions/setLastSentTime", (userId: number, messageId: string) => ({
		userId,
		messageId,
	}))();

	export const setLastReadTime = createAction("ChatActions/setLastReadTime", (userId: number, messageId: string) => ({
		userId,
		messageId,
	}))();

	export const getMoreChatHistory = createAction("ChatActions/getMoreChatHistory", (userId: number) => ({userId}))();

	const combinedActions = {
		setAllHistoryIsFetched,
		setDeliveredTimestampMs,
		setChatRoomFetchingState,
		setChatRoomUploadingState,
		setInputText,
		setMessages,
		addMessages,
		setReadTimestampMs,
		setSelectedMessage,
		setTypingState,
		bulkSetChatRoomState,
		setSentReadMarkerTimestampMs,
		sendTextMessage,
		markChatAsRead,
		getMoreChatHistory,
	};

	export type ChatRoomActionType = ActionType<typeof combinedActions>;
}
