import {ActionPattern, call, cancelled, Effect, race, take} from "redux-saga/effects";

export type DoSomething = () => void;

/**
 * Utility fn which will race between doSomething and waitFor.
 * If doSomething finishes first, it will wait for the 'waitFor' action anyway.
 */
function* doSomethingAndWait(doSomething: DoSomething | undefined, waitFor: ActionPattern) {
	let shouldWait = true;

	if (doSomething !== undefined) {
		const {stopped} = yield race({
			didIt: call(doSomething),
			stopped: take(waitFor),
		});
		shouldWait = stopped === undefined;
	}

	if (shouldWait) {
		yield take(waitFor);
	}
}

/**
 * Returns a saga that reacts to 2 states of a state-machine. A on-state and an off-state.
 * Optionally, it accepts 2 functions which will be called when the state is either on or off.
 * @param initialState
 * @param onState
 * @param offState
 * @param onEffect
 * @param offEffect
 */
export function stateMachineHandler(
	initialStateEffect: Effect,
	onState: ActionPattern,
	offState: ActionPattern,
	onEffect?: DoSomething,
	offEffect?: DoSomething,
) {
	return function* () {
		let isOn: boolean = false;

		try {
			isOn = yield initialStateEffect;

			while (true) {
				if (isOn) {
					yield call(doSomethingAndWait, onEffect, offState);
					isOn = false;
				} else {
					yield call(doSomethingAndWait, offEffect, onState);
					isOn = true;
				}
			}
		} finally {
			const isCancelled = yield cancelled();
			if (isOn && offEffect !== undefined && isCancelled) {
				yield call(offEffect);
			}
		}
	};
}

/**
 * This function allow us to check whether a condition is met before we execute a function.
 * If the condition isn't met, we postpone the execution until onState is satisfied
 */
export function* doSomethingIfOrWaitUntil(
	fn: (...args: any[]) => any,
	initialStateEffect: Effect,
	onState: ActionPattern,
) {
	const isOn = yield initialStateEffect;

	if (!isOn) {
		yield take(onState);
	}

	return yield call(fn);
}
