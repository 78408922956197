import {createEventViewId} from "redux/tracking/TrackingHelper";
import {CustomTrackerEventView, EventViewData, EventViewType} from "redux/tracking/TrackingTypes";
import {TrackerDatum} from "../../tracker/customTracker/customTrackerTypes";

export const transformTrackerDatumToTrackerEventView = (
	trackerDatum: TrackerDatum,
	contactId: number,
): CustomTrackerEventView => {
	return {
		/**  event Id */
		id: createEventViewId(EventViewType.CUSTOM_TRACKER, trackerDatum.sensorId, trackerDatum.date),
		/** Owner user id */
		ownerId: contactId,
		/** tracker name */
		title: trackerDatum.trackerName,
		/** StartTime of EventViewData */
		startTime: trackerDatum.date,
		/** EndTime of EventViewData */
		endTime: trackerDatum.date,
		/** type to identify `source` */
		type: EventViewType.CUSTOM_TRACKER,
		/** Raw data of `EventViewData` */
		source: {
			id: trackerDatum.sensorId,
			trackers: trackerDatum,
		},
		/** Event created at */
		createdAt: trackerDatum.date,
		/** Event updated at */
		updatedAt: trackerDatum.date,
	};
};

/*
 * Merge event view data with custom tracker data
 */
export const mergeEventViewDataWithCustomTracker = (
	trackerEventView: CustomTrackerEventView[],
	eventViewData: EventViewData[],
): EventViewData[] => {
	const mergedEventData = [...trackerEventView, ...eventViewData];

	/*
	 * https://github.com/senseobservationsystems/web-getgoalie/issues/5563
	 * sort tracker ascending by startTime
	 */
	const sortedEventData = mergedEventData.sort((a, b) => {
		if (a.startTime < b.startTime) {
			return -1;
		}
		return 1;
	});

	return sortedEventData;
};
