import {call, put, takeEvery} from "redux-saga/effects";
import {groupTherapyActions} from "../../redux/groupTherapyActions";
import {ActionType} from "typesafe-actions";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import createLogger from "../../../logger/createLogger";
import {toastActions} from "../../../toaster/redux";
import localization from "../../../localization/Localization";
import strTranslation from "../../../assets/lang/strings";
import {getAuthUserId, getSessionId} from "../../../auth/helpers/authStorage";
import {
	GroupTherapyMember,
	SensorData as BaseSensorData,
	GroupTherapySensor,
	SensorDataResolvedResponse,
	RegistrationType,
} from "@sense-os/goalie-js";
import {MeetingNoteFormValues} from "../../../tracker/meetingNotes/meetingNotesTypes";
import {transformFormValuesIntoSensorData} from "../../../tracker/meetingNotes/helpers/meetingNotesHelpers";
import {MeetingNote} from "@sense-os/sensor-schema/goalie-2-ts/meeting_note";
import {mergeSensorDataAndClients} from "../../helpers/groupTherapyHelpers";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import groupTherapySDK from "../../helpers/groupTherapySdk";
import {meetingNotesActions} from "../../../tracker/meetingNotes/redux/meetingNotesActions";
import {editGroupTherapySessionNoteSaga} from "./editGroupTherapySessionNoteSaga";
import moment from "moment";

const log = createLogger("saveGroupTherapySessionNoteSaga", SentryTags.GroupTherapy);

/**
 * This saga will handle group registration and sensor creation or update
 * Steps:
 * 1. Create meeting notes for each clients on the group therapy
 * 2. Create group therapy registration
 */
function* saveGroupTherapySessionNoteSaga(
	action: ActionType<typeof groupTherapyActions.saveGroupTherapySessionNote.request>,
) {
	const {formValues, group, isEdit} = action.payload;

	const token: string = yield call(getSessionId);

	if (isEdit) {
		// edit group therapy meeting note
		yield call(editGroupTherapySessionNoteSaga, group, formValues);
	} else {
		try {
			/** 1. Create meeting notes for each clients on the group therapy */
			const createdSensorIds: string[] = yield call(createSessionNotes, group.clients, formValues);

			/** 2. Create group therapy registration */
			yield apiCallSaga(groupTherapySDK.createRegistration, token, group.id, {
				sensorIds: createdSensorIds,
				title: formValues.title,
				dueDate: moment(formValues.reportTime).toDate(),
				type: RegistrationType.SESSION_NOTE,
				details: formValues,
			});

			yield put(groupTherapyActions.saveGroupTherapySessionNote.success());
			yield put(groupTherapyActions.fetchGroupRegistrationList.request({id: group.id}));
			// Clear fetched registration
			yield put(groupTherapyActions.clearFetchedRegistrationDetails());

			yield put(
				toastActions.addToast({
					message: strTranslation.MEETING_NOTES.add_meeting_note.alert.success,
					type: "success",
				}),
			);
			yield put(meetingNotesActions.closeForm());
		} catch (error) {
			log.captureException(error, {message: "Failed to save group therapy meeting notes"});
			yield put(groupTherapyActions.saveGroupTherapySessionNote.failure({error}));

			const locKeyPrefixFail: string = isEdit
				? strTranslation.MEETING_NOTES.edit_meeting_note.alert.fail
				: strTranslation.MEETING_NOTES.add_meeting_note.alert.fail;

			// Show error toast message
			yield put(
				toastActions.addToast({
					message: localization.formatMessage(locKeyPrefixFail),
					type: "error",
				}),
			);
		}
	}
}

function* createSessionNotes(clients: GroupTherapyMember[], formValues: MeetingNoteFormValues) {
	const token: string = yield call(getSessionId);
	const creatorId: number = yield call(getAuthUserId);

	/** Transform `formValues` into `SensorData` */
	const sensorData: BaseSensorData<MeetingNote> = yield call(
		transformFormValuesIntoSensorData,
		creatorId,
		formValues,
	);

	/** bulk meeting notes creation payload */
	const bulkMeetingNoteCreationPayload: GroupTherapySensor<MeetingNote>[] = yield call(
		mergeSensorDataAndClients,
		sensorData,
		clients,
	);

	const results: SensorDataResolvedResponse<MeetingNote>[] = yield apiCallSaga(
		groupTherapySDK.createBulkSensors,
		token,
		bulkMeetingNoteCreationPayload,
	);

	return results.map((result) => {
		return result.id;
	});
}

export default function* () {
	yield takeEvery(groupTherapyActions.saveGroupTherapySessionNote.request, saveGroupTherapySessionNoteSaga);
}
