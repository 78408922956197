import {call, put} from "redux-saga/effects";
import {Sensors} from "redux/tracking/TrackingTypes";
import {ActionType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getSensorResolved} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {filteringSessions, transformSessionSensorDatumToDataSource} from "../helpers/interventionSessionsHelpers";
import {SessionDataSource, SessionSensorDatum} from "../InterventionSessionType";
import {interventionSessionActions} from "../redux/interventionSessionAction";

const log = createLogger("fetchInterventionSessions", SentryTags.Intervention);

/**
 * Saga for fetching the session list that is mapped on the intervention page
 * @param action
 */
export function* fetchInterventionSessions(
	action: ActionType<typeof interventionSessionActions.fetchSessions.request>,
) {
	const {clientId} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		const sessionsResponse: SessionSensorDatum[] = yield apiCallSaga(
			getSensorResolved,
			token,
			clientId,
			[Sensors.PLANNED_EVENT],
			undefined,
			undefined,
		);

		const sessionDataSources: SessionDataSource[] = filteringSessions(
			transformSessionSensorDatumToDataSource(sessionsResponse),
		);

		yield put(
			interventionSessionActions.fetchSessions.success({
				clientId,
				data: sessionDataSources,
			}),
		);
	} catch (error) {
		log.error("Error fetch session list", error);
		yield put(interventionSessionActions.fetchSessions.failure(error));
	}
}
