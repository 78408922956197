import {ActionType, getType} from "typesafe-actions";
import {taskRegistrationActions} from "../redux/taskRegistrationActions";
import {call, put, takeEvery} from "redux-saga/effects";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import taskSDK from "../helpers/taskSDK";
import {getSessionId} from "../../auth/helpers/authStorage";
import {TRACKER_MODEL_NAME} from "@sense-os/goalie-js";
import {toastActions} from "../../toaster/redux";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import behaviorExprSDK from "../../tracker/behaviorExpr/helpers/behaviorExprSDK";
import psychoEducationSDK from "../../tracker/psychoEducation/helpers/psychoEducationSDK";
import customExerciseSDK from "../../tracker/customExercise/helper/customExerciseSDK";

const log = createLogger("deleteTaskById", SentryTags.Task);

export function* deleteTaskById(action: ActionType<typeof taskRegistrationActions.deleteTaskById.request>) {
	const {id, sensorName, linkedTaskRegistrationId} = action.payload;

	const token: string = yield call(getSessionId);
	const isBehaviorExperiment: boolean = sensorName === TRACKER_MODEL_NAME.BEHAVIOR_EXPERIMENT;
	const isPsychoEducation: boolean = sensorName === TRACKER_MODEL_NAME.HOMEWORK;
	const isCustomExercise: boolean = sensorName === TRACKER_MODEL_NAME.CUSTOM_EXERCISE;
	const doesHaveRegistration: boolean = !!linkedTaskRegistrationId;

	// show deleting toast
	yield put(
		toastActions.addToast({
			message: localization.formatMessage(strTranslation.GRAPHS.delete_event.in_progress.toast),
			type: "info",
		}),
	);

	try {
		yield apiCallSaga(taskSDK.deleteTask, token, id);

		// handle if the task has behavior experiment related registration, delete it
		if (isBehaviorExperiment && doesHaveRegistration) {
			yield apiCallSaga(behaviorExprSDK.deleteBehaviorExperiment, token, linkedTaskRegistrationId as string);
		}

		// handle if the task has custom exercise registration
		if (isCustomExercise) {
			yield apiCallSaga(customExerciseSDK.deleteCustomExercise, token, linkedTaskRegistrationId as number);
		}

		// handle if the task has behavior experiment related registration, delete it
		if (isPsychoEducation && doesHaveRegistration) {
			yield apiCallSaga(psychoEducationSDK.removePsychoEducation, token, linkedTaskRegistrationId as number);
		}

		// set that delete Task success
		yield put(taskRegistrationActions.deleteTaskById.success());

		// close the delete task modal
		yield put(
			taskRegistrationActions.setDeletingModalTask({
				open: false,
			}),
		);
		// close the task detail dialogue
		yield put(
			taskRegistrationActions.setTaskDetailDialog({
				open: false,
			}),
		);

		// show success toast
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.GRAPHS.delete_event.success.toast),
				type: "success",
			}),
		);

		// reset the task detail dialogue state
		yield put(taskRegistrationActions.resetTaskRegistrationDetailDialog());
	} catch (error) {
		log.captureException(error);
		yield put(taskRegistrationActions.deleteTaskById.failure());
	}
}

export default function* () {
	yield takeEvery(getType(taskRegistrationActions.deleteTaskById.request), deleteTaskById);
}
