import {BehaviorExperimentDetailResponse} from "@sense-os/goalie-js/dist/behaviorExperiment";
import {LoadingState} from "constants/redux";
import {TIME_UNITS} from "constants/time";
import moment from "moment";
import {NumberMap} from "services/utils/Maps";
import {ReminderTimeUnit} from "../../../helpers/time";

export interface BehaviorExprItem {
	eventViewData: {userId: number; eventViewId: string};
}

export interface BehaviorExprFormValues {
	title?: string;
	description: string;
	negativeExpectation: string;
	negativeExpectationBelievability: number;
	rationalExpectation: string;
	rationalExpectationBelievability: number;
	negativeExpectationConsequence: string;
	rationalExpectationConsequence: string;
	expiresAt: Date;
	// reminder toggle
	reminderToggle: boolean;
	// reminder time unit
	reminderTimeUnit?: ReminderTimeUnit;
	// reminder "before" duration
	reminderDuration?: number;
}

export const initialBehaviorExprFormValues: BehaviorExprFormValues = {
	negativeExpectation: "",
	negativeExpectationBelievability: 0,
	rationalExpectation: "",
	rationalExpectationBelievability: 0,
	description: "",
	negativeExpectationConsequence: "",
	rationalExpectationConsequence: "",
	title: "",
	expiresAt: moment().add(1, TIME_UNITS.HOURS).add(1, TIME_UNITS.DAY).startOf(TIME_UNITS.HOURS).toDate(),
	reminderToggle: true,
	reminderTimeUnit: ReminderTimeUnit.HOURS,
	reminderDuration: 1,
};

export enum BEHAVIOUR_EXP_FORM_TYPE {
	CREATING,
	EDITING,
	PLAN_AGAIN,
}

export interface BehaviorExprState {
	// Behavior Experiment form isOpen state
	isFormOpened: boolean;
	// Behavior Experiment saving state
	savingState: LoadingState;
	// Behavior Experiment form values map
	savedFormValues: NumberMap<BehaviorExprFormValues>;
	// behaviour exp form type
	formType: BEHAVIOUR_EXP_FORM_TYPE;
	// behaviour exp edited Id
	behaviourExpId: number;
	/**
	 * Behavior Expr Detail
	 * this state is used to load the detail of behavior experiment
	 * used in:
	 * - detail behavior expr detail modal
	 * - initialize data for behavior experiment edit form
	 */
	behaviorExprDetail?: BehaviorExperimentDetailResponse;
	// Behavior Experiment loading state
	loadingState: LoadingState;
	/**
	 * this state is used in dashboard feed
	 * we can't rely on the selected client since on the dashboard page
	 */
	userId: number;
	/** Behavior Experiment deleting state */
	deletingState: LoadingState;
}

export enum BehaviorExperimentFeedStatus {
	COMPLETED = "completed",
	UNCOMPLETED = "uncompleted",
	CANCELED = "canceled",
}
