import {createMiddleware} from "redux-beacon";
import Segment from "@redux-beacon/segment";
import {eventsMap} from "./analyticsEventsMap";

const setupAnalyticsMiddleware = (eventsMap) => {
	const segment = Segment();
	return createMiddleware(eventsMap, segment);
};

export default setupAnalyticsMiddleware(eventsMap);
