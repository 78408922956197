import {call, put} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {interventionTaskRegistrationActions} from "../redux/interventionTaskRegistrationAction";
import taskSDK from "../../../taskRegistration/helpers/taskSDK";
import {TaskResponseList} from "@sense-os/goalie-js";

const log = createLogger("fetchInterventionTaskRegistration", SentryTags.Intervention);

/**
 * Saga for fetching the task list that is mapped on the intervention page
 * @param action
 */
export function* fetchInterventionTaskRegistration(
	action: ActionType<typeof interventionTaskRegistrationActions.fetchTaskRegistration.request>,
) {
	const {hashId, page, pageSize, status} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		const res: TaskResponseList<unknown> = yield apiCallSaga(taskSDK.getTaskList, token, {
			patient: hashId,
			page,
			pageSize,
			status,
		});
		const {results, count} = res;
		yield put(interventionTaskRegistrationActions.fetchTaskRegistration.success({results, count}));
	} catch (error) {
		log.error("Error fetch intervention task registration list", error);
		yield put(interventionTaskRegistrationActions.fetchTaskRegistration.failure(error));
	}
}
