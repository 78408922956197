import {ActionType, createAction} from "typesafe-actions";
import {SnackbarKey} from "notistack";
import {ToastType} from "./toastTypes";

export const toastActions = {
	addToast: createAction("toastActions/ADD_TOAST", (toast: ToastType) => toast)(),
	removeToast: createAction("toastActions/REMOVE_TOAST", (key: SnackbarKey) => key)(),
	clearToasts: createAction("toastActions/CLEAR_TOASTS")(),
};

export type ToastActionTypes = ActionType<typeof toastActions>;
