import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {interventionBehaviorExperimentActions} from "../redux/interventionBehaviorExperimentActions";
import {BehaviorExperimentTaskRegistration, TaskResponseList} from "@sense-os/goalie-js";
import {CoreTrackerId} from "../../../tracker/utils/coreTracker";
import taskSDK from "../../../taskRegistration/helpers/taskSDK";
import {behaviorExperimentDataSourceFormatter} from "../helpers/interventionBehaviorExperimentHelpers";
import {BehaviorExperimentDataSource} from "../interventionBehaviorExperimentType";

const log = createLogger("fetchBehaviorExperiment", SentryTags.Intervention);

/**
 * saga for fetch behavior experiment list and count on the behavior experiment intervention page
 * @param action
 */
function* fetchInterventionBehaviorExperiment(
	action: ActionType<typeof interventionBehaviorExperimentActions.fetchBehaviorExperiment.request>,
) {
	const {hashId, currentPage, pageSize, status} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		const behaviorExperimentsResponse: TaskResponseList<BehaviorExperimentTaskRegistration> = yield apiCallSaga(
			taskSDK.getTaskList,
			token,
			{
				patient: hashId,
				page: currentPage,
				pageSize,
				status,
				tracker: CoreTrackerId.BEHAVIOR_EXPERIMENT,
			},
		);

		// total data of behavior experiment by client hash id
		const total: number = behaviorExperimentsResponse.count;
		// behavior experiment list
		const behaviorExperimentList: BehaviorExperimentDataSource[] = behaviorExperimentDataSourceFormatter(
			behaviorExperimentsResponse.results,
		);

		// update data attribute to behavior experiment list on the intervention behavior experiment detail page
		yield put(
			interventionBehaviorExperimentActions.fetchBehaviorExperiment.success({
				total,
				data: behaviorExperimentList,
			}),
		);
	} catch (error) {
		log.error("Error fetch behavior experiment list", error);

		yield put(interventionBehaviorExperimentActions.fetchBehaviorExperiment.failure({error}));
	}
}

export default function* () {
	yield takeEvery(
		getType(interventionBehaviorExperimentActions.fetchBehaviorExperiment.request),
		fetchInterventionBehaviorExperiment,
	);
}
