import {FC, useEffect} from "react";
import {useLocation} from "react-router";
import {createContext} from "../helpers/contextCreator";

const {Provider, createGetStateHook, createSetStateHook} = createContext({to: "", from: ""});

const useUpdateRoute = createSetStateHook((state, newRoute: string) => {
	state.from = state.to;
	state.to = newRoute;
});

export const useGetPreviousRoutePathname = createGetStateHook<string>((state) => state.from);

/**
 * This Provider will help us get the information
 * of what the previous pathname is.
 *
 * Primarily built to support part of Time Tracking Export,
 * where we want to show a warning/reminder in the login page,
 * when user attempt to go to Time Tracking Download Page
 * without login.
 */
export const PrevPathnameProvider: FC<{}> = ({children}) => {
	return (
		<Provider>
			<PrevPathnameUpdater>{children}</PrevPathnameUpdater>
		</Provider>
	);
};

const PrevPathnameUpdater: FC<{}> = ({children}) => {
	const location = useLocation();
	const updateRoute = useUpdateRoute();

	useEffect(() => {
		updateRoute(location.pathname);
	}, [location, updateRoute]);

	return <>{children}</>;
};
