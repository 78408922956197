import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import {DiaryEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import createLogger from "../../../logger/createLogger";
import {interventionDiaryActions} from "../redux/interventionDiaryActions";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getDiaryEntrySensors} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {SentryTags} from "../../../errorHandler/createSentryReport";

const log = createLogger("fetchInterventionDiariesSaga", SentryTags.Intervention);

export function* fetchInterventionDiaries(action: ActionType<typeof interventionDiaryActions.fetchDiaries.request>) {
	const {userId} = action.payload;
	const token = yield call(getSessionId);
	try {
		const diaries: SensorDatum<DiaryEntry>[] = yield apiCallSaga(
			getDiaryEntrySensors,
			token,
			userId,
			undefined,
			undefined,
		);
		yield put(interventionDiaryActions.fetchDiaries.success({data: diaries}));
	} catch (err) {
		log.captureException(err);
		yield put(interventionDiaryActions.fetchDiaries.failure({err}));
	}
}

export default function* () {
	yield takeEvery(getType(interventionDiaryActions.fetchDiaries.request), fetchInterventionDiaries);
}
