import {fork} from "redux-saga/effects";
import loadOrganizationsSaga from "./loadOrganizationsSaga";
import joinOrganizationSaga from "./joinOrganizationSaga";
import loadOrganizationsGroupsSaga from "./loadOrganizationsGroupsSaga";
import setAvailableOrganizationsSaga from "./setAvailableOrganizationsSaga";

export default function* () {
	yield fork(loadOrganizationsSaga);
	yield fork(joinOrganizationSaga);
	yield fork(loadOrganizationsGroupsSaga);
	yield fork(setAvailableOrganizationsSaga);
}
