import {getType} from "typesafe-actions";
import produce from "immer";
import {TaskRegistrationDialogState} from "../taskTypes";
import {LoadingState} from "constants/redux";
import {taskRegistrationActionType, taskRegistrationActions} from "./taskRegistrationActions";

export const initialState: TaskRegistrationDialogState = {
	/**
	 * To determine fetching data request to backend
	 */
	fetchingState: LoadingState.EMPTY,
	/**
	 * task registration response
	 */
	data: null,
	/**
	 * is task detail dialog open
	 */
	isTaskDetailOpen: false,
	/**
	 * initial state of deleting state
	 */
	deletingState: LoadingState.EMPTY,
	/**
	 * initial state of deleting modal open
	 */
	isDeletingModalOpen: false,
};

export const taskDialogDetailReducer = produce(
	(state: TaskRegistrationDialogState, action: taskRegistrationActionType): TaskRegistrationDialogState => {
		switch (action.type) {
			case getType(taskRegistrationActions.fetchTaskRegistrationDetailDialog.request): {
				state.data = null;
				state.fetchingState = LoadingState.LOADING;
				return;
			}
			case getType(taskRegistrationActions.fetchTaskRegistrationDetailDialog.success): {
				const {taskRegistrationData} = action.payload;
				state.fetchingState = LoadingState.LOADED;
				state.data = taskRegistrationData;
				return;
			}
			case getType(taskRegistrationActions.fetchTaskRegistrationDetailDialog.failure): {
				state.fetchingState = LoadingState.ERROR;
				return;
			}
			case getType(taskRegistrationActions.resetTaskRegistrationDetailDialog): {
				state.fetchingState = LoadingState.EMPTY;
				state.deletingState = LoadingState.EMPTY;
				state.data = null;
				return;
			}
			case getType(taskRegistrationActions.setTaskDetailDialog): {
				const {open} = action.payload;
				state.isTaskDetailOpen = open;
				return;
			}
			case getType(taskRegistrationActions.deleteTaskById.request): {
				state.deletingState = LoadingState.LOADING;
				return;
			}
			case getType(taskRegistrationActions.deleteTaskById.success): {
				state.deletingState = LoadingState.LOADED;
				return;
			}
			case getType(taskRegistrationActions.deleteTaskById.failure): {
				state.deletingState = LoadingState.ERROR;
				return;
			}
			case getType(taskRegistrationActions.setDeletingModalTask): {
				const {open} = action.payload;
				state.isDeletingModalOpen = open;
				// if the deleting modal is closed, set the deleting state to EMPTY
				if (!open) {
					state.deletingState = LoadingState.EMPTY;
				}
				return;
			}
		}
	},
	initialState,
);
