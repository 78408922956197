import {ActionType} from "typesafe-actions";
import {groupTherapyActions} from "../redux/groupTherapyActions";
import {call, put, takeEvery} from "redux-saga/effects";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import groupTherapySDK from "../helpers/groupTherapySdk";
import {toastActions} from "../../toaster/redux";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("updateTrackerStatusSaga", SentryTags.GroupTherapy);

function* updateTrackerStatusSaga(action: ActionType<typeof groupTherapyActions.updateTrackerStatus.request>) {
	const {groupId, isEnabled, trackerId} = action.payload;

	const token: string = yield call(getSessionId);

	try {
		yield apiCallSaga(groupTherapySDK.updateGroupTherapyTrackerStatus, token, groupId, trackerId, {
			isEnabled,
		});

		yield put(groupTherapyActions.updateTrackerStatus.success());
	} catch (error) {
		log.captureException(error, {message: "Failed to update group therapy tracker."});
		yield put(groupTherapyActions.updateTrackerStatus.failure({error}));
		yield put(
			toastActions.addToast({
				message: strTranslation.CLIENT_NETWORK.connections.fetching.failed,
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.updateTrackerStatus.request, updateTrackerStatusSaga);
}
