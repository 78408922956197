import {AppState, AppStateNames} from "../../ts/redux/AppState";

import {createSelector} from "reselect";
import {emptyRoom} from "./ChatRoomReducer";
import {ConnectionStatus, UserRole} from "@sense-os/goalie-js";
import {getAuthUser} from "../../auth/redux";
import {cacheCreateSelectorPerUserId} from "../../ts/redux/utils";
import {calculateUnreadMessages} from "../helpers/chatRoomUtils";

/** Returns chatRoom state from AppState */
export const getChatRoomState = (state: AppState) => state.chatRoom;

/**
 * Returns chatRoom state by userId
 *
 * @param {number} userId
 */
export const userChatRoomState = cacheCreateSelectorPerUserId((userId: number) => {
	return createSelector(getChatRoomState, (state) => state[userId] || emptyRoom);
});

/**
 * Returns chat messages by userId
 *
 * @param {number} userId
 */
export const chatRoomMessages = (userId: number) =>
	createSelector([userChatRoomState(userId)], (state) => state.messages || []);

/**
 * Returns earliest chat messages by userId
 *
 * @param {number} userId
 */
export const chatRoomEarliestMessage = (userId: number) =>
	createSelector([userChatRoomState(userId)], (state) => state.messages[0]);

/**
 * Returns unread messages count by userId
 *
 * @param {number} userId
 */
export const chatRoomUnreadMessageCount = (() => {
	const cache = {};
	return (state: AppState, userId: number) => {
		if (!cache[userId]) {
			cache[userId] = createSelector(
				(s: AppState) => (s.chatRoom[userId] || emptyRoom).messages,
				(s: AppState) => (s.chatRoom[userId] || emptyRoom).sentReadMarkerTimestampMs,
				getAuthUser,
				(messages, sentReadMarkerTimestampMs, authUser) => {
					if (!messages || messages.length === 0 || !authUser) {
						return 0;
					}

					return calculateUnreadMessages(authUser.id, messages, sentReadMarkerTimestampMs);
				},
			);
		}

		return cache[userId](state);
	};
})();

/**
 * Returns chat unread messages count map by userId
 */
export const unreadMessagesCountMap = createSelector([getChatRoomState, getAuthUser], (roomState, authUser) => {
	if (!authUser) {
		return {};
	}
	return Object.keys(roomState).reduce((map, id) => {
		const userId = Number(id);

		const userChatRoomState = roomState[userId];

		const unreadMessageCount =
			userChatRoomState &&
			userChatRoomState.messages &&
			calculateUnreadMessages(
				authUser.id,
				userChatRoomState.messages,
				userChatRoomState.sentReadMarkerTimestampMs,
			);

		map[userId] = unreadMessageCount || 0;
		return map;
	}, {});
});

export const canBeInvitedToConferenceCall = (state: AppState, id: number) =>
	state[AppStateNames.contacts].contactsMap[id]?.role !== UserRole.PATIENT;

/** Returns connection status of chat SDK */
export const getConnectionStatus = (state: AppState) => state[AppStateNames.chat].connectionStatus;

/** Returns connection status of chat SDK */
export const isConnected = (state: AppState) => getConnectionStatus(state) === ConnectionStatus.Connected;

export const getLastInteractionTime = (state: AppState, userId: number): number | undefined => {
	if (state.chatRoom[userId] && state.chatRoom[userId].messages && state.chatRoom[userId].messages.length > 0) {
		const messageIndex = state.chatRoom[userId].messages.length - 1;
		return state.chatRoom[userId].messages[messageIndex].timestamp;
	}

	// Need at least one of this information to determine the last interaction times.
	if (!state.chatRoom[userId]?.lastReceivedTimestampMs && !state.chatRoom[userId]?.deliveredTimestampMs) {
		return undefined;
	}

	// Based on the conditional above, one of these informations is available.
	return Math.max(
		state.chatRoom[userId].lastReceivedTimestampMs || 0,
		state.chatRoom[userId].deliveredTimestampMs || 0,
	);
};

export const getHaveUnreadFromBEData = (() => {
	const cache = {};
	return (state: AppState, userId: number) => {
		if (!cache[userId]) {
			cache[userId] = createSelector(
				(s: AppState) => (s.chatRoom[userId] || emptyRoom).messages,
				(s: AppState) => (s.chatRoom[userId] || emptyRoom).lastReceivedTimestampMs,
				(s: AppState) => (s.chatRoom[userId] || emptyRoom).sentReadMarkerTimestampMs,
				(messages, lastReceivedTimestampMs, sentReadMarkerTimestampMs) => {
					return (
						(!messages || messages.length === 0) &&
						lastReceivedTimestampMs &&
						sentReadMarkerTimestampMs &&
						lastReceivedTimestampMs > sentReadMarkerTimestampMs
					);
				},
			);
		}

		return cache[userId](state);
	};
})();

export const getSentReadMarkerTimestampMs = (userId: number) => (state: AppState) =>
	state.chatRoom[userId]?.sentReadMarkerTimestampMs;

export const getChatRoomFetchingState = (userId: number) => (state: AppState) => state.chatRoom[userId]?.fetchingState;

export const getChatRoomUploadingState = (userId: number) => (state: AppState) =>
	state.chatRoom[userId]?.uploadingState;

export const isChatBoxFullScreen = (state: AppState) => state.UI.chatBoxState.isChatBoxFullscreen;
