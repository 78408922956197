import {ClientsFeedLabel, HourRange, FIRST_PAGE_NUMBER, UserFeed, FeedType} from "../clientsFeedTypes";
import Localization, {ILocalization} from "../../localization/Localization";
import {TIME} from "constants/time";
import {createEventViewDataLink, createEventViewId} from "redux/tracking/TrackingHelper";
import strTranslation from "../../assets/lang/strings";
import moment from "moment";
import {Contact} from "@sense-os/goalie-js";
import {EventViewType} from "redux/tracking/TrackingTypes";
import {TrackerSensorName} from "../../tracker/customTracker/customTrackerSensorTypes";

/**
 * Get first item number of clients feed page
 *
 * e.g {firstItemNumber} - 8 of 30
 *
 * @param {number} currentPage
 * @param {maxItemPerPage} maxItemPerPage
 */
export function getFirstItemNumber(currentPage: number, maxItemPerPage: number): number {
	return (currentPage - FIRST_PAGE_NUMBER) * maxItemPerPage + FIRST_PAGE_NUMBER;
}

/**
 * Get last item number of clients feed page
 *
 * e.g 1 - {lastItemNumber} of 30
 *
 * @param {number} currentPage
 * @param {number} maxItemPerPage
 * @param {number} totalItems
 */
export function getLastItemNumber(currentPage: number, maxItemPerPage: number, totalItems?: number): number {
	const lastItemNumber: number = getFirstItemNumber(currentPage, maxItemPerPage) + maxItemPerPage - FIRST_PAGE_NUMBER;
	if (totalItems && totalItems < lastItemNumber) {
		return totalItems;
	}
	return lastItemNumber;
}

/**
 * Transform `ClientFeedLabel` to human readable text and localise it
 *
 * @param {ClientFeedLabel} label
 */
export function getLabelTexts(label: ClientsFeedLabel): string {
	const loc: ILocalization = Localization;
	return {
		[ClientsFeedLabel.PAST_ACTIVITY]: loc.formatMessage(strTranslation.CLIENT_FEED.label.past_activity),
		[ClientsFeedLabel.UPCOMING_ACTIVITY]: loc.formatMessage(strTranslation.CLIENT_FEED.label.upcoming_activity),
		[ClientsFeedLabel.DATA_CHECK]: loc.formatMessage(strTranslation.XAI.dashboard_page.title.label),
	}[label];
}

/**
 * Return text for time range dropdown based on hours value
 */
export function getHourRangeText(hourRange: number): string {
	const loc: ILocalization = Localization;
	switch (hourRange) {
		case HourRange.ONE_DAY:
		case HourRange.TWO_DAYS:
		case HourRange.THREE_DAYS:
			return loc.formatMessage(strTranslation.CLIENT_FEED.time_range.hours, {
				hours: hourRange,
			});
		case HourRange.ONE_WEEK:
			return loc.formatMessage(strTranslation.CLIENT_FEED.time_range.weeks, {
				weeks: hourRange / TIME.HOURS_IN_DAY / TIME.DAYS_IN_WEEK,
			});
		default:
			return loc.formatMessage(strTranslation.CLIENT_FEED.time_range.months, {
				months: hourRange / TIME.HOURS_IN_DAY / TIME.DAYS_IN_MONTH_30,
			});
	}
}

/**
 * Given `UserFeed`, this function will return true IF `UserFeed` is a planned event (Recurring or sensor data)
 */
export function isPlannedEventFeed(feed: UserFeed) {
	return [
		FeedType.RECURRING_PLANNED_EVENT,
		FeedType.PLANNED_EVENT,
		FeedType.SMQ,
		FeedType.OMQ,
		FeedType.THERAPY_SESSION,
	].includes(feed.feedType as FeedType);
}

/**
 * This function is used to get eventViewId based on feed data
 * Return eventViewId based on userFeed.
 *
 * @param {UserFeed} feed
 */
export function getEventViewIdByFeed(feed: UserFeed): string {
	/**
	 * - Use `orderDate` for psycho education feed since it doesn't have `occurrenceStartTime`
	 * - Use `createdAt` for behavior experiment feed since it doesn't have `createdAt`
	 *
	 * Based on this issue, https://github.com/senseobservationsystems/goalie-backend/issues/4084
	 * we need to change occurrenceTime to completedAt/happenedAT for completed dailyPlanner
	 *
	 */

	switch (feed.feedType) {
		case FeedType.PLANNED_EVENT:
			return createEventViewId(EventViewType.PLANNED_EVENT_SENSOR, feed.sourceId, feed.occurrenceStartTime);
		case FeedType.DIARY_ENTRY:
			return createEventViewId(EventViewType.DIARY_ENTRY_SENSOR, feed.sourceId, feed.occurrenceStartTime);
		case FeedType.MEETING_NOTE:
			return createEventViewId(EventViewType.MEETING_NOTE_SENSOR, feed.sourceId, feed.occurrenceStartTime);
		case FeedType.THOUGHT_RECORD:
			return createEventViewId(EventViewType.THOUGHT_RECORDS_SENSOR, feed.sourceId, feed.occurrenceStartTime);
		case FeedType.RECURRING_PLANNED_EVENT:
			const occurrenceTime = feed?.completedAt ?? feed.occurrenceStartTime;
			return createEventViewId(EventViewType.RECURRING_PLANNED_EVENT, feed.sourceId, occurrenceTime);
		case FeedType.PSYCHO_EDUCATION:
			return createEventViewId(EventViewType.PSYCHO_EDUCATION, feed.sourceId, feed.orderDate);
		case FeedType.BEHAVIOR_EXPERIMENT:
			return createEventViewId(EventViewType.BEHAVIOR_EXPERIMENT, feed.sourceId, feed.createdAt);
		case FeedType.THERAPY_SESSION:
			return createEventViewId(EventViewType.THERAPY_SESSION_SENSOR, feed.sourceId, feed.occurrenceStartTime);
		case FeedType.OMQ:
			return createEventViewId(EventViewType.OMQ_SENSOR, feed.sourceId, feed.occurrenceStartTime);
		case FeedType.SMQ:
			return createEventViewId(EventViewType.SMQ_SENSOR, feed.sourceId, feed.occurrenceStartTime);
		case FeedType.CHECK_IN_FEELING:
			return createEventViewId(EventViewType.CHECK_IN_FEELING, feed.sourceId, feed.occurrenceStartTime);
		case FeedType.CUSTOM_EXERCISE:
			return createEventViewId(EventViewType.CUSTOM_EXERCISE, feed.sourceId, feed.updatedAt);
	}

	if (isTrackerRegistrationFeed(feed)) {
		// Create event view id for tracker registration feed
		return createEventViewId(EventViewType.CUSTOM_TRACKER, feed.sourceId, feed.occurrenceStartTime);
	}

	return null;
}

/**
 * Return event view source link.
 *
 * @param {UserFeed} feed
 * @param {Contact} clientData
 */
export function getSourceLink(feed: UserFeed, clientData: Contact): string {
	const eventId: string = getEventViewIdByFeed(feed);
	return createEventViewDataLink(clientData.hashId, eventId);
}

/**
 * Get feed time in human readable string format
 */
export function getFeedStringTime(feedRegistrationTime: Date): string {
	const now: moment.Moment = moment();
	const feedTime: moment.Moment = moment(feedRegistrationTime);

	const isToday: boolean = moment(feedTime).isSame(now, "day");
	const isSameYear: boolean = moment(feedTime).isSame(now, "year");

	if (isToday) {
		// Return `13:20`
		return feedTime.format("HH:mm");
	}

	if (!isSameYear) {
		// Return `Month Date, Year` if it's not from same year
		// `Dec 15, 2022`
		return feedTime.format("MMM D, YYYY");
	}
	// Return  `Jan 23, 12:30` only if it's before today
	return feedTime.format("MMM D, HH:mm");
}

/** Check If given `UserFeed` is a tracker registration  */
export function isTrackerRegistrationFeed(feed: UserFeed): boolean {
	const customTrackerFeedTypes = Object.values(TrackerSensorName) as string[];
	return customTrackerFeedTypes.includes(feed.feedType);
}
