import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {PlannedEventEntry, SensorDatum, Sensors} from "redux/tracking/TrackingTypes";
import {interventionAssessmentActions} from "../redux/interventionAssessmentActions";
import {getSensorResolved} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {
	getOmqSmqFromPlannedEvents,
	getOmqSmqTotalReflections,
	transformSensorDatumToDataSource,
} from "../helpers/assessmentHelpers";
import {AssessmentDataSource} from "../interventionAssessmentTypes";

const log = createLogger("fetchOmqSmqRegistrations", SentryTags.Intervention);

function* fetchOmqSmqRegistrations(action: ActionType<typeof interventionAssessmentActions.fetchOmqSmq.request>) {
	const {userId} = action.payload;

	try {
		const token: string = yield call(getSessionId);
		// Get user's planned events
		const plannedEvents: SensorDatum<PlannedEventEntry>[] = yield apiCallSaga(
			getSensorResolved,
			token,
			userId,
			[Sensors.PLANNED_EVENT],
			undefined,
			undefined,
		);

		// Get planned and reflections of OMQ and SMQ from `plannedEvents`
		const plannedOmqSmq: SensorDatum<PlannedEventEntry>[] = yield call(getOmqSmqFromPlannedEvents, plannedEvents);
		const totalReflections: number = yield call(getOmqSmqTotalReflections, plannedOmqSmq);

		// Transform `plannedOmqSmq` into `AssessmentDataSource`
		const dataSource: AssessmentDataSource[] = yield call(transformSensorDatumToDataSource, plannedOmqSmq);

		yield put(
			interventionAssessmentActions.fetchOmqSmq.success({
				dataSource,
				totalReflections,
			}),
		);
	} catch (err) {
		log.error("Error fetch OMQ and SMQ.", err);
		yield put(interventionAssessmentActions.fetchOmqSmq.failure({err}));
	}
}

export default function* () {
	yield takeEvery(getType(interventionAssessmentActions.fetchOmqSmq.request), fetchOmqSmqRegistrations);
}
