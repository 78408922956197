import {TrackerItem} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";
import {ResolvedSensorData} from "redux/tracking/TrackingTypes";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import {UserTrackerGraphV2Map} from "./customTrackerGraphV2Types";
import {CoreTrackerId} from "../utils/coreTracker";
import {DraggableTrackerCard} from "./views/ManageTrackers/DraggableTrackers/DraggableTrackerCard";
import {MeasureValues} from "@sense-os/goalie-js/dist/tracking";

export interface TrackerCategory {
	id: string;
	category: string;
	trackers: Tracker[];
}

export interface Tracker {
	id: number;
	trackerName: string;
	description?: string;
	iconName: string;
	sensorName: string;
	scheduleType: string;
	category: string;
	measures: TrackerMeasure[];
	tags: string[];
}

export interface TrackerMeasure {
	iconName: string;
	name: string;
	description: string;
}

export enum RangeOption {
	HOURLY = "hourly",
	DAILY = "daily",
	WEEKLY = "weekly",
	FOUR_WEEKS = "4_weeks",
	RAW_DATA = "raw_data",
}

export interface CustomTrackerState {
	/***
	 * States for SetupTrackerDialog
	 */
	setupTracker: SetupTrackerState;
	/**
	 * Custom Trackers data mapped by hashId
	 */
	userTrackerMap: UserTrackerMap;
	/***
	 * States for ManageTrackersDialog
	 */
	manageTrackers: ManageTrackersState;
	/***
	 * States for customTracker Dialog
	 */
	trackerDialog: CustomTrackerDialogState;
	/***
	 * States for user graph v2
	 */
	userGraphV2Map: UserTrackerGraphV2Map;
}

export interface UserTrackerMap {
	[hashId: string]: UserTrackerState;
}

export interface ManageTrackersState {
	isManageTrackersDialogOpened: boolean;
	savingState: LoadingState;
}

export interface SetupTrackerState {
	isSetupTrackerDialogOpened: boolean;
	loadingState: LoadingState;
	savingState: LoadingState;
	trackerCategories: TrackerCategory[];
	newAddedTracker?: Tracker;
}

export interface UserTrackerState {
	loadTrackerError?: Error;
	trackers: TrackerItem[];
	trackerDataMap: TrackerDataMap;
	// implemented on loadUserTrackers saga action
	userTrackerFetchingState: LoadingState;
	// implemented on loadTrackerData saga action
	trackerDataFetchingState: LoadingState;
}

export type TrackerDataMap = {
	[sensorName: string]: TrackerDatum[];
};

/**
 * Simplified custom tracker sensor data.
 * Custom tracker data form is very complicated. This interface should improve
 * readability of custom tracker data.
 */
export interface TrackerDatum {
	/**
	 * Tracker sensor id
	 */
	sensorId: string;
	/**
	 * Basically same with sensorName
	 */
	trackerName: string;
	/**
	 * Date of when the registration occurs
	 */
	date: Date;
	/**
	 * Tracker measure values
	 */
	values: MeasureValuesMap;
	/**
	 * Tracker note
	 */
	note?: string;
}

/**
 * Every tracker
 */
export interface MeasureValuesMap {
	[measureSensorName: string]: MeasureValues;
}

/**
 * Generic form of every custom tracker sensor data.
 *
 * ```js
 * {
 * 	sensorName: "tracker_something",
 * 	value: {
 * 		measures: {
 * 			[measureName]: {
 * 				sensorData: {
 * 					value: {
 * 						[quantity | duration | intensity | boolean]: value
 * 					}
 * 				}
 * 			},
 * 			[otherMeasure]: {....}
 * 		}
 * 	}
 * }
 * ```
 */
export interface CustomTrackerSensor {
	measures: Record<string, ResolvedSensorData<MeasureValues>>;
	note?: string;
}

export const DefaultTrackerId = {
	Activities: -1,
	Mood: -2,
	StepCount: -4,
	Trackers: -5,
};
export const DEFAULT_TRACKER_ORDER = Object.values(DefaultTrackerId);

/**
 * Custom Tracker Dialog State
 */
export interface CustomTrackerDialogState {
	// Custom Tracker fetch status
	customTrackerFetchState: LoadingState;
	// Custom Tracker fetch Data
	customTrackerData: SensorDatum<CustomTrackerSensor>;
}

/** these ids for event under "exercise" section on the tracker setting modal dialogue */
export const ExerciseTrackerId = {
	DIARY_NOTE: CoreTrackerId.DIARY_NOTE,
	THOUGHT_RECORD: CoreTrackerId.THOUGHT_RECORD,
	CHECK_IN: CoreTrackerId.CHECK_IN,
	BEHAVIOR_EXPERIMENT: CoreTrackerId.BEHAVIOR_EXPERIMENT,
	PSYCHOEDUCATION: CoreTrackerId.PSYCHOEDUCATION,
	CUSTOM_EXERCISE: CoreTrackerId.CUSTOM_EXERCISE,
};

export const EXERCISE_TRACKER_ID_LIST = Object.values(ExerciseTrackerId);

/** get exercise list filtering by tracker id */
export const getExerciseList = (draggableTrackers: DraggableTrackerCard[]) => {
	return draggableTrackers
		.filter((tracker) => {
			return EXERCISE_TRACKER_ID_LIST.includes(tracker.id);
		})
		.map((tracker) => {
			/**
			 * based on product board the check in tracker can't be deactivated
			 * https://github.com/senseobservationsystems/web-getgoalie/issues/5762
			 */
			if (tracker.id === ExerciseTrackerId.CHECK_IN) {
				tracker.disabled = true;
				tracker.checked = true;
			}
			return tracker;
		});
};

/** get custom trackers list filtering by tracker id
 * except tracker under "exercise" category & meeting note
 */
export const getCustomTrackerList = (draggableTrackers: DraggableTrackerCard[]) => {
	return draggableTrackers.filter((tracker) => {
		return !EXERCISE_TRACKER_ID_LIST.includes(tracker.id) && tracker.id !== CoreTrackerId.MEETING_NOTES;
	});
};
