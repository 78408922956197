import {LoadingState} from "constants/redux";
import produce from "immer";
import {combineReducers, Reducer} from "redux";
import {getType} from "typesafe-actions";
import {
	TwoFASetupState,
	TwoFAState,
	DisableTwoFAState,
	RecoveryCodeCountState,
	ChangeTwoFADeviceState,
	GenerateRecoveryCodeState,
	ActivationState,
} from "../twoFATypes";
import {twoFAActions, TwoFAActionType} from "./twoFAActions";

const twoFASetupInitialState: TwoFASetupState = {
	recoveryCodes: [],
	qrCodeUrl: null,
	setupKey: null,
	setupKeyLoadingState: LoadingState.EMPTY,
	setupVerificationError: null,
	setupVerificationLoadingState: LoadingState.EMPTY,
	showSaveRecoveryCodeDialog: false,
};

export const twoFASetupReducer = produce((state: TwoFASetupState, action: TwoFAActionType) => {
	switch (action.type) {
		case getType(twoFAActions.loadSetupKey.request):
			state.setupKeyLoadingState = LoadingState.LOADING;
			return;
		case getType(twoFAActions.loadSetupKey.success):
			state.setupKeyLoadingState = LoadingState.LOADED;
			state.setupKey = action.payload.setupKey;
			state.qrCodeUrl = action.payload.qrCodeUrl;
			return;
		case getType(twoFAActions.loadSetupKey.failure):
			state.setupKeyLoadingState = LoadingState.ERROR;
			return;

		case getType(twoFAActions.submitVerificationCode.request):
			state.setupVerificationLoadingState = LoadingState.LOADING;
			return;
		case getType(twoFAActions.submitVerificationCode.success):
			state.setupVerificationError = null;
			state.setupVerificationLoadingState = LoadingState.LOADED;
			return;
		case getType(twoFAActions.submitVerificationCode.failure):
			state.setupVerificationLoadingState = LoadingState.ERROR;
			state.setupVerificationError = action.payload.error;
			return;

		case getType(twoFAActions.openSaveRecoveryCodeDialog):
			state.showSaveRecoveryCodeDialog = true;
			state.recoveryCodes = action.payload.recoveryCodes;
			return;
		case getType(twoFAActions.closeSaveRecoveryCodeDialog):
			state.showSaveRecoveryCodeDialog = false;
			return;
	}
}, twoFASetupInitialState);

const twoFAActivationState: ActivationState = {
	isEnabled: false,
	fetchingState: LoadingState.EMPTY,
};

const activationStateReducer = produce((state: ActivationState, action: TwoFAActionType) => {
	switch (action.type) {
		case getType(twoFAActions.submitVerificationCode.success): {
			state.isEnabled = true;
			return;
		}
		case getType(twoFAActions.submitDisable2FACode.success): {
			state.isEnabled = false;
			return;
		}
		case getType(twoFAActions.loadStatus.request):
			state.fetchingState = LoadingState.LOADING;
			return;
		case getType(twoFAActions.loadStatus.success):
			state.isEnabled = action.payload.isActive;
			state.fetchingState = LoadingState.LOADED;
			return;
		case getType(twoFAActions.loadStatus.failure):
			state.isEnabled = false;
			state.fetchingState = LoadingState.ERROR;
			return;
	}
}, twoFAActivationState);

const disableTwoFAState: DisableTwoFAState = {
	showFormDialog: false,
	loadingState: LoadingState.EMPTY,
	error: null,
};
const disableTwoFAReducer = produce((state: DisableTwoFAState, action: TwoFAActionType) => {
	switch (action.type) {
		case getType(twoFAActions.submitDisable2FACode.request): {
			state.loadingState = LoadingState.LOADING;
			return;
		}
		case getType(twoFAActions.submitDisable2FACode.success): {
			state.loadingState = LoadingState.LOADED;
			state.error = null;
			return;
		}
		case getType(twoFAActions.submitDisable2FACode.failure): {
			state.loadingState = LoadingState.ERROR;
			state.error = action.payload.error;
			return;
		}

		case getType(twoFAActions.openDisable2FAFormDialog): {
			state.showFormDialog = true;
			return;
		}

		case getType(twoFAActions.closeDisable2FAFormDialog): {
			state.showFormDialog = false;
			state.error = null;
			return;
		}
	}
}, disableTwoFAState);

const defaultRecoveryCodeCountState: RecoveryCodeCountState = {
	showUsedRecoveryCodeDialog: false,
	active: 5,
	total: 5,
};

const recoveryCodeCountReducer = produce((state: RecoveryCodeCountState, action: TwoFAActionType) => {
	switch (action.type) {
		case getType(twoFAActions.openUsedRecoveryCodeDialog): {
			state.active -= 1;
			state.showUsedRecoveryCodeDialog = true;
			return;
		}
		case getType(twoFAActions.closeUsedRecoveryCodeDialog): {
			state.showUsedRecoveryCodeDialog = false;
			return;
		}

		case getType(twoFAActions.submitVerificationCode.success): {
			// Reset active recovery code to full
			state.active = action.payload.recoveryCodes.length;
			state.total = action.payload.recoveryCodes.length;
			return;
		}

		case getType(twoFAActions.loadStatus.success): {
			state.active = action.payload.tokensRemaining;
			state.total = action.payload.tokensTotal;
			return;
		}
	}
}, defaultRecoveryCodeCountState);

const changeTwoFAState: ChangeTwoFADeviceState = {
	showFormDialog: false,
	loadingState: LoadingState.EMPTY,
	error: null,
};
const changeTwoFAReducer = produce((state: ChangeTwoFADeviceState, action: TwoFAActionType) => {
	switch (action.type) {
		case getType(twoFAActions.submitChange2FADeviceCode.request): {
			state.loadingState = LoadingState.LOADING;
			return;
		}
		case getType(twoFAActions.submitChange2FADeviceCode.success): {
			state.loadingState = LoadingState.LOADED;
			state.error = null;
			return;
		}
		case getType(twoFAActions.submitChange2FADeviceCode.failure): {
			state.loadingState = LoadingState.ERROR;
			state.error = action.payload.error;
			return;
		}

		case getType(twoFAActions.openChange2FADeviceDialog): {
			state.showFormDialog = true;
			return;
		}

		case getType(twoFAActions.closeChange2FADeviceDialog): {
			state.showFormDialog = false;
			state.error = null;
			return;
		}
	}
}, changeTwoFAState);

const generateRecoveryCodeState: GenerateRecoveryCodeState = {
	showFormDialog: false,
	loadingState: LoadingState.EMPTY,
	error: null,
	recoveryCodes: [],
};

const generateRecoveryCodeReducer = produce((state: GenerateRecoveryCodeState, action: TwoFAActionType) => {
	switch (action.type) {
		case getType(twoFAActions.submitChange2FADeviceCode.request): {
			state.loadingState = LoadingState.LOADING;
			return;
		}
		case getType(twoFAActions.submitChange2FADeviceCode.success): {
			state.loadingState = LoadingState.LOADED;
			state.error = null;
			return;
		}
		case getType(twoFAActions.submitChange2FADeviceCode.failure): {
			state.loadingState = LoadingState.ERROR;
			state.error = action.payload.error;
			return;
		}

		case getType(twoFAActions.openGenerateRecoveryCodeDialog): {
			state.showFormDialog = true;
			return;
		}

		case getType(twoFAActions.closeGenerateRecoveryCodeDialog): {
			state.showFormDialog = false;
			state.error = null;
			state.recoveryCodes = [];
			return;
		}

		case getType(twoFAActions.generateNewRecoveryCodes.request): {
			state.loadingState = LoadingState.LOADING;
			state.error = null;
			return;
		}

		case getType(twoFAActions.generateNewRecoveryCodes.success): {
			state.loadingState = LoadingState.LOADED;
			state.error = null;
			state.recoveryCodes = action.payload.recoveryCodes;
			return;
		}

		case getType(twoFAActions.generateNewRecoveryCodes.failure): {
			state.loadingState = LoadingState.ERROR;
			state.error = action.payload.error;
			return;
		}
	}
}, generateRecoveryCodeState);

export const twoFAReducer: Reducer<TwoFAState> = combineReducers({
	activationState: activationStateReducer,
	recoveryCodeCount: recoveryCodeCountReducer,
	setup: twoFASetupReducer,
	disableTwoFA: disableTwoFAReducer,
	changeTwoFADevice: changeTwoFAReducer,
	generateRecoveryCode: generateRecoveryCodeReducer,
});
