import {createAction, ActionType, createAsyncAction} from "typesafe-actions";

export namespace DashboardAction {
	export const clearError = createAction("DashboardActions/clearError")();

	export const requestVerificationToken = createAsyncAction(
		"dashboardActions/requestVerificationCode",
		"dashboardActions/requestVerificationCodeSuccess",
		"dashboardActions/requestVerificationCodeFail",
	)<void, void, Error>();

	export const submitVerificationToken = createAsyncAction(
		"dashboardActions/submitEmailVerificationCode",
		"dashboardActions/submitEmailVerificationCodeSuccess",
		"dashboardActions/submitEmailVerificationCodeFail",
	)<{emailVeriToken: string}, void, Error>();

	const combinedActions = {
		clearError,
		requestVerificationToken,
		submitVerificationToken,
	};

	export type DashboardActionType = ActionType<typeof combinedActions>;
}
