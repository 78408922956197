import {fork} from "redux-saga/effects";
import saveMeetingNotesSaga from "./saveMeetingNotesSaga";
import meetingNotesFormSaga from "./meetingNotesFormSaga";
import fetchMeetingNote from "./fetchMeetingNote";

export default function* () {
	yield fork(saveMeetingNotesSaga);
	yield fork(meetingNotesFormSaga);
	yield fork(fetchMeetingNote);
}
