import {Profile} from "@sense-os/goalie-js";

const profileSDK = new Profile();
profileSDK.getUserData = profileSDK.getUserData.bind(profileSDK);
profileSDK.updateUserData = profileSDK.updateUserData.bind(profileSDK);
profileSDK.setUserImage = profileSDK.setUserImage.bind(profileSDK);
profileSDK.getUserSettings = profileSDK.getUserSettings.bind(profileSDK);
profileSDK.updateUserSettings = profileSDK.updateUserSettings.bind(profileSDK);
profileSDK.getJobSpecializations = profileSDK.getJobSpecializations.bind(profileSDK);

export default profileSDK;
