import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {authActions} from "../redux";
import authSDK from "../helpers/authSDK";
import Localization from "../../localization/Localization";
import {shouldReportToSentry} from "../helpers";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {RegisterResponse} from "@sense-os/goalie-js/dist/authentication/types";

const log = createLogger("registrationSaga", SentryTags.Authentication);

/**
 * Handle registration request
 *
 * @param {ActionType<typeof authActions.register.request>} action
 */
function* handleRegistration(action: ActionType<typeof authActions.register.request>) {
	const {email, password, firstName, lastName} = action.payload;
	try {
		const loc = Localization;

		const response: RegisterResponse = yield call(authSDK.registerTherapist, {
			email,
			password,
			firstName,
			lastName,
			preferredLanguage: loc.getLocale(),
		});

		yield put(authActions.register.success({response}));
		yield put(authActions.login.request({email, password}));
	} catch (error) {
		if (shouldReportToSentry(error)) {
			log.captureException(error);
		}
		yield put(authActions.register.failure({error}));
	}
}

export default function* () {
	yield takeEvery(getType(authActions.register.request), handleRegistration);
}
