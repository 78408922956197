import {NotFoundError} from "@sense-os/goalie-js";
import {fork, put, takeEvery, delay, take} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import Localization from "../../localization/Localization";

import {contactActions} from "../redux/contactAction";
import contactSdk from "../contactSdk";
import {getSessionId} from "../../auth/helpers/authStorage";
import {runSagaForAcceptedTherapists, whenLoggedIn} from "../../auth/sagas/helper";
import {toastActions} from "../../toaster/redux/toastAction";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {fetchIncomingInvitations} from "../contactSdkHelper";
import {IncomingInvitation} from "../contactTypes";
import strTranslation from "../../assets/lang/strings";
import featureFlags from "../../featureFlags/FeatureFlags";
import {inviteClientsActions} from "../../inviteClients/redux/inviteClientsActions";
import {fetchInviteClientListParams} from "../../inviteClients/redux/inviteClientsType";

const log = createLogger("InvitationActionCreators", SentryTags.Invitation);

/**
 * Fetches pending incoming invitations from clients and puts them into redux store
 */
function* fetchPendingIncomingInvitations() {
	log.debug("fetchInvitations() called");
	try {
		const invitations: IncomingInvitation[] = yield apiCallSaga(fetchIncomingInvitations, getSessionId());

		log.debug("Fetched ", invitations ? invitations.length : 0, " invitations", invitations);
		yield put(contactActions.setPendingIncomingInvitations(invitations));
	} catch (error) {
		log.captureException(error, {message: "Fail to fetch pending incoming invitations"});
	}
}

/**
 * Reject Client Invitation
 */
function* rejectClientInvitation(action: ActionType<typeof contactActions.rejectClientInvitation.request>) {
	const {contact, reason} = action.payload;

	let toastMessage: string = Localization.formatMessage(strTranslation.INVITATIONS.rejection.toast.info, {
		name: contact.fullName,
	});

	log.debug("doRejectClientInvitation, calling deleteInvitationById()");
	try {
		yield apiCallSaga(contactSdk.rejectInvitation, getSessionId(), contact.invitationId, reason);

		log.debug("doRejectClientInvitation, deleteInvitationById() -> success");
		yield put(toastActions.addToast({message: toastMessage, type: "info"}));

		yield put(contactActions.rejectClientInvitation.success({contact}));
	} catch (error) {
		if (error instanceof NotFoundError) {
			// As the invitation doesn't exists anymore, we put this action here just
			// to make the card itself disappear. The invitation might be already accepted,
			// rejected, or canceled.
			yield put(contactActions.rejectClientInvitation.success({contact}));
			return;
		}

		toastMessage = Localization.formatMessage(strTranslation.INVITATIONS.rejection.toast.error, {
			name: contact.fullName,
		});
		yield put(toastActions.addToast({message: toastMessage, type: "error"}));
		log.captureException(error, {message: "Fail to reject invitation", invitationId: contact.invitationId});

		yield put(contactActions.rejectClientInvitation.failure({error, contact}));
	}
}

/**
 * Accept Client Invitation Thunk Action
 */
function* acceptClientInvitation(action: ActionType<typeof contactActions.acceptClientInvitation.request>) {
	const {contact} = action.payload;

	let toastMessage: string = Localization.formatMessage(strTranslation.INVITATIONS.accept.toast.info, {
		name: contact.fullName,
	});

	try {
		log.debug("doAcceptClientInvitation, Calling acceptInvitation()");
		yield contactSdk.acceptInvitation(getSessionId(), contact.invitationId);

		log.debug("doAcceptClientInvitation, acceptInvitation() -> success");
		yield put(toastActions.addToast({message: toastMessage, type: "info"}));

		yield put(contactActions.acceptClientInvitation.success({contact}));
		yield put(contactActions.loadContactById(contact.id));
	} catch (error) {
		if (error instanceof NotFoundError) {
			// As the invitation doesn't exists anymore, we put this action here just
			// to make the card itself disappear. The invitation might be already accepted,
			// rejected, or canceled.
			yield put(contactActions.acceptClientInvitation.success({contact}));
			return;
		}

		toastMessage = Localization.formatMessage(strTranslation.INVITATIONS.accept.toast.error, {
			name: contact.fullName,
		});
		yield put(toastActions.addToast({message: toastMessage, type: "error"}));
		log.captureException(error, {message: "Fail to accept invitation", invitationId: contact.invitationId});

		yield put(contactActions.acceptClientInvitation.failure({contact, error}));
	}
}

function* invitationSaga() {
	yield takeEvery(getType(contactActions.fetchPendingIncomingInvitations), fetchPendingIncomingInvitations);
	yield takeEvery(getType(contactActions.acceptClientInvitation.request), acceptClientInvitation);
	yield takeEvery(getType(contactActions.rejectClientInvitation.request), rejectClientInvitation);

	yield fork(
		runSagaForAcceptedTherapists(function* () {
			// There's behaviour in portal where it fetch invitations each minute
			while (true) {
				yield put(contactActions.fetchPendingIncomingInvitations());

				// init fetching of invite client list
				if (featureFlags.inviteClients) {
					// wait until the process of fetching pending invitations finishes
					yield take(getType(contactActions.setPendingIncomingInvitations));
					yield put(inviteClientsActions.fetchInviteClientsList.request(fetchInviteClientListParams));
				}

				yield delay(60000);
			}
		}),
	);
}

export default function* () {
	yield fork(whenLoggedIn(invitationSaga));
}
