import {getType} from "typesafe-actions";
import produce from "immer";

import {ClientNotePageState} from "../clientNotesTypes";
import {ClientNotePageActionType, clientNotePageActions} from "./clientNoteActions";
import {LoadingState} from "../../ts/constants/redux";
import {findNoteContent} from "../helper/findNoteContent";

const initialState: ClientNotePageState = {
	data: [],
	loadingState: LoadingState.EMPTY,
	privateNoteSearchResult: {
		searchMatchedCount: 0,
		isSearchFound: false,
		searchResults: [],
	},
	meetingNoteSearchResults: [],
	search: "",
};

export const clientNotePageReducer = produce((state: ClientNotePageState, action: ClientNotePageActionType) => {
	switch (action.type) {
		case getType(clientNotePageActions.fetchNotes.request): {
			state.loadingState = LoadingState.LOADING;
			state.error = null;
			state.meetingNoteSearchResults = [];
			state.data = [];
			return;
		}
		case getType(clientNotePageActions.fetchNotes.success): {
			const {data} = action.payload;
			state.loadingState = LoadingState.LOADED;
			state.data = data;
			return;
		}
		case getType(clientNotePageActions.fetchNotes.failure): {
			const {err} = action.payload;
			state.loadingState = LoadingState.ERROR;
			state.error = err;
			return;
		}
		case getType(clientNotePageActions.updatePrivateNoteSearchResults): {
			const {searchValue, privateNote} = action.payload;
			const result = findNoteContent(searchValue, privateNote);
			const {isSearchFound, searchMatchedCount, searchResults} = result;
			state.privateNoteSearchResult = {
				isSearchFound,
				searchMatchedCount,
				searchResults,
			};
			return;
		}
		case getType(clientNotePageActions.updateMeetingNoteSearchResults): {
			const {searchValue} = action.payload;
			const searchResult = state.data.map((meetingNote) => {
				const {searchMatchedCount, isSearchFound, searchResults} = findNoteContent(
					searchValue,
					meetingNote.value.note,
				);
				return {
					id: meetingNote.id,
					searchMatchedCount,
					isSearchFound,
					searchResults,
				};
			});
			state.meetingNoteSearchResults = searchResult;
			return;
		}
		case getType(clientNotePageActions.updateSearch): {
			const {searchString} = action.payload;
			state.search = searchString;
			return;
		}
	}
}, initialState);
