import {fork} from "redux-saga/effects";
import timeTrackingNavigationSaga from "./timeTrackingNavigationSaga";
import timeTrackingActiveEntrySaga from "./timeTrackingActiveEntrySaga";
import timeTrackingEntrySaga from "./timeTrackingEntrySaga";
import autoTimeTrackingSaga from "./autoTimeTrackingSaga";
import timeTrackingConfirmation from "./timeTrackingConfirmationSaga";
import unconfirmedEntriesSaga from "./timeTrackingUnconfirmedSaga";
import {timeTrackingExportSaga} from "./timeTrackingExportSaga";

export default function* () {
	yield fork(timeTrackingNavigationSaga);
	yield fork(autoTimeTrackingSaga);
	yield fork(timeTrackingConfirmation);
	yield fork(timeTrackingEntrySaga);
	yield fork(timeTrackingActiveEntrySaga);
	yield fork(unconfirmedEntriesSaga);
	yield fork(timeTrackingExportSaga);
}
