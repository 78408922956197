import {PlannedEventApi} from "@sense-os/goalie-js";

const PlanEventSDK = new PlannedEventApi();

PlanEventSDK.plannedEventIdOccurrenceOccurrenceTimePost =
	PlanEventSDK.plannedEventIdOccurrenceOccurrenceTimePost.bind(PlanEventSDK);
PlanEventSDK.plannedEventPost = PlanEventSDK.plannedEventPost.bind(PlanEventSDK);
PlanEventSDK.plannedEventIdOccurrenceOccurrenceTimeExcludePost =
	PlanEventSDK.plannedEventIdOccurrenceOccurrenceTimeExcludePost.bind(PlanEventSDK);

export default PlanEventSDK;
