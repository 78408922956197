import {TrackerItem} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import {TrackerChartConfig} from "./customTrackerChartTypes";
import {CustomTrackerSensor} from "./customTrackerTypes";
import {GRAPH_TIME_VIEW_VALUE} from "./helpers/graphV2Utils/graphV2Config";

export interface TrackerChartConfigWithTracker {
	tracker: TrackerItem;
	config: TrackerChartConfig[];
}

export interface TrackerGraphV2DataMap {
	[sensorName: string]: SensorDatum<CustomTrackerSensor>[];
}

export interface UserTrackerGraphV2 {
	trackerDataMap: TrackerGraphV2DataMap;
	fetchingState: LoadingState;
}

export interface UserTrackerGraphV2Map {
	[hashId: string]: UserTrackerGraphV2;
}

export interface TrackerNameWithData {
	trackerName: string;
	data: SensorDatum<CustomTrackerSensor>[];
}

export interface TrackerRegistrationGroupByDate {
	[dateString: string]: SensorDatum<CustomTrackerSensor>[];
}

// Graph interface
export interface IGraphV2Datum {
	x: Date;
	y: number;
}

export interface IGraphV2Data {
	// id of the data series, used as translation key
	id: string;
	// data series
	data: IGraphV2Datum[];
	color: string;
}

// hold information of graph data
export interface IUseGraphData {
	// list of data series, 1 graph can contain multi data series
	data: IGraphV2Data[];
	// maximum value of y axis line, not the data
	yMax: number;
	// list of y tick that will be shown
	yTickValues: number[];
	// smallest / oldest value of the data
	xMin: Date;
	// most recent value of the data
	xMax: Date;
	// list of x tick that will be shown
	xTickValues: Date[];
}

/*
 * Represent Graph interface configuration interface
 */
export interface IGraphConfig {
	// type of graph data
	graphType: GraphV2Type;
	graphName: string;
	// y axis label
	yLegend: string;
	// x axis label
	xLegend: string;
	// custom tooltip config
	yToolTipFormatter?: (
		yLabel: string,
		yValue: number,
		trackerData?: SensorDatum<CustomTrackerSensor>[],
	) => JSX.Element;
	// hooks that return graph data
	useGraphData: (
		trackerRegistration: TrackerRegistrationGroupByDate,
		trackerName: string,
		graphType: GraphV2Type,
		startDate: Date,
		selectedTimeView: GRAPH_TIME_VIEW_VALUE,
	) => IUseGraphData;
	// AllTimePlainGraphData is for getting graph data without using data grouped by date
	useAllTimePlainGraphData?: (
		trackerRegistration: SensorDatum<CustomTrackerSensor>[],
		trackerName: string,
	) => IUseGraphData;
}

export enum GraphV2Type {
	TOTAL_REGISTRATION = "totalRegistration",
	TOTAL_REGISTRATION_WITH_SYMPTOM = "totalRegistrationWithSymptom",
	AVERAGE_INTENSITY = "averageIntensity",
	AVERAGE_INTENSITY_WITH_BOOLEAN = "averageIntensityWithBoolean",
	AVERAGE_DURATION = "averageDuration",
	AVERAGE_QUANTITY = "averageQuantity",
	BOOLEAN = "boolean",
	QUANTITY = "quantity",
	RESEARCH_EMOTION = "researchEmotion",
	CHECK_IN_FEELING = "checkInFeeling",
	CATEGORICAL = "categorical",
}
