import {ActionType, getType} from "typesafe-actions";
import {select, put, call, takeEvery, race, take} from "redux-saga/effects";
import {CallSDK, CallType} from "@sense-os/goalie-js";

import {toastActions} from "../../toaster/redux";
import {getAuthUser} from "../../auth/redux";
import {AuthUser} from "../../auth/authTypes";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

import chatSDK from "../../chat/sdk";
import twilioSDK from "../../twilio/twilioSDK";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {callActions} from "../redux/callActions";
import {getActiveCall} from "redux/videoCall/VideoCallSelectors";
import {getTokenValidUntil} from "../helpers/callUtils";

const logger = createLogger("acceptCallSaga");

function* acceptCall({payload: {isVideo}}: ActionType<typeof callActions.acceptCall>) {
	yield put(callActions.openCallWindow.request());

	const result = yield race({
		success: take(getType(callActions.openCallWindow.success)),
		failure: take(getType(callActions.openCallWindow.failure)),
	});

	if (result.failure) {
		// When call window is not opened, then do nothing here.
		return;
	}

	const activeCall: ActiveCall = yield select(getActiveCall);
	const authUser: AuthUser = yield select(getAuthUser);

	try {
		const accessToken: string = yield call(getTwilioToken, activeCall, authUser);
		if (!!accessToken) {
			yield put(
				callActions.createActiveCall({
					...activeCall,
					accessToken,
					type: isVideo ? CallType.Video : CallType.Audio,
				}),
			);
			yield put(callActions.userJoinCall(authUser.id, Date.now()));
			yield call(chatSDK.acceptCall, activeCall.roomId, CallSDK.Twilio, {});
		}
	} catch (error) {
		logger.captureException(error, {isVideo});
		yield put(callActions.closeCallWindow());
		yield put(
			toastActions.addToast({
				message:
					localization.formatMessage(strTranslation.CHAT.video.call_failed.toast) +
					" " +
					JSON.stringify(error),
				type: "error",
			}),
		);
	}
}

function* getTwilioToken(activeCall: ActiveCall, authUser: AuthUser) {
	const identity: string = activeCall?.participantMap[authUser.id].publicId;
	const validUntil: Date = yield call(getTokenValidUntil);

	const token: string = yield apiCallSaga(twilioSDK.createVideoAccessToken, authUser.token, activeCall?.roomId, {
		identity,
		validUntil,
	});
	return token;
}

export function* acceptCallSaga() {
	yield takeEvery(getType(callActions.acceptCall), acceptCall);
}
